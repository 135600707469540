<template>
  <div class="section-frame fill-height">
    <div class="hunters-container">
      <v-img src="@/assets/images/ghosts/hunters/banner.jpg"></v-img>
      <hunters-menu class="hunters-menu"></hunters-menu>
      <v-container class="fill-height pa-0" fluid>
        <router-view></router-view>
      </v-container>
    </div>
  </div>
</template>
<script>
import HuntersMenu from "@/components/ghosts/HuntersMenu";
import currentGameMixin from "../../mixins/currentGameMixin";

export default {
  name: "GhostHunters",
  data() {
    return {};
  },
  components: {
    HuntersMenu,
  },
  mixins: [currentGameMixin],
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.hunters-container {
  background: #000000;
}
.hunters-menu {
  max-width: 500px;
  margin: 0 auto;
}
</style>
