<template>
  <v-container class="pa-0">
    <DialogOverlay :text="$t('mykonos.research.tutorial')" :show="!$store.getters.mykonosResearchTutoDone" :OKtext="'common.buttons.close'" @onOk="closeTutorial()"></DialogOverlay>
    <v-row>
      <v-col class="d-flex justify-center">
        <v-img :src="carImageSrc" class="car-img"></v-img>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="messages">
        {{ $t("mykonos.research.messages.teamReady") }}<br />
        {{ $t("mykonos.research.messages.destination") }}
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="d-flex justify-center">
        <input
          type="text"
          v-model="coords"
          @keypress.enter="sendResearchTeam"
          :placeholder="$t('mykonos.research.inputs.coordinates')"
          class="text-box"
        />
      </v-col>
      <v-col cols="12" class="research-note">
        <span
          >*&nbsp;{{ $t("mykonos.research.messages.unlimitedSearch") }}</span
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="d-flex justify-center">
        <button @click="sendResearchTeam" class="send-button">
          {{ $t("mykonos.research.buttons.send") }}
        </button>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="d-flex justify-center research-count">
        [&nbsp;
        <div
          v-html="
            $t('mykonos.research.messages.sitesRemaining', {
              researchCount: researchCount,
            })
          "
        ></div>
        &nbsp;]
      </v-col>
    </v-row>
    <v-row class="messages" dense v-if="searchedDestinations.length > 0">
      <v-col cols="12">
        {{ $t("mykonos.research.labels.searchedList") }}
      </v-col>
      <v-col
        v-for="(destination, index) in searchedDestinations"
        :key="`destination-${index}`"
        cols="12"
        class="searchedList"
      >
        <router-link :to="destination.route">
          {{ $t(`mykonos.data.destinations.${destination.id}.title`) }}
        </router-link>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import currentGameMixin from "../../mixins/currentGameMixin";
import DialogOverlay from "@/components/DialogOverlay";

export default {
  name: "ResearchHome",
  data() {
    return {
      coords: "",
    };
  },
  mixins: [currentGameMixin],
  components: {
    DialogOverlay
  },
  computed: {
    carImageSrc: function () {
      return require(`@/assets/images/${this.caseID}/research/research_car.svg`);
    },
    researchDeadEnd: function () {
      return this.$store.getters.mykonosResearchDeadEnd;
    },
    researchCount: function () {
      return this.$store.getters.mykonosResearchCount;
    },
    searchedDestinations: function () {
      return this.$store.getters.mykonosDestinations.filter((d) => d.searched);
    },  
  },
  methods: {
    sendResearchTeam: function () {
      this.$store.dispatch("mykonosSendResearchTeam", this.coords);
    },
    resetResearch: function () {
      this.$store.dispatch("mykonosResetResearch");
    },
    closeTutorial: function() {
      this.$store.dispatch('mykonosResearchTutoDone');
    }
  },
  watch: {
    researchDeadEnd: function (newValue) {
      if (newValue) {
        this.$router.push({ name: "mykonos-nowhere" });
      }
    },
    coords: function () {
      this.resetResearch();
    },
  },
  mounted() {
    const vm = this;

    vm.$nextTick(() => {
      vm.$store.subscribe((mutation) => {
        if (mutation.type === "mykonosSetResearchDestination") {
          const destination = vm.$store.getters.mykonosDestinations.find(
            (d) => d.id === vm.$store.getters.mykonosTargetDestination
          );

          if (destination !== undefined) vm.$router.push(destination.route);
        }
      });
    });
  },
};
</script>
<style scoped>
.car-img {
  max-width: 500px;
}
.messages {
  font-family: Source Sans 3;
  font-size: 17px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.06em;
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
}
.text-box {
  background: #00000080;
  border: none;
  border-radius: 0%;
  height: 27px;
  min-width: 200px;
  font-family: Source Sans 3;
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.06em;
  text-align: left;
  text-transform: uppercase;
  color: #ffffff;
}
.research-note {
  font-family: Source Sans 3;
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: 0.06em;
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
  font-style: italic;
}
.send-button {
  color: #ffc122;
  border: 1px solid #ffc122;
  font-family: Bebas Neue;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0.06em;
  text-align: center;
  text-transform: uppercase;
  width: 128px;
  height: 36px;
}
.research-count {
  font-family: Source Sans 3;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.06em;
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
}
research-count span {
  color: #ffc122;
}

.searchedList {
  font-family: Source Sans 3;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.06em;
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
}

.searchedList a {
  color: #ffffff;
  text-decoration: none;
}

.searchedList a:hover {
  text-decoration: underline;
}
</style>