export default {
    caseDetails: [
        "name",
        "victim",
        "location",
        "status"
    ],
    availableLocales: [
        "fr"
    ],
    sections: [
        {
            id: "research",
            route: {
                name: "mykonos-research-home"
            }
        },
        {
            id: "evidences",
            subsections: [
                {
                    id: "blog",
                    route: {
                        name: "mykonos-blog"
                    },
                    disabled: false,
                    lockable: true,
                    locked: true
                },
                {
                    id: "voices",
                    lockable: true,
                    locked: true,
                    disabled: true,
                    route: {
                        name: "mykonos-voices"
                    }
                },
                {
                    id: "flightaway",
                    lockable: true,
                    locked: true,
                    disabled: true,
                    route: {
                        name: "mykonos-flightaway"
                    }
                }
            ]
        },
        {
            id: "clues",
            route: {
                name: "mykonos-clues"
            }
        }
    ],
    research: {
        destinations: [
            {
                id: "luxuryEstate",
                coords: "E11",
                route: {
                    name: "mykonos-luxury-estate"
                },
                unlockedEvidence: "voices"
            },
            {
                id: "hospital",
                coords: "F6",
                route: {
                    name: "mykonos-hospital"
                },
                patients: ["gérard amaury", "abby taylor"]
            },
            {
                id: "policeStation",
                coords: "F9",
                route: {
                    name: "mykonos-police-station"
                },
                caseNumber: "5789B55"
            },
            {
                id: "casino",
                coords: "D5",
                route: {
                    name: "mykonos-casino"
                },
                personName: "felix gartner"
            },
            {
                id: "sanGiovani",
                coords: "D11",
                route: {
                    name: "mykonos-san-giovani"
                },
                spot: "wastebin"
            },
            {
                id: "airport",
                coords: "H10",
                route: {
                    name: "mykonos-airport"
                },
                unlockedEvidence: "flightaway"
            },
            {
                id: "palaceHotel",
                coords: "E12",
                route: {
                    name: "mykonos-palace-hotel"
                },
                envelope: "amaury",
                safeCode: "1998"
            },
            {
                id: "chronakiBeachClub",
                coords: "Q9",
                route: {
                    name: "mykonos-chronaki-beach-club"
                },
                bonusDestination: true
            },
            {
                id: "artemisClub",
                coords: "E13",
                route: {
                    name: "mykonos-artemis-club"
                },
                bonusDestination: true
            },
        ]
    },
    blog: {
        userLogin: "jc_ricci",
        userPassword: "castor pollux"
    },
    flightAway: {
        name: "muñoz",
        fullName: "Elena Muñoz",
        flightNumbers: ["MY-2348", "PA-4555"],
        cardNumber: "9854876299991445",
        passportNumber: "FF15AA101AA397",
        dateOfBirth: "1983-12-15",
        placeOfBirth: "Marseille (13)"
    }
}