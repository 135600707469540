<template>
<div>
  <div class="pc-container" v-if="!(connecting || connected)">
    <v-img src="/maestro/assets/images/security/pc.svg"></v-img>
    <div class="icon-container">
      <!-- <v-img src="@/assets/images/security/icons/eye.svg" @click="connect()" class="eye-icon"></v-img> -->
      <svg-eye-icon class="eye-icon" @click="connect()"></svg-eye-icon>
    </div>
  </div>
      <v-banner color="#00000000" v-if="connecting && !connected">
      <loader :value="progress" class="loader"></loader>
    </v-banner>
<div class="security-frame fill-height" v-if="connected">

    <div class="container-with-bg fill-height" v-if="connected">
          <div class="window greeting-window" v-if="!locked">
            <div class="window-inner">
              <div class="window-header">
                <img class="window-header-icon" src="" />
                <p v-t="`${caseID}.security.banner`"></p>
                <div
                  class="window-icon close"
                  @click="gotoHome"
                ></div>
              </div>
              <div class="window-content">
                <div class="window-body unlock-window-body">
                  <p align="center">{{ $t(`${caseID}.security.messages.unlock1`) }}</p>
                  <p align="center">{{ $t(`${caseID}.security.messages.unlock2`) }}</p>
                  <div class="envelope-container">
                    <img src="/maestro/assets/images/security/envelope.svg" />
                  </div>
                </div>
              </div>
            </div>
          </div>

      <div v-else-if="!passwordRecovery.visible && !error" class="window login-window">
        <div class="window-inner">
          <div class="window-header">
            <img class="window-header-icon" src="" />
            <p v-t="`${caseID}.security.banner`"></p>
            <div class="window-icon close"></div>
          </div>

          <div class="window-content">
            <v-row no-gutters>
              <v-col cols="12" sm="5">
              <label for="text18" v-t="`${caseID}.security.labels.email`"></label>
              </v-col>
              <v-col cols="12" sm="7">
              <input
                id="text18"
                type="text"
                class="text-field"
                v-model="inputEmail"
                @keypress.enter="login()"
              />
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" sm="5">
              <label for="text19" v-t="`${caseID}.security.labels.password`"></label>
              </v-col>
              <v-col cols="12" sm="7">
              <input
                id="text19"
                type="text"
                class="text-field"
                v-model="inputPassword"
                @keypress.enter="login()"
              />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
              <button
                @click="login()"
                class="win98-button"
                v-t="`${caseID}.security.buttons.login`"
              ></button>
              </v-col>
              <v-col>
              <a
                href="#"
                @click="showRecovery"
                v-t="`${caseID}.security.links.lostPassword`"
              ></a>
              </v-col>
            </v-row>
          </div>
        </div>
      </div>
            <div class="window questions-window" v-if="passwordRecovery.visible && !passwordRecovery.wrong && !passwordRecovery.checked">
        <div class="window-header">
          <img class="window-header-icon" src="" />
          <p v-t="`${caseID}.security.labels.securityQuestions`"></p>
          <div class="window-icon close"></div>
        </div>

        <div class="window-content">
          <v-row no-gutters
            v-for="(question, index) in passwordRecovery.questions"
            v-bind:key="index"
          >
          <v-col cols="12" sm="6">
            <label :for="'text-q' + index" v-text="question.text"></label>
          </v-col>
          <v-col cols="12" sm="6">
            <input
              :id="'text-q' + index"
              type="text"
              class="text-field"
              v-model="passwordRecovery.answers[index]"
            />
          </v-col>
          </v-row>
          <v-row>
            <v-col class="d-flex justify-center">
            <button
              @click="recover()"
              class="win98-button"
              v-t="`${caseID}.security.buttons.recover`"
            ></button>
            </v-col>
            <v-col class="d-flex justify-center">
            <button
              class="win98-button"
              @click="passwordRecovery.visible = false"
              v-t="`${caseID}.security.buttons.cancel`"
            ></button>
            </v-col>
          </v-row>
        </div>
      </div>
      <div class="modal-container" v-if="error">
        <div class="window error-dialog">
          <div class="window-inner">
            <div class="window-header">
              <img class="window-header-icon" src="" />
              <p v-t="`${caseID}.security.messages.connectionFailure`"></p>
              <div class="window-icon close"></div>
            </div>

            <div class="window-content">
              <p>
                <img src="/maestro/assets/images/security/icons/warning.png" class="win98-alert-icon"/>{{
                  $t(`${caseID}.security.messages.wrongEmailOrPassword`)
                }}
              </p>
              <v-row>
                <v-col class="d-flex justify-center">
                <button
                  class="win98-button"
                  @click="error = false"
                  v-t="`${caseID}.security.buttons.ok`"
                ></button>
                </v-col>
              </v-row>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-container" v-if="passwordRecovery.wrong">
        <div class="window error-dialog">
          <div class="window-inner">
            <div class="window-header">
              <img class="window-header-icon" src="" />
              <p v-t="`${caseID}.security.messages.recoveryFailure`"></p>
              <div class="window-icon close"></div>
            </div>

            <div class="window-content">
              <p>
                <img src="/maestro/assets/images/security/icons/warning.png" class="win98-alert-icon"/>
                {{ $t(`${caseID}.security.messages.wrongAnswers`) }}:
                <ul>
                  <li v-for="index in passwordRecovery.wrongAnswers" :key="index">
                    {{ passwordRecovery.questions[index].text }}
                  </li>
                </ul>
              </p>
              <v-row no-gutters justify="center">
                <v-col class="d-flex justify-center">
                <button
                  class="win98-button"
                  @click="passwordRecovery.wrong = false"
                  v-t="`${caseID}.security.buttons.ok`"
                ></button>
                </v-col>
              </v-row>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-container" v-if="passwordRecovery.checked">
        <div class="window error-dialog">
            <div class="window-inner">
              <div class="window-header">
                <img class="window-header-icon" src="" />
                <p v-t="`${caseID}.security.messages.passwordRecovered`"></p>
              <div class="window-icon close"></div>
            </div>

        <div class="window-content">
          <div class="window-body">
            <p>
              <img src="/maestro/assets/images/security/icons/information.png" class="win98-alert-icon"/>
              {{
                $t(`${caseID}.security.messages.yourPasswordIs`, {
                  password: passwordRecovery.password,
                })
              }}
            </p>
            <v-row no-gutters justify="center">
              <v-col class="d-flex justify-center">
              <button class="win98-button" @click="showLogin" v-t="`${caseID}.security.buttons.ok`"></button>
              </v-col>
            </v-row>
          </div>
            </div>
                    </div>
        </div>
      </div>
      <audio src="@/assets/audio/win31.mp3" ref="tada"></audio>
    </div>
  </div>
</div>
</template>

<script>
import axios from "axios";
import Loader from "@/components/Loader.vue";
import SvgEyeIcon from "@/components/maestro/SvgEyeIcon.vue";
import { isLoggedIn, loginUser } from "../../utils";
import currentGameMixin from "../../mixins/currentGameMixin";
import securityMessages from "../../messages/maestro/security.messages";

export default {
  name: "Security",
  i18n: {
    messages: securityMessages
  },
  data() {
    return {
      progress: 0,
      inputEmail: "",
      inputPassword: "",
      showPassword: false,
      connecting: false,
      connected: false,
      connectionDelay: 3000,
      error: false,
      appLaunched: false,
      result: {},
      passwordRecovery: {
        visible: false,
        password: "philha411",
        questions: [],
        checked: false,
        wrong: false,
        wrongAnswers: [],
      },
    };
  },
  components: {
    Loader,
    SvgEyeIcon,
  },
  mixins: [currentGameMixin],
  computed: {
    locale: function () {
      return this.$i18n.locale;
    },
    resultString() {
      let str = "";

      if (!this.result)
        return this.$t(`${this.caseID}.security.messages.noResult`);

      if (this.result.lastname) {
        if (str.length > 0) str += " ";
        str += this.result.lastname;
      }

      if (this.result.seat) {
        if (str.length > 0) str += ", ";
        str += this.result.seat;
      }

      if (str.length === 0)
        str = this.$t(`${this.caseID}.security.messages.noResult`);

      return str;
    },
    locked: function () {
      return this.$store.getters.maestroSecurityLocked;
    },
  },
  methods: {
    connect() {
      const vue = this;

      vue.connected = false;
      vue.connecting = true;

      const interval = setInterval(function () {
        const progress = vue.progress + Math.random() * 10;
        vue.progress = progress;

        if (vue.progress >= 100) {
          clearInterval(interval);
          vue.connecting = false;
          vue.connected = true;
        }
      }, 100);
    },
    refreshQuestions: function () {
      axios.get(`${this.casePublicData}/questions.json`).then(({ data }) => {
        this.passwordRecovery.questions = data.map(
          (q) => {
            return {
              id: q.id,
              text: this.$t(
                `${this.caseID}.data.security.questions.${q.id}.text`
              ),
              answers: q.answers,
            };
          }
        );
      });
      /*this.passwordRecovery.questions = require(`${this.casePublicData}/questions.json`).map(
        (q) => {
          return {
            id: q.id,
            text: this.$t(
              `${this.caseID}.data.security.questions.${q.id}.text`
            ),
            answers: q.answers,
          };
        }
      );*/
    },
    unlock() {
      this.$store.dispatch("unlockSection", {
        gameID: this.caseID,
        sectionID: "security",
      });
      this.playTada();
    },
    login() {
      if (loginUser(this.inputEmail, this.inputPassword)) {
        this.error = false;
        this.unlock();
      } else this.error = true;
    },
    launchApp() {
      this.searchString = "";
      this.result = {};
      this.appLaunched = true;
    },
    closeApp() {
      this.appLaunched = false;
    },
    showRecovery() {
      this.passwordRecovery.visible = true;
    },
    showLogin() {
      this.passwordRecovery.answers.fill("");
      this.passwordRecovery.checked = false;
      this.passwordRecovery.visible = false;
    },
    recover() {
      let answers = this.passwordRecovery.answers;
      let wrongAnswers = [];

      this.passwordRecovery.questions.forEach((question, index) => {
        const answer = answers[index];

        if (
          !question.answers.find(
            (a) => answer.toLowerCase().trim() === a.toLowerCase().trim()
          )
        ) {
          wrongAnswers.push(index);
        }
      });

      this.passwordRecovery.wrongAnswers = wrongAnswers;
      this.passwordRecovery.wrong = wrongAnswers.length > 0;
      this.passwordRecovery.checked = wrongAnswers.length === 0;
    },
    playTada() {
      this.$refs.tada.play();
    },
    gotoHome: function () {
      this.$router.push({ name: `${this.caseID}-home` });
    },
  },
  created() {
    this.unlocked = isLoggedIn();
  },
  mounted() {
    this.unlocked = isLoggedIn();
    this.refreshQuestions();
    this.passwordRecovery.answers = new Array(
      this.passwordRecovery.questions.length
    ).fill("");
  },
  watch: {
    locale: function () {
      this.refreshQuestions();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.window {
  background-color: #bdbebd;
  border-left: 1px solid white;
  border-bottom: 1px solid black;
  border-top: 1px solid white;
  border-right: 1px solid black;
  box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.75);
}

.window-inner {
  border: 2px solid #bdbebd;
  height: 100%;
}

.window-header {
  background: linear-gradient(to right, #000080, #1084d0);
  font-size: 15px;
  overflow: auto;
}

.window-header p {
  color: white;
  display: inline;
  position: absolute;
  vertical-align: middle;
  display: inline;
  overflow: hidden;
  white-space: nowrap;
  width: calc(100% - 105px);
  padding-top: 3px;
  padding-left: 3px;
}

.window-icon {
  width: 20px;
  height: 20px;
  background-color: #bdbebd;
  display: inline-block;
  float: right;
  border-top: 1px solid white;
  border-left: 1px solid white;
  border-bottom: 1px solid black;
  border-right: 1px solid black;

  text-align: center;
  margin-top: 3px;
  margin-right: 3px;
  margin-bottom: 3px;
}

.close {
  background-image: url("https://res.cloudinary.com/penry/image/upload/q_100/v1475278244/close_jw5cay.png");
  background-size: auto 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}

.maximise {
  background-image: url("https://res.cloudinary.com/penry/image/upload/q_100/v1475278244/maximise_anhbyl.png");
  background-size: auto 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}

.minimise {
  background-image: url("https://res.cloudinary.com/penry/image/upload/q_100/v1475278244/minimise_p60zap.png");
  background-size: auto 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}

.window-content {
  margin-top: 2px;
  border: 2px solid #808080;
  border-bottom: 2px solid white;
  border-right: 2px solid white;
  height: calc(100% - 28px);
  padding: 10px;
}

.program {
  float: left;
  display: block;
  margin-left: 5px;
  height: 29px;
  width: 200px;
  text-align: left;
  padding: 4px;
  padding-left: 30px;
  border-top: 2px solid white;
  border-left: 2px solid white;
  border-bottom: 2px solid black;
  border-right: 2px solid black;
  background-color: #bdbebd;
  box-sizing: border-box;
  overflow: hidden;
  white-space: nowrap;

  background-size: auto 70%;
  background-position: 3px 50%;
  background-repeat: no-repeat;
}

.window-header-icon {
  height: 20px;
  margin-left: 3px;
  margin-top: 3px;
  display: inline-block;
}

.focused {
  border-top: 2px solid black;
  border-left: 2px solid black;
  border-bottom: 2px solid white;
  border-right: 2px solid white;
}

.login-window {
  position: relative;
  top: 50%;
  left: 50%;
  max-width: 400px;
  width: 100%;
  transform: translate(-50%, -50%);
}

.greeting-window {
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 300px;
}

.questions-window {
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-width: 500px;
  width: 100%;
}

.modal-container {
  position: relative;
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
}

.error-dialog {
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-width: 500px;
  width: 100%;
}

.app-window {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 500px;
}

img {
  max-width: 100%;
}

.icon {
  position: absolute;
  top: 10%;
  width: 10%;
}

.text-field {
  padding: 3px 4px;
  border: none;
  box-shadow: inset -1px -1px #ffffff, inset 1px 1px #808080,
    inset -2px -2px #dfdfdf, inset 2px 2px #0a0a0a;
  background-color: #ffffff;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0;
}

.win98-button {
  box-sizing: border-box;
  border: none;
  background: #c0c0c0;
  box-shadow: inset -1px -1px #0a0a0a, inset 1px 1px #ffffff,
    inset -2px -2px #808080, inset 2px 2px #dfdfdf;
  border-radius: 0;

  min-width: 75px;
  min-height: 35px;
  padding: 0 12px;
}

.win98-text {
  font-family: "Pixelated MS Sans Serif", Arial;
  -webkit-font-smoothing: none;
  font-size: 11px;
}

.win98-alert-icon {
  vertical-align: middle;
  margin-right: 10px;
  width: 32px;
}

.security-frame {
  padding: 10px;
  background: #00000066;
  border: 1px solid #ffffff;
  border-radius: 7px;
  padding: 19px;
}

.container-with-bg {
  background-image: url("/maestro/assets/images/security/win95_logo.png"),
    url("/maestro/assets/images/security/clouds_background.jpg");
  /* background-repeat: repeat; */
  background-size: 30%, cover;
  background-position: center;
  padding: 10px;
}

.bg-logo {
  position: relative;
  left: 50%;
  width: 30%;
  transform: translate(-50%, -50%);
}

.loader {
  position: absolute;
  top: 50%;
  transform: translate(-50%, 50%);
  left: 50%;
  width: 370px;
  height: 150px;
  max-width: 100%;
  box-shadow: 8px 6px 7px 0px #0000004d;
}
.pc-container {
  position: relative;
  max-width: 400px;
  margin: auto;
}
.icon-container {
  position: absolute;
  width: 20%;
  height: 20%;
  top: 32%;
  left: 52%;
  transform: translate(-50%, -50%);
}

.unlock-window-body {
  position: relative;
  padding-bottom: 100px;
  text-transform: uppercase;
}

.envelope-container {
  position: absolute;
  top: 100%;
  left: 50%;
  width: 67px;
  transform: translate(-50%, -100px);
}
</style>
