<template>
  <div>
    <div class="blog-frame fill-height">
      <v-container class="blog-container fill-height pa-0" fluid>
        <template v-if="locked">
          <v-row>
            <v-col cols="12" class="d-flex justify-center">
              <img
                src="@/assets/images/mykonos/blog/mydrive_logo.svg"
                class="blog-logo"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="d-flex justify-center">
              <v-avatar class="blog-avatar">JC</v-avatar>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="d-flex justify-center blog-greetings">
              {{ $t("mykonos.blog.messages.welcome") }},&nbsp; {{ userLogin }}
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="d-flex justify-center blog-prompt">
              {{ $t("mykonos.blog.messages.pleaseLogin") }}
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="d-flex justify-center">
              <div class="blog-login-field">
                <div class="blog-login-container">
                  <div>
                    <input
                      type="password"
                      :placeholder="$t('mykonos.blog.placeholders.password')"
                      v-model="password"
                      @keypress.enter="tryLogin"
                    />
                  </div>
                  <div>
                    <v-btn icon class="blog-login-button" @click="tryLogin"
                      ><img src="@/assets/images/mykonos/blog/login_arrow.svg"
                    /></v-btn>
                  </div>
                </div>
              </div>
            </v-col>
            <v-col
              cols="12"
              class="blog-login-error d-flex justify-center"
              v-if="loginError"
            >
              {{ $t("mykonos.blog.messages.wrongPassword") }}
            </v-col>
          </v-row>
        </template>
        <template v-else>
          <v-row
            class="flex-wrap flex-column fill-height"
            align="center"
            no-gutters
          >
            <v-col class="browser-header shrink pa-4">
              <img
                src="@/assets/images/mykonos/blog/mydrive_logo.svg"
                class="browser-logo"
              />
              <div class="text-right">
                <span v-if="!isMobile" class="user-name">{{ userLogin }}</span
                ><v-avatar size="40" class="blog-avatar ml-2">JC</v-avatar>
              </div>
            </v-col>
            <v-col class="grow">
              <v-container
                class="browser-ui"
                :class="{ mobile: isMobile }"
                fluid
              >
                <v-row class="lex-wrap flex-column fill-height" no-gutters>
                  <template v-if="!showPreviewOnly">
                    <v-col class="shrink blog-title pb-3 pl-3" v-if="!isMobile">
                      {{ $t("mykonos.blog.labels.myDrive") }}</v-col
                    >

                    <v-col
                      class="shrink folder-title pb-2 pl-8"
                      :class="{ mobile: isMobile }"
                    >
                      <img
                        src="@/assets/images/mykonos/blog/folder.svg"
                        class="blog-folder"
                      />
                      {{ $t("mykonos.blog.labels.folder") }}
                      <v-divider class="mt-2" v-if="!isMobile"></v-divider>
                    </v-col>
                  </template>
                  <v-col class="shrink folder-title back-bar" v-else>
                    <v-btn icon @click="backToFolder"
                      ><img
                        src="@/assets/images/mykonos/blog/return_icon.svg"
                        class="back-icon"
                    /></v-btn>
                    {{ $t("mykonos.blog.links.backToFolder") }}
                  </v-col>
                  <v-col class="grow pt-2">
                    <v-row class="fill-height">
                      <v-col
                        class="pl-5"
                        cols="12"
                        md="6"
                        v-if="!showPreviewOnly"
                      >
                        <ul class="browser-list">
                          <li
                            v-for="(picture, index) in pictures"
                            :key="`pic-${index}`"
                            class="picture-link"
                          >
                            <a @click="selectPicture(index)">
                              <img
                                :src="getPictureByIndex(index)"
                                class="thumbnail"
                              />
                              <span>
                                {{
                                  formatPictureName(picture) | stripExtension
                                }}</span
                              >
                            </a>
                          </li>
                        </ul>
                      </v-col>
                      <v-col cols="12" md="6" class="px-5 text-center">
                        <img
                          v-show="selectedIndex !== -1"
                          :src="selectedUri"
                          class="picture-preview"
                        />
                        <p class="text-center" v-if="selectedIndex !== -1">
                          {{
                            formatPictureName(pictures[selectedIndex])
                              | stripExtension
                          }}
                        </p>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
          </v-row>
        </template>
      </v-container>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import currentGameMixin from "../../mixins/currentGameMixin";

export default {
  name: "Blog",
  data() {
    return {
      sectionID: "blog",
      password: "",
      pictures: [],
      publicPath: process.env.BASE_URL,
      selectedIndex: -1,
    };
  },
  mixins: [currentGameMixin],
  computed: {
    locked: function () {
      return this.$store.getters.mykonosBlogLocked;
    },
    userLogin: function () {
      return this.$store.getters.mykonosBlogLogin;
    },
    loginError: function () {
      return this.$store.getters.mykonosBlogError;
    },
    dataPath: function () {
      return `${this.publicPath}${this.caseID}/data/myDrive.json`;
    },
    selectedUri: function () {
      return this.getPictureByIndex(this.selectedIndex);
    },
    showPreviewOnly: function () {
      if (this.selectedIndex === -1) return false;

      return this.isMobile;
    },
    isMobile: function () {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  methods: {
    tryLogin: function () {
      this.$store.dispatch("mykonosTryLoginBlog", this.password);
    },
    resetError: function () {
      this.$store.dispatch("mykonosResetBlogError");
    },
    selectPicture: function (index) {
      this.selectedIndex = index;
    },
    getPictureByIndex: function (index) {
      if (index === -1) return "";

      return `${this.publicPath}${this.caseID}/assets/images/${this.sectionID}/pictures/${this.pictures[index]}`;
    },
    backToFolder: function () {
      this.selectedIndex = -1;
    },
    formatPictureName: function (name) {
      return name.replace("-", ":");
    },
  },
  mounted() {
    this.$nextTick(() => {
      axios.get(this.dataPath).then(({ data }) => {
        this.pictures = data;
      });
    });
  },
  watch: {
    password: function () {
      this.resetError();
    },
  },
};
</script>
<style scoped>
.blog-frame {
  background: #00000066;
  border: 1px solid #ffffff;
  border-radius: 7px;
  padding: 19px;
}
.blog-container {
  background-image: url(~@/assets/images/mykonos/blog/background.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}
.blog-logo {
  max-width: 262px;
  width: 100%;
}
.blog-avatar {
  background: #ed1c24;
  color: #ffffff;
  font-family: DM Sans;
  font-size: 18px;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: 0.1em;
  text-align: center;
  font-family: DM Sans;
  font-size: 18px;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: center;
}

.user-name {
  font-family: DM Sans;
  font-size: 13px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: right;
  text-transform: uppercase;
}
.blog-greetings {
  font-family: DM Sans;
  font-size: 18px;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: center;
  color: #000000;
}

.blog-prompt {
  font-family: DM Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
  color: #000000;
}

.blog-login-field {
  border-radius: 20px;
  background: #808080;
  max-width: 331px;
  width: 50%;
  height: 40px;
  display: table;
  padding: 2px;
}
.blog-login-container {
  display: table-row;
}
.blog-login-container > div {
  width: 100%;
  display: table-cell;
  vertical-align: middle;
  padding-left: 20px;
}
.blog-login-button {
  float: right;
}
.blog-login-container input {
  width: 100%;
  font-family: DM Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
}

.blog-login-container input::placeholder {
  color: #ffffff80;
}

.blog-login-error {
  font-family: DM Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #ed1c24;
}

.browser-logo {
  max-height: 35px;
  float: left;
}

.browser-header {
  background: #ffffff;
}

.blog-title {
  font-family: DM Sans;
  font-size: 18px;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
}

.blog-folder {
  height: 25px;
  vertical-align: middle;
}

.folder-title {
  font-family: DM Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}

.folder-title.mobile {
  background: #dbdbdb;
  padding: 10px 30px !important;
  font-size: 14px;
}

.browser-ui {
  height: calc(100% - 44px);
  margin-top: 44px;
  background: #ffffff;
}

.browser-ui.mobile {
  height: 100%;
  margin-top: 0;
  padding: 0;
}

.browser-list {
  list-style-type: none;
}

.back-icon {
  vertical-align: middle;
}

.back-bar {
  background: #dbdbdb;
  padding: 10px !important;
}

.picture-link {
  cursor: pointer;
  text-decoration: underline;
  color: #000000;
}

.thumbnail {
  width: 47px;
  vertical-align: middle;
  margin-bottom: 5px;
}

.picture-preview {
  max-width: 100%;
}
</style>