<template>
  <v-container>
    <v-row dense>
      <v-col>
        <game-menu :menus="sections" v-if="$vuetify.breakpoint.xs"></game-menu>
      </v-col>
      <v-col cols="12" class="justify-center d-flex d-sm-none">
        <reset-button :gameID="caseID" class="setting-button">{{
          $t("homepage.reset.button")
        }}</reset-button>
      </v-col>
      <v-col cols="12" class="justify-center d-flex d-sm-none">
        <locale-switcher :locales="caseAvailableLocales" :currentLocale="currentLocale" class="setting-button">{{
          $t("homepage.localeSwitcher.title")
        }}</locale-switcher>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import GameMenu from "@/components/GameMenu.vue";
import ResetButton from "@/components/ResetButton.vue";
import LocaleSwitcher from "@/components/LocaleSwitcher.vue";
import currentGameMixin from "@/mixins/currentGameMixin";

export default {
  name: "Home",

  components: {
    GameMenu,
    ResetButton,
    LocaleSwitcher,
  },
  mixins: [currentGameMixin],
  computed: {
    sections: function () {
      return this.$festivalStore.sections.map((s) => {
        const sec = s;

        sec.title = this.$t(`${this.caseID}.data.sections.${sec.id}`);

        if (sec.subsections) {
          sec.subsections = sec.subsections.map((ss) => {
            const subsec = ss;

            if (sec.id === "envelopes") {
              subsec.title = this.$t(`${this.caseID}.data.sections.${subsec.id}`);
            } else if (sec.id === "evidences" && subsec.disabled) {
              subsec.title = this.$t(`${this.caseID}.data.sections.locked`);
            } else {
              subsec.title = this.$t(`${this.caseID}.data.sections.${subsec.id}`);
            }            

            return subsec            
          });
        }

        return sec;
      });
    },
  },
};
</script>
<style scoped>
.setting-button {
  font-family: "Source Sans 3";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0.06em;
  margin-top: 50px;
  color: #ffffff;
  text-transform: uppercase;
}

.setting-button:hover {
  color: #ffc122;
}
</style>
