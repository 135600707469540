<template>
  <v-item-group>
    <v-container class="games-container">
      <img
        src="/common/assets/images/55investigations_logo.svg"
        class="agency-logo"
      />
      <img
        src="/common/assets/images/55investigations_title.svg"
        class="agency-title"
      />
      <locale-switcher :locales="supportedLocales" :currentLocale="currentLocale" class="locale-switcher">{{
        $t("homepage.localeSwitcher.title")
      }}</locale-switcher>
      <v-row dense>
        <v-col class="d-flex justify-center">
          <span class="welcome-title">{{ $t("common.games.welcome") }}</span>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col class="d-flex justify-center">
          <span class="agent-title">{{ $t("common.games.agentName") }}</span>
        </v-col>
      </v-row>
      <v-row class="pt-4">
        <v-col class="d-flex justify-center">
          <span class="game-choice">{{ $t("common.games.caseChoice") }}</span>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col class="d-flex justify-center" cols="12">
          <img src="/common/assets/images/chevron.svg" class="first-chevron" />
          <img src="/common/assets/images/chevron.svg" class="second-chevron" />
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col
          v-for="game in currentGames"
          :key="game.id"
          class="d-flex justify-center"
          md="4"
          lg="2"
        >
          <v-item v-if="game.visible.includes(currentLocale)">
            <div
              class="game-card"
              :class="{ 'coming-soon': !game.available.includes(currentLocale) }"
              v-on="!game.available.includes(currentLocale) ? {} : { click: () => launchGame(game) }"
            >
              <v-img :src="getGameThumbnail(game)" class="game-thumb"></v-img>
              <div class="game-caption">
                <span>{{ $t(`${game.id}.title`) }}</span>
              </div>
              <v-overlay absolute v-if="!game.available.includes(currentLocale)">
                {{ $t("common.games.comingSoon") }}
              </v-overlay>
            </div>
          </v-item>
        </v-col>
      </v-row>
    </v-container>
  </v-item-group>
</template>
<script>
import LocaleSwitcher from "../components/LocaleSwitcher.vue";
import axios from "axios";

export default {
  name: "Games",
  data() {
    return {
      games: [],
      publicPath: process.env.BASE_URL,
    };
  },
  components: {
    LocaleSwitcher,
  },
  computed: {
    currentLocale: function() {
      return this.$i18n.locale;
    },
    supportedLocales: function() {
      return this.$store.getters.supportedLocales;
    },
    currentGames: function() {
      return this.games.filter(e => e.visible.includes(this.currentLocale));
    }
  },
  methods: {
    launchGame(game) {
      this.$router.push(game.route);
    },
    getGameThumbnail(game) {
      return `${this.publicPath}${game.id}/assets/images/thumbnail.jpg`;
    },
  },
  mounted() {
    this.$nextTick(function () {
      axios.get(`/common/data/games.json`).then(({ data }) => {
        this.games = data;
      });
    });
  },
};
</script>
<style scoped>
.locale-switcher {
  position: absolute;
  left: 40px;
  top: -30px;
  font-family: "Source Sans 3";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0.06em;
  margin-top: 50px;
  color: #ffffff;
  text-transform: uppercase;
}

.locale-switcher:hover {
  color: #ffc122;
}

.agency-logo {
  position: absolute;
  height: 77px;
  top: 58px;
  left: 50%;
  transform: translate(-50%, 0%);
  filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4));
}

.agency-title {
  position: absolute;
  height: 18px;
  top: 156px;
  left: 50%;
  transform: translate(-50%, 0%);
  filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4));
}

.welcome-title {
  font-family: Source Sans 3;
  font-size: 16px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
}

.agent-title {
  font-family: Bebas Neue;
  font-size: 28px;
  font-weight: 400;
  line-height: 34px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
}

.game-choice {
  font-family: Source Sans 3;
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
}

.first-chevron {
  transform: translate(50%, 0%);
}

.second-chevron {
  transform: translate(-50%, 100%);
}

.games-container {
  margin-top: 218px;
}

.game-card {
  position: relative;
  height: 301px;
  width: 192px;
  background: #ffc12247;
  padding-top: 25px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 34px;
  border: 1px solid #ffc122;
  border-radius: 9px;
  background: #ffc12247;
  box-shadow: 8px 6px 7px 0px #0000004d;
}
.game-card:not(.coming-soon) {
  cursor: pointer;
}
.game-card:not(.coming-soon):hover {
  box-shadow: 0px 0px 12px 0px #ffffff;
  border: 3px solid #ffffff;
}
.game-card.coming-soon {
  cursor: not-allowed;
}
.game-thumb {
  width: 167px;
  height: 167px;
  border-radius: 6px;
  margin: auto;
  margin-bottom: 28px;
}
.game-caption {
  padding: 6px;
  background: #000000;
  border-radius: 0% !important;
  font-family: Bebas Neue;
  font-size: 21px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.06em;
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
}
</style>