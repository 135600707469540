<template>
  <v-card class="mx-auto">
    <v-toolbar
      color="#3490CE"
      dark
      :class="{
        'webmail-banner-mobile': $vuetify.breakpoint.xsOnly,
        'webmail-banner': $vuetify.breakpoint.smAndUp,
      }"
    >
    </v-toolbar>
    <v-container v-if="locked">
      <v-row>
        <v-col cols="12">
          <h2 class="webmail-title">
            {{ $t("colossus.webmail.headlines.connection") }}
          </h2>
        </v-col>
        <v-col cols="12">
          <v-img
            src="~@/assets/images/colossus/webmail/user_icon.svg"
            class="user-icon"
          ></v-img>
        </v-col>
        <v-col cols="12">
          <h3 class="webmail-user">{{ fullName }}</h3>
        </v-col>
        <v-col cols="12">
          <div class="webmail-field">
            {{ email }}
          </div>
        </v-col>
        <v-col cols="12">
          <div class="webmail-field">
            <input
              v-model="password"
              type="password"
              :label="$t('colossus.webmail.labels.prompt')"
              @keypress.enter="login"
            />
          </div>
        </v-col>
        <v-col v-if="unlockError">
          <v-alert type="error" class="webmail-error">
            {{ errorMsg }}
          </v-alert>
        </v-col>
        <v-col cols="12" class="d-flex justify-center">
          <button @click="login" class="login-button">
            {{ $t("colossus.webmail.buttons.login") }}
          </button>
        </v-col>
      </v-row>
    </v-container>
    <v-list three-line v-else>
      <v-list-item-group v-model="selected" active-class="cyan--text">
        <div v-for="email in emails" :key="email.guid" class="email-container">
          <v-list-item @click="readEmail(email.guid)" class="email-item">
            <v-list-item-avatar :color="email.avatarColor">
              <span class="white--text text-h5">{{
                email.fromName | initial
              }}</span>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title v-html="email.fromName"></v-list-item-title>
              <v-list-item-subtitle
                v-html="email.subject"
              ></v-list-item-subtitle>
              <v-list-item-subtitle
                class="email-excerpt"
                v-text="
                  email.content
                    .replace(/<p[^>]*>/g, '')
                    .replace(/<\/p>/g, ' ')
                "
              >
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </div>
      </v-list-item-group>
    </v-list>
  </v-card>
</template>
<script>
import axios from "axios";
import currentGameMixin
 from "../../mixins/currentGameMixin";
export default {
  name: "Webmail",
  data: () => ({
    publicPath: process.env.BASE_URL,
    emails: [],
    selected: -1,
    password: "",
    unlockError: false,
  }),
  mixins: [currentGameMixin],
  computed: {
    locale: function () {
      return this.$i18n.locale;
    },    
    dataPath: function () {
      return `${this.publicPath}${this.caseID}/data`;
    },
    filePath: function() {
      return `${this.dataPath}/webmail_${ this.$i18n.locale }.json`;
    },
    email: function () {
      return this.$store.getters.colossusWebmailEmail;
    },
    fullName: function () {
      return this.$store.getters.colossusWebmailFullName;
    },
    locked: function () {
      return this.$store.getters.colossusWebmailLocked;
    },
    errorMsg: function () {
      return this.unlockError
        ? this.$t("colossus.webmail.messages.wrongPassword")
        : "";
    },
  },
  methods: {
    login: function () {
      this.$store.dispatch("colossusTryUnlockWebmail", {
        email: this.email,
        password: this.password,
        allowedPasswords: this.$i18n.t("colossus.webmail.passwords"),
      });
    },
    readEmail: function (guid) {
      this.$router.push({
        name: "colossus-webmail-reader",
        params: { guid: guid },
      });
    },
    getLocalisedMails: function () {
      axios.get(this.filePath).then(({ data }) => {
        this.emails = data;
      });
    },
  },
  watch: {
    password: function () {
      this.$store.dispatch("colossusResetWebmailError");
    },
    locale: function () {
      this.getLocalisedMails(); 
    },
  },
  mounted() {
    this.getLocalisedMails();

    this.unlockError = this.$store.getters.colossusWebmailError;

    this.$store.subscribe((mutation) => {
      if (
        mutation.type === "colossusSetWebmailError" ||
        mutation.type === "colossusResetWebmailError"
      )
        this.unlockError = this.$store.getters.colossusWebmailError;      
    });
  },
};
</script>
<style scoped>
.webmail-banner {
  width: 100%;
  background-image: url(~@/assets/images/colossus/webmail/pandamail_logo.svg);
  background-size: 30%;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  height: 0;
  padding: 0; /* reset */
  padding-bottom: calc(100% * 15 / 121);
}
.webmail-banner-mobile {
  width: 100%;
  background-image: url(~@/assets/images/colossus/webmail/pandamail_logo.svg);
  background-size: 50%;
  background-repeat: no-repeat;
  background-position: 10% 50%;
  height: 0;
  padding: 0; /* reset */
  padding-bottom: calc(100% * 15 / 121);
}
.webmail-title {
  font-family: Plus Jakarta Sans;
  font-size: 22px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0.025em;
  text-align: center;
  color: #3490ce;
  text-transform: uppercase;
}
.webmail-field {
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.025em;
  text-align: center;
  color: #000000;
  border: 1px solid #000000;
  padding: 10px;
  width: 80%;
  max-width: 300px;
  margin: 0 auto;
}
.webmail-error {
  max-width: 300px;
  margin: 0 auto;
}
.user-icon {
  width: 106px;
  margin: 0 auto;
}
.webmail-user {
  font-family: Plus Jakarta Sans;
  font-size: 19px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.025em;
  text-align: center;
  color: #000000;
}
.login-button {
  background: #3490ce;
  font-family: Plus Jakarta Sans;
  font-size: 17px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0.025em;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  padding: 7px 20px;
  margin-bottom: 25px;
}
.email-container {
  padding: 2px;
}
.email-item {
  background: #f0f0f0;
}
.email-excerpt {
  word-break: keep-all;
}
</style>