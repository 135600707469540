<template>
  <div>
    <player-info-panel
      :trackInfo="getTrackInfo"
      class="player-info"
    ></player-info-panel>
    <player-control-bars
      @playtrack="play"
      @pausetrack="pause"
      @stoptrack="stop"
      @skiptrack="skip"
      :loop="loop"
      @toggleloop="toggleLoop"
      :shuffle="shuffle"
      @toggleshuffle="toggleShuffle"
      :progress="progress"
      @updateseek="setSeek"
      class="player-controls"
      buttonColor="#FFC122"
    ></player-control-bars>
    <player-playlist-panel
      :playlist="playlist"
      :selectedTrack="selectedTrack"
      @selecttrack="selectTrack"
      class="playlist"
    ></player-playlist-panel>
  </div>
</template>

<script>
import PlayerPlaylistPanel from "../../components/maestro/PlayerPlaylistPanel.vue";
import PlayerControlBars from "../../components/maestro/PlayerControlBars.vue";
import PlayerInfoPanel from "../../components/maestro/PlayerInfoPanel.vue";
import { Howl } from "howler";
import currentGameMixin from "../../mixins/currentGameMixin";

export default {
  name: "AudioPlayer",
  mixins: [currentGameMixin],
  methods: {
    selectTrack(track) {
      this.selectedTrack = track;
    },
    handleClick(track) {
      this.currentTrack = track;
    },
    canPlay() {
      this.$refs.audioPlayer.play();
    },
    play(index) {
      let selectedTrackIndex = this.playlist.findIndex(
        (track) => track === this.selectedTrack
      );

      if (typeof index === "number") {
        index = 0 + index;
      } else if (this.selectedTrack) {
        if (this.selectedTrack != this.currentTrack) {
          this.stop();
        }
        index = selectedTrackIndex;
      } else {
        index = this.index;
      }

      let track = this.playlist[index].howl;

      if (track.playing()) {
        return;
      } else {
        track.play();
      }

      this.selectedTrack = this.playlist[index];
      this.playing = true;
      this.index = index;
    },
    pause() {
      this.currentTrack.howl.pause();
      this.playing = false;
    },
    stop() {
      this.currentTrack.howl.stop();
      this.playing = false;
    },
    skip(direction) {
      let index = 0;
      const lastIndex = this.playlist.length - 1;

      if (this.shuffle) {
        index = Math.round(Math.random() * lastIndex);
        while (index === this.index) {
          index = Math.round(Math.random() * lastIndex);
        }
      } else if (direction === "next") {
        index = this.index + 1;
        if (index >= this.playlist.length) {
          index = 0;
        }
      } else {
        index = this.index - 1;
        if (index < 0) {
          index = this.playlist.length - 1;
        }
      }

      this.skipTo(index);
    },
    skipTo(index) {
      if (this.currentTrack) {
        this.currentTrack.howl.stop();
      }

      this.play(index);
    },
    toggleLoop(value) {
      this.loop = value;
    },
    toggleShuffle(value) {
      this.shuffle = value;
    },
    setSeek(percents) {
      let track = this.currentTrack.howl;

      if (track.playing()) {
        track.seek((track.duration() / 100) * percents);
      }
    },
  },
  data() {
    return {
      index: 0,
      playing: false,
      headers: [
        {
          text: this.$t(`${this.caseID}.audioplayer.headers.title`),
          value: "title",
        },
        {
          text: this.$t(`${this.caseID}.audioplayer.headers.artist`),
          value: "artist",
        },
      ],
      playlist: [],
      selectedTrack: null,
      loop: false,
      shuffle: false,
      seek: 0,
      publicPath: process.env.BASE_URL,
    };
  },
  computed: {
    trackInfo() {
      if (this.currentTrack)
        return `${this.currentTrack.artist} - ${this.currentTrack.title}`;
      else return "";
    },
    currentFile() {
      return this.currentTrack ? this.currentTrack.file : "";
    },
    currentTrack() {
      return this.playlist[this.index];
    },
    progress() {
      if (this.currentTrack.howl.duration() === 0) return 0;
      return this.seek / this.currentTrack.howl.duration();
    },
    getTrackInfo() {
      let artist = this.currentTrack.artist,
        title = this.currentTrack.title,
        seek = this.seek,
        duration = this.currentTrack.howl.duration();
      return {
        artist,
        title,
        seek,
        duration,
      };
    },
    playlistPath: function () {
      return `${this.publicPath}${this.caseID}/assets/audio/playlist`;
    },
  },
  components: {
    PlayerPlaylistPanel,
    PlayerControlBars,
    PlayerInfoPanel,
  },
  created() {
    let db = require("@/data/maestro/tracks.json");

    db.forEach((entry) => {
      let track = {
        title: entry.title,
        artist: entry.artist,
        howl: null,
        display: true,
      };
      let file = entry.file; //.replace(/\s/g, "_");
      track.howl = new Howl({
        src: `${this.playlistPath}/${file}`,
        html5: true,
        onend: () => {
          if (this.loop) {
            this.play(this.index);
          } else {
            this.skip("next");
          }
        },
        onloaderror: (soundId, error) => {
          console.warn(error);
        },
      });
      this.playlist.push(track);
    });
  },
  watch: {
    playing(playing) {
      this.seek = this.currentTrack.howl.seek();
      let updateSeek;
      if (playing) {
        updateSeek = setInterval(() => {
          this.seek = this.currentTrack.howl.seek();
        }, 250);
      } else {
        clearInterval(updateSeek);
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    this.playlist.forEach((track) => {
      track.howl.stop();
    });

    next();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.player-info {
  background: #00000099;
  font-family: "Source Sans 3";
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 34px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
  text-transform: uppercase;
}

.player-controls {
  background: #00000099;
}

.playlist {
  margin-bottom: 50px;
}
</style>
