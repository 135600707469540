<template>
  <button @click.prevent="onClick" class="resolve-button"><slot></slot></button>
</template>
<script>
export default {
  methods: {
    onClick: function () {
      this.$emit("click");
    },
  },
  props: {
    checked: Boolean
  }
};
</script>
<style scoped>
.resolve-button {
  font-family: 'Bebas Neue';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0.06em;
  text-align: center;
  text-transform: uppercase;
  height: 40px;
  padding-left: 20px;
  padding-right: 20px;
  border: 1pt solid #ffc122;
  color: #ffc122;
  background: #00000000;
}

.resolve-button:hover {
  border: 1px solid #ffffff;
  box-shadow: 3px 4px 3px 0px #00000050;
  color: #ffffff;
}
</style>
