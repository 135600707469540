<template>
  <div class="places-container">
    <v-overlay :value="secretPlaceDialog">
      <div class="dialog-frame">
        <form @submit.prevent="tryUnlockSecretPlace">
          <div>
            <h1>{{ $t('ghosts.touristOffice.secretPlace.password') }}</h1>
            <input type="password" v-model="password" @input="resetSecretPlacesError()"/>
            <span v-if="isError">{{  $t('ghosts.touristOffice.secretPlace.wrongPassword') }}</span>
            <div>
              <button type="submit">{{ $t('common.buttons.ok') }}</button>
              <button @click="hideSecretPlaceDialog">{{ $t('common.buttons.cancel') }}</button>
            </div>
          </div>
        </form>
      </div>
    </v-overlay>
    <div class="place-wrapper" :class="{ 'mobile': isMobile }" v-for="place in places" :key="place.id">
      <div class="place-content">
        <h3>{{ place.name }}</h3>
        <div v-html="place.description"></div>
      </div>
      <div class="place-thumbnail-container" :class="{ 'clickable': place.interactive }"
        v-on="getPlaceEventHandlers(place)">
        <img :src="place.imageSrc" class="place-thumbnail" />
        <!-- <img v-if="place.interactive" src="../../assets/images/ghosts/tourist/magnify_icon.svg" class="place-action-icon"> -->
      </div>
    </div>
  </div>
</template> 
<script>
import currentGameMixin from "../../mixins/currentGameMixin";
import axios from "axios";

export default {
  name: "TouristOfficePlaces",
  data() {
    return {
      places: [],
      secretPlaceDialog: false,
      password: '',
      secretPlace: ''
    };
  },
  mixins: [currentGameMixin],
  computed: {
    placesSrc: function () {
      return `${this.casePublicData}/places.json`;
    },
    isMobile: function () {
      return this.$vuetify.breakpoint.mobile;
    },
    isSecretPlacesLocked: function () {
      return this.$store.getters.ghostsSecretPlacesLocked;
    },
    isError: function () {
      return this.$store.state.ghosts.touristOffice.secretPlaces.error;
    }
  },
  methods: {
    fetchPlaces: async function () {
      try {
        const { data } = await axios.get(this.placesSrc);

        this.places = await Promise.all(
          data.map(async (place) => {
            const desc = await this.fetchPlaceDesc(place.id);
            console.log(this.casePublicImages);

            return {
              id: place.id,
              name: this.$t(`ghosts.data.touristOffice.places.${place.id}.title`),
              description: desc,
              imageSrc: `${this.casePublicImages}/places/${place.id}.jpg`,
              interactive: !!place.goto,
              goto: place.goto
            };
          })
        );
      } catch (e) {
        console.error("Could not fetch places", e);
      }
    },
    fetchPlaceDesc: async function (place) {
      try {
        const { data } = await axios.get(
          `${this.casePublicHtml}/tourist-office/${place}_${this.$i18n.locale}.html`
        );
        return data;
      } catch (e) {
        return "";
      }
    },
    getPlaceEventHandlers: function (place) {
      if (place.interactive && place.goto) {
        return {
          click: () => {
            console.log(this.isSecretPlacesLocked);
            if (this.isSecretPlacesLocked) {
              this.showSecretPlaceDialog(place.goto);
            } else {
              this.gotoSecretPlace(place.goto)
            }
          }
        };
      }

      return {};
    },
    showSecretPlaceDialog: function (placeName) {
      this.secretPlace = placeName;
      this.secretPlaceDialog = true;
    },
    hideSecretPlaceDialog: async function () {
      this.secretPlaceDialog = false;
    },
    tryUnlockSecretPlace: function () {
      this.$store.dispatch('ghostsTryUnlockSecretPlaces', this.password);
    },
    resetSecretPlacesError: function () {
      this.$store.dispatch('ghostsResetSecretPlacesError');
    },
    gotoSecretPlace: function (name) {
      this.$router.push({ name: name })
    }
  },
  watch: {
    isSecretPlacesLocked: function (newValue, oldValue) {
      if (!newValue && !!oldValue) {
        this.gotoSecretPlace(this.secretPlace);
      }
    }
  },
  mounted() {
    this.fetchPlaces();
  },
};
</script>
<style scoped>
.places-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  font-family: Roboto;
  margin-top: 50px;
}

.place-wrapper {
  display: flex;
  align-items: center;
  gap: 20px;
}

.place-wrapper.mobile {
  flex-direction: column;
}

.place-content h3 {
  text-transform: uppercase;
  font-style: italic;
  font-size: 14px;
  font-weight: 500;
  color: #668e39;
  margin: 10px 0;
}

.place-content div {
  font-size: 12px;
  font-weight: 300;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
}

.place-content>div>p>strong {
  font-weight: 500;
}

.place-thumbnail-container {
  position: relative
}

.place-thumbnail-container.clickable {
  pointer-events: all;
  cursor: pointer;
}

.place-thumbnail {
  width: 237px;
  aspect-ratio: 1;
}

.place-action-icon {
  position: absolute;
  bottom: 14px;
  left: 18px;
}
</style>
<style>
.place-content>div>p>strong {
  font-weight: 500;
}

.dialog-frame {
  min-width: 300px;
}

.dialog-frame > form > div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dialog-frame > form > div > div {
  display: flex;
  justify-content: space-around;
  gap: 20px;
}
</style>
