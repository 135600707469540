<template>
  <v-container class="reader-container">
    <v-card class="mx-auto" tile>
      <v-card-actions class="reader-toolbar"
        ><router-link
          :to="{ name: 'colossus-webmail' }"
          class="reader-back-link"
          ><img
            src="~@/assets/images/colossus/webmail/back_icon.svg"
            class="reader-back-icon"
          />{{ $t("colossus.webmail.links.inbox") }}</router-link
        ></v-card-actions
      >
      <v-card-title
        ><h2 v-text="email.subject" class="email-subject"></h2
      ></v-card-title>
      <v-card-subtitle>
        <v-row>
          <v-col cols="2">
            <v-avatar :color="email.avatarColor"
              ><span class="white--text text-h5">{{
                email.fromName | initial
              }}</span></v-avatar
            >
          </v-col>
          <v-col>
            <v-container>
              <v-row dense>
                <v-col cols="12" class="email-header">
                  <strong
                    >{{ $t("colossus.webmail.labels.from") }}:&nbsp;{{
                      email.fromName
                    }}
                  </strong>
                </v-col>
                <v-col cols="12" class="email-value">
                  &lt;{{ email.from }}&gt;
                </v-col>
                <v-col class="email-header">
                  {{ $t("colossus.webmail.labels.to") }}:&nbsp;{{
                    email.toName
                  }}
                </v-col>
                <v-col cols="12" class="email-value">
                  &lt;{{ email.to }}&gt;
                </v-col>
                <v-col cols="12">
                  {{
                    getUTCDate(
                      new Date(Date.parse(email.timestamp))
                    ).toLocaleDateString(this.$i18n.locale, {
                      dateStyle: "medium",
                    })
                  }},&nbsp;
                  {{
                    getUTCDate(
                      new Date(Date.parse(email.timestamp))
                    ).toLocaleTimeString(this.$i18n.locale, {
                      timeStyle: "short",
                    })
                  }}
                </v-col>
              </v-row>
            </v-container>
          </v-col>
        </v-row>
      </v-card-subtitle>
      <v-card-text v-html="email.content" class="email-content"></v-card-text>
      <v-img :src="getSignature(email.from)" class="email-signature"></v-img>
      <template v-if="email.history">
        <v-container>
          <v-row
            v-for="(entry, index) in email.history"
            :key="`entry-${index}`"
            dense
          >
            <v-col cols="12">
              <v-divider></v-divider>
            </v-col>
            <v-col cols="12">
              <strong>
                <span class="entry-header">{{
                  $t("colossus.webmail.labels.from")
                }}</span
                >:</strong
              >&nbsp;<span class="entry-value"
                >{{ entry.fromName }}&nbsp;&lt;{{ entry.from }}&gt;</span
              >
            </v-col>
            <v-col cols="12">
              <span class="entry-header">{{
                $t("colossus.webmail.labels.sent")
              }}</span
              >:&nbsp;<span class="entry-value"
                >{{
                  getUTCDate(
                    new Date(Date.parse(entry.timestamp))
                  ).toLocaleDateString($i18n.locale, { dateStyle: "long" })
                }}
                &nbsp;{{
                  getUTCDate(
                    new Date(Date.parse(entry.timestamp))
                  ).toLocaleTimeString($i18n.locale, { timeStyle: "short" })
                }}</span
              >
            </v-col>
            <v-col cols="12">
              <span class="entry-header">{{
                $t("colossus.webmail.labels.to")
              }}</span
              >:&nbsp;<span class="entry-value"
                >{{ entry.toName }} >&nbsp;&lt;{{ entry.to }}&gt;</span
              >
            </v-col>
            <v-col cols="12">
              <span class="entry-header">{{
                $t("colossus.webmail.labels.subject")
              }}</span
              >:&nbsp;<span class="entry-value">{{ entry.subject }}</span>
            </v-col>
            <v-col cols="12" v-html="entry.content"></v-col>
            <v-col cols="12"
              ><v-img
                :src="getSignature(entry.from)"
                class="email-signature"
              ></v-img
            ></v-col>
          </v-row>
        </v-container>
      </template>
    </v-card>
  </v-container>
</template>
<script>
import axios from "axios";
import currentGameMixin from "../../mixins/currentGameMixin";

export default {
  data: () => ({
    publicPath: process.env.BASE_URL,
    email: {},
    selected: -1,
  }),
  mixins: [currentGameMixin],
  props: {
    guid: String,
  },
  computed: {
    locale: function () {
      return this.$i18n.locale;
    },    
    dataPath: function () {
      return `${this.publicPath}${this.caseID}/data`;
    },
    filePath: function() {
      return `${this.dataPath}/webmail_${ this.$i18n.locale }.json`;
    },    
    signaturePath: function () {
      return `${this.publicPath}${this.caseID}/assets/images/webmail/signatures`;
    },
  },
  watch: {
    locale: function () {
      this.getLocalisedMail(); 
    },
  },  
  methods: {
    getUTCDate: function (localeDate) {
      const timeDiff = localeDate.getTimezoneOffset() * 60000;
      const adjustDate = localeDate.valueOf() + timeDiff;
      return new Date(adjustDate);
    },
    getDomainFromEmailAddress: function (address) {
      return address.split("@")[1].split(".")[0];
    },
    getSignature: function (address) {
      return `${this.signaturePath}/${this.getDomainFromEmailAddress(
        address
      )}.svg`;
    },
    getLocalisedMail: function() {
      axios.get(this.filePath).then(({ data }) => {
        this.email = data.find((email) => email.guid === this.guid);
      });
    },
  },
  mounted() {
    this.getLocalisedMail();
  },
};
</script>
<style scoped>
.reader-container {
  border: 1px solid #ffffff;
  border-radius: 7px;
  padding: 19px;
}
.reader-toolbar {
  background: #dbdbdb;
}
.reader-back-link {
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}
.reader-back-icon {
  margin-left: 12px;
  margin-right: 10px;
  vertical-align: middle;
}
.email-header {
  font-family: Plus Jakarta Sans;
  font-size: 15px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
}
.email-subject {
  font-family: Plus Jakarta Sans;
  font-size: 20px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: center;
  word-break: break-word;
}
.email-value {
  font-family: Plus Jakarta Sans;
  font-size: 11px;
  font-style: italic;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
}
.email-content {
  font-family: Plus Jakarta Sans;
  font-size: 15px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
}
.email-signature {
  width: 80%;
  max-width: 250px;
  margin: 20px;
}
.entry-header {
  font-family: Plus Jakarta Sans;
  font-size: 15px;
  font-weight: bold;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
}
.entry-value {
  font-family: Plus Jakarta Sans;
  font-size: 11px;
  font-style: italic;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
}
</style>