export default {
    fr: {
        maestro:
        {
            safe: {
                buttons: {
                    ok: "OK"
                },
                messages: {
                    unlock1: "Coffre déverrouillé.",
                    unlock2: "Vous pouvez ouvrir l'enveloppe.",
                    error1: "CODE FAUX",
                    error2: "Veuillez réessayer"
                }
            }
        }
    },
    en: {
        maestro: {
            safe: {
                buttons: {
                    ok: "OK"
                },
                messages: {
                    unlock1: "Safe unlocked.",
                    unlock2: "You can open the envelope.",
                    error1: "WRONG CODE",
                    error2: "Please try again"
                }
            }
        }
    },
    de: {
        maestro: {
            safe: {
                buttons: {
                    ok: "OK"
                },
                messages: {
                    unlock1: "Der Safe ist entsperrt.",
                    unlock2: "Sie können den Umschlag öffnen.",
                    error1: "FALSCHER CODE",
                    error2: "Bitte versuchen Sie es erneut"
                }
            }
        }
    },
    es: {
        maestro: {
            safe: {
                buttons: {
                    ok: "OK"
                },
                messages: {
                    unlock1: "Caja fuerte abierta.",
                    unlock2: "Puede abrir el sobre.",
                    error1: "CÓDIGO INCORRECTO",
                    error2: "Por favor, inténtelo de nuevo"
                }
            }
        }
    },
    it: {
        maestro: {
            safe: {
                buttons: {
                    ok: "OK"
                },
                messages: {
                    unlock1: "Cassaforte sbloccata.",
                    unlock2: "Puoi aprire la busta.",
                    error1: "CODICE SBAGLIATO",
                    error2: "Si prega di riprovare"
                }
            }
        }
    }
}