<template>
    <div class="section-frame fill-height">
      <div class="suitcase-container">
        <v-overlay absolute opacity="0" :value="advertiseUnlock">
          <div class="dialog-frame unlock">
            <p>
              <span v-html="$t('festival.suitcase.unlock_desc')"></span>
            </p>
            <p></p>
          </div>
        </v-overlay>
        <v-overlay absolute opacity="0" :value="showLeftCode">
          <div class="dialog-frame left-code">
            <v-hover v-slot="{ hover }">
              <div class="suitcase-icon-close" :class="{ hovered: hover }" @click="onClickClose"></div>
            </v-hover>
            <label>{{ $t('festival.suitcase.left_code') }}</label>
            <svg-numeric-padlock
              :secretLength="3"
              secretChar="?"
              :isOk="!isLockedLeft"
              :isLocked="isLocked"
              :initCode="leftCode"
              @codeChange="onLeftPadlockCodeChange"
              class="padlock-ui"
              ref="padlock_left"
            >
            </svg-numeric-padlock>
          </div>
        </v-overlay>
        <v-overlay absolute opacity="0" :value="showRightCode">     
          <div class="dialog-frame right-code">
            <v-hover v-slot="{ hover }">
              <div class="suitcase-icon-close" :class="{ hovered: hover }" @click="onClickClose"></div>
            </v-hover>
            <label>{{ $t('festival.suitcase.right_code') }}</label>          
            <svg-numeric-padlock
              :secretLength="3"
              secretChar="?"
              :isOk="!isLockedRight"
              :isLocked="isLocked"            
              :initCode="rightCode"            
              @codeChange="onRightPadlockCodeChange"
              class="padlock-ui"
              ref="padlock_right"
            >
            </svg-numeric-padlock>          
          </div>
        </v-overlay>
        <v-overlay absolute opacity="0"  :value="showLabel">     
          <div class="dialog-frame label">
            <v-hover v-slot="{ hover }">
              <div class="suitcase-icon-close" :class="{ hovered: hover }" @click="onClickClose"></div>
            </v-hover>
            <div>
              {{ $t('festival.suitcase.label') }}
            </div>         
          </div>
        </v-overlay>             
        <div class="suitcase-image">
            <svg-suitcase
              :locked1="isLockedLeft"
              :locked2="isLockedRight">
            </svg-suitcase>
  
            <v-hover v-slot="{ hover }" v-if="!isAnyPanelShown" >
              <div class="suitcase-icon-eye leftcode" :class="{ hovered: hover }" @click="onClickLeftCode" ></div>
            </v-hover>
            <v-hover v-slot="{ hover }" v-if="!isAnyPanelShown">      
              <div class="suitcase-icon-eye rightcode" :class="{ hovered: hover }" @click="onClickRightCode"></div>
            </v-hover>
            <v-hover v-slot="{ hover }" v-if="!isAnyPanelShown">
              <div class="suitcase-icon-eye label" :class="{ hovered: hover }" @click="onClickLabel"></div>
            </v-hover>
        </div>
      </div>
      <audio :src="unlockSoundSrc" ref="unlockSound"></audio>
    </div>
  </template>
  <script>
  import SvgNumericPadlock from "@/components/festival/SvgNumericPadlock.vue";
  import SvgSuitcase from "@/components/festival/SvgSuitcase.vue";
  import currentGameMixin from "../../mixins/currentGameMixin";
  
  export default {
    name: "Suitcase",
    data() {
      return {
        sectionId: "suitcase",
        advertiseUnlock: false,
        showLeftCode: false,
        showRightCode: false,
        showLabel: false,
        timeout1: null,
        timeout2: null,
        leftCode: '',
        rightCode: '',
      };
    },
    components: {
      SvgNumericPadlock,
      SvgSuitcase,
    },  
    mixins: [currentGameMixin],
    computed: {
      isLocked: function() {
        return !this.$festivalStore.suitcase.solved;
      },
      isLockedLeft: function() {
        return ![this.$festivalStore.suitcase.solution1, this.$festivalStore.suitcase.solution2].includes(this.leftCode);
      },    
      isLockedRight: function() {
        return ![this.$festivalStore.suitcase.solution1, this.$festivalStore.suitcase.solution2].includes(this.rightCode);
      },
      isAnyPanelShown: function() {
        return this.showLeftCode || this.showRightCode || this.showLabel;
      },
      unlockSoundSrc: function() {
        return `${this.casePublicAudio}/unlock_sound.mp3`;
      },      
    },
    methods: {
      unlock: function() {
        this.$festivalStore.suitcase.solved = true;
        
        this.$festivalStore.enableEvidence('video');
        this.$festivalStore.enableEnvelope('telephone');
        this.$festivalStore.enableEnvelope('coffre');
        this.$festivalStore.saveState();
  
        this.onClickClose();
        this.playUnlockSound();
  
        setTimeout(() => {
          this.advertiseUnlock = true;
        }, 1000);
      },
      onClickLeftCode: function() {
        this.showLeftCode = true;
      },
      onClickRightCode: function() {
        this.showRightCode = true; 
      },
      onClickLabel: function() {
        this.showLabel = true;
      },
      onClickClose: function() {
        this.showLabel = false;
        this.showRightCode = false;
        this.showLeftCode = false;
      },
      playUnlockSound: function() {
        this.$refs.unlockSound.play();
      },    
      onLeftPadlockCodeChange(code) { 
        this.leftCode = code;
        if(this.timeout1) {
            clearTimeout(this.timeout1);
        }
        this.timeout1 = setTimeout(() => { 
            if (!this.isLockedLeft && !this.isLockedRight && this.leftCode != this.rightCode) {
                this.unlock();
            }
        }, 1000);
      },
      onRightPadlockCodeChange(code) {
        this.rightCode = code;
        if(this.timeout2) {
            clearTimeout(this.timeout2);
          }
          this.timeout2 = setTimeout(() => {
            if (!this.isLockedLeft && !this.isLockedRight && this.leftCode != this.rightCode) {
                this.unlock();
            }
          }, 1000);
      },    
    },
    mounted: async function() {
      if (!this.isLocked) {
        this.playUnlockSound();
        this.advertiseUnlock = true;
      }
    },
    watch: {
      isLocked: function(newValue, previousValue) {
        if (previousValue && !newValue) this.unlock();
      },
    },
  };
  </script>
  <style scoped>
  .section-frame {
    border: 0 !important;
    background: transparent !important;
  }
  .suitcase-container {
    background: transparent;
    position: relative;
    font-family: Arimo;
    padding-bottom: 30px;
  }
  .suitcase-image {
    width: 80%;
    position: relative;
    margin: auto;
    margin-top: 40px;
  }
  .suitcase-image img {
    width: 100%;
    position: relative;  
    display: block;
  }
  .suitcase-icon-eye {
    width: 45px;
    height: 45px;
    position: absolute !important;
    cursor: pointer;
    background-image: url(/festival/assets/images/suitcase/icone_oeil_55.svg);
  }
  .suitcase-icon-eye.hovered {
    background-image: url(/festival/assets/images/suitcase/icone_oeil_55_hover.svg);
  }
  .suitcase-icon-eye.leftcode {
    left: 14%;
    top: 60%;
  }
  .suitcase-icon-eye.rightcode {
    left: 80%;
    top: 60%;
  }
  .suitcase-icon-eye.label {
    left: 33%;
    top: 78%;
  }
  .suitcase-icon-close {
    width: 45px;
    height: 45px;
    position: absolute !important;
    cursor: pointer;
    background-image: url(/festival/assets/images/suitcase/icone_close.svg);
    top: 10px;
    right: 10px;
  }
  .suitcase-icon-close.hovered {
    background-image: url(/festival/assets/images/suitcase/icone_close_hover.svg);
  }
  .dialog-frame {
    border-radius: 0;
    width: 305px;
    height: 350px;
  }
  
  .dialog-frame.unlock {
    width: 100%;
    height: auto;
  }
  
  .dialog-frame label {
    margin-top: 20px;
    font-family: 'Bebas Neue';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 22px;
  }
  
  .dialog-frame .padlock-ui {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  
  .dialog-frame.label {
    min-width: 205px !important;
    width: 205px;
    height: 250px;
    font-family: Arimo;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    text-transform: none;
    padding-top: 65px;
  }
  </style>
  