<template>
  <div :class="`phone-container ${ shake ? 'shake-horizontal' : ''}`">
    <svg
      id="Calque_1"
      data-name="Calque 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 540 1000"
    >
      <g transform="rotate(0 0 0) translate(0 50)">
        <g id="telephone_deverrouillé">
          <g id="Lignes_téléphone" data-name="Lignes téléphone">
            <path
              class="cls-1"
              d="M4.5,625.5V355.4c0-3.2.8-6.5-3.2-8.4-.9-.4-1.2-2.9-1.2-4.5C0,322.9,0,303.3.2,283.7c0-2,1.8-4,2.7-6,.6-1.4,1.7-2.9,1.6-4.2-.4-5.4,2.4-11.8-3.8-16-.8-.5-.6-2.8-.6-4.2,0-19.2-.1-38.5.1-57.8,0-2.2,1.9-4.3,2.8-6.4a15.16,15.16,0,0,0,1.5-5c.2-5.7.2-11.4,0-17-.1-4,1.4-8.4-3.5-11.1-.9-.5-.9-3-.9-4.6-.1-8.1,0-16.1,0-24.2,0-3.6-.7-7.3,3.5-9.6.9-.5.9-3.3.9-5.1.1-18.3,0-36.7,0-55C4.6,24.4,28.9,0,61.8,0H401.2c32.5,0,57.1,24.4,57.2,57,.2,48.8,0,97.6.1,146.3,0,3.4-.8,6.8,2.9,9.3,1.1.8,1.1,3.7,1.1,5.7.1,33.6.1,67.1-.1,100.7,0,2.4-1.7,4.9-2.6,7.3a15.07,15.07,0,0,0-1.3,3.3,50.48,50.48,0,0,0-.1,5.5v555c0,28-15.8,50.1-41.1,57a63.83,63.83,0,0,1-16.8,2q-169.2.15-338.3.1c-33.5,0-57.6-24-57.7-57.5ZM7.2,473.9v418c0,30.6,22.5,54.5,52.3,54.5q171.9.3,343.8-.1a52.61,52.61,0,0,0,21.7-5c21.3-10.2,30.9-28.1,30.9-51.5V58.2c0-32.3-23.3-55.5-55.5-55.5q-169.2,0-338.3.1A65.12,65.12,0,0,0,46.9,4.6C22.8,10.9,7.3,31.8,7.3,57.6Q7.15,265.65,7.2,473.9Zm-4-129.3h.7V281.2H3.2Zm0-89.1H4V192.9H3.1Zm456.3-39.9h-.6V321.9h.6ZM4,122.4H3.3V154H4Z"
              transform="translate(-0.03)"
            />
            <path
              class="cls-1"
              d="M444.4,474.8c0,137.5-.3,275,.3,412.6.1,25.9-18.9,47.3-47.2,47.2-111.5-.5-223-.2-334.5-.2-21.1,0-38.1-12.6-43.3-32.2a51.67,51.67,0,0,1-1.6-13.6q-.15-414.15,0-828c0-27,18.5-45.6,45.6-45.8,14.7-.1,29.3.2,44-.1,5.8-.1,8.4,2.3,9.3,7.7a104.7,104.7,0,0,0,2.5,12.4c2.3,8,7.8,12.7,16,13.8a71.74,71.74,0,0,0,10.4.8H317a63.7,63.7,0,0,0,9.8-.7c9.4-1.4,14.7-6.3,16.8-15.7,1-4.1,1.5-8.3,2.3-12.4.7-3.4,2.6-5.7,6.4-5.7,18,.1,36-.6,53.9.6,21.4,1.4,37.8,19.8,38.2,41.2.3,12.3.1,24.6.1,36.8Zm-2.6.3V60.4c0-25.7-17-42.6-42.8-42.7H363.2c-13.7,0-13.7,0-16.5,13.1a45.8,45.8,0,0,1-1.4,5.3c-2.6,8.5-8.4,13.6-17.2,15a60,60,0,0,1-10.4.9H145a65.89,65.89,0,0,1-10.9-.9c-8.7-1.5-14.6-6.5-17.2-15a87.85,87.85,0,0,1-2.5-12.4c-.6-4.3-2.4-6.2-7.2-6.1-14.7.3-29.3,0-44,.1-25.1.1-42.6,17.5-42.6,42.6V889.2c0,24.8,17.6,42.3,42.2,42.4H398.9a48.43,48.43,0,0,0,12-1.2c18.8-4.6,30.7-20.5,30.7-41Q441.9,682.1,441.8,475.1Z"
              transform="translate(-0.03)"
            />
            <path
              class="cls-1"
              d="M222.1,37.2H196.3c-3.4,0-6.5-.9-6.5-4.9s3.1-4.9,6.6-4.9h52.1c3.5,0,6.4.9,6.4,5s-3.2,4.8-6.5,4.8Zm.1-7.1c-8.2,0-16.4-.1-24.7.1-1.7,0-3.3,1.3-5,1.9,1.6.8,3.1,2.2,4.7,2.2q25.2.3,50.4,0c1.5,0,3-1.3,4.4-2-1.6-.8-3.1-2.2-4.7-2.2C239.1,30,230.6,30.1,222.2,30.1Z"
              transform="translate(-0.03)"
            />
            <path
              class="cls-1"
              d="M281.6,24.4a8.39,8.39,0,0,0-8.2,7.9,8.16,8.16,0,0,0,8,8,7.94,7.94,0,0,0,8.1-7.8A8.09,8.09,0,0,0,281.6,24.4Zm-.2,11c-1-1.2-2.8-2.2-3-3.5-.1-.9,1.8-2.1,3.5-4,1.2,2.1,2.6,3.5,2.4,4.6s-1.8,1.9-2.9,2.9Z"
              transform="translate(-0.03)"
            />
          </g>
        </g>
      </g>
    </svg>
    <canvas id="pattern-lock"></canvas>
  </div>
</template>
<script>
import PatternLock from '@phenax/pattern-lock-js';

export default {
  name: "SvgPhonePattern",
  data() {
    return {
      lock: null,
      shake: false,
      pin: [],
      dialNumber: [],
      solved: false,
      correctHash: '',
    };
  },
  props: {
    locked: Boolean,
    error: Boolean,
  },
  computed: {
  },
  methods: {
    success: function () {
      this.lock.setThemeState('success');
      this.solved = true;
      this.$emit('onSolved');
    },
    failure: function () {
      if (this.lock.selectedNodes.length > 0)
      {
        this.lock.setInitialState();
        this.lock.coordinates = null;
        this.lock.renderLoop(false);
        this.lock._isDragging = false;
        this.shake = true;
        setTimeout(() => {
          this.shake = false;
        }, 1000);       
      }

    },
  },
  watch: {
    error: function (newValue) {
      if (newValue) this.resetPin();
    },
  },
  mounted() {
    this.correctHash = this.$festivalStore.telephone.correctHash;

    this.lock = PatternLock({
        $canvas: document.querySelector('#pattern-lock'),
        width: 300,
        height: 430,
        grid: [ 3, 3 ],
    });

    this.lock.onComplete(({ hash }) => (this.correctHash === hash) ? this.success() : this.failure());

    this.lock.setTheme({
      default: {
        colors: {
            accent: '#ae64cd',     // Accent color for node
            primary: '#ffffff',    // Primary node and line color
            bg: '#2c3e5000',         // Canvas background color
        },
        dimens: {
            node_radius: 20,       // Radius of the outer ring of a node
            line_width: 10,         // Thickness of the line joining nodes
            node_core: 10,          // Radius of the inner circle of a node
            node_ring: 2,          // Outer ring thickness
        }
      },
      success: {
        colors: {
          accent: '#51e980',     // Green accent on successful match
        }
      },
      failure: {
        colors: {
          accent: '#e74c3c',     // Red accent on an unsuccessful match
        }
      },
      customState: {                 // Your custom state
          dimens: {
              node_radius: 25,       // Increases the node radius
          }
      },
    });
  }
}
</script>
<style scoped>
.phone-container {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  position:relative;
}
.cls-1 {
  fill: #fff;
  stroke-width: 1px;
}
#pattern-lock {
  position: absolute;
  left: -20px;
  top:80px;
}

.shake-horizontal{-webkit-animation:shake-horizontal .6s cubic-bezier(.455,.03,.515,.955) both;animation:shake-horizontal .6s cubic-bezier(.455,.03,.515,.955) both}
@-webkit-keyframes shake-horizontal{0%,100%{-webkit-transform:translateX(0);transform:translateX(0)}10%,30%,50%,70%{-webkit-transform:translateX(-10px);transform:translateX(-10px)}20%,40%,60%{-webkit-transform:translateX(10px);transform:translateX(10px)}80%{-webkit-transform:translateX(8px);transform:translateX(8px)}90%{-webkit-transform:translateX(-8px);transform:translateX(-8px)}}@keyframes shake-horizontal{0%,100%{-webkit-transform:translateX(0);transform:translateX(0)}10%,30%,50%,70%{-webkit-transform:translateX(-10px);transform:translateX(-10px)}20%,40%,60%{-webkit-transform:translateX(10px);transform:translateX(10px)}80%{-webkit-transform:translateX(8px);transform:translateX(8px)}90%{-webkit-transform:translateX(-8px);transform:translateX(-8px)}}


</style>
