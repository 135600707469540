<template>
  <v-row no-gutters>
    <v-col>
      <img src="/maestro/assets/images/police/logo1.svg" class="logo1" />
      <img src="/maestro/assets/images/police/logo2.svg" class="logo2" />
    </v-col>
  </v-row>
</template>

<script>
import { isPoliceLoggedIn, logoutPolice } from "@/utils";

export default {
  name: "PoliceHeader",
  methods: {
    loggedIn: function () {
      return isPoliceLoggedIn();
    },
    logout: function () {
      logoutPolice();
      this.$router.go();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.logo1 {
  width: 100px;
  float: left;
  margin-left: 10px;
}

.logo2 {
  width: 150px;
  float: right;
  margin-right: 10px;
}
</style>
