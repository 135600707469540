<template>
  <destination-template
    :location="$t('mykonos.research.labels.location')"
    :coordinates="destination.coords"
    :title="$t('mykonos.data.destinations.casino.title')"
    :returnRoute="{ path: '.' }"
  >
    <template v-slot:icon
      ><img src="@/assets/images/mykonos/destinations/casino_pinpoint.svg" />
      ></template
    >
    <template v-slot:thumbnail
      ><v-img
        src="@/assets/images/mykonos/destinations/casino_thumbnail.jpg"
      ></v-img
    ></template>
    <template v-slot:ui>
      <v-container v-if="!destination.searched">
        <v-row>
          <v-col class="d-flex justify-center ui-message">
            {{ $t("mykonos.data.destinations.casino.messages.personSearch") }}
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex justify-center">
            <input
              type="text"
              v-model="personName"
              :placeholder="
                $t('mykonos.data.destinations.casino.labels.personName')
              "
              class="text-box"
              @keypress.enter="trySearch"
            />
          </v-col>
        </v-row>
        <v-row v-if="destination.errors.length > 0">
          <v-col class="d-flex justify-center ui-message error-message">
            {{ $t("mykonos.data.destinations.casino.messages.searchError") }}
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex justify-center">
            <button @click="trySearch" class="submit-button">
              {{ $t("mykonos.research.buttons.submit") }}
            </button>
          </v-col>
        </v-row>
      </v-container>
      <template v-else>
        <v-row
          dense
          v-for="(withdrawal, index) in withdrawals"
          :key="`w-${index}`"
          class="ui-message data-record"
        >
          <v-col class="d-flex justify-center">
            {{ getLocaleDateString(withdrawal.timestamp) }}
          </v-col>
          <v-col class="d-flex justify-center">
            {{ getLocaleTimeString(withdrawal.timestamp) }}
          </v-col>
          <v-col class="d-flex justify-center">
            {{
              $t(`mykonos.data.destinations.casino.labels.${withdrawal.type}`)
            }}
          </v-col>
          <v-col class="d-flex justify-center">
            {{ withdrawal.amount }} €
          </v-col>
        </v-row>
      </template>
    </template>
  </destination-template>
</template>
<script>
import DestinationTemplate from "../../components/mykonos/DestinationTemplate.vue";
import axios from "axios";
import currentGameMixin from "../../mixins/currentGameMixin";

export default {
  name: "Casino",
  data() {
    return {
      destinationID: "casino",
      publicPath: process.env.BASE_URL,
      personName: "",
      withdrawals: [],
    };
  },
  mixins: [currentGameMixin],
  computed: {
    destination: function () {
      return this.$store.getters.mykonosDestination(this.destinationID);
    },
    dataPath: function () {
      return `${this.publicPath}${this.caseID}/data/withdrawals.json`;
    },
  },
  components: {
    DestinationTemplate,
  },
  methods: {
    trySearch: function () {
      this.$store.dispatch("mykonosTrySearchCasino", this.personName);
    },
    resetSearchError: function () {
      this.$store.dispatch("mykonosResetDestinationErrors", this.destinationID);
    },
    getLocaleDateString: function (timestamp) {
      return new Date(Date.parse(timestamp)).toLocaleDateString();
    },
    getLocaleTimeString: function (timestamp) {
      return new Date(Date.parse(timestamp)).toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      });
    },
  },
  mounted() {
    const vm = this;

    this.$nextTick(() => {
      axios.get(vm.dataPath).then(({ data }) => {
        this.withdrawals = data;
      });
    });
  },
  watch: {
    personName: function () {
      this.resetSearchError();
    },
  },
};
</script>
<style scoped>
.ui-message {
  font-family: Source Sans 3;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.06em;
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
}

.text-box {
  background: #00000080;
  border: none;
  border-radius: 0%;
  height: 27px;
  min-width: 200px;
  font-family: Source Sans 3;
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.06em;
  text-align: left;
  text-transform: uppercase;
  color: #ffffff;
}

.submit-button {
  color: #ffc122;
  border: 1px solid #ffc122;
  font-family: Bebas Neue;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0.06em;
  text-align: center;
  text-transform: uppercase;
  width: 128px;
  height: 36px;
}

.error-message {
  border: 1px solid #ff2900;
  color: #ff2900;
  padding: 10px;
}

.data-record {
  white-space: nowrap;
}
</style>