<template>
<div class="dc-short-text">
    <subscribe :caseID="caseID" v-if="allowedLocales.includes('it')"></subscribe>
    <h2>Soluzione</h2>
    <p>
        Il 12 luglio 2024, al Grand Hastur Hotel di Amsterdam, nel corso della serata inaugurale per il suo
nuovo mandato, Jasper Van der Meer muore avvelenato subito dopo aver pronunciato il suo
discorso e fatto un brindisi. La morte è stata orchestrata da sua moglie, Lucy Van der Meer, con
l&#39;aiuto di Koen, responsabile del servizio in camera e del parcheggio dell&#39;hotel. Completamente
sottomesso alla volontà di Lucy e fiducioso di ottenere una promozione (<i>Testimonianza di Sophie
Beaumont</i>), Koen segue alla lettera gli ordini della donna. Dal canto suo, Lucy vive sottomessa a
un marito violento. La sua ferita all&#39;occhio, nascosta con il trucco da Eliza, la giornalista venuta per
l&#39;intervista del reportage sulla &quot;donna più potente di Amsterdam&quot;, testimonia gli abusi fisici di
Jasper (<i>Testimonianza di Eliza Janssen, Articolo di Gerald Peters</i>). Ma più ancora delle violenze, è
la paura di essere scoperta che ha spinto Lucy ad agire. Jasper sta per scoprire che è stata lei a
fare soffiate alla stampa per compromettere la sua carriera (<i>Telefono di Lucy</i>).       
    </p>

<p>
    Il giornalista Gerald Peters, caro amico di Lucy, si appresta a pubblicare la sua testimonianza
    anonima in un articolo che metterà in luce gli abusi di potere e la condotta criminosa del sindaco.
    Tuttavia, poco prima della pubblicazione completa, un trafiletto che annuncia queste rivelazioni ha
    messo in allerta Jasper (<i>Articolo di Gerald Peters</i>). Furioso e deciso a salvare la sua reputazione,
    Jasper ha fatto rapire Gerald e lo tiene nascosto nel casale di famiglia, che ha ereditato da sua
    madre, per impedirgli di pubblicare l&#39;articolo. Jasper ha intenzione di inviare suo fratello Dirk al
    casale per eliminare Gerald, ma questo comprometterebbe Lucy e rovinerebbe il suo piano
    (<i>Registrazione audio, Telefono di Lucy, Atto di proprietà</i>). Sentendo che il tempo stringe, Lucy
    decide di uccidere Jasper prima che scopra la verità e si rivolti contro di lei.
</p>
<p>
    Il piano consiste nell&#39;incastrare Dirk piazzando delle cialde avvelenate e delle tracce di veleno sul
    letto della sua suite (<i>Foto della stanza delle rose, Testimonianza di Maria ore 19:30</i>). Jasper,
    amante del White Russian (<i>Menù dei cocktail, Foto del bicchiere del sindaco, Post sui social di Lucy</i>), 
    ha richiesto questo cocktail nel corso della serata. Una volta combinato il veleno con la
    caffeina presente nel cocktail, questo diviene mortale (<i>Analisi tossicologica</i>). Le finte prove
    piazzate nella stanza di Dirk dovrebbero far credere che sia lui il responsabile dell&#39;avvelenamento.
    Lucy ha cercato di attizzare la rivalità fra i due fratelli, in particolare riguardo all&#39;eredità del casale
    di famiglia e dei debiti che Dirk ha contratto con Jasper. Inoltre, ha instillato il seme del dubbio
    attraverso gli scambi SMS, insinuando che Dirk potrebbe tradire suo fratello (<i>Telefono di Lucy</i>).
</p>
<p>
    Il piano però fallisce quando Dirk mostra a Jasper una pagina che ha strappato dal libro degli ospiti
    dell&#39;hotel, che dimostra che Lucy conosce bene Gerald Peters (<i>Pagina del libro degli ospiti,
    Testimonianza di Joris Boor</i>). Furioso, Jasper decide di mandare Dirk al casale per uccidere
    Gerald, costringendo Dirk ad annullare la prenotazione della sua stanza nell&#39;hotel e rischiando di
    mandare all&#39;aria tutta la messa in scena (<i>Telefono di Lucy, Testimonianza di Dirk, Testimonianza
    di Maria ore 21:15, Schema check-in/servizio in camera</i>). Lucy, presa dal panico, ordina allora a
    Koen di pulire la suite di Dirk e spostare le prove nel bagagliaio della sua auto. Questo ordine
    viene trasmesso con un messaggio scritto sull&#39;ombrellino del suo cocktail (<i>Videosorveglianza della
    reception, Foto dell&#39;ombrellino</i>). Koen obbedisce: piazza le tracce di veleno e le briciole delle cialde
    nel veicolo di Dirk nel parcheggio, abbandonando il suo posto in reception (<i>Foto del bagagliaio
    dell&#39;auto, Testimonianza di Maria ore 21:05</i>).
</p>
<p>
    Dopo aver fatto visita alla suite di Jasper, Dirk si reca nella stanza della loro madre, in hotel. Da lì,
    scatta delle foto compromettenti di Katarina, la capa del partito degli Incorruttibili, insieme a un
    cameriere dell&#39;hotel (<i>Testimonianza di Maria ore 21:15</i>). Questi scatti dovrebbero permettergli di
    fare pressione su Katarina, che minaccia di rivelare gli scandali di corruzione dei fratelli Van der
    Meer. Il microfono dimenticato dalla giornalista Eliza Janssen registra una conversazione fra Dirk e
    Katarina. Durante questo scambio, Katarina conferma di essere al corrente dei crimini dei due
    fratelli e di essere pronta a tutto per rivelarli (<i>Registrazione audio</i>).
</p>

<p>
    Sophie, addetta al servizio in camera, consegna le cialde avvelenate ignorandone però il
    contenuto. Koen ha infatti segretamente posato la scatola avvelenata sul suo carrello
    <i>(Testimonianza di Sophie</i>). Koen le chiede inoltre di pulire la stanza delle rose, inizialmente
    riservata a Dirk, ma la ragazza non ha tempo di occuparsene. Un cliente scontento interrompe il
    suo lavoro per lamentarsi dell&#39;assenza di risposta alle chiamate in reception, dato che infatti Koen
    è occupato a piazzare finte prove nella macchina di Dirk (<i>Telefono di Sophie, Testimonianza di
    Sophie, Schema check-in/servizio in camera</i>).
</p>
<p>
    Il piano di Lucy avrebbe potuto riuscire, ma la testimonianza della madre di Jasper e Dirk, che ha
    osservato la serata dalla finestra della sua stanza, fa venire alla luce delle incoerenze. Sostiene di
    aver visto Koen entrare nella stanza delle rose con una scatola di cialde prima del cocktail party
    (<i>Testimonianza di Maria ore 19:30</i>) e lo sorprende più tardi chinato nel bagagliaio della macchina
    di Dirk (Testimonianza di Maria ore 21:05). Questi elementi, incrociati con le altre testimonianze e
    gli altri indizi, permettono di dedurre che la morte di Jasper Van der Meer non è accidentale ma il
    risultato di un complotto orchestrato da Lucy, con l&#39;aiuto di Koen.
</p>
</div>
</template>

<script>
import config from '@/config/';
import Cases from "@/enums/Cases";
import currentGameMixin from "../../mixins/currentGameMixin";
import Subscribe from "@/components/Subscribe.vue";  

export default {
  name: "JasperVanDerMeer",
  components: { Subscribe },
  mixins: [currentGameMixin],
  computed: {
    allowedLocales: function() {
      return config.common.showSubscribeForLocales
    },  
  },
  watch: { },
  mounted() {
    this.$i18n.locale = "it";
  },
  created() {
    this.setCurrentGame(Cases.JASPERVANDERMEER);
  },
}
</script>

<style scoped>
.dc-short-text {
    color: #fff;
    padding: 30px;
    text-align: center;
}
</style>