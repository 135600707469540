<template>
  <div>
    <v-dialog v-model="dialog" persistent width="500" max-width="100%">
      <template v-slot:activator="{ on }">
        <button v-on="on" class="activator-button">
          <v-img src="/common/assets/images/reset.svg" class="icon"/>
          <span><slot></slot></span>
        </button>
      </template>
      <div class="dialog-frame">
        <div class="reset-title">
          <span v-text="$t('homepage.reset.title')"></span>
        </div>
        <div class="dialog-text">
        <p>
          {{ $t("homepage.reset.areYouSure") }}
        </p>
        <p>
          {{ $t("homepage.reset.lostProgression") }}
        </p>
        </div>
        <v-row>
          <v-col class="d-flex justify-center">
            <button text @click="reset" class="dialog-button">
              {{ $t("homepage.reset.yes") }}
            </button>
          </v-col>
          <v-col class="d-flex justify-center">
            <button
              color="green darken-1"
              text
              @click="dialog = false"
              class="dialog-button"
            >
              {{ $t("homepage.reset.no") }}
            </button>
          </v-col>
        </v-row>
      </div>
    </v-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      dialog: false,
    };
  },
  props: {
    gameID: String
  },
  methods: {
    reset: function () {
      if(this.gameID === 'yakuza') {
        this.$yakuzaStore.resetState();
      } else if(this.gameID === 'festival') {
        this.$festivalStore.resetState();
      } else {
        this.$store.dispatch("resetGame", this.gameID);
      }
      this.dialog = false;
      this.$emit("reset");
      this.$router.push({name: `${this.gameID}-home`});
    },
  },
};
</script>
<style scoped>
.icon {
  width: 24px;
  float: left;
  margin-right: 10px;
}

.activator-button {
  text-transform: inherit;
}

.reset-title {
  font-family: 'Bebas Neue';
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: center;
  text-transform: uppercase;
  color: #ffc122;
  margin-bottom: 39px;
}

.dialog-text {
  font-family: Source Sans 3;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 20px;
letter-spacing: 0em;
text-align: center;
text-transform: uppercase;
color: #ffffff;
}

.dialog-button {
  font-family: Source Sans 3;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
  margin-top: 42px;
}

.dialog-button:hover {
  color: #ffc122;
}
</style>
