<template>
  <div class="grow container coffre-container">
    <SvgSafe 
      :correctCode = "$festivalStore.coffre.correctCode"
      @onCodeSuccess="win()"
      class="safe"
    ></SvgSafe>
    <v-overlay absolute opacity="0" :value="solved">
        <div class="dialog-frame unlock">
        <p>
            <span v-html="$t('festival.coffre.unlock_desc')"></span>
        </p>
        <p></p>
        </div>
    </v-overlay>
    <Sound :src="`${casePublicAudio}/win.mp3`" ref="successSound"></Sound>    
  </div>
</template>
  
<script>
  import Sound from "@/components/festival/Sound";
  import SvgSafe from "@/components/festival/SvgSafe";
  import currentGameMixin from "@/mixins/currentGameMixin";
  
  export default {
    name: "Coffre",

    components: {
      SvgSafe,
      Sound
    },

    mixins: [currentGameMixin],

    data: () => ({
        solved: false,
    }),
    methods: { 
        win: function() {
            this.solved = true;
            this.$refs.successSound.play();
        }
    },
    computed: {  },
    watch: { },
    mounted() { 
        if (this.$festivalStore.coffre.solved) { this.win(); }
    }
  };
</script>

<style lang="scss" scoped>
  .coffre-container {
    position: relative;
  }

  .safe {
    width: 600px;
    margin: auto;
  }

  .dialog-frame {
    border-radius: 0;
    width: 805px;
    height: 350px;
  }
  
  .dialog-frame.unlock {
    width: 100%;
    height: auto;
  }
  
  .dialog-frame label {
    margin-top: 20px;
    font-family: 'Bebas Neue';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 22px;
  }
</style>
  