<template>
  <destination-template
    :location="$t('mykonos.research.labels.location')"
    :coordinates="destination.coords"
    :title="$t('mykonos.data.destinations.sanGiovani.title')"
    :returnRoute="{ path: '.' }"
  >
    <template v-slot:icon
      ><img
        src="@/assets/images/mykonos/destinations/sangiovani_pinpoint.svg"
      />
      ></template
    >
    <template v-slot:thumbnail
      ><v-img
        src="@/assets/images/mykonos/destinations/sangiovani_thumbnail.jpg"
      ></v-img
    ></template>
    <template v-slot:ui>
      <v-container v-if="!destination.searched">
        <v-row>
          <v-col class="d-flex justify-center ui-message">
            {{
              $t("mykonos.data.destinations.sanGiovani.messages.searchPrompt")
            }}
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex justify-center">
            <select class="search-list ui-message" v-model="selectedSpot">
              <option disabled value="">
                --&nbsp;{{
                  $t(
                    "mykonos.data.destinations.sanGiovani.messages.selectAnItem"
                  )
                }}&nbsp;--
              </option>
              <option
                v-for="(spot, index) in searchSpots"
                :key="`spot-${index}`"
                class="search-item ui-message"
                :value="spot"
                @change="resetSearchError"
              >
                {{ $t(`mykonos.data.destinations.sanGiovani.items.${spot}`) }}
              </option>
            </select>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex justify-center">
            <button
              @click="trySearch"
              class="submit-button"
              :disabled="selectedSpot === ''"
            >
              {{ $t("mykonos.research.buttons.submit") }}
            </button>
          </v-col>
        </v-row>
        <v-row v-if="destination.errors.length > 0">
          <v-col class="d-flax justify-center ui-message error-message">
            {{
              $t("mykonos.data.destinations.sanGiovani.messages.nothingFound")
            }}
          </v-col>
        </v-row>
      </v-container>
      <template v-else>
        <v-col cols="12" class="d-flex justify-center">
          <div class="envelope-message">
            {{ $t("mykonos.research.messages.envelopeUnlocked") }}&nbsp;{{
              $t("mykonos.data.destinations.sanGiovani.envelope")
            }}
          </div>
        </v-col>
      </template>
    </template>
  </destination-template>
</template>
<script>
import DestinationTemplate from "../../components/mykonos/DestinationTemplate.vue";
import axios from "axios";
import currentGameMixin from "../../mixins/currentGameMixin";

export default {
  name: "SanGiovani",
  data() {
    return {
      destinationID: "sanGiovani",
      publicPath: process.env.BASE_URL,
      searchSpots: [],
      selectedSpot: "",
    };
  },
  mixins: [currentGameMixin],
  computed: {
    destination: function () {
      return this.$store.getters.mykonosDestination(this.destinationID);
    },
    dataPath: function () {
      return `${this.publicPath}${this.caseID}/data/sanGiovani.json`;
    },
  },
  components: {
    DestinationTemplate,
  },
  methods: {
    trySearch: function () {
      this.$store.dispatch("mykonosTrySearchSanGiovani", this.selectedSpot);
    },
    resetSearchError: function () {
      this.$store.dispatch("mykonosResetDestinationErrors", this.destinationID);
    },
  },
  mounted() {
    const vm = this;

    this.$nextTick(() => {
      axios.get(vm.dataPath).then(({ data }) => {
        data.forEach((spot) => this.searchSpots.push(spot));
      });
    });
  },
  watch: {
    selectedSpot: function () {
      this.$store.dispatch("mykonosResetDestinationError", this.destinationID);
    },
  },
};
</script>
<style scoped>
.ui-message {
  font-family: Source Sans 3;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.06em;
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
}

.search-list {
  background: #00000080;
  padding: 10px;
}

.search-item {
  background: #00000080;
}

.submit-button {
  color: #ffc122;
  border: 1px solid #ffc122;
  font-family: Bebas Neue;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0.06em;
  text-align: center;
  text-transform: uppercase;
  width: 128px;
  height: 36px;
}

.envelope-message {
  font-family: Bebas Neue;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0.06em;
  text-align: center;
  text-transform: uppercase;
  color: #00ff29;
  border: 1px solid #00ff29;
  padding: 20px;
}

.error-message {
  border: 1px solid #ff2900;
  color: #ff2900;
  padding: 10px;
}
</style>