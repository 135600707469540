const Cases = Object.freeze({
    COLOSSUS: "colossus",
    MYKONOS: "mykonos",
    MAESTRO: "maestro",    
    GHOSTS: "ghosts",
    EGYPT: "egypt",
    YAKUZA: "yakuza",
    FESTIVAL: "festival",
    MEREDITHCARTER: "meredithcarter",
    JASPERVANDERMEER: "jaspervandermeer",
    LORDRAVARYII: "lordravaryii",
  });
  
  export default Cases;