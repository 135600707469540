<template>
  <v-container class="container-with-bg">
    <v-row>
      <v-spacer></v-spacer>
      <v-col>
        <v-list color="#00000000">
          <v-list-item v-for="voice in voices" :key="voice.reference">
            <v-list-item-content>
              <v-list-item-title
                v-text="voice.reference"
                class="voice-title mb-2"
              ></v-list-item-title>
              <v-list-item-subtitle class="mb-2 text-wrap"
                ><span v-text="voice.title" class="voice-subtitle"> </span>
              </v-list-item-subtitle>
              <voice-slider
                @onPlay="onVoicePlay(voice)"
                @onPause="onVoicePause(voice)"
                @onSeek="onVoiceSeek(voice, $event)"
                :playing="voice.playing"
                :duration="voice.duration"
                :position="voice.currentTime"
                ref="slider"
                class="slider mb-2"
              ></voice-slider>
              <av-waveform
                :audio-src="voice.file"
                played-line-color="#ffc122"
                noplayed-line-color="#000000"
                playtime-slider-color="#ffffff"
                :audio-controls="false"
                :audio-class="'audio-player-' + voice.reference"
                :canv-width="waveformWidth"
              ></av-waveform>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
  </v-container>
</template>

<script>
import VoiceSlider from "@/components/VoiceSlider.vue";
import axios from "axios";
import currentGameMixin from "@/mixins/currentGameMixin";

export default {
  name: "Voices",
  data() {
    return {
      publicPath: process.env.BASE_URL,
      voices: [],
      currentTrack: undefined,
      waveformWidth: 400,
    };
  },
  components: {
    VoiceSlider,
  },
  mixins: [currentGameMixin],
  methods: {
    getVoicePlayer: function (reference) {
      return document.getElementsByClassName(`audio-player-${reference}`)[0];
    },
    onVoicePlay: function (voice) {
      const player = this.getVoicePlayer(voice.reference);

      player.play();
    },
    onVoicePause: function (voice) {
      const player = this.getVoicePlayer(voice.reference);

      player.pause();
    },
    onVoiceSeek: function (voice, currentTime) {
      const player = this.getVoicePlayer(voice.reference);

      player.currentTime = currentTime;
    },
  },
  computed: {
    dataPath: function () {
      return `${this.publicPath}${this.caseID}/data`;
    },
    mediaPath: function () {
      return `${this.publicPath}${this.caseID}/assets/audio/${this.$i18n.locale}`;
    },
  },
  watch: {
    mediaPath: function () {
      this.$router.go();
    },
  },
  created() {},
  mounted() {
    this.$nextTick(function () {
      const self = this;

      axios
        .get(`${self.dataPath}/voices.json`)
        .then(({ data }) => {
          data
            .map((v) => {
              return {
                reference: v.reference,
                file: `${self.mediaPath}/${v.file}`,
                title: self.$t(
                  `${this.caseID}.data.voices.${v.reference}.title`
                ),
              };
            })
            .forEach((d) => {
              self.voices.push({
                reference: d.reference,
                file: d.file,
                title: d.title,
                playing: false,
                duration: 0,
                currentTime: 0,
              });
            });
        })
        .then(() => {
          self.voices.forEach((voice) => {
            const player = self.getVoicePlayer(voice.reference);

            voice.duration = player.duration;

            player.addEventListener("durationchange", () => {
              voice.duration = player.duration;
            });

            player.addEventListener("play", () => {
              voice.playing = true;
            });

            player.addEventListener("pause", () => {
              voice.playing = false;
            });

            player.addEventListener("timeupdate", () => {
              voice.currentTime = player.currentTime;
            });
          });
        });
    });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.item {
  border: 3pt solid #5e492a;
}
.container-with-bg {
  background-color: #00000000;
}

.slider {
  max-width: 400px;
}

.voice-title {
  color: #ffffff;
  font-family: Bebas Neue;
  font-size: 24px;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
  text-transform: uppercase;
}

.voice-subtitle {
  color: #ffffff;
  font-family: Source Sans 3;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  text-transform: uppercase;
}
</style>
