<template>
  <v-expansion-panels accordion tile flat>
    <v-expansion-panel
      v-for="surveyItem in survey"
      :key="surveyItem.id"
      class="question-panel"
      style="background: #00000000"
      active-class="question-active"
    >
      <v-expansion-panel-header
        hide-actions
        class="question-header d-flex justify-center"
        active
        v-text="surveyItem.question"
      >
      </v-expansion-panel-header>
      <v-expansion-panel-content class="pa-3 question-content">
        <v-row>
          <v-col
            class="d-flex justify-center"
            cols="12"
            v-if="surveyItem.choices"
          >
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <button v-bind="attrs" v-on="on" class="user-choice">
                  {{
                    surveyItem.userChoice !== -1
                      ? surveyItem.choices[surveyItem.userChoice].value
                      : $t(`${caseID}.solution.labels.chooseSuspect`)
                  }}
                </button>
              </template>
              <v-item-group
                v-model="surveyItem.userChoice"
                @change="surveyItem.showAnswer = false"
              >
                <v-container class="question-choice">
                  <v-row
                    v-for="(choice, index) in surveyItem.choices"
                    :key="index"
                    class="choice-item"
                  >
                    <v-col>
                      <v-item v-slot="{ toggle }">
                        <div @click="toggle">{{ choice.value }}</div>
                      </v-item>
                    </v-col>
                  </v-row>
                </v-container>
              </v-item-group>
            </v-menu>
          </v-col>
          <v-col cols="12" class="d-flex justify-center">
            <v-btn
              tile
              text
              class="submit-button"
              @click="surveyItem.showAnswer = true"
              :disabled="surveyItem.userChoice === ''"
              color="#ffc122"
              v-if="surveyItem.showAnswer === false"
              ><span>{{ surveyItem.showSolutionLabel }}</span></v-btn
            >
            <v-btn
              tile
              text
              class="submit-button"
              @click="surveyItem.showAnswer = false"
              color="#ffc122"
              v-else
              ><span>{{
                $t(`${caseID}.solution.buttons.hideSolution`)
              }}</span></v-btn
            >
          </v-col>
          <v-col
            cols="12"
            v-if="surveyItem.showAnswer"
            v-html="getAnswer(surveyItem)"
            class="answer-text"
          >
          </v-col>
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<script>
import { solutionChoicesComparisonFunctions } from '../utils';

export default {
  data() {
    return {
      survey: [],
    };
  },
  props: {
    caseID: String,
  },
  computed: {
    locale: function() {
      return this.$i18n.locale;
    },
  },
  methods: {
    getAnswer: function(surveyItem) {
      if (surveyItem.choices) {
        if (surveyItem.choices.length === 0) return "";

        if (surveyItem.userChoice < 0) return "";

        if (surveyItem.userChoice > surveyItem.choices.length) return "";

        const choice = surveyItem.choices[surveyItem.userChoice];

        if (choice) return choice.result;

        return "";
      }

      return surveyItem.answer;
    },
    refreshSurvey: function() {
      const survey = require(`@/data/${this.caseID}/resolution.json`).map(
        (ri) => {
          const transKey = `${this.caseID}.data.resolution.${ri.id}.labels.showSolution`;
          const showSolutionButtonLabel = this.$te(transKey)
            ? this.$t(transKey)
            : this.$t(`${this.caseID}.solution.buttons.showSolution`);

          const item = {
            id: ri.id,
            question: this.$t(
              `${this.caseID}.data.resolution.${ri.id}.question`
            ),
            answer: this.$t(`${this.caseID}.data.resolution.${ri.id}.answer`),
            showAnswer: false,
            showSolutionLabel: showSolutionButtonLabel,
          };

          if (ri.choices) {
            item.choices = [];
            item.userChoice = -1;

            ri.choices.forEach((ch) => {
              item.choices.push({
                id: ch.id,
                value: this.$t(
                  `${this.caseID}.data.resolution.${ri.id}.choices.${ch.id}.value`
                ),
                result: this.$t(
                  `${this.caseID}.data.resolution.${ri.id}.choices.${ch.id}.result`
                ),
              });
            });

            if (ri.sortFunc) {
              item.choices.sort(solutionChoicesComparisonFunctions[ri.sortFunc]);
            }
          }

          return item;
        }
      );

      this.survey = survey;
    },
  },
  watch: {
    locale: function() {
      this.refreshSurvey();
    },
  },
  mounted() {
    this.refreshSurvey();
  },
};
</script>

<style scoped>
.v-expansion-panels {
  -webkit-box-shadow: 5px 4px 15px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 5px 4px 15px 0px rgba(0, 0, 0, 0.2);
}

.question-panel {
  background: #00000000;
}

.question-header {
  background: #00000099;
  text-transform: uppercase;
  font-family: "Bebas Neue";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.06em;
  text-align: center;
  color: #ffffff;
}

.question-active .question-header,
.question-header:hover {
  background: #ffc12266;
}

.question-content {
  color: #ffffff;
  background: #00000040;
  font-family: Source Sans 3;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}
.question-list {
  opacity: 0.6;
  background: #000000;
  font-family: Source Sans 3;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
}

.question-choice {
  background: #000000;
  color: #ffffff;
}

.user-choice {
  padding: 4px;
  min-width: 100px;
  border: 1pt solid white;
}

.choice-item {
  cursor: pointer;
}

.choice-item:hover {
  background: #ffc12266;
}

.submit-button {
  font-family: "Bebas Neue";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0.06em;
  text-align: center;
  text-transform: uppercase;
  height: 40px;
  padding-left: 20px;
  padding-right: 20px;
  border: 1pt solid #ffc122;
  background: #00000000;
}
.submit-button span {
  color: #ffc122;
}

.answer-text {
  text-align: justify;
  text-justify: inter-word;
}
</style>
