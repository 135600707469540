<template>
  <v-container class="agency-container pt-10" color="#00000000">
    <v-row align="center">
      <v-col class="d-flex" cols="12">
        <reset-button :gameID="gameID" class="setting-button">{{
          $t("homepage.reset.button")
        }}</reset-button>
      </v-col>
      <v-col class="d-flex">
        <locale-switcher :locales="locales" :currentLocale="currentLocale" class="setting-button" v-if="showLocaleSwitcher">{{
          $t("homepage.localeSwitcher.title")
        }}</locale-switcher>
      </v-col>
    </v-row>
    <router-link :to="{ name: `${this.gameID}-home` }">
      <img
        src="/common/assets/images/55investigations_logo.svg"
        class="agency-logo" />
      <img
        src="/common/assets/images/55investigations_title.svg"
        class="agency-title"
    /></router-link>
  </v-container>
</template>
<script>
import LocaleSwitcher from "./LocaleSwitcher.vue";
import ResetButton from "./ResetButton.vue";

export default {
  props: {
    gameID: String,
    showLocaleSwitcher: Boolean,
    locales: Array,
    currentLocale: String
  },
  components: {
    LocaleSwitcher,
    ResetButton,
  },
};
</script>
<style scoped>
.agency-logo {
  margin-top: 50px;
  position: absolute;
  height: 47px;
  top: 70px;
  left: 50%;
  transform: translate(-50%, 0%);
  filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4));
}

.agency-title {
  margin-top: 50px;
  position: absolute;
  height: 11px;
  top: 130px;
  left: 50%;
  transform: translate(-50%, 0%);
  filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4));
}

.agency-container {
  background-color: rgba(0, 0, 0, 0);
}

.setting-button {
  font-family: "Source Sans 3";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0.06em;
  color: #ffffff;
  text-transform: uppercase;
}

.setting-button:hover {
  color: #ffc122;
}
</style>
