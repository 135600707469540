<template>
  <v-row justify="center" class="ma-0">
    <v-dialog v-model="showResolveDialog" persistent max-width="500px">
      <div class="dialog-frame">
        <h1>{{ $t("common.resolveCase.dialogs.showResolve.title") }}</h1>
        <p>{{ $t("common.resolveCase.dialogs.showResolve.text") }}</p>
        <v-row>
          <v-col
            ><button @click="confirmShowResolve">
              {{ $t("common.buttons.yes") }}
            </button></v-col
          >
          <v-col
            ><button @click="cancelShowResolve">
              {{ $t("common.buttons.no") }}
            </button></v-col
          >
        </v-row>
      </div>
    </v-dialog>


    <v-col cols="auto">
      <v-img class="case-picture" :src="thumbnailPath" />
    </v-col>
    <v-col cols="auto">
      <div class="d-flex flex-column fill-height">
        <div class="item-container flex-grow-0 flex-shrink-0">
          <div v-for="(detail, key) in details" :key="key" class="case-item">
            <div class="label-container">
              <span class="case-label">{{ key }}</span>
            </div>
            <div class="prop-container">
              <div class="case-prop">
                {{ getResolveStatus(detail) }}
              </div>
            </div>
          </div>
        </div>
        <div class="flex-grow-1 flex-shrink-0"></div>
        <div class="flex-grow-0 flex-shrink-0">
          <resolve-button v-if="!isEventPage" @click="gotoResolution" id="resolve-button" class="resolve-button">{{
            $t("common.casebar.buttons.resolve")
          }}</resolve-button>
        </div>
      </div>
    </v-col>
  </v-row>
</template>
<script>
import ResolveButton from "./ResolveButton.vue";
import Cases from "../enums/Cases";

export default {
  name: "CaseBar",
  data() {
    return {
      publicPath: process.env.BASE_URL,
      showResolveDialog: false,
    };
  },
  props: {
    caseID: String,
    details: Object,
  },
  methods: {
    gotoResolution: function() {
      if(this.caseID === Cases.EGYPT || this.caseID === Cases.YAKUZA) {
        this.showResolveDialog = true;
      } else {
        this.$router.push({
          name: `${this.caseID}-resolve`,
          params: { caseID: this.caseID },
        });
      }
    },

    confirmShowResolve: function() {
      this.showResolveDialog = false;
      this.$router.push({
          name: `${this.caseID}-resolve`,
          params: { caseID: this.caseID },
        });      
    },

    cancelShowResolve: function() {
      this.showResolveDialog = false;
    },

    getResolveStatus: function(detail) {
      if (this.caseID === Cases.YAKUZA) {
        if (detail.code === "status") {
          if (this.$yakuzaStore.resolve.solved) return this.$t("common.solved");
        }
      }

      if (this.caseID === Cases.EGYPT) {
        if (detail.code === "status") {
          if (this.$store.getters.egyptResolveSolved) return this.$t("common.solved");
        }
      }      

      console.log(typeof detail)
      if (typeof detail === "object") {
        return detail.label;
      } else {
        return detail;
      }
    }

  },
  computed: {
    isEventPage: function() {
      return this.$route.name.includes('event') || this.$route.name.includes('festival')
    },
    thumbnailPath: function() {
      return `${this.publicPath}${this.caseID}/assets/images/thumbnail.jpg`;
    },
  },
  components: {
    ResolveButton,
  },
};
</script>

<style scoped>
.case-container {
  width: 100%;
}

.case-layout {
  padding: 10px;
  display: table;
  margin: auto;
}

.case-row {
  display: table-row;
}

.case-details {
  display: table-cell;
  vertical-align: top;
  padding-left: 10px;
  padding-right: 10px;
  text-align: left;
}

.case-label {
  display: inline-block;
  background: #ffc122;
  font-family: "Bebas Neue";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: right;
  padding-left: 4px;
  padding-right: 4px;
}

.item-container {
  display: table;
}

.case-item {
  display: table-row;
}

.label-container {
  display: table-cell;
  text-align: right;
  vertical-align: middle;
}

.prop-container {
  display: table-cell;
  vertical-align: middle;
}

.case-prop {
  color: #ffffff;
  font-family: "Source Sans 3";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.06em;
  text-align: left;
  padding-left: 8px;
  text-transform: uppercase;
}

.case-picture {
  height: 100%;
  border: 1px solid #ffc122;
  border-radius: 7px;
  max-width: 151px;
}

.picture-container {
  display: table-cell;
  /* background-image: url(../assets/images/maestro.jpg);
  background-size: cover; */
  /* width: 35%; */
  vertical-align: top;
  text-align: right;
}

.button-container {
  text-align: center;
  height: 100%;
}

.resolve-button {
  width: 100%;
}
</style>
