<template>
    <div class="grow container video-container">
      <video :src="`${casePublicAssets}/videos/message.mp4`" autoplay controls width="100%" class="video-tag"></video>
    </div>
  </template>
    
  <script>
    import currentGameMixin from "@/mixins/currentGameMixin";
    
    export default {
      name: "Video",
  
      components: { },
      mixins: [currentGameMixin],
      data: () => ({ }),
      methods: { },
      computed: { },
      watch: { },
      mounted() { }
    };
  </script>
  
  <style lang="scss" scoped>
    .video-container {
      position: relative;
    }
  </style>
    