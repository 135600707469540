import sections from "./sections";
import chats from "./chats";
import ouija from "./ouija";
import tarot from "./tarot";
import hotel from "./hotel";
import horizon from "./horizon";
import touristOffice from './tourist';

export default {
    caseDetails: [
        "name",
        "victim",
        "location",
        "status"
    ],
    availableLocales: [
        "fr", "en", "it", "de", "es"
    ],
    sections,
    chats,
    ouija,
    tarot,
    hotel,
    horizon,
    touristOffice
}