import { render, staticRenderFns } from "./Coffre.vue?vue&type=template&id=8b10b1f0&scoped=true&"
import script from "./Coffre.vue?vue&type=script&lang=js&"
export * from "./Coffre.vue?vue&type=script&lang=js&"
import style0 from "./Coffre.vue?vue&type=style&index=0&id=8b10b1f0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8b10b1f0",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VOverlay } from 'vuetify/lib/components/VOverlay';
installComponents(component, {VOverlay})
