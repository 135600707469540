<template>
  <v-container>
    <v-row>
      <v-col class="d-flex justify-start align-center" cols="8">
        <span>{{ trackInfo.artist }} - {{ trackInfo.title }}</span>
      </v-col>
      <v-col class="d-flex justify-end align-center" cols="4">
        {{ trackInfo.seek | minutes }}/{{ trackInfo.duration | minutes }}
      </v-col>
    </v-row>
  </v-container>
</template>
 
<script>
export default {
  props: {
    trackInfo: Object,
  },
};
</script>