<template>
  <v-container class="hunters-demons-container">
    <v-row>
      <v-col cols="12" class="hunters-demons-title">{{
        $t("ghosts.hunters.demons.title")
      }}</v-col>
      <v-col cols="12" class="hunters-demons-nota"
        >{{
          $t("ghosts.hunters.demons.messages.alphabeticalOrder")
        }}:</v-col
      >
    </v-row>
    <v-item-group v-model="letterIndex">
      <v-row justify="center">
        <v-col v-for="(letter, index) in letters" :key="`letter-${index}`">
          <v-item v-slot="{ toggle, active }">
            <div
              class="hunters-lexicon-letter"
              :class="{ selected: active }"
              @click="toggle"
            >
              {{ letter }}
            </div>
          </v-item>
        </v-col>
      </v-row>
    </v-item-group>
    <template v-if="letterIndex !== null">
      <v-row justify="center">
        <v-col class="text-center">
          <span class="hunters-lexicon-current">{{
            selectedLetter
          }}</span></v-col
        >
      </v-row>
      <v-item-group v-model="demonIndex">
        <v-row>
          <v-col
            v-for="(demon, index) in filteredDemons"
            :key="`demon-${index}`"
          >
            <v-item v-slot="{ toggle, active }">
              <template v-if="demon.wiki">
                <span
                  class="hunters-demons-link"
                  :class="{ selected: active }"
                  @click="if (!active) toggle();"
                >
                  {{ demon.name }}
                </span></template
              >
              <template v-else>
                <span class="hunters-demons-label">
                  {{ demon.name }}
                </span>
              </template>
            </v-item>
          </v-col>
        </v-row>
        <v-row
          v-if="demonIndex !== null"
          class="hunters-demon-card"
          align="center" justify="center"
        >
          <v-col>
            <div class="hunters-demon-title">{{ selectedDemon.name }}</div>
            <div v-html="demonDesc" class="hunters-demon-desc"></div>
          </v-col>
          <v-col class="hunters-demon-seal"
            ><v-img :src="selectedDemonSeal"></v-img
          ></v-col>
        </v-row>
      </v-item-group>
    </template>
  </v-container>
</template>
  <script>
import axios from "axios";
import currentGameMixin from "../../mixins/currentGameMixin";

export default {
  name: "GhostHuntersDemons",
  data() {
    return {
      publicPath: process.env.BASE_URL,
      letters: [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
      ],
      letterIndex: null,
      demonIndex: null,
      demons: [],
      demonDesc: "",
    };
  },
  mixins: [currentGameMixin],
  computed: {
    publicDataPath: function () {
      return `${this.publicPath}${this.caseID}/data`;
    },
    demonsListSrc: function () {
      return `${this.publicDataPath}/demons.json`;
    },
    publicAssetsPath: function () {
      return `${this.publicPath}${this.caseID}/assets`;
    },
    publicSealsPath: function () {
      return `${this.publicPath}${this.caseID}/assets/images/hunters/demons`;
    },
    publicHtmlPath: function () {
      return `${this.publicAssetsPath}/html/hunters/demons`;
    },
    demonDescSrc: function () {
      if (this.selectedDemon !== undefined)
        return `${this.publicHtmlPath}/${this.selectedDemon.name}_${this.$i18n.locale}.html`;

      return "";
    },
    selectedLetter: function () {
      if (this.letterIndex !== null)
        return this.letters[this.letterIndex].toLowerCase();

      return "";
    },
    filteredDemons: function () {
      return this.demons
        .filter((d) => d.name.toLowerCase().startsWith(this.selectedLetter))
        .sort((demon1, demon2) => {
          return demon1.name
            .toLowerCase()
            .localeCompare(demon2.name.toLowerCase());
        });
    },
    selectedDemon: function () {
      if (this.demonIndex !== null) return this.filteredDemons[this.demonIndex];

      return undefined;
    },
    selectedDemonSeal: function () {
      return this.getDemonSeal(this.selectedDemon);
    },
  },
  methods: {
    fetchDemons: async function () {
      const { data } = await axios.get(this.demonsListSrc);
      this.demons = data;
    },
    getDemonSeal: function (demon) {
      return `${this.publicSealsPath}/${demon.name}.svg`;
    },
    fetchDemonDesc: async function () {
      const { data } = await axios.get(this.demonDescSrc);
      this.demonDesc = data;
    },
  },
  mounted: async function () {
    await this.$nextTick();
    this.fetchDemons();
  },
  watch: {
    selectedLetter: function () {
      this.demonIndex = null;
    },
    selectedDemon: function (newValue) {
      if (newValue !== undefined) this.fetchDemonDesc();
    },
  },
};
</script>
  <style scoped>
.hunters-demons-container {
  padding-left: 46px;
  padding-right: 46px;
}
.hunters-demons-title {
  font-family: Eater;
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
}
.hunters-demons-nota {
  font-family: Fredoka;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
}
.hunters-lexicon-letter {
  font-family: Fredoka;
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  color: #ed1c24;
  border: 1px solid #ed1c24;
  text-transform: uppercase;
  width: 30px;
  height: 30px;
  cursor: pointer;
  margin: 0 auto;
}
.hunters-lexicon-letter:hover,
.hunters-lexicon-letter.selected {
  background: #ed1c24;
  color: #000000;
}
.hunters-lexicon-current {
  font-family: Fredoka;
  font-size: 32px;
  font-weight: 400;
  line-height: 39px;
  letter-spacing: 0em;
  text-align: center;
  color: #ed1c24;
  text-transform: uppercase;
}
.hunters-demons-link {
  font-family: Fredoka;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
  text-transform: capitalize;
  cursor: pointer;
  text-decoration: underline;
}
.hunters-demons-label {
  font-family: Fredoka;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
  text-transform: capitalize;
}
.hunters-demons-link:hover {
  text-decoration: none;
}
.hunters-demons-link.selected {
  cursor: default;
  text-decoration: none;
}
.hunters-demon-title {
  font-family: Fredoka;
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
  text-transform: uppercase;
}
.hunters-demon-card {
  background: #282828;
}
.hunters-demon-seal {
  max-width: 154px;
}
.hunters-demon-desc {
  font-family: Fredoka;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
}
</style>