<template>
  <v-container class="k-container">
    <v-overlay absolute class="text-center" :value="isError">
      <div class="dialog-frame">
        <p v-html="$t('egypt.karnak.error')"></p>
        <button @click="resetError">
          {{ $t("common.buttons.ok") }}
        </button>
      </div>
    </v-overlay>
    <div class="k-header">
      <img :src="casePublicImages + '/karnak/logo.svg'" />
      <span class="k-header-title">{{ $t("egypt.karnak.catalog") }}</span>
    </div>
    <div class="k-bandeau">
    </div>
    <div class="k-body">
      <div class="k-panel-1" v-if="currentStep == 1 && isLocked">
        <div class="k-title">{{ $t("egypt.karnak.title1")}}<br/>{{ $t("egypt.karnak.title2")}}</div>
        <div class="k-panel-body">
          <div class="k-panel-text">{{ $t("egypt.karnak.notarobot_prompt")}}</div>
          <div class="k-robot">
            <v-checkbox 
              :label="$t('egypt.karnak.notarobot')"
              color="primary"
              value="primary"
              @click="tryUnlockStep1()"
              hide-details
            ></v-checkbox>
          </div>
        </div>
      </div>
      <div class="k-panel-2" v-if="currentStep == 2 && isLocked">
        <div class="k-title">{{ $t("egypt.karnak.title1")}}<br/>{{ $t("egypt.karnak.title2")}}</div>
        <div class="k-panel-body">
          <div class="k-panel-text">{{ $t("egypt.karnak.wheel")}}</div>
          <div>
            <spin-wheel 
              :init-angles="startAngles"
              :reactive-ranges="reactiveRanges"
              @onAngleChange="angleChange"
            >
            </spin-wheel>
          </div>
          <div>
            <v-btn @click="tryUnlockStep2" class="k-submit-button">{{ $t("egypt.karnak.submit") }}</v-btn>          
          </div>  
        </div>
      </div>      
      <div class="k-panel-3" v-if="!isLocked">
        <div class="k-warning">{{ $t("egypt.karnak.warning1") }}</div>
        <div class="k-filter-by">
          <v-row align="center">
            <v-col cols="12" sm="2"  class="pl-4 pb-0 pt-4">
              <span>{{ $t("egypt.karnak.filterby") }}</span>
            </v-col>
            <v-col cols="12" sm="5" class="pl-8 pt-0 pb-0" >
              <v-radio-group
                v-model="filter"
                row
              >
                <v-radio
                    :label="$t('egypt.karnak.title')"
                    color="primary"
                    value="title"
                  ></v-radio>
                <v-radio
                  :label="$t('egypt.karnak.author')"
                  color="primary"
                  value="author"
                ></v-radio>
                <v-radio
                  :label="$t('egypt.karnak.borrower')"
                  color="primary"
                  value="borrower"
                ></v-radio>                            
              </v-radio-group>
            </v-col>
            <v-col  sm="5" class="pt-0">
              <v-text-field
                class="ml-4 mr-4 pt-0"
                :label="$t('egypt.karnak.search')"
                prepend-inner-icon="mdi-magnify"
                v-model="filterKeyword"
              ></v-text-field>
            </v-col>
          </v-row>
        </div>
        <div class="mobile-visible-only">
          <v-row class="k-swipe-anim">
            <v-col cols="9" v-html="$t('egypt.karnak.swipe_help')"></v-col>
            <v-col cols="3">
              <img class="wobble-hor-bottom" :src="casePublicImages + '/karnak/swipe.png'" />
            </v-col>
          </v-row>
        </div>
        <div class="k-table">
          <v-simple-table dense>
            <thead>
              <tr>
                <th class="text-left">{{ $t("egypt.karnak.title") }}</th>
                <th class="text-left">{{ $t("egypt.karnak.author") }}</th>
                <th class="text-left">{{ $t("egypt.karnak.year") }}</th>
                <th class="text-left">{{ $t("egypt.karnak.status") }}</th>
                <th class="text-left">{{ $t("egypt.karnak.borrower") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="book in filteredBooks"
                :key="book.title"
              >
                <td>{{ $t("egypt.karnak.books." + book.title) }}</td>
                <td>{{ book.author }}</td>
                <td>{{ book.year }}</td>
                <td>{{ book.status }}</td>
                <td>{{ book.num_borrow }}</td>
              </tr>
            </tbody>
          </v-simple-table>
        </div>
      </div>
    </div>

  </v-container>
</template>
<script>
import currentGameMixin from "../../mixins/currentGameMixin";
import SpinWheel from "@/components/egypt/SpinWheel.vue";

export default {
  components: { SpinWheel },
  name: "Karnak",
  data() {
    return {    
      angles: [0,0,0],
      filter: "title",
      filterKeyword: "",
    };
  },
  mixins: [currentGameMixin],
  computed: {
    publicCasePath: function () {
      return `${this.publicPath}${this.caseID}/`;
    },
    isLocked: function() {
      return this.$store.getters.egyptKarnakLocked;
    },
    isError: function() {
      return this.$store.getters.egyptKarnakError;
    },
    currentStep: function() {
      return this.$store.getters.egyptKarnakStep;
    },
    startAngles: function() {
      return this.$store.getters.egyptKarnakStartAngles;
    },
    reactiveRanges: function() {
      return this.$store.getters.egyptKarnakReactiveRanges;
    },
    filteredBooks: function() {
      let books = this.$store.getters.egyptKarnakBooks;

      if(this.filterKeyword.trim().length === 0) {
        return books;
      }

      let kw = this.filterKeyword.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");

      switch(this.filter) {
        case 'title':
          return books.filter((el) => this.$i18n.t("egypt.karnak.books." + el.title).toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(kw));
        case 'author':
          return books.filter((el) => el.author.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(kw));
        case 'borrower':
          return books.filter((el) => el.num_borrow.toLowerCase().includes(this.filterKeyword.toLowerCase()));
        default:
          return books;
      }
    }
  },
  methods: {
    angleChange: function(id, angle) {
      if(angle[id] > Math.PI) angle[id] -= Math.PI * 2;
      if(angle[id] < -Math.PI) angle[id] += Math.PI * 2; 
      this.angles[id] = angle[id];
    },
    setDefaultPositions: function() {
      
    },
    tryUnlockStep1: function() {
      this.$store.dispatch("egyptTryUnlockKarnak1");
    },
    minimizeAngle: function(a) {
      a = a % (Math.PI * 2);
      if(Math.abs(a) > Math.abs((Math.PI * 2) - a)) {
        a = (Math.PI * 2) - a;
      }
      return a;
    },
    tryUnlockStep2: function() {
      this.$store.dispatch("egyptTryUnlockKarnak2", this.angles.map(a => this.minimizeAngle(a)));
    },    
    resetError: function() {
      this.$store.dispatch("egyptResetKarnakError");
    },
  },
  async mounted() {
    this.setDefaultPositions();
    this.resetError();
  }
};
</script>
<style>
#app {
  background-position: top !important;
}
</style>
<style scoped lang="scss">
.k-container {
  position: relative;
  background: rgba(0, 0, 0, 0.4);
  border: 1px solid #FFFFFF;
  box-shadow: 8px 6px 7px rgba(0, 0, 0, 0.3);
  border-radius: 7px;
  padding: 19px !important;
  display: block !important;
  height: auto !important;
  width: 100%;
  text-align: center;

  .k-header {
    height: 66px;
    background: #ffffff;
    text-align: left;

    img {
      height: 40px;
      margin-top: 10px; 
      margin-left: 12px;
    }

    .k-header-title {
      font: normal 600 17px/121.69% 'Roboto';
      color: #B76B37;
      padding-left: 20px;
      vertical-align: middle;
      height: 50px;
      display: inline-block;

      @media only screen and (max-width: 600px) {
        font-size: 14px;
      }
    }


  }
}

.k-bandeau {
  height: 37px;
  background: #ffffff;
  text-align: left;
  background: center/cover url(/egypt/assets/images/karnak/bandeau.svg);
}

.k-body {
  background: center/cover url(/egypt/assets/images/karnak/bg.jpg);
  width: 100%;
  min-height: 320px;
}

.k-panel-1,
.k-panel-2 {
  width: 100%;
  max-width:400px;
  margin: auto;
  padding-top: 20px;  
}

.k-title {
  background-color: #4594F0;
  text-transform: uppercase;
  font: normal 700 12px 'Roboto','Arimo';
  color: #FFFFFF;
  margin: auto;
  padding: 10px;
}

.k-panel-body {
  background-color: #ffffff;
  padding-top: 30px;
  padding-bottom: 30px;
}

.k-panel-text {
  font: normal 400  11px/11px 'Arimo';
  text-align: center;
  padding-bottom: 18px;
}

.k-robot {
  background: rgba(217, 217, 217, 0.2);
  border: 1px solid #7F7F7F;
  margin: auto;
  width: 209px;
  height: 54px;
  padding: 10px;
  .v-input--checkbox::v-deep {
    margin: 0;
    padding-bottom: 4px;
    .v-label {
      font-size: 11px !important;
    }
  }
}
.k-panel-3 {
  width: 100%;
  background-color: #ffffff;
  padding: 20px;

  .k-warning {
    font: italic 500 12px/121.69% 'Roboto';
    text-align: center;
    color: #B76B37;
    padding-bottom: 20px;
  }
  .k-filter-by {
    width: 100%;
    text-align: left;
    border: 1px solid #878787;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin-bottom: 4px;

    span {
      display: inline-block;
      font: normal 600 12px/121.69% 'Roboto';
      text-align: center;
      text-transform: uppercase;
      padding-left: 20px;
      padding-right: 20px;
    }

    ::v-deep .v-label {
      font-size: 12px !important;
      padding-left: 0px;
      margin-left: -4px;
    }
    ::v-deep .v-icon {
      font-size: 19px !important;
    }    

    ::v-deep [class*="__ripple"] {
      left: -7px;
      height: 24px;
      width: 24px;
      top: calc(50% - 19px);
    }

    .v-input--radio-group--row {
      display: inline-block;

      .v-radio {
        padding: 0px;
      }
    }
  }

  ::v-deep table {
    margin-top: 20px !important;
    
    td {
      font-size: 12px !important;
      text-align: left;
    }
    th {
      background-color: #B76B37;
      color: #ffffff !important;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 11px;
      line-height: 121.69%;


    }
  }
}

.dialog-frame {
  border-radius: 0 !important;
}

.k-submit-button {
  background-color: #4594F0 !important;
  color: #ffffff !important;
  text-transform: none !important;
  font: normal  400 12px/121.69% 'Roboto','Arimo';
}

@media only screen and (min-width: 600px) {
  .k-swipe-anim {
    display:none;
  }
}
.k-swipe-anim {
  margin-top: 10px;
  font-size: 12px;
  text-align: center;

  img {
    width: 45px;
    height: 45px;
  }
}

.wobble-hor-bottom {
	-webkit-animation: wobble-hor-bottom 3s infinite both;
	animation: wobble-hor-bottom 3s infinite both;
}

/* ----------------------------------------------
 * Generated by Animista on 2023-8-14 14:37:24
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation wobble-hor-bottom
 * ----------------------------------------
 */
 @-webkit-keyframes wobble-hor-bottom {
  0%,
  18%,
  100% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
  }
  3% {
    -webkit-transform: translateX(-30px) rotate(-6deg);
            transform: translateX(-30px) rotate(-6deg);
  }
  6% {
    -webkit-transform: translateX(15px) rotate(6deg);
            transform: translateX(15px) rotate(6deg);
  }
  9% {
    -webkit-transform: translateX(-15px) rotate(-3.6deg);
            transform: translateX(-15px) rotate(-3.6deg);
  }
  12% {
    -webkit-transform: translateX(9px) rotate(2.4deg);
            transform: translateX(9px) rotate(2.4deg);
  }
  15% {
    -webkit-transform: translateX(-6px) rotate(-1.2deg);
            transform: translateX(-6px) rotate(-1.2deg);
  }
}
@keyframes wobble-hor-bottom {
  0%,
  18%,
  100% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
  }
  3% {
    -webkit-transform: translateX(-30px) rotate(-6deg);
            transform: translateX(-30px) rotate(-6deg);
  }
  6% {
    -webkit-transform: translateX(15px) rotate(6deg);
            transform: translateX(15px) rotate(6deg);
  }
  9% {
    -webkit-transform: translateX(-15px) rotate(-3.6deg);
            transform: translateX(-15px) rotate(-3.6deg);
  }
  12% {
    -webkit-transform: translateX(9px) rotate(2.4deg);
            transform: translateX(9px) rotate(2.4deg);
  }
  15% {
    -webkit-transform: translateX(-6px) rotate(-1.2deg);
            transform: translateX(-6px) rotate(-1.2deg);
  }
}

</style>