import police_egypt from "./police";
import karnak_books from './karnak_books';

export default {
    availableLocales: [
        "fr"
    ],
    caseDetails: [
        "name",
        "victim",
        "location",
        "status"
    ], 
    sections: [
        {
            id: "envelopes",
            subsections: [
                {
                    id: "security",
                    lockable: true,
                    locked: true,
                    disabled: false,
                    route: { name: "egypt-security" }
                },
                {
                    id: "police",
                    lockable: true,
                    locked: true,
                    disabled: true,
                    route: { name: "egypt-police" }
                },                
                {
                    id: "suitcase",
                    lockable: true,
                    locked: true,
                    disabled: true,
                    route: { name: "egypt-suitcase" }
                },
                {
                    id: "phone",
                    lockable: true,
                    locked: true,
                    disabled: true,
                    route: { name: "egypt-phone" }
                },                  
            ]
        },
        {
            id: "evidences",
            subsections: [
                {
                    id: "call",
                    lockable: true,
                    locked: true,
                    route: { name: "egypt-call" }
                },
                {
                    id: "karnak",
                    lockable: true,
                    locked: true,
                    route: { name: "egypt-karnak" }
                },
                {
                    id: "webmail",
                    lockable: true,
                    locked: true,                    
                    route: { name: "egypt-webmail" }
                }
            ]
        },
        {
            id: "clues",
            route: { name: "egypt-clues" }
        }
    ],
    "security": {
        defaultPosition: 1,
        solution1: "12",
        solution2: "Rayan Sallah",
        step: 1
    },
    "chats": {
        police_egypt
    },
    "suitcase": {
        solution1: "1473",
        solution2: "1458"
    },
    "phone": {
        pins: [
            "1934"
        ]
    },
    "karnak": {
        startAngles: [3.71, 5.22, 2.30],
        correctAngles: [0, 0, 0],
        tolerance: 0.18,
        reactiveRanges: ['14,25',
                         '25.1,37',
                         '37.1,50'],
        karnak_books 
    },
    "webmail": {
        correct: {
            university: ["Lille IV", "Lille", "Lille 4", "Université de Lille", "Uni Lille", "Université Lille", "LILLEIV", "LILLE4"],
            name: ["Marion Suchet", "Suchet Marion", "Suchet"],
            dob: ["18/12/2001"],
            studentnum: ["B9455A"]
        }
    },
    "call": {
        callNumber: "075475670",
        secret: "SOBEK",
        otherNumbers: ["064478960", "054670964", "075147347"]
    },
    "resolve": {
        solutions: ["9", "8", "5", "6"]
    }
}
