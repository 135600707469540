<template>
  <div class="tourist-home-container">
    <h2>{{ $t("ghosts.touristOffice.home.title") }}</h2>
    <div v-html="htmlContent"></div>
  </div>
</template> 
<script>
import currentGameMixin from "../../mixins/currentGameMixin";
import axios from "axios";

export default {
  name: "TouristOfficeHome",
  data() {
    return {
      publicPath: process.env.BASE_URL,
      htmlContent: "",
    };
  },
  mixins: [currentGameMixin],
  computed: {
    publicAssetsPath: function () {
      return `${this.publicPath}${this.caseID}/assets`;
    },
    publicHtmlPath: function () {
      return `${this.publicAssetsPath}/html/tourist-office`;
    },
    htmlContentSrc: function () {
      return `${this.publicHtmlPath}/home_${this.$i18n.locale}.html`;
    },
  },
  methods: {
    fetchHtmlContent: async function () {
      try {
        const { data } = await axios.get(this.htmlContentSrc);

        this.htmlContent = data;
      } catch (e) {
        console.error("Can not fetch html content", e);
      }
    },
  },
  mounted() {
    this.fetchHtmlContent();
  },
};
</script>
<style scoped>
.tourist-home-container {
  display: flex;
  flex-direction: column;
  font-family: Roboto;
  letter-spacing: 0em;
  text-align: left;
}

.tourist-home-container > h2 {
  font-weight: 500;
  line-height: 16px;
  font-size: 14px;
  font-style: italic;
  letter-spacing: 0em;
  text-align: left;
  margin: 30px 0;
}
</style>
<style>
.tourist-home-container p {
  font-size: 12px;
  font-weight: 300;
}

.tourist-home-container strong {
  font-weight: 500;
}
</style>
