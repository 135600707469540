<template>
    <div class="phone-container">
        <v-overlay absolute opacity="0" :value="solved">
            <div class="dialog-frame unlock">
            <p>
                <span v-html="$t('festival.telephone.unlock_desc')"></span>
            </p>
            <p></p>
            </div>
        </v-overlay>
        <svg-phone-pattern
            class="phone-ui"
            @onSolved="onSolved"
        ></svg-phone-pattern>
        <audio :src="unlockSoundSrc" ref="unlockSound" />
    </div>
  </template>
  
  <script>
  import SvgPhonePattern from "@/components/festival/SvgPhonePattern.vue";
  import currentGameMixin from "../../mixins/currentGameMixin";
  
  export default {
    components: { SvgPhonePattern },
    name: "Telephone",
    data() {
      return {
        solved: false
      };
    },
    mixins: [currentGameMixin],  
    computed: {
      isLocked: function() {
        return !this.$festivalStore.telephone.solved;
      },
      unlockSoundSrc: function() {
        return `${this.casePublicAudio}/unlock_sound_phone.mp3`;
      }, 
    },
    watch: {
      solved: function (newValue) {
        if (newValue)
        {
            this.playUnlockSound();
        }
      },
    },
    methods: {
      playUnlockSound: function() {
        this.$refs.unlockSound.play();
      },
      onSolved: function() {
        this.solved = true;
        this.$festivalStore.telephone.solved = true;
        this.$festivalStore.saveState();
      }
    },
    created() {},
    mounted: async function() {
      if (!this.isLocked) this.solved = true;
    },
  };
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
  .phone-container {
    width: 100%;
    max-width: 100%;
    background-color: #00000000;
    position: relative;
  }
  
  .phone-ui {
    max-width: 300px;
    margin-left:auto;
    margin-right: auto;
  }

  </style>
  