<template>
  <div class="locale-switcher" v-if="locales.length > 1">
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <div class="locale-container">
          <v-img :src="currentFlag" width="24" class="flag"></v-img>
          <button class="menu-button" v-bind="attrs" v-on="on">
            <slot></slot>
          </button>
        </div>
      </template>
      <v-list class="menu" color="#00000066">
        <v-list-item-group v-model="selectedItem">
          <v-list-item
            v-for="(item, index) in locales"
            :key="index"
            class="menu-item"
          >
            <v-list-item-avatar size="32">
              <v-img :src="getLocaleFlag(item.code)"></v-img>
            </v-list-item-avatar>
            <v-list-item-title class="menu-title">{{
              item.name
            }}</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>
  </div>
</template>
<script>
import { setLocale } from "../utils";

export default {
  name: "LocaleSwitcher",
  data() {
    return {
      publicPath: process.env.BASE_URL,
      selectedItem: -1,
    };
  },
  props: {
    locales: Array,
    currentLocale: String
  },
  computed: {
    flagsPath: function () {
      return `${this.publicPath}common/assets/images/flags`;
    },
    currentFlag: function () {
      return this.getLocaleFlag(this.currentLocale);
    },
  },
  methods: {
    getLocaleFlag: function (locale) {
      return `${this.flagsPath}/${locale}.svg`;
    },
  },
  watch: {
    selectedItem: function (newIndex) {
      this.$i18n.locale = this.locales[newIndex].code;
      setLocale(this.$i18n.locale);
    },
  },
  mounted: async function() {
    await this.$nextTick();

    this.selectedItem = this.locales.indexOf(this.currentLocale);
  }
};
</script>
<style scoped>
.flag {
  float: left;
  text-align: middle;
  margin-right: 10px;
}

.locale-container {
  text-align: left;
  cursor: pointer;
}

.menu {
  background: #00000066;
}

.menu-button {
  text-transform: inherit;
}

.menu-button i {
  color: inherit;
}

.menu-item:hover {
  background: #ffc12266;
}

.menu-title {
  color: #ffffff;
  text-transform: uppercase;
}
</style>