<template>
  <v-container class="fill-height" fluid>
    <police-header />
    <v-container v-if="!locked">
      <v-row>
        <v-col class="d-flex justify-start">
          <div class="section-container">
            <router-link :to="{ name: `${this.caseID}-laboratory` }">
              <div class="section-row">
                <div class="icon">
                  <img
                    src="/maestro/assets/images/police/laboratory.svg"
                    class="section-logo"
                  />
                </div>
                <div
                  class="label"
                  v-text="$t(`${this.caseID}.police.links.laboratory`)"
                ></div>
              </div>
            </router-link>
          </div>
        </v-col>
        <v-col class="d-flex justify-start">
          <div class="section-container">
            <router-link :to="{ name: `${this.caseID}-bank` }">
              <div class="section-row">
                <div class="icon">
                  <img
                    src="/maestro/assets/images/police/bank.svg"
                    class="section-logo"
                  />
                </div>
                <div
                  class="label"
                  v-text="$t(`${this.caseID}.police.links.bank`)"
                ></div>
              </div>
            </router-link>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-card v-else width="100%">
      <v-card-title
        v-t="`${caseID}.police.title`"
        class="police-title"
      ></v-card-title>
      <v-text-field
        v-model="inputCrypto"
        :label="$t(`${this.caseID}.police.crypto.label`)"
        placeholder="XXX"
        required
        clearable
        :error-messages="errorMessages"
        @keypress.enter="login()"
      ></v-text-field>
      <v-card-actions>
        <v-btn
          v-t="`${this.caseID}.police.buttons.login`"
          v-bind:disabled="inputCrypto === ''"
          @click="login()"
        ></v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import PoliceHeader from "../../components/maestro/PoliceHeader.vue";
import { loginPolice } from "../../utils";
import currentGameMixin from "../../mixins/currentGameMixin";

export default {
  name: "PoliceHome",
  data() {
    return {
      inputCrypto: "",
      showCrypto: false,
      error: false,
    };
  }, 
  components: {
    PoliceHeader,
  },
  mixins: [currentGameMixin],
  computed: {
    locked: function () {
      return this.$store.getters.maestroPoliceLocked;
    },
    errorMessages() {
      return this.error
        ? this.$t(`${this.caseID}.police.crypto.wrongCrypto`)
        : "";
    },
  },
  methods: {
    unlock() {
      this.$store.dispatch("unlockSection", {
        gameID: this.caseID,
        sectionID: "police",
      });
    },
    login() {
      if (loginPolice(this.inputCrypto)) {
        this.error = false;
        this.unlock();
      } else this.error = true;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.police-title {
  word-break: normal;
}

.section-container {
  display: table;
  color: #1e43a3;
  font-family: "Roboto";
  font-size: 24px;
  font-style: normal;
}

.section-row {
  display: table-row;
}

.section-container .icon,
.section-container .label {
  display: table-cell;
  vertical-align: middle;
  padding: 10px;
}

.section-container a {
  text-decoration: none;
  color: #1e43a3;
}

.section-container a:hover {
  text-decoration: none;
  text-decoration: underline;
}

.section-logo {
  width: 100px;
}
</style>
