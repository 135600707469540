<template>
    <div class="dc-short-text">
        <subscribe :caseID="caseID" v-if="false || allowedLocales.includes('it')"></subscribe>
                
        <h2>Soluzione</h2>
        <p>
            Il cavallo da corsa di Louis de Bavière, Lord Ravary II, montato dal celebre fantino Lionel Larue,
            inanella vittorie su un percorso di gloria che pare inarrestabile (Risultati delle corse).
            Nonostante le riserve della sua allenatrice ufficiale, il nobile uomo d&#39;affari Louis de Bavière decide
            di far correre Lord Ravary II durante una prestigiosa corsa di gala a Dubai, prevista il 10 giugno
            2024 (<i>Conversazione Gruppo Team LDB</i>). Durante questo evento, si verifica un incidente: Luca
            Rossi, un fantino noto per le sue numerose cadute, causa uno scontro che provoca un infortunio a
            Lord Ravary II (<i>Risultati delle corse, Asimmetria locomotoria del cavallo</i>).
        </p>
        <p>
            Furioso per questo incidente, Lionel Larue scatenerà l’alterco con Rossi al Prestige Ranch (<i>Post
            sui social di Steeve Green</i>).
        </p>
        <p>
            Inoltre, in seguito all’infortunio, la velocità del cavallo diminuisce significativamente; una debolezza
            imperdonabile a questo livello agonistico (<i>Prima pagina dell&#39;Horse Mag</i>).
        </p>
        <p>
            L&#39;informazione resta confidenziale, pochissime persone sono al corrente dell&#39;incidente. Fra queste
            c&#39;è Malika Jamelson, la quale informa suo padre di tale fragilità. L&#39;uomo ne approfitta per
            scommettere su un altro cavallo, Swift Arrow, nel momento in cui la quota su quest&#39;ultimo è
            particolarmente favorevole (<i>Evoluzione delle quote, Ricevuta di scommessa</i>).
        </p>
        <p>
            Nathalie Bonnard, allenatrice ufficiale della scuderia LDB, è stata licenziata il 9 giugno e ignora la
            perdita di equilibrio del cavallo (<i>Lettera di licenziamento</i>).
        </p>
        <p>
            Nel frattempo, il contratto di assicurazione di Lord Ravary II deve essere rivalutato in occasione
            della visita periodica prevista dall&#39;assicuratore Equine Assurance il 22 giugno 2024 (<i>Contratto di
            assicurazione</i>).
        </p>
        <p>
            Prendendo una decisione radicale, Louis decide di sbarazzarsi con discrezione del cavallo,
            provocandogli un&#39;overdose di avena. La sera del 18 giugno, dopo aver acquistato un nuovo cavallo
            di classe 1, Lord Lion, approfitta dell&#39;assenza di Lea Lumet fra le 19:15 e le 19:30 per introdursi nel
            magazzino di alimenti del Prestige Ranch. Qui ruba due sacchi d&#39;avena e li somministra a Lord
            Ravary II facendoli passare dalla finestra del box, condannando così il cavallo (<i>Mappa del Prestige
            Ranch, E-mail di Lea Lumet</i>).
        </p>
        <p>
            I sacchi di avena vengono ritrovati davanti al box 8; il logo &quot;Race Horse USA&quot;, visto anche sui
            documenti della scuderia, è ben leggibile (<i>Fotofinish del Westgate Derby e foto di Rosedale
            Stakes</i>).
        </p>
        <p>
            Osservando la mappa del Prestige Ranch e considerando gli spostamenti di Lea Lumet, che va dai
            box al maneggio, diviene chiaro che gli altri sospettati potenziali, ovvero i fantini, non avrebbero
            potuto percorrere questo tragitto senza incrociare la donna (<i>Mappa del Prestige Ranch, E-mail di
            Lea e Registro ingressi/uscite</i>).
        </p>
    </div>
</template>

<script>
import config from '@/config/';
import Cases from "@/enums/Cases";
import currentGameMixin from "../../mixins/currentGameMixin";
import Subscribe from "@/components/Subscribe.vue";  

export default {
  name: "LordRavaryII",
  components: { Subscribe },
  mixins: [currentGameMixin],
  computed: {
    allowedLocales: function() {
      return config.common.showSubscribeForLocales
    },  
  },
  watch: { },
  mounted() {
    this.$i18n.locale = "it";
  },
  created() {
    this.setCurrentGame(Cases.LORDRAVARYII);
  },  
}
</script>

<style >
.dc-short-text {
    color: #fff;
    padding: 30px;
    text-align: center;
}
</style>