<template>
  <v-card tile color="#00000000">
    <v-dialog v-model="showFullSolutionDialog" persistent max-width="500px">
      <div class="dialog-frame">
        <h1>{{ $t("common.resolveCase.dialogs.showFullSolution.title") }}</h1>
        <p>{{ $t("common.resolveCase.dialogs.showFullSolution.text") }}</p>
        <v-row>
          <v-col
            ><button @click="confirmShowSolution">
              {{ $t("common.buttons.yes") }}
            </button></v-col
          >
          <v-col
            ><button @click="cancelShowSolution">
              {{ $t("common.buttons.no") }}
            </button></v-col
          >
        </v-row>
      </div>
    </v-dialog>
    <v-container>
      <v-row justify="center">
        <v-col cols="12" sm="3" class="d-flex justify-center">
          <button @click="returnToInvestigation" class="return-button">
            {{ $t(`${caseID}.solution.buttons.returnToInvestigation`) }}
          </button>
        </v-col>
        <v-col class="resolution-header" cols="12" sm="6" md="6">
          <p>{{ $t(`${caseID}.solution.headline.line1`) }}</p>
        </v-col>
        <v-col cols="2" sm="3"> </v-col>
      </v-row>
      <v-row justify="center">
        <v-col class="resolution-header" cols="12" sm="8" md="6">
          <p>{{ $t(`${caseID}.solution.headline.line2`) }}</p>
          <p class="resolution-headline">
            {{ $t(`${caseID}.solution.headline.line3`) }}
          </p>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="12" sm="8" md="6">
          <resolution :caseID="caseID" class="resolution-panel"></resolution>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="12" sm="8" md="6" class="d-flex justify-center">
          <button
            class="solution-button"
            @click="toggleSolution"
            v-if="solutionVisible"
          >
            {{ $t(`${caseID}.solution.buttons.hideFullSolution`) }}
          </button>
          <button class="solution-button" @click="toggleSolution" v-else>
            {{ $t(`${caseID}.solution.buttons.showFullSolution`) }}
          </button>
        </v-col>
      </v-row>
      <template v-if="solutionVisible">
        <v-row justify="center">
          <v-col
            v-html="solutionHtml"
            cols="12"
            sm="8"
            md="6"
            class="solution-text"
          >
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col
            cols="12"
            sm="8"
            md="8"
          >
            <subscribe :caseID="caseID" v-if="allowedLocales.includes(currentLocale)"></subscribe>
          </v-col>
        </v-row>        
        <v-row justify="center">
          <v-col
            v-if="evaluateUrl !== undefined"
            cols="12"
            sm="8"
            md="6"
            class="text-center"
          >
            <a :href="evaluateUrl" target="_blank">{{
              $t("common.links.evaluateThisGame")
            }}</a>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col v-for="network in socialNetworks" :key="network.id" cols="auto"
            ><a :href="network.url" target="_blank">
              <img
                :src="getSocialNetworkLogo(network)"
                class="social-network-logo"
            /></a>
          </v-col>
        </v-row>
      </template>
    </v-container>
  </v-card>
</template>
<script>
import config from '@/config/'
import Resolution from "@/components/Resolution.vue";
import Subscribe from "@/components/Subscribe.vue";
import axios from "axios";
import currentGameMixin from "../mixins/currentGameMixin";

export default {
  name: "ResolveCase",

  data() {
    return {
      publicPath: process.env.BASE_URL,
      solutionVisible: false,
      solutionHtml: "",
      showFullSolutionDialog: false,
    };
  },
  mixins: [currentGameMixin],
  methods: {
    askShowFullSolution: function() {
      this.showFullSolutionDialog = true;
    },
    toggleSolution: function() {
      if (this.solutionVisible) this.solutionVisible = false;
      else this.askShowFullSolution();
    },
    confirmShowSolution: function() {
      this.showFullSolutionDialog = false;
      this.solutionVisible = true;
    },
    cancelShowSolution: function() {
      this.showFullSolutionDialog = false;
    },
    fetchSolution: function() {
      axios
        .get(this.solutionSrc)
        .then(({ data }) => (this.solutionHtml = data))
        .catch(() => {
          this.solutionHtml = "";
        });
    },
    returnToInvestigation: function() {
      this.$router.push({ path: "." });
    },
    mergeLocaleMessages: function(locale) {
      try {
        const messages = require(`@/messages/${this.caseID}/solution.messages.js`);
        this.$i18n.mergeLocaleMessage(locale, messages.default[locale]);
      } catch (e) {
        console.warn(`Could not merge locale messages : ${e}`);
      }
    },
    getSocialNetworkLogo: function(network) {
      return require(`../assets/images/social_networks/${network.id}_icon.png`);
    },
  },
  computed: {
    solutionSrc: function() {
      return `${this.publicPath}${this.caseID}/solution/facts_${this.$i18n.locale}.html`;
    },
    evaluateUrl: function() {
      return this.$store.getters.evaluateUrl(this.caseID);
    },
    currentLocale: function() {
      return this.$i18n.locale;
    },
    socialNetworks: function() {
      return this.$store.state.socialNetworks;
    },
    allowedLocales: function() {
      return config.common.showSubscribeForLocales
    }
  },
  components: {
    Resolution,
    Subscribe,
  },
  watch: {
    solutionSrc: function(newValue) {
      if (newValue !== "") this.fetchSolution();
      else this.solutionHtml = "";
    },
    solutionVisible: function(newValue) {
      if (newValue) this.$vuetify.goTo(".solution-button");
    },
    currentLocale: function(newLocale) {
      if (newLocale) this.mergeLocaleMessages(newLocale);
    },
  },
  created() {
    this.mergeLocaleMessages(this.$i18n.locale);
  },
  mounted() {
    this.fetchSolution();
  },
};
</script>
<style scoped>
.resolution-header {
  font-family: Source Sans 3;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
}

.resolution-headline {
  font-family: Bebas Neue;
  font-size: 22px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0em;
}

.solution-button {
  font-family: "Bebas Neue";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0.06em;
  text-align: center;
  background: #ffc122;
  width: 128px;
  padding: 5px;
  border: 1px solid #ffc122;
}

.solution-button:hover {
  background: #ffffff;
}

.solution-text {
  font-family: Source Sans 3;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
  text-align: justify;
  text-justify: inter-word;
}

.return-button {
  font-family: "Bebas Neue";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0.06em;
  text-align: center;
  text-transform: uppercase;
  height: 40px;
  padding-left: 20px;
  padding-right: 20px;
  border: 1pt solid #ffc122;
  color: #ffc122;
  background: #00000000;
  white-space: nowrap;
}

.return-button:hover {
  border: 1px solid #ffffff;
  box-shadow: 3px 4px 3px 0px #00000050;
  color: #ffffff;
}

.social-network-logo {
  height: 64px;
}
</style>
