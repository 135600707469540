const currentGameMixin = {
  data() {
    return {
      publicPath: process.env.BASE_URL,
    }
  },
  computed: {
    caseID: function () {
      return this.$store.state.currentGame;
    },
    casePublicPath: function() {
      return `${this.publicPath}${this.caseID}`;
    },
    casePublicAssets: function() {
      return `${this.casePublicPath}/assets`;
    },
    casePublicImages: function() {
      return `${this.casePublicAssets}/images`;
    },
    casePublicAudio: function() {
      return `${this.casePublicAssets}/audio`;
    },
    casePublicVideos: function() {
      return `${this.casePublicAssets}/videos`;
    },    
    casePublicHtml: function() {
      return `${this.casePublicAssets}/html`;
    },
    casePublicData: function() {
      return `${this.casePublicPath}/data`;
    },
    caseImages: function() {
      return `@/assets/images/${this.caseID}`;
    },
    caseAvailableLocales: function() {
      if (this.caseID === 'yakuza') {
        return this.$yakuzaStore.getAvailableLocales();
      } else if (this.caseID === 'festival') {
        return this.$festivalStore.getAvailableLocales();
      } else {
        return this.$store.getters.gameAvailableLocales(this.caseID);
      }
    },
    currentLocale: function() {
      const ambientLocale = this.$i18n.locale;

      if (this.caseAvailableLocales.findIndex(al => al.code === ambientLocale) !== -1)
        return ambientLocale;

      if(this.caseAvailableLocales.length > 0)
        return this.caseAvailableLocales[0].code;

      return "en";
    }
  },
  methods: {
    setCurrentGame: function(gameID) {
      this.$store.state.currentGame = gameID;
      this.$i18n.locale = this.currentLocale;
    }
  }
}

export default currentGameMixin;