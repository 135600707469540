
export default {
    availableLocales: [
        "fr"
    ],
    caseDetails: [
        "name",
        "location",
        "status"
    ], 
    sections: [
        {
            id: "envelopes",
            subsections: [
                {
                    id: "valise",
                    lockable: false,
                    locked: false,
                    route: { name: "festival-valise" }
                },
                {
                    id: "telephone",
                    lockable: true,
                    locked: true,
                    disabled: true,                
                    route: { name: "festival-telephone" }
                },
                {
                    id: "coffre",
                    lockable: true,
                    locked: true,
                    disabled: true,  
                    route: { name: "festival-coffre" }
                },                                                                             
            ]
        },
        {
            id: "evidences",
            subsections: [
                {
                    id: "video",
                    lockable: false,
                    locked: false,
                    disabled: true,
                    route: { name: "festival-video" }
                },
            ]
        },
    ],
    suitcase: {
        solution1: "647",
        solution2: "475" ,
        solved: false,       
    },
    telephone: {
        correctHash: "MTA1MDA0MzEw",
        solved: false,
    },
    coffre : {
        correctCode: 20101510,
        solved: false,
    },
}
