<template>
  <destination-template
    :location="$t('mykonos.research.labels.location')"
    :coordinates="destination.coords"
    :title="$t('mykonos.data.destinations.hospital.title')"
    :returnRoute="{ path: '.' }"
  >
    <template v-slot:icon
      ><img src="@/assets/images/mykonos/destinations/hospital_pinpoint.svg" />
      ></template
    >
    <template v-slot:thumbnail
      ><v-img
        src="@/assets/images/mykonos/destinations/hospital_thumbnail.jpg"
      ></v-img
    ></template>
    <template v-slot:ui>
      <v-container v-if="!destination.searched">
        <v-row>
          <v-col class="d-flex justify-center ui-message">
            {{ $t("mykonos.data.destinations.hospital.messages.patients") }}
          </v-col>
        </v-row>
        <v-row v-for="(patient, index) in patients" :key="`patient-${index}`">
          <v-col class="d-flex justify-center">
            <input
              type="text"
              v-model="patients[index].fullName"
              :placeholder="
                $t('mykonos.data.destinations.hospital.labels.patientName')
              "
              class="text-box"
              :class="{ 'invalid-input': isError(index) }"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex justify-center">
            <button @click="trySearch" class="submit-button">
              {{ $t("mykonos.research.buttons.submit") }}
            </button>
          </v-col>
        </v-row>
      </v-container>
      <v-row class="unlock-message" v-else>
        <v-col cols="12" class="d-flex justify-center ui-message">
          {{ $t("mykonos.research.messages.correctData") }}
        </v-col>
        <v-col cols="12" class="d-flex justify-center">
          <div class="envelope-message">
            {{ $t("mykonos.research.messages.envelopeUnlocked") }}&nbsp;{{
              $t("mykonos.data.destinations.hospital.envelope")
            }}
          </div>
        </v-col>
      </v-row>
    </template>
  </destination-template>
</template>
<script>
import DestinationTemplate from "../../components/mykonos/DestinationTemplate.vue";
import currentGameMixin from "../../mixins/currentGameMixin";

export default {
  name: "Hospital",
  data() {
    return {
      destinationID: "hospital",
      patients: [],
    };
  },
  mixins: [currentGameMixin],
  computed: {
    destination: function () {
      return this.$store.getters.mykonosDestination(this.destinationID);
    },
    patientsCount: function () {
      return this.destination.patients.length;
    },
  },
  components: {
    DestinationTemplate,
  },
  methods: {
    trySearch: function () {
      this.$store.dispatch(
        "mykonosTrySearchHospital",
        this.patients.map((p) => p.fullName)
      );
    },
    resetSearchError: function () {
      this.$store.dispatch("mykonosResetDestinationErrors", this.destinationID);
    },
    isError: function (fieldIndex) {
      return this.destination.errors.indexOf(fieldIndex) !== -1;
    },
  },
  mounted() {
    const vm = this;

    this.$nextTick(() => {
      for (let i = 0; i < vm.patientsCount; i++) {
        vm.patients.push({ fullName: "", unknown: false });
      }
    });
  },
  watch: {
    patients: {
      deep: true,
      handler: function () {
        this.resetSearchError();
      },
    },
  },
};
</script>
<style scoped>
.ui-message {
  font-family: Source Sans 3;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.06em;
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
}

.error-message {
  border: 1px solid #ff2900;
  color: #ff2900;
  padding: 10px;
}

.text-box {
  background: #00000080;
  border: none;
  border-radius: 0%;
  height: 27px;
  min-width: 200px;
  font-family: Source Sans 3;
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.06em;
  text-align: left;
  text-transform: uppercase;
  color: #ffffff;
}

.text-box.invalid-input {
  background: #ff0000;
}

.submit-button {
  color: #ffc122;
  border: 1px solid #ffc122;
  font-family: Bebas Neue;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0.06em;
  text-align: center;
  text-transform: uppercase;
  width: 128px;
  height: 36px;
}

.envelope-message {
  font-family: Bebas Neue;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0.06em;
  text-align: center;
  text-transform: uppercase;
  color: #00ff29;
  border: 1px solid #00ff29;
  padding: 20px;
}
</style>