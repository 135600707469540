<template>
  <v-container>
    <police-header />
    <div>
      <v-row>
        <v-col class="pa-10">
          <router-link :to="{ name: 'maestro-police-home' }" class="home-link"
            ><v-img src="/maestro/assets/images/police/home.svg" class="icon" />{{
              $t(`${this.caseID}.police.buttons.home`)
            }}</router-link
          >
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6">
          <v-card>
            <v-card-title>{{
              $t(`${this.caseID}.laboratory.toxicology.title`)
            }}</v-card-title>
            <v-card-subtitle
              >{{ $t(`${this.caseID}.laboratory.toxicology.subtitle`) }}
            </v-card-subtitle>
            <v-row align="center">
              <v-col cols="12" xl="7">
                <v-autocomplete
                  v-model="toxicSearch"
                  :items="suspects"
                  :label="
                    $t(`${this.caseID}.laboratory.toxicology.placeholders.name`)
                  "
                  item-text="fullname"
                  :error-messages="toxicsError"
                  @change="toxicsError = ''"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="12" xl="5">
                <v-btn
                  v-bind:disabled="toxicSearch === undefined"
                  @click="searchToxic()"
                  >{{
                    $t(`${this.caseID}.laboratory.toxicology.buttons.search`)
                  }}</v-btn
                >
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6">
          <v-card>
            <v-card-title>{{
              $t(`${this.caseID}.laboratory.dna.title`)
            }}</v-card-title>
            <v-card-subtitle>{{
              $t(`${this.caseID}.laboratory.dna.subtitle`)
            }}</v-card-subtitle>
            <v-row align="center">
              <v-col cols="12" xl="7">
                <v-autocomplete
                  v-model="dnaSearchString"
                  @keypress.enter="searchDna()"
                  :items="dnas"
                  item-text="item"
                  item-value="reference"
                  :label="$t(`${this.caseID}.laboratory.dna.search.label`)"
                />
              </v-col>
              <v-col cols="12" xl="5">
                <v-btn
                  v-bind:disabled="dnaSearchString === ''"
                  @click="searchDna()"
                  >{{
                    $t(`${this.caseID}.laboratory.dna.buttons.analyze`)
                  }}</v-btn
                >
              </v-col>
            </v-row>
            <v-card-text class="terminal">
              <vue-typer
                id="typewriter"
                :text="dnaResultString"
                :repeat="0"
                :type-delay="10"
                @typed-char="onTypedChar"
              ></vue-typer>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-overlay absolute="absolute" :value="showToxicResult">
        <div class="report">
          <div
            class="report-title"
            v-text="$t(`${this.caseID}.laboratory.toxicology.report.title`)"
          ></div>
          <div class="identity-container">
            <div class="identity">
              <div class="details">
                <div
                  v-text="
                    $t(`${this.caseID}.laboratory.toxicology.report.name`)
                  "
                  class="label"
                ></div>
                <div v-text="toxicResult.fullName" class="value"></div>
              </div>
            </div>
          </div>
          <div class="tests-container">
            <div class="tests">
              <div class="result">
                <div class="label">
                  {{
                    $t(`${this.caseID}.laboratory.toxicology.tests.alcohol`)
                  }}&nbsp;:
                </div>
                <div v-text="getResultString('alcohol')" class="value"></div>
              </div>
              <div class="result">
                <div class="label">
                  {{
                    $t(`${this.caseID}.laboratory.toxicology.tests.nicotine`)
                  }}&nbsp;:
                </div>
                <div v-text="getResultString('nicotine')" class="value"></div>
              </div>
              <div class="result">
                <div class="label">
                  {{
                    $t(`${this.caseID}.laboratory.toxicology.tests.cocaine`)
                  }}&nbsp;:
                </div>
                <div v-text="getResultString('cocaine')" class="value"></div>
              </div>
              <div class="result">
                <div class="label">
                  {{
                    $t(`${this.caseID}.laboratory.toxicology.tests.cannabis`)
                  }}&nbsp;:
                </div>
                <div v-text="getResultString('cannabis')" class="value"></div>
              </div>
              <div class="result">
                <div class="label">
                  {{
                    $t(`${this.caseID}.laboratory.toxicology.tests.other`)
                  }}&nbsp;:
                </div>
                <div v-text="getOtherResultsString()" class="value"></div>
              </div>
            </div>
          </div>
          <img src="/maestro/assets/images/laboratory/toxic_stamp.jpg" class="stamp" />
          <div
            class="report-footer"
            v-text="$t(`${this.caseID}.laboratory.toxicology.report.footer`)"
          ></div>
          <v-btn
            dark
            absolute
            bottom
            right
            fab
            @click="hideToxicResult()"
            class="close-button"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </v-overlay>
    </div>
  </v-container>
</template>

<script>
import { VueTyper } from "vue-typer";
import PoliceHeader from "../../components/maestro/PoliceHeader.vue";
import currentGameMixin from "../../mixins/currentGameMixin";
import laboratoryMessages from "../../messages/maestro/laboratory.messages";

export default {
  name: "Laboratory",
  i18n: {
    messages: laboratoryMessages
  },
  data() {
    return {
      suspects: [],
      toxics: [],
      dnas: [],
      toxicSearch: "",
      toxicsError: "",
      dnaSearchString: "",
      toxicResult: {},
      dnaResult: {},
      typingAudio: {},
      showToxicResult: false,
      countWord: 0,
      publicPath: process.env.BASE_URL,
    };
  },
  components: {
    PoliceHeader,
    VueTyper,
  },
  mixins: [currentGameMixin],
  created() {
    this.suspects = require(`@/data/${this.caseID}/suspects.json`)
      .map((s) => {
        const sus = s;

        sus.fullname = `${s.lastname
          .toUpperCase()
          .trim()} ${this.$options.filters.firstname(s.firstname.trim())}`;
        return sus;
      })
      .sort((a, b) =>
        `${a.lastName} ${a.firstName}`.localeCompare(
          `${b.lastName} ${b.firstName}`
        )
      );

    this.toxics = require(`@/data/${this.caseID}/toxicology.json`)
      .map((t) => {
        const tm = t;

        tm.fullName = `${tm.lastName} ${tm.firstName}`;
        return tm;
      })
      .sort((a, b) => a.fullName.localeCompare(b.fullName));

    this.refreshDnas();
    this.typingAudio = new Audio(require("@/assets/audio/computer_beep2.mp3"));
  },
  computed: {
    locale: function () {
      return this.$i18n.locale;
    },
    dnaResultString() {
      let str = "";

      if (this.dnaResult && this.dnaResult.result) {
        str += this.dnaResult.result;

        if (this.dnaResult.comment && this.dnaResult.comment !== "")
          str += " ," + this.dnaResult.comment;
      }

      return str;
    },
  },
  methods: {
    refreshDnas: function () {
      this.dnas = require(`@/data/${this.caseID}/dna.json`)
        .map((d) => {
          return {
            reference: d.reference,
            item: this.$t(`${this.caseID}.data.dna.${d.reference}.item`),
            result: this.$t(`${this.caseID}.data.dna.${d.reference}.result`),
          };
        })
        .sort((a, b) => a.item.localeCompare(b.item));
    },
    searchDna() {
      if (this.dnaSearchString && this.dnaSearchString !== "")
        this.dnaResult = this.dnas.find(
          (d) => d.reference === this.dnaSearchString
        );
      else this.dnaResult = undefined;
    },
    searchToxic() {
      if (this.toxicSearch !== undefined) {
        const result = this.toxics.find(
          (t) =>
            t.fullName.toLowerCase().trim() ===
            this.toxicSearch.toLowerCase().trim()
        );

        if (result !== undefined) {
          this.toxicResult = result;
          this.showToxicResult = true;
        } else {
          this.toxicResult = {};
          this.showToxicResult = false;
          this.toxicsError = this.$t(
            `${this.caseID}.laboratory.toxicology.messages.noData`
          );
        }
      }
    },
    onTypedChar: function (typedChar, typedCharIndex) {
      // Fix word breaks
      if (typedCharIndex == 0) {
        document.getElementById("typewriter").firstChild.innerHTML = "";
      }
      var lessNodes =
        document.getElementById("typewriter").lastChild.childNodes;
      if (typedChar == " " || lessNodes.length == 1) {
        var finalNodes = document.getElementById("typewriter").firstChild;
        var listNodes = finalNodes.childNodes;

        var newNode = document.createElement("span");

        var x = this.countWord;
        var countNodes = listNodes.length;
        while (x < countNodes) {
          if (listNodes[this.countWord].innerHTML != " ")
            newNode.insertAdjacentElement(
              "beforeend",
              listNodes[this.countWord]
            );
          else this.countWord++;

          // TODO: ADD LAST CHAR
          x++;
        }
        newNode.style.cssText = "white-space: nowrap;";
        finalNodes.insertAdjacentElement("beforeend", newNode);

        this.countWord++;
      }
      this.typingAudio.play();
    },
    getResult: function (product) {
      if (this.toxicResult === undefined) return false;

      if (this.toxicResult.tests === undefined) return false;

      return (
        this.toxicResult.tests.find(
          (t) => t.toLowerCase().trim() === product.toLowerCase().trim()
        ) !== undefined
      );
    },
    getResultString: function (product) {
      return this.getResult(product)
        ? this.$t(`${this.caseID}.laboratory.toxicology.results.positive`)
        : this.$t(`${this.caseID}.laboratory.toxicology.results.negative`);
    },
    getOtherResultsString: function () {
      const exclusions = ["alcohol", "nicotine", "cocaine", "cannabis"];

      if (this.toxicResult === undefined) return "";

      if (this.toxicResult.tests === undefined) return "";

      return this.toxicResult.tests
        .filter((t) => exclusions.indexOf(t) === -1)
        .map((t) => this.$t(`${this.caseID}.laboratory.toxicology.tests.${t}`))
        .sort()
        .join(", ");
    },
    hideToxicResult: function () {
      this.showToxicResult = false;
    },
  },
  watch: {
    locale: function () {
      this.refreshDnas();
      this.searchDna();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import "~@/assets/css/terminal.css";
.home-link {
  text-decoration: none;
  color: #1e43a3;
}

.home-link:hover {
  text-decoration: underline;
}

.icon {
  float: left;
  width: 32px;
  margin-right: 10px;
  position: relative;
  top: -5px;
}

.report {
  aspect-ratio: 21 / 29.7;
  width: 100vw;
  max-height: 100vh;
  background: #ffffff;
  position: fixed;
  top: 50vh;
  left: 50vw;
  transform: translate(-50%, -50%);
  color: #000000;
  background-image: url(/maestro/assets/images/laboratory/toxic_bg.jpg);
  background-size: contain;
  background-repeat: no-repeat;
  margin-left: auto;
  margin-right: auto;
  max-width: 21cm;
}

.report-title {
  position: absolute;
  top: 15%;
  left: 5%;
  color: #000000;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1em;
}

.identity-container {
  position: absolute;
  top: 27%;
  left: 5%;
  font-size: 1em;
}

.identity {
  display: table;
}

.identity .details {
  display: table-row;
}

.identity .label,
.identity .value {
  display: table-cell;
}

.identity .label {
  text-transform: capitalize;
  font-weight: bold;
}

.identity .value {
  padding-left: 10px;
}

.tests-container {
  position: absolute;
  top: 30%;
  left: 15%;
  font-size: 1em;
}

.tests {
  display: table;
}

.tests .result {
  display: table-row;
}

.tests .result .label,
.tests .result .value {
  display: table-cell;
  color: #000000;
  padding-top: 5%;
}

.tests .result .label {
  text-transform: capitalize;
  font-weight: bold;
  text-align: right;
  font-family: "Roboto";
}

.tests .result .value {
  font-family: "Courier";
  padding-left: 30px;
}

.stamp {
  position: absolute;
  bottom: 12%;
  left: 50%;
  width: 40%;
  transform: translate(-50%, 0%);
}

.report-footer {
  position: absolute;
  bottom: 2%;
  left: 50%;
  transform: translate(-50%, 0%);
  text-align: center;
  font-family: "Roboto";
  font-weight: bold;
  font-size: 0.8em;
}

.close-button {
  transform: translate(0%, -100%);
}

.terminal {
  background-color: black;
  border-radius: 0px;
  min-height: 200px;
}

.police-container {
  background: #ffffff;
}

.police-frame {
  background: #00000066;
  border: 1px solid #ffffff;
  border-radius: 7px;
  padding: 19px;
}
</style>
