export default {
    id: "envelope",
    subsections: [
        {
            id: "ouija",
            lockable: true,
            locked: true,
            route: {
                name: "ghosts-ouija"
            }
        },
        {
            id: "daily",
            lockable: true,
            locked: true,
            disabled: true,
            route: {
                name: "ghosts-daily"
            }
        },
        {
            id: "police",
            lockable: true,
            locked: true,
            disabled: true,
            route: {
                name: "ghosts-police"
            }
        },
        {
            id: "hotel",
            lockable: true,
            locked: true,
            disabled: true,
            route: {
                name: "ghosts-hotel"
            }
        }
    ]
}