export function comparePersonChoices(ch1, ch2) {
    return ch1.localizedValue.split(' ').reverse().join(' ').localeCompare(ch2.localizedValue.split(' ').reverse().join(' '));
}

export function compareSolutionPersonChoices(ch1, ch2) {
    return comparePersonChoices(ch1.value, ch2.value);
}

export const solutionChoicesComparisonFunctions = {
    personsByLastnameAsc: compareSolutionPersonChoices
}
