<template>
  <v-container class="tourist-shop-container">
    <v-row>
      <v-col v-for="product in products" :key="product.id" cols="12" sm="6" md="4" lg="3" class="d-flex justify-center">
        <figure class="tourist-product-wrapper">
          <img :src="getProductImageSrc(product)" />
          <figcaption>{{ getProductDescription(product) }}</figcaption>
        </figure></v-col
      >
    </v-row>
  </v-container>
</template>
<script>
import axios from "axios";
import currentGameMixin from "../../mixins/currentGameMixin";

export default {
  name: "TouristOfficeShop",
  mixins: [currentGameMixin],
  data() {
    return {
      products: [],
    };
  },
  computed: {
    productsDataSrc: function () {
      return `${this.casePublicData}/shop.json`;
    },
  },
  methods: {
    fetchProducts: async function () {
      try {
        const { data } = await axios.get(this.productsDataSrc);
        this.products = data;
      } catch (e) {
        console.error("Could not fetch products data", e);
      }
    },
    getProductImageSrc: function (product) {
      return `${this.casePublicImages}/shop/${product.id}.jpg`;
    },
    getProductDescription: function (product) {
      const desc = this.$t(`ghosts.data.touristOffice.shop.products.${product.id}`);

      if (product.reference) {
        return `${desc} - ${product.reference}`;
      }

      return desc;
    },
  },
  mounted() {
    this.fetchProducts();
  },
};
</script>
<style scoped>
.tourist-shop-container {
  margin-top: 49px;
  text-align: center;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
}
.tourist-product-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 146px;
  
}
.tourist-product-wrapper img {
  height: 146px;
}
.tourist-product-wrapper figcaption {
  margin-top: 6px;
  max-width: 146px;
  word-wrap: break-word;
  text-align: center;
}
</style>
