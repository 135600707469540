<template>
  <v-container class="darkweb-container">
    <div class="dark-bg">
      <v-row v-if="!loggedIn"
        ><v-col class="d-flex justify-center">
          <svg-ascii-art
            :ascii="ascii"
            class="ascii-art"
            @complete="onAsciiComplete"
          ></svg-ascii-art> </v-col
      ></v-row>
      <v-row v-if="asciiComplete && !loggedIn" class="padlock-row"
        ><v-col cols="12" class="d-flex justify-center padlock-title">{{
          $t("colossus.darkweb.labels.padlock")
        }}</v-col>
        <v-col cols="12" class="d-flex justify-center">
          <svg-padlock
            :secretLength="secretLength"
            secretChar="?"
            :locked="locked"
            @codeChange="onPadlockCodeChange"
            class="padlock-ui"
            ref="padlock"
          >
          </svg-padlock>
        </v-col>
        <v-col cols="12" class="d-flex justify-center">
          <button class="padlock-button" @click="resetPadlock">
            {{ $t("colossus.darkweb.buttons.retry") }}
          </button>
        </v-col></v-row
      >
      <v-row v-if="loggedIn"
        ><v-col>
          <v-container>
            <v-row>
              <v-col class="darkweb-banner">
                <div class="darkweb-headline">
                  ________________________| www.party.com
                  |________________________
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="darkweb-ascii" v-if="$vuetify.breakpoint.smAndUp">
                <pre>{{ gunLeftAscii }}</pre>
              </v-col>
              <v-col class="darkweb-baseline">
                {{ $t("colossus.darkweb.baseline") }}
              </v-col>
              <v-col
                class="darkweb-ascii ascii-flip"
                v-if="$vuetify.breakpoint.smAndUp"
              >
                <pre>{{ gunLeftAscii }}</pre>
              </v-col>
            </v-row>
            <v-row v-for="item in chatData" :key="`chat-${item.id}`" dense>
              <v-col cols="12" class="chat-timestamp">
                {{ 
                  $t(`${caseID}.data.darkweb.chat.${item.id}.time`) 
                }}
              </v-col>
              <v-col cols="12" class="chat-item">
                <span class="chat-who">{{ 
                  $t(`${caseID}.data.darkweb.chat.${item.id}.from`) 
                }}:&nbsp;</span>
                <span class="chat-message">{{
                  $t(`${caseID}.data.darkweb.chat.${item.id}.text`)
                }}</span>
                <p></p>
              </v-col>
            </v-row>
          </v-container> </v-col
      ></v-row>
    </div>
  </v-container>
</template>
<script>
import SvgAsciiArt from "@/components/colossus/SvgAsciiArt";
import SvgPadlock from "@/components/colossus/SvgPadlock.vue";
import axios from "axios";
import currentGameMixin from "../../mixins/currentGameMixin";

export default {
  data() {
    return {
      publicPath: process.env.BASE_URL,
      ascii: "",
      asciiComplete: false,
      chatData: [],
      loginDelay: 2000,
      loggedIn: false,
      gunLeftAscii: "",
    };
  },
  components: {
    SvgAsciiArt,
    SvgPadlock,
  },
  mixins: [currentGameMixin],
  computed: {
    gunLeftPath: function () {
      return `${this.publicPath}${this.caseID}/assets/gun_left_ascii.txt`;
    },
    asciiPath: function () {
      return `${this.publicPath}${this.caseID}/assets/ascii_skull2.txt`;
    },
    dataPath: function () {
      return `${this.publicPath}${this.caseID}/data/chat.json`;
    },
    locked: function () {
      return this.$store.getters.colossusDarkwebLocked;
    },
    secretLength: function () {
      return this.$store.getters.colossusDarkwebSecretLength;
    },
  },
  methods: {
    onPadlockCodeChange(code) {
      this.$store.dispatch("colossusTryUnlockDarkweb", code);
    },
    onAsciiComplete: function () {
      this.asciiComplete = true;
    },
    resetPadlock: function () {
      this.$refs.padlock.reset();
    },
  },
  watch: {
    locked: function (newValue) {
      if (!newValue) {
        setTimeout(() => {
          this.loggedIn = true;
        }, this.loginDelay);
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      axios.get(this.gunLeftPath).then((res) => {
        this.gunLeftAscii = res.data;
      });

      axios.get(this.asciiPath).then((res) => {
        this.ascii = res.data;
      });

      axios.get(this.dataPath).then((res) => {
        this.chatData = res.data;
      });

      if (!this.locked) this.loggedIn = true;
    });
  },
};
</script>
<style>
.darkweb-container {
  border: 1px solid #ffffff;
  border-radius: 7px;
  padding: 19px;
}
.dark-bg {
  background: #000000;
  width: 100%;
}
.darkweb-banner {
  background: #00ff294d;
  overflow: clip;
  position: relative;
  height: 72px;
  margin-bottom: 10px;
}
.darkweb-headline {
  color: #00ff29;
  font-family: Oxygen Mono;
  font-size: 25px;
  font-weight: 400;
  line-height: 33px;
  letter-spacing: 0.06em;
  text-align: center;
  white-space: nowrap;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.darkweb-ascii {
  font-family: Oxygen Mono;
  font-size: 7px;
  font-weight: 400;
  line-height: 9px;
  letter-spacing: 0.06em;
  text-align: left;
  color: #00ff29;
}
.ascii-flip {
  transform: rotateY(0.5turn);
}
.darkweb-baseline {
  font-family: Oxygen Mono;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.06em;
  text-align: center;
  color: #00ff29;
  padding-bottom: 30px !important;
}
.ascii-art {
  max-width: 100%;
}
.padlock-ui {
  width: 100%;
  max-width: 250px;
}
.padlock-row {
  background: #00ff291a;
}
.padlock-title {
  font-family: Oxygen Mono;
  font-size: 17px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.09em;
  text-align: center;
  text-transform: uppercase;
  color: #00ff29;
}
.padlock-button {
  font-family: Oxygen Mono;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.09em;
  text-align: center;
  text-transform: uppercase;
  color: #00ff29;
}
.padlock-button:hover {
  background: #00ff29;
  color: #000000;
}
.chat-timestamp {
  font-family: Oxygen Mono;
  font-size: 10px;
  font-weight: 400;
  line-height: 13px;
  letter-spacing: 0.06em;
  text-align: left;
  color: #ffffff;
}
.chat-item {
  font-family: Oxygen Mono;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.06em;
  text-align: left;
}
.chat-who {
  color: #ffffff;
}
.chat-message {
  color: #00ff29;
}
</style>