<template>
  <div>
    <v-list color="#00000000" class="playlist pa-0">
      <v-list-item
        v-for="(track, index) in playlist"
        :key="track.title"
        v-show="track.display"
        class="playlist-item"
        :class="[
          { selected: track === selectedTrack },
          { even: index % 2 == 0 },
          { odd: index % 2 != 0 },
        ]"
        @click="selectTrack(track)"
      >
        <v-list-item-content class="playlist">
          {{ index | numbers }} {{ track.artist }} -
          {{ track.title }}
        </v-list-item-content>
        <!-- <v-spacer></v-spacer> -->
        <span class="playlist">
          {{ track.howl.duration() | minutes }}
        </span>
      </v-list-item>
    </v-list>
  </div>
</template>
<script>
export default {
  data() {
    return {
      clicks: 0,
      clickDelay: 700,
    };
  },
  props: {
    playlist: Array,
    selectedTrack: Object,
  },
  methods: {
    selectTrack(track) {
      this.$emit("selecttrack", track);

      this.clicks++;
      if (this.clicks === 1) {
        this.timer = setTimeout(() => {
          this.result.push(event.type);
          this.clicks = 0;
        }, this.delay);
      } else {
        clearTimeout(this.timer);
        this.result.push("dblclick");
        this.clicks = 0;
      }
    },
    playTrack(index) {
      this.$emit("playtrack", index);
    },
  },
};
</script>
<style scoped>
.selected {
  background-color: #ffc12246 !important;
}
.even {
  background: #00000066;
}
.odd {
  background: #00000099;
}
.playlist {
  font-family: Source Sans 3;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  text-transform: uppercase;
  color: #ffffff;
}

.playlist-item:hover {
  background: #ffc12266;
}
</style>