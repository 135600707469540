<template>
  <svg
    width="60"
    height="60"
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_251_801)" class="eye-container" @click="onClick">
      <path
        class="eye-bg"
        d="M27 50C40.8071 50 52 38.8071 52 25C52 11.1929 40.8071 0 27 0C13.1929 0 2 11.1929 2 25C2 38.8071 13.1929 50 27 50Z"
        fill="#FFC122"
      />
      <g class="eye-outline">
        <path
          d="M29.1114 13.7779L28.2225 16.4446C27.0139 16.2271 25.7727 16.2734 24.5837 16.5802C23.3947 16.8871 22.286 17.4472 21.3336 18.2224C19.6574 19.6359 18.5518 21.6102 18.2225 23.7779C18.24 24.0059 18.2019 24.2347 18.1114 24.4446V24.889C18.0961 26.4891 18.5374 28.0602 19.3837 29.4182C20.23 30.7762 21.446 31.8646 22.8892 32.5557L21.778 35.5557C13.8892 33.2224 8.22249 26.4446 7.55583 25.6668L6.88916 24.7779L7.55583 23.889C9.12763 22.0209 10.8765 20.3092 12.778 18.7779L12.8892 18.6668L14.5558 21.0002L14.4447 21.1113C13.0078 22.2155 11.6695 23.4423 10.4447 24.7779C12.1174 26.6426 14.0217 28.2855 16.1114 29.6668C15.3642 28.1464 14.9837 26.472 15.0003 24.7779C15.0192 24.0327 15.0935 23.2899 15.2225 22.5557C15.4628 21.3903 15.8747 20.2669 16.4447 19.2224C17.5782 17.2185 19.2792 15.5948 21.3336 14.5557C23.4993 13.4809 25.9416 13.0932 28.3336 13.4446L29.1114 13.7779Z"
          fill="#020202"
        />
        <path
          d="M31.2225 25.1112C31.2041 26.1805 30.8008 27.2074 30.0864 28.0033C29.3721 28.7993 28.3947 29.311 27.3336 29.4445H26.8892C25.9073 29.4517 24.9575 29.0955 24.2225 28.4445C23.3454 27.708 22.7864 26.6614 22.6622 25.5228C22.538 24.3842 22.8581 23.2417 23.5558 22.3334C23.9074 21.8926 24.3447 21.5275 24.8412 21.2601C25.3377 20.9928 25.8831 20.8287 26.4447 20.7778H27.3336L26.6669 21.3334L26.4447 21.4445C26.1014 21.8492 25.9055 22.3585 25.8892 22.8889V23.0001C25.8919 23.286 25.9521 23.5684 26.0661 23.8306C26.1802 24.0928 26.3458 24.3294 26.5531 24.5264C26.7604 24.7233 27.0051 24.8765 27.2728 24.977C27.5405 25.0775 27.8257 25.1231 28.1114 25.1112C28.3039 25.1401 28.5004 25.1008 28.6669 25.0001C29.0389 24.8981 29.378 24.701 29.6507 24.4283C29.9234 24.1555 30.1205 23.8165 30.2225 23.4445L30.4447 22.6667L30.778 23.4445C31.0792 23.9475 31.2332 24.525 31.2225 25.1112Z"
          fill="#020202"
        />
        <path
          d="M25.0002 36.3335L25.8891 33.5557C27.0977 33.7732 28.339 33.7269 29.528 33.4201C30.717 33.1132 31.8256 32.5531 32.778 31.7779C33.6372 31.1027 34.3544 30.2643 34.8883 29.3108C35.4222 28.3574 35.7624 27.3078 35.8891 26.2224C35.8716 25.9944 35.9098 25.7657 36.0002 25.5557V25.0002C36.0102 23.4013 35.5666 21.8323 34.721 20.4753C33.8753 19.1183 32.6622 18.0289 31.2225 17.3335L32.2225 14.3335C40.1114 16.6668 45.778 23.4446 46.3336 24.2224L47.0002 25.1113L46.3336 26.0002C44.7618 27.8683 43.0129 29.58 41.1114 31.1113L41.0002 31.2224L39.3336 28.8891L39.4447 28.7779C40.8816 27.6737 42.2199 26.4469 43.4447 25.1113C41.7449 23.2418 39.8446 21.5651 37.778 20.1113C38.5252 21.6317 38.9057 23.3061 38.8891 25.0002C38.8702 25.7454 38.7959 26.4882 38.6669 27.2224C38.4452 28.393 38.0323 29.5192 37.4447 30.5557C36.3434 32.5844 34.6335 34.2165 32.5558 35.2224C30.3901 36.2972 27.9478 36.6849 25.5558 36.3335H25.0002Z"
          fill="#020202"
        />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_d_251_801"
        x="0"
        y="0"
        width="60"
        height="60"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="3" dy="5" />
        <feGaussianBlur stdDeviation="2.5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.36 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_251_801"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_251_801"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
</template>
<script>
export default {
  methods: {
    onClick: function () {
      this.$emit("click");
    },
  },
};
</script>
<style scoped>
.eye-container:hover .eye-bg {
  fill: #ffffff;
}

.eye-container:hover .eye-outline path {
  fill: #e58c08;
}

.eye-container {
  pointer-events: all;
  cursor: pointer;
}
</style>