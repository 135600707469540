export default {
    questions: [
        {
            id: "question1",
            answers: ["jane"]
        },
        {
            id: "question2",
            answers: ["poker"]
        },
        {
            id: "question3",
            answers: ["2012"]
        }
    ]
}