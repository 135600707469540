<template>
  <v-container class="w-container">
    <div class="w-header" v-if="locked">
      <img :src="casePublicImages + '/webmail/logo_universites.svg'" />
    </div>
    <div class="w-header w-lille" v-if="!locked">
      <img :src="casePublicImages + '/webmail/logo_lille.svg'" />
    </div>
    <div class="w-bandeau" v-if="!locked">
      webmail université
    </div>    
    <div class="w-body" v-if="locked">
      <div class="w-title">{{ $t("egypt.webmail.title1") }}</div>
      <div class="w-title">{{ $t("egypt.webmail.title2") }}</div>
      <v-alert 
        class="w-login-error ml-4 mr-4"
        type="error" 
        elevation="3" 
        v-if="isError"
      >
        {{  $t("egypt.webmail.wrongPassword") }}
      </v-alert>
      <div class="w-login">
        <v-text-field
          class="ml-4 mr-4"
          :label="$t('egypt.webmail.university')"
          :class="university === '' || focused === 'university' ? '' : errorDetail.university ? 'border-green' : 'border-red'"
          outlined
          v-model="university"
          @focus="inactivateCheck('university')"
          @blur="loginCheck"          
        ></v-text-field>
        <v-text-field
          class="ml-4 mr-4"
          :label="$t('egypt.webmail.name')"
          :class="name === '' || focused === 'name' ? '' : errorDetail.name ? 'border-green' : 'border-red'"          
          outlined
          v-model="name"
          @focus="inactivateCheck('name')"
          @blur="loginCheck"
        ></v-text-field>
        <v-text-field
          class="ml-4 mr-4"
          :label="$t('egypt.webmail.dob')"
          :class="dob === '' || focused === 'dob' ? '' : errorDetail.dob ? 'border-green' : 'border-red'"
          outlined
          v-model="dob"
          ref="dob"
          :placeholder="$t('egypt.webmail.dob_example')"
          :rules="[dobCheck]"
          @focus="inactivateCheck('dob')"
          @blur="loginCheck"
        ></v-text-field>
        <v-text-field
          class="ml-4 mr-4"
          :label="$t('egypt.webmail.studentnum')"
          :class="studentnum === '' || focused === 'studentnum' ? '' : errorDetail.studentnum ? 'border-green' : 'border-red'"
          outlined
          v-model="studentnum"
          @focus="inactivateCheck('studentnum')"
          @blur="loginCheck"          
        ></v-text-field>
        <div class="ml-4 mr-4">
          <v-btn 
            block
            large
            @click="login"
          >
            {{ $t("egypt.webmail.connection") }}
          </v-btn>
        </div>

      </div>
    </div>
    <v-list three-line class="email-list" v-if="!locked">
      <v-list-item-group v-model="selected" active-class="cyan--text">
        <div v-for="email in emails" :key="email.guid" class="email-container">
          <v-list-item @click="readEmail(email.guid)" class="email-item">
            <v-list-item-avatar :color="email.avatarColor">
              <span class="white--text text-h5">{{
                email.fromName | initial
              }}</span>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title 
                class="email-from"
                v-html="email.fromName">
              </v-list-item-title>
              <v-list-item-subtitle
                class="email-title"
                v-html="email.subject"
              ></v-list-item-subtitle>
              <v-list-item-subtitle
                class="email-excerpt"
                v-text="
                  email.content
                    .replace(/<p[^>]*>/g, '')
                    .replace(/<\/p>/g, ' ')
                "
              >
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </div>
      </v-list-item-group>
    </v-list>

  </v-container>
</template>


   
<script>
import axios from "axios";
import currentGameMixin
 from "../../mixins/currentGameMixin";
export default {
  name: "Webmail",
  data: () => ({
    publicPath: process.env.BASE_URL,
    emails: [],
    selected: -1,
    university: "",
    name: "",
    dob: "",
    studentnum: "",
    formHasErrors: false,
    focused: "",
  }),
  mixins: [currentGameMixin],
  computed: {
    locale: function () {
      return this.$i18n.locale;
    },    
    dataPath: function () {
      return `${this.publicPath}${this.caseID}/data`;
    },
    filePath: function() {
      return `${this.dataPath}/webmail_${ this.$i18n.locale }.json`;
    },
    locked: function () {
      return this.$store.getters.egyptWebmailLocked;
    },
    isError: function() {
      return this.$store.getters.egyptWebmailError;
    },
    errorDetail: function() {
      console.log(this.$store.getters.egyptWebmailErrorDetail)
      return this.$store.getters.egyptWebmailErrorDetail;
    },
    form: function() {
      return {
        university: this.university,
        name: this.name,
        dob: this.dob,
        studentnum: this.studentnum,
      }
    }
  },
  methods: {
    login: function () {
      this.$store.dispatch("egyptTryUnlockWebmail", this.form);
    },
    readEmail: function (guid) {
      this.$router.push({
        name: "egypt-webmail-reader",
        params: { guid: guid },
      });
    },
    getLocalisedMails: function () {
      axios.get(this.filePath).then(({ data }) => {
        this.emails = data;
      });
    },
    dobCheck: function() {
      if(this.dob === "")
        return true;

      let regexp = /^(?:0[1-9]|[12]\d|3[01])(?:[/])(?:0[1-9]|1[012])(?:[/])(?:19|20)\d\d$/i;
      return regexp.test(this.dob) ? true :  this.$i18n.t('egypt.webmail.dob_error');
    },
    loginCheck: function() {
      this.focused = "";
      console.log(this.form);
      this.$store.dispatch("egyptCheckWebmailLogin", this.form);

    },
    inactivateCheck: function(w) {
      this.focused = w;
    },    
  },
  watch: {
    university: function () {
      this.$store.dispatch("egyptResetWebmailError");
    },/*
    name: function () {
      this.$store.dispatch("egyptResetWebmailError");
    },*/
    dob: function () {
      this.$store.dispatch("egyptResetWebmailError");
    },
    studentnum: function () {
      this.$store.dispatch("egyptResetWebmailError");
    },            
    locale: function () {
      this.getLocalisedMails(); 
    },
  },
  mounted() {
    this.getLocalisedMails();

  },
};
</script>
<style lang="scss" scoped>
::v-deep .v-input__slot {
  background-color: #ffffff !important;
}

::v-deep .v-text-field--outlined.border-green fieldset {
  border-color: #009900;
  border-width: 2px;
}
::v-deep .v-text-field--outlined.border-red fieldset {
  border-color: #990000;
  border-width: 2px;
}
.w-container {
  position: relative;
  background: rgba(0, 0, 0, 0.4);
  border: 1px solid #FFFFFF;
  box-shadow: 8px 6px 7px rgba(0, 0, 0, 0.3);
  border-radius: 7px;
  padding: 19px !important;
  display: block !important;
  height: auto !important;
  width: 100%;
  text-align: center;

  .w-header {
    height: 100px;
    background: center/cover url(/egypt/assets/images/webmail/header_universite.jpg);
    text-align: left;

    img {
      height: 50px;
      margin: 25px 0px 0px 12px; 
    }
    &.w-lille {
      background: #ffffff;
    }    
  }
  .w-bandeau {
    height: 40px;
    color: #FFF;
    text-align: center;
    font: normal 500 14px Roboto;
    text-transform: uppercase;
    background: #7A1818;
    padding-top: 10px;
  }
  .w-body {
    background-color: #ffffff;
    width: 100%;
    min-height: 320px;
    padding: 20px 0px;

    .w-title {
      color: #000000;
      font: normal 400 17px/20px 'Roboto';
      text-align: center;
    }

    .w-login-error {
      width: 100%;
      max-width: 400px;
      margin: auto !important;
      margin-top: 16px !important;
      font-size: 14px;
    }

    .w-login {
      max-width: 400px;
      width: 100%;
      background: #F0F0F0;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 5px;
      margin: 20px auto;
      padding: 20px;  
      
      button {
        background-color: #16257A;
        color: #ffffff;
        color: #FFF;
        font-family: Roboto;
        letter-spacing: 1.89px;
        text-transform: uppercase;
      }
    }
  }

  .email-list {
    min-height: 380px;
    padding-top: 2px;
  }

  .email-container {
    padding: 2px;
    text-align: left;
  }
  .email-item {
    background: #f0f0f0;
  }

  .email-from {
    text-align: left !important;
    color: #000;
    font-size: 16px;
    font-family: Plus Jakarta Sans;
  }
  .email-title {
    text-align: left;
    color: #333 !important;
    font-size: 13px;
    font-family: Plus Jakarta Sans;
  } 
  .email-excerpt {
    word-break: keep-all;
    text-align: left;
    color: #7F7F7F;
    font-size: 13px;
    font-family: Plus Jakarta Sans;
    font-style: italic;    
  }  
}
</style>