<template>
  <div class="section-frame fill-height">
    <div class="police-container">
      <v-overlay absolute :value="advertiseUnlock">
        <div class="dialog-frame">
          <p>
            <span v-html="$t('ghosts.police.messages.envelope')"></span><br />
            <span v-html="$t('ghosts.police.messages.envelopeContent')"></span>
          </p>
          <p v-html="$t('ghosts.police.messages.sectionEnabled')"></p>
        </div>
      </v-overlay>
      <div class="police-headline">
        <div>
          <div>
            <img
              src="@/assets/images/ghosts/police/police_emblem.svg"
              class="police-emblem"
            />
          </div>
          <div>
            <img
              src="@/assets/images/ghosts/police/police_logo.svg"
              class="police-logo"
            />
          </div>
        </div>
      </div>
      <div class="police-banner">
        <div class="police-baseline">
          {{ $t("ghosts.police.baseline") }}
        </div>
      </div>
      <div class="police-title">
        {{ $t("ghosts.police.title") }}
      </div>
      <div class="police-chat">
        <rpg-chat
          :chatId="chatId"
          @chatComplete="onChatComplete"
          @chatDeadend="onChatDeadend"
          ref="chat"
        ></rpg-chat>
      </div>
      <div v-if="chatDeadend">
        <v-btn @click="resetChat">Recommencer</v-btn>
      </div>
    </div>
    <audio :src="unlockSoundSrc" ref="unlockSound"></audio>
  </div>
</template>
<script>
import RpgChat from "../../components/ghosts/RpgChat";
import currentGameMixin from "../../mixins/currentGameMixin";

export default {
  name: "Police",
  data() {
    return {
      sectionId: "police",
      chatId: "police",
      chatDeadend: false,
      advertiseUnlock: false,
    };
  },
  components: {
    RpgChat,
  },
  mixins: [currentGameMixin],
  computed: {
    isLocked: function() {
      return this.$store.getters.ghostsPoliceLocked;
    },
    unlockSoundSrc: function() {
      return `${this.casePublicAudio}/common/unlock_sound.mp3`;
    },
  },
  methods: {
    resumeChat: function() {
      this.$refs.chat.resumeChat();
      this.chatDeadend = false;
    },
    resetChat: function() {
      this.$refs.chat.resetChat();
      this.chatDeadend = false;
    },
    onChatComplete: async function() {
      console.log("Chat complete");
      await this.unlock();

      setTimeout(() => {
        this.advertiseUnlock = true;
      }, 4000);
    },
    onChatDeadend: function() {
      console.log("Chat is a deadend");
      this.chatDeadend = true;
    },
    unlock: async function() {
      await this.$store.dispatch("unlockSection", {
        gameID: this.caseID,
        sectionID: this.sectionId,
      });

      await this.$store.dispatch("enableSection", {
        gameID: this.caseID,
        sectionID: 'hotel',
      });
    },
    playUnlockSound: function() {
      this.$refs.unlockSound.play();
    },
  },
  watch: {
    advertiseUnlock: function(newValue, previousValue) {
      if (!previousValue && newValue) this.playUnlockSound();
    },
  },
  mounted: async function() {
    this.resumeChat();

    if (!this.isLocked) this.advertiseUnlock = true;
  },
};
</script>
<style scoped>
.police-container {
  background: #ffffff;
  position: relative;
}
.police-headline {
  display: table;
}
.police-headline > div {
  display: table-row;
}
.police-headline > div > div {
  display: table-cell;
  vertical-align: middle;
  padding-top: 22px;
  padding-bottom: 22px;
}
.police-emblem {
  height: 54px;
  vertical-align: middle;
  margin-left: 31px;
}
.police-logo {
  height: 39px;
  vertical-align: middle;
  margin-left: 31px;
}
.police-banner {
  background-image: url(~@/assets/images/ghosts/police/police_banner.jpg);
  background-size: cover;
  background-position-y: 40%;
  height: 183px;
  position: relative;
}
.police-baseline {
  position: absolute;
  left: 50%;
  bottom: 22px;
  transform: translate(-50%, 0);
  font-family: Arimo;
  font-size: 17px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
  max-width: 284px;
  background: #11111199;
  padding: 8px 23px 6px;
}
.police-title {
  font-family: Arimo;
  font-size: 18px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
  color: #3263aa;
  text-transform: uppercase;
  margin-top: 21px;
}
.police-chat {
  padding: 31px;
}
.police-chat::v-deep .chat-log-header {
  background: #3263aa;
}
</style>
