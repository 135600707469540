export default {
    fr: {
        maestro: {
            laboratory: {
                toxicology: {
                    title: "Analyse toxicologique",
                    subtitle: "Entrez un nom pour obtenir une analyse toxicologique",
                    placeholders: {
                        name: "NOM Prénom"
                    },
                    buttons: {
                        search: "Rechercher"
                    },
                    messages: {
                        noData: "Aucune donnée disponible"
                    },
                    results: {
                        positive: "positif",
                        negative: "négatif"
                    },
                    report: {
                        title: "rapport d'analyse de toxicologie",
                        name: "nom et prénom",
                        footer: "Les analyses sont fiables sur une durée de 3 mois précédant le prélèvement."
                    },
                    tests: {
                        anxiolytics: "anxiolytiques",
                        opioids: "opioïdes",
                        ibuprofen: "ibuprofène",
                        vicodin: "vicodin",
                        alcohol: "alcool",
                        nicotine: "nicotine",
                        cocaine: "cocaïne",
                        cannabis: "cannabis",
                        other: "autres"
                    }
                },
                dna: {
                    title: "Analyse ADN",
                    subtitle: "Que voulez-vous faire analyser?",
                    buttons: {
                        analyze: "Analyser"
                    },
                    search: {
                        label: "Sélectionner un élément à analyser"
                    }
                }
            }
        }
    },
    en: {
        maestro: {
            laboratory: {
                toxicology: {
                    title: "Toxicological analysis",
                    subtitle: " Enter the first and last name to obtain a toxicological analysis",
                    placeholders: {
                        name: "Surname Name"
                    },
                    buttons: {
                        search: "Search"
                    },
                    messages: {
                        noData: "No data available"
                    },
                    results: {
                        positive: "positive",
                        negative: "negative"
                    },
                    report: {
                        title: "Toxicology analysis report",
                        name: "name and surname",
                        footer: "The analyses are reliable over a period of 3 months preceding the sampling."
                    },
                    tests: {
                        anxiolytics: "anxiolytics",
                        opioids: "Opioids",
                        ibuprofen: "Ibuprofen",
                        vicodin: "Vicodin",
                        alcohol: "Alcohol",
                        nicotine: "Nicotine",
                        cocaine: "Cocaine",
                        cannabis: "Cannabis",
                        other: "Others"
                    }
                },
                dna: {
                    title: "DNA analysis",
                    subtitle: "What do you want to have analysed?",
                    buttons: {
                        analyze: "Analyze"
                    },
                    search: {
                        label: "Select an item to analyse"
                    }
                }
            }
        }
    },
    de: {
        maestro:
        {
            laboratory: {
                toxicology: {
                    title: "Toxikologische Analyse",
                    subtitle: "Geben Sie einen Namen und einen Vornamen ein, um eine toxikologische Analyse zu erhalten. ",
                    placeholders: {
                        name: "Namen Vornamen"
                    },
                    buttons: {
                        search: "Suchen"
                    },
                    messages: {
                        noData: "Keine Daten verfügbar"
                    },
                    results: {
                        positive: "Negativ",
                        negative: "Positiv "
                    },
                    report: {
                        title: "Bericht über die toxikologische Analyse",
                        name: "Name und Vorname",
                        footer: "Die Analysen sind zuverlässig über einen Zeitraum von drei Monaten vor der Probenahme."
                    },
                    tests: {
                        anxiolytics: "Anxiolytikum",
                        opioids: "Opiode",
                        ibuprofen: "Ibuprofen",
                        vicodin: "Vicodin",
                        alcohol: "Alkohol",
                        nicotine: "Nikotin ",
                        cocaine: "Kokain",
                        cannabis: "Cannabis",
                        autres: "Andere"
                    }
                },
                dna: {
                    title: "DNA-Analyse",
                    subtitle: "Was möchten Sie untersuchen lassen?",
                    buttons: {
                        analyze: "Analyse"
                    },
                    search: {
                        label: "Wählen Sie ein Element aus, das Sie analysieren möchten"
                    }
                }
            }
        }
    },
    es: {
        maestro: {
            laboratory: {
                toxicology: {
                    title: "Análisis toxicológico",
                    subtitle: "Entra un nombre y un apellido para obtener un análisis toxicológico",
                    placeholders: {
                        name: "Nombre y apellido"
                    },
                    buttons: {
                        search: "Buscar "
                    },
                    messages: {
                        noData: "No hay datos disponibles"
                    },
                    results: {
                        positive: "Positivo",
                        negative: "Negativo"
                    },
                    report: {
                        title: "Informe del análisis toxicológico.",
                        name: "nombre y apellidos",
                        footer: "Los análisis son fiables durante un periodo de 3 meses antes del muestreo."
                    },
                    tests: {
                        anxiolytics: "ansiolíticos",
                        opioids: "Opioides",
                        ibuprofen: "Ibuprofeno",
                        vicodin: "Vicodina",
                        alcohol: "Alcohol",
                        nicotine: "Nicotina",
                        cocaine: "Cocaína",
                        cannabis: "Cannabis",
                        other: "Otros"
                    }
                },
                dna: {
                    title: "Análisis de ADN",
                    subtitle: "¿qué quieres analizar?",
                    buttons: {
                        analyze: "Analizar"
                    },
                    search: {
                        label: "Seleccione un elemento para analizar"
                    }
                }
            }
        }
    },
    it: {
        maestro: {
            laboratory: {
                toxicology: {
                    title: "Analisi tossicologiche",
                    subtitle: "Inserire un nome e un cognome per ottenere un'analisi tossicologica.",
                    placeholders: {
                        name: "Cognome e nome"
                    },
                    buttons: {
                        search: "Cercare"
                    },
                    messages: {
                        noData: "Nessun dato disponibile"
                    },
                    results: {
                        positive: "Negativo",
                        negative: "Positivo "
                    },
                    report: {
                        title: "rapporto di analisi tossicologica",
                        name: "nome e cognome",
                        footer: "Le analisi sono affidabili su un periodo di 3 mesi prima del campionamento."
                    },
                    tests: {
                        anxiolytics: "ansiolitici",
                        opioids: "oppioidi",
                        ibuprofen: "Ibuprofene",
                        vicodin: "Vicodin",
                        alcohol: "Alcool",
                        nicotine: "Nicotina ",
                        cocaine: "Cocaina",
                        cannabis: "Cannabis",
                        autres: "Altri"
                    }
                },
                dna: {
                    title: "Analisi DNA",
                    subtitle: "cosa vuoi far analizzare?",
                    buttons: {
                        analyze: "analizzare"
                    },
                    search: {
                        label: "SSeleziona un elemento da analizzare"
                    }
                }
            }
        }
    }
}