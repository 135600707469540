export default {
    fr: {
        maestro: {
            solution: {
                headline: {
                    line1: "Vous pensez avoir tiré au clair cette affaire, agent ?",
                    line2: "Vérifiez vos réponses aux 3 premières questions.",
                    line3: "Désignez le coupable lors de la question 4."
                },
                buttons: {
                    showSolution: "voir la solution",
                    hideSolution: "masquer la solution",
                    returnToInvestigation: "retourner à l'enquête",
                    showFullSolution: "voir la solution complète",
                    hideFullSolution: "masquer la solution complète"
                },
                labels: {
                    chooseSuspect: "-- sélectionner un.e suspect.e --"
                }
            }
        }
    },
    en: {
        maestro: {
            solution: {
                headline: {
                    line1: "Think you've got this investigation figured out, agent?",
                    line2: "Then it's your turn.",
                    line3: "Answer these 4 questions."
                },
                buttons: {
                    showSolution: "Display the solution",
                    hideSolution: "Hide solution",
                    returnToInvestigation: "Back to investigation",
                    showFullSolution: "Reveal complete solution",
                    hideFullSolution: "Hide complete solution"
                },
                labels: {
                    chooseSuspect: "-- select a suspect --"
                }
            }
        }
    },
    de: {
        maestro: {
            solution: {
                headline: {
                    line1: "Glauben Sie, dass Sie den Fall geklärt haben, Agent?",
                    line2: "Dann sind Sie jetzt an der Reihe.",
                    line3: "Beantworten Sie diese vier Fragen."
                },
                buttons: {
                    showFullSolution: "vollständige Lösung anzeigen",
                    hideFullSolution: "vollständige Lösung ausblenden",
                    returnToInvestigation: "Ermittlung fortsetzen",
                    showSolution: "Lösung anzeigen",
                    hideSolution: "Lösung ausblenden"
                },
                labels: {
                    chooseSuspect: "-- eine/n Verdächtige/n auswählen --"
                }
            }
        }
    },
    es: {
        maestro: {
            solution: {
                headline: {
                    line1: "¿Cree que lo tiene todo claro, agente?",
                    line2: "Entonces te toca a ti.",
                    line3: "Responde a estas 4 preguntas."
                },
                buttons: {
                    showSolution: "Ver la solución",
                    hideSolution: "solución de ocultación",
                    returnToInvestigation: "Reanudar tu investigación",
                    showFullSolution: "ver la solución completa",
                    hideFullSolution: "ocultar la solución completa"
                },
                labels: {
                    chooseSuspect: "-- seleccione un sospechoso --"
                }
            }
        }
    },
    it: {
        maestro: {
            solution: {
                headline: {
                    line1: "Pensa di aver capito tutto, agente?",
                    line2: "Poi è il tuo turno.",
                    line3: "Rispondi a queste 4 domande."
                },
                buttons: {
                    showFullSolution: "vedere la soluzione completa",
                    hideFullSolution: "nascondere la soluzione completa",
                    returnToInvestigation: "Riprendi l'indagine",
                    showSolution: "Vedi la soluzione",
                    hideSolution: "nascondere la soluzione"
                },
                labels: {
                    chooseSuspect: "-- seleziona un sospetto --"
                }
            }
        }
    }
}