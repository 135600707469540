<template>
  <destination-template
    :location="$t('mykonos.research.labels.location')"
    :coordinates="destination.coords"
    :title="$t('mykonos.data.destinations.policeStation.title')"
    :returnRoute="{ path: '.' }"
  >
    <template v-slot:icon
      ><img
        src="@/assets/images/mykonos/destinations/police_station_pinpoint.svg"
      />
      ></template
    >
    <template v-slot:thumbnail
      ><v-img
        src="@/assets/images/mykonos/destinations/police_station_thumbnail.jpg"
      ></v-img
    ></template>
    <template v-slot:ui>
      <v-container v-if="!destination.searched">
        <v-row>
          <v-col class="d-flex justify-center ui-message">
            {{
              $t(
                "mykonos.data.destinations.policeStation.messages.enterCaseNumber"
              )
            }}
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex justify-center">
            <input
              type="text"
              v-model="caseNumber"
              :placeholder="
                $t('mykonos.data.destinations.policeStation.labels.caseNumber')
              "
              class="text-box"
              @keypress.enter="trySearch"
            />
          </v-col>
        </v-row>
        <v-row v-if="destination.errors.length > 0">
          <v-col class="d-flex justify-center ui-message error-message">
            {{
              $t("mykonos.data.destinations.policeStation.messages.searchError")
            }}
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex justify-center">
            <button @click="trySearch" class="submit-button">
              {{ $t("mykonos.research.buttons.submit") }}
            </button>
          </v-col>
        </v-row>
      </v-container>
      <template v-else>
        <v-row class="ui-message">
          <v-col cols="12" class="d-flex justify-center">
            <div class="envelope-message">
              {{ $t("mykonos.research.messages.envelopeUnlocked") }}&nbsp;{{
                $t("mykonos.data.destinations.policeStation.envelope")
              }}
            </div>
          </v-col>
        </v-row>
      </template>
    </template>
  </destination-template>
</template>
<script>
import DestinationTemplate from "../../components/mykonos/DestinationTemplate.vue";
import currentGameMixin from "../../mixins/currentGameMixin";

export default {
  name: "PoliceStation",
  data() {
    return {
      destinationID: "policeStation",
      caseNumber: "",
    };
  },
  mixins: [currentGameMixin],
  computed: {
    destination: function () {
      return this.$store.getters.mykonosDestination(this.destinationID);
    },
  },
  components: {
    DestinationTemplate,
  },
  methods: {
    trySearch: function () {
      this.$store.dispatch("mykonosTrySearchPoliceStation", this.caseNumber);
    },
    resetSearchError: function () {
      this.$store.dispatch("mykonosResetDestinationErrors", this.destinationID);
    },
  },
  watch: {
    caseNumber: function () {
      this.resetSearchError();
    },
  },
};
</script>
<style scoped>
.ui-message {
  font-family: Source Sans 3;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.06em;
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
}

.text-box {
  background: #00000080;
  border: none;
  border-radius: 0%;
  height: 27px;
  min-width: 200px;
  font-family: Source Sans 3;
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.06em;
  text-align: left;
  text-transform: uppercase;
  color: #ffffff;
}

.submit-button {
  color: #ffc122;
  border: 1px solid #ffc122;
  font-family: Bebas Neue;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0.06em;
  text-align: center;
  text-transform: uppercase;
  width: 128px;
  height: 36px;
}

.error-message {
  border: 1px solid #ff2900;
  color: #ff2900;
  padding: 10px;
}

.envelope-message {
  font-family: Bebas Neue;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0.06em;
  text-align: center;
  text-transform: uppercase;
  color: #00ff29;
  border: 1px solid #00ff29;
  padding: 20px;
}
</style>