var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"section-frame fill-height"},[_c('div',{staticClass:"suitcase-container"},[_c('v-overlay',{attrs:{"absolute":"","opacity":"0","value":_vm.advertiseUnlock}},[_c('div',{staticClass:"dialog-frame unlock"},[_c('p',[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('festival.suitcase.unlock_desc'))}})]),_c('p')])]),_c('v-overlay',{attrs:{"absolute":"","opacity":"0","value":_vm.showLeftCode}},[_c('div',{staticClass:"dialog-frame left-code"},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('div',{staticClass:"suitcase-icon-close",class:{ hovered: hover },on:{"click":_vm.onClickClose}})]}}])}),_c('label',[_vm._v(_vm._s(_vm.$t('festival.suitcase.left_code')))]),_c('svg-numeric-padlock',{ref:"padlock_left",staticClass:"padlock-ui",attrs:{"secretLength":3,"secretChar":"?","isOk":!_vm.isLockedLeft,"isLocked":_vm.isLocked,"initCode":_vm.leftCode},on:{"codeChange":_vm.onLeftPadlockCodeChange}})],1)]),_c('v-overlay',{attrs:{"absolute":"","opacity":"0","value":_vm.showRightCode}},[_c('div',{staticClass:"dialog-frame right-code"},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('div',{staticClass:"suitcase-icon-close",class:{ hovered: hover },on:{"click":_vm.onClickClose}})]}}])}),_c('label',[_vm._v(_vm._s(_vm.$t('festival.suitcase.right_code')))]),_c('svg-numeric-padlock',{ref:"padlock_right",staticClass:"padlock-ui",attrs:{"secretLength":3,"secretChar":"?","isOk":!_vm.isLockedRight,"isLocked":_vm.isLocked,"initCode":_vm.rightCode},on:{"codeChange":_vm.onRightPadlockCodeChange}})],1)]),_c('v-overlay',{attrs:{"absolute":"","opacity":"0","value":_vm.showLabel}},[_c('div',{staticClass:"dialog-frame label"},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('div',{staticClass:"suitcase-icon-close",class:{ hovered: hover },on:{"click":_vm.onClickClose}})]}}])}),_c('div',[_vm._v(" "+_vm._s(_vm.$t('festival.suitcase.label'))+" ")])],1)]),_c('div',{staticClass:"suitcase-image"},[_c('svg-suitcase',{attrs:{"locked1":_vm.isLockedLeft,"locked2":_vm.isLockedRight}}),(!_vm.isAnyPanelShown)?_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('div',{staticClass:"suitcase-icon-eye leftcode",class:{ hovered: hover },on:{"click":_vm.onClickLeftCode}})]}}],null,false,180161696)}):_vm._e(),(!_vm.isAnyPanelShown)?_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('div',{staticClass:"suitcase-icon-eye rightcode",class:{ hovered: hover },on:{"click":_vm.onClickRightCode}})]}}],null,false,1860151232)}):_vm._e(),(!_vm.isAnyPanelShown)?_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('div',{staticClass:"suitcase-icon-eye label",class:{ hovered: hover },on:{"click":_vm.onClickLabel}})]}}],null,false,30902976)}):_vm._e()],1)],1),_c('audio',{ref:"unlockSound",attrs:{"src":_vm.unlockSoundSrc}})])}
var staticRenderFns = []

export { render, staticRenderFns }