import { defineStore } from "pinia"
import { cloneObject } from '@/utils';
import { getSupportedLocales } from "@/util/i18n/supported-locales";


export const useFestivalStore = defineStore("festival", {
    state: () => {
        return {
            initialized: false,
            initialConfig: {},
            availableLocales: "",
            sections: [],
            details: {},
            suitcase: {},
            telephone: {},
            coffre: {},
        }
    },
    actions: {
        initialize(config) {
            this.initialConfig = config;
            this.availableLocales = cloneObject(config.festival.availableLocales);
            this.sections = cloneObject(config.festival.sections);
            this.details = cloneObject(config.festival.caseDetails);
            this.suitcase = cloneObject(config.festival.suitcase);
            this.telephone = cloneObject(config.festival.telephone);
            this.coffre = cloneObject(config.festival.coffre);
            this.loadState();
        },
        getAvailableLocales() {
            const supportedLocales = getSupportedLocales();
            return this.availableLocales.map(l => supportedLocales.find(sl => sl.code === l));
        },
        findEvidence(s) {
            const evidences = this.sections.find((v) => v.id === "evidences");
            if(!evidences) {
                console.log("Section evidences not found");
                return;
            }

            const section = evidences.subsections.find((v) => v.id === s);
            if(!section) {
                 console.log(`Subsection ${s} not found`);
                return;
            }

            return section
        },       
        findEnvelope(s) {
            const envelope = this.sections.find((v) => v.id === "envelopes");
            if(!envelope) {
                console.log("Section envelope not found");
                return;
            }

            const section = envelope.subsections.find((v) => v.id === s);
            if(!section) {
                console.log(`Subsection ${s} not found`);
                return;
            }

            return section
        },        
        unlockEnvelope(e) {
            let envelope = this.findEnvelope(e);
            envelope.locked = false;
            this.saveState();
        },
        enableEnvelope(e) {
            let envelope = this.findEnvelope(e);
            envelope.disabled = false;
            this.saveState();
        },
        enableEvidence(e) {
            let evidence = this.findEvidence(e);
            evidence.disabled = false;
            this.saveState();
        },
        saveState() {
            let o = {
                sections: this.sections,
                suitcase: this.suitcase,
                telephone: this.telephone,
                coffre: this.coffre,
            }
            localStorage.setItem('festivalStorage', JSON.stringify(o));
        },
        loadState() {
            const savedState = localStorage.getItem('festivalStorage');

            if (savedState) {
                let o = JSON.parse(savedState);

                this.sections = o.sections;
                this.suitcase = o.suitcase;
                this.telephone = o.telephone;
                this.coffre = o.coffre;
            } 
        },
        resetState() {
            this.sections = cloneObject(this.initialConfig.festival.sections);
            this.suitcase = cloneObject(this.initialConfig.festival.suitcase);
            this.telephone = cloneObject(this.initialConfig.festival.telephone);
            this.coffre = cloneObject(this.initialConfig.festival.coffre);
            this.saveState();
        }
    }
})
