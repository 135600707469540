<template>
    <div class="dc-short-text">
    <subscribe :caseID="caseID" v-if="allowedLocales.includes(currentLocale)"></subscribe>
    <h2>Solution</h2>
    <p>
        Le 18 février 2024, à 7h15, Daren Jobs, chef de la sécurité du zoo, découvre le corps de Meredith Carter 
        dans la Forêt des ours.
    </p>
    <p>
        Depuis plusieurs semaines, Meredith mène une investigation sur un trafic d’animaux depuis le zoo 
        (<i>Tableau d’enquête de Meredith</i>). Elle a identifié le tigre Surya, reconnu sur un <i>flyer de cirque</i>, 
        ainsi que Russel McBrook, un ancien employé renvoyé pour maltraitance animale (<i>Commentaire d’un visiteur du zoo, 
        Badge de Russel</i>), figurant également sur ce flyer. Les recherches de Meredith ont révélé que certains animaux, 
        officiellement “relâchés dans leur habitat naturel” selon le registre du zoo (<i>Animaux réintroduits dans leur 
        milieu naturel</i>), n’avaient jamais atteint leur destination. En contactant la Réserve de Sikhote-Aline en 
        Sibérie, Meredith constate que certains animaux n’y sont jamais arrivés (<i>E-mail de la réserve de 
        Sikhote-Aline</i>). Meredith a également noté que l’ourson Léo devait être transféré en Sibérie sous la 
        supervision de Carmen. Ces incohérences l’ont menée à suspecter Carmen de falsifier les documents pour vendre 
        les animaux à des clients privés ou à des cirques, notamment celui de Russel.
    </p>
    <p>
        Carmen, se sentant menacée, envoie une lettre de menace anonyme à Meredith pour la dissuader de continuer 
        ses recherches (<i>Photo du bureau de Meredith</i>). Motivée par des difficultés financières liées aux soins 
        coûteux de sa mère malade, Carmen avait mis en place ce trafic (<i>Photo des effets personnels de Carmen</i>).
    </p>
    <p>
        Plusieurs indices permettent d’établir la chronologie de la nuit du meurtre et de relier Carmen au crime.
    </p>
    <p>
        À 22h10, le chef de la sécurité note une agitation inhabituelle chez les singes, 20 minutes après leur 
        nourrissage (<i>Témoignage de Daren</i>). En réalité, c’est l’heure à laquelle Carmen s’introduit dans le 
        zoo, entrant par la zone de maintenance et traversant l'île aux singes, causant leur agitation (<i>Rapport 
        de badges</i>).
    </p>
    <p>
        Le rapport de badge confirme une entrée à 21h50 (Kelly, pour le nourrissage des singes, voir <i>Planning 
        de nourrissage des animaux</i>) et une seconde entrée suspecte depuis l'extérieur à 22h07, correspondant 
        à l’arrivée de Carmen. La <i>vidéosurveillance</i> montre une silhouette quittant l'île aux singes pour 
        se diriger vers le centre de soins, où Carmen récupère le fusil et les fléchettes (<i>Plan du zoo, Stock 
        de fléchettes</i>) 
    </p>
    <p>
        À 22h22, Kelly remarque une lumière allumée dans le centre de soins (<i>Conversation entre Kelly et sa 
        mère</i>). Carmen, après y avoir pris l’équipement, se rend à la Forêt des ours, où Meredith devait les 
        nourrir à 22h30.
    </p>
    <p>
        Le <i>rapport de badges</i> confirme l’entrée de Meredith, puis de Carmen dans l’enclos des ours. Carmen 
        tire sur Meredith et quitte l’enclos à 22h40.
    </p>
    <p>
        La fléchette utilisée pour tuer Meredith n’a pas été retrouvée sur le corps. Donald, un singe capucin 
        qui accompagnait souvent Meredith, a l’habitude de voler de petits objets (<i>Carte de Donald, Post 
        Extragram d’un visiteur</i>) et de les cacher dans l’appartement de Meredith, dans un conduit d’aération. 
        La fléchette est retrouvée à cet endroit (<i>Photo du conduit d’aération</i>).
    </p>
    <p>
        En poursuivant Donald, qui a volé la fléchette, Carmen retourne vers l'île aux singes. Donald escalade 
        alors des cordages près de la cage d’Abelli, ce qui provoque les cris de cette dernière (<i>Plan de l’île 
        aux singes, Témoignage de Daren, Conversation entre Kelly et sa mère</i>). Kelly et Daren, alertés par ces 
        cris, se dirigent vers l'île aux singes. Carmen abandonne sa poursuite, laisse le fusil dans la zone de 
        maintenance et s’enfuit (<i>Photo du fusil dans la zone de maintenance</i>).
    </p>
    <p>
        Kelly, la stagiaire, mentionne à sa mère avoir entendu des bruits dans les conduits vers 23h, correspondant 
        à l'heure où Donald cache la fléchette (<i>Conversation entre Kelly et sa mère</i>).
    </p>
    <p>
        L’analyse du <i>stock de fléchettes</i> révèle qu’il en manque trois, deux ayant été tirées le matin même 
        sur les ours par Daren. Carmen a donc utilisé la troisième fléchette (létale quand elle est tirée sur 
        quelqu’un de moins de 150 kilos) sur Meredith. Enfin, la <i>To-do-list de Richard</i>, le soigneur, confirme 
        que les grilles de l'enclos des ours étaient bien fermées la veille au soir et que le stock de fléchettes 
        était complet. Carmen a ouvert les grilles après le meurtre pour simuler un accident.
    </p>
    <p>
        Malgré son conflit avec Meredith au sujet du projet SMART, Luis ne peut pas être le meurtrier car son 
        badge ne possède pas l’autorisation nécessaire pour entrer dans le centre de soins (<i>Badge de Luis, Plan 
        du zoo</i>). Il n’a donc pas pu s’emparer de l’arme du crime. 
    </p>
    <p>
        Kelly était en train de discuter avec sa mère sur son ordinateur au moment du meurtre, comme en témoignent 
        les heures des messages envoyés (<i>Conversation entre Kelly et sa mère</i>). Les autorisations de son badge 
        ne lui permettent pas d’accéder à la zone de maintenance, où le fusil a été retrouvé (<i>Badge de Kelly, 
        Plan du zoo</i>). De plus, son désaccord avec Meredith au sujet d’Abelli semble être un mobile trop léger.
    </p>
    <p>
        Richard n’était pas présent au zoo le soir du meurtre. Il est parti en avance ce soir-là pour assister au 
        concert des Cow-boys de Kenwood (<i>To-do-list de Richard, Article du Kenwood Paper</i>).
    </p>
    <p>
        Russel, quant à lui, animait l’avant-première de son spectacle de cirque ce soir-là à 21h30 (<i>Flyer du 
        cirque</i>). S’il est complice de Carmen dans le trafic d’animaux, il n’est pas le meurtrier.
    </p>
    <p>
        Pour finir, Daren est innocenté car il a passé la nuit à l’intérieur du zoo. Il n’avait aucun intérêt de 
        s’échapper du zoo par la zone de maintenance et a passé la nuit au poste de sécurité (<i>Témoignage de 
        Daren</i>). Il ne possède aucun mobile contre Meredith. 
    </p>

    </div>
</template>

<script>
import config from '@/config/';
import Cases from "@/enums/Cases";
import currentGameMixin from "../../mixins/currentGameMixin";
import Subscribe from "@/components/Subscribe.vue";  

export default {
  name: "MeredithCarter",
  components: { Subscribe },
  mixins: [currentGameMixin],
  computed: {
    allowedLocales: function() {
      return config.common.showSubscribeForLocales
    },  
  },
  watch: { },
  created() {
    this.setCurrentGame(Cases.MEREDITHCARTER);
  },    
}
</script>

<style scoped>
.dc-short-text {
    color: #fff;
    padding: 30px;
    text-align: center;
}
</style>