export default {
    fr: {
        maestro: {
            bank: {
                transactions: "Transactions bancaires",
                placeholders: {
                    name: "Nom"
                },
                buttons: {
                    search: "Rechercher"
                },
                headers: {
                    date: "Date",
                    location: "Lieu",
                    description: "Motif",
                    amount: "Montant"
                }

            }
        }
    },
    en: {
        maestro: {
            bank: {
                transactions: "Bank transactions",
                placeholders: {
                    name: "Name"
                },
                buttons: {
                    search: "Search"
                },
                headers: {
                    date: "Date",
                    location: "Location",
                    description: "Reason",
                    amount: "Amount"
                }
            }
        }
    },
    de: {
        maestro: {
            bank: {
                transactions: "Bankgeschäfte",
                placeholders: {
                    name: "Namen"
                },
                buttons: {
                    search: "Suchen"
                },
                headers: {
                    date: "Datum",
                    location: "Ort",
                    description: "Grund",
                    amount: "Betrag"
                }
            }
        }
    },
    es: {
        maestro: {
            bank: {
                transactions: "Operaciones bancarias",
                placeholders: {
                    name: "Nombre"
                },
                buttons: {
                    search: "Buscar "
                },
                headers: {
                    date: "Fecha",
                    location: "Ubicación",
                    description: "Motivo",
                    amount: "Total"
                }
            }
        }
    },
    it: {
        maestro: {
            bank: {
                transactions: "Operazioni bancarie",
                placeholders: {
                    name: "Cognome"
                },
                buttons: {
                    search: "Cercare"
                },
                headers: {
                    date: "Data",
                    location: "Posizione",
                    description: "Motivo",
                    amount: "Importo"
                }
            }
        }
    }
};
