<template>
  <div class="ouija-container text-center">
    <v-overlay absolute :value="showDescription">
      <div class="dialog-frame">
        <p v-html="$t('ghosts.ouija.messages.description')"></p>
        <button @click.prevent="showDescription = false">
          {{ $t("common.buttons.ok") }}
        </button>
      </div>
    </v-overlay>
    <v-overlay absolute :value="showUnlockNotification">
		<div class="dialog-frame">
			<p>
				<span v-html="$t('ghosts.ouija.messages.envelope')"></span><br />
				<span v-html="$t('ghosts.ouija.messages.envelopeContent')"></span>
			</p>
			<p v-html="$t('ghosts.ouija.messages.sectionEnabled')"></p>
			<p v-html="$t('ghosts.ouija.messages.sectionEnabled2')"></p>
		</div>
    </v-overlay>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 2000 1500"
      ref="board"
      class="ouija-board"
      @mousedown="mouseDown = true"
      @mouseup="mouseDown = false"
      @mousemove="updatePlanchette"
    >
      <defs>
        <radialGradient
          id="lueur_x5F_selection_00000127738335446751093720000000995855984742420143_"
        >
          <stop offset="0" style="stop-color: #b7ffdf; stop-opacity: 0.9" />
          <stop offset="1" style="stop-color: #00e991; stop-opacity: 0" />
        </radialGradient>
        <filter id="glow" x="-30%" y="-30%" width="160%" height="160%">
          <feGaussianBlur stdDeviation="20 20" result="glow" />
          <feMerge>
            <feMergeNode in="glow" />
            <feMergeNode in="glow" />
            <feMergeNode in="glow" />
          </feMerge>
        </filter>
      </defs>
      <g id="board">
        <g id="fond_bois">
          <defs>
            <rect id="SVGID_1_" y="2" width="1992.3" height="1400" />
          </defs>
          <clipPath
            id="SVGID_00000019667274669055884520000002770875760087581596_"
          >
            <use xlink:href="#SVGID_1_" style="overflow: visible" />
          </clipPath>
          <g
            style="
              clip-path: url(#SVGID_00000019667274669055884520000002770875760087581596_);
            "
          >
            <image
              style="overflow: visible; enable-background: new"
              width="3100"
              height="1934"
              xlink:href="@/assets/images/ghosts/ouija/board_bg.jpg"
              transform="matrix(0.66 0 0 0.72 -15.37 1)"
            ></image>
          </g>
        </g>
        <text width="500" x="50%" y="15.5%" class="input-glow">
          <tspan text-anchor="middle">
            {{ inputWord }}
          </tspan>
        </text>
        <text width="500" x="50%" y="15.5%" class="input-text">
          <tspan text-anchor="middle">
            {{ inputWord }}
          </tspan>
        </text>

        <g id="graphisme">
          <path
            class="st1"
            d="M1718.1,1267.9C1718.1,1267.9,1718.1,1267.9,1718.1,1267.9c0.2,0.2,0.3,0.4,0.4,0.6
			C1718.3,1268.3,1718.3,1268,1718.1,1267.9z M1718.1,1267.9C1718.1,1267.9,1718.1,1267.9,1718.1,1267.9c0.2,0.2,0.3,0.4,0.4,0.6
			C1718.3,1268.3,1718.3,1268,1718.1,1267.9z"
          />
          <g>
            <polygon
              class="st1"
              points="1773.3,1259.4 1773.3,1259.4 1773.3,1259.4 1773.3,1259.4 			"
            />
            <path
              class="st1"
              d="M1991.6,1402V2.2c-2.4-0.1-4.9-0.2-7.3-0.2l0,0C1325.5,2,666.7,2,7.9,2C5.5,2,3.1,2.1,0.6,2.2V1402H1991.6z
  M29.9,1034.8c0.2-0.2,0.5-0.3,0.7-0.5c4,0.9,7.9,1.8,11.9,2.7c-0.1,0.3-0.1,0.7-0.2,1C38.1,1036.9,34,1035.9,29.9,1034.8z
   M41.4,1041.3L41.4,1041.3c-0.5,0.2-1.1,0.5-1.6,0.5c-0.4,0-0.7-0.3-1.1-0.4c0.4-0.3,0.8-0.6,1.2-0.9
				C40.4,1040.8,40.9,1041.1,41.4,1041.3z M32.9,1030.1c-0.1,0-0.3-0.1-0.4-0.1c0,0-0.1-0.1-0.2-0.1c-4.3,0.9-8-2.4-12.3-2
				c4.3-0.7,8.4-0.1,12.3,2c0.1,0,0.2,0,0.3-0.1C32.7,1030,32.8,1030,32.9,1030.1c2.4,0.5,4.8,1.1,7.1,1.6
				C37.5,1031.8,34.9,1032,32.9,1030.1z M25.9,1033.8c-4.3-0.8-7.9-1.5-11.5-2.1C18.1,1031.5,22,1031,25.9,1033.8z M42.8,1047.1
				c-9.5-1.8-19.1-3.5-28.6-5.2C24,1042.4,33.8,1043.2,42.8,1047.1z M61.9,1056.3c-9.1-0.8-16.3-4.8-24.1-6
				C45.8,1050.7,53.4,1052.8,61.9,1056.3z M46.9,1047.9c1.3-0.5,2.1-0.4,2.7,0.5c0.1,0,0.2,0,0.2,0.1c0,0,0.1,0.1,0.1,0.1
				c-0.1,0-0.2,0-0.3,0c0-0.1-0.1-0.1-0.1-0.2C48.8,1048.3,48,1048.1,46.9,1047.9z M50,1048.6c2.9-0.5,5.1,1.2,7.5,2.4
				C54.8,1050.8,52.1,1050.6,50,1048.6z M57.5,1050.9c0.2,0,0.3,0,0.5,0c0.1,0.1,0.2,0.1,0.3,0.2c-0.1,0-0.3,0-0.4,0
				C57.7,1051,57.6,1051,57.5,1050.9z M77.3,1066.8c-20.7-4.8-40.7-12.4-62.2-14.2C36.5,1054.4,57.5,1057.9,77.3,1066.8z
 M63.3,1057.1c2.5-1.2,3.8,1.1,6.3,1.4C66.8,1059.2,65.2,1058,63.3,1057.1z M14.6,1075.7c5,1.6,10,3.3,15.1,4.9
				C24.2,1080.4,19.1,1079.1,14.6,1075.7z M16.5,1082.5c-0.3,0.2-0.7,0.5-1,0.5c-0.4,0-0.7-0.4-1.1-0.6c0.4-0.2,0.7-0.5,1.1-0.5
				C15.8,1081.9,16.1,1082.2,16.5,1082.5z M29.5,1071.6c0.2-0.1,0.5-0.2,0.5-0.1c0.2,0.4,0.4,0.9,0.6,1.3c0.2-0.1,0.4-0.1,0.6,0
				c1-0.1,2.1-0.3,3.5-0.5c-1.3,1.9-2.4,0.6-3.5,0.5c-0.2,0-0.5,0.1-0.7,0.1C30.1,1072.5,29.8,1072.1,29.5,1071.6z M33.5,1049.3
				l0.1-0.2l0.5,0.2L33.5,1049.3z M16.6,1088.2c0.4-0.1,0.9,0.5,1.5,0.9c-1.4,1-3.3,0.8-3.5-0.1
				C13.8,1086.2,15.8,1088.4,16.6,1088.2z M61.8,1106.7c-16.3-1.8-31.9-6.3-47-12.5C30.2,1099.3,46.5,1100.9,61.8,1106.7z
 M19.7,1090.1c0.2-0.1,0.4-0.3,0.5-0.3c0.4,0.1,0.8,0.2,1.2,0.4c-0.3,0.2-0.7,0.5-1,0.4C20.2,1090.6,20,1090.3,19.7,1090.1z
 M142,1217.4L142,1217.4c-5.7,1.3-10.6-1.4-15.7-1c0.3-2,3.1-0.5,3.2-2.4c-0.1,0-0.1,0-0.2-0.1c-0.1-0.1-0.3-0.2-0.4-0.3
				c-6.3-1.7-12.7-3.1-19.2-2.9c-1.4,0.2-2.8,0.3-4.2,0.3c1.4-0.2,2.8-0.3,4.2-0.3c6.6-0.7,13.3-1.7,19.2,2.9
				c0.2,0.1,0.4,0.1,0.6,0.2c0,0.1,0,0.1,0,0.2C133.4,1215.5,138,1214.6,142,1217.4z M175.1,1222.7h-20.2v-0.3h20.2V1222.7z
 M178.2,1227.4c-8.3,2.5-16.6,2.2-24.9,1.2c-3.3-0.3-6.5-0.7-9.8-1.2c3.3,0.4,6.5,0.9,9.8,1.2
				C161.6,1229.3,169.9,1229,178.2,1227.4z M156.5,1225.2c-14.3,1.7-26-5.1-38.4-5.4C130.7,1220.2,142.8,1223.6,156.5,1225.2z
 M102.2,1187.4c5.4,3,10.8,6.1,16.2,9.1c0.2,0,0.3,0.1,0.5,0.1c0,0.1,0,0.1,0,0.1c1.5,0.1,2.7,0.8,3.9,2.3c-2,0-3.7,0.2-3.9-2.3
				c-0.1,0-0.1,0-0.2,0c-0.1-0.1-0.2-0.1-0.4-0.2C112.1,1195.3,107.3,1191,102.2,1187.4z M107.2,1196.7c-0.1,0.4-0.1,0.8-0.2,1.3
				c-2.1-0.2-4.2-0.3-6.3-0.6c-1.1-0.2-3.5,0.5-2.3-2.3c0.8-2,2-1.7,3.5-0.9C103.7,1195.1,105.5,1195.9,107.2,1196.7z M70.7,1184.7
				c10.6-1,20.1-3.7,28.9,2.4C90.6,1184.2,81.3,1185.3,70.7,1184.7z M97.5,1322.5c21.7,8.2,42.2-4.6,58.7-3.5
				C139.9,1321,119.4,1332.3,97.5,1322.5z M106.4,1309.8l0.6-0.1l0,0.4L106.4,1309.8z M118.9,1335c3.2-0.8,6.6-0.9,10-1.3
				c4.4-1.4,8.8-3.5,13.6-4.9c-4.3,3.4-9,4.4-13.6,4.9C125.7,1334.7,122.4,1335.3,118.9,1335z M141.1,1335.2
				C141.1,1335.2,141,1335.2,141.1,1335.2c-0.1,0-0.1,0.1-0.1,0.1c0,3.5-3.1,1.8-4.8,2.6c1.6-0.9,3.2-1.7,4.8-2.6c0-0.1,0-0.1,0-0.2
				C141,1335.1,141,1335.1,141.1,1335.2c1.6-0.7,3.3-1.4,4.9-2.1C144.6,1334.6,143.3,1336.8,141.1,1335.2z M158.5,1325
				c0,0,0.1-0.1,0.1-0.1c-5.4-1.1-9.4,3.2-16.1,4.3c13.1-7.5,25.2-9.9,38.5-8c-0.7,3.2-3.2,0.5-3.8,2.4c0,0-0.1,0-0.2,0
				c0.1,0.1,0.2,0.1,0.3,0.1c-0.2,0-0.4,0-0.6-0.1c-9.7,2.4-19.4,4.7-29.4,10.1C150.3,1328.2,157,1330.2,158.5,1325
				C158.6,1325.1,158.6,1325.1,158.5,1325z M176.6,1385.6L176.6,1385.6c-2.9,0.1-5.7,0-8.6-0.1
				C170.9,1385.5,173.7,1385.6,176.6,1385.6c17.3-0.4,34.5-3.4,51.9-2.8c-10,0.9-20.1,2.2-30.1,2.7
				C191.1,1385.8,183.9,1385.7,176.6,1385.6z M180.9,1376c8.1-0.8,17.9-1.8,27.7-2.8C198.9,1377.3,189.1,1377.1,180.9,1376z
 M269.7,1377c-25.5-4-50.4,5.2-77.4,3.9C214.3,1375.6,257,1373.2,269.7,1377z M180.4,1371c6.5-0.5,13-1.4,19.5,0H180.4z
 M381.9,1369c-1.5,6.7-5.3,9-13.2,8.1c-7.5-0.9-14.9-1.2-22.2,1.2c-4.6,1.6-9.3,1.8-12.4-2.9c-3.9-5.8-3.9-12.8,0-17.3
				c2.3-2.6-1.3-3-1.7-4.6c6.8-0.4,9.4-4.6,5.6-10.1c-4.2-6.1,1-9.1,3.5-13.1c2.4-4,5.7-5.3,10.1-3.5c5.9,2.5,11.4,5.6,16.1,10.1
				c8.5,8.2,8.5,9.3,0.2,17c-1.8,1.7-6.9,3.9-0.5,7.2c1.1,0.6,2.8,3.8,4.3,5.8c3.5,4.5,6.5,1.8,9.9,1.6c0.1-0.6,0.4-1,1.1-0.7
				C382.3,1368.2,382.1,1368.6,381.9,1369z M1681,1376.4L1681,1376.4c-10.4-2.7-20.7-2.9-31.3-2c-4.9,0.4-9.3-2.2-9.4-8.5
				c6.8,1.7,10.1-2.3,14.1-6.5c7.8-8.2-1.5-8.9-4.2-11c-3.9-3-3.5-5.3-1.2-8.3c5.5-7.4,12.6-12.8,21.2-16.2c3.8-1.5,6.9-1.1,9.1,2.5
				c2.5,4.2,8.4,7,4.6,13.6c-3.8,6.5-1.5,10.4,6.5,10.4c-2.9,2.3-4.4,3-0.2,6.3c3.5,2.8,0.7,9.9-1.4,14.8
				C1687.3,1375,1684.6,1377.4,1681,1376.4z M1742,1389.6c21-3.8,41.9-2,58.5,0.4C1783.8,1390.2,1762.9,1387.6,1742,1389.6z
 M1742.3,1375.7c0-0.1-0.1-0.1-0.1-0.1c-0.6,0.6-1.2,0.9-2,0.3c-0.1-0.1-0.1-0.5,0-0.6c0.7-0.4,1.4-0.5,2,0.2c0,0,0,0,0,0h0.1
				C1742.3,1375.6,1742.3,1375.6,1742.3,1375.7z M1742.3,1375.5c-0.4-2.2,1.5-1.3,2.4-1.5c22.1-3.9,43.9-2.2,65.8,1.7
				c6.2,1.1,12.6,1.1,18.2,1.6c-8.9,4.3-18.1,0.4-27.4-0.9c-16.8-2.2-33.6-4.3-50.6-1.7C1748,1375.2,1745.2,1375.3,1742.3,1375.5z
 M1861.7,1381.2c-17.8,3.5-37.7,1.2-57.6-0.9C1824,1382.4,1843.9,1381.7,1861.7,1381.2z M1844.6,1389.6
				c-15.9-0.2-31.5-2.3-47.3-4.4c-15.8-2.1-31.7-1.2-47.7-0.1c8.4-2.5,17.1-2.2,25.7-2.4C1798.8,1382.2,1821.5,1388.3,1844.6,1389.6
				z M1847.6,1389.7L1847.6,1389.7c-1,0-2-0.1-3-0.1l0,0c2.9,0,5.8,0,8.7-0.1c7.1-0.3,14.3-1.3,21.4-0.2
				C1865.7,1389.5,1856.6,1390,1847.6,1389.7z M1887,1335.2L1887,1335.2c-0.2-0.1-0.3-0.2-0.5-0.3c0.2,0,0.4,0.1,0.6,0.1
				c0.3,0.2,0.7,0.4,1,0.6C1887.7,1335.4,1887.4,1335.3,1887,1335.2z M1882.5,1318.7c10.5,2.7,21.2,3,31.9,2.9
				C1903.6,1322.9,1892.8,1323.2,1882.5,1318.7z M1910.5,1266.2c1.7-0.6,3.6-1.3,5.8-0.1C1914.4,1267.5,1912.5,1266.7,1910.5,1266.2
				z M1907.5,1254.3c2.4-0.2,4.7-0.5,7.1-1.1v1.8C1912.2,1254.8,1909.8,1254.5,1907.5,1254.3z M1864,1219.2
				c-3.8-0.2-7.5-0.5-11.3-0.7c-2.6,0.1-5.3,0.3-7.9,0.4c-0.1-0.3-0.1-0.6-0.2-0.9c2.7,0.2,5.4,0.3,8.1,0.5
				c3.8-0.2,7.5-0.4,11.3-0.6C1864,1218.4,1864,1218.8,1864,1219.2z M1867.8,1227.5c1.1-0.1,2.2-0.1,3.2,0H1867.8
				c-6.9,0.3-13.7,2.3-20.6,0H1867.8z M1918.1,1231.4c-25.3-4.6-51,8.2-76.2-0.9C1867.3,1234.7,1892.8,1225.4,1918.1,1231.4z
 M1906.6,1195.3c0.1,0,0.1-0.1,0.2-0.1c0.4-1,1.6-0.5,2.4-0.9c0-0.1,0.1-0.2,0.1-0.4c0.1,0,0.3,0,0.4-0.1
				c5.9-5.2,12.4-5.3,20.5-1c-7.7-0.6-14.1-0.2-20.5,1c-0.1,0.1-0.2,0.1-0.2,0.2c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0.2-0.1,0.4-0.2,0.6
				c0,0.1,0,0.1,0,0.2c0.1,0.1,0.2,0.1,0.3,0.2c0.2,0.3,0.3,0.6,0.3,0.9c0.3,0.3,0.6,0.5,0.9,0.5c-0.3,0-0.6,0.1-0.9,0.1
				c-0.1,0.4-0.5,0.7-1.1,0.8c-0.9,0.2-1.4-0.1-1.6-0.6c-1.8,0.2-3.6,0.5-5.4,0.8c1.4-1.4,2.9-2.7,5.1-2.3
				C1906.6,1195.4,1906.6,1195.4,1906.6,1195.3z M1906.4,1189.4c1-0.6,1.3-2.3,3-1.6C1908.8,1189.1,1908,1189.9,1906.4,1189.4z
 M1910.9,1186.8c0.2-0.1,0.3-0.3,0.5-0.3c0.2,0,0.3,0.2,0.4,0.4c-0.2,0.1-0.3,0.2-0.5,0.4C1911.1,1187,1911,1186.9,1910.9,1186.8
				z M1913.9,1185.8c-0.5-0.2-0.5-0.5,0-0.7c0.1,0,0.3,0.1,0.4,0.2c0,0.1,0,0.3,0,0.3v0C1914.2,1185.7,1914,1185.8,1913.9,1185.8z
 M1915.5,1184.2c1.4-0.9,2.1-1.8,3.4-1.5C1918.1,1183.4,1917.6,1184.7,1915.5,1184.2z M1903.9,1206.3c3.9,0,7.8,0,13.2,0
				c-8.7,1-16.1-0.6-24.9,2.8C1897.6,1206.3,1897.6,1206.3,1903.9,1206.3z M1900.7,1198.2c0.2-0.1,0.3-0.2,0.5-0.3
				c0.1,0,0.2,0,0.3-0.1c-0.1,0.1-0.1,0.2-0.2,0.2C1901.1,1198.1,1900.9,1198.1,1900.7,1198.2z M1913,1217c-0.1,0.2-0.2,0.4-0.3,0.6
				c-0.9-0.1-1.8-0.1-2.7-0.2c0-0.2,0-0.4,0-0.7C1911,1216.8,1912,1216.9,1913,1217z M1903,1215.8c-8.4-0.3-16.5,2.5-24.7,3.8
				C1886.5,1218.1,1894.6,1215.4,1903,1215.8z M1901.6,1202.9c5.6-2.8,11.3-3.1,23.4-1.3C1916.5,1202.2,1909.3,1201.3,1901.6,1202.9
				z M1911.1,1196.7c0.7-0.1,1.4-0.6,2.1-0.7c5.9-1.1,11.6-0.4,17.1,1.6C1923.8,1196.7,1917.5,1196.3,1911.1,1196.7z M1927.9,1179.4
				c6.4-1.4,12.8,0.8,17.1,0.8C1940.7,1180.7,1934.3,1180.5,1927.9,1179.4z M1952.5,1153.8c-1.4,0.2-2.7,0.6-3.9-0.2
				C1950.1,1153.6,1951.3,1153.7,1952.5,1153.8c0.6-0.1,1.3-0.1,1.9,0.1C1953.8,1153.8,1953.2,1153.8,1952.5,1153.8z M1955.8,1140.8
				L1955.8,1140.8c5.5-0.5,10.7-2.2,17.4-5.1c-3,2.3-5.6,1.1-6.6,3.8c2.4,0.3,4.7,0.5,7,0.8c0.2,0.2,0.3,0.4,0.5,0.6H1955.8z
 M1961.5,1113.2c5.2-1.7,9.4-2.5,13.7-1.8C1970.8,1110.8,1967.1,1114.8,1961.5,1113.2z M1968.2,1116.5c-2.3,1.1-3.8,1.4-5.5,0.5
				C1964,1115.5,1965.6,1115.9,1968.2,1116.5z M1972.6,1102.5c3.2-1.3,6.4-3,11.5-2C1979.5,1102.6,1976.1,1102.7,1972.6,1102.5z
 M1858.7,15.4L1858.7,15.4c14.2,1.8,28.4,3.1,42.7,4.2c1,0.1,2,0.6,2.9,1.1c2.1,0.1,4.1,0.1,6.2,0.1c-2.6,1.8-4.4,0.8-6.2-0.1
				c-15.9-0.6-31.2-2.3-46.7-0.2c15.7,4.7,32.5,0.7,47.9,6.1c-15.4-0.7-31-1.4-46.5-2.2c-0.1,0.6-0.1,1.2-0.2,1.8
				c13.2,1.3,26.2,3.6,39.5,4.3c-12.2,1.6-24.4-2.1-36.7,1.6c13,1.5,25.9,2.9,38.9,4.4c-9.7-0.1-19.2-0.6-28.9,0.5
				c5.1,0.9,10.3,1.4,15.7,3h-19.7c7.7,2.7,15.9,2,24.4,5.3c-7.7,1.1-14.6-1.6-21.3,0.4c5.6,2.6,12,0.9,18.8,3.9h-15.5
				c0,0.3,0,0.6-0.1,1c4,0.7,8.1,1.4,12.3,2.2c-1.9,1.8-4.6-0.4-6.6,2c6.8,0.8,9.5,7.2,15.4,9.5c7,2.7,7.8,2.6,9.2-4.5
				c1.9-9.8,5.9-18.4,14.8-24.1c3.1-3.8,6.6-4,11.6-3.4c10.9,1.4,22,1.3,33,2.2c-12.2,1.8-24.4,0.5-36.5,1.1c-2.4,0.1-4.9-0.4-7,1.1
				c6.9,2.7,14.1,1.8,21.3,2.3c9.1,0.6,18.4-0.3,27.4,2.8h-53.1c0,0.4-0.1,0.9-0.1,1.3c17.4,1.2,34.7,2.5,52.1,3.7
				c0,0.3-0.1,0.6-0.1,0.9h-51.4c18.1,5,35.4,1.7,52,4.7c-19.4,0-38.8-0.3-58.1,1.6c14,2.8,28.3,1.9,42.4,4.7h-44.5
				c0,0.7-0.1,1.4-0.1,2c17.4,2.3,35.1,0.6,52.4,4.3c-17.4,0.2-34.8-1.5-52.2-0.5c0,0.7-0.1,1.4-0.1,2.1c10.9,0.7,21.8,1.5,32.8,2.2
				c-10.6,2.7-21.5-0.5-32,3.1c16.5,0.8,32.9,1.6,49.4,2.4c0,0.4-0.1,0.7-0.1,1.1h-48.1c0,0.6-0.1,1.3-0.1,1.9
				c15.7,1.1,31.5,2.3,47.2,3.4c-15.3,2.2-30.5-1.3-45.8,0.2c12.8,1.3,25.5,2.6,37.9,5.4c-11.5-0.7-23.1-1.3-34.6-2
				c-0.1,0.7-0.1,1.4-0.2,2.1c7.4,0.9,14.8,1.7,22.2,2.6c0,0.3-0.1,0.7-0.1,1h-18.8c8.1,3.1,16.5,3.4,24.9,4.7h-20.7
				c0,0.4,0,0.8,0,1.2c7.6,0.8,15.2,1.6,23.6,2.4c-6.3,2.5-12.4,0.5-18.3,2.7c6.3,0.6,12.6,1.3,18.9,2c0,0.3,0,0.6,0,0.9h-13.8
				c5.6,2.8,11.4,2.6,17.2,3.1c-1.3,1.9-4.1-0.2-5.4,2.5c3.3,2.2,7.1,0.7,10.5,1.7c-1.1,1.3-3.5-0.2-4.3,2.1
				c0.9,2.2,3.9,1.6,5.1,3.5c-2.1,0.1-4.3,0.2-6.5,0.3c2.4,2.2,6,0.9,9.1,3.7c-4.4,0.2-8,0.3-12.1,0.5c2.6,3.8,6.9,1.4,9.7,3.7
				c-3.1,1.5-6.6-0.8-9.3,0.7c0,8.3,6.3,7.5,13,7.4c-4.7,2.8-8.9,0.2-12.7,3.2c4.5,2.4,9.3,1.2,13.5,2.8c-2.6,2-5.9-0.4-8.6,2
				c2,1.9,4.6,0.9,6.9,1.8c-1.5,2.3-4.6-0.6-6.2,2.4c5.2,0.7,6.5,5.4,9.9,8.9c-13.9-3.1-23.2-20.8-18.8-35.4
				c0.9-3.2,1.6-4.5-2.4-6.2c-18.4-7.9-31.6-20.7-36.1-40.8c-0.7-3.1-2.5-2.9-4.6-3.3c-23.7-5.2-43.1-26.2-45.2-48.6
				C1854.4,16.6,1854,14.8,1858.7,15.4z M1881.6,102L1881.6,102c44.4,45.8,39.8,124.3-10.3,166.2c-48.7,40.8-117.7,30.8-154.2-13.4
				c1.1,0,2.1-0.3,2.7,0.1c17.6,11,37.2,14.1,57.4,13.2c13.4-0.6,26.2-4.1,37.5-11.6c7.8-5.1,14-11.5,13.4-21.9
				c-0.2-3.2,1.8-4.7,5-5c4.2-0.4,8.3-0.9,12.5-1.2c1.1-0.1,2.2,0.3,2.7-1.1c0.4-1.1,0.3-2.6-0.8-2.8c-4.9-1-4.1-5.1-4.6-8.4
				c-0.6-4.2-1.8-7.4-7.9-6.4c5.7-5.9,4.9-10.3,0.5-14.9c-7-7.3-6.9-7.3,1.2-13.9c3.6-2.9,6.6-6.5,8.9-10.5c3.3-5.7,5-11.2-0.3-16.9
				c-2.2-2.4-2.3-5.6-2.3-8.8c0.1-21.2-19-50.4-50.1-54.2c-23.1-2.8-44.8,1.2-65.3,12.1c-1.6,0.8-3.1,1.8-4.9,1
				C1762.6,59.8,1836.6,55.6,1881.6,102z M1836.3,222.3L1836.3,222.3c-2.6-1.4-3.6-3.8-4-6.4c-0.2-1.1-1.2-3.4,1.3-3.2
				c1.7,0.1,4.3-2,5.1,1.6c0.4,2.1,0.7,4.4,1.7,6.3c2.8,5.4,0.8,7-5.1,6C1835.7,225,1840.7,224.6,1836.3,222.3z M1731.7,178.5
				L1731.7,178.5c1.9,3.2,3.4,6.6,3.9,11.3c-5.1-2-9.7-3.3-13.9-5.4c-3.7-1.9-6.4-1.6-9.4,1.3c-3.1,3-6.9,5.4-11.4,8.8
				c0.6-4.3,0.9-7.4,1.5-10.4c1.5-7.8-0.1-14.2-7.5-18.5c-1.7-1-4.6-2.4-3.9-4.7c0.7-2.4,3.9-1.9,5.8-1.8c10.7,0.5,15.9-5,18-14.8
				c0.3-1.3,0.3-3.2,2.6-4c3.4,4.7,1.9,12.7,8.7,14.5c5.5,1.5,11.7-0.3,19.1,2.1c-4.3,3.9-7.6,7.4-11.4,10.2
				C1729.3,170.7,1729.1,174.2,1731.7,178.5z M363,25.9c-3.6-0.6-7.3-1.2-11.5-1.9C355.9,23.4,359.5,23.9,363,25.9z M257.6,89.2
				c24.3,22.2,36.3,49.7,36.8,78.6c-0.8,49.5-26.4,87.9-68.5,102.9c-40.7,14.6-77.4,5.1-108.1-24.4
				c-48.5-46.6-42.1-126.7,12.4-165.9C167.7,53.4,222.4,57.2,257.6,89.2z M37.3,113.6h21.8c-6.8-2.5-13.7-2.8-18.5-4.5h23.6
				c0.1-0.5,0.1-1,0.2-1.5c-6.3-0.9-12.6-1.8-18.9-2.7c0-0.3,0-0.6,0-0.8h22.3l0.1-1.5c-3.6-0.3-7.2-0.3-10.8-0.9
				c-3.6-0.6-7.6,0.8-10.4-2h23.8C66,96.9,61,96.2,56,95.4c6.2-1.1,12.3,0.7,19.4-0.9c-5.4-2.7-10.9-1.2-15.1-4.4
				c5.7,0.2,11.3,1.3,17-0.7c-1.1-2.3-3.1-0.9-4.2-2c0.7-2.8,4-1,5.3-3.1c-1.8-2.9-5.1-0.4-7-2.2c2.5-1.5,6.4,1,9-2.3
				c-1.7-2.4-4.7-0.7-6.7-2.7c2-1.6,5,0.4,6.8-1.7c-2.4-2.4-6.1-0.5-8.7-2.6c2.8-1.9,6.6-0.1,9.7-2.7c-2.9-0.8-5.6-1.4-8.2-2.1
				c0-0.3,0-0.5,0-0.8c2.6-0.2,5.2-0.4,8.1-0.6c-1.4-3.6-5-1.2-6.8-3.1c1.8-1.3,4.2,0.6,6.1-1.5c0.2-1.7-7.5-1.8-1.5-5.2
				c0.7-0.4-0.7-1.7-1.9-1.8c-2.4-0.2-4.8-0.4-6.9-0.6c2.5-0.2,5.3-0.5,8.3-0.7c-1.3-3.2-4.5-1.9-6.3-3.6c6.2-0.8,12.3,0.8,18.5-1.5
				c-5.2-0.9-10.5-1.8-15.7-2.6c7.7-0.8,15.2,1.4,22.8-1.1c-7.8-2.1-16.1-2-22.5-4.3c8.3-0.9,17.6,2.3,27.2,0.4
				c-6.7-3-14.2-2.2-21.2-4.2c8.7-1.1,17.5,0.9,26.1-1c-8.9-1.6-18-1.7-27.2-4.1c10.9-1.4,21.4,1.8,31.9-1.2
				c-9.8-1.1-19.7-2.2-29.2-3.3h32.6c0.1-0.5,0.1-1.1,0.2-1.6c-10.3-2-20.7-2.3-29.6-3.1c9.7-1.3,21,0.8,32.3-1.8
				c-9-2-18.4-0.5-27.8-3.6c11.2-2.4,22.3,2.5,33.6-2.7c-3.5,9.8-7.8,18.2-15.6,24.4c-8,6.3-14.8,14.8-26.4,15
				c-2.1,0.1-1.8,1.5-1.1,3.3c6,16.3,0.4,30.7-8.4,44.2c-5.1,7.7-11.3,14.7-19.7,19.2c-5.5,3-11.3,4.8-17.9,4.5
				c1.1-2.2,4.2-0.3,4.9-3.4c-2.2-0.8-4.4-1.5-6.7-2.3c6.6-0.9,13.1-0.1,19.4-2.1C47.7,115.6,42.7,114.2,37.3,113.6z M72.4,208.5
				v0.2c0,0-0.1,0-0.1,0c0,0,0,0,0,0C72.3,208.5,72.4,208.5,72.4,208.5z M20.1,409.8c9.4,2.8,19.1,3.9,28.8,2.6
				c2.3-0.3,4.9-0.1,6.9-2.6c-10.6-0.6-20.8-1.2-31-1.7c15.4-3.8,31.1-1.7,46.7-2.7c-5.1-3.1-10.7-1.4-17.5-3.2
				c9.8-3.8,19.2,0.7,27.5-2.8c0-0.7-0.1-1.4-0.1-2.1H42.5c15.1-3.7,30.3-2.3,45.4-3.8c0.8-0.1,1.6-0.7,2-1.8
				c-19.5-0.1-38.6-0.2-57.6-2.4c20.7-2.3,41.6-1.6,62.4-3.4c-18.7-2.5-37.5,0-56.1-3.9c17.4-0.5,34.8-0.8,52.1-1.4
				c2.7-0.1,7.6,2,7.7-1.7c0.1-2.9-4.8-1.2-7.3-1.2c-17,0.2-34-0.6-50.9-1.7c20.5-1.9,41.1-2.5,61.7-2.6c0-0.7,0-1.3,0-2H58.8
				c0.6-3.2,2.5-2.3,3.7-2.4c12.1-0.6,24.3-1.9,36.4-1.6c1.8,0,4.9,1,4.7-1.9c-0.2-2.7-3.3-1-5-1c-18.1,0.2-36.2,0-53.1-0.9
				c18.2-1.9,37.6-2.4,56.9-2.8c1.3,0,3,0.2,3.1-1.5c0.1-2.6-2-1.6-3.3-1.6c-10.8,0-21.5,0-32.3-0.8c12-3.2,24.5-2,36.6-4.5
				c-15.9-2.5-32.1-0.2-47.9-2.8c0-0.7,0-1.3,0-1.9c14.5,0,29,0,43.5,0c1.6,0,4.2,1.1,4.3-1.5c0.1-3.2-2.8-1.9-4.5-1.9
				c-14.1,0.1-28.2,0-42.3-1.4c14.9-2.6,30-1.9,45.2-3.1c-11.4-3.3-23.3-0.9-34.7-4.7h33.6c-9.2-4.8-19.1-2.1-28.4-4.9
				c8.5-2.1,17.2-0.9,25.9-2.4c-8.8-4.4-18.7-1.8-29.4-3.9c8.6-1,15.8-1.5,23-1.3c1.2,0,3.1,0.4,3.2-1.2c0.3-2.5-2-1.8-3.3-1.8
				c-5.4-0.2-11,0.5-16.2-3.2c4.2,0,8.5,0.1,12.7,0c1.6,0,4.3,0.6,4.2-1.7c-0.2-2.6-2.6-1.2-4.2-1c-8.3,1.1-16.5-0.3-24.7-1.2
				c8.5-1.7,17.4,0.3,25.7-3.6c-10.7-1.3-21.5-1.5-32.1-3.7c7.7,0,15.3,0.1,23-0.1c1.4,0,3.7,0.5,3.7-1.6c0-2.2-2.1-2.1-4-1.8
				c-8.2,1.4-16.2-1.6-24.3-2h23.2c0-0.6,0.1-1.2,0.1-1.8c-10.3-1.6-20.9-0.4-31.8-2.7h23.3c-6.1-3.2-12.9-3.1-19.4-5.3h11.6
				c0-0.6,0-1.3,0-1.9c-8.6-0.1-17.2-0.2-25.1-2.5c8.5-0.7,17.5,0.8,26.3-1.4c-8.1-2.6-16.7-0.5-24.8-3c9.3-1.6,18.8,0.9,27.9-2
				c-7.6-3.2-15.9,0.1-23.8-2.8c8.5-1.3,17,0.8,25-2.4c-6.3-1.9-13-0.2-20.4-2.9c8.1-0.7,14.9-1.3,21.8-1.9c-3.7-4-8.1-0.6-13.2-3.9
				c4.3-0.4,7.4-0.6,10.4-0.9c1.3-0.1,2.9-0.1,2.6-2c-0.2-1.4-1.7-1.1-2.7-1c-5,0.2-10.1,0.6-15.3-0.8c5.6-1.3,11.3,0,17.9-3
				c-8.1-0.8-15,0.7-22.6-2.9h19.5c0.1-0.6,0.1-1.2,0.2-1.8c-7.6-0.8-15.6,0.6-22.7-3.4H66c-5.8-1.7-11.5-2.1-17.4-3.5
				c3.8-1.7,8,0.5,12.8-2.1c-6.8-2.7-14.9,1.9-18.2-6c8.2-3.3,20.4,0.9,26.7,9.8c8.2,11.7,9.4,23.9,2.4,36.6c-1.1,2-2.7,3.8,0.7,5.2
				c19.4,8.1,28,24.6,33.4,43.3c1.5,5.4,4.4,7.2,9.5,7.7c8.2,0.8,16.3-0.6,24.5-1.2c-6.8-1.8-13.8-1.9-19.2-4.5
				c9.1,1.3,19.4,2.3,29.8,1.8c-8.1-3.5-17.1-2.5-25.5-5.9c11.1,1.6,21.8,2.7,32.5,3c0.1-0.6,0.1-1.1,0.2-1.7
				c-3-0.4-5.9-0.7-9.6-1.2c2.2-2.9,4.7-1.3,6.5-2.3c0.2-1.8-2.5-0.5-2.4-2.1c2.7-0.8,5.7,0.7,8.8-1.6c-7-3.8-14.8-1.8-20.6-4.2
				h21.1c0-0.3,0.1-0.6,0.2-0.9c-5.7-1.2-11.4-2.3-17.1-3.5c8.2-0.1,16.4,1.7,24.7,0.2c-8.3-2-16.9-2.7-25.7-5.9
				c12.9,1.6,25,2.6,37.2,1c-11.7-3.5-23.9-2-37.5-5.1c14.5-1.1,27,2.4,39.6,0.9c-5.1-2.4-10.7-2.2-17.4-3c5.2-1.4,9.2-0.8,13.2-0.6
				c7.1,0.3,7.2,0.9,4.6,7.7c-1,2.7-5.2,5.2-2.8,7.8c2.6,2.7,7.1,2.7,11.2,3.1c4.9,0.4,6.8-4.5,12.1-5c-4.1-0.9-7-1.6-11-2.5
				c5.6-1.7,10.4,0.9,15.7-1.6c-4.1-1.1-7.7-0.5-11.3-2.6c8.5-1.3,16.2,0.5,23.9,2.5c7.4,1.9,14.8,1.8,22.2-0.9
				c-2.7-2-5.9-1.9-8.7-3.4H251c-9-1.6-18.1-2.3-26.9-4.1c7.1,0.7,14.3,0.7,21.4,1.2c4.6,0.3,0.6-2.1-0.2-3.6
				c5.4-1.2,10.9,1.1,17.5-1.6c-7.7-2.3-14.8,0-21-3.5c8.1-0.7,16,1.5,25.2-0.8c-7-2.4-12.9-1.9-19.7-3c7.5-2.1,14.4,0.5,21-2.6
				c-4.9-0.7-9.8-1.4-14.7-2.1c0-0.5,0-0.9,0-1.4h16.6c-5.6-3.9-12.2-1.5-19.3-5.2h19.6c0-0.3,0.1-0.6,0.1-0.9
				c-3.5-0.8-7-1.6-11.2-2.6c4-2.8,8.5,0.2,12.8-2.7c-4.5-0.7-8.1-0.8-12.2-2.8c3.1-0.2,5.3-0.3,7.6-0.5c1.6-0.1,1.7-0.2,1.2-2.6
				c-0.1-0.7-0.4-1.1,0.5-1.1c1.4,0,2.9-0.8,3.8,1.4c1.3,3.2,1.8,6.5,1.2,9.7c-0.8,4.8,1.3,5.3,5.1,4.4c3.8-0.9,7.8-1.4,11.3-4
				c-1.2-1.8-3.3-0.5-5.3-2.2c4.5-0.2,8.4-0.4,12.2-0.6c0-0.5,0.1-0.9,0.1-1.3c-3.9-0.4-7.9-0.8-13.6-1.4c7.7-2.1,14.2,0.4,21.2-2.7
				c-6.4-1.4-12.1,0.3-18.1-3.3H308c-6.5-1.2-13.1-1.8-20.6-3.7c9.3-0.9,17.6,0.7,27.4-1.1c-5.6-2.3-10.4-0.2-14.9-3.2
				c5.7-1.9,11.2,0.3,16.3-2.1c-4.5-0.9-9.1-1.1-15-3.1c7-1.5,13.1,1,18.4-1.8c-8.9-0.8-17.7-0.6-26.3-3.6h28.2
				c-7.8-3.4-16-3.6-24.1-5.4h27.6c-4.1-1.9-8.5-1.4-13.2-3.8c5.3-1.8,10.5,0.9,14.9-2.3c-8.1-0.6-16.1-1.3-25.3-2.8
				c9.5-1.8,17.9,0.5,26.2-1.6c-6.2-1.4-12.6-1.7-18.5-4.2h18.8c-4.4-2.3-9.4-1.4-14.4-3.8c5.6-1.9,11.1,0.8,17.6-1.5
				c-4.8-2.3-9.2-0.4-12.8-3.7c4.4-0.3,8.2-0.5,12.1-0.8c0-0.5,0-1,0-1.5c-2.1-0.4-4.3-0.9-6.5-1.3c1.6-2,4.6,0.4,6.5-2.4
				c-2.8-0.5-5.2-0.9-7.9-1.4c2.5-1.9,5.4,0.1,7.9-1.6c-0.7-2.5-3.7-0.8-4.5-2.2c-0.8-0.7-0.6-0.8,0.1-0.8c1.7-0.2,4.1,0.7,4.8-1.2
				c0.9-2.7-2.9-1.5-2.8-2c1.2-7.7-7.7-1.3-8.5-6.8h17.7c-5.3-3.2-11.6-1.6-17.7-4.6c8.1-0.9,15.4,0.8,24.1-0.8
				c-6.5-1.6-12-0.6-18.5-3.1c7.8-0.5,14.1-0.9,20.4-1.2c0-0.3,0-0.7,0-1c-4.1-0.8-8.1-1.6-13.4-2.7c7.5-1.9,14.4,1.1,20.3-2.8
				c-6.5,0.1-12.7-0.6-18.9-2.7c7-0.9,14.3,0.2,21.2-2.3c-5.4-1.5-11.1-0.9-16.4-3.6c3.3-0.2,6.5-0.4,9.8-0.4
				c3.1-0.1,6.4,0.9,9.9-1.9c-7.2-0.4-13.9,0.9-20.9-2.8c8.3-0.5,15.6-0.9,22.9-1.4c0-0.6,0-1.2,0-1.8c-9.8-0.7-19.7-0.5-29.2-3.6
				h30c-12.1-1.1-24.1-2.6-35.9-5.6c12.5-1.1,25,2.1,37.6-1.4c-12.1-1.3-24.2-2.6-36.3-3.9c12.2-2.5,24.4,1.4,36.6-1.9
				c-15.5-1.5-31-3-46.5-4.6c0-0.4,0-0.7,0.1-1.1c7.9,0.4,15.8,1,23.7,1c7.7,0,15.5,1.9,23.2-1c-15.5,0.2-30.6-3.9-46-4.6
				c15.1-2.4,30.4,2,45.5-0.9c-14.1-0.3-27.8-3.4-41.8-5h40.8c-13.6-1.1-26.9-4-40.5-4.8c10.3-1.6,20.7,1.1,32-1
				c-14.3-3.5-28.2-2.5-41.4-6c15.7,0.9,31.3,1.7,47,2.6c0.1-0.8,0.1-1.5,0.2-2.3c-13.5,0.6-26.5-2.3-39.7-4.7h37.1
				c-13.1-1.6-26.1-3.9-39.1-6.4c10.4,0.5,20.7,1.1,31.1,1.5c1.7,0.1,4.8,1.1,4.9-1.5c0.1-3-3-1.6-4.7-1.6
				c-10.8,0-21.4-1.2-29.2-2.9c6.7,0,16.2,0,25.6,0c1.4,0,3.5,0.8,3.5-1.4c0.1-2.2-1.9-1.9-3.5-1.7c-7.8,1.4-15.1-2.5-22.7-3.1h21.4
				c0-0.4,0.1-0.8,0.1-1.1c-8.5-1.2-17-2.3-25.1-3.4c6-1.6,12.7,0.9,19.7,0.1c-2.4-4.4-6.1-2.9-9-3.3c-3.2-0.4-6.5,0.1-10.9-2.8
				h20.9c-3.9-2.1-8.4-1.4-12.6-3.7c4.8-2.2,9.9,1.6,15-2.5c-8-1.2-15.1-2.3-23.5-3.5c8.4-1.9,15.6,0.9,22.7-1.6
				c-8.9-3.1-18.8,0.1-26.5-3.9h25.6c0-0.5,0-1,0.1-1.5c-11.1-1.3-22.2-2.5-32.9-3.7c10.6-1.7,21.8,1.6,33-0.3
				c-11.3-2.2-22.6-3.6-34.1-4.5c10.9-2.3,21.7,1.2,32.6-1.2c-14.2-2.3-28.7-1.3-43.5-4.9c14.3-1,27.8,2.9,41.4-1.3
				c-14.6-1.3-29.2-2.5-44.7-3.8c14.8-1.7,28.8,1.8,42.8,0.4c0-0.8,0-1.5,0-2.3c-16.1-1.1-32.1-2.2-49-3.4c16-1.7,31.2,1.8,46.5-0.3
				c-16.5-3.7-33.8-1.4-48.4-5.7c14-0.7,29.8,2.2,45.6,1.1c0-0.7,0.1-1.3,0.1-2c-17.3-1.3-34.6-2.6-51.9-3.9c0-0.5,0-0.6,0-0.6
				c0.2,0.1,0.3,0.3,0.5,0.3c16,0,32,0,48.1-1L281,34.8c0-0.3,0-0.5,0-0.8h41.9c-9.6-2.9-19.6-2.4-29.3-4.2h26.6
				c0-0.6,0.1-1.2,0.1-1.8c-16.3-2.3-33-0.6-49.1-4.2c14.6-0.2,29.2,1.4,43.8-0.9c-9-0.4-17.9-0.8-26.9-1.2v-1h24.1
				c0-0.6,0-1.1,0.1-1.7c-14-1.3-27.9-2.8-42-3.2c9.8,0,19.8,1.3,29.3-0.3c14.3-2.4,20.3,7.2,26.7,16.1c7.9,11,13.9,23,16.3,36.5
				c0.7,3.9,2.2,4.5,5.8,3.2c6.5-2.3,14-2.3,18.2-9.4c1.1-1.8,5.3-0.2,7-3.8c-2.2-0.3-4.2-0.5-6.1-0.7c5.6-0.3,9.7-3.1,14.2-6.9
				c-4.6-0.3-8.7-0.5-12.7-0.7c5.7-2.2,11.9-0.5,18.2-3.7c-3.3-0.4-5.8-0.8-8.4-1.1c4-1.7,8.8-0.4,12.5-3.4
				c-5.3,0.3-10.3-0.6-17-1.5c7.6-1.9,14.2,0.6,19.9-3.6h-16.1c6.2-2.2,12.9-0.2,19-3.2c-9.5-0.6-18.9-1.3-27.3-2.9
				c9.7,0.1,20.3,0.2,30.9-1.9c-11.4-0.8-22.6-1.3-33.9-3.4c11.7-0.6,23.3,1.4,34.6-2.4c-15.5,0.1-31-0.8-46.3-3.1
				c16.9-0.7,33.9,1.9,50.7-1.8c-9.4,1-18.5-1.2-28-1.7c1.5-2.2,3.3-1.5,4.8-1.5c11.9-0.2,23.9-0.3,35.8-0.3
				c384.5,0,768.9-0.1,1153.4,0.1c25.9,0,51.9,0.6,77.7,4.5H1584c24,2.3,48,2.5,67.6,6.3c-18.9,1.7-42.2-2.4-65.4,0.6
				c24.3,2.3,48.6,5.1,73,6.5c-23.5-0.7-46.9-1.4-70.4-2c21.7,4.6,43.9,3.3,63.5,7.4c-19.5-0.7-41-1.4-62.5-2.2c0,0.7-0.1,1.3-0.1,2
				c9.6,0.9,19.2,1.8,28.8,2.7c9.8,0.9,19.6,1.7,26.9,2.3c-16.1,1.4-34.5-1.2-53-0.6c16.2,2.1,32.5,3.1,48.6,6.3
				c-15,0.8-30-1.3-45,1c15.4,0.6,30.5,3.3,45.6,5.7h-40.8c15.9,3.7,32.1,4,48.1,6.5h-41.7c9.8,1.4,19.4,2.8,30,4.8
				c-7.2,2.6-14.3-1.7-21,2.6c7.2,0.7,14,0.4,20.2,3.5h-15.1c-0.1,0.6-0.2,1.2-0.3,1.9c6.4,1.6,13.7-0.6,20.3,3.1
				c-3.3,0.3-6,0.6-9.5,0.9c3.5,4.7,8.2,5.8,13.1,6.1c-0.9,4-4.6,1.6-6.5,4.1c4,2.4,8.8,1.5,13.9,3.2c-2,0.4-3.1,0.4-4,0.8
				c-2,0.8-5.6-1.6-6,1.9c-0.5,4.5,1,8.7,5,11.5c2.1,1.5,2.6-0.7,3.3-1.8c6.7-10.7,15.7-19,26.3-25.5c3.8-2.3,1.7-4.7,0.8-7.3
				c-4.7-14.3-4.3-27.7,6.5-39.4c5-5.1,11-8,18.1-8.1c11.5-0.2,22.7,2.1,33.9,4c-13.7,0.4-27.4-2-41.2,0.7
				c15.9,1.6,31.7,3.2,47.6,4.7c0,0.4-0.1,0.7-0.1,1.1c-12.1-0.7-24.2-1.2-36.3-2.2c-7.1-0.5-14.1-1.2-21,1c7.1,3,14.6,1.2,21.9,2.4
				c5.6,1,11.3,1.2,17,2.3c-13.9,1.7-28-2.3-41.9,0.7c19.9,1.7,39.8,3.2,59.5,6.3c-20.8-0.5-41.5-2.2-62.3-0.9
				c20.2,3.4,40.9,3.1,55.5,6.3c-15.1,0.8-35.6-1.8-56.2-1.7c-0.1,0.6-0.1,1.1-0.1,1.7c15.7,1.1,31.4,2.3,47.1,3.4
				c0,0.2-0.1,0.4-0.1,0.5h-46.1c14.6,3.7,29.6,3.8,44.3,6.1c-14.7-1.1-29.4-0.4-44.1-0.9c-0.1,0.6-0.1,1.1-0.2,1.7
				c16.4,1.7,32.7,3.3,49.5,5c-17.1,0-33.6-2.5-50.3-0.2c14-0.7,27.5,1.5,38,4.4h-35c9.7,1.7,19.5,1.7,29.1,4.4
				c-8.9,1.1-17.8-1.4-26.7,0.6c8.8,2.6,18,2.3,24.3,4.4c-8.8,0-20.1-1.8-31.3,2.1c5.1,0.7,10.2,1.3,15.3,2
				c-7.3,1.2-14.7-0.2-21.6,2.6c11.7,1.4,23.5,1.2,35,4.4c-14.4-0.3-28.9-2.1-43.1,2.1c13.6,1.3,27.2,0.4,40.4,4.2
				c-14.3-1.1-28.5-0.5-42.8,2.1c17.9,0.7,35.7,1.4,53.6,2.1c0,0.6,0,1.2,0,1.8c-20.6-1.1-41.2-1.7-61.7,2.6
				c16.5-0.5,32.8,0.7,50.6,5.2c-19.5,0.2-37.1-3.3-54.6,1.2c13.9,0.2,27.7,1.6,41.4,3.9c-14.6-0.4-29.1-0.9-43.6,1.9
				c13.8,0.9,27.6,1.1,41.3,3.4c-14.6,1.2-29.3-0.9-43.7,2.5c20.7,1.2,41.5,2.4,62.2,3.6c-21.5,0.3-42.9-1-64.3,2.1
				c18.2,2.1,36.6-0.6,54.5,4.6h-54.7c0,0.6-0.1,1.3-0.1,1.9c18.8,1.9,38,0.2,56.5,4.2c-18.9,0-37.7-1.4-56.5,0.7
				c14.7,3.4,30.2-0.9,44.8,4.9h-44.5c0,0.6,0,1.3-0.1,1.9c10.3,0.2,20.7,0.4,30.8,2.9h-29.3c12.4,4.9,26,1.4,39.5,6h-39
				c9,2.9,18.8,0.8,27.7,5.5H1645c9.9,2.6,20,2.6,29.7,6.3h-27.5c0,0.7,0,1.4,0,2.2c13.6,1,27.2,1.9,40.8,2.9v1h-38.3
				c0,0.6-0.1,1.2-0.1,1.9c11.4,1.6,23.1,0.3,35.1,2.7c-5.5,3.3-10.8,2.2-16.1,1.3c-5.1-0.9-10.3-0.8-15.6,1.6
				c12,0.7,23.9,1.5,35.8,2.2c0,0.3,0,0.5-0.1,0.8h-32.5c0,0.4-0.1,0.8-0.1,1.3c8.4,0.7,16.9,1.4,25.3,2.1c0,0.3,0,0.5,0,0.8h-22.4
				c-0.1,0.7-0.2,1.4-0.3,2.2c7.5,0.7,15.2,0.5,24.1,3h-21.9c6.4,4.1,12.3,1,17.1,3.6c-3.1,2-6.9-0.4-10.9,2.1
				c5.7,0.6,6.7,7.9,12.7,6.4c-0.2,5.1,4.4,5.7,7.5,7.5c3.1,1.7,6.1,1.4,7.6-2.3c4.8-11.4,13.9-12.5,24.7-11.2
				c13.1,1.5,26.4,2.2,39.6,3.3c1.8,0.1,3.9-0.5,6.3,2.1c-21.7-0.5-42.4-3.6-63,0c16.9,1,33.7,2.9,50.5,4.4
				c-9.3,0-18.5-0.2-27.8,0.1c-9.3,0.2-18.7-1.3-27.8,1.9c17.9,1.1,35.6,2.5,53.3,4.3c-18.8,2.2-37.7-2.1-56.4,0.9
				c20.7,2,41.5,1.6,64.1,4.9h-67.8c0,0.7,0,1.5,0,2.2h35c0,0.8,0,1.7,0,2.5h-36.3c0,0.6,0.1,1.2,0.1,1.7
				c21.1,1.8,42.3,3.4,63.6,3.4c-21.3,1.5-42.7-0.3-64,0.5c19.7,4.4,40,1.5,57.2,4.7h-56.3c-0.1,0.2-0.1,0.3-0.2,0.5
				c4.9,0.7,9.8,1.4,14.7,2.1c0,0.4,0,0.8,0,1.2h-14.8c5.6,3.2,12,1.3,18.2,4.8h-18.2c3.5,2.5,7.8,1.7,12.1,4.3h-12
				c4.1,4.1,8.7,1.3,12.6,3.6c-3.2,2.2-6.8,0.2-9.8,1.4c-1.3,1.9,6.4,1.7,0.9,4.3c0,0,0.2,0.9,0.5,1.1c4.2,3.5,4.3,11.2,11.7,11.5
				c-0.8,1.5-2.5,0.6-3.9,1.6c2.3,0.8,4.4,1.6,7.1,2.5c-2.1,0.5-3.6,0.8-4.9,1.1c2.2,6.6,8.8,4.2,13.5,6.8c-2.1,0.5-3.7,0.9-6.4,1.6
				c15,9.1,31,9.8,46.7,11.2c-0.1,0.5-0.1,1.1-0.1,1.6c-11.9-0.6-23.9-1.2-35.8-1.8c0,0.5-0.1,1.1-0.1,1.6c15.3,3,31.1,2,46.3,5.8
				c-14.6-0.8-29.3-1.5-43.9-2.2c-0.1,0.7-0.1,1.4-0.2,2.1c14.3,1.2,28.7,2.4,43,3.5c-13.4,1.9-26.7-1.6-40.1,0.4
				c12.9,1.8,25.9,3.4,38.7,3.8c0.3,0.9,0.4,1.1,0.4,1.3h-36.4c14.3,1.6,28.7,1.8,40.8,4.3c-10.7,1.2-23.5-0.2-36.5,1.5
				c11.7,1.1,23.3,2.2,35,3.4c0,0.2,0,0.4-0.1,0.6H1741c12.7,3,25.9,2.3,39,4.9h-38.8c11.1,3.2,22.7,2,33.9,4.8h-26.9
				c8.5,2.9,17.3,2.5,27.7,3.1c-6.4,2-11.6,0.1-17.4,2.6c20.2,5.2,38.7,7,56.4-4.4c3.8-2.4,5-4.4,2.5-8.3c-2.9-4.5-2.7-9.7-2.9-14.7
				c-0.2-4.7,1.8-7.7,7.6-7.4c6.1,0.3,12.3-0.1,18.2,2.6H1819c-0.1,0.6-0.2,1.3-0.2,1.9c7.8,1.3,16,0.1,23.6,3.8h-23.6
				c8.5,3.3,17.5,1.6,25.9,5.4h-25.2c0,0.6-0.1,1.2-0.1,1.8c9.2,0.7,18.6,0.4,27.5,3.9h-26c8.7,3.9,18.2,2.3,28.9,5.5
				c-7.5,0.3-13.3-2-19.9,0.6c6.2,2.9,12.1,1.9,17.5,3.8h-19.3c3.9,1.9,8.2,1.4,12.2,3.3c-0.6,2.7-4.3,0.2-5,3.1
				c10.2,3.1,20.3,2.4,30.4-0.9c2.4-0.8,2.9-2.6,3.3-4.5c2.8-10.9,7.5-20.6,16.5-27.9c2.5-2.1,1.3-4.4,0.5-6.6
				c-2.1-6-2.6-12.1-1.7-18.2c2.5-16.2,14.9-23.1,32.2-19.6c-3.8,0.4-7.7,0.8-11.5,1.3c0,0.4,0,0.7-0.1,1.1c7,0.7,13.9,1.4,20.9,2.1
				c-10.1,1.4-20.4-0.5-30.4,2.3c11.6,0.9,23.1,1.5,34.5,3.6c-12.9,0.4-26-1.2-38.8,1.5c15,1.2,29.8,3.1,40.6,4.7
				c-11.6,2-27.3-2.6-43,0.7c11.1,1.1,22.3,0.5,35,3.7c-13.3,0.8-24.8-0.9-36.1,1.7c16,2.5,32.1,1.8,48,4.9c-16-0.2-32-0.7-48-0.4
				c0,0.8-0.1,1.5-0.1,2.3c16.6,1.4,33.2,2.7,44.9,3.7h-43.8c11.7,3.9,24.5,1.6,36.2,5.8c-11.7-0.9-23.4-1-35.3-0.1
				c6.2,3.2,13.4,1.6,21.5,4.6h-18.7c5.1,4.5,11.3,1.5,17.9,4c-6.9,0.6-12.7,1.1-18.5,1.6c-0.4,0.5-0.8,1-1.3,1.5
				c-2.4,1.9-6.4-0.3-8.7,3.9c4.6,0.5,8.8,0.9,14.2,1.4c-6.1,3.6-12.4,0.4-17.5,4.1c7.8,0.9,15.7,0.5,23.2,4h-24.9
				c0,0.6,0,1.2,0,1.8c12.1,0.1,24.1,0.6,36.1,2.7c-12.8,1.6-25.9-1.2-38.7,0.9c0,0.6,0,1.2-0.1,1.8c16,0.8,32.1,1.5,48.1,2.3
				c-16.4,2.6-32.8-0.7-49.2,2.2c16.8,1.5,33.7,1.1,50.3,4.4c-16.5,0.7-33.1-1.6-49.7,1.7c19.6,0.7,39.2,1.5,58.7,2.3
				c0,0.4,0,0.9,0,1.3h-57.6c0,0.7,0,1.3-0.1,1.9c21.6,1,43.2,2,64.8,2.9c0,0.3,0,0.6,0,0.9c-11.6,0-23.2,0.1-34.9,0
				c-8.6-0.1-17.2-0.5-25.8-0.8c-1.5,0-3.7-0.2-3.7,1.8c0,2.6,2.5,1.9,4.1,2c20,0.8,40,1.1,60,3.2c-10.3,1.1-20.7-0.1-31.1-0.1
				c-8.6,0-17.2-0.2-25.9-0.3c-1.6,0-4.2-0.7-4.3,1.7c-0.1,2.6,2.6,1.7,4.1,1.7c15.2,0.6,30.5-0.3,45.7,1.4
				c5.2,0.6,10.4,1.4,15.5,2.4c-21.2,0.1-42.3-2.5-63.5,0.3c13.7,2.4,27.9,0.4,41.5,4.7h-37.7c0,0.7,0,1.5-0.1,2.2
				c17.8,2.3,35.9,1.2,53.7,3.8h-50.2c-0.1,0.6-0.1,1.3-0.1,1.9c13.8,0.8,27.5,1.6,41.1,2.4c0.2,0.4,0.3,0.6,0.5,0.8
				c-0.2-0.1-0.4-0.2-0.6-0.2c-11.3,0-22.6,0-33.9,0c0,0.9,0,1.8,0,2.7h35.5c0.2,1.4,0.2,1.6,0.2,1.8h-29.3c0,0.6,0,1.2,0,1.8
				c7.9,2.6,16.4,0.7,25,2.6c-4.6,1.7-8.8,0.3-12.9,1.7c8.9,3.6,18.6,1.1,28.9,2.5c-3.4,2.7-6.8,1.2-9.9,2.8
				c15.1,2.3,29.7,2.6,44.1-1.4c7.9-2.2,7.9-2.1,7.9,6v602.4c0,11.2,0,11.2-10.3,15.6c-2,0.9-4.4,1-6.3,3.6c5.7-1.5,10.7-2.7,15.8-4
				c-18.4,7.1-35.5,16.9-54.2,23.4c3.2,1.5,5.8-0.2,8.5-0.9c0.5-0.1,1.1,0.2,1.6,0.3c-0.5,0.6-1,1.3-1.5,1.9c0.3,0.4,0.7,0.8,1,1.3
				c2-0.1,3.7-1.7,5.8-0.9l0,0l0.1-0.1h0.2c1.8-2.4,3.8-3.7,6.8-2.3c-2.2,1.1-4.3,2.4-6.8,2.3c0,0-0.1,0.1-0.1,0.1
				c0,0-0.1-0.1-0.2-0.1c-3,3.1-6.1,6.2-9.1,9.2c0,0,0,0,0,0c0,0.1,0,0.1-0.1,0.2c1.2,0.8,2.9-1.4,3.8,0.4c0.2,0.4-0.7,1.3-1.2,2
				c0,0,0,0,0.1,0c0.1,0.2,0.2,0.4,0.2,0.6c1.2,1.6,2.8-0.8,4.1-0.1c-1.6,1.5-3.2,3.3-5.8,2c0,0,0,0,0,0.1c0,0-0.1,0-0.1,0.1
				c0.5,1-0.4,1.1-1.1,1.1c-0.2,0-0.4-0.1-0.6-0.2c0.6-0.3,1.1-0.6,1.7-0.9c0-0.1-0.1-0.1-0.1-0.2c0.1,0,0.1,0.1,0.2,0.1
				c0.1-0.4,0.1-0.7,0.2-1.1c0,0-0.1,0-0.1,0c-0.1-0.2-0.1-0.3-0.1-0.4c-2.4-1.5-4,0.9-6,1.4c-0.8,0.2-1.8,0.4-1.3-1
				c0.2-0.5,0.6-0.9,0.9-1.4h-0.1c-0.1-0.1-0.1-0.2-0.1-0.3c-4.2-1.3-7.5,1.8-11.2,2.5c-4.9,1-9.7,2.3-15.1,3.6
				c6.1,3.1,11.5-1.3,17-0.6c0,0,0-0.1,0.1-0.1c0,0,0.1,0,0.1,0c0.1-1,0.8-1.1,1.6-1.1c0.2,0,0.4,0.4,0.6,0.7
				c-0.7,0.1-1.5,0.3-2.2,0.4c0,0.1,0,0.1,0,0.2c-0.1,0-0.1,0-0.2,0c-1.8,3.7-6.8,0.9-8.9,4c3.4,0.2,7.1-1.8,8.7,4.3
				c0.6,2.5,6-1,10.3-0.8c-3.2-2-5.7-0.1-7.9-1.1c11.1-5.7,45.5-15.9,53.9-15.4c-14.8,4.6-28.5,8-41.3,14.4
				c11.8-2.8,23.6-5.7,35.5-8.5v-0.1c0,0,0.1,0.1,0.1,0.1c1.7-1.1,3.6-0.9,5.4-0.2c-1.8,0.2-3.5,1.7-5.4,0.2c0,0,0,0-0.1,0
				c0,0,0,0-0.1,0c0.1,2.5-2,1.4-3.2,1.7c-11.9,3.6-23.8,7.2-36.1,11c10.5,5.5,20,0.7,29.7-1.4c-6.5,3.1-13.9,4.3-19.1,10.1
				c1.9-0.6,3.9-1.3,5.8-1.9c10.3-4.3,21.2-6.1,32.2-7.6c-10.2,3.1-20.4,6.2-30.6,9.3c-2.1,1.4-4.2,2.8-6.5,4.3
				c1.9,1.9,3.3-0.2,4.8,0.2c0.7-0.5,1.4-1.1,2.2-0.2c0.7,1-0.2,1.5-0.8,2c-1.7,1.8-5.1,1.5-6.1,5.2c11-6.3,22.6-8.7,34.4-10.8
				c-8.9,4.7-19.2,5.8-27.7,11.5c0,0,0,0,0,0c0.2,0.2,0.4,0.4,0.4,0.6c1.4,0.9,3-1.4,4.5-0.1c-0.7,1.8-2.6,2.1-4.2,3
				c-1.3,1.1-2.4,2.6-4.3,2.7c0,0,0,0,0,0.1c2.1,1.6,3.6-0.2,5.3-0.9c10.1-4.3,20.8-6.4,31.9-7.4c-10.1,6.4-23.8,3.8-34.1,14.6
				c12-4.6,22.5-7.8,33.7-7.8c-9,4.1-19.2,4.7-29.2,9.3c4.3,1.4,7-1.4,10-1.2c0,0,0-0.1,0.1-0.1c0.1,0,0.1,0.1,0.2,0.1
				c1.7-0.3,3.5-0.5,5.3-0.8c-1.7,0.7-3.5,1.5-5.3,0.8c0,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.2,0c-2.2,3.6-6.8,2.8-9.7,5.3h-0.1
				c0,0,0.1,0,0.1,0c0,0,0,0,0,0c5.4,1.1,10.7-2.8,16.2-0.4c-4.1,1.2-8.3,2.1-12.4,3.5c-3.9,1.4-5.1,7.3-2.3,9.7
				c7.7-2.6,15.7-3.4,23.9-2.4c-7.5,1.3-15,2.7-22.4,4c-0.8,0.2-2.4,0.4-2.4,0.7c-0.2,2.1,1.5,1.5,2.5,1.4c3.9-0.4,7.9-1.1,11.8-1.5
				c3.9-0.4,7.9-0.7,12-1c-10.9,2.7-22.2,2.7-34,7.2l0,0c3.3,0.2,5.2,0.4,7.4,0.5c-0.9,1.6-3.3,0.2-3.4,2.7h12.6
				c-7,1.3-12.3,2.6-17.6,3.3c-3.1,0.4-2.4,3.2-3,4.6c-0.9,2.1,2.6-0.7,2.7,1.8c-3.4,2.3-5.8,5.9-6.2,10.3c-0.2,3.1,2.8,0.1,3.8,1.7
				c-1.5,0.9-2.9,1.8-5.7,3.4c6.6,1.3,12-0.5,14.6,0.7c-4.7,2.4-13.8-2.1-19.7,5.6c0.5,2.5,3-0.2,3.7,2c-2.4,2.2-6.1,2-9,3.8
				c3,2.9,6.6,3.1,9.6,3.2c-14-0.6-25.3,7.6-37,14.6c-11.6,6.9-23.3,13.3-36.9,15.6c-6.8,1.2-12.9-1.5-19.4-1.3
				c10.1,5.5,20.5,4.7,31.1,1.1c0.2-0.1,0.4,0,0.6,0c-0.2,0.5-0.4,1-0.6,1.4c0.5,0.7,0.9,1.3,1.4,2c6.4-1,11.9-4.3,17.7-7
				c2.3-1.1,5.1-1.4,7.6-1.9c-16.9,10.7-35.2,15.6-55.5,12.7c3.3,3.6,7.9,3.2,11.9,4.9c-4.9,0.6-9.9,1.8-14.7,0.4
				c-4.4-1.3-5.8,2.3-5.1,4.3c0.9,2.7-2,5,1.9,8.7c6,5.6,6.2,5.3,18.7,6.9c8.3,1.1,16.7,0.5,25.1-0.7c9.3-1.4,18.7-2.1,28.1,0.5
				c-18.7-2.3-37,5.7-55.7,1.7c-3-0.7-6-0.9-9.1-0.9c5.2,3,11,3.2,16.7,3.6c11,0.6,22,0,33-1.6c7.9-1.1,15.8,0.5,23.5,2.5
				c-18.4-2.1-36.5,3-55,1.7c17,6.2,34.8-1.4,51.4,3.4c-13.1-1.8-26.5-0.6-39.9,2c4.9,2.4,10.2,0.3,15.2,1.4
				c-0.2,1.7-1.9,0.5-3.2,2.3c6.7,0.7,13.2,1.5,19.5,2.2c-6.3,0.4-12.8,0-18.9,2.1c-4.3,1.5,2.5,2.1,0.4,3.6
				c2.2,1.7-2.8,4.3,0.9,6.1c2.8,1.4,5.1,2.2,4.9-2c-0.2-4.8,2.3-3.8,3.8-1.8c1.7,2.2,4,1.4,5.9,2.5c-1.2,1.7-3.8-0.3-5.1,2.7
				c4.6,0.5,9,1,12.5,1.3c-3,0.8-7.2,1-11.3,0.5c-2.7-0.3-4.2,0.4-4.5,3.1c-0.4,3.7,2.9,1.3,4.1,2.4c0.6,0.5,1.7,0.2,4.2,0.5
				c-5.8,0.4-10.1,0.7-15.3,1.1c2.8,1.6,3,5,6.9,4.1c-3,2.2-8.1,0.1-8.3,4.3c-0.2,3.1,4,1.5,6.3,2.8c-4.5-0.1-9.7-0.3-10.3,5
				c-0.6,5.2,4.8,2.8,7.7,4.4c-5.4-0.1-10.6-0.1-11.8,6.8c9.1,1.1,18.6,2.2,28.1,3.3c1.1,0.1,2.2,0.2,3.3,0.2
				c-0.7,0.8-3-0.1-2.2,2.2c0,0.1,0.1,0.1,0.1,0.1c0.5-0.4,1-0.5,1.4-0.5c0.7,0,1.3,0.3,1.9,0.8c0.1,0.1,0,0.5-0.1,0.6
				c-1.2,0.3-2.4,0.3-3.2-0.9c-4.7-2.5-11.7-3.7-14.6-2.1c-0.8,0.5-1.6,0.8-2.4,1.1c0,0,0,0.1-0.1,0.1c-0.5,0.2-1,0.3-1.5,0.4
				c-5.9,1.3-11.8-0.9-18.2,3.1c3.2-10.7,5.8-19.3,8.4-27.9c4.3-14.2,0.8-27.4-9.4-34.3c-5.3-3.6-11.2-6.4-17.6-7.1
				c-7.1-0.8-13.7-3.4-20.5-5.2c-4.7-1.3-6.9-4-6.5-9.1c0.7-8.5,0.6-17.1,1.5-25.6c0.9-7.6,4.1-9.2,11.5-6.4c4.2,1.6,8.4,2.6,12.8,3
				c4.6,0.4,8.5-2.6,13.6-2.2c-1.1-2.5-2.8-3.1-4.5-3.5c8.3,1.6,16.4,1.9,24-2.8c0.6-0.4,2-0.6,1.6-1.8c-0.1-0.1-0.3-0.2-0.4-0.3
				c0.1,0,0.1-0.1,0.2-0.1c-1.1-0.2-2.3,0.7-3.3-0.5c0.9-0.4,1.9-0.9,2.8-1.3c1.3-1.7,5.4-2.2,3.8-4.6c-1.4-2.2-2.9-6.4-8-3.7
				c-6,3.2-12.7,1-19,0.1c6.6-0.7,13.5,1.9,21.1-3.2c-13-0.4-24.7,0.6-35.9-3.5c10.7,2.4,21.3,4.7,32.8,0.3
				c-3.2-2.6-6.1,0.4-8.5-1.5c1.8-0.9,4.5-0.3,6.2-3.3c-4-0.2-7.5-0.3-10.1-0.4c3.9-1,8.9-2.2,15.4-3.7c-6.8-3.3-11.9,2.1-16.9-0.3
				c4.5-2.9,10-1.4,15.7-2c-1.6-2.5-3.5-3.3-5.5-3.5c-0.3,0.2-0.5,0.3-0.6,0.2c-0.2-0.1-0.4-0.2-0.6-0.2c-0.8,0.1-1.6,0.2-2.4,0.3
				c-3.3,0.6-6.7,1.9-10.2,1c4.3,0.3,8.3-2.7,12.7-1.3c0.4,0,0.8,0,1.3,0c0.4-0.3,1-0.9,1.1-1.3c0.3-0.9-0.5-1.6-1.5-1.8
				c-2.7-0.5-5.2-0.3-7.8,0.5c-2.6,0.8-5.2,0.9-8.3,0.2c4.4-1.6,8.6-2.3,12.9-3c-9.6-4.6-18.8,3.9-28.4,0.1c8.5-1.6,17.1-3.2,26-4.9
				c-4.3-4.2-9-0.4-13.7-1.2c1.9-2.6,4.3-2.4,6.7-2.6c1.1-0.1,3.4,1.3,3.1-1.7c-3.4-0.4-6.9-0.9-11.6-1.5c5.2-1.2,9.1-1.5,13-0.1
				c1.3,0.1,2.5,1.2,3.8,0.2c-2.5-4.9-6.4-7.4-12.6-6.8c1.3-3.1,4.5-0.9,5.4-3.4c-2.7-1.3-5.5,0.3-8.1-0.4c1.6-0.4,3.5-0.7,5.2-1.4
				c0.8-0.3,3.1,0.1,2-1.9c-0.6-1.2-0.4-3.8-3.1-3c-3.2,1-6.6,0.3-9.6,0c-2.3,0.4-4.4,0.8-6,0.9c1.5-1.2,3.6-1.2,6-0.9
				c2.8-0.5,6-0.9,9.3-0.7c-0.1-2.6-2.7-1.1-3.3-2.6c1.6-1.8,4.1,0.4,6-1.5c-4.6-3.4-11-0.8-16.7-5.3c6.8,0.8,10.5-1.9,12.9-7.1
				h-16.9c6.7-1.4,13.7-0.5,20.3-2.9c-1.8-2.2-4.5-0.1-6.5-1.4c2.2-0.9,4.3-1.8,7.3-3c-6.9-0.4-13-0.7-18.9-1
				c-0.8,0.1-1.6,0.1-2.4,0.1c0-0.1,0-0.2,0-0.3c0.8,0,1.6,0.1,2.4,0.1c3.7-0.2,7.4-0.4,11.2-0.6c-0.6-2.4-3.5-1.5-4.5-3.3
				c4.4-0.8,8.9,0.4,12.6-2.2c-14-0.1-28-2.2-41.5,2.8c-0.6,0.2-1.4,0.3-1.9,0.6c-10.2,7-20.2,8.1-32.7,4.2
				c-11.4-3.6-20.1,4.7-21.3,17.4c-0.8,8.1-4.5,13-12.3,15.1c0,0,0-0.1,0-0.1c-2.6,1.7-5.2,3.5-7.8,5.2c-7.9,8.5-8.7,13.5-3.4,24.2
				c1.2,2.4,0.7,4-0.5,6.1c-2.7,4.7-3.3,9.8-0.4,14.5c4.1,6.5,4,12.8,0.6,19.3c0.4,0.3,0.9,0.7,1.3,1c2,0.2,3.1-3.2,4.7-1.9
				c3,2.5-1.6,4.3-1.3,6.9c3.8,1,3.2-5.1,6.8-4.1c3.1-7.6,3.5-15.1-0.4-22.6c-1.3-2.4-0.9-5.5,0.4-7.7c2.4-4.2,3.3-8.2,2.8-13.1
				c-0.6-5.4,5-5.8,8.8-8.1c-0.3,4.3-1.7,7.9-2.9,11.5c-2.4,7.3-1.1,9.4,5.9,10.5c-4.2,1.3-8.2,12.5-6.2,18.6
				c2.9,9.1,9.9,16,13.2,25c1.2,3.3,6.8,3.3,10.8,3.4c6.8,0.1,12.8-3.2,19.3-4.5c-0.6-2.1-2.3-1.1-2.6-1.4c1.6-0.2,4-0.6,6.6-1
				c-1-1.8-2.4-1.7-3.6-1.9c0.4-0.1,1-0.2,1.2-0.4c0.9-1.3-0.1-1.9-0.9-2.4c-0.3,0.2-0.5,0.4-0.8,0.6c-0.1,0.1-0.4-0.1-0.6-0.2
				c-0.1,0,0-0.3,0-0.3c0.4-0.1,0.9-0.3,1.2-0.1c0.1,0,0.1,0.1,0.1,0.1c1.9-1.5,3.8-3,4.3-5.6c0-0.2-1.4-0.6-1.6-0.6
				c1.8-0.6,4.9-1.2,6.4,0.7c1.5,1.8-0.3,4.5-1.2,6.7c-3.8,8.9-12,10.2-20.1,11.6c-1.9,0.3-4,0.4-5.9,0.1c-5.1-0.9-7.1,1.4-7.3,6.3
				c-0.1,3.7-1.9,4.5-5.8,6.1c-15.4,6.4-31.1,3.5-46.5,3.2c-17.9-0.3-26.7,14.7-29.5,27.8c-0.8,3.9-2.1,6.3-4.1,9.1
				c-0.9,1.2-2.2,2.2-1.9,4c1.6,1.4,2.4-1.8,3.9-0.8c0.2-0.5,0.2-1.1,0.5-1.4c0.6-0.6,0.7,0.1,1,0.4c0,0,0,0,0,0
				c-1.7-2.2,1.1-2.6,2.3-3.6c-0.8,1.2-1.6,2.4-2.3,3.6c0,0,0,0,0,0c0,0.1,0.1,0.1,0.1,0.2c0,0-0.1-0.1-0.1-0.1
				c-0.5,0.3-1,0.7-1.5,1c-2.5,3.9-1.9,6.7,2.8,8c0,0-0.1,0-0.1-0.1c0,0,0.1,0,0.1,0.1c0,0,0,0,0,0c5.1,3.3,7.5-1.8,10.7-3.8
				c1.8-1.1,3.2-2.9,5-0.4c0,0,0,0,0,0c0.4,6.4-0.2,12.3-6.7,15.9c-0.7,0.4-1.1,3.1,1.2,4.7c2.5,1.7,2.2-0.2,3.3-1.2
				c2.6-2.2,5.7-3.9,8.7-5.8c-1.2-1.9-3.7,0.9-4.3-1.5c-0.4-1.5,0.8-2.5,2-3c5-2.3,7.8-7.8,14.5-8.1c2.9-0.1,7.5-2.7,8-7.5
				c0,0,0,0-0.1,0c0,0,0-0.1-0.1-0.1c-2.3-1.7-2.9,2-5.2,1.7c2.3-2,3.9-4.4,6.5-5.5v-0.2c0,0,0.1,0.1,0.1,0.1c0.1-1.4,3-1.8,1.8-3.6
				c-0.2-0.1-0.4-0.2-0.5-0.3v-0.1c-0.3,0.1-0.6,0.2-0.9,0.2c0,0.1,0,0.1,0.1,0.2c-0.2,0.1-0.5,0.2-0.8,0.2c-1,0.6-2.1,1.3-3.2,2
				c0.1-2.6,1.9-1.9,3.2-2c0.2-0.1,0.4-0.2,0.6-0.4c0,0,0.1,0,0.1,0c-0.6-1,0.1-1.2,0.9-1.4v-0.2c0.4-0.4,0.9-0.9,1.3-1.3
				c0.1,0,0.2-0.1,0.3-0.1c3.3-4.9,8.6-2.6,13-3.4c-2,1.9-6,2-5.3,6.1c0,0,0.1,0,0.1,0c0,0-0.1,0.1-0.1,0.1v0
				c1.7-0.8,3.3-1.9,5.1-2.4c2.5-0.8,1.6,0.7,1,1.8c0.4,0.5,0.8,0.9,1.2,1.4c1.8-0.4,3.2-1.3,4.3-2.7c-0.5-0.5-1.1-1-1.6-1.5
				c-0.7-0.1-1.5,1.2-2.1-0.1c3.3-1.3,5.6-5.3,10-4.1c0,0,0,0,0-0.1c-0.3-0.3-1.3-0.3-0.5-1c0.3-0.3,0.8-0.3,1.3-0.4
				c0-0.1,0.1-0.1,0.1-0.2c0.8-0.6,1.3-1.8,2.5-1.6l0-0.1l0.2,0.1c0,0-0.1,0-0.1,0l0,0.1l0,0c-0.8,0.5-1.6,1.1-2.4,1.6
				c-0.1,0-0.1,0-0.2,0c-0.2,0.5-0.5,0.9-0.8,1.4c0,0,0.1,0.1,0.1,0.1c0,0-0.1,0-0.1,0c-0.8,0.5-1.7,1.1-2.6,1.6
				c0.4,0.6,0.7,1.2,1.1,1.8c1.5,0.5,2.7-1.5,4.3-0.5c-1.2,0.9-2.3,1.7-4,3c7.5,1.1,14.3,0,20.9-2l-0.2-0.2h0.2c0,0.1,0,0.1-0.1,0.2
				c-0.9,1.6,0.4,1.6,1.4,1.3c2.4-0.9,4.7-2.1,7-3.1c0-0.1,0-0.1,0-0.2c1.2-0.2,2.4-0.3,3.5-0.5c0-0.2-0.1-0.4-0.1-0.6
				c0,0,0.1,0.1,0.1,0.1c0,0.2,0,0.4,0,0.6c-1.1,0.2-2.3,0.3-3.4,0.5c-0.1,0-0.1,0.1-0.2,0.1c-0.5,2.4-2.9,3-4.2,4.7
				c0.4,0.5,0.9,1,1.3,1.5c3.2-1,6-3.3,9.5-3.2l0.6-0.1c0.4-0.6,0.8-0.6,1.3-0.1c0,0,0.1,0.1,0.1,0.1c3.8,1.5,7.5,3,11.6,1.7
				c0.1-0.1,0.2-0.2,0.2-0.3c0.4-0.6,0.8-1.2,1.1-1.8c0.2-0.8,0.6-1.2,1.5-1.1c0.1-0.7,0.5-0.8,1-0.3c-0.3,0.1-0.6,0.2-0.9,0.4
				c0,1-0.7,1.1-1.4,1.2c-0.4,0.6-0.7,1.2-1.1,1.8c-0.1,0.1-0.2,0.1-0.4,0.1c-1.3,1.4-3.9,2.3,0.6,4c4.2,1.6,6.7-2.9,10.3-1.7
				c0,0,0,0,0.1,0c0,0,0.1-0.1,0.1-0.1c-0.1-0.2-0.2-0.4-0.3-0.7c0.3,0,0.6-0.1,0.9-0.1c-0.2,0.2-0.4,0.5-0.6,0.8
				c0,0.1,0.1,0.2,0.1,0.2c-0.1,0-0.2-0.1-0.2-0.1c-1.9,1.6-3.8,3.1-6,4.8c2.1,1.9,4.3-0.6,6.1,0.6v0c0.1,0,0.1-0.1,0.2-0.1
				c-0.1-2.9,2.6-2.6,4.3-3.6c-0.8,1.9-2.5,2.8-4.3,3.6v0.2c-0.1-0.1-0.1-0.1-0.2-0.1c-0.3,0.2-0.4,0.6-0.4,1
				c0.3,0.4,0.6,0.7,0.8,1.1c1.6-0.6,3.2-1.3,4.9-1.9c1.2,2-2.8,2-1.6,4c2.8,1.7,4.5-3.1,7.4-1.1c0.4,0.5,0.8,0.9,1.2,1.4
				c0.6-0.1,1.4-0.3,1.9-0.1c1.4,0.7,0.1,1.5-0.3,1.7c-2.1,1.4-4.4,2.6-6.6,3.8c0.3,0.4,0.7,0.9,1.1,1.4c2.6-0.8,5.3-1.6,8-2.4
				c0.1,2.4-1.9,2.3-2.8,3.3c0.4,0.5,0.9,1,1.3,1.5c1.1-0.2,2.1-0.4,4.7-0.8c-2,1.5-3.1,2.4-4.7,3.7c0.3,0,0.5,0,0.8-0.1
				c-0.2,0.2-0.4,0.4-0.7,0.6h5.6c-1.8,3.2-6.1,1.8-7.9,5.1h8.1c0.1,0.2,0.1,0.5,0.2,0.7c-2.2,0.7-4.3,1.4-7.4,2.3
				c4.6,3.4,9-0.5,13,1.4c-0.8,2.6-4.1,1.3-6.3,4c7.9-2.9,7.1,6.6,12.6,6.8c-4.2,0.7-7.5-1.2-10.5-3.4
				c-13.7-9.9-28.7-18.1-41.1-29.8c-5.4-5.1-12.3-8.1-19.9-7.5c-11.9,0.9-23.8-1.4-35.6,0.1c-1.9,0.2-4.7-0.9-5.3,1
				c-0.9,2.8,2.2,3.1,4.1,4.1c0.6,0.3,1.1,0.7,2.1,1.4c-11.7,1.8-15.8,12.5-23.4,18.8c-0.8,0.7-1,1.9-0.3,3c0.9,1.3,1.9,0.6,2.9,0.3
				c4.8-1.5,8.4-4.7,11.5-8.5c1.9-2.3,3.6-5,7.8-4.1c-1.3,4.6-5.3,7.1-7.4,10.8c-0.8,1.4-3.1,2.7-1.1,4.5c1.6,1.4,3.4,0.1,4.8-0.8
				c3.5-2.3,6.2-5.6,8.8-8.9c1.8-2.2,3.3-4.8,6.6-5.1c0.6,0,1.1,0,1.7,0c-0.2,0.5-0.4,1-0.6,1.4c-1.8,3.5-3.8,6.9-5.4,10.5
				c-0.5,1-1.7,2.5-0.5,3.5c1.3,1.2,3,0.3,4.3-0.4c3.6-2,5.5-5.5,8-8.6c1.2-1.6,1.7-4.2,5-4.2c-0.2,4.9-2.7,9.1-3.8,13.6
				c-0.3,1.2-1.5,2.5,0.1,3.5c1.3,0.8,2.6,0,3.6-0.9c2.8-2.5,4.8-5.7,6.3-9.2c2.7-6.3,13-7.9,18.1-3.1c6.5,6.2,12.9,12.4,18.3,19.7
				c2.3,3.2,6.1,3.7,9.8,1.2c2.2-1.5,4.1-1.9,4.7,1.5c0.3,0.4,0.6,0.9,0.9,1.3c1.3-0.2,2.4-1.7,3.9-0.5c0-0.4-0.1-0.7-0.1-1.1
				c0.1,0.3,0.1,0.7,0.2,1c1.2,2.9,3.5,0.4,5,0.5c12.6,0.8,24,5.1,35.1,10.6c0.2,0,0.4,0.1,0.6,0.1c0.1,0.1,0.1,0.2,0.2,0.3
				c0.4,0.1,0.9,0.3,1.3,0.4c-0.2,0.2-0.6,0.4-0.6,0.4c-0.2-0.3-0.4-0.6-0.7-0.9c-0.1,0-0.2-0.1-0.3-0.1c-0.2-0.1-0.3-0.2-0.5-0.2
				c-8.1-0.9-14.7-6.4-22.8-7.2c-0.6,0.6-1.1,1.2-1.7,1.8c7.3,3.4,15,5.8,21.6,10.7c0.2,0.1,0.5,0.2,0.7,0.3
				c0.1,0.1,0.2,0.2,0.3,0.3c0.3,0.1,0.6,0.2,0.9,0.4c-0.1,0.1-0.4,0.2-0.4,0.1c-0.2-0.2-0.3-0.3-0.5-0.5c-0.1-0.1-0.3-0.1-0.4-0.2
				c-0.2-0.1-0.4-0.3-0.5-0.4c-6.9-2.7-13.8-5.3-20.6-8.3c-3-1.3-6-2.3-9-3c-0.1,0-0.3,0-0.4,0c-1-0.1-2.3,0.5-2.7-0.6
				c-9.1-1.4-18.2-0.2-27.6,2.3c-12.7,3.4-26,4.7-39,7.5c-2.8,0.6-7.6,0.6-11-3.2c-3.5-3.9-10.6-3.5-12.6,4.5c-2,8-3.6,16-0.3,23.9
				c3.8,9,12.4,10,17.7,1.8c2.3-3.7,4.8-4.4,8.6-4.1c16.6,1.2,33.1,1.9,49-4.4c4.5-1.8,2,4.6,4.9,4.4c0.3-0.1,0.7-0.2,1-0.3
				c-0.4,0.2-0.7,0.2-1,0.3c-18.7,4.9-37.9,4.7-56.7,8.1c8.6-0.1,17,0.8,25.3,2.7c-18.1-0.5-36.1,0.8-54,4.4
				c28.7-4.6,56.8,0.3,85.7,4.6c1.7-0.3,3.4-0.7,5-1.2c1.4-0.4,3.1-0.6,5,2.6c-3.4-0.5-6.7-1-10.1-1.5c-7.3,1.2-14.7,0.5-21.8-1
				c-27.8-6-55.2-4-82.6,2c-2.6,0.6-5.2,1.1-7.8,1.6c2.4,1.5,5.2,0.1,8.5,1.2c-3,1.1-5.4,2-7.8,2.8c16.2-0.9,32-4.7,50.1-2.5
				c-16.4,1.9-31.4,0.1-45.5,4.7c0.1,0.6,0.1,1.2,0.2,1.8c4.1-0.4,8.3-0.8,12.3-1.1c-0.7,2.2-1.6,3.7-4.3,4.5
				c-18.4,5.4-37.1,2.6-56.3,3.3c6.7-11.4,15-21.2,12.8-34.8c-0.6-3.8,2.9-3.6,5.1-4.6c0.5-1.1,1.6-1.7,2.3-0.9
				c1.2,1.2-0.5,1.4-1.1,2c-0.7,1-2.2,2.1-2.1,3c0.2,1.6,2.1,1.2,3.3,1.2c5.2,0.1,9.3-2.9,12.7-6.2c4.3-4.3,8.2-4.9,13.4-1.8
				c4.1,2.5,8.6,4.4,13.1,6.4c7.8,3.4,11.8,0.7,11.7-7.5c0-4,0.2-7.9,1.1-11.8c0.3-1.3,0.8-3.8-1.5-3.5c-7.1,0.9-10.8-4.5-15.9-7.6
				c-9.2-5.6-18.2-9.5-29.9-4c-13.4,6.3-13.9,5.3-19.8,12.4c-1.9-2.5-4.2-4.6-5.7-7.3c-3.4-6-8.2-6.6-13.9-3.8
				c-7.2,3.5-14.2,7.2-19.4,13.5c-3.6,4.4-7,4.3-8.5-1.2c-2.9-10.6-10.7-15-19.9-18.4c-2.7-1-5.4-2.1-7.8-3.6
				c-7.1-4.2-14.2-4.1-21.5-0.4c-1.7,0.9-3.6,1.5-5.8,1.7c3.5-5.9,9.1-8.9,15.5-11.5c-9.6-0.6-18.9-1.7-28.5-1.8
				c4.9,3.7,11.1,2.7,17.9,4.8c-9.2,1.2-16.7-1-25.7-1.4c7,4.3,14.4,1.6,21.1,5.5c-7.1,0.5-13.1-1.5-19.1-2.1
				c-2.5-0.2-8.6,3.1-11.8,4.6c-3.3,1.7-7.5,1.6-11.3,2.3c0.6,0.9,1.7,0.4,2.6,0.2c2.1-0.4,2.1,1.2,2.1,2.3c0,1.6-1.4,1.2-2.5,1.2
				c-6.6,0-13-2.4-19.9-1.8c0.9,7.2,1.7,14.2,6.2,21.9c-10.4-3.9-9.8,1.5-9.1,7.3c0.5,4.4,0.5,8.8,1,13.2c0.3,2.4,1.2,5.5,3.4,6.1
				c7.9,2.5,6.6,8.9,6.4,14.4c-0.1,4.5,1.7,5.8,5.9,6.4c13.4,1.9,13.1,2,13.2-11.8c0.1-9-0.3-18.1,1.2-27c0.2-1.2-0.4-3.5,2.2-3.2
				c3,0.3,1.7,2.4,1.5,3.9c-1.7,13-0.8,26.1-0.1,39.2c2.7-3.2,2.6-7.4,3.2-11c0.5-3.1,1.7-3.7,4.1-3.5c4.4,0.3,9,0,13.2,1.2
				c8.7,2.4,15.8-0.4,22-5.9c10.3-9.2,20.5-7.1,30.8-0.4c1.7,1.1,2.4,1.4,1.1,4.2c-3,6.7,0,12.5,9.3,19.2c-13.4,0-25.2-0.2-37.2-0.9
				c-9.6-0.5-19.8-0.1-29.7,0.9c-12.3,1.2-24.7,0.3-37.1,0.3h-1004c-12.4,0-24.8,0-37.2,0c-12.4,0-24.8,0.1-37.2,0
				c-12.3-0.1-24.7-0.3-37-0.3c-12.3-0.1-24.6,0-37.4,0c5.1-5.1,7.5-11.1,4.3-18.1c-0.4-1,0-1.5,0.6-2.2c5.3-5.9,20.8-9,26.9-3.6
				c10.3,9,21.3,10.7,34.3,8.3c9.3-1.7,9.4-0.8,11.2,8.8c0.3,1.8-0.3,4.1,3,5.4c-0.3-12.8,0.7-25.2-1.5-37.5c-0.4-2.2-2.4-5.3,2.1-6
				c0.4-0.1,0.7-0.3,1-0.5c0-0.3,0.1-0.6,0.3-0.8c0.1-0.4,0-0.8-0.1-1.3c-1.2-6.5,0-13.2-2.6-20.1c-4.5,6.9-10.1,5.4-15.5,2.3
				c-5.7-3.3-11.5-6.4-17.5-9.3c-8.5-4.2-16.4-4.2-24.6,0.5c-7,4-15.7,5.1-21.2,11.8c-0.9,1.1-3,1.8-1.9,3.9c0.1,0,0.2,0,0.2-0.1
				c0,0.1,0,0.1,0,0.2c0.8,0.1,1.7,0.1,2.6,0.2c-0.2,1.1-1.1,1.5-2,1.9c-2.6,2.7-4.8,6.8-7.9,7.6c-3.8,1-4.9-4.7-7.9-6.7
				c-3.5-2.3-6.8-4.9-10.4-6.9c-13-7.1-13.7-7-22.4,4.8c-2.5,3.4-3.2,2.8-5.9,0.2c-8.3-8-19.7-9.5-30.2-12.9
				c-0.9-0.3-4.1-1.4-2.4,2.3c0.6,1.3-1.4,1.8-2.2,1.4c-5.1-2.8-2.6,1.7-3.2,3.1c0,0,0,0,0,0s0,0,0,0c0.4-0.3,0.8-0.4,1.1,0.3
				c0,0.1-0.1,0.2-0.2,0.3l-1-0.6c-0.1,0-0.1,0.1-0.2,0.1c0-0.1,0.1-0.1,0.1-0.2c-0.5-0.6-1-1.7-1.6-1.8c-1.9-0.3-1.2,1.1-1,2.1
				c1.1,6.9,0.9,6.1-6.1,7.4c-5.3,1-10.3,1.7-15.7,1.1c-2.4-0.2-1.7,2.1-1.4,3.4c1.1,5.2,1.7,10.5,0.9,15.7
				c-0.5,3.2,1.3,3.8,3.7,4.2c3.6,0.6,7,0.2,10.2-1.6c2.3-1.3,4.8-2.2,7.1-3.6c7-4.2,13.2-6,19.8,1.4c2.4,2.7,6.5,5.3,11.3,4.4
				c2.2-0.4,2.7-1.1,1.4-2.9c-3.7-3.1-7.5-6.3-11.2-9.4c5.1,1.4,9.1,4.3,12.2,8.5c3.1,0.1,4,1.8,4,4.7c-0.2,7.9,1.5,15.1,7.2,21.1
				c2.2,2.4,1.3,6.6,5.6,10.1c-23-3.6-44.3,2-65.3-3c0,0,0,0,0,0c-1.2,1-2.5,2-3.7,3c-1.6,0.1-3.8,0.5-4-1.6
				c-1.3-0.1-2.6-0.2-3.9-0.3l-0.2,0.2c-0.2-0.1-0.3-0.2-0.5-0.3c-0.7,0-1.4-0.1-2.1-0.1c-0.2,1.5-1.7,2.2-2.9,2.3
				c-5.6,0.4-11.3,1.2-16.7-1.3c6.6-0.9,13.1-1.2,19.6-1c0-0.2,0-0.4,0-0.6c-0.2-1.9-1.9-1.5-3.1-1.5c-14.3-0.6-28.6-1.1-42.5,3.4
				c-4.6,1.5-9.2-1-13.9-0.4c-0.7,0.2-1.5,0.3-2.2,0.5c0.7-0.3,1.5-0.4,2.2-0.5c24.8-5.9,49.9-5.5,75-2.8c1-1,1.9-2,2.9-3
				c1.5,0.1,4.1,0.1,3.1,1.9c0,0,0,0.1,0,0.1c2.7,0.5,5.7-1.1,8.3,0.1c2.5,1.1,5.5,1.1,8.3,1.6c0.4-0.5,0.8-1,1.2-1.5
				c-14.5-5.4-29.7-5.2-44.8-5.3c-6,0-12,0-18.1,0.2c19.9-5,39.5-0.5,59.6,0.7c-2.3-0.6-4.5-1.1-6.8-1.7c-2.3-0.6-4.6-1.2-8.2-2
				c6.5-1.9,11.5,2.3,16.4,0.3c-27.5-6.7-55.5-9.8-84-5.5c9.8-4.3,20.2-3.4,30.3-3.2c10.3,0.2,20.6,1.3,30.9,1.2
				c-21.5-2.4-43-6.1-64.9-3.1c10.4-2.5,21-3.8,33.1-2.8c-1.7-1.3-2.1-1.9-2.6-2c-6.5-0.8-12.8-0.3-19.5-2.3
				c-7-2.1-15.1,0.1-22.7,0.8c-4.9,0.4-9.3,0-13.9-0.8c5.8,0.2,11.6,0.6,17.6-1.6c-6.6-1-13.3-2-21.5-3.2c3.3-0.7,5-1.1,6.7-1.5
				c-0.2-2.9-3.6-1.2-4.4-3.1c6.8-1.7,12.3,2.9,18.5,3.8c12.5,1.9,25.1,2.2,37.7,1.1c3.8-0.3,6.5,0.2,8.7,4
				c2.1,3.8,5.8,5.9,10.6,5.2c4.6-0.7,6.2-4.2,7.3-7.7c2.7-8.8,1.6-17.5-2.1-25.7c-2-4.5-8.1-5.7-11.8-1.9c-3.7,3.8-7.9,3.7-12,2.7
				c-7.8-1.8-15.8-3.2-23.5-4.3c-4-0.6-7.8-3-12.5-2.3c-4.7,0.7-9.9-1.8-14.8-3c-1.2-0.3-4,0.9-3.3-1.2c0.8-2.4-2.8-4-0.5-5.9
				c1.8-1.5,3.1,0.6,4.4,1.3c2.6,1.4,4.7-0.1,7-0.6c4.1-0.9,0.3-2.7,0.4-4c0,0-0.1,0-0.1,0v-0.1c-1.2,0.6-2.2,0-3.7-0.7
				c1.5-0.2,2.6-0.3,3.7-0.5v-0.2c0.1-0.1,0.2-0.2,0.3-0.2c1.6-4.2,6.2-6.1,8.4-10c0.8-1.4,1.6,0.3,2.6,0.4c1.8,0.1,3.3-0.8,3.7-2.1
				c0.3-1.1-0.4-1.7-1.3-2.1c-0.5,0-1.1-0.1-1.5-0.7l-0.3,0.1l0.2-0.2c0,0,0.1,0.1,0.1,0.1h0c0.4,0.2,1,0.4,1.5,0.7
				c0.9,0,1.8-0.4,2.6-0.2c1.4,0.3,3.4,0,3.6-2c0.1-0.6,0-0.9-0.2-1.2c-0.7-0.1-1.5-0.3-2.2-0.6c-0.3,0-0.5-0.1-0.6-0.3
				c0.2,0.1,0.4,0.2,0.6,0.3c0.7,0.1,1.7,0,2.2,0.6c3.6,0.5,7.6-1.1,11.5-0.3c3.2,0.6,5,1.5,6.3,4.6c1.2,2.8,3,5.4,4.8,7.9
				c1.1,1.6,2.7,3.8,4.7,2.7c2.4-1.2,0.4-3.4-0.1-5.2c-1-3.9-3.5-7.4-3.3-12.4c3.7,1.7,4.7,4.8,6.7,7c1.6,1.8,3,3.8,4.8,5.4
				c1.6,1.3,3.7,3.5,5.7,1.8c1.9-1.6-0.6-3.6-1.3-5.3c-1.4-3.3-3.4-6.4-5.2-9.8c3.9-1.1,5.5,1.4,7.4,3.5c2.7,2.9,5.2,6,8.1,8.7
				c1.7,1.6,4.3,3.9,6.3,2c1.9-1.8-0.9-3.9-1.9-5.8c-2-3.6-6.7-4.9-7.4-9.8c3.7,0.4,6.2,2.5,8.2,5.2c2.9,3.8,7,5.8,11.2,7.7
				c1.2,0.5,2.7,1,3.7-0.2c1-1.2,0.3-2.5-0.8-3.4c-7.6-5.8-11.1-16.4-22.1-17.6c0.3-3.1,2.9-1.8,3.9-3.2c1.2-1.7,2.1-3.5-0.9-3.5
				c-16.9,0.1-33.8-2.5-50.5,2.2c-3,0.9-5.6,2.6-7.9,4.7c-12.7,12.1-27.9,20.8-42.1,30.7c-2.6,1.8-5.3,3-9.5,3.4
				c2.8-2.9,9.1-4.2,3.4-8.9c0,0-0.1-0.1-0.1-0.1c-0.3,0.1-0.6,0.2-0.9,0.3c0.1-0.3,0.1-0.5,0.2-0.8c0.2,0.2,0.5,0.3,0.7,0.5
				c0,0,0,0,0,0c1.6,0.9,3.2,0.9,5.6,0.1c-2.4-1.3-4.2-2.2-6.5-3.4c5.8-0.9,10.8,1.9,16.6,0.6c-3.2-3.6-8.1-1.7-11.1-4.5
				c4-1.5,7.7,2,12,0.3c-1.2-3.4-5.6-1.7-7.2-4.2c2.3-1.8,4.4,0.8,7.2,0.4c-1.2-3.4-5.4-2.8-6.5-5.6c0.8-1.1,3.2,1.4,3.3-1.3
				c0.1-2.9,0-2.9,2.9-2.2c0.4-0.6,0.7-1.1,1.1-1.7c-0.6-0.7-1.2-1.4-1.7-2.1c-0.1,0-0.1,0.1-0.2,0.1c0-0.1,0-0.1,0-0.1
				c-0.6-0.1-1.1-0.3-1.7-0.4c0.1-0.2,0.3-0.6,0.4-0.6c0.6,0.1,1.7-0.2,1.3,1c0,0,0.1,0,0.1,0c0,0,0,0,0,0c3.4-2.4,6.2,3.6,9.7,1h0
				c2.3-3.8-2.8-4.7-3-7.5c0,0-0.1,0-0.1,0c0,0,0,0,0,0c-0.5,0.4-0.9,0.3-1.2-0.3c-0.1-0.1,0.1-0.4,0.1-0.6c0.4,0.3,0.7,0.6,1.1,0.9
				c0,0,0.1-0.1,0.1-0.1c0,0.1,0,0.1,0,0.2c0.6,0.2,1.3,0.4,1.9,0.6c0.4-0.4,0.8-0.9,1.2-1.3c-0.9-0.5-1.8-1-2.7-1.6v-0.1
				c0,0-0.1,0-0.1,0.1c0,0,0.1-0.1,0.1-0.1v0c1.9-1.1,3.5,0.2,5.2,0.6c0.5-0.6,1-1.2,1.5-1.7c-1.2-4.4,2.7,0,3.2-1.4
				c0-0.2,0-0.4,0-0.6c0.1,0,0.2,0,0.3,0c0.9-0.6,1.6-2.1,2.8-0.3c3.2-2.6,6.4-1.8,9.6,0.1c0.4-0.6,0.9-1.1,1.3-1.7
				c-0.2-0.6-0.5-1.1-0.7-1.7c0,0-0.1,0-0.1,0l-1.1-1.8c-0.4,0-1.2,0.4-0.7-0.6c0.2,0.2,0.5,0.3,0.7,0.5c0.4,0.6,0.8,1.2,1.1,1.8
				c0,0,0,0.1,0.1,0.1c0.6,0,1.4-0.1,1.7,0.2c3.9,4.3,9.7-1.4,13.7,2.6c0,0,1-0.7,1.1-1.1c0.2-1.7-1.6-1.6-2.4-2.6
				c2.1-1.6,3.7,0.3,5.4,0.9c0.9,0.3,2,0.6,2-0.4c0-0.7-1-1.5-1.6-2.2c-0.1,0-0.2,0-0.3,0c0-0.1,0-0.2,0-0.2
				c-0.2-0.2-0.5-0.4-0.8-0.7c0.3,0,0.5-0.1,0.8-0.1c0,0.3,0,0.5-0.1,0.8c0,0,0.1,0.1,0.1,0.1c0,0.1,0.1,0.1,0.1,0.2
				c4.2,0.4,7.3,3.8,11.3,4.6c0.4-0.5,0.8-1,1.2-1.6c-1.7-1.3-3.4-2.6-5.2-3.8c6.8,2,13,6.7,20.5,3.3c0-0.1,0-0.1,0-0.2c0,0,0,0,0,0
				c-1.1-3-5.4-2.6-6.7-6.7c4.1,2.1,7.2,4.9,11.1,6.1c0.5,0.2,1.8,0.9,0.6,1.8c-0.5,0.4-1.5,0.2-2.3,0.2c0,0,0,0.1,0,0.1
				c-0.1,0.1-0.2,0.1-0.3,0.2c-0.9,2.4,1.5,2.5,2.7,2.7c2.4,0.6,1.2-0.7,1-2c-0.2-1.6,1.2-0.9,2.2-0.7c1.6,0.5,2.8,2.8,4.9,1.2
				c0-0.1,0-0.2,0-0.2c0,0,0,0,0.1,0c-1.4-3.3-5.3-3.6-7.5-6.2c2.7-0.6,4.4,2,6.8,1.8c0.2-0.4,0.5-0.7,0.8-0.9
				c0.1-0.4,0.1-0.8,0.2-1.2c0,0-0.1,0-0.1,0c-1.1-0.3-2.2-0.5-3.3-0.8c0,0,0-0.1,0.1-0.1c-0.1-0.6-0.3-1.2-0.4-1.8
				c-0.1-0.1-0.1-0.1-0.2-0.2c-0.1-0.1-0.2-0.1-0.4-0.2c-0.4-0.1-0.8-0.1-1.1-0.2c0.1-0.1,0.3-0.3,0.4-0.2c0.3,0.1,0.5,0.3,0.8,0.4
				c0.2,0,0.3,0,0.5,0.1c0,0.1,0,0.2,0.1,0.3c0.6,0.5,0.9,1,0.4,1.8c0,0,0,0,0,0c1.1,0.2,2.3,0.5,3.4,0.8c0,0.1,0,0.1,0,0.2
				c2.2,0.7,4.7,1.1,4.4,4.4c0.8-0.1,2-0.4,2.4-0.1c0.6,0.5,0.1,0.7-0.6,0.4c-0.5-0.2-1.2-0.2-1.7-0.3c0,0,0,0-0.1,0
				c-0.5-0.4-1.1-1-1.4-0.9c-1,0.5-0.6,1.3-0.2,2.1c1.2,2,4.9,1.8,4.8,5c-0.8,0-1.7,0-2.5-0.1c0,0,0,0.1,0,0.1c0,0-0.1,0-0.1,0
				c4.2,7,12.1,12.2,19.3,12.7c0.4-0.4,0.7-0.8,1.1-1.3c-0.1-0.7-1.4-1.2-0.6-2.2c-2.1-0.2-3.4-1.3-3.4-3.7c1.9,1.4,3.7-3.7,5.3-0.3
				c1.4,2.9,4.7-4.2,5,1.1c5.1-0.3,4.2-3.2,2.7-6.3c1.6,0.3,3.1,0.7,4.5,1.3c0,0,0-0.1,0.1-0.1c0.1,0.1,0.2,0.2,0.3,0.3
				c5,2.2,8.4,6.1,10.3,11.7c0.9,2.8,2.7,1.1,3.9,1.7c2.6,1.2,2.4-0.8,3.6-1.9c2.2-2.1,0.1-2.8-0.6-4.1c-2.9-4.8-8.4-8.2-8.4-14.7
				c0-0.1-0.1-0.1-0.1-0.1c-0.1-0.1-0.2-0.2-0.3-0.2c-4.9-0.4-2.8-6.8-6.6-8.1c4.3,1,3.7,6,6.6,8.1c0.2,0,0.3,0,0.4,0
				c0,0.1,0,0.2,0,0.3c0.8,0.9,1.7,1.7,2.2,2.8c1,2.2,2.7,2.1,4.3,1.3c1.9-1,0-2.2-0.2-3.2c-1.6-6.7-5.2-12.6-9.3-18
				c-4-5.3-9.3-8.7-16.2-9c-7.1-0.4-14.1-0.8-21.2-0.2c-9.5,0.9-19,1-27.9-3.4c-2.8-1.4-5.8-2.4-5.8,4.8c-1.5-6.9-1.2-12.3-3.8-15.6
				c-2.8-3.7-8.6-0.7-13-1.6c-8.1-1.7-16.1-3.4-19.9-12.2c3-0.8,5,0.3,5.8,3.3c0.1,0.3,0.1,0.9,0.3,1c9.6,3.5,18.9,8.8,29.7,5.6
				c1.7-0.5,3.7-0.7,4.2-2.7c2-6.9,6.4-12.5,10.1-18.4c4.8-7.5,1.7-15.1,0-22.3c-0.8-3.4-5.5-1.6-8.7-1.8c0,0,0,0.1-0.1,0.1
				c-0.1,0-0.1,0.1-0.2,0.1c-0.2,1.3-0.4,2.7-0.6,3.9c0-1.3-1.7-3,0.6-3.9c0-0.1,0-0.2,0-0.2c0.1,0,0.1,0,0.2,0
				c1.4-2.7,3.9-1.4,5.6-1c5.8,1.5,6.1-2.3,5.5-5.9c-0.8-4.7-2-9.4-4.3-13.7c7.8-0.2,10.1,2.2,9.1,9.5c-0.3,2.1,0.2,4.2,1.3,5.7
				c4.1,5.5,3.1,10.7,0.9,16.7c-2.2,6-2.6,12.5,3.5,17.2c0.5-0.4,1-0.9,1.5-1.3c4.2-1.6,7.8-3.3,5.5-9.2c-1.1-2.9,1.1-5.9,2.4-8.6
				c1.3-2.8,3.5-6,2.2-8.7c-3.6-7.3-2.4-14,0.1-21.3c2.2-6.3-2.3-11.3-5.5-16.4c-0.1,0-0.1,0-0.2,0c0-0.1,0-0.1,0-0.2
				c-3.2-1.4-6.5-2.6-9.1-5.1c-0.1,0-0.1,0-0.2,0v-0.1c-6.8-1.6-10.4-6-11.3-12.8c-0.1-0.7-0.3-1.3-0.4-2
				c-2.1-16.1-12.8-20.6-27.4-15.9c-7.9,2.5-13.7,2.7-20.5-2.2c-6.1-4.4-13.9-4.8-21.4-5.8c-9.5-1.3-19-0.5-28.6-0.4
				c10.5,4,22.2,0.1,33.1,5.1c-5.1,1.1-8.8-2.4-13.2-2c-4.4,0.3-8.8,0.3-13.2,0.7c-0.5,0-3.9-0.8-1.8,1c3.3,2.8-9,1.4-0.8,4.1
				c0.7,0.2-0.4,1.3-2.6,1.9c2.7,3.4,5.8,6.8,10.2,7.5c1.5-0.1,3,0,4.4,0.2c3.6,0,7.3-0.2,11.1,1.7c-3.8,0.3-7.3-1.1-11.1-1.7
				c-1.2,0-2.5,0-3.7-0.1c-0.2,0-0.5-0.1-0.7-0.1c-1.8,0.2-3.7,0.6-5.8,1.7c5.8,0.9,9.9,1.6,14.3,2.3c-6.1,3-12.8,1.7-18.6,4.9h11.6
				c-4.9,1.9-10.5,1.2-13.2,7.7c-1.6,4-2.7,3.6,1.8,4.7c3.6,0.9,7.4,1,10.5,3.4c0.2,0,0.4,0.1,0.5,0.1c0,0,0,0.1,0.1,0.1
				c4.6-0.2,9.1,0.4,12.5,1.9c-3.6,1.7-8-0.4-12.6-0.7c0,0,0,0-0.1,0.1c-0.1,0-0.2,0-0.3,0.1c-1.7,1.5,3.4,1.6,0.9,3.2
				c-0.4,0.4-0.8,0.9-1.1,1.3c0.9,0.4,1.8,0.9,3.3,1.6c-6.2,1.4-11.7-3.5-17.8-0.1c5.4,1.3,10.3,2.5,16.1,3.9
				c-7.3,1.9-13.7-3.4-21.6,1.3c9,0.3,16.1,2.2,23.1,4.1c-7.9,3.2-15.6-4.4-25.5-0.2c6.9,1.4,12,2.5,18.9,3.9
				c-5.2,0.8-8.6,0.6-11.7-0.2c-1.5,3.3,2.6,1.9,2.4,4.3c-5.4-0.4-11-4.9-17.6,0.9c8.7,0.1,16.2,1.7,24.5,3.1
				c-8.3,1.6-15.5-1.6-22.9-1.1c7.7,3.3,15.6,5.1,24.5,4.4c-6.5,2.8-12.5-0.4-18.5,0.3c-0.4,0.6-0.9,1.2-1.3,1.8
				c3,0.9,6,1.8,9.2,2.7c-3-0.5-5.7,0.6-8.6-0.5c-4.9-1.9-7.2,0-6.4,5.2c0.2,1.3-0.3,1.4-1.3,1.2c-0.3-0.1-0.7-0.3-1-0.5
				c1.9,5.6,7.2,5.2,11.5,6.1c0.1,0,0.3,0,0.4,0.1c-1.8-0.1-3.6-0.1-5.4,1.1c2.3,1.3,4.6,2.1,6.3,2.5c0.8,0.2,1.6,0.3,2.1,0.4
				c-0.5-0.1-1.2-0.2-2.1-0.4c-2-0.3-4.7-0.8-7.4-1.3c-2.3-0.4-6.4,1.1-6.5,2.7c0,2.5,2.7,3.5,4.2,3.7c10.9,1.2,20.9,7.1,32.1,6.6
				c4.5-0.2,8.5-2.1,12.8-3.3c6.8-2,9.3-0.2,9.9,6.8c0.7,8.6,1.3,17.1,2.2,25.7c0.5,4.6-1.4,7.3-5.5,8.5c-7,2-13.7,4.9-21.1,5.6
				c-6.2,0.6-11.9,3.2-17.2,6.6c-10.9,7.1-14.5,20.5-9.6,35.3c2.8,8.3,5.4,16.7,8.6,26.3c-7.1-2-13.5-1.4-20-2.1
				c4.5-0.9,9.1-1.5,13.7-1.5c-10.7-4.3-21.4,2.6-32.2-0.1c10.6,0.1,20.5-4.5,32.3-3.1c-4.9-4.5-9.8-1.8-15.6-3.4
				c4.9-0.4,8.4-0.6,12.7-0.9c-4.5-4.4-9.5-1.2-15.3-3.2c3.9-0.3,6.4-1,8.6-0.6c3.9,0.7,1-4.3,3.9-4.1c-0.5-2.4-2.5-1.3-3.7-1.3
				c-5-0.2-10-0.1-15-0.2c5.1-0.3,10.3-0.5,15.4-0.8c-4.7-0.9-8.4-5.1-13.3-4.5c2.6-0.9,5.9,0,8.5-2.2c-0.9-2.3-3.1-0.8-4.8-1.9
				c3.2-0.6,4.7-3.8,8-4c-5.6-0.7-10.9-2.4-16.3-1.4c2.9-1.6,6.4-1.3,9.9-2c-4.9-7.8-12.9-1.7-18.5-4.6c5.6-1.1,12-0.2,18.7-3.7
				c-2.7-0.7-4.4-1.7-4.5-4.5h8.5c-12.2-3.8-24.3-1.5-36.4-0.6c12.9-2.5,26-2.2,40.1-2.3c-6.1-3.7-11.8-2.9-17.4-3.5
				c6-1.1,12-2.5,18.1-1c-0.8-3.4-4.4-1.3-5.9-3.4c5.3-1.3,10.9,1,16-1.9c-10.5-0.8-20.8-3.3-31.5-2.4c13.3-2.4,26.9,2.5,41.8-2.3
				c-16.2-0.8-30.7-1.6-45.3-2.3c16.5-4.4,33.1,2.1,49.6-1.6c-18.2-0.9-36.4-3.6-54.6-2.5c-2.7,0.6-5.7,0.6-8.7,0.9
				c-2.2,0.3-4.4,0.6-6.6,1.1c2.1-0.7,4.3-0.9,6.6-1.1c2.9-0.4,5.8-0.7,8.7-0.9c0.4-0.1,0.9-0.2,1.3-0.3c10.8-3.1,21.3-2.2,32-1
				c11.5,1.3,22.9,1.3,34.3-0.6c2.9-0.5,5.9-1.2,8.1-3.8c-7.9,2.4-15.9,2.9-24,2.8c-6,0-11.9,0-17.8-1.2
				c-14.7-3.1-29.4-1.6-44.1,0.4c-5,0.7-10.1,0.1-15.2,0.1c23-3.6,46.1-4.6,69.4-2.8c11.8,0.9,24.2,2.4,36.3-4
				c-2.9-1.3-4.2-3.7-3.5-6c1.2-4.3-0.9-5-4.1-4.3c-4.9,1-9.6,0.7-15,0.3c5.2-2.8,11.3-2.3,15.5-6.4c-21.9,6.1-41.2-0.9-60.3-11.4
				c3.9-0.7,6.7,2.6,10.1,1v-0.2c0,0,0,0,0,0c-0.1-0.2-0.1-0.4-0.2-0.6c-0.1-0.1-0.1-0.1-0.2-0.2c0-0.1,0-0.1,0-0.2
				c-0.2-0.2-0.3-0.5-0.5-0.8c1-0.2,0.6,0.4,0.5,0.8c0,0.1,0.1,0.1,0.1,0.1c0,0.1,0.1,0.2,0.1,0.3c0.2,0.2,0.5,0.3,0.7,0.3
				c0.4-0.2,0.8-0.3,1.1-0.3c0.4-0.2,0.9-0.4,1.3-0.1c0.9,0.5,1.8,0.9,2.7,1.3c-0.9,0.8-2.3-0.9-3,0.6c0,0.1,0,0.1,0,0.2
				c0,0,0,0,0,0c2.9,4.7,8,4.9,12.6,6.2c0.5-0.6,1.1-1.2,1.6-1.8c-0.1-0.2-0.2-0.4-0.2-0.6c0-0.1,0.2-0.2,0.3-0.2
				c8.1,2.7,16.4,2.8,25.9,1.5c-5.2-2.5-9.7-1-14-1.7c-16.2-2.6-29.6-11.1-43.3-19.1c-7-4.1-14-8.4-22.3-9.6
				c-0.6-0.1-1.8-0.3-1.8-0.5c0.1-9.3-8.7-4.4-12.5-8.3c3.2-0.1,6.2-0.3,10.4-0.5c-7.9-5.3-16.1-4.8-23.8-6.3H81
				c-4-3.7-8.2-4.4-12.3-4.7c-1.9,0.2-3.8,0.3-5.7-0.3c1.9,0.2,3.8,0.2,5.7,0.3c2.5-0.3,5.2-0.7,8.1,0c-2.7-3.9-6.2-3.1-9.6-4.3
				c2.2-0.1,3.8,0,5.3-0.3c1.3-0.2,3.3,1.8,3.9-1.3c0.5-2.8-0.4-4-3-4.5c-0.8-0.2-1.5-0.8-2.4-1.3c1.6-1.7,3.5-0.2,4.9-1.1
				c-2.1-2.5-2.6-7.4-8.8-5c2.9-3.4,5.4-0.6,8-2.2c-5.1-1.6-9.9-3-15.2-2.9c0.6,3.6,3.6,1.4,5.4,3.4c-10.9-1-21.3-1.9-31.6-2.9
				c0-0.2,0-0.4,0-0.6h11.8c0-0.3,0.1-0.6,0.1-0.9c-3.2-0.8-6.5-1.5-9.8-2.3c0.1-0.3,0.1-0.6,0.1-0.9c12,1.2,24,2.4,37.5,3.7
				c-15.1-5.9-30-4.9-43.9-9c11.7,1.4,23.5,2.7,36,4.2c-2.2-2.6-5.6-1.3-6.7-4.1c1.9-2,4.2,0.6,6.4-0.8c-8.2-3.9-16.8-4.3-26-5.3
				c5.1-1.9,9.4,0,13.8-0.1c0.4-0.5,0.8-1,1.2-1.5c-9.5-3.2-19.8-1.8-29.1-5.8c12.3,2.3,24.9,2.1,37.3,5c-12.5-5.4-26.2-5.8-39-10
				c12.9,2.9,26.2,2.2,38.9,6.1c0.6,0.2,1.5-0.2,2.3-0.4c-8.6-5.4-18.8-5.2-28.3-7.3c-7.5-1.7-14.9-3.2-21.9-6.5
				c17.3,4.7,35.2,6.2,52.9,11.8c-0.9-3.2-9.1-2.7-3.4-7.7c-1.6-1-3.2-2.5-5-2.9c-7.9-2-15.8-3.7-23.7-5.5c-4.1-1-8-2.2-11.4-5
				c14.6,3.3,29.9,4.1,43.8,10.6c0.7,0.3,1.7,2.1,2-0.2c0.1-0.5-0.5-1.1-0.8-1.7c-1.9-1.2-4.5-1.1-5.7-3.5c0,0-0.1,0-0.1,0.1
				c0,0,0-0.1-0.1-0.1c-1.4,0.1-3,0.7-3.8-1.2c0,0-0.1,0-0.1,0.1c-0.2-0.1-0.3-0.2-0.5-0.2c-14.1-1.7-27.7-5.2-40.5-11.6
				c13.4,4,27.6,5.5,40.5,11.6c0.2,0,0.4,0.1,0.6,0.1c0,0,0,0.1,0,0.1c1.6-0.5,3-0.6,3.8,1.2h0.2c0,0,0,0,0,0.1
				c1.7-1,3.4,0.6,5.1,0.2c0-0.1,0-0.1,0.1-0.2h0c-5.7-5.1-13.3-5.5-20-8c-5.5-2-11.4-3.1-16.5-6.7c9.5,2.5,18.9,4.9,28.4,7.4
				c1.7,0.4,3.4,2.2,5.2,0c0.1-0.1-0.1-0.4-0.2-0.5c-0.1-0.2-0.3-0.3-0.4-0.5c-1-0.2-2.6-0.7-2.1-1.8c1-2.1,2.4-0.3,3.7,0.1
				c3,1.2,6,2.4,9.1,3.6c-11-9.4-25.6-9.9-38.2-15.6c11,0.7,21.2,5.2,32.2,6.1c-5.1-4.2-11.9-4.2-17.4-7.6c8,1.9,15.9,5.2,24.9,0.3
				c-10.4-4.3-20.9-6-30.9-9.4c9.4,0.7,18.4,3.5,27.5,5.6c4.8,1.1,9.4,3.3,14.5,3.3c-2.1-2.9-6.4-1.7-9.1-5
				c7.5-0.2,13.6,4.6,21.7,3.9c-7.5-4.8-16.1-4.7-22.5-9.4c-0.2,0-0.3-0.1-0.5-0.1c0,0-0.1-0.1-0.1-0.1c-2.3,0.7-4-0.2-5.6-2
				c1-1.1,3,1.1,3.6-1.4c-4.9-4.2-12-3.6-17.3-7.3c4.6,0.1,8.7,2,13,3.3c2,0.6,4,2.6,6.3,0.9c0-0.1,0-0.1,0-0.1c0,0,0.1,0,0.1,0
				c-1.1-4.2-3.9-7.2-8.1-6.7c-9.5,1.1-17.2-4-27.1-5.6c3.8-1.8,6.3,1.3,8.7-0.8c0-0.1-0.1-0.1-0.1-0.2c0.1,0,0.1,0,0.2,0
				c0.1-1.2-1.3-1.3-1.9-2.2c2.6-0.3,4.6,2.2,7.2,1c0,0,0-0.1-0.1-0.1c0,0,0.1,0,0.1-0.1c-2.5-4.7-8.4-3.3-11.8-6.4
				c-0.1,0-0.2,0-0.3,0c0-0.1,0-0.1,0-0.1c-0.7-0.2-1.5-0.3-2.2-0.5c0.1-0.3,0.3-0.8,0.4-0.8c0.9,0.1,2.2-0.4,1.8,1.3
				c0,0,0.1,0,0.1,0c0.1,0.1,0.1,0.1,0.2,0.1c9,0.2,16.3,5.5,24.9,8.3c-3.2,1.6-5.2-1.7-7.9-0.6c0,0,0.1,0.1,0.1,0.1
				c0,0-0.1,0.1-0.1,0.1c-0.5,3,4.2,1.1,3.9,3.9c0.1,0,0.1,0,0.2-0.1c0,0.1,0,0.1,0,0.1c3.6-0.6,6.5,1.7,9.9,2.1
				c0.3-0.4,0.7-0.8,1-1.2c-0.9-0.8-1.9-1.5-2.8-2.3c-0.2-0.2-0.4-0.5-0.7-0.7c0.4,0,0.8,0,1.2,0c0.6-0.5,1.2-1,1.8-1.5
				c-14.7-7.4-29.9-13.6-47.3-18.6c7.4-0.9,12.3,2,17.7,3c-3.3-4.9-9.9-3.7-13.9-7.3c-0.1,0-0.2,0-0.2,0v-0.1
				c-1.2,0.3-2.3,0.6-3.5-0.4c0.9-1.5,2.4-0.4,3.5-0.8v-0.2c0,0,0,0,0.1,0c-2.8-3.2-6.8-2.4-10.7-4c0.9,4.1,4,1.6,5,3.5
				c-0.2,0.2-0.4,0.8-0.6,0.7c-10-0.5-12.6-3.1-12.6-13.1c0-159,0-431.6-0.1-590.6C13.4,408.5,15.4,408.3,20.1,409.8z M54,369.8
				c0.2-0.2,0.3-0.3,0.5-0.5c0.1,0.1,0.2,0.3,0.4,0.4c-0.1,0.1-0.3,0.2-0.4,0.3C54.3,369.9,54.2,369.8,54,369.8z M290.5,261.1
				c2.6-1,4.9-1.1,7.3,0C295.3,262.5,293.1,261.3,290.5,261.1z M303.9,240.8h5.3C308,242.3,306.8,241.6,303.9,240.8z M311.5,128.7
				c2.6-1.1,4.9-0.9,8.3,0.1C316.4,130.1,314.2,129.3,311.5,128.7z M315.4,166.2c0,0-0.1,0.1-0.1,0.1c0,0,0,0,0,0
				c0-0.1,0-0.1-0.1-0.2C315.2,166.1,315.3,166.1,315.4,166.2z M320.8,86.4c1.9-0.7,3.2-0.9,4.5-0.2
				C324.1,87.9,322.8,86.7,320.8,86.4z M1647.3,49.1c-1.6,1.1-3.2,0.7-5.7-0.3C1644.6,47.9,1646,48.4,1647.3,49.1z M1849,336.1
				c-2.6,0.6-4.6,0.8-7.3,0C1844.4,335.1,1846.4,335.9,1849,336.1z M1982.5,1030.2c-0.2,0-0.5,0.1-0.7,0.1c-2.6,0.7-5.3,1.4-9.4,2.6
				c3.9-2.2,6.7-2.3,9.4-2.6c0.2-0.1,0.5-0.1,0.7-0.2l-0.1-0.2l0.2,0.2c-0.1,0-0.1,0-0.1,0L1982.5,1030.2z M1953.3,1042.6
				c7.6-3,14.9-6.4,23-6.9C1968.9,1039.1,1961.1,1041,1953.3,1042.6z M1964.4,1043.9c-0.2,0.1-0.4,0.2-0.6,0.2
				c-0.2,0-0.3-0.3-0.4-0.4c0.2,0,0.4-0.1,0.6-0.1C1964.2,1043.7,1964.3,1043.8,1964.4,1043.9z M1944.9,1055L1944.9,1055
				c-0.2,0-0.3-0.2-0.5-0.3c0.6-0.3,1.3-0.6,1.9-0.8c0-0.1,0-0.1,0-0.2c0.1,0,0.2,0,0.3,0c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0-0.1,0.1
				C1946.5,1054.9,1945.7,1054.9,1944.9,1055z M1939.7,1052.2c1.6-1.5,3-2.3,5.6-2C1943.4,1051.7,1941.9,1052.4,1939.7,1052.2z
 M1950.3,1052.6c-0.8,1.7-2.3,1.3-3.7,1.1C1947.6,1052.5,1948.9,1052.5,1950.3,1052.6z M1946.9,1048.7c0.9-0.4,1.8-0.7,2.5,0.2h0
				c0.1,0,0.2-0.1,0.3-0.1c-0.1,0.1-0.1,0.1-0.2,0.2c0-0.1-0.1-0.1-0.1-0.1c-0.7,0.8-1.6,1.1-2.7,0.7
				C1946.7,1049.7,1946.7,1048.8,1946.9,1048.7z M1949.7,1048.9c2.8-3.4,7.4-2.2,11.1-3.3
				C1957.1,1046.7,1953.4,1047.8,1949.7,1048.9z M1952.2,1052.2c-0.5,0.1-1,0.1-1.5,0.2c-0.1,0.1-0.2,0.1-0.3,0.2
				c-0.1,0-0.1,0-0.2,0c0-0.1,0-0.1,0.1-0.1c0.1,0,0.3,0,0.4-0.1c0.3-0.3,0.7-0.6,1.1-0.8C1952,1051.8,1952.1,1052,1952.2,1052.2z
 M1969.2,1034.2L1969.2,1034.2c-0.2,0-0.5,0.1-0.7,0.1c-0.5,0.2-1.1,0.5-1.6,0.7c0-0.2-0.1-0.4-0.1-0.6c0.6-0.1,1.2-0.1,1.8-0.2
				c0.2-0.1,0.4-0.2,0.5-0.2c0.1,0,0.1,0,0.1,0c-0.2-0.9,0.4-1.2,1.3-1.2c0.3,0,0.6,0.4,0.8,0.6c-0.7,0.2-1.4,0.4-2.1,0.6
				C1969.2,1034,1969.2,1034.1,1969.2,1034.2z M1966.2,1031.2L1966.2,1031.2c-5,2-10,3.8-15.2,5.2c8.4-4.9,17.7-7.5,26.7-10.9
				C1974.7,1029,1970.2,1029.6,1966.2,1031.2z M1951.3,1039.6c0.2-0.1,0.5,0.1,0.8,0.2c-0.4,0.4-0.8,0.7-1.3,1.1
				c0.1,0.1,0.1,0.1,0.2,0.2c-0.1,0-0.1-0.1-0.2-0.1c0,0,0,0,0,0c0,0,0,0,0,0C1950.2,1040.3,1950.3,1039.8,1951.3,1039.6z
 M1962.7,1049.1c-2.6,1.5-4.6,1.8-7,1.5C1958.1,1050.4,1959.6,1047.5,1962.7,1049.1z M1980.5,1038.9L1980.5,1038.9
				c0,0,0.1,0,0.1,0.1c0.3,0,0.5,0,0.8,0c-0.1,0.1-0.3,0.2-0.4,0.2c-0.1,0-0.3-0.1-0.4-0.2L1980.5,1038.9c-2.5,1.8-5.1,2.9-8.3,2.3
				C1974.9,1040.4,1977.7,1039.7,1980.5,1038.9z M1969.4,1042.7c-0.3,0.1-0.7,0.2-1,0.2c-0.2-0.1-0.4-0.2-0.6-0.4
				c0.2,0,0.4-0.1,0.6-0.1C1968.7,1042.5,1969,1042.6,1969.4,1042.7z M1967.8,1047.2c3.3-1.3,6.5-2.8,10.1-2.8c0,0,0.1-0.1,0.1-0.1
				c0.1,0,0.3,0,0.4,0c1-0.4,2-0.9,3.1-0.3c-1.1,0.1-2.1,0.2-3.1,0.3c-0.1,0.1-0.2,0.1-0.3,0.1h-0.2
				C1975.1,1047.1,1971.2,1046.5,1967.8,1047.2c-1,0.4-1.9,0.8-2.9,1.1C1965.8,1047.8,1966.8,1047.4,1967.8,1047.2z M1981.5,1067.6
				L1981.5,1067.6l0.2,0.3l-0.4,0L1981.5,1067.6z M1981.4,1077.7L1981.4,1077.7c0.1,0,0.3,0.5,0.7,1.1c-1,0.1-1.8,0.2-2.7,0.2
				c-0.2,0-0.3-0.5-0.5-0.8C1979.8,1078,1980.6,1077.8,1981.4,1077.7z M1982.4,1082.3c-6.6,5.6-15,5-22.3,8
				c-0.2-0.6-0.3-1.2-0.5-1.8C1967.3,1086.5,1974.9,1084.4,1982.4,1082.3z M1977.6,1025.5c0,0,0.1-0.1,0.1-0.1c0,0,0.1,0,0.1,0.1
				c1.2-0.2,2.5-0.5,4-0.7c-1.2,2.3-2.6,1.5-4,0.7c0,0,0,0-0.1,0C1977.7,1025.4,1977.7,1025.4,1977.6,1025.5z M1948.9,1037.4
				c-0.4,0.2-0.8,0.4-1.2,0.4c-0.2,0-0.4-0.4-0.7-0.7c0.4-0.2,0.8-0.4,1.2-0.4C1948.5,1036.7,1948.7,1037.2,1948.9,1037.4z
 M1941.7,1039.8c-0.2,0.2-0.4,0.4-0.6,0.5c-0.1,0.1-0.4,0.1-0.5,0c-0.6-0.3-0.6-0.6,0-0.7
				C1940.9,1039.5,1941.2,1039.7,1941.7,1039.8z M1926.3,1058.5L1926.3,1058.5c-0.4,0.2-0.7,0.4-1.1,0.4c-0.3,0-0.6-0.3-1-0.5
				c0.3-0.2,0.7-0.4,1-0.4C1925.6,1058.1,1926,1058.4,1926.3,1058.5z M1969.6,1076.7L1969.6,1076.7c-0.2,0-0.3,0-0.5,0
				c-6.2,2.3-12.5,4.6-18.8,6.9c-0.2-0.6-0.4-1.2-0.6-1.8c6.2-2.5,12.2-5.6,19.4-5c0.1,0,0.2-0.1,0.3-0.1c0,0,0,0,0,0
				c-0.8-0.8-0.2-1.1,0.6-1.3c0.2,0,0.5,0.2,0.8,0.3c-0.5,0.3-0.9,0.7-1.4,1C1969.5,1076.6,1969.5,1076.7,1969.6,1076.7z
 M1958,1090.5L1958,1090.5c-0.1,0.2-0.7,0.3-1,0.5c-0.3-0.2-0.6-0.4-0.9-0.6c0.3-0.2,0.7-0.5,1-0.6
				C1957.6,1089.5,1958.2,1089.7,1958,1090.5z M1959.4,1117.4c-1,1.6-2,2.4-3.3,1.6c-0.1-0.1-0.1-0.9,0-0.9
				C1957.2,1117.8,1958.3,1117.6,1959.4,1117.4z M1959.1,1114.6c-1.1-1,0.3-0.9,0.6-1c0.2-0.1,0.7,0.2,1.1,0.4
				c-0.2,0.3-0.3,0.8-0.5,0.8C1959.9,1114.9,1959.4,1114.8,1959.1,1114.6z M1967.2,1130.3H1957c0-0.2-0.1-0.4-0.1-0.7
				c3.3-0.2,6.7-0.5,10-0.8C1967,1129.3,1967.1,1129.8,1967.2,1130.3z M1880.3,1202.7c-0.2,0.1-0.3,0.3-0.5,0.3
				c-0.4,0-0.7-0.2-1.1-0.2l0.7-0.6C1879.7,1202.4,1880,1202.6,1880.3,1202.7z M1840.9,1268.5L1840.9,1268.5c0,0-0.1,0-0.1,0
				C1840.8,1268.6,1840.8,1268.6,1840.9,1268.5c-0.1,0-0.2,0-0.2,0c-0.1,0-0.2,0-0.3,0c-0.1,0-0.1-0.1-0.2-0.1
				c0.2,0,0.3,0.1,0.5,0.1c0,0,0.1,0,0.1,0c-0.1-0.1,0-0.2,0.1-0.3c-0.1-0.3-0.1-0.6-0.2-0.9c0.8,0.2,0.4,0.5,0.2,0.9
				C1840.9,1268.3,1840.9,1268.4,1840.9,1268.5z M1826,1162.7c-1.4-2.3-3.6,0.3-5.1-0.9c2.8-2.3,6.6,0.9,9.5-1.2
				c-2.2,4.7-6.2,5.4-12.7,5.4C1821.8,1164.9,1824.4,1165.1,1826,1162.7z M1834.2,1159.7c-0.5-0.6-0.4-0.7,0.2-0.4
				c0.1,0.1-0.1,0.6-0.1,0.9h0c0,0,0,0,0,0.1c-0.1,0-0.1,0-0.2-0.1c-1.2,0.1-2.3,1.4-3.5,0.3h0c0,0-0.1,0-0.1,0.1c0,0,0-0.1,0.1-0.1
				c0,0,0,0,0.1,0.1c1.1-0.8,2.3-0.7,3.5-0.3c0.1,0,0.1,0,0.2,0C1834.2,1160,1834.3,1159.8,1834.2,1159.7z M1839.7,1177.4v0.3h-7.4
				v-0.3H1839.7z M1837.2,1157.2c0-0.2,0.1-0.4,0.1-0.5c0.3-0.2,0.7-0.3,1.1-0.5c0,0,0.1,0,0.1,0c0.2-0.6,0.3-1.4,0.9-0.7
				c1,1.2-0.3,0.6-0.9,0.7c-0.2,0.5-0.4,1-1.1,0.6c-0.1,0.2-0.1,0.4-0.1,0.5C1837.3,1157.3,1837.2,1157.2,1837.2,1157.2
				L1837.2,1157.2z M1828,1178.4c2,3.5,2.2,6.8-0.7,10.3C1827.4,1185,1828.7,1181.8,1828,1178.4z M1843.6,1192
				c0.4-0.1,0.8-0.2,1.1-0.3c0.1,0,0.3,0.2,0.4,0.4c-0.2,0.1-0.4,0.3-0.5,0.3C1844.4,1192.4,1844,1192.2,1843.6,1192z
 M1853.2,1177.6c0-0.2,0-0.3,0-0.5c1.5,0.1,2.9,0.3,5.7,0.5H1853.2z M1857.4,1192.2c-0.3,0.1-0.6,0.2-1,0.2
				c-0.1,0-0.3-0.2-0.4-0.4c0.3-0.1,0.7-0.2,1-0.2C1857.1,1191.8,1857.3,1192,1857.4,1192.2z M1856,1195.6c-0.1,0.3-0.2,0.7-0.3,1
				c-2.8-0.9-5.5-1.9-8.3-2.8c0.1-0.3,0.2-0.6,0.3-1C1850.4,1193.7,1853.2,1194.7,1856,1195.6z M1817.2,1163.9L1817.2,1163.9
				c0.1,0.1,0.3,0.3,0.4,0.4c-0.1,0.1-0.3,0.2-0.4,0.4c-0.1-0.1-0.3-0.1-0.4-0.2C1816.9,1164.3,1817.1,1164.1,1817.2,1163.9z
 M1798.7,1144.2c6.8,1.3,12.6-2.3,18.9-3.7C1811.8,1144,1806,1148,1798.7,1144.2z M1774.8,1146c0.9,2.5-1,3.2-2.9,4l0,0
				C1771.4,1147.5,1774.5,1147.8,1774.8,1146z M1774.8,1145.9C1774.8,1145.9,1774.8,1145.9,1774.8,1145.9
				C1774.8,1146,1774.8,1146,1774.8,1145.9C1774.8,1146,1774.8,1145.9,1774.8,1145.9C1774.8,1145.9,1774.8,1145.9,1774.8,1145.9
				c0.5-0.3,1-0.6,1.5-0.9C1776.4,1146.1,1775.9,1146.5,1774.8,1145.9z M1777.4,1143.3c0.5,1,0.7,1.9-0.9,1.6c0,0,0,0.1-0.1,0.1
				c0,0-0.1,0.1-0.1,0.1c0-0.1,0-0.2,0-0.2c0.1,0,0.1,0,0.2,0C1776.8,1144.3,1777.1,1143.8,1777.4,1143.3z M1777.4,1143.2
				c0.6-0.2,1.2-0.3,1.8-0.5c0-0.1,0.1-0.2,0.2-0.2c0.2-0.4,0.5-0.9,0.7-1.3c-0.2,0.5-0.4,1-0.6,1.5c-0.1,0-0.2,0.1-0.3,0.1
				C1778.7,1143.3,1778.2,1143.7,1777.4,1143.2z M1785.8,1202.1c-3.7-0.7-7-1.3-10.2-1.8C1779.2,1198.7,1782.8,1197.9,1785.8,1202.1
				z M1769.6,1151.2l-0.5,0l0.3-0.4L1769.6,1151.2z M1751.9,1170.5L1751.9,1170.5c-0.6,0.4-1.2,0.7-1.9,1.1l-0.4,0.9
				c0.1-0.3,0.2-0.7,0.2-1C1750.5,1171.2,1751.2,1170.9,1751.9,1170.5c-0.1-0.2-0.1-0.4,0-0.6c-0.1-3,1.9-6,0-9.1
				c4.1,3.2,0.4,6.1,0,9.1C1751.8,1170.1,1751.8,1170.3,1751.9,1170.5z M1750.7,1157.5L1750.7,1157.5c-0.8-1.5-1.6-3-2.4-4.6
				c-2,0.6-1,2.4-1.7,3.4c-1.3-3.5,0-6.4,3.1-9.8C1749.8,1150.8,1749.2,1154.3,1750.7,1157.5z M1761.2,1260.6c-0.1,0-0.3,0-0.4,0.1
				c0.1-0.1,0.2-0.2,0.2-0.2c0-0.1,0.1-0.1,0.1-0.2c-0.1-0.3-0.1-0.5-0.2-0.8c0.3,0,0.5,0,0.8,0c-0.2,0.2-0.4,0.5-0.6,0.8
				C1761.1,1260.4,1761.1,1260.5,1761.2,1260.6z M1738.5,1272.8L1738.5,1272.8c-0.1,0-0.1-0.1-0.2-0.1c-0.4,0-0.9,0-1.3-0.1
				c0.1-0.2,0.1-0.4,0.2-0.6c0.4,0.2,0.7,0.4,1.1,0.6c0.1,0,0.1,0,0.2,0c-0.1-0.4-0.3-0.9-0.4-1.4c0.2-0.1,0.4-0.1,0.5-0.2
				c0,0.5-0.1,1-0.1,1.5C1738.5,1272.8,1738.5,1272.8,1738.5,1272.8z M1737.2,1263.2c-0.5,0.4-0.9,0.9-1.4,1.3
				c-0.3,0.8-0.7,1.4-1.7,1c0.2,1.1-0.4,1.4-1.3,1.3c0,0,0,0.1,0,0.1c0,0-0.1,0-0.1,0c0.1,1-0.4,1.3-1.3,1.2c0,0.1,0,0.1,0,0.1
				c-0.1,0-0.1,0-0.2,0c-0.3,0.5-0.6,1-1,1.5c0,0.1,0.1,0.1,0.1,0.2c-0.1,0-0.2,0-0.3,0c-0.4,0.3-0.8,0.6-1.3,0.9c0,0.1,0,0.1,0,0.2
				c-0.1,0-0.2,0-0.2,0c-0.3,0.4-0.7,0.8-1,1.2c0.1,0.1,0.1,0.1,0.1,0.2c-0.1,0-0.1,0.1-0.2,0.1c-0.8,3.3,3,3.8,3.8,3.7
				c0.4-0.1,0.6-0.1,0.8-0.2c0.1-0.7,0.1-1.5,0.1-2.2c-0.3-0.7-0.6-1.4-0.2-2c0.1,0.7,0.2,1.4,0.2,2c0.4,0.9,0.8,1.8-0.1,2.2
				c-0.3,2.9-1.5,5.1-3.2,4.9c-2-0.2-5.8,2.5-6.6-2c-0.1,0-0.1,0-0.2,0.1c0-0.1,0-0.1,0-0.2h0.2c0,0.1,0,0.1,0,0.1
				c2.3-0.5,5.1-0.8,2.1-4.2c-0.6-0.8-0.3-1.1,3.1-2.1c0-0.1,0-0.1,0.1-0.2c0,0,0,0,0.1-0.1c-0.3-0.3-0.9-0.6-0.2-1.1
				c0.3-0.2,0.8-0.1,1.2-0.1c0,0,0.1-0.1,0.1-0.1c0,0,0.1-0.1,0.1-0.1c0-0.3-0.1-0.7-0.1-1c0.4,0,0.9,0.1,1.3,0.1
				c0.1,0,0.1-0.1,0.2-0.1c0,0,0,0,0,0c-0.7-1.1-0.2-1.5,1-1.5c0-0.1,0-0.1,0.1-0.1h0.1c-0.5-1.3,0.2-1.4,1.3-1.2c0,0,0-0.1,0-0.1
				h0.1c-0.4-1.2,0.3-1.3,1.3-1.3c0,0,0,0,0,0h0c-0.3-1,0.2-1.4,1.1-1.5c0-0.9,0.5-1.3,1.4-1.3c0.1-0.9,0.6-1.2,1.4-1.2
				c0,0-0.1-0.1-0.1-0.1c0.1,0,0.1,0.1,0.2,0.1c0.9-1.9,2-3.4,4.4-2.1c-1.7,0.1-2.2,3.1-4.4,2.1c0,0,0,0,0,0h-0.1
				C1739,1262.6,1737.8,1262.8,1737.2,1263.2z M1718.3,1280C1718.3,1280,1718.3,1280.1,1718.3,1280c-0.1,0.1-0.1,0.1-0.1,0.1
				c0.1,0.5,0.2,1,0.1,1.3c-0.4,0.8-0.8,0.1-1.3-0.1c0,0,0,0.1,0,0.1c0,0-0.1-0.1-0.1-0.1c0,0,0,0,0.1,0c0-0.8,0.5-1.1,1.2-1.2
				c0-0.1,0-0.1,0-0.1C1718.1,1280,1718.2,1280,1718.3,1280c0-0.7,0.4-1.2,1.1-1.2c0,0,0-0.1,0-0.1h0.2c0.4-1.1,1.1-1.9,2.3-2.1
				c0.1,0.7,0.1,1.4,0.1,2.1c-0.8,0-1.7,0-2.5,0c0,0,0,0.1,0,0.1c-0.1,0-0.1,0-0.2,0c-0.1,0.5-0.1,1-0.2,1.6
				C1718.9,1280.2,1718.6,1280.1,1718.3,1280z M1718.6,1293.7c0.8,1.2-0.1,1.7-1.2,2c-0.7,0.2-1.5,1.1-2.1,0.1c-0.7-1,0.3-1.4,1-1.7
				C1717,1293.5,1717.8,1292.4,1718.6,1293.7L1718.6,1293.7z M1714.3,1276.1C1714.2,1276.1,1714.2,1276.1,1714.3,1276.1
				c-0.1,0.6-0.4,1.1-1.1,0.9c-0.1,0-0.3-0.4-0.3-0.6C1713.1,1275.8,1713.6,1275.7,1714.3,1276.1
				C1714.2,1276.1,1714.2,1276.1,1714.3,1276.1L1714.3,1276.1c-0.4-1.4,0-1.8,1.2-1.3v-0.1l0.1-0.1c0.1-0.4,0.1-1,0.3-1.2
				c0.2-0.3,0.4-0.3,0.5-0.2c-0.5-1.1,1.4-2,1.1-3.1c0,0,0,0,0,0c0,0,0,0-0.1-0.1c0,0,0.1,0,0.1,0c0.5-0.3,0.9-0.5,1.4-0.8
				c0-0.1-0.1-0.2-0.1-0.3c0.1,0,0.2,0,0.3,0.1c0.4-0.8,1.3-1.6,0.3-2.5c-1-1-1.8,0.1-2.6,0.5c0,0.1,0,0.1,0,0.2
				c0-0.1,0-0.1-0.1-0.2c0,0,0,0,0,0c-0.4,0.3-0.9,0.7-1.3,1c0-0.4-0.1-0.9,0.1-1.1c0.4-0.5,1-0.7,1.2,0.1c0,0,0,0,0,0
				c-0.3-2.8,1-4.6,3.8-5.3c0-0.1,0-0.1,0-0.2c0.1,0,0.1-0.1,0.2-0.1c0-0.4-0.1-0.9,0.1-1.2c0.2-0.4,0.4-0.4,0.6-0.2
				c-0.4-0.6,0.4-1.2,0.8-1.8c0.1-0.1,0.5,0.1,0.8,0.2c-0.4,0.7-0.8,1.3-1.1,2c0,0,0,0-0.1,0.1c0.1,0,0.1,0.1,0.1,0.1
				c-0.1,0-0.1,0-0.2-0.1c-0.4,0.3-0.8,0.7-1.2,1v0.2c-0.1,0-0.1,0-0.2,0.1c-0.3,1.3-2.2,2.3-0.7,3.8c2,1.9,2.8-2.1,4.6-1.3
				c0,0,0,0,0-0.1c0,0,0.1,0,0.1-0.1c-0.1-0.4-0.6-1,0.3-1.1c0.4-0.1,0.9,0.2,1.3,0.4c-0.5,0.2-1.1,0.5-1.6,0.7c0,0.1,0,0.1,0,0.2v0
				c-0.1,0-0.1-0.1-0.2-0.1c-1.7,1.7-1.7,5.6-5.5,4.8c0,0.1-0.1,0.1-0.1,0.2c0,0-0.1,0-0.1,0.1c0.2,0.4,0.5,0.8-0.2,1.4
				c-0.5,0.4-0.7,0.2-0.9,0c0.6,1.2,0,2.2-1,3.1c0,0,0,0-0.1,0h0c-0.5,0.3-0.9,0.5-1.4,0.8c0,0.1,0,0.2,0,0.2c-0.1,0-0.1,0-0.1,0
				C1715.5,1275.6,1715.2,1276.1,1714.3,1276.1C1714.2,1276.1,1714.2,1276.1,1714.3,1276.1z M1726.6,1263.1c0,0,0.1,0.4,0.1,0.4
				c0,0-0.1,0-0.1,0c0,0,0,0,0,0L1726.6,1263.1z M1728,1259.6l0.3,0l-0.2,0.4L1728,1259.6z M1742.3,1258.6c0.1,0.1,0.2,0.3,0.3,0.4
				c0,0.1,0,0.2,0,0.4c0,0,0,0.1,0,0.1h0v0.1c0,0-0.1,0-0.1-0.1c0,0,0.1,0,0.1,0v-0.1C1742.5,1259.1,1742.4,1258.8,1742.3,1258.6z
 M1703.6,1283.8c-0.7-4.3,3.6-4.6,3.5-7.3c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0,0,0,0,0.1c0.9-0.4,1.8-1.1,2.7-1
				c0.8,0.1,0.2,1.1-0.1,1.4c-0.8,0.8-1.6,1.4-2.1,0.7C1708.6,1280.5,1705.8,1281.5,1703.6,1283.8z M1702.6,1283.3
				c0.2,1.3-0.5,1.8-1.7,2h0c-1.3-0.8-0.3-1.6,0.3-2.2C1701.5,1282.9,1702.6,1283.2,1702.6,1283.3z M1701.7,1280.1
				c-0.3-0.3-0.6-0.5-0.9-0.8c1.8-2.1,3.6-4.2,5.4-6.3c0.2,0.2,0.4,0.3,0.6,0.5C1705.2,1275.7,1703.5,1277.9,1701.7,1280.1z
 M1703.7,1264.2L1703.7,1264.2c0-0.1,0.1-0.2,0.1-0.2c0.1-1.5,0.4-2.9,2.7-4.2c-1.2,1.9-2,3.1-2.7,4.2c0,0.1,0,0.2,0,0.3
				C1703.8,1264.3,1703.8,1264.2,1703.7,1264.2z M1708.6,1269.1L1708.6,1269.1c0.1,0.1,0.2,0.1,0.3,0.2c-0.1,0-0.1,0-0.2,0l0.1,0.2
				l-0.3-0.2c0.1,0,0.1,0,0.2,0L1708.6,1269.1z M1758.8,1286.2L1758.8,1286.2c-0.1,0.1-0.2,0.1-0.4,0.2c0-0.2,0-0.5,0-0.7
				c0.1,0.1,0.2,0.1,0.4,0.2C1758.8,1285.9,1758.9,1286.1,1758.8,1286.2z M1769.1,1357.2c-2.6,1.6-4.9,1.3-7-2.5
				c3.1-0.4,5.6-1.3,7.9,0.4C1770.2,1355.1,1769.7,1356.8,1769.1,1357.2z M1692.2,1340.3v-0.6c0.2,0,0.5,0.1,0.7,0.1
				C1692.7,1340,1692.5,1340.2,1692.2,1340.3z M1693,1339.9c0,0,0.1-0.1,0.2-0.1c0-0.1,0.1-0.1,0.1-0.2c0,0.1,0,0.2,0,0.3
				C1693.1,1339.9,1693,1339.9,1693,1339.9z M1693.2,1339.6c0-0.4,0.1-0.8,0.1-1.2c0.2,0,0.4,0.1,0.6,0.1
				C1693.7,1338.8,1693.5,1339.2,1693.2,1339.6z M1695.8,1337c0.2-3,1.1-5.2,5.1-5.4C1698.9,1333.8,1697.4,1335.4,1695.8,1337z
 M1622.4,1332.3L1622.4,1332.3c-0.2,0.1-0.3,0.4-0.5,0.4c-0.2,0-0.3-0.3-0.4-0.4c0.2-0.1,0.3-0.3,0.5-0.4
				C1622.1,1332,1622.3,1332.1,1622.4,1332.3z M1606.5,1334.1L1606.5,1334.1c1.8,3.5,0.6,7.4,2.5,10.7c-2.5,0.1-1.2-3.3-3.3-3.5
				c0,0,0,0.1,0,0.1c-0.1,0-0.1-0.1-0.2-0.1c-3.1,1-6.2,2-5-4.8c1.1,3.2,3.2,3.8,5,4.8c0,0,0.1,0,0.1,0c0,0,0,0,0.1,0
				c-1.3-9-4-10.2-11.2-5.6c-2.6,1.6-5.5,3-8.5,3.9c-6.5,1.8-9.9,7-14.2,11.5c-1.5,1.6-5.4,5.5-6.1-1.3c-0.2-2.1-1.4-1.4-2.7-1
				c-1,0.3-2.5,0.4-2.6-1.1c-0.1-1.6,0.7-3,2.5-3.2c13.3-0.9,22.7-9.2,32.6-16.7c3.5-2.6,19.1-0.2,24.3,3.6c-3.5,1-6.5-1.3-9.7-1.4
				C1607.1,1329.9,1605.3,1331.8,1606.5,1334.1z M1552.5,1340.7L1552.5,1340.7c-2.3-0.3-4.4-0.8-4.2-3.9c0.4-6.1,1.5-12.1,1.6-18.3
				c0-1.8,3.5-4.4,5.9-3.4c7,2.9,13.9,3.7,21.1,1.1c2.3-1.8,4.1-5.3,8.1-2.7c-1.2,2.9-4,3.5-6.5,4.4c-2,2.1-4.5,3.4-7.1,4.7
				c-6.9,3.4-9,3.1-14.5-2.3c-2.3,5.5-1.3,11.3-2,16.8C1554.6,1338.7,1555.5,1341.1,1552.5,1340.7z M459.4,1353.2L459.4,1353.2
				c-0.8-0.5-2.2-0.4-3.2-2.5c-0.3,2.9-0.5,4.9-0.7,7.2c-0.7-1.2-1.3-2-1.8-2.9c-0.2,0.8-0.5,1.6-1.2,3.9c-0.4-3.5-0.7-5.7-0.9-7.9
				c-1.9,0.4-0.5,2.4-1.8,3.4c-6.8-10.1-15.9-15.5-28.5-13.8c0,0.1,0,0.1,0,0.2c0,0.1,0,0.2-0.1,0.3c0.6,2.7-0.4,5.1-2.2,8.4
				c0.9-3.5,1.6-5.9,2.2-8.4c0-0.1,0-0.3-0.1-0.4c0.1,0,0.1,0,0.2,0c0.6-1.9,0.9-3.7-1-6.6c-1.4,5.4-4.6,9.1-6.6,13.4
				c0,0.1,0,0.2,0,0.3c-0.1,0-0.2,0.1-0.4,0.1c0,0-0.1,0.1-0.1,0.1c-0.1,0-0.2,0-0.3,0c0.1,0,0.3-0.1,0.4-0.1
				c0.1-0.1,0.2-0.2,0.2-0.2c0-0.1,0.1-0.1,0.1-0.2c-0.6-3.2,0-6.4,1-9.5c1.5-4.7-0.9-5.4-4.6-4.8c-3.6,0.6-7.2,1.5-12,2.6
				c8.5-6.8,17.4-7.4,25.9-5.4c5.6,1.3,10.2,6.4,15.7,9.1c6.2,3,11.4,8.2,19.2,7.4c2.6-0.3,2.4,2.7,2.4,4.6
				C461.2,1351.8,462.9,1355.2,459.4,1353.2z M323.7,1337.6L323.7,1337.6c-1.1-0.9-2.2-1.8-3.5-2.8c2.4-0.8,3.9-0.3,4.5,2
				c1.7,0.8,2.8,2,2.5,4c0,0,0.1,0,0.1,0v0.1c1.3-0.6,1.6,0.6,2.1,1.4c0.1,0.1-0.4,0.9-0.4,0.8c-1-0.4-1.8-1-1.7-2.2
				c0,0-0.1,0-0.1,0.1c0-0.1,0-0.1,0-0.2C325.3,1340.5,324.5,1339,323.7,1337.6z M307.9,1345.6c-0.1,0-0.3,0-0.4,0
				c-0.1,0-0.3-0.1-0.4-0.1c0.1,0,0.3-0.1,0.4,0C307.6,1345.5,307.8,1345.6,307.9,1345.6z M251.7,1358.4c0-2.6,2.6-0.2,3.2-1.6
				c1.7,0.8,4.3-0.7,4.1,2c-0.1,1.6-2,2.1-3.6,2.1C253.6,1361,251.7,1360.4,251.7,1358.4z M165.6,1288.6c0.1-0.1,0.3-0.3,0.4-0.3
				c0.1,0,0.3,0.2,0.4,0.4c-0.2,0.1-0.3,0.3-0.5,0.3S165.7,1288.7,165.6,1288.6z M176.5,1273.9c0.2-0.1,0.4-0.3,0.5-0.3
				c0.4,0.1,0.8,0.2,1.2,0.4c-0.4,0.2-0.7,0.5-1,0.5C177,1274.5,176.7,1274.1,176.5,1273.9z M181.7,1273.8c0-0.4,0.1-0.8,0.1-1.2
				c0.2,0.2,0.4,0.3,0.7,0.4C182.3,1273.3,182,1273.5,181.7,1273.8z M216.4,1147.9c0-0.1-0.1-0.2-0.2-0.3
				C216.3,1147.7,216.4,1147.8,216.4,1147.9C216.4,1147.9,216.4,1147.9,216.4,1147.9c0.2,0.3,0.3,0.7,0.6,0.7
				c2.7,0.5,4.9-1.4,8.9-1.8c-9.7,6.4-17.3,2.4-24.8-1.3c5.2-1.1,9.4,3,14.4,3.1C215.7,1148.6,216,1148.2,216.4,1147.9z M229,1144
				C229,1144,229,1143.9,229,1144c0.1-0.1,0.1-0.1,0.2-0.2c0-0.4,0-0.9,0.2-1.1c0.6-0.7,0.8,0.1,1.1,0.3c0,0,0,0,0.1,0
				c0-0.2,0-0.4-0.1-0.6c0,0,0,0,0-0.1c0-0.3-0.1-0.5-0.1-0.8c1.2,0.1,0.2,0.5,0.1,0.8c0,0.3,0.1,0.5,0.1,0.8c0,0-0.1-0.1-0.1-0.1
				c-0.4,0.3-0.9,0.5-1.4,0.8c0,0.1,0,0.2,0,0.3C229.1,1144,229.1,1144,229,1144c-0.2,0.7-0.6,1.2-1.4,1.1c0,0,0,0.1,0,0.1
				c-0.1,0-0.2,0.1-0.3,0.1c-0.2,0.3-0.5,0.6-0.7,0.9c-0.1-0.2-0.2-0.3-0.3-0.5c0.4-0.1,0.7-0.2,1.1-0.3c0.1-0.1,0.1-0.2,0.2-0.2
				c0,0,0.1,0,0.1,0C227.6,1144,227.8,1143.3,229,1144z M234.1,1178.7c-4.1-1.3-7.2-1.2-10.2-0.9c-0.7,0.2-1.4,0.3-2.1,0.3
				c0.7-0.1,1.4-0.2,2.1-0.3C226.8,1177,229.9,1174.8,234.1,1178.7z M244.9,1204.2c-3.6,0.6-7.2,1.2-11.1,1.8
				C237,1201.8,240.9,1203,244.9,1204.2z M202.4,1169.5L202.4,1169.5c0.1,0,0.3-0.1,0.4-0.1c-0.1-0.3-0.2-0.6-0.2-0.9
				c0.1,0.2,0.2,0.3,0.3,0.5c0,0.2,0,0.4,0,0.6C202.7,1169.5,202.6,1169.5,202.4,1169.5c-4.7,1.5-9,1.2-13.5-3.8
				C193.9,1168.4,198,1169.6,202.4,1169.5z M190.7,1183.8c1.2,3.8,0,8.4,4.2,11.1c0.1,0,0.1,0,0.2,0.1c0,0.1-0.1,0.1-0.1,0.2
				c0,0.5,0,0.9,0.1,1.4c-0.2-0.1-0.4-0.1-0.6-0.2c0.2-0.4,0.3-0.8,0.5-1.2v-0.2c0,0-0.1-0.1-0.1-0.1
				C187.4,1193.4,191.8,1187.6,190.7,1183.8z M240.5,1258.2l0.6-0.2v0.4L240.5,1258.2z M243.8,1259.5L243.8,1259.5
				c0.2-0.1,0.3-0.3,0.5-0.3c0.1,0,0.3,0.2,0.4,0.4c-0.1,0.1-0.3,0.3-0.5,0.3C244,1259.9,243.9,1259.7,243.8,1259.5z M277.7,1275.8
				c-0.2,0.1-0.5,0.2-0.6,0.1c-0.6-0.3-0.7-0.7-0.3-1.2l0,0c0,0,0,0,0,0c-1.4,0.8-2-0.2-2.6-1.2c0,0-0.1,0-0.1,0.1v-0.2h0.1
				c0,0,0,0.1,0,0.1c1.4-0.8,2,0.2,2.6,1.2c0.1,0,0.1,0,0.2-0.1c0,0-0.1,0.1-0.1,0.1C277.1,1275.1,277.4,1275.5,277.7,1275.8z
 M279.4,1262.8L279.4,1262.8C279.4,1262.8,279.4,1262.8,279.4,1262.8C279.4,1262.8,279.4,1262.9,279.4,1262.8
				c0.9-0.6,1.5,0.2,2.1,0.7c0.5,0.6,1.5,1.1,0.3,1.9c-0.1,0.1-0.7-0.7-1.1-1.1C279.8,1264.1,279.3,1263.8,279.4,1262.8
				c0,0.1,0,0.1-0.1,0.2C279.4,1262.9,279.4,1262.8,279.4,1262.8c-0.9-0.2-1.3-0.6-1-1.5c0,0,0,0-0.1-0.1c-0.1-0.1-0.2-0.1-0.3-0.2
				c-0.3,0-0.5,0-0.8,0c0.1-0.1,0.1-0.2,0.2-0.3c0.2,0.1,0.4,0.3,0.6,0.4c0.2,0,0.3,0,0.5,0c0,0.1-0.1,0.2-0.1,0.3
				C278.8,1261.7,279.7,1261.9,279.4,1262.8z M274.4,1258.8c-0.4-0.1-0.8-0.3-1.1-0.5c0.2-0.2,0.4-0.4,0.5-0.4
				c0.4,0.1,0.8,0.3,1.1,0.5C274.7,1258.5,274.5,1258.8,274.4,1258.8z M288.6,1270.8L288.6,1270.8
				C288.6,1270.8,288.7,1270.8,288.6,1270.8c0.1,0,0.1,0.1,0.1,0.1C288.7,1270.9,288.7,1270.8,288.6,1270.8
				C288.6,1270.8,288.6,1270.8,288.6,1270.8c-1,0.1-1.6-0.1-1.3-1.3c-0.9,0.7-1.4-0.1-2-0.6c-1.1-0.7-2.8-2-2.2-2.6
				c1.6-1.4,2.3,0.5,3,1.7c0.9,0.1,1.4,0.5,1.2,1.5C288.3,1269.4,288.8,1269.8,288.6,1270.8z M288.2,1260c0.4-0.3,0.7-0.7,0.8-0.6
				c0.5,0.3,1,0.7,1.4,1.2c0.1,0.2,0,0.6-0.1,0.9C289.6,1261,289,1260.5,288.2,1260z M290.1,1271.5c-0.2,0.2-0.5,0.5-0.7,0.7
				c-0.2-0.4-0.4-0.9-0.6-1.3C289.2,1271.1,289.6,1271.3,290.1,1271.5z M288.6,1275.4c-0.2,0.1-0.3,0.3-0.5,0.3
				c-0.1,0-0.3-0.3-0.4-0.4c0.2-0.1,0.3-0.3,0.5-0.3C288.4,1275,288.5,1275.3,288.6,1275.4z M283.1,1276.1c0,0.1,0.1,0.1,0.1,0.2
				c0,0,0.1,0.1,0.1,0.1c-0.1,0-0.2,0.1-0.3,0.1C283.1,1276.4,283.1,1276.2,283.1,1276.1z M284.2,1277c-0.3-0.2-0.6-0.4-0.8-0.6
				c0.2-0.1,0.5-0.2,0.7-0.3C284.1,1276.4,284.1,1276.7,284.2,1277z M283.1,1275.1L283.1,1275.1c0,0.3,0,0.6,0,0.9
				c-0.2-0.3-0.4-0.6-0.5-1C282.7,1275.1,283.1,1275.1,283.1,1275.1z M293.6,1265.7C293.6,1265.7,293.6,1265.8,293.6,1265.7
				c0.1,0.1,0.2,0.2,0.3,0.3c-0.1,0-0.2-0.1-0.3-0.1C293.6,1265.8,293.6,1265.8,293.6,1265.7z M293.6,1265.7
				c-0.4-0.4-0.8-0.7-1.2-1.1c0.4-0.3,0.7-0.9,1.1-0.2C293.7,1264.8,293.6,1265.3,293.6,1265.7z M292.2,1264.3c0,0.1,0,0.2,0.1,0.3
				c0,0,0,0,0.1,0.1c-0.1,0-0.1,0.1-0.2,0.1C292.1,1264.6,292.1,1264.5,292.2,1264.3c-0.1-0.3-0.2-0.6-0.3-0.9
				C292.8,1263.6,292.4,1264,292.2,1264.3z M293.8,1266c3.8,1,6,3.4,6.6,7.3C297.3,1271.7,296,1268.4,293.8,1266z M300.4,1273.3
				c0.1,0,0.1,0.1,0.2,0.1c0,0,0,0.1,0.1,0.1c-0.1,0-0.1,0-0.2-0.1C300.4,1273.4,300.4,1273.4,300.4,1273.3z M245.3,1187.1
				c0.2-0.4,0.3-0.7,0.5-1.1c0,0.3-0.1,0.7-0.1,1.1c0,0.2-0.1,0.3-0.2,0.5C245.5,1187.4,245.4,1187.3,245.3,1187.1L245.3,1187.1z
 M154.5,1187.3c-2.8,1.6-5.4,1.1-7.9,0.9C148.8,1188.2,151.2,1187.6,154.5,1187.3z M140.5,1180.6c-1.4,0.3-2-0.7-2.1-1.8
				c0-0.3,0.9-1.1,1.2-1c0.9,0.3,2.3,0,2.6,1.1C142.3,1180.5,141.3,1180.5,140.5,1180.6z M124.3,1199.9c0.1-0.1,0.3-0.3,0.4-0.3
				c0.1,0,0.2,0.2,0.4,0.4c-0.1,0.1-0.1,0.2-0.2,0.3C124.7,1200.1,124.5,1200,124.3,1199.9z M137.9,1205.8c0.5,0.2,1,0.4,1.6,0.6
				c-0.1,0.1-0.1,0.1-0.2,0.1c-0.2,0-0.4,0.1-0.6,0C138.4,1206.3,138.2,1206,137.9,1205.8z M40.7,1123.5c3.8,0.3,7.6,0.6,11.3,0.9
				c2,0.4,4.2,0.2,6.2,2.1c-2.9,1.3-5.4,0.6-7.8-0.3C47,1126.4,43.8,1125.7,40.7,1123.5z M39.9,1079.6L39.9,1079.6
				c1.9-0.2,3.7-1.8,4.1,1c0,0,0.1,0,0.2,0c0,0,0,0,0,0c1.2-1.2,2.6-0.3,4.4,0c-1.8,1.5-3.1,0.9-4.4,0c-0.1,0.1-0.1,0.1-0.2,0.2
				c0-0.1,0-0.2,0-0.2C42.8,1080.3,41.6,1080,39.9,1079.6z M50.2,1082.3c4.3-0.4,8,1.4,11.8,2.8
				C57.4,1086.5,54.1,1083.4,50.2,1082.3z M45.4,1038.4c0.2-0.1,0.4,0,0.6,0.1c0.1,0,0.1,0.2,0.2,0.3c-0.2,0.1-0.4,0.2-0.6,0.1
				C44.9,1038.8,44.8,1038.6,45.4,1038.4z"
            />
          </g>
          <path
            class="st1"
            d="M463.1,1401.7c0,0-0.1,0-0.1,0c0,0,0,0,0,0L463.1,1401.7L463.1,1401.7z"
          />
          <path
            class="st1"
            d="M304.6,1401.9L304.6,1401.9L304.6,1401.9C304.7,1401.9,304.6,1401.9,304.6,1401.9c-0.1,0.1-0.1,0.1-0.2,0.1
			C304.5,1402,304.7,1401.9,304.6,1401.9L304.6,1401.9z"
          />
          <path
            class="st1"
            d="M1739.2,1403.7L1739.2,1403.7c0,0-0.1,0-0.1,0C1739.2,1403.7,1739.2,1403.7,1739.2,1403.7z"
          />
          <path
            class="st1"
            d="M1747.7,1135.2C1747.7,1135.2,1747.8,1135.1,1747.7,1135.2c0.1-0.1,0.1-0.1,0.1-0.1L1747.7,1135.2z"
          />
          <path
            class="st1"
            d="M1755.8,1201.1C1755.8,1201.1,1755.8,1201.1,1755.8,1201.1C1755.7,1201.1,1755.7,1201.1,1755.8,1201.1
			L1755.8,1201.1L1755.8,1201.1z"
          />
          <path
            class="st1"
            d="M290.9,1280.5c0,0.1-0.1,0.1-0.1,0.2c0.1,0,0.2,0,0.3,0L290.9,1280.5L290.9,1280.5z"
          />
          <path
            class="st1"
            d="M1755.7,1129.8C1755.7,1129.8,1755.6,1129.9,1755.7,1129.8L1755.7,1129.8z"
          />
          <path
            class="st1"
            d="M1722.6,1258.2C1722.6,1258.2,1722.6,1258.2,1722.6,1258.2c0-0.1-0.1-0.1-0.2-0.2
			C1722.5,1258.2,1722.5,1258.2,1722.6,1258.2L1722.6,1258.2z"
          />
          <path
            class="st1"
            d="M1707.5,1274.3c0.1,0.5,0.3,0.9,0.5,1.1C1707.9,1275.1,1707.8,1274.7,1707.5,1274.3
			C1707.6,1274.3,1707.6,1274.3,1707.5,1274.3L1707.5,1274.3z"
          />
          <path
            class="st1"
            d="M1911.3,1062.4L1911.3,1062.4c0,0.1,0.1,0,0.1-0.1C1911.3,1062.4,1911.3,1062.4,1911.3,1062.4
			C1911.2,1062.4,1911.3,1062.4,1911.3,1062.4L1911.3,1062.4z"
          />
          <path
            class="st1"
            d="M64.3,1112.1L64.3,1112.1L64.3,1112.1c0-0.1-0.1,0-0.2,0C64.2,1112.1,64.3,1112.1,64.3,1112.1
			C64.3,1112.2,64.3,1112.1,64.3,1112.1z"
          />
          <path
            class="st1"
            d="M87.8,1070.2c-0.1,0-0.2,0-0.2,0c0,0.1,0.1,0.1,0.1,0.2L87.8,1070.2z"
          />
          <path
            class="st1"
            d="M133.2,1179.6c0.1,0,0.1,0.1,0.2,0.1c0-0.1-0.1-0.2-0.1-0.3L133.2,1179.6L133.2,1179.6z"
          />
          <path
            class="st1"
            d="M1716.9,1271c0.1,0.2,0.2,0.4,0.5,0.6C1717.2,1271.4,1717.1,1271.1,1716.9,1271z"
          />
          <path
            class="st1"
            d="M297.6,1277.9c0-0.1,0-0.1,0-0.2c-0.1,0-0.1,0-0.2,0L297.6,1277.9L297.6,1277.9z"
          />
          <g id="sceau_Bune">
            <path
              class="st1"
              d="M188.9,267.5c-54.4,0.9-99.2-44.9-99.6-98.7c-0.4-55.1,45-101.3,101.1-100.6c53.1,0.7,98.2,45,98.2,99.8
				S243.1,268.5,188.9,267.5L188.9,267.5L188.9,267.5z M285.5,168c0.9-52.6-43.5-96.5-96-96.8c-53.6-0.2-97.3,44.6-97.2,97.2
				c0.2,51.6,43.6,96.9,98.1,96.2C241.8,264,286.3,220.8,285.5,168L285.5,168z"
            />
            <path
              class="st1"
              d="M257.5,167.7c2.1,3.1,5.1,2.5,7.8,2.6c1.8,0,2.3,0.8,1.3,2.2c-1.8,2.5-2.1,5.2-1.6,8.2
				c0.1,0.4-0.2,1.4-0.3,1.4c-0.6,0-1.4,0-1.7-0.4c-0.8-0.9-1.5-2-1.9-3.1c-0.6-1.7-1.2-1.9-2.5-0.6c-2.1,2.1-2.9,4.7-3,7.6
				c-0.1,4.7-1.4,5.5-5.9,3.9c-1.8-0.7-2.8-1.8-3-3.7c-0.2-2-0.5-3.9-0.6-5.9c-0.1-1.3-0.4-1.9-1.9-1.3c-2.2,0.9-4.6,0.2-5.9-1.4
				c-1.2-1.6-1.3-5.1-0.1-6.8c1.1-1.5,3.6-2.3,5.7-1.8c2,0.6,2.2,0.5,2.1-1.6c-0.1-1.7-0.3-3.5-0.9-5.1c-1.1-3-5.1-4.2-7.6-2.4
				c-0.9,0.6-1.7,2-1.8,3.1c-0.3,5.4-0.3,10.7-0.5,16.1c-0.1,2.7-0.1,5.4-0.5,8c-0.7,4.1-3.2,6.8-7.3,7.8c-4.1,0.9-8.1,0.9-11.9-1
				c-2.7-1.4-4.2-3.8-4.7-6.8c-0.3-2.1-0.4-4.3-0.5-6.4c-0.1-1.5-0.6-2.1-2.1-2.1c-1.6,0-1.8,0.9-1.9,2.1c-0.1,2.2-0.1,4.4-0.5,6.6
				c-0.8,4.5-3.7,7-8.1,7.8c-3.3,0.6-6.6,0.6-9.8-0.7c-3.9-1.5-5.6-4.7-6.1-8.7c-0.2-1.7-0.1-3.5-0.1-5.2c0-1.3-0.5-2-1.9-1.9
				c-3.1,0-6.2,0-9.3,0c-1.3,0-2,0.5-1.9,1.9c0,1.6,0,3.1,0,4.7c0,1.1,0.6,1.5,1.6,1.7c2.8,0.3,3.8-0.3,4.5-3.1c0.3-1,0.5-2,0.9-3
				c0.1-0.3,0.6-0.5,1-0.8c0.2,0.3,0.7,0.6,0.7,1c0.2,4.1,0.4,8.2,0.5,12.2c0,0.5-0.5,1.1-0.8,1.5c-0.5-0.3-1.2-0.6-1.4-1.1
				c-1.1-2.1-2.5-3.5-5.1-3.4c-1.4,0.1-1.9,0.6-1.6,2c0.5,2.8,1.6,5.1,4.1,6.6c0.6,0.4,0.9,1.4,1.4,2.2c-0.7,0.3-1.5,0.9-2.2,0.9
				c-3.5,0-7-0.1-10.5-0.3c-0.6,0-1.1-0.5-1.6-0.7c0.3-0.5,0.6-1.1,1.1-1.4c2.9-1.7,4-4.4,4.4-7.6c0.2-1.6-0.7-2.5-2.1-2
				c-1.4,0.6-2.6,1.6-3.8,2.5c-0.8,0.6-1.2,1.6-2,2.1c-0.9,0.7-1.9,0.5-2-0.8c-0.2-3.6-0.4-7.2-0.5-10.8c0-0.7,0.5-1.4,0.8-2.2
				c0.2-0.1,0.4-0.1,0.6-0.1c0.8,1.1,1.6,2.1,2.3,3.3c1.5,2.8,2.4,3.4,5.5,2.7c0.6-0.1,1.3-0.9,1.4-1.4c0.2-1.7,0.2-3.5,0-5.2
				c-0.1-0.6-0.9-1.4-1.5-1.4c-4.1-0.1-8.3-0.1-12.4-0.1c-1.1,0-1.5,0.7-1.6,1.7c-0.1,1.3-0.1,2.7-0.3,4c-0.7,5.1-3.3,8-8.3,9.1
				c-3.5,0.7-7.1,0.6-10.5-0.8c-2.7-1.2-4.4-3.2-5.2-6c-0.4-1.4-0.7-3-0.7-4.5c-0.2-3.6,0.2-3.4-3.8-3.6c-2.2-0.1-4.6-0.5-6.6-1.4
				c-3.4-1.7-5.1-7.9-3.8-11.6c1.7-4.7,4.3-6.4,9.8-6.5c4.7-0.1,4.3,0.3,4.3-4.4c0-1.8,0-3.6,0-5.4c0-1.3-0.5-2-1.8-2.4
				c-3-0.9-4.2-2.2-4.4-5.1c-0.2-2.5,0.3-4.8,2.7-6.2c2.3-1.3,5.8-1,7.6,0.5c1.5,1.3,1.8,3.1,1.9,4.9c0.1,2.9,0.1,2.9,3,2.9h66.2
				c1.7,0,2.6-0.8,2.7-2.5c0.4-5.7,5.2-8.5,9.9-5.7c2,1.1,3,3.8,2.6,6.6c-0.4,2.6-1.7,4.1-4.1,4.6c-0.9,0.2-2,0.4-2.9,0.4
				c-1.5,0-2,0.7-2,2.2c0,7.9,0,15.9,0,23.8c0,1.6,0.6,2.2,2.2,2.2c3.4-0.1,6.9-0.1,10.3,0c1.7,0,2.3-0.7,2.2-2.3
				c-0.1-1.4,0-2.8,0-4.2c0-1.5-0.1-2.9-1.7-3.7c-0.4-0.2-0.7-1.1-0.6-1.6c0-0.3,0.9-0.7,1.4-0.7c1.8-0.1,3.6-0.1,5.4,0
				c0.5,0,1.2,0.3,1.3,0.6c0.1,0.5-0.1,1.3-0.4,1.5c-1.9,1.1-1.9,2.9-1.9,4.7c0,1.2,0,2.5,0,3.7c-0.1,1.4,0.6,2,2,2
				c6.4,0,5.3,0.4,5.4-5.1c0-3.4-0.1-6.9,1.9-10c1.6-2.6,3.8-4,7-3.5c3.5,0.6,6.8,1.4,8.5,4.9c1.2,2.5,0.6,5.1,0.1,7.7
				c-1.1,5.4-1.3,10.9,0.3,16.2c0.2,0.8,1,1.4,1.5,2.1c0.6-0.6,1.6-1.1,1.9-1.8c1-2.3,1.7-4.7,2.7-7c0.6-1.4,1.4-2.7,2.4-3.8
				c0.9-0.9,0.9-1.6,0.2-2.6C257.9,171,256.8,169.9,257.5,167.7L257.5,167.7L257.5,167.7z M192.4,168.1c-0.3-0.6-0.6-1.1-0.9-1.7
				c-0.6-1.2-1.1-2.5-1.9-3.5c-0.9-1.2-2.3-1.3-3.5-0.7c-0.6,0.3-0.9,1.5-0.9,2.3c-0.1,2.7,0,5.4-0.1,8c0,1.6,0.6,2.2,2.1,2.2h14
				c1.4,0,2-0.6,2-2c0-2.2,0-4.4,0-6.6v-17.3c0-1.2-0.2-2.1-1.7-2.1H187c-1.2,0-1.8,0.6-1.8,1.9c0,2.7,0,5.4,0,8
				c0,1.5,0.8,1.9,2.1,1.9c1.9,0,3-1.1,3.8-3.7c0.4-1.1,0.8-2.2,1.2-3.5C193.7,152.8,193.7,166.8,192.4,168.1L192.4,168.1
				L192.4,168.1z M137.8,161.9c-2.4,0-4.8,0-7.1,0c-1.3,0-1.9,0.6-1.9,1.9c0,3,0,5.9,0,8.9c0,1.4,0.6,2,2,2c4.7,0,9.4,0,14.1,0
				c1.4,0,2.1-0.6,2.1-2.1c-0.1-2.9-0.1-5.7,0-8.6c0.1-1.6-0.7-2.2-2.2-2.1C142.4,162,140.1,161.9,137.8,161.9L137.8,161.9
				L137.8,161.9z M194.3,178.1h-3.9c-5.8,0-6.1,0.5-5.1,6.3c0.5,2.8,1.5,5.2,4.2,6.3c3.2,1.3,6.5,1.4,9.6-0.2c1.2-0.6,2.2-1.8,3-3
				c1.5-2.3,0.8-5.1,0.9-7.7c0.1-1.4-0.6-1.9-2-1.9C198.9,178.1,196.6,178,194.3,178.1L194.3,178.1L194.3,178.1z M137.8,158.5h7.1
				c1.4,0,2-0.6,2-2c-0.1-2.6-0.1-5.2,0-7.9c0-1.3-0.6-2-1.9-2h-14.6c-1.2,0-1.7,0.6-1.7,1.7c0,2.7,0,5.5,0,8.2
				c0,1.3,0.6,1.9,1.9,1.9C133,158.5,135.4,158.5,137.8,158.5L137.8,158.5z M158.3,162h-6.1c-1.2,0-1.9,0.5-1.9,1.8v9.3
				c0,1.1,0.6,1.7,1.8,1.8c4,0,8,0,12.1,0c1.2,0,1.8-0.6,1.8-1.9c0-3,0-5.9,0-8.9c0-1.4-0.6-2-2-2C162,161.9,160.2,161.9,158.3,162
				L158.3,162L158.3,162z M137.9,178.1c-2.4,0-4.9-0.1-7.3,0.1c-0.6,0-1.5,0.6-1.7,1.1c-1,3.4,0.8,8,3.8,9.5c3.4,1.7,6.9,1.7,10.2,0
				c3.4-1.8,4-5.1,4-8.5c0-1.5-0.6-2.1-2.1-2.1C142.6,178.1,140.2,178.1,137.9,178.1L137.9,178.1z M223.1,185.1
				c-0.9,0-1.8,0.1-2.6,0c-0.6-0.1-1.3-0.5-1.4-0.9c-0.1-0.5,0.2-1.4,0.6-1.6c1.6-0.8,2-2.1,1.6-3.7c-0.5-1.5-2-0.9-3.1-0.9
				c-4.6,0-4.3-0.6-4.2,4.4c0.1,6.6,3.7,9.8,10.3,9.1c0.4,0,0.8-0.1,1.2-0.1c3.3-0.4,5.6-2.2,6.3-5.4c0.5-2.2,0.3-4.5,0.3-6.8
				c0-0.4-0.9-1-1.4-1.1c-1.2-0.1-2.5-0.1-3.7,0c-0.7,0.1-1.9,0.5-1.9,0.9c-0.1,0.9,0.3,2,0.5,3c0.1,0.2,0.6,0.3,0.7,0.5
				c0.3,0.6,0.7,1.3,0.8,1.9c0,0.2-0.9,0.6-1.5,0.7C224.7,185.2,223.9,185.1,223.1,185.1L223.1,185.1L223.1,185.1z M158.1,158.5
				c1.9,0,3.9,0,5.8,0c1.4,0,2.1-0.6,2.1-2.1c-0.1-2.6-0.1-5.1,0-7.7c0-1.5-0.6-2.1-2.1-2.1h-11.5c-1.4,0-2,0.6-2,2
				c0,2.6,0,5.2,0,7.9c0,1.4,0.6,2,2,2C154.2,158.5,156.2,158.5,158.1,158.5L158.1,158.5L158.1,158.5z M169,168.4L169,168.4
				c0,1.4,0,2.9,0,4.4c0,1.3,0.6,2,1.9,2h9.4c1.2,0,1.8-0.6,1.8-1.9c0-3,0-6,0-9.1c0-1.4-0.6-1.9-1.9-1.9h-9.2
				c-1.3,0-1.9,0.5-1.9,1.9C169.1,165.3,169,166.9,169,168.4L169,168.4L169,168.4z M175.6,158.5h4.7c1.2,0,1.8-0.5,1.8-1.8
				c0-2.7,0-5.5,0-8.2c0-1.3-0.6-1.8-1.8-1.8c-3.1,0-6.3,0-9.4,0c-1.2,0-1.9,0.6-1.8,1.8c0,2.7,0,5.5,0,8.2c0,1.3,0.6,1.8,1.8,1.8
				H175.6L175.6,158.5z M125.3,168.3L125.3,168.3c0-1.6,0.1-3.1-0.1-4.7c-0.1-0.6-0.7-1.5-1.2-1.6c-1.9-0.1-4-0.1-5.9,0.2
				c-1.8,0.3-3,1.7-3.4,3.4c-1.1,5.4,0.4,9.1,6.8,9.1c0.5,0,1.1,0,1.6,0c1.4,0.1,2.2-0.6,2.1-2.1
				C125.3,171.2,125.3,169.8,125.3,168.3L125.3,168.3L125.3,168.3z M125.3,137.7c-1.7,0-2.7,1-2.8,2.7c0,1.8,1.1,2.9,2.7,2.9
				c1.6,0,2.7-1.1,2.8-2.7C128.1,138.8,127,137.7,125.3,137.7z M212.3,140.5c0-1.7-0.9-2.7-2.6-2.8s-2.8,0.9-2.9,2.6
				c-0.1,1.8,1.1,3,2.8,3C211.3,143.2,212.3,142.2,212.3,140.5L212.3,140.5L212.3,140.5z M242.5,175.7c0.3,0,0.6,0,0.8,0
				c0.5-0.7,1.2-1.3,1.3-2c0.1-1.1-0.6-1.9-1.9-1.8c-1.1,0.1-1.9,0.7-1.7,1.8C241.2,174.4,242,175,242.5,175.7L242.5,175.7z"
            />
          </g>
        </g>
        <g id="Nombres">
          <path
            d="M457,1041.2c2.1-0.3,4.6-1.1,7.5-2.2c2.9-1.2,5.9-2.3,9.1-3.6c3.2-1.2,6.3-2.4,9.4-3.4c3.1-1,5.8-1.6,8.2-1.7
			c1.2,0,2.2,0.1,3.1,0.3c0.9,0.2,1.7,0.4,2.5,0.7c0.4,0.2,0.7,1,0.7,2.2c0,1.3,0.1,2,0.1,2.2c0.2,6,0.1,11.7-0.3,17.1
			c-0.4,5.4-1.1,10.8-2,16.2c-0.2,3.9-0.4,7.9-0.5,12c-0.1,4.1-0.4,8-0.8,11.8c-0.1,0.6-0.2,1.5-0.2,2.9s-0.1,2.8-0.1,4.3
			s0,2.9,0.1,4.2s0.1,2.2,0.2,2.8c-0.1,1.4,0,2.6,0.4,3.5s1,1.8,1.8,2.5c0.8,0.7,1.8,1.4,2.9,2s2.3,1.3,3.6,2.1
			c1.3,0.9,2.8,1.7,4.6,2.5c1.8,0.8,3.1,1.7,4.1,2.5c1.5,1.5,2.1,2.9,1.6,4.2s-1.8,2-3.9,2.2c-1.2,0-2.4-0.4-3.7-1.2
			s-2.5-1.3-3.7-1.6c-1.2-0.1-2.2-0.2-3-0.2c-0.8-0.1-1.5-0.1-2.3-0.1s-1.6,0-2.4-0.1c-0.8-0.1-1.8-0.2-2.9-0.4
			c-2.4-0.4-5.2-0.5-8.4-0.2c-3.2,0.3-6.3,0.6-9.4,1.1s-6,0.7-8.8,0.8c-2.7,0.1-4.8-0.3-6.2-1.3c-1.5-0.8-2.5-1.6-2.9-2.4
			s-0.4-1.6,0-2.4c0.4-0.8,1.1-1.5,2-2.2c1-0.7,2.1-1.3,3.4-1.9c2.8-1.3,6.7-2.6,11.5-3.8c0.9-1.4,1.6-3.4,2-5.9s0.9-5.3,1.1-8.3
			c0.3-3,0.4-6.2,0.5-9.4s0.1-6.4,0.1-9.5s0-5.9-0.1-8.4c-0.1-2.6-0.1-4.7-0.1-6.3c0.1-1.5,0.2-2.8,0.2-3.8v-5.2
			c0-0.8,0.1-1.8,0.3-3c0-0.1,0-0.5-0.1-1.1c-0.1-0.6-0.1-1.3-0.2-2s-0.2-1.4-0.2-2s-0.1-0.9-0.2-0.9c-1.2-0.2-2.3-0.1-3.4,0.2
			c-1,0.4-2,0.9-3,1.5s-2,1.2-3.1,1.6c-1.1,0.5-2.2,0.7-3.3,0.7c-1.6,0-3.1-0.1-4.3-0.4s-2.1-1.2-2.6-2.7c-0.4-1.2-0.6-2.5-0.5-3.8
			C455.6,1042.5,456.1,1041.6,457,1041.2L457,1041.2z"
          />
          <path
            d="M573,1054c-0.7,0.4-1.3,0.6-2,0.5s-1.3-0.3-1.9-0.7c-0.6-0.4-1.2-0.9-1.6-1.5c-0.5-0.6-1-1.1-1.4-1.6
			c-1-0.8-1.4-1.7-1.2-2.8c0.2-1.1,0.7-2.2,1.5-3.4s1.8-2.2,2.9-3.3c1.1-1,2-1.8,2.8-2.4c1.3-0.9,2.8-1.9,4.5-3.2
			c1.7-1.2,3.5-2.4,5.3-3.5c1.9-1.1,3.8-2,5.7-2.6c2-0.7,3.8-0.9,5.4-0.7c0.9,0,2.1,0.1,3.7,0.2c1.6,0.1,3,0.1,4.2-0.2
			c1.1-0.4,2.5-0.5,4.3-0.1c1.8,0.4,3.7,1,5.6,1.8c1.9,0.8,3.7,1.8,5.2,3c1.6,1.2,2.7,2.3,3.4,3.4c2.3,3.3,3.9,6.1,4.8,8.4
			c0.9,2.3,1.3,4.4,1.2,6.4s-0.6,3.9-1.5,5.9c-0.9,2-1.9,4.3-3,7.1c-0.5,1.2-1,2.5-1.3,3.9c-0.3,1.4-0.8,2.7-1.5,3.9
			c-0.4,1-1.2,2-2.1,3.1c-1,1.1-2.1,2.2-3.3,3.2c-1.2,1-2.4,2.1-3.5,3s-2.1,2-2.9,2.9c-1.2,1.3-2.3,2.8-3.4,4.3s-2.1,3.1-3,4.7
			c-0.7,1.6-1.6,3.2-2.7,4.6c-1.2,1.4-2.3,2.8-3.4,4c-1.1,1.3-2,2.4-2.8,3.5c-0.8,1.1-1.2,2.1-1.2,2.9c0,0.6,0.5,0.8,1.4,0.8
			s1.5-0.1,1.7-0.3c1.6-0.4,3.1-0.7,4.4-0.7s2.6,0.1,3.8,0.2c1.3,0.1,2.6,0.2,3.9,0.2s2.9,0,4.7-0.2c1.9-0.3,3.8-1.1,5.7-2.2
			c2-1.2,3.9-2.3,5.9-3.5c2-1.2,3.8-2.3,5.6-3.3s3.3-1.5,4.8-1.5c1.2,0,1.9,0.7,2,2c0.2,1.3,0,3-0.5,5s-1.2,4.2-2.2,6.6
			c-1,2.3-2,4.6-3,6.6c-1,2.1-1.9,3.8-2.7,5.3s-1.3,2.3-1.6,2.4c-1.5,0.9-3.2,1.3-5.1,1.2c-1.9-0.1-3.8-0.3-5.8-0.7
			c-2-0.4-4-0.8-5.9-1.4c-1.9-0.6-3.6-0.9-5-1c-0.4-0.1-1.4-0.2-2.9-0.2h-11.8c-2.3,0-4.6-0.1-6.8-0.3c-1.2-0.2-2.3-0.3-3.4-0.2
			s-2,0.2-3,0.3c-0.9,0.2-1.8,0.3-2.6,0.3c-0.8,0.1-1.5-0.1-2-0.4c-0.7-0.4-1.3-1.1-2-1.9c-0.7-0.8-1.2-1.7-1.6-2.5
			s-0.7-1.8-0.9-2.6c-0.2-0.9-0.1-1.6,0.1-2.1c0.4-1.1,1.1-2,2-2.7c0.9-0.7,1.9-1.3,3-1.9s2.2-1.1,3.2-1.6c1-0.5,1.9-1.2,2.5-2.1
			c1.2-1.5,2.6-3.2,4.1-5.1c1.5-1.9,3.2-3.5,5.1-4.9c0.7-0.9,1.4-1.6,2.1-2.3s1.6-1.6,2.5-2.8c0.2-0.7,0.4-1.4,0.7-2.3
			c0.2-0.9,0.4-1.8,0.7-2.6c0.8-2.4,1.9-4.7,3.5-6.8c1.6-2.1,3.1-4.3,4.6-6.5c1.5-2.2,2.7-4.5,3.7-7s1.3-5.2,1-8.1
			c-0.4-2.9-1.7-5.6-3.8-8c-2.1-2.4-4.5-4.2-7.2-5.2c-2.7-1.1-5.5-1.4-8.5-0.8s-5.4,2.2-7.5,5.1c-0.7,0.7-1.3,1.4-1.9,2.1
			C574.6,1052.2,573.9,1053,573,1054L573,1054z"
          />
          <path
            d="M688.5,1043.5c0.7-1.4,2-2.9,4.1-4.4c2.1-1.5,4.5-2.9,7.2-4.2c2.7-1.3,5.7-2.3,8.8-3.2c3.1-0.9,6-1.4,8.8-1.5
			c0.9,0,1.8,0.2,2.7,0.5s1.8,0.5,2.7,0.5s1.8-0.1,2.6-0.2c0.9-0.2,1.6-0.2,2.3-0.2c4.2,0.4,7.7,1.8,10.6,4.1c2.9,2.3,5,4.7,6.3,7.2
			c1.3,2.6,1.9,5.2,1.8,7.8c-0.1,2.6-0.6,5.1-1.6,7.5c-0.9,2.4-2.2,4.7-3.7,6.8c-1.5,2.1-3.1,4.1-4.8,5.8c-0.9,0.7-2.1,1.3-3.8,1.9
			s-3.3,1-4.9,1.2c-1.1,0.6-2.1,0.7-3,0.6c-0.9-0.2-1.1,0.1-0.7,0.9c0,0.2,0.2,0.4,0.6,0.4c0.4,0.1,0.7,0.2,0.9,0.4
			c1.6,0.3,3.3,0.6,4.9,0.8c1.6,0.2,3.2,0.6,4.8,1c1.6,0.4,3.1,1,4.6,1.8c1.5,0.8,2.9,1.9,4.3,3.3c2.1,2.1,3.7,4.2,4.9,6.4
			c1.2,2.2,1.8,4.3,1.8,6.4c0.5,2.9,0.3,6.3-0.7,9.9c-1,3.7-2.4,6.8-4.3,9.3c-2.3,2.7-5.1,4.8-8.5,6.2c-3.4,1.4-6.5,2.8-9.3,4.3
			c-2.3,1-4.7,1.6-7.2,2c-2.5,0.3-5,0.5-7.5,0.5s-4.8-0.1-6.9-0.3s-3.9-0.3-5.3-0.3c-2.7,0-5.1-0.3-7-1c-1.9-0.7-3.7-1.3-5.3-1.8
			c-0.8-0.2-1.6-0.6-2.6-1.2s-1.9-1.3-2.7-2.1c-0.8-0.8-1.5-1.6-2-2.5c-0.5-0.9-0.8-1.7-0.8-2.5c-0.2-0.9,0.2-1.5,1.2-1.8
			c1-0.3,1.8-0.9,2.5-1.8c0.4-1,0.9-1.9,1.5-2.8c0.5-0.9,1.2-1.6,1.8-2.1c0.7-0.6,1.3-0.8,2-0.8s1.4,0.4,2,1.2
			c0.9,1.2,2.5,2.8,4.9,4.7c2.4,1.9,5.2,3.6,8.3,5c3.1,1.4,6.3,2.3,9.6,2.5s6.3-0.7,9-3c1.4-1.4,2.8-3.1,4.3-4.9s2.1-4,2.1-6.6
			c0-2.2-0.3-4.4-1-6.7c-0.7-2.3-1.6-4.4-2.8-6.5c-1.2-2-2.7-3.8-4.4-5.3c-1.8-1.5-3.7-2.6-5.9-3.3c-0.9-0.6-2.1-1.1-3.7-1.6
			c-1.6-0.5-3.5-1.1-5.8-1.6c-0.9-0.1-1.7-0.1-2.4,0.1c-0.7,0.2-1.4,0.3-2,0.4s-1.2,0.1-1.7-0.2c-0.5-0.2-1.1-0.8-1.6-1.6
			c-0.3-0.7-0.7-1.4-1-2.2c-0.3-0.8-0.5-1.6-0.7-2.3c-0.1-0.7-0.1-1.3,0.1-1.9c0.2-0.6,0.5-0.9,1.1-1c1.4-0.5,3.3-0.8,5.6-0.8
			s4.7-0.2,7.1-0.6s4.9-1.2,7.3-2.4c2.4-1.2,4.5-3.3,6.4-6.2c0.9-0.9,1.4-1.9,1.6-3.1c0.2-1.2,0.2-2.6,0.2-4.1c-0.2-1-0.5-2.1-1-3.4
			c-0.4-1.3-1-2.6-1.8-3.8c-0.8-1.2-1.7-2.1-2.7-2.8c-1-0.7-2.2-0.8-3.5-0.5c-1.2,0-2.1,0-2.8-0.1c-0.7-0.1-1.6-0.2-2.8-0.4
			c-1.2,0-2.3,0.1-3.2,0.3s-2,0.6-3.2,1c-0.1,0.1-0.5,0.6-1.2,1.4c-0.7,0.8-1.4,1.8-2.3,2.8s-1.9,2.1-3,3.1s-2.1,1.8-3.1,2.4
			c-1.6,0.7-3.2,0.6-4.7-0.2c-1.5-0.8-2.2-2.7-2.2-5.7c0.1-0.6,0.1-1,0-1.3c-0.1-0.3-0.2-0.7-0.4-1c-0.2-0.3-0.3-0.7-0.3-1
			C688.2,1044.5,688.3,1044,688.5,1043.5L688.5,1043.5z"
          />
          <path
            d="M856.9,1093.7c0,1.9,0,3.1-0.1,3.6c-0.1,0.6-0.1,1-0.2,1.3c-0.1,0.3-0.2,0.7-0.2,1.2s0,1.6,0.2,3.4c0.2,1.2,0.5,2.3,1,3.4
			c0.4,1.1,0.5,2.1,0.3,2.9c0,0.7-0.1,1.4-0.2,2.1c-0.1,0.8,0.1,1.4,0.7,2c0.1,0.9,0.6,1.5,1.5,1.9c0.9,0.4,1.8,0.7,2.9,0.9
			s2.1,0.4,3.2,0.7c1.1,0.2,1.9,0.6,2.5,1.2v5.4c0.2,0.6-0.1,0.9-0.9,1.1c-0.8,0.2-2,0.2-3.4,0.2s-3.2-0.1-5.2-0.2
			c-2-0.1-4.1-0.2-6.4-0.2c-1.5,0-3.2,0.1-5.2,0.2c-1.9,0.2-3.8,0.3-5.7,0.4s-3.7,0.1-5.3,0.1s-3-0.3-3.9-0.7
			c-1.5-0.8-2.7-1.4-3.4-1.9c-0.8-0.5-1.2-1.1-1.2-1.9c-0.7-1.8-0.6-3,0.2-3.8c0.8-0.8,1.8-1.3,3-1.6c1.3-0.3,2.5-0.6,3.8-0.9
			s2.2-0.9,2.6-1.9c0.1-0.1,0.2,0,0.4,0.2c0.2,0.3,0.3,0.4,0.4,0.5c0.1,0.1,0.2-0.2,0.3-0.7c0.1-0.5,0.2-1.6,0.2-3.2
			c0.2-0.8,0.3-1.4,0.4-2.1c0-0.6,0.1-1.1,0.1-1.6c0-1,0-1.8,0.1-2.4s0.2-1.3,0.4-2.2c0-2.1-0.1-3.8-0.2-5.1
			c-0.2-1.3-0.2-2.2-0.2-2.6c0-0.6-0.5-0.7-1.6-0.6c-1.1,0.2-1.8,0.4-2,0.6h-26.2c-1.8,0-3.3,0-4.5-0.1c-1.2-0.1-1.9-0.2-2.1-0.4
			c-0.2-0.1-0.5-0.4-0.7-1c-0.3-0.6-0.5-1.2-0.7-1.9c-0.2-0.7-0.4-1.4-0.6-2.1s-0.2-1.2-0.2-1.5c-0.5-1.4-0.2-3,0.9-4.8
			c1.2-1.8,2.6-3.6,4.4-5.3c1.8-1.8,3.6-3.4,5.5-4.8s3.1-2.5,3.8-3.2c0.4-0.9,0.9-1.8,1.4-2.9s1.1-1.9,1.7-2.7
			c0.7-1.2,1.7-2.4,3.1-3.6c1.4-1.2,2.3-2.4,2.8-3.6c1.2-1.4,2.1-2.6,2.8-3.5s1.6-2.2,2.8-3.8c1.1-1.5,2.6-2.9,4.4-4
			s3.5-2.5,5.1-4.2c0.3-0.4,1-1.4,2.1-2.8s2.3-2.9,3.7-4.3s2.8-2.8,4.2-3.8s2.6-1.5,3.6-1.3c0.4,0.1,1,0.5,1.8,1.2s1.6,1.4,2.4,2.2
			s1.5,1.6,2.1,2.4s1,1.3,1.1,1.6c-0.2,0.9-0.5,1.6-0.7,2.2c-0.3,0.6-0.6,1.2-0.9,1.6s-0.6,1-0.9,1.6s-0.5,1.3-0.7,2.2
			c0.2,0.6,0.3,1.4,0.4,2.5c0,1.2,0.1,2,0.1,2.5c0.2,3.8,0.2,7.7-0.1,11.5s-0.5,7.6-0.7,11.3c-0.1,0.2-0.2,0.8-0.2,1.8
			c-0.1,1-0.1,2-0.1,3s0,2,0.1,2.9c0,0.9,0.1,1.3,0.2,1.3c0.2,0.6,0.7,0.8,1.3,0.9c0.7,0.1,1.4,0.1,2.3,0.1c1.4,0,3.1-0.3,5.2-0.8
			c2-0.6,4-1,5.8-1.5c2.8-1.6,4.7-2.2,5.4-1.8c0.9,0.4,1.4,1.1,1.5,2s0,1.8-0.2,2.8c-0.2,1-0.5,2-1,2.9c-0.4,1-0.7,1.8-0.8,2.3
			c-0.2,0.7-0.4,1.4-0.4,2.4c-0.1,0.9-0.1,1.7-0.1,2.2c-0.7,1.3-1.3,2.4-2,3.2s-1.4,1.2-2.1,1.2c-0.5,0.1-1.1,0.1-1.7-0.1
			c-0.6-0.2-1.3-0.4-2.1-0.7c-0.8-0.3-1.8-0.6-2.9-0.8c-1.1-0.3-2.5-0.5-4.1-0.6L856.9,1093.7L856.9,1093.7z M818.2,1081.2
			c2.6-0.7,5.1-1,7.5-1.2c2.4-0.1,4.5-0.1,6.4,0c2.1,0.2,4.1,0.5,6.1,0.8c0.8-3.2,1.3-6.4,1.7-9.6c0.4-3.2,0.5-6.6,0.2-10.1
			c0.2-0.7,0.3-1.5,0.2-2.5c-0.1-1-0.2-1.8-0.2-2.5c0.1-0.2,0.2-0.6,0.2-1.2c0-0.6,0.1-1.1,0.2-1.6s0.1-1,0.1-1.4s-0.1-0.5-0.2-0.4
			c-3.2,3.2-5.8,6.6-8,10.2s-4.7,7.1-7.4,10.2c-0.9,0.8-1.7,1.8-2.5,2.9s-1.5,2.3-2.1,3.4s-1.1,1.9-1.5,2.5
			C818.6,1081.7,818.3,1081.8,818.2,1081.2L818.2,1081.2z"
          />
          <path
            d="M942.9,1032.5c1.3,0.6,2.6,0.9,3.8,1.2c1.3,0.2,2.5,0.3,3.7,0.3s2.4,0,3.7-0.1c1.3-0.1,2.7-0.1,4.2-0.1
			c4.6,0.2,8.5,0.4,11.8,0.4c3.3,0.1,6.2-0.3,8.7-1.2c1.6-0.6,2.9-1.4,3.8-2.5s2.1-2.1,3.6-3.1c0.5-0.2,1.2-0.3,2-0.4
			c0.8-0.1,1.5-0.2,2-0.4c0.2,0,0.6,0.1,1.2,0.2c0.5,0.2,1.1,0.2,1.6,0.2c0.8,0.1,1,0.7,0.8,1.7c-0.2,1-0.6,2.2-1.2,3.6
			s-1.3,2.7-2,3.9c-0.7,1.3-1.2,2.1-1.4,2.5c-0.5,0.8-1,1.8-1.3,2.9s-0.6,2.5-0.9,3.8s-0.6,2.6-0.9,3.8c-0.3,1.2-0.8,2.1-1.3,2.8
			c-1,0.6-1.9,0.8-2.6,0.8s-1.5-0.1-2.3-0.3c-0.8-0.2-1.5-0.5-2.3-0.8c-0.8-0.3-1.6-0.6-2.5-0.7c-1.2-0.6-2.5-0.8-3.8-0.9
			s-2.8-0.2-4.4-0.4c-0.9,0-1.7,0-2.5,0.1c-0.8,0.1-1.6-0.1-2.5-0.6c-1.9-0.6-3.2-0.7-4.1-0.4c-0.9,0.3-2.1,0.4-3.8,0.4h-2.1
			c-0.4,0-0.7,0-0.8,0.1c-0.1,0.1-0.2,0.1-0.2,0.2c-0.1,0.1-0.2,0.1-0.4,0.2c-0.2,0-0.3,0.4-0.2,1.2s-0.1,2.8-0.6,6
			c0.2,0.6,0.3,1.3,0.2,2.2c-0.1,0.9-0.2,1.9-0.3,2.8c-0.2,0.9-0.3,1.9-0.4,2.8s-0.1,1.7,0,2.2c0.3,1,0.9,1.4,1.7,1.3
			c0.8-0.1,1.8-0.4,2.9-0.8c1.1-0.4,2.3-0.9,3.6-1.5c1.3-0.5,2.7-0.8,4.1-0.8c2.1,0,4.4,0.3,7,0.9c2.6,0.6,5.1,1.2,7.6,1.9
			c3.3,1.2,6,2.7,8.2,4.5s4,3.8,5.3,5.9s2.4,4.3,3,6.6c0.7,2.3,1,4.5,1.2,6.6c0,2.2,0,4.1-0.1,5.7c-0.1,1.6-0.2,3.2-0.4,4.6
			c-0.2,1.4-0.6,2.8-1.1,4.2c-0.5,1.4-1.2,2.8-2,4.2c-2.8,4.1-5.7,7.2-8.5,9.5c-2.8,2.3-5.7,4-8.5,5.2c-2.8,1.2-5.6,1.9-8.4,2.2
			c-2.8,0.3-5.6,0.5-8.5,0.5c-0.8,0-2.8-0.2-6.1-0.7c-3.3-0.4-6.8-1-10.6-1.8c-3.8-0.8-7.2-1.6-10.3-2.5s-4.9-1.8-5.3-2.7
			c-0.7-1.2-0.8-2.7-0.5-4.3c0.3-1.7,0.5-3,0.5-3.8c0.9-1.4,1.7-2.5,2.4-3.2s1.5-1.3,2.2-1.9c0.7-0.7,1.2-1.4,1.7-2.3
			c0.5-0.9,1-1.3,1.4-1.3c0.5,0,1.1,0.1,1.6,0.3s1.2,0.6,2,1c0.4,0.4,1,1.1,1.6,1.9c0.6,0.8,1.2,1.6,1.9,2.5
			c0.7,0.8,1.4,1.6,2.2,2.5s1.7,1.4,2.5,1.9c1.1,0.4,2,0.9,2.8,1.4c0.8,0.5,1.5,1,2.1,1.4c0.7,0.4,1.3,0.8,2,1.2
			c0.7,0.3,1.4,0.6,2.3,0.7c1.2,0.2,2.5,0.4,3.8,0.4c1.4,0.1,2.7,0.1,3.8,0.1c1.6,0,2.8-0.3,3.6-1c0.8-0.7,1.9-1.6,3.3-2.8
			c1.8-2,3.1-3.7,4.2-5.2s1.8-3.1,2.1-4.7c0.4-1.6,0.5-3.3,0.2-5.2c-0.2-1.9-0.6-4.1-1.2-6.7c-0.7-1.2-1-2.3-1.1-3.4
			s-0.5-2.1-1.2-2.9c-1.1-0.9-2.2-1.8-3.4-2.9c-1.2-1-2.4-1.9-3.8-2.5c-1.2-1.2-2.1-2-2.7-2.4c-0.6-0.4-1.8-0.8-3.7-1.4
			c-1-0.2-2.2-0.2-3.7,0.2c-1.5,0.3-3,0.7-4.6,1.2c-1.6,0.5-3.2,1-4.8,1.6s-3,0.9-4.3,1.2c-0.4,0.2-1.1,0.2-2-0.1
			c-0.9-0.3-1.4-0.5-1.6-0.7c-0.2-0.2-0.3-0.7-0.2-1.6c0.1-0.8,0.2-1.8,0.2-3c0.3-1.2,0.7-2.6,1-4.3c0.3-1.6,0.6-3.2,0.8-4.8
			c0.2-1.5,0.4-3,0.6-4.3c0.2-1.4,0.3-2.4,0.4-3c-0.1-1.3-0.3-3-0.5-5.1c-0.2-2.1-0.4-4.3-0.5-6.6s-0.1-4.6-0.1-6.8
			c0-2.2,0.2-4,0.6-5.6c0-1.2,0.5-1.9,1.6-2.1S942,1032.7,942.9,1032.5L942.9,1032.5z"
          />
          <path
            d="M1097.7,1027.1c0.9,0,1.8,0.6,2.7,1.8c0.9,1.2,1.6,2.6,2.1,4.3c0.4,1.6,0.5,3.4,0.2,5.2c-0.3,1.8-1.2,3.4-2.6,4.7
			c-0.6,0.3-1.2,0.5-2.1,0.4c-0.8-0.1-1.7-0.1-2.5-0.2c-0.9-0.1-1.7-0.1-2.5,0c-0.8,0.1-1.5,0.3-2.1,0.7c-0.9,0.6-2,1-3.4,1.5
			c-1.4,0.4-2.9,1-4.4,1.6c-1.5,0.6-3.1,1.3-4.6,2.1c-1.5,0.8-2.8,1.8-3.9,3.1c-0.2,0.2-0.3,0.4-0.2,0.7c0.1,0.2,0,0.6-0.2,1.2
			c-0.2,0-0.6,0.1-1.1,0.2c-0.5,0.2-0.7,0.4-0.7,0.6c-2.2,2.1-3.7,4.1-4.4,5.9c-0.8,1.9-1.7,3.7-2.8,5.6c-1.2,2.8-2.1,5.1-2.7,6.9
			s-1,3.1-1.2,4.1s-0.3,1.7-0.3,2.1s0.1,0.6,0.2,0.6c0.4,0.2,0.8,0.1,1.1-0.3c0.3-0.4,0.5-0.8,0.7-1c0.6-0.8,1.1-1.4,1.6-2
			c0.5-0.6,1-1,1.6-1.4s1.3-0.8,2.1-1.2c0.8-0.4,1.8-0.8,2.9-1.4c1.8-1.4,3.3-2.4,4.7-3c1.4-0.6,3.6-1.1,6.8-1.6
			c1.3-0.7,3-0.7,5-0.2s3.8,0.8,5.3,1.1c1.3,0,2.7,0.1,4.2,0.4c1.5,0.3,2.9,0.7,4.3,1.2c1.4,0.6,2.8,1.2,4,1.9s2.3,1.6,3,2.5
			c0.9,0.9,1.5,1.6,2,2.2s1,1.8,1.6,3.7c0.6,1.2,1.1,2.3,1.6,3.4c0.5,1,0.7,2.1,0.7,3c0.6,2.9,0.4,5.8-0.3,8.4
			c-0.8,2.7-1.4,5.2-2,7.5c-0.6,2.2-0.9,3.9-1.1,5.2c-0.2,1.3-0.5,2.5-0.9,3.4c-0.4,1-1.2,2-2.1,3.1c-1,1.1-2.6,2.5-4.9,4.1
			c-0.3,0.1-1,0.4-2.1,1c-1.1,0.6-2.2,1.1-3.4,1.7s-2.3,1.2-3.4,1.6s-1.7,0.7-2.1,0.7c-0.6,0.1-1.4,0.3-2.7,0.6
			c-1.3,0.3-2.6,0.6-3.9,0.9c-1.4,0.3-2.7,0.6-3.9,0.8c-1.3,0.2-2.2,0.3-2.7,0.3c-4.4,0-8.6-0.2-12.6-0.7c-4-0.4-7.7-1.7-11.1-3.8
			c-3.7-2.3-6.5-5.6-8.4-9.8c-1.9-4.2-3.2-8.7-3.8-13.5c0-1.6-0.1-3.7-0.2-6.3c-0.1-2.6,0.1-4.9,0.5-7c1.2-7.5,3.5-14.5,6.9-20.9
			s8-12,13.8-16.8c1.9-2.1,3.8-4,6-5.9c2.1-1.9,4.2-3.7,6.3-5.6c2.9-2.3,6.3-4.3,10-6.2C1089.6,1028.6,1093.5,1027.5,1097.7,1027.1
			L1097.7,1027.1z M1062.1,1094c0,1.6,0.1,3,0.2,4.1c0.2,1.1,0.2,2.3,0.2,3.8c0,1.3,0.2,2.3,0.7,3c0.5,0.7,1,1.7,1.6,2.9
			c0.7,0.9,1.3,1.7,1.8,2.5c0.6,0.8,1.2,1.6,1.8,2.5c1.9,1.4,3.6,2.5,5.2,3.4s3.8,1.2,6.6,1.2c3.1,0,5.9-0.9,8.5-2.7
			c2.6-1.8,4.4-4.1,5.2-6.8c0.7-2.1,1.1-3.7,1.3-4.9c0.2-1.2,0.2-2.6,0-4.3c-0.4-2.5-1-4.8-1.6-6.7c-0.6-2-1.6-3.7-3-5.1
			c-1.3-1.2-2.5-2.3-3.5-3.4s-2.3-1.9-3.7-2.5c-1.4-0.6-2.7-0.8-3.9-0.9c-1.2-0.1-2.5-0.1-3.8-0.1c-1.8,0-3.4,0.4-4.8,1.2
			s-2.8,1.9-4,3.3c-1.2,1.4-2.2,2.9-3.1,4.6C1063,1090.7,1062.4,1092.3,1062.1,1094L1062.1,1094z"
          />
          <path
            d="M1169.4,1033.8c1.4-1.4,3.8-1.9,7.2-1.3c2.3,0.1,5,0.2,8.1,0.3s6.2,0.2,9.3,0.2s6,0.1,8.6,0.2s4.6,0.3,5.9,0.5
			c2.3,0.3,4.1,0.5,5.4,0.6c1.3,0.1,2.4,0.1,3.2,0c0.8-0.1,1.5-0.2,2.1-0.3c0.6-0.2,1.1-0.2,1.6-0.2c0.3-0.2,0.5-0.3,0.4-0.4
			c-0.1-0.1-0.1-0.1-0.1-0.2s0.1-0.2,0.3-0.3c0.2-0.2,0.8-0.5,1.6-0.9c0.7-0.2,1.6-0.5,2.8-0.9c1.2-0.4,2.2-0.7,3.1-0.9
			c0.7-0.4,1.3-0.3,1.9,0.5c0.6,0.8,1,1.5,1.2,2.3c0.2,0.4,0.2,0.9-0.1,1.3c-0.3,0.4-0.5,0.8-0.7,1c-0.7,1.9-1.6,3.8-2.8,5.8
			c-1.2,2-2.2,3.7-3.1,5c-0.9,1.6-1.9,3.2-3.2,4.8s-2.3,3.2-3.2,4.8c-0.8,1.5-1.4,3.1-1.9,4.8c-0.5,1.6-1.1,3.4-1.7,5.2
			c-0.2,0.3-0.6,0.8-1.2,1.6c-0.6,0.7-1.1,1.5-1.6,2.3c-0.6,0.8-1,1.6-1.5,2.5s-0.7,1.4-0.8,1.9c-0.8,1.8-1.4,3.2-2.1,4.3
			c-0.6,1.2-1.2,2.2-1.7,3.2c-0.6,1-1.1,2.1-1.6,3.2c-0.6,1.2-1.2,2.6-1.8,4.3c-1,2.7-2.1,5.1-3.3,7.1c-1.2,2-2.4,4.5-3.6,7.5
			c-0.9,2.6-1,4.8-0.3,6.4c0.7,1.6,1.5,3,2.5,4.2s2,2.2,2.9,3c0.9,0.9,1.2,1.8,0.8,2.8c-1.2,3.2-3.4,5.3-6.5,6.4
			c-3.1,1.1-6.4,1.5-9.9,1.3c-0.9-0.1-1.8-0.3-2.8-0.7c-1-0.3-1.9-0.7-2.8-1c-0.9-0.3-1.6-0.6-2.2-0.9s-0.9-0.5-0.9-0.6
			c-0.1-0.1-0.3-0.5-0.6-1.1c-0.3-0.6-0.5-1.3-0.7-2s-0.4-1.5-0.5-2.3c-0.1-0.8-0.1-1.5,0-2.1c0.7-2.5,1.3-4.8,1.8-6.9
			c0.6-2.1,1.2-3.7,1.8-4.9c0.7-0.9,1.2-1.6,1.6-2.3c0.4-0.7,0.7-1.3,0.9-1.8c0.2-0.6,0.4-1.2,0.7-1.8c0.2-0.7,0.6-1.4,1-2.3
			c0.8-0.6,1.2-1.3,1.2-2.3c0.1-1,0.4-1.8,1.1-2.3c0.6-0.2,0.9-0.8,1.2-1.8c0.2-1,0.6-1.8,1.2-2.3c2.7-4.4,5.3-8.7,7.8-12.9
			c2.5-4.2,5.2-8.6,8.1-13.2c0.6-0.8,1.3-1.8,2.2-3c0.9-1.3,1.8-2.6,2.6-4s1.4-2.8,1.9-4.1c0.4-1.3,0.5-2.4,0.2-3.3
			c0-0.4-0.4-0.6-1.2-0.3c-0.4,0.1-0.8,0.2-1.2,0.3c-1.5,0.4-2.8,0.7-3.7,0.9c-0.9,0.2-1.8,0.2-2.7,0.2h-2.8c-1,0-2.2,0.1-3.6,0.2
			h-3.4c-1.9,0-4,0.1-6.2,0.2c-2.2,0.1-4.2,0.2-6.1,0.4s-2.9,0.4-3.1,0.7c-0.9,0.9-1.9,2-2.9,3.4c-1.1,1.4-1.8,2.8-2,4.3
			c0.2,1.2,0.1,2.6-0.5,4.1c-0.4,1.3-1.8,2-4.1,2c-0.2-0.2-0.7-0.7-1.4-1.4s-1.3-1.2-1.9-1.4c-0.2-0.3-0.3-1.7-0.3-4.1
			c0.2-2.3,0.8-4.8,1.6-7.5c0.9-2.7,1.7-5.5,2.5-8.4c0.4-1.9,0.8-3.4,1.1-4.8C1168.9,1035.6,1169.2,1034.6,1169.4,1033.8
			L1169.4,1033.8z"
          />
          <path
            d="M1295,1036.1c1.6-0.3,3-0.7,4-1c1-0.3,2.1-0.7,3-1c1-0.3,2-0.7,2.9-1.1c1-0.4,2.2-0.7,3.8-1.1c1.4-0.2,3.1-0.5,5.2-0.8
			c2-0.3,4.1-0.5,6.2-0.5c1.2,0,2.3,0.2,3.4,0.5s1.9,0.8,2.5,1.3c0.1,0,0.5,0.1,1.2,0.4s1.4,0.6,2.1,1s1.5,0.7,2.2,1
			s1.2,0.4,1.4,0.4c1.3,0,2.4,0.5,3.4,1.4c0.9,0.9,1.7,2.1,2.3,3.5c0.6,1.4,1.1,3,1.4,4.7c0.3,1.7,0.6,3.4,0.7,5
			c0,1.9-0.2,3.5-0.5,4.8s-0.6,2.5-0.8,3.4c-0.4,1.9-1,3.6-1.7,5.2s-1.5,3.1-2.4,4.3c-1.4,1.4-3.5,2.6-6.2,3.4
			c-2.7,0.9-5.1,2-7.1,3.4l2,1c1.1,0.6,1.8,0.8,2.1,0.8c1,0.4,2.6,1.2,4.8,2.2c2.2,1,4,2.3,5.4,3.7c2.5,2,4.5,3.7,5.9,5.1
			c1.2,1.9,1.9,3.5,2.1,4.9s0.6,2.8,1,4.1c0,1.6-0.1,3.2-0.3,4.8s-0.6,3.3-1,5.2c-0.6,1.4-1,2.9-1.5,4.5c-0.4,1.6-1,3-1.8,4.2
			c-0.7,0.9-1.3,1.4-1.8,1.5c-0.6,0.1-1.3,0.7-2.3,1.8c-0.7,0.9-2,1.8-4,2.8c-2,1-3.7,1.7-5,2.1c-0.8,0.6-1.5,0.9-2.1,1
			c-0.7,0.1-1.3,0.3-2,0.5c-0.8,0.4-1.5,0.8-2.2,1.1c-0.7,0.3-1.7,0.5-2.9,0.7c-4.7,1.9-9.7,2.6-15.1,2.3c-2.2-0.2-4.6-0.5-7.1-0.8
			c-2.6-0.3-4.9-0.8-7-1.5c-1.5-0.8-2.9-1.8-4.2-3.1c-1.3-1.3-2.6-2.6-4-3.8c-0.6-0.4-1.1-1.3-1.7-2.5s-1.2-2.5-1.6-3.9
			c-0.5-1.4-0.9-2.7-1.2-3.9c-0.3-1.2-0.4-2-0.4-2.5c0-1.5-0.1-3-0.2-4.5c-0.1-1.5,0.1-3,0.5-4.5c0.8-2.1,1.9-4.2,3.5-6.2
			c1.6-2.1,3.3-3.8,5.2-5.2c0.2-0.6,0.6-0.9,1.2-1.1c0.6-0.2,1.1-0.6,1.6-1.2c0.7-0.4,1.2-0.9,1.5-1.3c0.3-0.4,0.8-0.9,1.3-1.5
			c0.2-0.2,0.7-0.4,1.4-0.6c0.7-0.2,1.5-0.3,2.3-0.6c0.8-0.2,1.6-0.4,2.3-0.7c0.7-0.2,1.1-0.5,1.2-0.8s-0.4-0.8-1.4-1.3
			c-1-0.6-2.3-1.2-3.7-1.8c-1.4-0.7-2.8-1.3-4.1-2c-1.3-0.7-2.2-1.3-2.6-1.8c-0.9-1.9-1.6-3.7-2.1-5.5c-0.6-1.8-0.9-3.6-1.2-5.5
			c-0.1-2.3,0.1-4.7,0.7-7.1s1.4-4.8,2.5-7.1c1.1-2.3,2.4-4.4,3.9-6.2C1291.4,1038.3,1293.1,1037,1295,1036.1L1295,1036.1z
 M1295,1094.5c0,1.6-0.1,2.8-0.4,3.4c-0.3,0.7-0.4,1.8-0.4,3.4c0,0.9,0.3,1.9,0.9,3.2s0.9,2.3,0.9,3.2c0.2,0.7,0.5,1.2,0.9,1.7
			s0.7,1,0.9,1.4c0.6,0.6,1,1.2,1.4,2s0.8,1.5,1.4,2.1c0.4,0.8,1.2,1.3,2.2,1.7c1,0.4,2.7,0.6,5,0.6c1.6,0.2,2.8,0.4,3.6,0.4
			c0.8,0.1,1.4,0.1,2,0.1c1.6-0.2,2.8-0.5,3.6-0.7c0.8-0.3,1.3-0.5,1.6-0.7s0.6-0.5,0.8-0.8s0.6-0.7,1.2-1c0.6-0.4,1-0.7,1.4-0.9
			s0.7-0.6,0.9-1.2c1.2-2.8,1.9-5.6,2.1-8.3s-0.3-5.3-1.6-7.8c-0.8-1.3-1.6-2.5-2.5-3.7c-0.9-1.2-2.1-2.3-3.4-3.5
			c-0.4-0.4-0.8-0.7-1.2-0.9c-0.3-0.2-0.6-0.3-0.9-0.5s-0.6-0.4-0.8-0.7c-0.3-0.3-0.6-0.7-0.9-1.2c-1.1-0.7-2.2-1.5-3.4-2.5
			s-2.4-1.1-3.8-0.1c-0.2,0-0.6,0.2-1.1,0.7c-0.5,0.5-1.2,1-2.2,1.4c-1.3,1-2.6,1.9-3.9,2.7s-2,1.4-2,1.9c-0.4,0.6-0.8,1.3-1.1,2.4
			C1295.9,1093.3,1295.5,1094,1295,1094.5L1295,1094.5z M1301.8,1045.3c-0.4,0.4-0.8,1.1-1.2,2s-0.6,1.9-0.6,2.9
			c-0.2,1.4-0.3,2.9-0.3,4.3s0.1,2.7,0.3,3.5c0.2,0.4,1,1.4,2.2,2.8c1.3,1.4,2.9,2.9,5,4.4c2.2,1.2,3.7,2.2,4.5,2.9
			c0.8,0.8,1.3,1.2,1.6,1.2c1.2-0.4,2.5-1.2,4-2.4s2.5-2.2,3.2-3c1.9-2.8,2.7-6,2.5-9.6c-0.2-3.6-1.6-6.6-4.3-9.1
			c-0.9-0.9-1.8-1.7-2.9-2.5c-1-0.8-2.5-1.5-4.3-2.1c-1-0.2-1.9-0.2-2.6,0.2c-0.8,0.3-1.5,0.8-2.3,1.4s-1.5,1.2-2.3,1.8
			C1303.5,1044.7,1302.7,1045.1,1301.8,1045.3L1301.8,1045.3z"
          />
          <path
            d="M1413.3,1036.6c1.3-0.9,2.8-1.6,4.6-2.3s3.6-1.2,5.4-1.6c1.9-0.4,3.7-0.7,5.7-0.9c1.9-0.2,3.7-0.3,5.3-0.3
			c1.1-0.1,2.6,0,4.6,0.2c2,0.3,4,0.7,6,1.2c2,0.6,4,1.2,5.8,2c1.9,0.8,3.2,1.6,4.1,2.6c0.8,0.7,1.4,1.4,1.9,2.2
			c0.5,0.8,1.1,1.6,1.7,2.2c0.2,0.6,0.7,1,1.3,1.4c0.7,0.4,1,0.7,1,0.9c0,0.8,0,1.4,0.1,1.9c0.1,0.5,0.2,1.1,0.4,1.9
			c0.4,1.2,0.8,2.1,1,2.8s0.3,1.3,0.4,2.1c0.1,0.7,0.1,1.6,0.2,2.5s0.1,2.5,0.2,4.4c0.1,1.5,0.1,3.5,0,5.9c-0.1,2.4-0.4,4.9-0.7,7.4
			c-0.4,2.5-0.9,4.9-1.5,7.1s-1.4,3.9-2.4,5c-0.4,0.8-0.7,1.5-0.7,2.2s-0.4,1.7-1.2,2.9c-0.3,0.6-0.6,1.3-0.9,2.1
			c-0.3,0.9-0.6,1.8-0.8,2.6c-0.3,0.9-0.6,1.7-1.1,2.5c-0.4,0.8-1,1.4-1.6,1.9c-1.9,1.3-4,2.8-6.3,4.4c-2.3,1.6-4.2,3.5-5.7,5.6
			c-2.1,2.9-4.1,5.3-6.1,7.1s-4.3,3.8-7.1,6c-1.2,1.4-2.5,2.3-3.8,2.7c-1.3,0.4-2.7,1.2-4.1,2.4c-0.4,0.7-1.1,1.4-2.1,2.1
			s-1.7,1.4-2.4,2c-0.2,0.2-0.8,0.4-1.8,0.7c-1,0.2-2.1,0.4-3.2,0.6s-2.3,0.2-3.4,0.2s-2-0.1-2.5-0.2c-0.3-0.1-0.7-0.8-1.1-2
			c-0.4-1.2-0.7-2.6-0.8-4.1c-0.2-1.5-0.1-3,0.2-4.5s0.8-2.5,1.7-3c1.4-1,2.6-1.6,3.6-1.8c1-0.2,1.9-0.3,2.9-0.4
			c0.9-0.1,1.9-0.2,3-0.3c1.1-0.2,2.5-0.7,4.1-1.6c0.8-0.6,1.8-1.2,3.1-2c1.3-0.8,2.4-1.5,3.3-2.1c0.9-0.6,1.8-1.2,2.9-2
			c1-0.8,1.9-1.5,2.7-2.1c1.3-1.4,2.7-3.5,4.3-6.2s2.9-5.6,4.1-8.6s2.2-5.8,3-8.5c0.8-2.7,1.2-4.8,1.2-6.3c0-0.1-0.2,0.1-0.7,0.5
			c-0.5,0.4-1,1-1.6,1.6s-1.1,1.3-1.6,1.9s-0.9,1-1,1.1c-2.1,1.3-3.8,2-5.2,2s-3.2,0.2-5.2,0.7c-1.8,0.9-3.7,1.4-5.9,1.6
			c-2.2,0.2-4.4,0.1-6.6-0.2s-4.4-0.7-6.5-1.4c-2.1-0.7-3.9-1.4-5.6-2.1c-0.6-0.2-1.2-0.7-2-1.6c-0.8-0.8-1.5-1.7-2.1-2.7
			c-0.7-1-1.2-1.9-1.6-2.9c-0.4-0.9-0.7-1.6-0.7-2.1c-0.2-1.2-0.4-2.5-0.6-3.8c-0.2-1.4-0.6-2.5-1.2-3.4c-0.1-0.7-0.1-1.7-0.1-3.1
			c0.1-1.4,0.2-2.9,0.4-4.5c0.2-1.6,0.5-3.1,0.9-4.4s0.8-2.4,1.4-3c1.1-2,2.2-3.6,3.4-5s2.4-2.6,3.7-3.7c1.3-1.1,2.8-2.1,4.3-3.1
			C1409.3,1038.9,1411.2,1037.8,1413.3,1036.6L1413.3,1036.6z M1416.6,1047.6c-0.3,0.7-0.6,1.2-0.7,1.6s-0.2,0.7-0.2,1.1
			s-0.1,0.7-0.2,1.2c-0.1,0.4-0.2,1-0.4,1.6c-0.4,0.9-1,1.8-1.6,2.7c-0.7,0.9-0.9,1.8-0.7,2.7c-0.2,1.2-0.3,2.1-0.3,2.8
			s0.1,1.4,0.3,2.3s0.5,1.7,0.8,2.5c0.3,0.8,0.8,1.5,1.5,2.1c0,0.9,0,1.3,0.1,1.4c0.1,0.1,0.3,0.4,0.9,0.9c2.4,2.3,4.7,3.5,6.8,3.7
			s5,0,8.6-0.6c1.2-0.2,2.1-0.8,2.8-1.8c0.7-1,1.4-1.8,2.3-2.3c0.7-0.4,1.5-1.3,2.6-2.5s1.9-2.2,2.5-2.9c0.8-1,1.1-2.4,0.9-4.3
			c-0.2-1.9-0.6-3.9-1.3-5.9c-0.7-2-1.7-3.9-2.9-5.6c-1.2-1.7-2.4-2.8-3.6-3.4c-0.9-0.3-1.8-0.7-2.9-1.2c-1-0.4-2.1-0.8-3-1.1
			c-1-0.3-2-0.3-2.9-0.2c-1,0.1-1.9,0.5-2.6,1.2c-0.7,0.4-1.5,0.7-2.5,0.9s-1.8,0.6-2.5,1.4c-0.2,0.2-0.6,0.6-1.2,1.1
			C1416.6,1047.3,1416.4,1047.6,1416.6,1047.6L1416.6,1047.6z"
          />
          <path
            d="M1543.4,1031.5c1.6-0.9,3.6-1.4,5.8-1.6s4.6-0.3,7.1-0.2c2.5,0.1,4.9,0.3,7.3,0.6c2.4,0.3,4.5,0.4,6.4,0.4
			c2.5,0.4,5,1.2,7.4,2.4c2.4,1.2,4.2,2.8,5.4,4.8c0.6,1,1,2.4,1.5,4.2s0.8,3.6,1.1,5.5c0.3,1.9,0.5,3.6,0.6,5.2
			c0.1,1.6,0.1,2.6,0,3c0,6.6-0.4,13-1.1,19.4c-0.7,6.4-2.1,12.5-4.1,18.2s-4.8,11-8.4,15.9c-3.6,4.9-8.2,9.1-13.9,12.6
			c-2.2,1.3-4.7,2.3-7.4,3.1c-2.7,0.8-5.6,1.3-8.4,1.5c-2.9,0.2-5.8,0.2-8.6-0.2c-2.8-0.3-5.5-0.9-7.9-1.6c-1.2-0.2-2.2-0.4-2.9-0.5
			s-1.7-0.4-3-1c-2.1-1.1-3.6-3-4.5-5.7s-1.6-5.6-1.9-8.7c-0.3-3.1-0.5-6.2-0.4-9.2c0.1-3,0-5.5-0.1-7.5c0-2,0-4.3,0.1-7.1
			c0.1-2.8,0.2-5.7,0.3-8.7c0.2-3,0.5-5.9,0.9-8.7c0.4-2.8,1-5.1,1.8-7c3.2-6.8,7-12.3,11.6-16.7
			C1532.6,1039.6,1537.7,1035.5,1543.4,1031.5L1543.4,1031.5z M1542.6,1047.6c-2.5,1-4.2,2.2-5.1,3.5c-0.9,1.4-1.4,2.8-1.6,4.3
			c-0.2,1.5-0.3,3-0.3,4.5s-0.4,3.1-1.2,4.6c0,0.7-0.2,1.2-0.5,1.6c-0.3,0.4-0.5,1.1-0.5,2v6.4c-0.7,1.2-1.2,2.3-1.6,3.3
			c-0.4,1-0.7,2.1-0.7,3.4c0,2.1,0.3,4.2,0.9,6.2c0.6,2.1,0.8,4.2,0.6,6.2v5.5c0,1.8,0.6,3.8,1.8,5.8c1.5,2.5,3.2,4.4,5.2,5.6
			c1.9,1.2,4.1,1.9,6.6,2.1c0.9,0.1,1.8,0,2.9-0.4s2.1-0.9,3-1.5c1-0.6,1.9-1.3,2.7-2.1c0.8-0.8,1.4-1.4,1.9-2
			c2.9-2.9,5.5-6.9,7.5-11.8c2.1-4.9,3.4-10.1,4.1-15.5s0.5-10.8-0.6-16.1c-1-5.3-3.2-9.8-6.5-13.5c-1.2-1.4-2.7-2.3-4.3-2.5
			s-3.1-0.7-4.3-1.2c-1.4-0.7-3.1-0.8-5.2-0.4C1545.4,1046.1,1543.8,1046.7,1542.6,1047.6L1542.6,1047.6z"
          />
        </g>
      </g>
      <g id="lettres_x5F_selectionnables">
        <g id="lettres_haut">
          <path
            class="symbol"
            data-symbol="A"
            d="M215.3,634.9c2-1.5,3.9-2.1,5.5-2s3.2,0.8,4.6,1.8c1.4,1.1,2.8,2.4,4.1,3.9c1.3,1.5,2.6,2.9,3.8,4.3l3.6,5
			c1.3,0.6,3,1.5,5.1,2.5c2.1,1.1,3.8,2.5,5.1,4.2l2.5,3.4c0.2,0.3,1,0.9,2.5,2s3.1,2.2,4.9,3.4c1.8,1.2,3.5,2.4,5,3.6
			s2.5,2,2.9,2.6c1.3,1.7,3.2,3.9,5.7,6.5s5.2,4.9,7.9,7.2c2.7,2.2,5.3,4,7.7,5.3c2.4,1.3,4.2,1.6,5.3,0.8c1.3-0.9,2.4-1.5,3.3-1.8
			c0.9-0.3,1.7-0.6,2.2-1c0.3-0.2,0.8-0.5,1.5-0.9s1.6-0.8,2.6-1s1.9-0.4,2.8-0.3c0.9,0.1,1.7,0.6,2.3,1.4c1.3,1.8,1.6,3.6,0.9,5.2
			c-0.8,1.7-2,3.1-3.7,4.4l-9.5,6.3l-3.9,2.8l-6.9,5l-3,2.2l-3.5,3.2c-0.5,0.4-1.5,1-2.8,1.8c-1.3,0.8-2.2,0.9-2.7,0.2
			c-0.9-1.3-1.1-2.8-0.6-4.4c0.5-1.7,1.4-3.3,2.6-5c1-1.4,1.7-2.7,2.1-3.9s0.2-2.4-0.7-3.7c-0.2-0.3-0.8-0.9-1.8-1.8
			s-2.1-1.9-3.4-2.9c-1.2-1-2.4-1.8-3.5-2.5s-1.9-0.9-2.2-0.6c-0.9-0.7-1.9-1.6-2.8-2.6s-1.8-1.8-2.7-2.6c-0.9-0.7-1.7-1.1-2.6-1.2
			c-0.8-0.1-1.7,0.3-2.6,1.2l-4.7,3.4c-0.3,0.2-0.8,0.6-1.6,1.2s-1.6,1.2-2.3,1.9s-1.5,1.4-2.2,2.1c-0.7,0.7-1.1,1.3-1.1,1.8
			c-0.8,1.4-0.7,2.6,0.3,3.5s1.9,1.9,2.8,3.1c0,0.6,0.1,1.6,0.3,3c0.2,1.5,0.5,3,0.9,4.5c0.4,1.6,0.7,3,1.1,4.2
			c0.4,1.3,0.6,2,0.9,2.3c0.8,1.1,1.7,1.7,2.6,1.7c0.9,0,1.9,0,2.9-0.1s2.1,0,3.2,0.3c1.1,0.3,2.3,1.3,3.6,3.1
			c0.4,0.5,0.5,1.1,0.4,1.7s-0.4,1.1-0.8,1.6c-0.4,0.5-0.8,1-1.3,1.4s-0.8,0.7-1.1,0.9c0.2,0.3,0.2,0.5,0.1,0.8s-0.3,0.5-0.7,0.7
			c-0.3,0.2-0.9,0.3-1.8,0.3c-0.4,0.3-0.9,0.6-1.4,1c-0.6,0.4-1.2,0.8-2,1.2l-2.7,1.5c-1.2,0.9-2.1,1.7-2.6,2.5
			c-0.5,0.8-1.4,1.7-2.6,2.5c-1.3,0.9-2.1,1.7-2.6,2.2s-0.7,1-0.9,1.3c-0.1,0.3-0.2,0.5-0.2,0.7c0,0.2-0.2,0.3-0.4,0.5
			c-0.5,0.3-1.3,0.9-2.5,1.6c-1.2,0.7-2.5,1.4-3.9,2.1s-2.6,1.1-3.8,1.3s-2,0-2.4-0.5c-1.3-1.7-1.8-3.6-1.6-5.5
			c0.2-2,1.1-3.6,2.9-4.8l2.1-2.6c0.2-0.1,0.4-0.5,0.7-1.2c0.3-0.7,0.5-1.5,0.7-2.3c0.2-0.9,0.4-1.8,0.7-2.7s0.4-1.7,0.6-2.2
			c-1.1-2.7-1.9-5.9-2.5-9.6c-0.5-3.7-1.1-7.3-1.7-10.6c-0.6-2-1.1-4.1-1.4-6.4s-0.6-4.3-0.7-6.1c-0.4-2.4-0.8-4.7-1.4-6.8
			s-1.2-4.2-1.9-6.3c-0.7-2.1-1.3-4.2-2-6.5c-0.6-2.2-1.2-4.5-1.7-7c-0.4-1.8-0.9-3.7-1.7-5.6c-0.8-1.9-1.6-3.8-2.6-5.7
			s-1.8-4-2.5-6.1s-1.4-4.3-1.8-6.7c0.2-0.9,0.4-1.8,0.6-3s0.7-2.4,1.4-3.8L215.3,634.9L215.3,634.9z M231.9,661.3
			c-0.6,0.5-1,1.2-1.1,2.1c-0.1,0.9-0.1,1.8,0,2.8c0.1,1,0.4,1.8,0.7,2.7c0.3,0.8,0.6,1.4,0.8,1.6c0.6,0.8,1.1,1.4,1.4,1.9
			c0.3,0.4,0.5,0.8,0.7,1.2s0.3,0.9,0.6,1.6c0.3,0.7,0.7,1.7,1.3,3.1l0.8,1.1c0.4,0.5,1.2,0.8,2.4,0.7c1.2-0.1,2.1-0.4,2.8-0.8
			c0.3-0.2,0.7-0.5,1.3-1c0.6-0.4,1.2-0.9,1.7-1.4c0.5-0.5,0.9-1.1,1.2-1.7s0.2-1.2-0.2-1.7c-0.9-1.2-1.9-1.9-3-2
			c-1.1-0.2-1.9-0.6-2.4-1.2l-4.4-6c-0.4-0.5-1.1-1.2-2.2-2C233.1,661.1,232.4,660.9,231.9,661.3L231.9,661.3z"
          />
          <path
            class="symbol"
            data-symbol="B"
            d="M316.6,578l1.2-0.6l1.2-0.6l4.2-2.2c0.9,0.5,1.7,0.5,2.6,0.2c0.9-0.3,1.5-0.6,1.9-0.8l1.7-0.9l0.9-0.5
			c2.7-1.4,5.5-2.5,8.4-3.3c2.9-0.8,5.9-1.1,9-1c3.1,0.1,6.1,0.8,9.2,2c3.1,1.2,6.1,3.1,9.2,5.6l4.1,8c0.9,1.7,1.2,3.5,1.1,5.5
			s-0.4,3.8-0.8,5.6c-0.4,1.8-0.8,3.4-1.1,4.9c-0.3,1.5-0.4,2.5,0,3.1c0.2,0.3,0.6,0.6,1.3,0.8c0.7,0.2,1.4,0.5,2.2,0.7
			c0.9,0.2,1.9,0.4,3,0.6c1-0.5,2.2-0.5,3.7-0.1c1.5,0.5,2.9,1.2,4.2,2.2c1.4,1,2.6,2.2,3.8,3.5s2,2.5,2.6,3.5l2.2,4.2
			c0.6,2.1,1.3,3.9,2.1,5.3c0.8,1.4,1.2,3.5,1.2,6.2c-0.4,1-0.5,1.8-0.5,2.6c0.1,0.8,0.1,1.5,0.2,2.2c0,0.7-0.1,1.4-0.3,2.1
			s-0.8,1.4-1.6,2.3c-2.1,3.8-4.6,7.2-7.3,10.3c-2.8,3.1-6.2,5.7-10.5,7.9l-10,5.2l-4.2,2.2l-4.2,2.2l-0.9,0.5l-4.9,0.5l-1,1.7
			c-0.5,0.9-1.2,1.6-1.9,1.9l-3,1.6c0.1-0.3,0-0.5-0.3-0.5c-0.3,0-0.7,0-1.1,0s-1,0.2-1.6,0.4c-0.6,0.2-1.1,0.3-1.6,0.4
			c-1.4,0.7-2.5,1-3.3,0.8s-1.4-0.7-1.8-1.4c-0.7-1.4-1.2-3-1.4-4.8c-0.2-1.8,0.4-3.1,1.8-3.8l2.6-1.3c0.6-0.3,1.6-1.1,3-2.3
			c1.4-1.2,2.4-2,3-2.3s1-0.7,1-1.1c0.1-0.4,0-0.9-0.2-1.3s-0.5-0.9-0.9-1.4c-0.4-0.5-0.6-0.8-0.8-1.1c0.2-0.8,0.1-1.4-0.5-2
			c-0.3-0.2-0.5-0.4-0.8-0.5l-2-3.8c-0.1-1.7-0.5-3.2-1-4.5c-0.5-1.3-0.9-2.2-1.2-2.8l-2.2-4.2c-0.4-1.2-0.7-2.5-1-3.7
			c-0.2-0.9-0.4-1.8-0.7-2.8c-0.2-1-0.4-1.6-0.6-1.9c-0.4-0.7-0.6-1-0.8-0.9s-0.3,0-0.5-0.1c-0.2-0.2-0.5-0.7-1-1.7
			c-0.4-1-1.1-2.9-2.1-5.7l-1.3-2.6l-1.2-2.4c-0.4-0.7-0.6-1.7-0.8-3c-0.1-1.3-0.4-2.3-0.8-3.1c-1.4-2.6-2.9-4.5-4.7-5.7
			c-1.8-1.2-3.6-2-5.5-2.4c-1.9-0.4-3.9-0.5-5.8-0.3s-3.8,0.3-5.4,0.4c-1.7,0.1-3.1,0-4.4-0.2c-1.3-0.2-2.2-0.9-2.8-2
			c-0.5-0.9-0.5-1.9-0.2-3s0.9-2.1,1.5-3c0.7-0.9,1.5-1.7,2.4-2.5c0.9-0.7,1.7-1.2,2.3-1.5l3-1.6l5.9-4.8L316.6,578L316.6,578z
 M337.3,586.1c-0.6,0.3-1.1,1-1.6,2.2c-0.5,1.2-0.8,2.4-1,3.8c-0.2,1.4-0.2,2.7-0.1,4c0.1,1.3,0.5,2.1,1.1,2.6l1.3,2.6l1.5,2.9
			l2,3.8c0.9,1.3,1.7,2.4,2.4,3.2s1.4,1.5,2.2,2c0.8,0.5,1.6,0.7,2.4,0.7c0.9-0.1,2-0.5,3.4-1.2c0.7-0.4,1.5-1.1,2.5-2.2
			s1.8-2.4,2.6-3.8s1.4-2.8,1.8-4.2c0.4-1.4,0.3-2.5-0.2-3.5l-2-3.8l-2.2-4.2c-0.5-0.9-1.3-1.8-2.5-2.7c-1.2-0.9-2.6-1.6-4.2-2.1
			c-1.6-0.5-3.3-0.8-5-0.9C340.1,585.1,338.6,585.4,337.3,586.1L337.3,586.1z M354.2,621c-1.4,0.7-2.3,1.8-2.6,3.3s-0.4,3-0.2,4.5
			s0.6,3,1,4.4c0.5,1.4,0.9,2.4,1.2,3l2.8,5.4l2,3.8c0.2,0.3,0.5,0.7,1.1,1.2s1.2,0.9,2,1.3c0.8,0.4,1.6,0.6,2.4,0.8s1.6,0,2.3-0.3
			c1.3-0.7,2.3-1.4,2.9-2c0.6-0.7,1.2-1.3,2-2c1.1-1,2.3-2.2,3.3-3.7c1.1-1.5,1.9-3.1,2.6-4.8c0.7-1.7,1-3.3,1.1-5
			c0-1.6-0.4-3.1-1.3-4.4c-0.8-1.2-1.4-2.3-1.8-3s-0.6-1.4-0.8-1.8s-0.2-0.8-0.4-1.1c-0.1-0.2-0.4-0.5-0.7-0.9
			c-0.8-1.2-1.9-2.1-3.2-2.5c-1.4-0.4-2.8-0.5-4.4-0.3c-1.5,0.2-3.1,0.6-4.8,1.2c-1.7,0.6-3.1,1.2-4.5,1.9l-1.2,0.6L354.2,621
			L354.2,621z"
          />
          <path
            class="symbol"
            data-symbol="C"
            d="M451.9,515c1.6-0.6,2.9-0.7,4-0.4c1.1,0.4,2,1,2.9,1.9c0.8,0.9,1.5,1.9,2,3c0.5,1.1,0.9,2,1.2,2.7l1.2,3
			c0.3,2.7,0.1,5.3-0.6,7.9c-0.7,2.6-2.3,4.4-4.8,5.3c-1.6,0.6-3.5,0.8-5.6,0.5c-2.2-0.3-4.4,0-6.6,0.9c-2.3,0.9-4.3,2.6-5.8,5.1
			s-2.8,5.2-3.7,8.1c-0.9,2.9-1.5,5.7-1.7,8.4s-0.1,4.7,0.4,6l1.7,4.4l2.4,6c0.5,1.3,1.6,3.3,3.2,6.2c1.6,2.9,3.5,5.8,5.8,8.6
			c2.2,2.8,4.6,5.2,7.1,7.1c2.5,1.9,4.9,2.4,7.2,1.5l2.7-2.5c2.4-1,4.6-2.1,6.5-3.5s3.4-3.4,4.5-5.9c1.2-3.7,2.6-6.7,4.1-9.1
			c0.4-0.8,1-1.4,1.8-2c0.8-0.5,1.5-0.9,2.1-1.2c0.3,0.1,0.7,0.3,1.2,0.6c1,0.6,1.6,1.2,1.8,1.8c0.9,2.3,1.1,5.3,0.6,9
			c-0.5,3.7-1.4,7.3-2.7,10.9c-1.3,3.6-2.8,6.9-4.6,9.7s-3.4,4.6-5,5.2l-1.3,0.5c-1.4,0.9-2.6,1.8-3.7,2.6c-1.1,0.7-2.1,1.3-3.1,1.9
			c-1,0.6-1.7,1-2.1,1.2c-0.9,0.3-2.2,0.4-4,0.3s-3.8-0.4-5.9-0.7c-2.1-0.3-4-0.8-5.8-1.4c-1.8-0.6-3-1.2-3.7-1.9
			c-0.5-0.2-1.5-1-2.9-2.4s-2.8-3.2-4.4-5c-1.5-1.9-3-3.8-4.3-5.7c-1.3-1.9-2.2-3.4-2.6-4.5l-1.9-4.9c-0.6-0.5-1.1-1-1.6-1.4
			c-0.9-0.8-1.6-1.6-1.9-2.4c-0.3-1.3-0.5-2.7-0.6-4c-0.2-1-0.4-2.1-0.5-3.2c-0.2-1.1-0.4-2-0.7-2.7l-1.4-3.5
			c-0.1-0.3-0.4-0.7-0.8-1.1c-0.4-0.4-0.8-0.8-1.2-1.2c-0.5-0.4-1-0.9-1.5-1.4l-0.2-5.6l-1.2-3c0.1-2.1,0.3-4.1,0.5-5.8
			c0.1-1.5,0.3-3,0.5-4.4c0.2-1.4,0.5-2.5,0.8-3.2c-0.5-2.1-0.4-4.2,0.3-6.3s1.6-4.2,2.8-6.2c1.2-2,2.6-4,4.1-6
			c1.5-2,3.1-3.9,4.6-5.8c1.1-0.7,2.1-1.4,3.2-2.2c0.8-0.5,1.5-1.1,2.4-1.7c0.8-0.6,1.4-0.9,1.7-1l3.2-1.2l6-2.4L451.9,515
			L451.9,515z"
          />
          <path
            class="symbol"
            data-symbol="D"
            d="M564.5,483c5.7-1.6,11.4-2.2,17.3-1.9c5.9,0.4,11.4,1.7,16.5,3.9c5.2,2.3,9.7,5.5,13.6,9.8c3.9,4.3,6.7,9.5,8.4,15.6
			l0.9,8.2l3,10.8c0.2,0.8,0.2,1.9-0.1,3.4c-0.3,1.5-0.3,2.7-0.1,3.5c-0.3,1.6-0.5,3.2-0.6,4.8c-0.2,1.4-0.5,2.8-0.7,4.3
			c-0.3,1.5-0.7,3-1.2,4.5c-0.2,1-0.7,2.2-1.4,3.6s-1.6,2.8-2.7,4.1c-1.1,1.4-2.2,2.6-3.5,3.6c-1.2,1-2.5,1.8-3.8,2.1l-3.1,0.9
			c-1.2,0.3-2.4,0.8-3.5,1.5c-1.1,0.7-2.2,1.3-3.2,2c-1,0.7-2,1.3-2.7,1.9c-0.8,0.6-1.3,0.9-1.7,1l-4.4,1.2
			c-1.9,0.5-3.4,1.2-4.6,2.1c-1.2,0.9-2.3,1.5-3.3,1.7l-5.9,1.7l-14.1,4l-2.8,0.8c-0.6,0.5-1.1,1-1.5,1.5c-0.9,0.8-1.9,1.4-2.9,1.7
			l-5.1-0.5l-4.6,1.3l-3.9,3c-0.8,0.2-1.9,0.1-3.3-0.2c-1.5-0.4-2.7-0.8-3.7-1.4c-0.4,0.1-1.1,0.3-2,0.5c-0.9,0.2-1.8,0.3-2.7,0.2
			c-0.9,0-1.8-0.3-2.6-0.7c-0.8-0.4-1.4-1.2-1.7-2.3c-0.2-0.9-0.1-1.8,0.5-2.8c0.5-1,1.2-1.9,2.1-2.7c1-1,2.1-2,3.4-3.1l4.6-1.3
			l7.2-4c1,0.1,1.9,0.2,2.7,0.3c1.6,0.2,2.8,0.1,3.8-0.2c1.1-0.3,1.8-0.9,2.2-1.8c0.4-0.9,0.6-1.8,0.6-2.9c0-1.1-0.1-2.1-0.4-3.2
			c-0.2-1-0.5-1.9-0.6-2.6l-0.9-3.1l-1.9-6.9c-0.1-0.4-0.5-1-1.2-1.8c-0.3-0.4-0.7-0.7-1-1l-2.8-10.2c-0.2-0.9-0.3-2-0.3-3.5
			s-0.1-3-0.6-4.6l-1.8-6.4l-2.2-7.7c-0.8-3-2-5.3-3.6-7s-3.9-2.2-7.1-1.3c-0.7,0.2-1.2,0.4-1.6,0.7c-0.4,0.3-0.8,0.6-1.1,0.8
			c-0.3,0.3-0.7,0.5-1.1,0.8c-0.4,0.2-1,0.5-1.8,0.7l-5,0l-3.1,0.9c-0.8,0.2-1.8,0.1-3.2-0.3c-1.3-0.4-2.1-0.9-2.3-1.6
			c-0.5-1.6,0-3.3,1.5-5c1.5-1.7,3.2-3.3,5.3-4.8s4.1-2.7,6.2-3.7c2.1-1,3.5-1.6,4.3-1.8l2.8-0.8c0.9-0.2,1.8-0.2,2.7,0.2
			c0.9,0.4,1.8,0.5,2.7,0.2c0.8-0.5,1.5-1,2.2-1.5c0.6-0.4,1.2-0.8,1.9-1.2c0.7-0.4,1.2-0.7,1.8-0.9l6.1-1.7l3.1-0.9
			c2.1-0.8,4.1-1.6,5.9-2.4c1.5-0.6,2.9-1.2,4.4-1.8S564,483.1,564.5,483L564.5,483z M561.3,501c-0.3,0.1-0.9,0.5-1.8,1.2
			c-0.9,0.7-1.7,1.6-2.5,2.6c-0.8,1.1-1.5,2.1-2.1,3.2c-0.6,1.1-0.8,2-0.5,2.8c0.3,0.2,0.6,0.4,0.9,0.6c0.6,0.5,1,1.2,1.3,2.1
			l1.3,4.6l2.5,9l5.7,20.1l2.2,7.7c0.7,0.8,1.2,1.6,1.6,2.5c0.4,1,0.8,1.8,1.2,2.7c0.4,0.8,0.9,1.5,1.5,2c0.6,0.5,1.6,0.7,2.8,0.6
			l1.8-0.5c0.3-0.1,0.9-0.3,1.7-0.7s1.6-0.7,2.5-1.1s1.8-0.7,2.6-1.1c0.8-0.3,1.5-0.6,1.9-0.7c4.7-1.4,8.3-3.6,11-6.5
			c2.7-2.9,4.6-6.2,5.8-9.8c1.2-3.6,1.8-7.3,1.8-11.1c0-3.8-0.4-7.2-1.1-10.3c-0.6-2.4-1.9-5.2-4.1-8.2c-2.2-3-4.5-5.8-7.1-8.2
			c-2.6-2.4-5-4.1-7.3-5.2c-2.3-1.1-3.8-0.9-4.6,0.5c-0.8-0.2-1.5-0.5-2.2-0.8c-0.6-0.2-1.2-0.4-1.8-0.5s-1-0.2-1.4-0.1l-1.5,0.4
			L561.3,501L561.3,501z"
          />
          <path
            class="symbol"
            data-symbol="E"
            d="M689.2,454.5c0.7-0.1,1.3-0.2,2-0.2c0.6,0,1.4,0,2.2-0.1s1.9-0.2,3.1-0.3c1.2-0.1,2.7-0.3,4.6-0.7l5.3-2.4
			c0.8-0.4,1.7-0.8,2.4-1.1c0.6-0.4,1.3-0.6,1.9-0.9c0.7-0.2,1.1-0.4,1.5-0.4c0.8-0.1,1.8,0,3.1,0.3s2.4,0.8,3.4,1.3
			c1,1.3,1.9,2.9,2.5,4.9c0.6,2,1.4,3.6,2.3,4.9c0.4,0.3,0.9,0.8,1.5,1.7s1.2,1.7,1.8,2.5c0.7,1.1,1.5,2.3,2.3,3.4
			c0.4,2.1-0.8,3.4-3.6,4c-0.7,0.1-1.8-0.2-3.3-1c-1.5-0.8-3.5-1.7-5.8-2.6c-2.3-0.9-5.1-1.6-8.2-2.1c-3.1-0.5-6.7-0.4-10.7,0.3
			l-3,1.4c-0.5,1.1-1,2.1-1.5,2.9c-0.5,0.8-0.7,1.4-0.6,1.8l0.3,1.5c-0.1,0.9-0.1,1.8-0.2,2.6c-0.1,0.7-0.2,1.4-0.1,2.1
			c0,0.7,0,1.2,0.1,1.5l2.2,12c0.3,1.7,0.9,3.2,1.9,4.6c1,1.4,2.1,1.9,3.6,1.7c0.3-0.1,0.9-0.2,1.6-0.5s1.6-0.6,2.4-1
			c1-0.4,2-0.8,3-1.2l3.3-0.6c0.9-0.2,1.7-0.5,2.3-1.1s1.2-1.2,1.8-1.8s1.1-1.2,1.7-1.8c0.5-0.6,1.1-0.9,1.8-1
			c0.3-0.1,0.8,0.1,1.3,0.5c0.5,0.4,1.1,0.8,1.6,1.3l2,1.7l2,11c0.6,3.1-0.5,4.9-3.3,5.5c-1.2,0.2-2.3,0.2-3.3-0.2
			c-1-0.3-1.9-0.7-2.6-1.2c-1.3-0.2-2.4-0.5-3.4-0.9c-0.9-0.4-2-0.5-3.2-0.3l-2.7,0.5l-3.3,0.6c-2.8,0.5-4.5,1.3-5.1,2.5
			c-0.6,1.1-0.6,3.2-0.1,6.2l0.8,4.2c0.6,1.5,1.1,3,1.5,4.6s0.9,3,1.4,4.2s1.3,2.3,2.2,3.1c1,0.8,2.3,1.2,4,1.2l9.4-1.7
			c1.6-0.3,3.2-0.9,4.9-1.9s3.4-2.1,5.2-3.5c1.8-1,3.4-2,4.9-2.8c1.5-0.8,2.9-1.4,4.3-1.7c0.5,0.2,1,0.6,1.5,0.9
			c0.4,0.3,0.8,0.5,1.2,0.8s0.6,0.6,0.7,0.9c0.1,0.8,0,1.6-0.4,2.3s-1,1.6-1.6,2.4c-1.3,1.7-2.1,3.4-2.5,5s-0.8,3.1-1.2,4.5
			c-0.4,1.4-0.9,2.6-1.7,3.6c-0.7,1-2,1.7-3.8,2c-0.7,0.1-1.3,0.2-1.9,0.1s-1.2-0.2-1.9-0.3c-0.7-0.2-1.4-0.3-2.3-0.3
			s-1.9,0.1-3.1,0.3l-16.1,3.5l-5.4,2.4l-8.9,1.6l-5,0.9l-3.2,1.6l-5,1.3c-0.6,0.1-1.2,0-2-0.3c-0.8-0.3-1.5-0.7-2.1-1.2
			s-1.3-1-1.8-1.6c-0.5-0.5-0.8-1-0.8-1.3c-0.5-2.8,1.3-4.6,5.4-5.3c0.8-0.5,1.7-1,2.5-1.7c1.5-1.1,2.8-1.9,3.9-2.4
			c2.3-0.9,3.7-1.9,4.4-3c0.7-1.2,0.6-3.6,0-7.2c-0.1-0.6,0-1.2,0.3-1.9s0-1.3-0.9-1.8c-0.1-0.6-0.3-1.1-0.5-1.7
			c-0.2-0.6-0.5-1.2-0.8-1.7c-0.3-0.6-0.7-1.3-1.1-2l-0.6-3.2l-1.1-6l-0.8-4.2l-0.8-12l-0.5-2.8c-0.1-0.7-0.1-1.1,0-1.4
			c0.1-0.2,0.1-0.7,0-1.4l-1.6-8.9l-0.9-4.7l-0.9-5c-0.2-1-0.9-1.7-2.1-2.1s-2.1-0.6-2.7-0.5l-4.7,0.9c-2.1,0.4-3.8-0.2-5.1-1.8
			c-1.3-1.6-2.1-3.6-2.6-5.9c-0.1-0.7,0.1-1.3,0.6-2s1.1-1.1,1.8-1.2l5.5-1l3.3-0.6c0.7-0.1,1.7-0.4,3-0.8c1.4-0.4,3.1-0.8,5.2-1.2
			L689.2,454.5L689.2,454.5z"
          />
          <path
            class="symbol"
            data-symbol="F"
            d="M822.9,433.7c0.7-0.1,1.9,0,3.8,0.3s3.2,0.4,4,0.3l7.4-0.8l2.9-0.3l5.5-1.6l2.5,0.8l4.2-0.4l4.1-1.8l3.7-0.4
			c1.2-0.1,2.6,0.3,4,1.3c1.4,1,2.2,2.2,2.3,3.7l1,9.5c0.1,1.4-0.1,2.7-0.7,4.2c-0.6,1.4-1.6,2.2-2.9,2.4c-2.7-1.1-5.3-2.1-7.7-3
			c-2-0.7-4-1.4-5.9-2c-2-0.7-3.3-0.9-4.1-0.9l-4.7,0.5c-0.3,0-0.9,0.1-1.8,0.3c-0.8,0.1-1.7,0.3-2.5,0.6c-0.8,0.2-1.6,0.6-2.2,1.1
			c-0.6,0.5-0.9,1-0.8,1.7l0.6,6.1l0.8,7.6l0.6,6.1l0.3,2.7c0.1,0.9,0.5,1.5,1.4,1.9c0.8,0.4,1.8,0.6,2.9,0.6s2.2,0.1,3.2,0
			c1.1,0,1.8-0.1,2.3-0.1c2.6-0.3,4.5-0.8,5.7-1.5s2.2-1.6,2.7-2.4c0.6-0.9,1.1-1.6,1.4-2.3s0.9-1,1.7-1.1c1.2-0.1,2.3,0,3.1,0.5
			c0.8,0.5,1.3,1.1,1.4,1.7l-0.9,9.7c0.8,1.4,1.1,2.3,0.8,2.6c-0.3,0.4-0.4,0.9-0.3,1.6c0.1,1,0.5,1.8,1.1,2.3
			c0.4,0.3,0.7,0.5,1.1,0.7c0.2,1.5-0.2,2.9-1.1,4.4c-0.9,1.5-1.7,2.2-2.5,2.3c-1.2,0.1-2.2-0.3-2.8-1.2s-1.4-1.9-2.2-3
			s-1.8-2.1-3-3c-1.2-0.9-2.9-1.2-5.1-1c-0.9,0.1-1.9,0.2-3,0.4c-1.1,0.2-2.1,0.4-3,0.8s-1.7,0.9-2.3,1.7s-0.8,1.7-0.7,2.8l0.3,2.9
			c0.1,0.9,0,2-0.3,3.5c-0.3,1.4-0.4,2.9-0.2,4.6c0.1,1,0.2,1.8,0.4,2.3c0.2,0.5,0.3,1,0.6,1.4c0.2,0.4,0.4,1,0.7,1.7
			c0.2,0.7,0.5,1.9,0.6,3.4l0.1,1.4c0.2,1.5,0.7,2.9,1.5,4.2c0.9,1.3,1.7,2.3,2.6,2.9c1.1,0.3,2.3,0.6,3.8,0.8
			c1.4,0.2,2.8,0.4,4.1,0.6c1.5,0.3,3.1,0.5,4.7,0.7c0.6-0.1,1.2,0,1.9,0.2c0.3,0.1,0.7,0.1,1,0.1c0.7-0.1,1.4,0.1,2.2,0.6
			c0.8,0.5,1.2,1.1,1.3,1.8c0.1,1.2-0.2,2.3-1,3.1c-0.8,0.8-1.7,1.5-2.7,2s-2,0.9-3,1.2c-1,0.3-1.7,0.4-2,0.5
			c-1.6,0.2-2.9,0.2-3.9,0.1c-1-0.1-1.9-0.2-2.7-0.2c-0.8-0.1-1.5-0.2-2.1-0.3c-0.6-0.1-1.3-0.1-2-0.1l-9.3,1l-5.8,0.6
			c-2.9,0.3-5.4,0.3-7.5-0.1c-1.2-0.2-2.2-0.5-3-0.7c-1.6,0.2-2.7,0.3-3.4,0.4s-1.2,0.5-1.6,1.1c-0.2,0.2-0.6,0.5-1.2,0.7
			c-0.6,0.2-1.6,0.4-2.9,0.5l-3.4,0.3l-2.7,0.3c-1,0.1-2.3-0.2-4-0.9c-1.7-0.7-2.6-1.7-2.7-3.1c-0.1-1.1,0.3-2.1,1.2-2.8
			c0.9-0.8,1.9-1.4,3.1-1.8c1.2-0.4,2.3-0.7,3.5-0.9s1.9-0.3,2.3-0.3l1.9-0.2l4.2-0.4l4.7-0.5c0.3,0,0.7-0.1,1.2-0.3
			s0.9-0.4,1.5-0.7c0.5-0.3,1.1-0.6,1.8-1l1-0.1c1.5-0.2,2.3-1,2.7-2.5c0.2-0.7,0.2-1.5,0.1-2.4l-0.3-2.9l-0.3-2.9l-2.3-9.2l0.9-4.4
			c-0.3-1.1-0.7-2.1-1-3.1c-0.3-0.9-0.6-1.7-0.8-2.6c-0.2-0.8-0.3-1.4-0.3-1.8l-0.5-4.7l-0.9-8.8c0.1-1.1,0.2-2.2,0.3-3.3
			c0.1-0.9,0.2-1.8,0.2-2.7c0-0.9,0-1.5,0-1.8l-0.5-4.7c0-0.3-0.2-1.1-0.5-2.4s-0.8-2.6-1.3-4c-0.6-1.4-1.3-2.7-2.2-3.7
			s-1.8-1.5-2.8-1.4c-1.6,0.2-3.3,0.9-5.3,2.3c-2,1.4-3.6,2.6-5,3.5c-1.5,0.8-3.5,2.1-6.1,3.9s-4.8,3.4-6.8,5
			c-0.8,0.9-1.5,2.3-2,4.4c-0.5,2-0.9,4.2-1.2,6.4c-0.4,2.5-0.8,5.3-1,8.3l0.6,5.6c0.2,1.6-0.2,3.1-1.1,4.6c-0.9,1.5-2,2.9-3.2,4.3
			c-1.3,1.3-2.7,2.4-4.2,3.2s-2.8,1.3-3.8,1.4c-2.4,0.2-4.1-0.5-5.1-2.1c-1-1.7-1.6-3.2-1.8-4.7c-0.2-2.2-0.2-4.9,0.2-7.8
			c0.4-2.9,1-5.9,1.9-8.9c0.9-3,1.9-5.9,3-8.7c1.1-2.8,2.4-5.2,3.8-7.3c2-4.1,4.4-7.7,7.2-10.8c2.8-3.1,5.9-5.9,9.2-8.2
			c3.3-2.3,6.7-4.4,10.2-6.2c3.5-1.8,6.9-3.4,10.2-4.7c0.2-0.5,0.8-0.8,1.7-0.9s2-0.2,3.1-0.2C820,433.4,821.4,433.5,822.9,433.7
			L822.9,433.7z"
          />
          <path
            class="symbol"
            data-symbol="G"
            d="M976.9,425.1l3.2-0.1l4.8-0.1c2.4-0.1,4.4,0.9,6.1,2.8c1.7,2,3,4.1,4.1,6.6c1,2.5,1.8,4.8,2.3,7c0.5,2.2,0.8,3.5,0.8,4
			c0,0.9,0.1,1.6,0.2,2.1c0.1,0.5,0.3,1,0.5,1.4c0.2,0.5,0.5,0.8,0.7,1.2c0,0.3,0,0.7-0.1,1.2c-0.2,0.8-0.6,1.5-1.1,2.1
			c0,0.8,0,1.6-0.1,2.3s-0.5,1.1-1.2,1.1c-0.6,0-1.5-0.6-2.8-1.9c-1.3-1.3-2.6-2.7-4-4.2c-1.6-1.9-3.4-4-5.4-6.3
			c-1.3-0.6-3-1.4-5.1-2.1s-4.1-1.4-6.1-2.1c-2.4-0.7-4.8-1.4-7.4-2c-3.6,0.1-7.2,0.9-10.7,2.3s-6.7,3.5-9.5,6.2s-5.2,5.9-7.1,9.7
			c-1.9,3.8-3,8.1-3.2,12.8c0,2.3-0.1,4.1-0.5,5.5c-0.4,1.4-0.6,2.9-0.6,4.4c0,1.6,0.1,2.9,0.3,4c0.1,1.1,0.6,2.4,1.3,4
			c0.8,2.4,2,4.9,3.6,7.6c1.5,2.7,3.3,5.2,5.4,7.4c2,2.3,4.3,4.1,6.8,5.6c2.5,1.5,5.2,2.2,8,2.1l6.1-2.2c1.6,0,3.3-0.7,5.1-1.9
			c1.8-1.2,2.7-3,2.7-5.2l-0.1-3.4c-0.3-1.4-0.6-2.7-1-4.1c-0.4-1.4-0.9-2.8-1.6-4.3c-1-1.6-2.1-3.1-3.4-4.8
			c-1.3-1.6-2.5-2.6-3.4-3.1c-1.6-0.5-2.4-1.3-2.4-2.3c0-0.5,0.2-0.8,0.7-1.1s1.1-0.5,1.8-0.6c0.8-0.1,1.7-0.2,2.7-0.2l7.5-0.2
			l8.2-0.2l3.2-0.1c0.8,0,1.4-0.1,2-0.2c0.5-0.1,1-0.3,1.6-0.5s1.3-0.4,2.2-0.6c0.9-0.2,2.1-0.3,3.7-0.3c0.8,0.2,1.5,0.5,2.2,0.8
			c0.6,0.2,1.1,0.5,1.6,1s0.8,0.9,0.8,1.3c0,0.7-0.2,1.8-0.8,3.3c-0.5,1.5-1.1,2.3-1.8,2.3l-1.9,0c-2.4,0.1-4.3,1-5.8,2.9
			c-1.5,1.9-2.2,3.5-2.2,5l0.1,4.8l0.1,5.1c0,1.5-0.2,2.6-0.6,3.4c-0.4,0.8-0.6,2.1-0.6,3.8c0.2,0.9,0.4,1.8,0.6,2.7
			c0.1,0.8,0.2,1.7,0.2,2.6c0,1-0.2,1.8-0.5,2.7c0,0.8-0.2,1.5-0.7,2c-0.5,0.5-1,1-1.6,1.5c-0.7,0.5-1.5,0.9-2.3,1.4L971,523
			l-2.7,0.1c-0.5,0-1.2-0.1-2.1-0.2c-1-0.2-2-0.3-3.1-0.6c-1.1-0.3-2.2-0.5-3.4-0.6c-1.2-0.1-2.3-0.2-3.3-0.2l-4.6,0.1
			c-0.6,0-1.4-0.1-2.4-0.3s-2-0.5-2.9-1c-1.7-0.5-3.4-0.9-5.1-1.1c-1.7-0.2-3-0.3-3.9-0.2c-0.3-0.4-0.9-0.8-1.6-1.1
			c-0.8-0.3-1.5-0.6-2.4-0.9c-0.9-0.3-1.7-0.7-2.4-1.1s-1.3-1-1.7-1.8c-0.9-0.3-2.1-1.5-3.6-3.4c-1.5-2-2.9-4.1-4.3-6.6
			c-1.4-2.5-2.7-5-3.8-7.5s-1.6-4.6-1.7-6.3l-0.1-2.9c0-0.9-0.4-2-1-3.3s-1-2.4-1-3.3l-0.2-6.6l-0.1-4.6c0.6-1.8,1.2-3.5,1.8-5.1
			c0.4-1.4,0.8-2.8,1.2-4.2c0.4-1.4,0.5-2.6,0.5-3.5l1.3-2.9c-0.1-2.5,0.8-5.2,2.7-8.1s4.1-5.6,6.7-8.1c2.6-2.5,5.3-4.7,8.2-6.4
			s5.3-2.6,7.3-2.6c0.9-0.1,1.8-0.3,2.5-0.4c0.7-0.1,1.3-0.3,2-0.4c0.7-0.1,1.2-0.2,1.7-0.2l1.9,0c1.7,0,2.9-0.1,3.7-0.3
			c0.7-0.2,1.4-0.4,2.1-0.7c0.7-0.3,1.5-0.5,2.5-0.7s2.5-0.3,4.5-0.4l6.6-0.2L976.9,425.1L976.9,425.1z"
          />
          <path
            class="symbol"
            data-symbol="H"
            d="M1058.5,426.1l1.9,0.1c0.9,0,2,0.5,3.4,1.2c1.4,0.8,2.8,1.2,4.4,1.3l4.8-1.3l6.6,0.4l3.2,0.2c1.2,0.1,2.1,0.7,2.4,1.8
			c0.2,0.5,0.3,1,0.2,1.7c-0.1,2.4-0.6,4-1.3,5c-0.7,0.9-1.7,1.5-2.7,1.7s-2.2,0.3-3.4,0.3c-1.2,0-2.3,0.2-3.4,0.6s-2,1.2-2.8,2.5
			s-1.2,3.3-1.4,6.1c-0.1,0.9,0.1,2.3,0.4,4.3c0.3,2,0.5,3.4,0.4,4.3l-0.3,4.8c-0.1,2.3,0.5,4.3,1.9,6.1c1.4,1.8,3.2,2.7,5.6,2.9
			c0.9,0,1.7-0.1,2.4-0.4c0.5-0.1,0.8-0.2,1.1-0.5l6.1,1.4l5.1,0.3l2.9,0.2c0.9,0,1.8,0.2,2.7,0.3c1.8,0.3,3.1,1,3.8,1.9
			c0.6,0,1.1,0,1.5-0.2c0.4-0.1,0.7-0.2,0.9-0.2c0.5-0.2,0.9-0.5,1.4-0.8c0.5-0.3,0.8-0.5,1.1-0.5l6.6,0.4c0.7-0.5,1.4-1.2,2-2
			c0.6-0.8,0.9-1.7,1-2.6l0.1-2.4l0.6-10.9l-1.4-8.6l0.2-2.9c0.1-1.1-0.3-2-1.1-2.4c-0.8-0.5-1.6-0.9-2.5-1.3
			c-0.9-0.4-1.7-0.9-2.5-1.6c-0.8-0.7-1.1-1.9-1-3.5c0.1-1.4,0.8-2.6,2.3-3.7c1.4-1.1,3-1.6,4.7-1.5c1.4,0.6,2.9,1.2,4.5,1.8
			c1.3,0.4,2.8,0.8,4.4,1.2c1.6,0.4,3.3,0.6,5,0.7l1.9,0.1l4.8,0.3c1.1,0.1,2.4,0.3,3.9,0.6c1.4,0.4,2.8,0.9,3.9,1.6
			s2.1,1.6,2.9,2.7c0.8,1.1,1.1,2.5,1,4.2l-1,1.8l-3.9,0.6l-4.6-0.3c-0.8,0-1.8,0.6-3.1,2c-1.3,1.4-1.9,2.7-2,3.8l-0.7,11.4
			l-0.4,6.6c0,0.7,0,1.9,0.2,3.8c0.2,1.8,0.2,3.1,0.2,3.9c0,0.5-0.2,1-0.6,1.5s-0.7,1.1-0.9,1.6c-0.7,1.4-1,2.5-1,3.2l-0.3,4.8
			l-0.3,4.8c-0.1,1.7-0.1,3.4,0.1,5c0.2,1.6,0.2,3.1,0.2,4.6c-0.1,0.9-0.2,1.9-0.6,2.9s-0.6,2.2-0.6,3.7c-0.1,0.8-0.1,1.4-0.3,1.9
			c-0.1,0.5-0.2,0.9-0.3,1.3c-0.1,0.5-0.1,0.9-0.1,1.4s0.1,1.2,0.3,2c0.3,1.3,1.2,2.4,2.8,3.3c1.6,0.9,3.3,1.8,5.1,2.6
			c1.8,0.8,3.5,1.6,4.9,2.3s2.1,1.6,2.1,2.5c-0.1,0.8-0.3,1.4-0.9,1.9s-1.1,0.9-1.7,1.3c-0.7,0.4-1.5,0.8-2.4,1.2l-9.3-0.5l-4.8-0.3
			l-4.8-0.3l-7.9-2.3l-4.9,1.6c-0.3,0-0.8-0.1-1.5-0.2c-0.7-0.1-1.3-0.2-2-0.5s-1.2-0.6-1.7-1s-0.7-1.2-0.7-2.1
			c0.1-1.2,0.7-2.2,1.9-3c1.2-0.7,2.5-1.6,3.9-2.5s2.7-2.1,3.9-3.6c1.2-1.5,1.9-3.5,2.1-6.3l0.3-4.8l0.3-4.8l0.3-5.1l0.3-4.8
			c0-0.8-0.2-1.8-0.8-3s-1.3-1.9-2.2-1.9l-4.8-0.3l-6.1-0.4c-0.8,0-1.9-0.4-3.3-1.1c-1.4-0.7-2.5-1.1-3.2-1.1l-3.4-0.2l-4.8-0.3
			l-4.6-0.3l-3.4-0.2c-2.4-0.1-4.3,0.3-5.9,1.2c-1.5,0.9-2.4,2.6-2.5,5l-0.6,10.9c-0.8,1.9-1.2,3-1.4,3.4s-0.2,0.9-0.3,1.6
			c-0.1,0.8,0.1,1.7,0.5,2.7c0.4,1,0.6,2.2,0.5,3.5c-0.8,1.7-0.7,3.1,0.4,4.2c1,1.1,2.3,2.2,3.8,3.3c1.5,1,3,2.1,4.4,3.1
			s2.1,2.1,2,3.3c0,0.3-0.3,0.7-0.8,1.1c-0.5,0.4-1.1,0.6-1.8,0.8c-0.7,0.2-1.4,0.3-2.1,0.5s-1.2,0.2-1.5,0.2
			c-0.6,0-1.3-0.2-2.3-0.6c-0.9-0.3-2-0.7-3.2-1.2c-1.2-0.5-2.4-0.9-3.8-1.2s-2.6-0.6-3.9-0.7c-0.6,0.3-1.2,0.6-1.8,0.8
			s-1.1,0.3-1.6,0.4c-0.5,0.1-1,0.1-1.4,0.1l-1.9-0.1l-4.8-0.3l-2.9-0.2c-1.6-0.1-3.1-0.6-4.6-1.5s-2.2-2.3-2.1-4.2
			c0.1-1.5,0.4-2.9,0.8-4.4c0.5-1.5,1.6-2.1,3.3-2l3.3-0.7c0.9,0,2-0.5,3.2-1.7c1.2-1.2,1.8-2.1,1.9-2.8l0.4-6.6l0.3-4.8
			c0.1-0.9-0.2-2.5-0.7-4.6c-0.5-2.2-0.7-4.5-0.5-6.8l0.5-8c0-0.3,0.1-1,0.4-1.9s0.5-1.8,0.8-2.9c0.3-1,0.6-2,0.8-2.9
			s0.3-1.5,0.4-1.9l-1.4-8.1c0.1-0.9,0.4-2.1,1.1-3.5c0.4-0.8,0.8-1.6,1.2-2.5l0.7-11.4c0.1-0.8-0.6-1.5-1.8-2
			c-1.3-0.5-2.7-1-4.3-1.4c-1.6-0.4-3.1-0.7-4.4-1.1c-1.3-0.4-1.9-0.7-1.9-1c0.1-2.1,0.9-4,2.3-5.5c1.4-1.5,2.8-2.2,4.3-2.1l8.5,0
			L1058.5,426.1L1058.5,426.1z"
          />
          <path
            class="symbol"
            data-symbol="I"
            d="M1226.9,440.4c0.3,0,0.9,0.2,1.7,0.5s1.6,0.5,2.5,0.8c0.9,0.3,1.7,0.5,2.6,0.8c0.8,0.2,1.5,0.4,1.9,0.4
			c0.2,0,0.6,0.3,1.1,0.8s1,1.1,1.4,1.9c0.4,0.7,0.8,1.6,1.1,2.5s0.4,1.7,0.3,2.5c-0.1,0.5-0.8,0.8-2.2,0.9
			c-1.4,0.2-2.9,0.3-4.7,0.4c-1.7,0.1-3.3,0.3-4.7,0.6c-1.4,0.3-2.2,0.8-2.2,1.4l-1.5,11.8c0.1,0.9,0.3,1.8,0.5,2.6
			c0.1,0.7,0.3,1.4,0.4,2.1c0.1,0.7,0.2,1.2,0.1,1.6l-2.9,7.7c0,0.3,0,1,0,1.9s0.2,1.9,0.3,3c0.2,1,0.2,2,0.3,3s0.1,1.5,0,1.9
			l0.4,8.6c-0.3,2.4-0.8,4.5-1.6,6.5c-0.8,2-1.2,3.3-1.3,4.1l-0.6,4.7c-0.1,0.7-0.5,1.2-1.2,1.7c-0.7,0.4-1.2,1.8-1.5,4.2
			c-0.1,0.5,0,1,0.3,1.7s0.6,1.3,1.1,1.8c0.4,0.6,0.9,1.1,1.4,1.5c0.5,0.4,0.9,0.6,1.3,0.7l3.6,1.5c3,1.9,4.2,4.4,3.8,7.7
			c-0.2,1.3-1.2,2.3-3.2,2.9s-3.9,0.8-5.8,0.5l-2.9-0.4l-3.2-0.4l-3.2-0.4l-6-2.7c-0.7-0.1-1.7,0-2.9,0.1c-1.3,0.2-2.6,0.4-4,0.7
			c-1.4,0.3-2.7,0.5-3.9,0.7c-1.2,0.2-1.9,0.3-2.3,0.2c-0.7-0.1-1.6-0.6-2.6-1.5c-1.1-0.9-1.6-1.7-1.5-2.5c0.2-1.2,0.9-2.2,2.4-3
			c1.4-0.8,2.9-1.6,4.6-2.3s3.1-1.6,4.3-2.6c1.2-1,1.8-2.3,1.6-4.1c-0.7-1.8-0.7-3.1-0.2-3.9c0.6-0.8,0.9-1.6,1-2.4l0.6-4.5
			l1.5-11.3l3.2-24.4l-1.3-4.8c0.4-1.2,0.8-2.4,1.3-3.4c0.3-0.9,0.7-1.7,1-2.5c0.3-0.8,0.5-1.4,0.6-1.9c0.3-2.2,0.3-4,0.2-5.4
			c-0.2-1.3-0.5-2.4-0.9-3.3c-0.5-0.6-1.1-1.1-1.7-1.3c-0.7-0.3-1.3-0.5-2.1-0.6c-0.7-0.1-1.5-0.3-2.2-0.5c-0.8-0.2-1.5-0.5-2.1-1
			c-0.6-0.5-1.1-1.3-1.4-2.4c-0.3-1.1-0.3-2.7-0.1-4.8c0.1-0.5,0.2-0.9,0.3-1.3c0.2-0.3,0.3-0.6,0.6-1c0.2-0.3,0.5-0.4,1-0.4
			l3.2,0.4l6.1,0.8c0.4,0.1,1,0.2,1.6,0.4s1.2,0.4,1.7,0.7c0.5,0.3,1.1,0.6,1.8,0.9c0.7,0.1,1.5,0.1,2.6,0.2s2,0,2.9-0.1
			c1.2-0.1,2.4-0.1,3.6-0.2l1.8,0.2c0.4,0.1,1-0.1,1.8-0.5L1226.9,440.4L1226.9,440.4z"
          />
          <path
            class="symbol"
            data-symbol="J"
            d="M1297.4,479.4l0-2.9l2.2-9.1c0.1-0.3-0.1-0.7-0.6-1.2c-0.4-0.5-1-1-1.6-1.4s-1.3-0.9-2-1.2c-0.7-0.4-1.2-0.6-1.7-0.7
			l-4.7-0.9c-0.4-0.1-1.1-0.1-2.1-0.1s-1.9,0.1-2.9,0.2s-2,0.2-2.9,0.2s-1.6,0-1.9-0.1c-1.6-0.3-2.4-0.8-2.6-1.6
			c-0.1-0.8-0.2-1.5,0-2.2c0.2-1.1,1-2.2,2.3-3.2c1.3-1,2.8-1.8,4.4-2.5c1.6-0.6,3.2-1.1,4.7-1.5c1.5-0.4,2.5-0.5,2.9-0.4l1.8,0.3
			l4.7,0.9c1.1,0.2,2,0.5,2.6,0.8c0.6,0.4,1.2,0.7,1.6,1c0.5,0.3,0.9,0.6,1.2,0.9c0.3,0.3,0.9,0.5,1.5,0.6c0.4,0.1,1.8,0.2,3.9,0.3
			c2.2,0.1,4.6,0.2,7.2,0.3c2.6,0.1,5.2,0.2,7.6,0.4s4.3,0.4,5.5,0.6l3.3,0.6c0.7,0.1,1.6,0.4,2.7,0.9s1.6,1.4,1.3,2.8
			c-0.4,2-1.5,3.1-3.4,3.4s-3.9,0.5-6.2,0.7c-2.2,0.2-4.4,0.8-6.4,1.8s-3.4,3.2-4,6.7c-0.2,0.3-0.4,0.7-0.6,1.1
			c-0.2,0.3-0.3,0.7-0.4,1.1s-0.2,0.9-0.2,1.3c0.2,1.4-0.5,3.1-2,5c-1,2.5-1.7,4.4-1.9,5.9c0.3,0.3,0.6,0.6,0.8,1
			c0.4,0.5,0.7,1.3,1,2.2c-0.1,0.7-0.2,1.3-0.3,1.8s-0.1,1.1-0.1,1.5c-0.1,0.5-0.1,0.9-0.1,1.4c-0.2,2-0.4,3.9-0.8,5.5
			c-0.4,1.6-0.8,3.1-1.3,4.6c-0.4,1.5-0.8,2.9-1.2,4.4c-0.3,1.4-0.5,3-0.6,4.6l-2.1,8.3v2.8l-2,5c-0.1,1.9-0.9,4.9-2.3,8.8
			c-1.4,4-3.1,8.1-5.2,12.4s-4.1,8.4-6.3,12.2c-2.2,3.8-4,6.5-5.5,8.1c-2.5,2.2-5.5,3.7-9,4.6s-6.7,1.5-9.6,1.7
			c-2.9,0.2-5,0.3-6.3,0.3c-1.3,0-1,0.2,1,0.5l-3.3-0.6c-0.3-0.1-1.1-0.1-2.2-0.2c-1.1,0-2.4-0.1-3.8-0.2s-2.7-0.2-4.1-0.3
			s-2.4-0.3-3.2-0.4c-1.2-0.2-2.7-0.6-4.4-1.2c-1.7-0.6-3.4-1.3-4.9-2.3s-2.8-2.2-3.7-3.7c-0.9-1.5-1.2-3.3-0.8-5.4
			c0.1-0.7,0.8-1.6,2.1-2.7s2.6-2.3,4.1-3.4c1.6-1.3,3.6-2.7,5.8-4.1c0.4-0.5,0.9-0.8,1.3-0.9s0.8-0.2,1.2-0.4
			c0.5,0,0.9-0.1,1.2-0.1c0.7,0.1,1.3,0.3,2,0.6c0.7,0.3,1.4,1,2.1,2.2c1.5,1.4,2.6,2.8,3.5,4.1c0.8,1.3,1.8,2.5,2.8,3.5
			s2.2,2,3.5,2.8c1.3,0.8,3.2,1.5,5.7,1.9c0.8-0.4,1.5-0.9,2.3-1.3c0.7-0.5,1.4-0.8,2.2-1.2c0.8-0.3,1.6-0.7,2.4-1.1
			c2.4-1.6,4.4-3.6,6-5.9c1.6-2.3,3-4.7,4.1-7.4s2-5.3,2.7-8s1.2-5.3,1.7-7.7c0.4-0.3,0.8-0.8,1.2-1.7s0.7-1.8,0.9-3
			c0.2-1.1,0.4-2.2,0.4-3.4s0.1-2.2,0.1-3.1l0.6-3.2l1.7-9.4l4.7-25.6L1297.4,479.4L1297.4,479.4z"
          />
          <path
            class="symbol"
            data-symbol="K"
            d="M1455.3,488.4c2.3,0.6,4,2,5.1,4.2c1.1,2.2,1.4,4.1,1,5.7c-0.5,2-1.9,3.2-4,3.6c-2.2,0.4-4.5,0.5-6.9,0.2
			c-2.5-0.2-4.8-0.7-6.9-1.2c-2.1-0.6-3.4-0.9-3.7-1c-1.1,0.4-2.6,0.9-4.5,1.5c-2,0.6-3.5,1.2-4.6,1.8c-1.7,1-3.5,2.1-5.4,3.5
			c-1.9,1.4-3.8,2.7-5.5,4c-1.7,1.3-3.2,2.6-4.4,3.7c-1.3,1.2-2,2.1-2.2,2.8c-0.2,0.9-0.2,1.7,0.2,2.5c0.4,0.8,0.8,2,1.1,3.7
			c0.4,1.5,1,3,1.7,4.4s1.5,2.8,2.2,4s1.4,2.3,2.1,3.3c0.7,1,1.2,1.6,1.6,2.1c0.6,1,1.1,2.2,1.6,3.8c0.5,1.5,1.1,3.2,1.6,4.8
			c0.6,1.7,1.1,3.3,1.8,5s1.4,3,2.4,4.1c0.6,1.1,1.5,2.4,2.6,3.9c1.1,1.5,2.1,3,3.1,4.5c1.2,1.7,2.4,3.5,3.8,5.4l3.3,0.9
			c0.9,0.2,2.1,0.5,3.7,0.8c1.6,0.3,2.6,1.1,3.2,2.5c0.4,0.8,0.7,2.2,1,4.1s0.2,3.7-0.3,5.4c-0.5,1.7-1.7,3-3.5,4
			c-1.8,1-4.7,1-8.6,0.1c-1.9-0.5-3.3-0.9-4.4-1.3c-1.1-0.3-2-0.8-2.8-1.2c-0.8-0.5-1.6-1-2.4-1.7c-0.8-0.7-1.9-1.6-3.2-2.6
			l-2.6-2.7c-1.3-0.9-2.4-2.2-3.3-3.7c-0.9-1.5-1.8-3.3-2.5-5.3c-1.6-3.1-3.4-6.9-5.3-11.2s-3.6-8.4-5.1-12.2
			c-0.8-3.2-1.7-6.2-2.7-9.1s-2-5.3-3.2-7.1c-1.3-1.7-2.7-2.7-4.1-3c-0.7-0.2-1.4,0-2.3,0.4c-0.9,0.5-1.4,1-1.6,1.5
			c-0.5,1.9-0.7,3.9-0.7,6.1c0.1,2.2-0.1,3.9-0.4,5.2l-0.9,3.3l0.5,3.5l-1.2,4.6c-0.8,3.1-0.6,5.5,0.8,7.4c1.4,1.9,3,3.5,5,4.7
			s3.8,2.3,5.4,3.1s2.3,1.8,2,2.9c-0.3,1.3-1,2.3-1.9,3s-1.8,1.2-2.9,1.5c-1,0.3-2,0.5-2.8,0.5s-1.4,0-1.7-0.1l-2.1-1.4
			c-0.7-0.2-1.1-0.2-1.3,0.1c-0.2,0.2-0.6,0.3-1,0.2l-8.2-2.2c-1-0.4-1.8-0.8-2.6-1.4c-0.7-0.4-1.4-0.9-2.1-1.4
			c-0.7-0.5-1.2-1.1-1.4-1.8l-4.6-1.2c-0.4-0.1-1.5-0.2-3.3-0.3c-1.8-0.1-3.7-0.1-5.8-0.2s-4.2-0.1-6.3-0.3s-3.7-0.4-4.8-0.7
			l-1.8-0.5c-0.9-0.2-1.7-0.7-2.6-1.5s-1.2-1.8-1.1-3c0.2-0.7,0.7-1.5,1.5-2.5c0.8-1,1.8-2,2.9-2.9s2.2-1.7,3.4-2.3
			c1.1-0.6,2.1-0.8,3-0.6l1.3,0.3l5.4,0.1l5.9,1.6c0.7,0.2,1.1-0.1,1.4-0.8c0.3-0.7,0.6-1.4,0.8-2.2l0.8-3.1
			c0.3-0.6,0.6-1.2,0.9-1.9c0.6-1.4,0.8-2.7,0.7-4c0-2.2,0.4-4.3,1.2-6.1c0.8-1.8,1.4-3.5,1.8-4.9l0.8-3.1l1.7-6.4
			c0.4-1.4,0.7-2.8,1-4.2c0.3-1.4,0.2-2.6-0.3-3.5l2.1-7.7c0.6-1.5,1.2-2.8,1.8-3.9c0.5-1,1-2,1.3-3s0.6-1.7,0.8-2.2
			c0.9-3.4,0.7-6-0.7-7.8c-1.4-1.8-3.1-3.2-5.1-4.4s-3.8-2.2-5.4-3.1s-2.2-2.4-1.6-4.4c0.3-1.3,1-2.3,1.9-2.9c0.9-0.6,2-1.1,3.1-1.3
			c1.1-0.2,2.2-0.3,3.4-0.1s2.1,0.3,3,0.5l2.6,0.7l1.8,0.5c0.3,0.1,0.9,0.3,1.7,0.7c0.8,0.4,1.7,0.8,2.6,1.2
			c0.9,0.4,1.8,0.8,2.6,1.1c0.8,0.4,1.5,0.6,1.9,0.7l4.6,1.2l6.8,0.4c1.3,0.4,2.7,1.1,4.3,2.4c1.5,1.2,2.1,2.8,1.6,4.6
			c-0.2,0.7-0.4,1.2-0.6,1.8s-0.5,0.7-1,0.6c-1-0.3-2-0.2-3.1,0c-1.1,0.3-2.1,0.7-3.2,1.3s-2,1.4-2.8,2.4s-1.3,2.1-1.6,3.2l-0.8,3.1
			c-0.3,1.1-0.5,3-0.6,5.6c0,1.4-0.1,2.9-0.2,4.5l-0.3,1.3c0,0.7,0.2,1.3,0.4,1.9c0.1,0.5,0.3,1,0.5,1.4c0.2,0.4,0.5,0.7,0.8,0.8
			c0.6,0.2,1.1,0,1.6-0.3s1.1-0.8,1.8-1.3c2.5-1.9,5.6-4.8,9.2-8.8c1.8-1.8,3.5-3.5,5.2-5.4c1.7-1.8,3.3-3.5,5.1-5.1
			c2.3-2.4,4.4-4.3,6.4-5.5c2-1.2,3.5-1.7,4.6-1.4l2,0.5c0.9,0.2,1.6,0.2,2.1-0.1c0.5-0.3,1.4-0.3,2.7,0L1455.3,488.4L1455.3,488.4z
			"
          />
          <path
            class="symbol"
            data-symbol="L"
            d="M1561.2,528.2c1.5,0.6,2.7,1.5,3.7,2.9s1.2,2.8,0.6,4.4l-1.2,3c-1.5,0.4-2.9,0.7-4.2,0.9c-1.1,0.2-2.1,0.4-3.1,0.5
			s-1.7,0.2-2,0l-4.8-1.8c-2.2-0.9-4.2-0.9-6-0.1s-3.1,2.3-3.9,4.5l-1.8,4.8c-1.1,2.8-2.7,5.8-4.6,9c-2,3.1-3.9,6.1-5.7,8.9
			l-3.4,8.7l-3.4,8.9l-1.7,4.4l-0.7,1.7c-0.1,0.3-0.3,0.9-0.4,1.8c-0.2,0.8-0.2,1.8-0.1,2.7c0.1,0.9,0.2,1.8,0.6,2.6
			c0.3,0.8,0.9,1.3,1.7,1.7l4.4,1.7l4.8,1.8l4.4,1.7c0.3,0.1,1.3,0.1,3,0.1c1.7-0.1,3.5-0.2,5.5-0.4c2-0.2,4-0.5,6-0.8
			s3.5-0.7,4.5-1c1.1-0.4,2.2-0.8,3.4-1.2s2-0.4,2.8-0.1c1.2-1.2,2-1.9,2.4-2.1c0.4-0.2,1-0.1,1.8,0.2c0.6,0.2,1.4,0.9,2.3,2.1
			c0.9,1.1,1.2,2,1,2.7c-0.1,0.3-1.1,1.4-2.8,3.2s-3.8,3.8-6.1,6c-2.3,2.2-4.7,4.3-7.1,6.2c-2.4,1.9-4.5,3-6.1,3.4
			c-0.5,0.3-1.5,0.2-3.1-0.2c-1.6-0.4-3.3-0.9-5-1.5c-1.9-0.6-4.1-1.4-6.6-2.3l-4.4-1.7l-3-1.2c-2.2-0.9-4.4-1.9-6.4-3.2
			c-2.1-1.3-3.6-1.8-4.5-1.6l-2.2-0.3l-8.3-1.2c-0.3-0.1-0.9-0.5-1.9-1c-0.9-0.5-1.9-1.2-3-1.9s-2.1-1.3-3-1.9
			c-0.9-0.5-1.6-0.9-1.9-1c-0.8-0.3-1.7-0.5-2.5-0.6c-0.8-0.1-1.5-0.1-2.2-0.1c-0.8,0.1-1.5,0.1-2.2,0.1c-1.2-0.5-1.9-1-2.3-1.8
			s-0.6-1.5-0.5-2.4c0-0.8,0.2-1.6,0.4-2.4s0.4-1.3,0.6-1.8c0.9-2.2,2.1-3.3,3.7-3.3s3.4,0.2,5.2,0.5c1.8,0.3,3.6,0.2,5.4-0.1
			s3.2-2,4.4-5l5.1-13.3l5.9-11.4l2.2-5.7c0.2-0.4,0.5-1.2,1.1-2.4c0.6-1.2,1.2-2.4,2-3.7c0.8-1.5,1.7-3.1,2.8-4.8
			c0.3-0.8,0.4-1.7,0.2-2.7c-0.2-0.9-0.2-1.7,0.1-2.3l0.7-1.7c1.1-3,1.5-5.2,1-6.6s-1.2-2.7-2-3.7c-0.9-1-1.6-2.1-2.2-3.2
			s-0.5-2.7,0.4-5c0.4-1.1,1-1.9,1.8-2.5s1.6-1,2.4-1.3c0.8-0.3,1.6-0.4,2.3-0.4c0.7,0,1.2,0.1,1.6,0.2L1561.2,528.2L1561.2,528.2z"
          />
          <path
            class="symbol"
            data-symbol="M"
            d="M1746.1,613.1c3.4,1.9,5.1,4.1,5.1,6.6c0,2.5-0.5,4.8-1.7,6.8c-0.2,0.3-0.6,0.6-1.4,1s-1.7,0.6-2.6,0.9
			c-1,0.2-1.9,0.4-2.8,0.5c-0.9,0.1-1.5,0-1.9-0.2l-2.5-1.4c-0.7-0.4-1.3-0.7-1.9-0.8c-1.4-0.5-2.5-0.7-3.2-0.5
			c-1.5-0.8-2.8-1.1-3.9-0.9c-1.1,0.2-2.1,0.7-3.1,1.5s-1.8,1.7-2.5,2.9c-0.7,1.2-1.3,2.2-1.9,3.2l-5.6,9.9l-6,10.7
			c-0.6,1.1-0.9,2-1,2.8s-0.1,1.4-0.2,2c0.2,0.9,0.1,1.6-0.2,2.2c-0.3,0.6-0.6,1.3-1,2l-3.2,4.6c-0.8,1.4-1.3,2.8-1.7,4.4
			c-0.4,1.5-0.7,2.6-1,3.2l-0.6,5.2l-2.3,4.2c-1,1.8-0.8,3.5,0.5,5.2s2.9,3.3,4.7,4.9s3.4,3.1,4.8,4.7c1.4,1.5,1.7,3,0.9,4.5
			c-1.7,3-3.9,3.7-6.7,2.1l-5.3-3c-0.7-0.4-1.4-0.8-2.3-1.2c-0.8-0.4-1.8-0.6-2.8-0.5l-11.8-7.8l-4.2-2.3l-2.8-1.6
			c-0.3-0.2-1-0.5-2.2-1s-2.4-1.1-3.7-1.7c-1.3-0.6-2.5-1.2-3.7-1.7c-1.1-0.5-1.9-0.8-2.2-1c-1.5-0.8-2.5-1.5-3-2.1
			s-0.4-1.5,0.3-2.8c0.8-1.4,2-2.7,3.7-3.9c1.7-1.2,3.5-1.2,5.6-0.1l2.8,1.6l5.8,3.2c0.7,0.4,1.4,0.5,2.1,0.3
			c0.7-0.2,1.3-0.6,1.8-1.1s1-1.1,1.4-1.8c0.4-0.6,0.7-1.1,0.9-1.5c0.7-1.3,1.3-3,1.8-5s0.9-3.5,1.3-4.2l3-5.3l2.5-4.5l2.3-4.2
			c0.2-0.4,0.5-1.1,0.9-2.1s0.7-2.1,0.9-3.2c0.2-1.1,0.4-2.1,0.4-3s-0.1-1.4-0.5-1.7c-0.2-0.1-0.8,0.1-2,0.5s-2.5,1.2-4.1,2
			c-1.6,0.9-3.4,1.8-5.3,2.9c-1.9,1-3.8,2.1-5.7,3.2c-1.8,1-3.5,2-5,2.8s-2.7,1.5-3.4,2c-1.1,0.2-2.2,0.6-3.3,1.2
			c-1.1,0.7-2.1,1.4-3.1,2.2c-1,0.8-1.9,1.7-2.8,2.5c-0.9,0.9-1.7,1.6-2.3,2.1c-1.6,0.9-3,1.7-4.3,2.3s-2.4,1.1-3.3,1.5
			c-1.7,1.2-2.8,1.9-3.2,2c-0.4,0.1-1.1-0.1-1.8-0.5c-1.2-0.7-1.7-1.5-1.6-2.5c0.1-1,0.1-2.5,0.1-4.5l3.9-9.7
			c0.3-2.2,0.6-4.4,0.7-6.8c0.2-2.4,0.4-4.7,0.8-7.1s0.8-4.6,1.4-6.8c0.6-2.2,1.3-4.1,2.3-5.8l0.9-1.6c0.2-0.4,0.4-1.1,0.7-2.2
			c0.2-1.1,0.4-2.2,0.6-3.4c0.1-1.2,0.2-2.2,0-3.2c-0.1-1-0.4-1.6-0.8-1.8c-0.3-0.2-0.9,0.1-1.9,0.7s-2.1,1.4-3.2,2.3
			s-2.2,1.9-3.2,3s-1.7,1.9-2,2.5l-4.2,7.4l-1.6,2.8c-1.8,2.3-3.5,4.4-5.1,6.3c-1.3,1.6-2.5,3.2-3.7,4.7s-1.9,2.5-2.1,2.9
			c-1.6,2.8-2.1,4.9-1.7,6.5c0.4,1.5,1.2,2.8,2.2,3.8s1.9,1.9,2.8,2.7c0.8,0.8,0.8,2,0,3.5c-0.6,1.1-1.3,1.8-2,2.1s-1.5,0.4-2.2,0.3
			s-1.4-0.3-2-0.6c-0.6-0.3-1.1-0.5-1.4-0.7l-5.8-3.2l-12.7-10.4l-2.8-1.6l-2.5-1.4c-0.4,0-0.8,0-1.3-0.1c-0.8,0-1.5-0.2-2.2-0.6
			l-2.8-1.6c-0.3-0.2-0.7-0.5-1.2-0.9c-0.5-0.4-1-0.9-1.5-1.5c-0.4-0.6-0.8-1.1-1.1-1.7s-0.3-1-0.1-1.4c0.6-1,1.4-1.6,2.6-2
			s2.3-0.5,3.6-0.4c1.2,0,2.3,0.2,3.3,0.5s1.7,0.5,1.9,0.7l3,1.7c0.3,0.2,0.9,0.5,1.9,0.8c0.9,0.4,2,0.8,3.1,1.1
			c1.2,0.4,2.5,0.9,4,1.5c1.1-0.3,2.4-1.1,4.1-2.5c1.7-1.3,3.2-2.8,4.8-4.3c1.5-1.5,2.9-3,4.1-4.3s1.9-2.2,2.1-2.6l3-5.3l0.9-1.6
			c0.5-0.1,1-0.5,1.5-1c0.6-0.5,1.1-1.1,1.6-1.8c0.5-0.7,1-1.5,1.5-2.3l3.8-6.7c0.2-0.4,0.6-0.9,1.2-1.7s1.2-1.4,1.8-2.1
			c0.8-0.7,1.6-1.5,2.5-2.3l2.3-4.2c0.5-0.5,1.1-1,1.6-1.4c0.5-0.4,0.9-0.8,1.4-1.4c0.4-0.5,0.8-1.2,1.2-1.8l1.8-3.3
			c0.2-0.3,0.4-1,0.7-2.2s0.5-2.5,0.8-3.8c0.3-1.5,0.7-3.2,1.1-5c-0.1-1-0.4-2-1-3.1c-0.6-1.1-1.1-2.2-1.5-3.2
			c-0.3-0.8-0.8-1.7-1.4-2.6s-0.9-2-0.9-3.1l0.9-1.6c0.3-0.6,0.8-0.9,1.5-0.9c0.7,0,1.4,0.1,2.2,0.3c0.8,0.2,1.7,0.6,2.7,1.1
			l3.7,2.1c1,0.5,1.8,0.9,2.3,1c0.5,0.1,1,0.1,1.3,0c0.3-0.1,0.6-0.2,0.8-0.3s0.4-0.1,0.7,0.1l7,3.9l2.8,1.6l3.2,4
			c-0.5,3.1-1.2,6.9-2.1,11.2s-2,8.9-3.1,13.7s-2.3,9.6-3.5,14.3c-1.2,4.7-2.2,9-2.9,12.9s-1.4,7-1.7,9.5s-0.3,3.8,0.2,4.1
			c0.5-0.4,1-0.7,1.5-0.9c0.4-0.3,0.9-0.5,1.5-0.8s1.1-0.4,1.6-0.6c4.3-4.2,9.3-8.3,14.8-12.3s10.7-7.9,15.5-11.8
			c1.2-2.1,2.8-3.5,4.9-4.4c2.1-0.8,4.4-1.9,7-3.2c0.7-0.5,1.4-1.3,2.1-2.4c0.7-1.1,1.6-2.1,2.4-3.1c0.9-1,1.9-1.8,3.1-2.5
			c1.2-0.7,2.5-0.8,4.1-0.4l5.8,3.2l1.4,2.3c1.4,0.8,2.7,1.4,4.1,1.8c1.3,0.4,2.6,0.8,3.9,1.1
			C1743.1,612.7,1744.5,612.9,1746.1,613.1L1746.1,613.1z"
          />
        </g>
        <g id="lettres_bas">
          <path
            class="symbol"
            data-symbol="N"
            d="M187.4,867.3c1.1-0.8,2.1-1.4,3-1.9c0.8-0.5,1.8-0.7,3-0.5c1.6-0.1,2.8,0,3.5,0.4s1.6,0.6,2.8,0.7c1.9,1.3,3.5,1.7,5,1.2
			c1.5-0.5,3.1-0.7,4.7-0.5c1.7,0.1,3.4,0.5,5.3,1s3.9,1.2,5.9,1.8c2,0.7,4,1.3,5.9,1.9c1.9,0.6,3.6,1,5.2,1.2
			c2.4,0.5,4.6,1.3,6.6,2.3s4.4,1.2,7.2,0.9c0.8,0.1,2.5,0.3,5.1,0.9c2.6,0.5,5.4,1,8.5,1.5c3,0.5,5.9,0.9,8.5,1.1
			c2.6,0.2,4.4,0,5.4-0.7c0.6-0.4,0.9-0.9,1-1.3c0-0.4-0.1-0.8-0.4-1.2s-0.6-0.7-1-1c-0.4-0.3-0.7-0.6-0.9-0.8l-7-9.8
			c-0.6-0.8-1-1.9-1.2-3.2c-0.3-1.4-0.9-2.8-2-4.3l-7.8-11c-0.5,0-1.3-0.1-2.4-0.3c-1-0.2-2.1-0.4-3.2-0.6c-1.1-0.2-2.1-0.4-3-0.5
			c-0.9-0.1-1.5-0.1-1.8,0.1l-2.5,1.8c-0.5,0.4-1.2,0.7-2.1,0.8c-0.9,0.2-1.8,0.3-2.7,0.3c-0.9,0-1.7-0.1-2.4-0.2s-1.1-0.2-1.2-0.4
			l-2.9-4c-0.6-0.9-0.9-1.7-0.9-2.5s0.3-1.5,0.7-2.2c0.4-0.7,1-1.2,1.7-1.8c0.7-0.5,1.3-1,1.8-1.4l2.5-1.8c0.6-0.4,1.5-0.9,2.6-1.5
			s2.2-1.2,3.4-1.8c1.2-0.6,2.3-1.2,3.4-1.8c1.1-0.6,2-1.2,2.8-1.7l18.1-12.9c1.7-1.2,3.6-2,5.7-2.3c2.1-0.3,3.8,0.5,5.1,2.3
			c0.5,0.7,0.9,1.3,1.1,1.8c0.2,0.5,0.4,1.4,0.4,2.5c-1.1,2.6-2.7,4.6-4.6,5.8c-2,1.2-3.8,2.4-5.5,3.7l-2.9,3.6l-2.2,1.6
			c-0.7,0.5-0.9,1.4-0.7,2.8c0.2,1.3,0.8,2.6,1.6,3.7l3.6,5.1l0.7,1l5.5,7.7l4.4,6.1c0.4,0.5,1.1,1.2,2.2,2c1.1,0.8,1.8,1.5,2.2,2
			l5.2,7.3l6.8,14.2c0.4,0.5,1,1.1,1.8,1.8c0.8,0.7,1.7,1.4,2.7,2.1c1,0.7,1.8,1.4,2.6,2c0.8,0.7,1.4,1.3,1.8,1.8
			c0.2,0.3,0.7,1,1.2,1.9s1.1,1.8,1.6,2.8c0.5,1,0.8,1.9,1,2.7c0.2,0.9,0,1.5-0.5,1.9c-0.4,0.2-0.9,0.7-1.8,1.2
			c-0.8,0.6-1.7,1.2-2.6,1.7c-0.9,0.5-1.8,0.9-2.6,1.2s-1.3,0.3-1.4,0c-2.1-0.3-4.2-1.1-6.2-2.2s-4.1-1.8-6-2
			c-1.9-0.6-3.6-1.1-5.1-1.3s-2.9-0.5-4.3-0.7c-1.4-0.2-2.8-0.5-4.1-0.8c-1.4-0.3-2.7-0.9-4.1-1.8c-2.5-1.5-5.5-2.5-8.9-3.1
			c-3.4-0.6-6.9-1.1-10.6-1.5c-3.6-0.4-7.2-0.9-10.8-1.4c-3.6-0.5-6.8-1.4-9.6-2.7c-0.2-0.3-0.6-0.6-1.3-1s-1.4-0.7-2.2-0.9
			c-0.8-0.2-1.6-0.3-2.5-0.4c-0.9,0-1.6,0.2-2.2,0.6c-1.6,1.2-1.6,3.1,0.3,5.7l7,9.8l3.6,5.1l6.2,6.6c1.2,1.7,2,3,2.4,3.7
			s1,1.8,1.9,3.1c1.2,1.7,2.6,2.5,4.1,2.7c1.6,0.1,3.3-0.1,5.1-0.8c1.8-0.6,3.6-1.4,5.4-2.4c1.9-1,3.6-1.8,5.4-2.5
			c1.7-0.7,3.4-1,4.9-1c1.5,0,2.8,0.7,3.8,2.1c0.5,0.7,0.6,1.3,0.2,1.7s-0.8,0.9-1.4,1.3L253.8,932l-7.5,7.1
			c-1.1-0.1-2.2,0.2-3.4,0.8s-2,1.2-2.6,1.6l-2.5,1.8c-1.8,1.3-3.3,2-4.6,2.2c-1.2,0.1-2.5,0.7-3.8,1.6l-4.7,3.3l0,2.2l-2.6,1.9
			c-0.2-0.3-0.7-0.9-1.3-1.7c-0.6-0.8-1.2-1.6-1.8-2.4c-0.8-0.9-1.5-1.9-2.3-2.9c-0.5-0.7-0.5-1.6-0.1-2.7c0.4-1.1,0.9-1.8,1.5-2.3
			c1.4-1,2.3-1.7,2.8-2s0.8-0.6,0.9-0.7c0.1-0.1,0.2-0.2,0.2-0.2c0,0,0.3-0.2,0.7-0.6c0.4-0.4,1.3-1,2.4-1.8s3-2.1,5.4-3.8
			c0.6-0.4,1-1,1.1-1.7c0.2-0.7,0.2-1.4,0.1-2.1c-0.1-0.7-0.3-1.4-0.6-2c-0.3-0.6-0.6-1.1-0.8-1.3c-0.4-0.2-0.8-0.5-1.1-0.8
			c-0.6-0.3-1.2-0.8-1.7-1.5c-0.9-1.3-1.8-2.3-2.5-3s-1.4-1.3-2.1-1.8c-0.6-0.5-1.2-0.9-1.7-1.3c-0.5-0.4-0.9-0.9-1.4-1.6
			c-0.4-0.6-0.9-1.4-1.4-2.3c-0.5-1-0.9-1.8-1.2-2.7c-0.4-1-0.7-2.1-1.1-3.2l-6.2-8.7c-0.4-0.5-0.9-1.1-1.5-1.7
			c-0.6-0.6-1.5-1-2.7-1.2c-0.7-1-1-1.8-0.7-2.4c0.1-0.4,0.3-0.6,0.6-0.8c-0.3-0.3-0.9-0.6-1.5-1.1c-0.7-0.4-1.4-0.8-2.1-1.2
			c-0.7-0.4-1.4-0.8-2-1.1c-0.6-0.4-1-0.7-1.2-0.9c-1.3-1.8-3-2.7-5.1-2.7c-2.1,0-4.3,0.2-6.6,0.6c-2.3,0.3-4.6,0.4-6.8,0.3
			c-2.2-0.1-4.1-1.3-5.6-3.5c-0.3-1.1-0.5-2.1-0.6-3.1c-0.1-0.8-0.1-1.7-0.1-2.5c0-0.8,0.3-1.4,0.9-1.8L187.4,867.3L187.4,867.3z"
          />
          <path
            class="symbol"
            data-symbol="O"
            d="M355.4,764.8l5.8-1.2l2.9-1.4c1.4-0.2,2.8-0.4,4-0.5c1.1-0.1,2.2-0.2,3.3-0.2c1-0.1,1.8,0,2.2,0.1
			c1.8,0.1,3.8-0.1,5.9-0.5s4.1-0.2,6,0.5c1.1,0.3,2.4,1.1,4,2.3c1.6,1.2,3,2,4,2.3c4.6,1.2,8,2.8,10.1,5c2.1,2.1,3.7,4.2,4.7,6.2
			l4.2,9.7l2,4c0.5,1.1,0.9,2.8,1.1,5.3c0.2,2.5,0.3,5.2,0.3,8c0,2.8,0,5.5,0,7.9c0,2.4,0,4,0,4.7c0.3,2.3-0.1,4.6-1.1,6.7
			s-2.2,4-3.5,5.5c-1.6,3.3-3.2,6.3-4.6,9c-1.5,2.7-3.6,4.8-6.4,6.1c-1.1,0.6-2.3,1-3.6,1.3c-1.2,0.3-2.8,0.9-4.7,1.8
			c-0.4,0.2-0.9,0.4-1.4,0.8c-0.5,0.3-1.1,0.7-1.8,1.1c-0.7,0.5-1.5,0.9-2.2,1.3l-2.5,1.2c-0.8,0.4-1.8,0.7-3.2,1s-2.6,0.5-3.9,0.5
			c-1.5,0.1-3.1,0.2-4.9,0.2c-3.1,0.1-6.4,0.1-9.8,0c-3.4-0.1-6.6-1.4-9.9-3.8c-4.3-3.8-8.6-7.9-12.8-12.3s-7.5-9-9.8-13.8
			c-0.5-1-0.8-1.9-1-2.7s-0.3-1.8-0.4-2.7c-0.1-1-0.2-2-0.2-3c0-1-0.2-2.3-0.5-3.9c-0.7-1.4-1.1-2.9-1.3-4.3
			c-0.2-1.5-0.2-3.1-0.1-4.9c-0.1-0.3-0.1-1.4,0-3.4s0.4-4.1,0.8-6.4s1-4.5,1.7-6.6c0.7-2.1,1.5-3.3,2.5-3.8l3.3-3.5
			c1.2-0.6,2.4-1.2,3.3-1.8c1-0.6,1.8-1.4,2.6-2.5c0.8-1,1.6-1.9,2.4-2.7s1.7-1.4,2.6-1.9l6.9-3.3L355.4,764.8L355.4,764.8z
 M348.5,782c-1.6,0.8-2.7,2.7-3.3,5.7c-0.6,3-0.8,6.2-0.7,9.7c0.1,3.5,0.3,6.7,0.8,9.7c0.4,3,0.9,4.9,1.2,5.6l1.2,2.5l4.3,8.8
			c0.6,1.3,2.5,3,5.5,4.9c3,1.9,6.4,3.7,10.2,5.2c3.8,1.5,7.7,2.7,11.5,3.4c3.9,0.7,6.9,0.5,9.2-0.6l2.7-1.3c2-1,3.9-2.6,5.8-4.8
			c1.8-2.2,3.3-4.7,4.4-7.5c1.1-2.8,1.7-5.7,1.9-8.8c0.2-3.1-0.4-6-1.7-8.7l-2-4l-4.6-9.5c-1-2-2-3.5-3.1-4.4
			c-1.1-0.9-2.4-1.8-3.8-2.4c-1.4-0.7-3-1.4-4.8-2.3c-1.8-0.8-4-2.2-6.4-3.9c-1-0.2-2.2-0.5-3.6-0.9c-1.3-0.4-2.7-0.8-4.1-1
			c-1.4-0.3-2.8-0.4-4.1-0.5s-2.6,0.2-3.5,0.6l-2.7,1.3c-0.8,0.4-1.7,0.8-2.8,1.2c-1.1,0.4-2.2,0.8-3.4,1.1
			C351.3,781.3,350,781.6,348.5,782L348.5,782z"
          />
          <path
            class="symbol"
            data-symbol="P"
            d="M441,727.1c0.7-0.2,1.2-0.3,1.4-0.3c0.2,0.1,0.8,0.4,1.7,1l25.9-9.3l13.5-2.1l4.2-1.5c2.8-1,5.6-0.9,8.2,0.4
			c2.7,1.3,5.1,3.1,7.3,5.3s4,4.6,5.5,7.1c1.5,2.5,2.6,4.5,3.1,6c0.6,1.7,1.2,3.7,1.7,5.9c0.5,2.2,0.8,4.5,0.7,6.8
			c0,2.3-0.5,4.6-1.4,6.9c-0.9,2.2-2.5,4.2-4.7,5.8c-0.4,0.1-0.9,0.5-1.5,1s-1.2,1.1-1.8,1.7c-0.6,0.6-1.1,1.1-1.6,1.6
			s-0.9,0.8-1.2,0.9c-0.7,0.2-1.7,0.5-3,0.8s-2.7,0.5-4.1,0.5c-1.4,0-2.7-0.2-3.8-0.5c-1.1-0.3-1.9-1-2.3-2.2
			c-0.5-1.4-0.5-2.5,0.2-3.3c0.6-0.8,1.5-1.5,2.7-2.2c1.2-0.7,2.4-1.5,3.7-2.4s2.4-2.1,3.3-3.6c0.9-1.5,1.3-3.5,1.3-6.1
			c0-2.5-0.7-5.8-2.2-9.8c-0.2-0.6-0.5-1.3-0.9-2s-1-1.3-1.8-1.8c-1.2-0.8-2.7-1.4-4.5-1.8c-1.8-0.4-3.7-0.5-5.5-0.6
			c-1.9,0-3.6,0.1-5.1,0.3c-1.5,0.2-2.5,0.4-2.9,0.5l-2.6,0.9c-0.4,0.1-0.8,0.5-1.2,0.9c-0.4,0.5-0.7,1-1,1.5
			c-0.3,0.5-0.4,1.1-0.3,1.7c0,0.6,0.3,1,0.7,1.3l7.1,19.7l2.5,7.1l3.1,8.7l4.1,6.2c0.2,1.5,0.4,2.9,0.5,4.1
			c0.2,1.1,0.3,2.1,0.5,3.1s0.4,1.7,0.5,2.1c1,2.8,2.4,4.3,4.2,4.5c1.8,0.2,3.5,0,5.3-0.5c1.8-0.6,3.5-1,5-1.4s2.7,0.3,3.2,1.9
			l0.6,1.7c0.1,0.4,0.2,1,0.1,1.7c-0.1,0.8-0.2,1.5-0.4,2.3c-0.2,0.8-0.4,1.5-0.8,2.1s-0.7,1-1.1,1.2l-2.6,0.9
			c-0.8,0.3-2.2,0.5-4.1,0.5c-1.9,0.1-3.3,0.2-4.1,0.5l-9.6,3.5c-0.4,0.1-1.2,0.5-2.4,1.1c-1.2,0.6-2.5,1.3-3.9,2.1
			c-1.4,0.8-2.8,1.5-4.1,2.2c-1.4,0.7-2.4,1.1-3.1,1.4c-1.1,0.4-2.2,0.8-3.2,1.1s-2,0.4-2.9,0.3s-1.7-0.5-2.5-1.1
			c-0.8-0.6-1.4-1.6-2-3.1c-0.2-0.7-0.5-1.5-0.8-2.5c-0.3-1-0.4-2-0.4-2.9c0-1,0.3-1.9,0.8-2.8c0.5-0.8,1.4-1.5,2.8-2l2.1,0.6l5.6-2
			c2.7-1,3.6-2.9,2.5-5.7l-1.6-4.5l-0.3-6.2l-2.7-2.3c-0.1-0.9-0.1-1.7-0.1-2.5c0-0.7,0-1.3,0-2c0-0.7-0.1-1.2-0.2-1.5
			c-0.2-0.7-0.9-1.6-1.9-2.6c-1-1-1.6-1.9-1.9-2.7c-0.1-0.3-0.1-0.6,0-0.8s0.2-0.6,0.3-1.1s0.1-1.1,0.1-1.7
			c-0.1-0.7-0.3-1.6-0.8-2.8l-2-5.4l-1.1-3c-0.2-0.6-0.7-1.2-1.6-1.7c-0.8-0.5-1.5-1.6-2-3.1l-1.5-4.2c-0.4-1-0.5-2.2-0.5-3.7
			c0-0.8,0.1-1.7,0.1-2.6c-0.5-1.5-1.7-2.6-3.4-3.2c-1.7-0.6-3.6-0.6-5.7,0.2l-1.7,0.6c-0.3,0.1-0.9,0.2-1.9,0.3s-2,0.2-3.2,0.2
			c-1.1,0-2.2-0.1-3.1-0.3c-0.9-0.2-1.5-0.7-1.7-1.2c-0.3-0.9-0.4-1.8-0.2-2.7c0.2-0.9,0.5-1.7,1-2.4c0.5-0.7,1-1.4,1.5-1.9
			C440.2,727.6,440.7,727.2,441,727.1L441,727.1z"
          />
          <path
            class="symbol"
            data-symbol="Q"
            d="M575.1,681.2c2.1-0.5,4.4-0.5,7.1,0.1c2.7,0.6,4.8,1.5,6.5,2.8c4.5,1.1,9,3.2,13.6,6.1c4.5,3,8.3,6.4,11.2,10.3
			c0.1,0.4,0.4,1.2,0.8,2.3c0.4,1.1,0.8,2.3,1.2,3.7s0.8,2.5,1.2,3.7c0.4,1.1,0.7,1.9,0.8,2.3l1.2,4.3l2.3,8.5
			c0.3,1.1,0.5,2.5,0.5,4.2c0,1.6,0,3.5-0.1,5.4s-0.4,4-0.7,6.1s-0.7,4.1-1.2,6c-0.7,1.8-1.5,3.5-2.3,5.1c-0.8,1.5-1.6,3-2.3,4.2
			s-1.3,2.4-1.7,3.2c-0.4,0.8-0.6,1.4-0.5,1.7c0.2,0.7,1.1,1.7,2.8,2.8s3.8,2.4,6.3,3.7c2.5,1.3,5.3,2.7,8.3,4.1
			c3,1.4,5.9,2.7,8.8,4s5.4,2.3,7.7,3.3c2.3,1,4,1.7,5.1,2.2c5.1,1.8,10.2,3.3,15.2,4.5c5,1.2,10.1,2.6,15.1,4.2
			c0.6,0.7,2.5,1.4,5.5,1.9c3,0.6,6.3,1,9.9,1.4s7,0.6,10.3,0.7c3.3,0.1,5.5,0,6.6-0.3l4.8,0.7l3.1-0.8c1.1-0.1,2.2-0.2,3.2-0.4
			c0.8-0.1,1.8-0.3,2.8-0.5c1-0.2,1.9-0.4,2.7-0.7l13.3-3.5c1.3-0.4,2.7-0.9,4.2-1.5c1.4-0.7,2.9-1.2,4.3-1.6c0.8-0.2,1.8-0.6,3-1
			s2.1-0.8,3-1c0.6-0.2,1.1-0.2,1.5-0.2s1.4-0.1,2.9-0.5c2.5-0.7,4.6-1.5,6.3-2.6s3.2-2.1,4.5-3.1s2.5-2,3.5-2.8
			c1-0.9,2-1.5,3.2-1.8l2.6-0.7c0.8-0.2,1.8-0.3,2.9-0.3c1.1,0,1.8,0.5,2,1.3c0.3,1.1-0.2,2.5-1.4,4c-1.2,1.5-2.7,3.1-4.3,4.7
			s-3.2,3-4.6,4.4c-1.5,1.3-2.3,2.2-2.5,2.7l-5.3,2.4c-0.9,0.2-1.9,0.6-3,1c-1.1,0.4-2,1-2.7,1.9l-8.7,2.3c-2.1,0.9-3.6,1.6-4.5,2.1
			c-0.9,0.5-1.6,1-2.1,1.3c-0.5,0.3-1,0.7-1.6,0.9c-0.6,0.3-1.6,0.6-2.9,0.9l-6,1.6c-0.3,0.1-1,0.2-1.9,0.3s-2,0.2-3.1,0.2
			c-1.2,0-2.6,0-4.1,0.1l-2.9,0.8c-0.3,0.1-1.5,0.2-3.5,0.2c-2,0-4.3,0.1-6.9,0.3c-2.6,0.1-5.1,0.3-7.5,0.4
			c-2.4,0.2-4.2,0.4-5.2,0.7c-3.4,0.9-7.2,1-11.3,0.4c-4.2-0.7-8.1-1.1-12-1.4c-4.3-0.5-8.4-1.3-12.2-2.4s-6.9-2.1-9.5-2.9
			c-1.8-0.4-3.7-0.5-5.9-0.3c-2.2,0.2-4.6-0.1-7.3-0.8c-0.8-0.7-1.5-1.3-2.1-2c-0.6-0.7-1.7-1.1-3.4-1.2c-1.7-0.6-3.5-1.5-5.5-2.5
			s-3.9-2.1-6-3.1s-4.1-2.1-6-3.1c-2-1-3.9-1.8-5.7-2.5c-1.5,0.4-2.9,0.2-3.9-0.8c-1.1-0.9-2.2-2.2-3.2-3.8
			c-1.9-0.9-3.6-1.4-5.2-1.5s-3.2-0.7-4.9-1.7c-1-0.5-1.9-1.3-2.6-2.5s-1.8-1.9-3.4-2.4c-1.4-0.7-3.1-1.3-5-1.7
			c-1.9-0.4-3.9-0.9-5.8-1.3s-3.8-1.1-5.7-1.8c-1.8-0.7-3.4-1.9-4.6-3.4c-1.5-1.2-3-2.3-4.4-3.1c-1.4-0.8-2.6-1.7-3.7-2.5
			s-2.1-1.7-2.9-2.7c-0.8-1-1.4-2.3-1.8-3.8l-1.6-4.2l-3.5-10.2l-2.3-8.5l-1.6-6l0.1-2.8c-0.2-0.8-0.2-1.5,0-2
			c0.2-0.5,0.5-1.6,0.9-3.4c-0.2-0.6,0-1.3,0.3-2.2c0.4-0.8,0.5-1.6,0.2-2.5c-0.2-1.4,0-3,0.6-4.7c0.5-1.7,1.3-3.4,2.2-5
			c1-1.6,2-3.1,3.3-4.4c1.2-1.4,2.6-2.5,4-3.3c0.7-0.2,1.5-0.8,2.3-1.9s1.9-2.2,3.2-3.3c1.2-1.2,2.5-2.3,3.7-3.2
			C572,682.4,573.4,681.7,575.1,681.2L575.1,681.2z M580,694.6c-2.9,0.8-5.1,2-6.6,3.8c-1.5,1.7-2.6,3.8-3.3,6.2
			c-0.7,2.4-1.2,5.1-1.4,8.1s-0.5,6.1-0.8,9.2l2.1,7.7c0.9,3.4,2.8,6.9,5.7,10.5c2.9,3.6,6,7,9.5,10.3c0.5,0.5,1.2,1.1,2.2,1.8
			c1,0.7,2.1,1.3,3.2,1.9s2.2,1,3.3,1.4c1.1,0.3,1.9,0.4,2.5,0.2c2.2-0.6,3.7-2.3,4.7-5.1s1.7-5.8,2.1-9c0.4-3.2,0.7-6.2,0.8-9
			c0.1-2.8,0.3-4.4,0.6-5l-1.2-4.3c-0.9-3.4-2-5.9-3.2-7.4s-1.8-2.6-2-3.1l-0.9-3.4c-0.4-1.3-1.5-3.1-3.4-5.4
			c-1.9-2.2-3.9-4.3-5.9-6c-0.9-0.4-2.1-1.2-3.5-2.3S581.4,694.2,580,694.6L580,694.6z"
          />
          <path
            class="symbol"
            data-symbol="R"
            d="M709.3,652.9c2.1-0.4,4.2,0.1,6.3,1.4c2.1,1.3,4,2.5,5.8,3.6c1.3,2.4,3,4,5,5c0.8,0.6,1.5,1.3,2.3,2
			c0.8,0.7,1.4,1.7,1.8,2.8c0.4-0.1,0.9,0.3,1.5,1.1c0.6,0.8,1.1,1.8,1.7,3c0.5,1.2,1,2.2,1.4,3.2s0.6,1.7,0.7,2
			c0.3,1.5,0.3,3.2,0.1,5c-0.2,1.9-0.5,3.8-1,5.7c-0.5,1.9-1,3.8-1.6,5.6c-0.6,1.8-1.1,3.3-1.6,4.4c-0.3,0.8-0.7,1.5-1.3,2
			c-0.6,0.5-1.2,1.1-1.8,1.5s-1,1.1-1.4,1.8c-0.4,0.7-0.4,1.6-0.2,2.7c0.2,1.2,0.8,2.6,1.8,4.4s2.1,3.5,3.4,5.2s2.6,3.2,3.8,4.5
			c1.3,1.3,2.2,2,2.9,2.2c1.4,0.6,2.7,1.5,4,2.5c1.2,1.1,2.5,2.2,3.8,3.2s2.7,2,4.2,2.7c1.4,0.8,3,1.2,4.8,1.2
			c0.7,0.1,1.7,0.3,3.2,0.6c1.5,0.3,2.6,0.8,3.5,1.4c0.7,0.5,1.2,0.9,1.3,1.2c0.1,0.2,0.1,0.8,0.1,1.7c-0.1,1.2-0.7,2.2-1.8,2.9
			c-1.1,0.7-2.4,1.3-3.8,1.7c-1.4,0.4-2.7,0.7-3.8,0.9c-1.2,0.2-1.9,0.2-2.1,0.1c-0.8-0.5-1.9-0.6-3.1-0.4c-1.2,0.2-2.2,0.3-3,0.2
			c-3.1-1.2-5.4-2.2-6.7-3c-1.3-0.8-2.9-1.6-4.6-2.2c-0.7-0.2-1.6-0.6-2.7-1.2c-1.1-0.6-2.3-1.3-3.6-2.2c-1.3-0.8-2.6-1.8-3.9-2.7
			s-2.5-1.8-3.6-2.6c-1.5-1.3-2.9-2.7-4-4c-1.2-1.3-2.2-2.5-3.1-3.8c-0.9-1.2-1.8-2.3-2.6-3.3c-0.8-1-1.7-1.7-2.5-2.2
			c-1.6-0.6-2.8-1.2-3.7-1.8c-0.8-0.6-2-0.8-3.4-0.5c-1.3,0.2-2.2,0.8-2.7,1.7c-0.5,0.9-0.9,1.9-1.1,3c-0.2,1.1-0.2,2.3-0.1,3.4
			c0.1,1.2,0.2,2.1,0.4,2.9l3.1,7.1l0.6,3.2c0.5,2.5,1.6,4.3,3.5,5.2c1.8,1,3.8,1.7,5.8,2.2c2,0.5,3.9,1,5.7,1.5
			c1.8,0.5,2.8,1.5,3.1,3.1c0.1,0.7-0.1,1.4-0.8,2.1c-0.6,0.7-1.3,1-1.9,1.2l-5.7,1l-9,0.3L699,751l-7.4,1.3l-2.7,0.5l-6.1,1.1
			c-0.3,0.1-1.3,0.2-3.1,0.3s-3.7,0.2-5.7,0.2c-2,0-3.9-0.2-5.6-0.6s-2.7-1.2-2.9-2.3c-0.1-0.3,0-0.7,0.3-1c0.3-0.4,0.6-0.8,0.9-1.1
			c0.3-0.5,0.8-1,1.4-1.4c0.3-0.1,0.8-0.1,1.5-0.1c0.7,0,1.3,0,2,0c0.8,0,1.6-0.1,2.4-0.1l3.2-0.6c2.1-0.4,3.8-1.3,5-2.8
			c1.2-1.5,1.7-2.9,1.4-4.2l-3-16.5l-4.6-25.5l-0.8-11.6c-0.5-1-0.9-2.2-1.3-3.7c-0.4-1.5-0.8-2.8-1.3-4.1s-1.2-2.4-2.1-3.3
			c-0.9-0.9-2-1.4-3.4-1.5l-6.1,1.1c-1.2,0.2-2.3,0.3-3.5,0.3s-2.3-0.2-3.4-0.5c-1.1-0.3-2-0.9-2.8-1.8c-0.8-0.8-1.3-2-1.6-3.6
			c-0.1-0.3,0.1-0.7,0.5-1.2c0.4-0.5,0.8-0.9,1.3-1.3c0.5-0.5,1.2-1,2-1.5l26.4-6.1l16.2-2.9c0.3-0.1,0.9-0.2,1.7-0.5
			c0.8-0.2,1.6-0.6,2.4-0.9c1-0.4,2.1-0.8,3.2-1.2L709.3,652.9L709.3,652.9z M697.3,671.6c-0.4,0.2-0.8,0.4-1.4,0.6
			c-0.5,0.3-1,0.6-1.6,0.9c-0.6,0.3-1.2,0.7-1.9,1.4c-0.7,0.1-1.4,0.6-1.9,1.4s-0.7,1.9-0.4,3.4l0.5,2.7l1.1,6.1l0.5,2.7
			c0.2,0.8,0.1,1.6-0.2,2.4s-0.4,1.5-0.3,2.1l0.7,0.2c0.2,0.2,0.4,0.3,0.6,0.5c0.1,0.1,0.2,0.5,0.3,1c-0.1,1.1,0.2,2.1,0.8,2.9
			s1.6,1.5,2.6,2s2.2,0.8,3.3,1c1.2,0.2,2.1,0.2,2.8,0c0.9-0.6,1.7-1.2,2.6-1.8c0.8-0.5,1.5-0.9,2.1-1.4c0.6-0.4,1.2-0.7,1.7-0.8
			l3.4,0.7c1.4-0.2,2.4-1,3.2-2.3c0.8-1.3,1.3-2.7,1.6-4.2c0.3-1.5,0.5-3,0.6-4.3c0.1-1.4,0.2-2.3,0.3-2.7l-1.7-4.2
			c-0.1-0.7-0.3-1.4-0.6-2.1c-0.3-0.7-0.9-1.3-1.9-2c-0.1-0.6-0.4-1.6-0.8-2.8s-1.1-2.1-2-2.6c-0.3,0.1-1.1,0-2.4-0.1
			s-2.6-0.2-4.1-0.4c-1.5-0.2-3-0.3-4.5-0.3C698.9,671.5,697.9,671.5,697.3,671.6L697.3,671.6z"
          />
          <path
            class="symbol"
            data-symbol="S"
            d="M797.8,656.2c-0.2-1.7,0-3.2,0.4-4.5c0.5-1.3,1.1-2.5,2-3.5s2-2.1,3.2-3.1s2.4-2,3.7-3c2.7-2.1,5.2-3.8,7.4-5.3
			c2.3-1.4,5.2-2.3,8.7-2.7c2.2-0.2,4.2-0.4,5.9-0.5c1.7-0.1,3.5,0.4,5.5,1.6c1.6,1,2.8,2.2,3.5,3.5s1.2,3,1.4,5
			c0.1,1.1,0,2.2-0.2,3.4c-0.2,1.2-0.7,2.3-1.3,3.3c-0.6,1-1.4,1.9-2.3,2.6s-2,1.1-3.2,1.2c-1.2,0.1-2.1-0.1-2.9-0.6
			s-1.5-1.1-2.1-1.6c-0.7-0.6-1.3-1.1-2.1-1.6c-0.8-0.5-1.7-0.7-2.7-0.6c-1.4,0.1-2.8,0.4-4.2,0.8c-1.4,0.4-2.7,1-3.8,1.8
			c-1.1,0.8-2,1.7-2.6,2.7c-0.7,1-0.9,2.2-0.8,3.6c0.1,1.3,0.7,2.1,1.5,2.6c0.9,0.5,2,0.7,3.2,0.8c1.2,0.1,2.6,0.2,4,0.2
			s2.7,0.4,4,0.9c3,1.2,5.6,2.3,7.8,3.2c2.2,1,4.7,2.2,7.5,3.7c1,0.4,1.9,0.8,2.7,1.1c0.8,0.3,1.5,0.6,2.2,0.8
			c0.7,0.2,1.5,0.5,2.3,0.9c0.8,0.3,1.7,0.8,2.7,1.5c3.3,2.1,6,4.3,8.2,6.6s3.4,5.5,3.8,9.5l0.4,3.7c0.1,1.3,0.5,2.4,1,3.4
			c0.5,1,0.9,2.1,1,3.5c0.2,2.2,0,4.1-0.8,5.5s-1.5,3.2-2.1,5c-0.1,0.9,0,1.6,0.2,2.2c0.3,0.6,0.2,1.2-0.3,1.9
			c-0.3,1.4-0.9,2.4-1.8,2.9c-0.9,0.5-1.7,1.4-2.5,2.5c-0.9,1-1.7,1.8-2.5,2.5s-1.6,1.4-2.4,2.1c-0.8,0.7-1.6,1.3-2.5,1.9
			c-0.8,0.6-1.9,1.3-3.1,2.1c-1.9,1.4-3.6,2.4-5.2,3s-3.3,1.3-5.4,2.1c-0.7,0.3-1.3,0.7-1.8,1.2c-0.5,0.5-1.2,0.8-2,0.9
			c-1,0.1-1.7,0.1-2.2,0c-0.5-0.1-1.1-0.1-1.8-0.1c-1.6,0.2-2.8,0.5-3.7,0.9s-2,0.7-3.4,0.8c-0.8,0.1-1.5,0-2-0.3
			c-0.5-0.3-1.2-0.5-2.1-0.7c-1.2-0.3-2.3-0.4-3.2-0.2c-0.9,0.1-1.9,0.2-3.1,0.1c-1.2,0.1-2.2-0.1-3-0.7c-0.8-0.6-1.8-1.2-3.1-1.9
			c-1.2-0.5-2.4-0.9-3.5-1c-1.1-0.2-2.3-0.8-3.6-1.8c-1.2-0.5-2-1.3-2.2-2.3c-0.2-1-0.7-2-1.5-3.2c-1-1.7-1.9-3-2.7-4
			c-0.7-0.9-1.2-2.5-1.4-4.6c-0.3-3.1,0.6-5.9,2.7-8.4c2.1-2.5,4.8-4,8.1-4.3c2-0.2,4,0,5.9,0.8c1.9,0.7,2.9,2,3.1,3.8
			c0.1,0.6,0,1.1-0.1,1.5c-0.1,0.3-0.2,0.7-0.1,1.1c0.1,0.7,0.3,1.3,0.7,1.7c0.4,0.4,0.6,0.8,0.6,1.4c0,0.2,0,0.5-0.2,0.9
			s-0.2,0.7-0.2,0.9c0.2,1.6,0.7,2.9,1.7,3.9c1,1,2.2,1.9,3.6,2.5c1.4,0.7,3,1.1,4.7,1.3c1.7,0.2,3.5,0.2,5.2,0
			c3.4-0.3,6.4-1.5,9.1-3.3s5-4.3,6.9-7.1c1.1-1.5,2-3,2.7-4.6c0.7-1.6,0.9-3.5,0.7-5.7c-0.1-1.3-0.6-2.4-1.3-3.5
			c-0.8-1-1.2-2.2-1.3-3.3c0-0.5,0-0.8,0.2-0.9s0.2-0.2,0.2-0.4c-0.2-1.7-0.8-3.1-1.8-4.1c-1-1-2.3-1.9-3.7-2.6
			c-1.5-0.7-3.1-1.3-4.8-1.9c-1.7-0.6-3.4-1.2-5.1-1.9c-0.5-0.2-0.8-0.5-1.2-1c-0.3-0.5-0.8-0.8-1.3-1c-1.1-0.4-2.2-0.6-3.3-0.5
			c-1.1,0.1-2.1-0.3-3.2-1c-0.9-0.4-1.5-0.9-1.9-1.5s-1-1.1-1.9-1.7c-1.2-0.4-2.3-0.6-3.2-0.6c-0.9,0-2-0.3-3.2-1
			c-0.7-0.7-1.3-1.3-1.9-1.7c-0.5-0.5-1-1.1-1.6-1.9c-1-1-2.1-1.7-3.4-2.2c-1.3-0.5-2-1.6-2.2-3.2l-0.4-4c-0.1-0.6-0.2-1.3-0.3-1.9
			c-0.2-0.6-0.3-1.3-0.4-2c0-0.4,0-0.8,0-1c0.1-0.3,0.1-0.6,0-1c0-0.5,0-1,0.1-1.4S797.8,656.4,797.8,656.2L797.8,656.2z"
          />
          <path
            class="symbol"
            data-symbol="T"
            d="M994.5,626.9c0.3,0,0.7,0.4,1.1,1.2c0.4,0.8,0.8,1.7,1.1,2.8s0.6,2.1,0.9,3.2c0.2,1.1,0.4,1.8,0.4,2.2l0.3,11.5
			c0,0.4,0,1.2,0,2.4c0,1.2-0.2,2.4-0.4,3.6s-0.7,2.3-1.3,3.3c-0.6,1-1.5,1.5-2.5,1.5c-1.8,0-3.1-0.3-4-1.1s-1.4-1.8-1.8-3
			c-0.4-1.2-0.7-2.5-1-4c-0.3-1.4-0.7-2.8-1.2-4c-0.6-1.2-1.4-2.2-2.6-3c-1.1-0.8-2.8-1.1-5.1-1.1l-8.8,0.2c-0.5,0-1.1,0.1-1.8,0.2
			c-0.6,0.1-1.2,0.3-1.8,0.5c-0.6,0.2-1.3,0.5-1.9,0.7c-2.2,0.1-4,0.8-5.1,2.3s-1.8,3.3-1.7,5.4l0.2,7.7c0,0.8,0,1.6,0,2.3
			c0,0.7,0.3,1.3,0.9,2l-0.6,6.3l0.7,25.9c0.1,2.1,0.3,3.9,0.6,5.2c0.4,1.3,0.8,2.4,1.4,3.2c0.6,0.8,1.2,1.5,1.8,2.1
			c0.7,0.6,1.3,1.1,1.8,1.6c1.6,0.5,3.1,1,4.4,1.4s2.8,0.6,4.7,0.6c3.2,0.5,5.6,1.4,7.3,2.8s2.5,2.9,2.7,4.4c0.2,1.5-0.4,3-1.6,4.4
			s-3.1,2.2-5.7,2.6c-1,0-1.9-0.1-2.8-0.4c-0.9-0.3-1.8-0.5-2.7-0.7c-1-0.1-2-0.1-3.2-0.1c-1.2,0-2.4,0-3.6,0
			c-1.2,0-2.4-0.1-3.5-0.3s-2.1-0.5-3-1l-1,0l-14.2,0.4c-0.6,0-1.9,0.3-3.9,1c-2,0.6-4.2,1-6.6,1.1c-1.7,0-3.3,0-4.6,0
			c-1.2-0.1-2.4-0.1-3.6-0.1s-2.1,0-2.5,0c-0.5,0-1,0.2-1.3,0.7c-0.3,0.4-1.4,0.7-3.2,0.7c-0.2-0.1-0.4-0.3-0.7-0.6
			c-0.2-0.2-0.4-0.4-0.5-0.7c-0.1-0.3-0.2-0.7-0.2-1.2c0.4-1.2,0.9-2.4,1.5-3.6c0.5-1,1.1-2,1.8-3.1c0.7-1.1,1.4-2.1,2.2-2.9
			c1.5-0.6,2.8-1.1,4.1-1.6c1-0.5,2.1-0.9,3.1-1.2c1-0.3,1.7-0.5,2-0.5l7.6-2c0.7,0,1.3-0.5,1.7-1.6c0.4-1,0.7-2.1,0.9-3.3
			c0.2-1.4,0.2-3,0.2-5l-0.5-17c0-0.4,0.2-1.2,0.6-2.3l0.6-1.3l-0.6-20.5c0-0.8,0-1.7,0.2-2.6c0.1-0.9,0.2-1.7,0.2-2.4
			c0-0.3-0.2-0.8-0.5-1.4s-0.8-1.3-1.3-1.9s-1.2-1.2-1.9-1.6s-1.4-0.7-2.2-0.7l-17.8,0.5c-2.2,0.1-4,0.5-5.2,1.3
			c-1.2,0.8-2.1,1.9-2.7,3.1c-0.6,1.2-1,2.6-1.3,4c-0.3,1.5-0.6,2.8-1.1,4c-0.5,1.2-1.1,2.3-1.9,3.1c-0.8,0.8-2.1,1.3-3.8,1.3
			c-1.6,0-2.8-0.5-3.5-1.7c-0.7-1.2-1.1-2.5-1.2-3.9c0-0.4,0-1.1,0.1-2c0.1-0.9,0.2-1.8,0.3-2.8s0.3-1.9,0.4-2.7
			c0.1-0.9,0.2-1.5,0.2-1.8l1.7-7.7c0-0.3,0-1,0.2-2.2c0.1-1.1,0.4-2.3,0.7-3.6s0.8-2.4,1.3-3.4c0.5-1,1.1-1.5,1.9-1.5
			c1.4,0,2.5,0.2,3.3,0.7s1.7,1.1,2.7,1.7c0.7,0.5,1.3,0.9,2,1.2c0.7,0.3,1.6,0.4,2.8,0.4c0.6,0,1.6-0.2,2.9-0.5s2.2-0.5,2.9-0.5
			l3.2-0.1c0.3,0.2,0.7,0.3,1.1,0.4s0.9,0.1,1.5,0.2c0.5,0.1,1.1,0.1,1.8,0.1l24.2-0.7l4.3-0.9l4.5,0.7c2.2-0.1,4.5-0.3,7-0.6
			c2.4-0.3,4.7-0.5,6.8-0.6l7.7-0.2c1.9,0,3.6-0.4,5.1-0.9s2.8-1.2,3.9-1.8s2-1.2,2.7-1.8C993.8,627.2,994.3,626.9,994.5,626.9
			L994.5,626.9z"
          />
          <path
            class="symbol"
            data-symbol="U"
            d="M1105.6,668.8c-0.2-0.3-0.4-0.7-0.6-1.2c-0.4-0.9-0.6-1.6-0.5-2.1l0.4-7.5l-1-9l0.2-3c0.1-1.2-0.3-2-1-2.4s-1.5-0.7-2.3-1
			c-1.2-0.3-2.2-0.5-3.1-0.8c-0.9-0.3-1.3-0.9-1.3-2c0.1-1,0.3-1.9,0.8-2.8s1-1.7,1.7-2.5c0.6-0.8,1.3-1.4,2.1-1.8
			c0.8-0.4,1.5-0.6,2.1-0.6c1.4,0.8,2.9,1.5,4.4,2c1.2,0.6,2.7,1.1,4.2,1.5s3.1,0.6,4.6,0.7l6.2,0.3c1.1,0,2.3,0,3.7-0.2
			c1.4-0.2,2.7-0.2,3.9-0.1c1.2,0.1,2.2,0.5,3.1,1.3c0.8,0.7,1.2,2.1,1.1,4.1c0.3,1.1,0.4,2.1,0.5,3.1c0.1,0.9,0.1,1.7,0,2.4
			c-0.1,0.7-0.4,1.3-0.8,1.6l-4.5-0.2l-3.2-0.2c-1.3-0.1-2.3,0.5-3.2,1.8c-0.9,1.3-1.3,2.7-1.4,4.2c-0.6,1.6-1,2.8-1.2,3.8
			c-0.2,1-0.1,1.7,0,2.2c0.1,0.6,0.3,1.1,0.7,1.5l-2.2,5.7c0.5,0.9,0.8,1.8,0.9,2.7s0.3,1.8,0.4,2.5c0.1,1,0.1,1.8,0.2,2.6
			c0,0.3-0.2,0.8-0.4,1.4c-0.2,0.6-0.5,1.3-0.8,2c-0.2,0.7-0.5,1.5-0.8,2.2c-0.2,0.7-0.4,1.3-0.4,1.7l-0.2,3c0,0.4-0.1,1-0.2,1.6
			s-0.3,1.3-0.6,2c-0.3,0.8-0.5,1.7-0.8,2.5c0,0.8,0,1.4,0.2,2c0.2,0.6,0.4,1.2,0.6,1.7c0.2,0.4,0.3,1,0.2,1.5c0,0.6-0.1,1.2-0.1,2
			c-0.1,1.5-0.2,2.5-0.5,3c-0.7,0.6-1.1,1.7-1.1,3.2c-0.1,1.5-0.8,3-2.1,4.5c-1.3,1.5-2.7,3.4-4.2,5.8c-0.1,1.4-0.8,2.5-2.2,3.5
			c-1.4,1-2.9,2.1-4.4,3.4c-0.8,0.8-1.4,1.5-1.9,1.9c-0.4,0.5-0.9,0.8-1.3,1.1s-0.8,0.5-1.3,0.7s-1.2,0.4-2,0.7
			c-3.6,1.4-7.1,2.2-10.5,2.3s-5.9,0.1-7.4,0c-1.8-0.1-3.8-0.3-6-0.6c-2.2-0.3-4.3-0.8-6.5-1.5c-2.2-0.7-4.2-1.4-6.2-2.2
			c-2-0.8-3.6-1.9-5-3.1l-3-4.6c-0.8-1.2-1.5-2.5-2-4c-0.6-1.4-1.1-2.7-1.6-3.9c-0.6-1.4-1.1-2.9-1.5-4.4l0.6-10.7
			c0.1-1.5-0.1-3-0.6-4.6c-0.5-1.6-0.7-3.6-0.6-6l0.5-9c0.3-1,0.6-2.1,1-3.2c0.3-0.8,0.5-1.7,0.8-2.5s0.4-1.4,0.4-1.7
			c-0.2-1.5-0.5-2.8-0.8-4c-0.4-2.4-0.6-4-0.5-5c0.3-0.8,0.6-1.7,0.9-2.5c0.2-0.7,0.5-1.4,0.8-2c0.2-0.6,0.4-1.2,0.4-1.6l0.4-8.8
			c0-0.7-0.6-1.4-1.8-2c-1.2-0.6-2.6-1.1-4.2-1.6c-1.5-0.5-2.9-0.9-4.1-1.3c-1.2-0.4-1.7-0.9-1.6-1.4c0.1-1.4,0.7-2.7,2-4.1
			s2.6-2,4.1-1.9l7.8-1.4l3,0.2c0.6,0,1.9,0.4,3.7,1.1s3.6,1.1,5.2,1.2l13.3,0.7c1.5,0.1,2.3,0.4,2.6,1.1c0.2,0.7,0.3,1.2,0.3,1.6
			c-0.1,2.3-0.5,4-1.2,4.8s-1.5,1.4-2.6,1.6c-1,0.2-2.1,0.3-3.2,0.2s-2.2,0.1-3.2,0.5c-1,0.4-1.9,1.3-2.6,2.6
			c-0.7,1.3-1.1,3.4-1.3,6.4c0,0.3,0,0.8,0.1,1.5c0.1,0.7,0.2,1.5,0.3,2.3s0.2,1.6,0.4,2.3s0.2,1.2,0.2,1.5l-0.2,4.5l-1.2,24
			l-0.6,10.7c-0.7,1.3-0.9,2.2-0.6,2.6s0.4,1,0.4,1.9l1.5,4.4c1,1.7,1.9,3,2.8,4c0.9,1,1.9,1.8,3.1,2.5c1.1,0.7,2.5,1.1,4.1,1.4
			c1.6,0.3,3.6,0.5,6.1,0.6c0.7,0,2-0.2,3.9-0.8c1.8-0.5,3.6-1.2,5.2-2c0.4-0.7,0.9-1.4,1.6-2.1c0.7-0.7,1.6-1.6,2.6-2.8
			c1-1.2,2.1-2.7,3.4-4.6c1.2-1.9,2.6-4.5,4-7.7l0.3-6.2c0-0.7,0.2-2.1,0.4-4.2s0.4-4.1,0.7-6.4s0.5-4.3,0.8-6.3
			C1105.4,670.8,1105.6,669.5,1105.6,668.8L1105.6,668.8z"
          />
          <path
            class="symbol"
            data-symbol="V"
            d="M1213.2,644.2c1.4,0.2,2.4,0.4,3.2,0.8c0.8,0.3,1.1,1.1,0.9,2.4c-0.3,2-1.1,3.4-2.5,4.2c-1.4,0.8-2.9,1.3-4.6,1.6
			c-1.7,0.3-3.2,0.7-4.5,1c-1.3,0.4-2.1,1.1-2.2,2.1c-0.1,0.8-0.1,1.8,0.1,2.9c0.2,1.1,0.4,2.2,0.6,3.2s0.6,1.8,0.9,2.5
			c0.4,0.7,0.7,1.1,1.1,1.1c0.6,0.8,1.2,1.7,1.6,2.6c0.4,0.8,0.8,1.6,1.1,2.5c0.3,0.8,0.4,1.7,0.3,2.5c0,0.3,0.1,1.2,0.4,2.5
			c0.3,1.4,0.7,3.1,1.2,5c0.5,2,1,4,1.7,6.3c0.6,2.2,1.3,4.3,2.1,6.2c0.8,1.9,1.6,3.5,2.3,4.8c0.8,1.3,1.6,2,2.3,2.1
			c0.6,0.1,1.4-0.3,2.2-1.3s1.6-2,2.3-3.2c0.8-1.4,1.7-3,2.6-4.8c0.9-1,2.3-2.7,4.2-5.3c1.9-2.6,3.8-5.4,5.8-8.5
			c1.9-3.1,3.7-6,5.2-8.9c1.6-2.8,2.5-5.1,2.7-6.7c0.2-1.2-0.1-2-0.7-2.4c-0.6-0.5-1.2-1-1.8-1.5c-0.7-0.3-1.4-0.8-2-1.4
			c-0.6-0.6-0.8-1.5-0.7-2.5s0.6-1.9,1.3-2.4s1.6-0.9,2.5-1c0.9-0.1,1.8-0.2,2.6-0.1c0.8,0.1,1.4,0.1,1.7,0.2l7.6,1
			c0.3,0,0.6,0.1,0.9,0.3c0.3,0.2,0.6,0.3,0.8,0.4c0.3,0.2,0.6,0.3,0.8,0.4c2.3,0.3,4.1,0.5,5.2,0.5c1.1,0,2,0,2.8,0
			c0.7-0.1,1.4-0.1,2.1-0.2s1.9,0,3.4,0.2l2.9,0.9l3.2,0c1.3,0.2,2.3,0.7,3.2,1.6c0.8,0.9,1.2,2.2,0.9,4c-0.2,1.2-0.5,2.1-1,2.7
			c-0.5,0.6-1.1,1.1-1.8,1.3s-1.4,0.4-2.3,0.5s-1.8,0.2-2.6,0.3c-2.5,0.1-5,0.3-7.7,0.5c-2.6,0.2-4.6,1.4-6,3.5l-1.3,3.4
			c-0.3,0.3-0.7,0.8-1.4,1.6c-0.7,0.8-1.4,1.6-2.2,2.6c-0.8,1-1.5,2-2.3,3.2c-0.8,1.1-1.3,2.2-1.8,3.2c-0.4,0.6-0.8,1.1-1.3,1.6
			c-0.4,0.5-0.8,0.9-1.2,1.4c-0.4,0.4-0.8,0.8-1.2,1c-0.8,1.5-1.9,3.2-3,5c-1.2,1.8-2.5,3.5-4.1,4.8c-3.5,5.9-6.6,11.3-9.2,16.3
			c-1.2,2.1-2.3,4.3-3.5,6.5c-1.2,2.2-2.2,4.2-3.1,6.1c-0.9,1.9-1.6,3.5-2.2,5c-0.6,1.5-0.9,2.6-1,3.3l-0.6,4.4
			c-0.2,1.6-0.6,2.9-1.1,4s-1.9,1.4-4.1,1.1c-0.8-0.1-1.7-0.3-2.6-0.5c-0.9-0.2-1.7-0.7-2.5-1.5c-1.3-0.8-1.9-1.7-2-2.5
			c0-0.9-0.3-1.7-0.9-2.4c-0.8-1.4-1.1-2.6-0.9-3.7c-0.1-1.2-0.1-2.2-0.2-3.2s-0.3-1.8-0.8-2.8c0.1-0.7-0.1-1.8-0.5-3
			c-0.4-1.3-0.6-2.3-0.5-2.9c-0.9-2.2-1.6-4.2-2-6.2c-0.4-1.9-0.9-3.9-1.4-5.9s-1-4.1-1.7-6.3c-0.6-2.2-1.6-4.5-2.8-7.1
			c-0.1-1.1-0.2-2.2-0.4-3.3c-0.1-0.9-0.2-1.8-0.3-2.7c-0.1-0.9-0.2-1.7-0.3-2.2l-0.9-4.2c-0.1-0.7-0.5-1.6-1.1-2.9
			c-0.6-1.3-1.3-2.5-2-3.8c-0.9-1.4-1.8-3-2.9-4.8c0-4.1-0.4-8-1.2-11.6c-0.8-3.7-2.4-6.3-4.6-8.1c-4.9-3.5-7.2-6.2-6.9-8.2
			c0.2-1.7,1.8-2.3,4.8-1.9l3,0.4c1.5,0.2,3.3,0.2,5.5-0.1c1.2-0.2,2.4-0.3,3.6-0.5l5.7,0.8l3.9,1.3l2.9,0.1c0.8,0,1.5,0,2.2,0
			c0.7,0,1.3,0,1.7,0.1L1213.2,644.2L1213.2,644.2z"
          />
          <path
            class="symbol"
            data-symbol="W"
            d="M1359.8,669.4c1.2,1,2.1,1.7,2.8,2.3c0.7,0.6,0.8,1.6,0.5,3c-0.4,1.5-1.4,2.5-2.9,2.8s-3.2,0.5-5,0.5
			c-1.8,0-3.4,0.1-4.9,0.2c-1.5,0.2-2.4,0.8-2.7,1.8c-0.2,0.8-0.3,1.7-0.1,2.5c0.1,0.8,0.4,1.9,1,3.2c0.4,1.1,0.8,2.1,1.1,3.2
			c0.3,1,0.4,2,0.2,2.8l1,4.5c0.2,1.3,0.4,2.5,0.6,3.8c0.2,1.3,0.2,2.3-0.1,3.1c-0.1,0.2,0,0.9,0.1,2.1c0.1,1.2,0.2,2.6,0.4,4.2
			c0.2,1.6,0.4,3.4,0.7,5.2c0.3,1.8,0.7,3.5,1,5c0.4,0.8,0.5,1.5,0.6,2.4c0,0.8,0,1.6,0.1,2.4c0.2,0.3,0.2,0.9,0.3,1.8
			c0,1,0.1,1.9,0.2,2.9s0.1,1.9,0.2,2.8c0.1,0.8,0.2,1.3,0.4,1.3c0.2,0,0.5-0.3,0.9-1c0.4-0.7,0.8-1.5,1.3-2.4
			c0.4-0.9,0.9-1.7,1.4-2.5s0.8-1.2,1.1-1.2c0.7-0.6,1.4-1.3,2-2.2s1.3-1.7,1.8-2.6c0.6-0.8,1-1.7,1.3-2.4c0.3-0.7,0.6-1.2,0.6-1.4
			c0.8-0.5,1.7-1.2,2.6-2.2c1-1,2-2.2,3.1-3.6c1.1-1.4,2.2-2.8,3.2-4.4s2.3-3,3.5-4.5c2-2.7,3.8-5.1,5.5-7.2c1.7-2.1,2.8-4,3.2-5.8
			c0.3-1,0.1-1.8-0.6-2.4c-0.6-0.5-1.3-1-2.1-1.4c-0.8-0.4-1.5-1-2.1-1.6c-0.6-0.7-0.7-1.7-0.4-3.1c0.2-0.9,0.7-1.6,1.4-2
			c0.7-0.4,1.5-0.8,2.4-1c0.9-0.2,1.7-0.3,2.5-0.3s1.4,0.1,1.7,0.2c0.8,0.2,1.4,0.4,1.7,0.6s1.1,0.8,2.3,1.7l7.3,1.8
			c1.9,0.5,3.1,0.7,3.7,0.8c0.6,0,1.1,0,1.4,0c0.3-0.1,0.8-0.1,1.3-0.2s1.5,0.1,2.9,0.5l7.4,1.9c1.2,0.3,2.2,0.9,2.8,1.9
			c0.6,0.9,0.7,2.3,0.3,4c-0.4,1.5-1.2,2.3-2.3,2.7c-1.2,0.4-2.6,0.5-4.2,0.5c-1.6,0-3.2,0-4.8-0.1s-3.1,0.2-4.3,0.7
			c-0.8,3.1-0.8,6-0.1,8.7c0.3,1,0.6,2.2,0.9,3.6s0.2,3-0.2,4.8c-0.1,0.2-0.1,1.2-0.1,3.1c0,1.9,0.1,4,0.3,6.4
			c0.2,2.4,0.4,4.9,0.8,7.5s0.8,4.6,1.4,6.1c0.2,0.3,0.3,1,0.4,2.2c0.1,1.2,0.2,2.5,0.3,3.9c0.1,1.3,0.1,2.6,0.2,3.7
			s0.2,1.7,0.3,1.6s0.4-0.5,0.8-1.2c0.4-0.7,0.8-1.5,1.3-2.2c0.5-0.8,0.9-1.5,1.4-2.2s0.8-1.1,1-1.1c0.9-0.5,1.7-1.2,2.4-2.1
			c0.7-0.9,1.3-1.8,1.8-2.7s0.8-1.8,1.2-2.6c0.3-0.8,0.5-1.4,0.6-1.7c1.4-0.7,2.9-2.2,4.6-4.5c1.7-2.3,3.4-4.7,5.3-7.2
			c0.7-1.1,1.6-2.2,2.6-3.3s1.7-2.2,2.2-3.2c2.6-4.2,4.2-7.3,4.7-9.4c0.3-1,0.1-1.8-0.6-2.4s-1.4-1-2.3-1.5c-0.8-0.4-1.6-1-2.2-1.6
			c-0.7-0.7-0.8-1.7-0.4-3.2c0.2-0.9,0.7-1.6,1.5-2c0.8-0.4,1.6-0.7,2.6-0.9s1.9-0.3,2.7-0.2s1.4,0.1,1.7,0.2
			c1.6,0.4,2.5,0.7,2.9,0.9s0.9,0.7,1.6,1.6l6.8,1.7c1.3,0.3,2.3,0.5,3,0.6c0.7,0.1,1.2,0.1,1.7,0c0.5,0,1-0.1,1.6-0.1
			c0.6,0,1.5,0.1,2.7,0.4l7.8,2c1.3,0.3,2.3,1,2.9,1.9c0.6,0.9,0.7,2.3,0.2,4c-0.4,1.5-0.8,2.5-1.4,3.1c-0.6,0.6-1.3,1.1-2,1.3
			c-0.7,0.3-1.5,0.3-2.3,0.2s-1.6-0.2-2.3-0.3c-1.3-0.2-2.5-0.4-3.7-0.6c-1.2-0.2-2.3-0.3-3.4-0.2c-1.1,0.1-2.1,0.3-3,0.7
			s-1.7,1.2-2.3,2.4l-2.8,5.2l-6.5,7.9c-0.2,0.9-0.9,1.7-2,2.3c-1.1,0.6-2.1,1.1-3.1,1.5c-0.8,1.1-1.4,2.1-1.7,2.9s-0.7,1.7-1.1,2.5
			c-0.4,0.8-0.9,1.7-1.4,2.4c-0.6,0.8-1.4,1.7-2.6,2.7c-3.7,4.9-7,9.5-9.9,13.7c-1.3,1.8-2.6,3.5-3.8,5.4c-1.2,1.8-2.3,3.5-3.3,5.1
			c-0.9,1.6-1.8,3-2.5,4.2s-1.2,2.2-1.3,3l-1.8,3.2c-0.4,0.8-0.8,1.5-1.2,2.3c-0.4,0.8-0.6,1.3-0.6,1.5c-0.1,0.4-0.3,1-0.5,1.6
			s-0.6,1.3-1,1.9c-0.4,0.6-1,1.1-1.8,1.4s-1.6,0.3-2.7,0.1c-0.8-0.2-1.3-0.8-1.3-1.8c-0.7-0.4-1.1-0.8-1.3-1.2s-0.5-0.6-1.1-0.8
			c-0.6-1-1.1-1.8-1.4-2.3c-0.3-0.5-0.6-1.5-0.9-3c-0.4-0.5-0.6-1.2-0.6-2.1s0-1.6-0.1-2.2c0.2-0.8,0.2-1.6,0-2.3s-0.3-1.3-0.4-1.9
			c0.4-1.5,0.6-2.6,0.6-3.5s-0.2-1.7-0.9-2.5c-0.6-2.6-0.9-4.7-1.1-6.5c-0.2-1.8-0.3-3.4-0.6-4.9s-0.5-3.1-1-4.7s-1.1-3.5-1.9-5.7
			c0.1-0.4,0.2-1,0.2-1.8c0-0.8,0.1-1.5,0.1-2.3c0-0.8,0.1-1.5,0.1-2.1c0.1-0.7,0.1-1.1,0.2-1.4l-1.5-5c0.1-0.5,0.2-1.3,0.3-2.4
			c0.1-1.1,0-2.2-0.2-3.2c0-0.1,0.1-0.5,0.1-1.3c0-0.8,0.1-1.6,0.1-2.4c0.1-0.9,0.1-1.7,0.1-2.5s0.1-1.3,0.1-1.4
			c-0.1-0.2-0.2-0.1-0.5,0.4s-0.6,1.1-1,1.8c-0.3,0.7-0.7,1.5-1,2.1c-0.3,0.7-0.6,1.1-0.7,1.3c-1.8,1.2-3.3,2.7-4.5,4.5
			s-2.4,3.8-3.5,5.8c-3.5,4.4-6.7,8.4-9.5,12.1c-2.5,3.2-4.8,6.4-6.9,9.5c-2.1,3.1-3.3,5.4-3.7,6.8l-2.6,3.6
			c-0.7,0.9-1.3,1.8-1.8,2.7c-0.6,0.8-0.8,1.4-0.9,1.6c-0.2,0.8-0.5,1.6-0.8,2.4s-0.9,1.4-1.6,1.9c-1.6,0.4-2.9,0.5-4,0.3
			c-1-0.2-2.2-0.4-3.7-0.8c-0.4-0.9-0.9-2.1-1.2-3.5c-0.4-1.5-0.8-3-1.3-4.5c-0.3-0.5-0.5-1.2-0.6-2c-0.1-0.8-0.1-1.6-0.2-2.3
			c0.2-0.8,0.3-1.6,0.2-2.2s-0.1-1.3-0.2-1.9c0.4-1.5,0.6-2.6,0.6-3.5s-0.2-1.7-0.9-2.5c-1-5.1-1.7-9.7-2-13.8
			c-0.3-4.1-1.1-8.1-2.3-12.1c-0.3-0.5-0.5-1.3-0.6-2.2c-0.1-1-0.2-1.9-0.3-2.8c-0.2-1.1-0.2-2.3-0.3-3.5l1.1-4.3
			c0.1-0.2,0-0.9-0.3-2.1c-0.2-1.2-0.6-2.6-0.9-4.1c-0.3-1.3-0.7-2.6-1.1-4c-0.4-1.4-0.5-2.1-0.5-2.3c-0.4-4.8-1.2-8.7-2.4-11.6
			c-1.2-2.9-2.4-5.3-3.7-7.1c-1.3-1.9-2.4-3.4-3.2-4.6c-0.8-1.2-1.1-2.5-0.7-4c0.2-0.9,0.8-1.9,1.8-2.8c1-1,2.2-1.2,3.8-0.9
			l20.3,5.1c1.1,0.1,2.2,0.1,3.3,0.2c0.9,0,1.7,0,2.5,0.1s1.4,0.2,1.8,0.3L1359.8,669.4L1359.8,669.4z"
          />
          <path
            class="symbol"
            data-symbol="X"
            d="M1589.1,739c0.4,0.2,1,0.5,1.8,1c0.8,0.5,1.5,1,2.2,1.5c0.9,0.6,1.9,1.2,2.9,1.8c1.7,1.6,3.2,3,4.5,4.2
			c1.4,1.2,3.2,2.2,5.5,3.1c2.1,0.8,3.3,1.8,3.8,2.9c0.4,1.1,0.2,2.8-0.7,5c-0.4,1.1-1.2,1.9-2.3,2.4c-1.1,0.5-2.4,0.9-3.7,1
			c-1.3,0.2-2.7,0.1-4.2,0c-1.5-0.2-2.7-0.5-3.7-0.9c-2.6-0.7-5.3-0.6-8.2,0.2s-5.8,2.1-8.7,3.7c-2.9,1.6-5.9,3.3-8.9,5
			c-3,1.8-6,3.3-8.9,4.5l-1.6,4c-0.3,0.8-0.4,1.7-0.3,2.7c0.1,1,0.3,2.2,0.5,3.4c0.2,1.2,0.5,2.5,0.7,3.8s0.3,2.5,0.3,3.7
			c0,1.6,0.3,3.6,0.9,6c0.5,2.4,1.2,4.5,1.9,6.5c0.6,1.5,0.9,3.2,1.2,5c0.2,1.9,0.5,3.7,0.9,5.5c0.4,1.8,1.1,3.4,2.1,4.8
			c1,1.4,2.6,2.3,4.9,2.7l5.8,2.3c1.6,1.1,2.8,2.3,3.6,3.6s1.2,2.6,1.4,3.8c0.2,1.3,0.1,2.5-0.1,3.7c-0.3,1.2-0.6,2.3-1,3.3
			l-1.3,3.3c-0.2,0.6-1,0.9-2.3,1c-1.3,0.1-2.3,0-2.8-0.2c-0.8-0.3-1.3-0.7-1.6-1.2c-0.3-0.5-0.6-1-0.8-1.5c-0.3-0.6-0.6-1.2-1-2
			c-0.4-0.7-1-1.5-1.8-2.3l-5.5-2.2c-1.2-0.1-2.4-0.3-3.5-0.4c-2-0.2-3.5-0.5-4.6-1l-4.7-3.8l-2.8-1.1c-0.7-0.3-1.5-0.9-2.4-1.9
			s-1.7-1.6-2.3-1.9c-0.7-0.5-1.6-0.9-2.6-1.2c-1-0.3-2-0.6-2.9-1s-1.6-0.8-2.1-1.4c-0.5-0.5-0.6-1.4-0.1-2.5c0.8-1.9,1.7-3,2.8-3.2
			s2.2-0.3,3.4,0c1-0.2,1.9-0.4,2.9-0.7c0.9-0.3,1.7-1.3,2.4-3c0.3-0.7,0.3-1.4,0.2-2.1s-0.3-1.4-0.5-2.2c-0.2-0.8-0.4-1.6-0.5-2.5
			s-0.1-1.9,0.1-3c-0.2-0.9-0.4-2-0.5-3.4s-0.3-2.7-0.6-4.1c-0.3-1.4-0.8-2.6-1.3-3.7s-1.4-1.8-2.4-2.2c-0.6-0.2-1.4-0.2-2.4,0.2
			c-1,0.3-2.2,1.1-3.4,2.3c-1.6,1.1-3.3,2-5.1,2.7c-1.8,0.7-3.5,1.5-5.2,2.4c-1.7,0.9-3.2,1.9-4.6,3c-1.4,1.1-2.4,2.6-3.1,4.3
			c-0.4,1.1-0.5,2.2-0.3,3.2s0.6,2.1,1.1,3.2c0.4,1,0.8,2,1.2,2.7c0.4,0.8,0.5,1.4,0.3,1.8c-0.2,0.5-0.7,0.6-1.4,0.4
			c-0.7-0.2-1.5-0.5-2.3-0.8l-27.6-11.1c-1.5-0.9-2.7-1.8-3.7-2.7c-0.9-0.8-1.7-1.8-2.2-2.8c-0.6-1-0.6-2.1-0.2-3.2
			c0.3-0.7,0.9-1.3,2-1.7s2.1-0.7,3.3-0.8s2.2-0.2,3.3-0.1c1.1,0,1.8,0.1,2.1,0.2l7,2.8c1.3,0.5,2.8,0.5,4.4,0s3.3-1.3,5.1-2.3
			c1.7-1,3.4-2,5.1-3.1c1.7-1.1,3.3-2,4.8-2.6c2.2-1.8,4.3-3.1,6.4-4s4.2-2.2,6.3-3.8c1.1-0.5,1.9-1,2.5-1.7s1.3-1.1,2.2-1.3
			l1.3-3.3c0.2-0.6,0.3-1.5,0.1-2.9s-0.4-2.7-0.7-4.2c-0.3-1.5-0.7-2.8-1.2-4c-0.4-1.2-0.8-2-1.1-2.5c-0.8-2-1.2-3.8-1.1-5.4
			c0-1.5-0.5-3-1.6-4.5c-0.5-0.9-0.8-1.9-0.9-3c-0.2-1.2-0.4-2.3-0.6-3.5s-0.5-2.3-1-3.4c-0.4-1.1-1.1-2-1.9-2.7c-1-0.7-2-1.4-3-1.9
			s-2.1-1-3.2-1.6c-1.1-0.5-2.2-1.2-3.4-2c-1.1-0.7-2.3-1.8-3.5-3.2c-1.2-1.2-2-2.6-2.6-4.3c-0.6-1.7-0.6-3.2-0.2-4.4
			c0.7-1.7,1.6-2.7,2.9-3s2.3-0.3,3.1,0l2.8,1.1c0.4,0.9,1.2,1.6,2.3,2.1c1.1,0.6,2,1,2.7,1.2l16.8,6.7c0.3,0.1,0.8,0.3,1.6,0.5
			c0.8,0.2,1.6,0.4,2.5,0.5c1,0.2,2,0.3,3.1,0.5l7.4,3c0.6,0.2,1.8,0.5,3.5,0.7c1.7,0.2,2.9,0.5,3.5,0.7l5.3,3.5l2.5,1
			c0.5-0.1,1.5-0.2,3-0.1c1.5,0.1,3,0.2,4.7,0.3c1.7,0.2,3.3,0.3,4.9,0.6C1587.4,738.5,1588.5,738.7,1589.1,739L1589.1,739z
 M1569.6,739.3c-1.9,0.3-3.8,0.5-5.5,0.7c-1.5,0.3-3,0.6-4.6,0.8c-1.5,0.2-2.9,0.6-4,1.1c-1.6,0-2.4,0.5-2.4,1.3s-0.1,1.6-0.4,2.3
			c-0.1,0.3-0.3,1.4-0.5,3.4s-0.3,4.2-0.3,6.6s0.2,4.7,0.7,6.8c0.5,2.1,1.4,3.4,2.8,4c0.7,0.3,2.2-0.2,4.4-1.3
			c2.2-1.2,4.6-2.6,7.2-4.3c2.5-1.7,4.8-3.4,6.8-5c2-1.7,3.2-2.9,3.5-3.7l-0.7-1.7c-0.3-0.5-0.7-1.3-1.3-2.4s-1.3-2.4-2-3.6
			s-1.4-2.4-2.1-3.3C1570.6,740,1570,739.5,1569.6,739.3L1569.6,739.3z"
          />
          <path
            class="symbol"
            data-symbol="Y"
            d="M1735.2,817.8c0.6,0.3,1.2,0.8,2,1.5s1.5,1.4,2.2,2.1c0.7,0.7,1.3,1.3,1.8,1.9s0.7,1,0.5,1.1c-1.2,2.1-2.8,3.5-4.7,4
			c-1.9,0.6-3.8,0.3-5.8-0.8l-4.3-2.5c-1.3-0.7-2.4-1.1-3.3-1c-0.9,0.1-1.8,0.4-2.6,0.8c-0.8,0.5-1.6,1.1-2.3,2s-1.5,1.7-2.3,2.5
			c-0.8,0.3-1.5,0.6-2.2,1c-0.7,0.3-1.5,0.5-2.3,0.5c-0.7,0.4-1.5,0.8-2.3,1c-0.8,0.3-1.6,0.6-2.3,0.9c-1.1,0.7-2.8,1.8-5.1,3.3
			c-2.3,1.5-4.6,3.2-7.1,5.2s-4.8,4-7.1,6.2c-2.2,2.2-3.9,4.3-5.1,6.3l-2.9,5l-2.9,3.5c-0.2,0.3-0.4,0.9-0.8,1.8s-0.8,1.9-1.1,2.9
			c-0.3,1-0.5,1.9-0.7,2.8c-0.1,0.8,0,1.4,0.4,1.6c0.4,0.7,1.5,2,3.3,3.6c1.7,1.7,3.5,3.5,5.3,5.4c1.8,1.9,3.3,3.8,4.4,5.6
			c1.2,1.8,1.5,3.1,1,4.1c-0.3,0.5-0.6,0.8-0.8,1.2c-0.6,0.6-1.3,0.7-2,0.3c-1.3,0.3-2.3,0.4-3,0.3s-1.6,0.1-2.6,0.5
			c-0.4-0.2-0.8-0.2-1.3,0.1c-0.5,0.3-0.9,0.6-1.4,0.9c-0.5,0.3-1.1,0.6-1.7,0.8s-1.2,0.2-1.8-0.2c-0.7-0.4-1.5-1.2-2.4-2.4
			c-0.9-1.2-1.9-2.1-3.2-2.9l-9.3-5.3c-0.7-0.4-1.4-0.9-2.1-1.4c-0.6-0.5-1.2-1-1.9-1.6c-0.7-0.6-1.3-1.3-1.7-2l-6.2-3.6
			c-1.6-0.9-2.8-1.4-3.8-1.4s-2.3-0.5-3.8-1.4l-2.4-1.3c-3.2-1.9-5.2-3.5-5.7-5s-0.2-3.4,1.3-5.9c0.9-1.6,2.2-2.9,3.9-3.9
			c1.7-1,3.5-1,5.5,0.1l5.7,5.3c0.6,0.3,1.3,0.5,2.3,0.7c1,0.1,1.9,0.1,2.8,0s1.6-0.4,2.3-0.9s1.1-1.2,1.2-2.1l7.3-16.3
			c0.4-0.7,0.8-1.5,1.2-2.4c0.4-0.8,1-1.5,1.9-2c0.3-0.6,0.5-1.2,0.6-2c0.1-0.8,0.3-1.4,0.6-2c1.1-1.9,1.6-4,1.5-6
			c-0.1-2.1-0.7-4.7-1.7-7.9c0.2-1.8-0.3-3.8-1.4-6c-1.1-2.2-2.2-4.6-3.4-7.1c0.2-1.6,0.1-3.4-0.2-5.5c-0.3-2-0.4-3.8-0.2-5.5
			c-0.3-0.8-0.7-1.5-1.2-2s-1.1-1.3-1.5-2.2c-1.5-1.5-3.1-2.9-4.8-4.2c-1.2-1.5-1.9-3.1-2.2-4.7c-0.3-1.6,0.1-3.3,1.2-5.1
			c0.9-1.6,2-2.7,3.3-3.3s2.9-0.4,4.8,0.7l6.7,3.8c1.3,0.7,2.4,1.7,3.3,2.9c0.9,1.2,1.7,2,2.3,2.3l6.5,3.7c0.3,0.2,0.8,0.5,1.4,1
			c0.7,0.5,1.5,1,2.3,1.6c0.8,0.5,1.7,1.1,2.6,1.8c0.9,0.6,1.6,1.1,2.1,1.4l3.1,1.8c0.7,0.4,1,0.7,1,0.8s-0.2,0.7-0.6,1.4
			c-0.4,0.7-1.1,1.3-2,1.6c-0.9,0.3-1.9,0.7-3,1.2c-1.1,0.4-2.1,0.9-3.1,1.4c-1,0.5-1.8,1.2-2.3,2.2c-0.6,1-0.8,2.6-0.7,4.8
			c0.1,2.2,0.4,4.4,0.8,6.7c0.5,2.6,1.1,5.5,1.9,8.6c-0.2,0.3-0.3,0.8-0.4,1.4c-0.1,0.7-0.2,1.3-0.3,2c-0.1,0.7-0.1,1.3,0,1.9
			c0.1,0.6,0.3,1,0.5,1.1c0.7,0.4,1.6,0.3,2.7-0.3c1.2-0.5,2.4-1.3,3.6-2.2c1.2-0.9,2.4-1.7,3.5-2.5c1.1-0.8,1.9-1.2,2.4-1.3
			c0.2-0.4,0.9-0.9,1.9-1.5c1.1-0.6,2.3-1.2,3.6-1.9c1.3-0.7,2.5-1.4,3.7-2.1c1.2-0.7,2-1.5,2.4-2.2c0.3-0.5,0.2-1.2-0.3-2
			c-0.5-0.8-1.1-1.7-1.6-2.7c-0.5-1-1-2.1-1.3-3.2c-0.3-1.2-0.1-2.4,0.7-3.8c0.2-0.4,0.6-0.6,1.2-0.6c0.6,0,1.2,0,1.9,0.2
			c0.7,0.2,1.4,0.4,2,0.6s1.2,0.5,1.5,0.7c0.4,0.5,0.9,0.9,1.3,1.3s0.8,0.7,1.2,1c0.4,0.3,0.7,0.5,1,0.7l2.6,1.5
			c1.6,0.4,2.9,0.9,4,1.4c1,0.5,2.8,1.5,5.3,3c0.9,0.5,1.7,0.9,2.4,1.1c0.7,0.2,1.3,0.3,1.8,0.3c0.6,0,1.1,0.1,1.6,0.2
			C1733.9,817.1,1734.5,817.4,1735.2,817.8L1735.2,817.8z"
          />
          <path
            class="symbol"
            data-symbol="Z"
            d="M1816.8,861.4c0.3,0.3,0.9,1.2,1.8,2.6c0.9,1.5,2,3.3,3.4,5.5c1.3,2.2,2.8,4.7,4.3,7.4c1.5,2.7,3.1,5.5,4.7,8.2
			c1.6,2.6,3.1,5.2,4.5,7.7s2.7,4.7,3.9,6.6c1.2,1.9,2.2,3.5,3,4.8s1.4,2.1,1.8,2.4c0.5,0.4,1.8,1.1,3.8,2.1c2,1,4.2,2,6.6,3
			c2.7,1.2,5.6,2.4,8.9,3.9c1.9,1.6,2.7,3.1,2.6,4.6c-0.2,1.5-0.7,2.8-1.6,3.8c-2,2.3-3.8,3.8-5.3,4.3c-1.6,0.5-3.1,0.5-4.8,0
			c-1.6-0.5-3.5-1.2-5.5-2.2s-4.6-1.7-7.7-2.2c-1.4-0.1-2.6-0.5-3.5-1.1c-0.9-0.6-1.7-1.3-2.5-2c-0.8-0.7-1.7-1.3-2.5-1.8
			s-1.9-0.8-3-0.8c-2.7-0.3-5.4-0.6-8.1-0.8s-5.4-0.5-8-0.8c-1,0-2.2,0.2-3.8,0.6s-3,0.4-4.4,0c-0.4,0-1.4-0.2-3.2-0.5
			c-1.7-0.3-3.8-0.7-6.2-1.1c-2.4-0.5-4.9-0.9-7.6-1.3c-2.7-0.4-5.2-0.8-7.5-1.2c-2.4-0.3-4.4-0.5-6-0.6c-1.6-0.1-2.6,0-2.8,0.3
			c-0.5,0.6-0.3,1.7,0.6,3.4c0.9,1.7,1.6,3.5,2,5.4c0.6,2.1,0.9,3.9,1,5.5c0,1.6,0.2,2.5,0.6,2.8c0.4,2.5,0.7,5.1,0.8,7.8
			s0.1,5.5,0.1,8.3s-0.1,5.5-0.3,8.2s-0.3,5.2-0.3,7.5c-0.8,4.7-1.4,9.6-1.9,14.8c-0.5,5.2-0.9,10.1-1.1,14.8
			c-0.1,1.1,0.1,2.3,0.5,3.8c0.4,1.4,0.6,2.7,0.7,3.8c0.7,1.5,1.2,3.2,1.5,5.2c0.3,2,0.8,3.7,1.4,4.9c0.1,0.6,0.3,1.4,0.9,2.4
			c0.5,1,1.1,2,1.8,3c0.7,1,1.3,1.9,1.9,2.7c0.6,0.8,1,1.3,1.2,1.5l3.4,2.9c0.5,0.7,0.9,1.4,1.3,2.2c0.8,1.4,1.3,2.7,1.7,4l6.3,3.5
			l2.8,4.3c0.6,0.5,1,0.8,1.3,0.8c0.3,0.1,0.6,0.1,1,0.2s0.8,0.2,1.2,0.4c0.5,0.2,1.2,0.7,2.2,1.5c1,0.8,1.9,1.6,2.8,2.2
			c0.9,0.7,2.2,1.2,3.7,1.7c2.7,1.2,5.7,2.2,8.8,2.8c3.2,0.7,6.1,1.3,8.9,1.8c0.8,0,1.6,0,2.4-0.1s1.6-0.2,2.5-0.2
			c1.8-0.7,3.8-0.9,5.9-0.8c0.6,0.5,1.2,0.6,1.9,0.4c0.7-0.2,1.4-0.5,2.2-0.7c0.7-0.2,1.5-0.2,2.3-0.1c0.8,0.1,1.7,0.9,2.6,2.2
			l0.1,0.7c-0.1,0.2-0.2,0.5-0.3,0.9c-0.2,0.3-0.5,0.8-1.1,1.5l-3.5,2.7c-0.8,0.9-2.1,1.5-3.9,1.9c-1.8,0.4-3.8,0.7-5.9,1
			c-2.1,0.2-4.2,0.4-6.1,0.6c-2,0.2-3.5,0.4-4.6,0.6c-3.3-0.1-6.7-0.7-10.2-1.7s-6.8-2-9.7-3.2c-3-1.1-5.5-2.2-7.5-3.3
			s-3.2-1.7-3.4-1.9l-6.1-5.2c0.3-0.4,0.3-1-0.2-1.7c-0.2-0.4-0.5-0.7-0.9-1l-4.7-4.1l-2.9-0.8l-5.7-4.9c-0.2-0.2-0.7-0.7-1.5-1.5
			s-1.5-1.6-2.3-2.5c-0.8-0.9-1.5-1.7-2.2-2.5s-1.1-1.3-1.4-1.5c-2.3-3-4.1-6.5-5.1-10.4c-1.1-3.9-1.9-7.9-2.4-11.9s-1-7.8-1.2-11.4
			c-0.2-3.6-0.5-6.7-0.9-9.1c0.4-1.5,0.7-3.2,0.7-5.1c0-1.9,0.3-3.8,0.8-5.5c0.8-2.3,1-4.6,0.5-7s-0.8-4.7-0.9-6.9
			c0.4-2.9,0.9-5.5,1.7-7.8c0.7-2.3,1.3-4.8,1.6-7.5c-0.4-1.3-0.5-2.6-0.4-3.9c0.1-1.3,0.2-2.6,0.3-3.9s0.1-2.7-0.1-4
			s-0.7-2.6-1.7-3.9c-1.5-1.7-2.9-3.5-4.1-5.4s-2.5-3.8-3.7-5.7c-1.2-1.9-2.4-3.7-3.6-5.3c-1.2-1.7-2.6-3.2-4-4.4
			c-0.5-0.4-0.4-1.1,0.2-2c0.7-0.9,1.2-1.7,1.7-2.2s1.5-0.9,3.1-1c1.5-0.1,3.2,0,4.8,0.1c1.7,0.2,3.2,0.4,4.6,0.7
			c1.4,0.3,2.2,0.5,2.3,0.6c1.9,0.2,3.8,0.6,5.5,1.2c1.8,0.5,3.6,1.1,5.3,1.8c1.8,0.6,3.6,1.3,5.5,2c1.9,0.7,3.9,1.2,6.1,1.6
			c2.9,0.1,5.6,0.4,8.1,0.9c2.5,0.5,5.4,0.7,8.7,0.6c1.2,0.2,2.6,0.3,4.2,0.2c1.5-0.1,3.1-0.2,4.6-0.2s3-0.1,4.5-0.1
			s2.7,0.3,3.9,0.7c0.4,0,0.9-0.1,1.5-0.3s1.1-0.3,1.7-0.5c0.7-0.1,1.3-0.3,2-0.6c0.3-0.4,0.5-1.1,0.4-2l-0.1-1.1
			c-0.6-1.1-1.6-2.9-3-5.4s-2.9-5.2-4.6-8s-3.4-5.5-5.1-7.9c-1.7-2.4-3.2-4.2-4.4-5.2c-0.2-0.2-0.9-0.5-2.1-0.9s-2.4-0.7-3.8-1.1
			s-2.7-0.7-3.9-1c-1.2-0.3-2-0.5-2.4-0.5c-1.7,0.7-3.6,1.1-5.7,1.3c-2.1,0.2-4.2,0.3-6.2,0.2s-3.9-0.3-5.6-0.7
			c-1.7-0.4-3-0.8-3.8-1.4c-0.4-0.2-0.8-0.5-1.4-1c-0.5-0.5-1-1-1.3-1.7c-0.3-0.7-0.5-1.4-0.5-2.1s0.4-1.5,1.1-2.3
			c1.7-1.9,3.6-3.3,5.8-4.2c2.2-0.8,4.5-1.4,6.9-1.5c2.4-0.2,4.8-0.2,7,0c2.3,0.2,4.4,0.4,6.4,0.5c1.6-0.6,3.4-1,5.2-1.3
			c1.9-0.3,3.3-0.4,4.4-0.3c0.4,0,1.3,0,2.6,0.2s2.7,0.5,4.1,0.8c1.4,0.3,2.7,0.6,3.9,1C1815.9,860.9,1816.6,861.2,1816.8,861.4
			L1816.8,861.4z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            class="reset-carving"
            d="M1357.8,255.3c1.4-9.7,2.6-19.4,7.8-28c5.7-9.5,11.5-19,18.5-27.7c2.7-3.4,5.4-6.8,8.4-10
					c1.3-1.4,1.3-2.4,0.1-3.6c-5.8-5.9-11.1-12.2-16.2-18.7c-5.5-7-11-14-15.8-21.4c-3.1-4.8-4.5-10.7-5.9-16.3
					c-0.3-1.3-1.8-3.1-0.6-3.9c1.4-0.9,2.2,1.3,3.2,2.2c6.9,5.5,12.6,11.9,17.6,19.2c5.1,7.6,11,14.7,17.1,21.7
					c2.8,3.2,5.6,6.5,8.6,9.6c1,1,1.6,1.1,2.7,0.1c11.3-10.8,23.9-20.1,37.1-28.5c9.3-5.9,19.4-9.2,30.4-10.2c1-0.1,2.5-1.2,3,0
					c0.4,1.1-1.4,1.7-2.3,2.4c-4.5,3.5-8.8,7.3-13.5,10.5c-4.1,2.8-8.8,4.9-13,7.5c-8.9,5.5-17.3,11.5-25.1,18.4
					c-2.9,2.6-5.8,5.2-8.7,7.7c-1.2,1.1-1.2,2,0,3.1c6.7,6.6,13.9,12.8,21.1,18.9c5.1,4.3,10.9,7.8,15.5,12.5
					c3.8,3.9,7.3,8.2,10.9,12.3c0.4,0.4,0.9,0.9,0.5,1.4c-0.4,0.6-1,0.1-1.5,0c-5.6-1.5-11-3.6-16.4-5.9c-4-1.7-7.1-4.8-10.5-7.4
					c-9.6-7.5-18.9-15.5-27.5-24.2c-1.2-1.2-1.9-0.8-2.7,0.2c-4,4.7-8,9.4-11.6,14.4c-6.3,8.8-12,18-17,27.6
					c-2.1,4.1-5.5,7.5-8.3,11.3c-1.3,1.8-2.6,3.6-4,5.4c-0.3,0.4-0.6,0.8-1.1,0.7C1357.6,256.3,1357.9,255.7,1357.8,255.3z"
          />
        </g>
        <g>
          <path
            ref="resetSymbol"
            class="reset-symbol"
            d="M1442.8,213.9c-1.1-1.1-2.6-1.7-3.8-2.8c-11.9-9-23.2-18.7-33.8-29.3c14.3-14.1,30.6-26.1,48.5-35.3
					c4.9-4,11.9-9.3,17.1-13.2c-7.3,0.8-14.5,2.4-21.6,4.4c-18.7,9.6-35.9,22.2-50.8,37c-8-8.5-15.6-17.5-22.7-26.8
					c-3.8-5-7.5-10.1-11-15.4c-4.7-4.4-9.4-8.9-14.5-13.4c1.6,6.5,3.7,12.8,6.2,18.9c0.7,1.5,1.9,2.6,2.8,4c2.7,3.9,5.7,8,8.6,11.8
					c7.4,9.7,15.3,19,23.7,27.9c-8.1,8.8-15.5,18.4-22,28.5c-3.9,6.1-7.5,12.5-10.8,18.9c-2,7.1-3.5,14.3-4.1,21.7
					c3.8-5.1,9.2-12.3,13-17.2c3-6.2,6.5-12.2,10.3-18.1c6.1-9.5,13-18.4,20.5-26.7c9.7,9.7,20,18.7,30.8,27.1
					c1.3,1,4.6,3.5,5.8,4.4c0.6,0.5,1.2,1.1,2,1.4c3.1,1.3,6.1,2.6,9.2,3.7c3.1,1.1,6.3,2.2,9.5,3.1c-1.8-2.1-4.7-5.4-6.5-7.5
					C1449.3,221.3,1442.8,213.9,1442.8,213.9z"
          />
        </g>
      </g>
      <circle
        id="lueur_x5F_selection"
        style="
          fill: url(#lueur_x5F_selection_00000127738335446751093720000000995855984742420143_);
        "
        cx="100"
        cy="100"
        r="90.9"
        ref="halo"
        class="halo"
      />

      <g ref="drop">
        <g>
          <defs>
            <path
              id="SVGID_2_"
              d="M314.7,627.9c-0.5-0.1-1-0.2-1.5-0.3c1.1,0.2,2.3,0.4,3.5,0.7C316,628.1,315.4,628,314.7,627.9
				L314.7,627.9z M570.2,505.9c-1.5-68.6-23.1-137.9-72.4-231.8c-44.1-84-113.4-166.5-143-201.7l-1.6-1.9
				c-23.4-27.8-57.9-60.5-67.5-69.5c-0.4-0.4-0.7-0.7-1-0.9c-0.4,0.3-0.7,0.7-1.2,1.1c-10.3,9.8-43.7,42-66.6,69.3l-1.6,1.9
				C185.8,107.5,116.5,190,72.4,274C23.2,368,1.5,437.3,0.1,505.9C-1.4,577.6,19.6,627.7,61,650.7c19.6,10.9,37.8,16.3,57.8,16.3
				c17.8,0,37.1-4.2,60.1-12.7c4.6-1.7,9-3.3,13.2-4.9c37.7-13.9,60.1-23.1,92.5-23.1s55.8,9.2,93.6,23.1c4.2,1.6,8.6,3.2,13.2,4.9
				c23,8.4,42.3,12.7,60.1,12.7c20.1,0,38.2-5.4,57.8-16.3C550.6,627.7,571.7,577.6,570.2,505.9L570.2,505.9z M253.7,628.1
				c0.1,0,0.2,0,0.3-0.1c0.8-0.2,1.5-0.3,2.3-0.4c0.2,0,0.4-0.1,0.6-0.1C255.8,627.7,254.8,627.9,253.7,628.1z M284.2,328.2
				c-51.6,0-93.5-42.1-93.5-94s41.9-94,93.5-94s93.5,42.1,93.5,94S335.9,328.2,284.2,328.2z M415.5,660.6c0.2,0,0.4,0.1,0.6,0.1
				s0.4,0.1,0.6,0.2C416.3,660.8,415.9,660.7,415.5,660.6z"
            />
          </defs>
          <clipPath
            id="SVGID_00000145774011835028768940000004847341200643040949_"
          >
            <use
              xlink:href="#SVGID_2_"
              style="overflow: visible"
              ref="dropClip"
            />
          </clipPath>
          <g
            style="
              clip-path: url(#SVGID_00000145774011835028768940000004847341200643040949_);
            "
          >
            <image
              style="overflow: visible; enable-background: new"
              width="5255"
              height="2568"
              xlink:href="@/assets/images/ghosts/ouija/drop_bg.jpg"
              transform="matrix(0.39 0 0 0.39 -946.27 -167.83)"
            ></image>
          </g>
        </g>
        <g>
          <path
            d="M446.6,367.6c8.2-5.4,10.7-13.6,9.6-22.8c-1-9-5.9-15.7-14.7-18.7c-14.8-5.1-29-3.3-41.1,6.4c-9,7.2-13.5,17.6-15.6,29
			c-3.1,16.4-1.1,32.2,5.3,47.4c1,2.5,2.2,4.9,4.3,7.1c2.8-4.1,5.8-8,9.7-11.1c8-6.2,16.9-7.9,26.6-5.9c2.4,0.5,5.1,0.2,7.3,1.8
			c2.1,1.6,2.6,3.4,0.1,4.7c-6.2,3.3-8.1,9-9.6,15.2c-2.3,9.7-0.8,18.8,5.2,26.7c4.7,6.1,11.2,9.7,19,9.9c5.6,0.2,9.6-2.6,12-8
			c-4.4-0.6-8.4-1.5-12.2-3.2c-12.9-5.7-16.9-22.7-7.7-33.5c6.9-8.1,18.9-13.8,31.5-9.6c14.1,4.7,24.6,18.4,25.3,33.1
			c1,21.8-12.7,39-32.8,45.6c-1.8,0.6-3.6,1.1-5.8,1.9c7.7,8.4,16.5,14.2,27.8,14.9c11.9,0.8,18.1-7.8,14.8-19.2
			c-0.3-1.1-1.9-2.5-0.4-3.8c1.4-1.3,3-0.4,4.3,0.2c9.1,4.1,14.6,11.5,17,20.8c3.6,13.9-1.6,24-14.6,33.4c-2.1,1.5-2.8,2.1-1.5,4.8
			c3.5,7.1,3.1,14.9,2.1,22.5c-1.3,10.3-1.7,20.2,5.2,29.1c1.9,2.5-0.9,3-2.7,3.2c-4.7,0.6-8.8-1.4-12.4-4c-2.5-1.8-3.3-1.2-4.9,1
			c-7.2,9.7-17.4,14.6-29,16.8c-5.4,1-10.8,0.9-16.3,0.1c-7.2-1-13.6,0.7-17.9,7.3c-2.1,3.2-3,6.4,0.2,9.6c2.2,2.2-0.5,2.3-1.4,2.5
			c-12.4,2.8-22.4-5-22.8-16.4c-0.1-3.1-1.1-4.4-3.7-5.6c-13.2-6.1-27.1-7.3-41.4-6.2c-10.7,0.8-21.4,3.9-32.3,1.8
			c-14.4-2.8-27.1-8.4-35.8-21c-0.5-0.7-0.8-1.6-1.2-2.3c-0.4-0.8-0.8-1.8,0-2.5c0.8-0.8,1.6,0,2.4,0.5c4.4,2.9,9.1,4.9,14.1,6.6
			c10.2,3.4,19.3,1.4,27.5-5.1c1.6-1.3,3.3-2.5,4.7-3.9c9.3-9.8,20.5-13.2,33.9-9.7c-0.2-3.2-1-6.1-0.6-9.1c0.6-4.5,1.7-5,5.4-2.5
			c5.3,3.7,10.7,7.3,17.4,9.3c0.5-4,0.4-7.9,1.4-11.7c4.2-15.9,17.5-25.6,33.2-25.9c12.1-0.2,27.5,10.2,30.5,19.2
			c6.2,19.1-5.1,31.8-20.9,34.8c5.7,3.7,18.8,5.1,24.8-1.2c8.4-8.8,14.3-18.5,13.1-31.2c-0.3-3.4-1.4-5.4-5.4-6.4
			c-12.3-3-22.5-9.9-30.7-19.6c-8.1-9.5-14.8-19.7-18-32c-0.4-1.5-1.2-2-2.5-2.5c-24.3-8.7-40.8-26.6-55.3-46.8
			c-5.1-7.1-10.1-14.2-16.8-20.1c-7.3-6.5-15.8-7.8-24.9-7c-2.4,0.2-4.8,0.4-7.1,1.1c-3.4,1-4.9-0.3-5.3-3.9
			c-1.4-11.5,2.9-20.3,12.8-26.6c-2.9-2.4-5.9-4-9.5-4.6c-3.1-0.5-3.9-2-3-5c6-20.4,26.7-25,40.7-16.4c1.1-4,2.3-7.9,3.4-11.8
			c4.6-17.8,14.8-31.9,28.6-43.6c4.5-3.8,8.9-7.7,13.9-10.7c8.7-5.1,13.5-17,9.7-27.6c-1.9-5.2-1.1-5.6,4.5-5.1
			c16,1.6,31.7,23.7,25.2,41c-0.4,1-0.6,2.1-1,3.2c25,1.5,45.1,29.2,44.7,55.4c-0.4,28.5-35.7,56-67,38.4
			c-18.8-10.5-22.8-40.7-6.6-53.2c8.1-6.3,19-7.6,27.8-1.6c9.1,6.2,11.9,15.6,7.6,26.1C448.4,363.7,447.5,365.7,446.6,367.6
			L446.6,367.6z M477.2,445.9c-1.8,0.2-2.4,1.1-2.5,2.8c-0.2,4.8-2.8,8.6-5.4,12.3c-1.4,2.1-2.5,2.4-5.4,2.2
			c4.3-2.9,6.4-6.9,7.9-11.2c0.4-1.2,1.2-2.8,0-3.3c-1.6-0.7-1.9,1.2-2.3,2.3c-2.4,5.9-6.8,9.7-13,11.3c-9.7,2.5-21.2-3-27.6-12.8
			c-4.6-7.1-6.7-14.9-5.4-23.2c1.3-8.1,2.4-16.4,10.1-21.9c-3.5-0.4-6.8-0.5-10.1-0.4c-11.2,0.2-21.4,7.2-25,17.5
			c-3,8.6-1,16.8,2.7,24.7c0.4,0.9,1.5,2,0.5,2.7c-1.3,1-2-0.6-2.6-1.3c-4-4.1-5.2-9.5-6.2-14.9c-0.5-2.5-1.5-4.4-3.3-6.1
			c-4.1-4.1-6.8-9.2-8.9-14.6c4.2,4,6.2,10,11,13.5c1.1-1.9-0.1-3-1-4.2c-11.6-15.2-13.6-32.9-12.9-51.2
			c0.4-10.5,5.8-19.5,10.2-28.8c8.4-18,38.2-30.2,58.8-17.2c15.7,10,19.5,30,7.5,44c-3.8,4.5-8.6,7.2-14.8,6.1
			c-1.8-0.3-3.5-0.5-5.2-0.2c-10.4,1.2-19.1-6.9-18.7-17.1c0-0.8-0.2-1.9,1-2.1c0.8,0.7,0.4,1.7,0.5,2.5c1.4,8.7,6.1,14.1,15.2,13.3
			c9-0.8,15-9.7,14.5-17.2c-0.6-7.9-6.4-13.9-14.3-15.6c-4.6-1-9,0.2-13.4,1.4c-2.2,0.6-3.9,1.9-5.2,3.6c-4.5,6.2-8,12.7-6.1,20.8
			c1.7,7.2,3.2,14.6,9.8,19.2c9.9,6.8,20.8,9.5,32.6,6.9c14.8-3.3,25.3-12.2,30-26.5c4.7-14.3,1.4-28-6.8-40.4
			c-7.1-10.6-16-19.1-29-22.1c-2.5-0.6-4-0.2-5.3,2.2c-0.7,1.4-1.2,3.4-3.5,3.6c6.5-17.5,4-23.8,2.3-29.7
			c-3.1-10.9-13.3-20.1-20.8-19.5c4.2,12,1.5,22.3-7.9,29.7c-3.1,2.4-6.2,4.8-9.5,7c-20.8,14.1-31.9,34.5-38.3,58.1
			c-0.5,1.9-0.5,3.5,1.7,4.4c1.5-8.7,7.7-24.4,11.5-28.9c0.9,1.5-0.3,2.6-0.9,3.8c-3,6.9-6.3,13.8-7.6,21.3
			c-0.5,2.6-2.2,5.4,0.1,8.5c2.4-6.9,3.2-13.8,6.8-19.8c-0.7,4.6-2.1,9-3.1,13.5c-0.9,4.3-3.2,8.6-0.4,13.1c0.6,1,1.5,2.7-0.1,3.3
			c-1.6,0.6-1.8-1.4-2.3-2.4c-3.3-6.2-8-11.1-14.3-14.4c-14.3-7.3-26.4,1-32.5,12.7c-0.4,0.8-1.3,2.2,0.5,2.3
			c4.8,0.4,8.4,2.7,11.3,6.3c0.8,1,2.1,0.8,2.7,0.1c0.9-0.9-0.4-1.5-0.9-2.1c-2.3-2.7-5.6-4-8.7-5.7c0.4-0.5,0.6-0.7,0.8-0.8
			c0.4-0.1,0.7,0,1.1,0.1c4,0.9,7.2,3,9.7,6.2c1.2,1.6,2.5,2.3,4.4,1.3c-0.6-2.5-3.4-3.2-4.4-5.5c5-0.5,5.3,5.7,9.5,6
			c0.7-1.7-1.7-2.1-0.5-4.4c2.7,5.7,8.5,6.4,12.9,9.1c2.7,1.6,5.4,3.4,7.6,5.7c2.1,2.2,4.1,4.5,4.7,7.8c-2.9-2.1-5.4-4.5-8.1-6.8
			c-10.2-8.4-21.9-9.6-32.6-3.8c-6.9,3.7-11.9,15.8-9.2,21.4c16.5-5.2,30.5-2.4,42,11.6c9.6,11.6,17.9,24.4,28.6,35.2
			c6.8,6.9,13.4,13.8,22.1,18.5c14.7,8,29.7,13.9,46.9,10.5c14.7-2.9,24.4-11.9,31-24.8c3.4-6.6,3.3-13.5,2.5-20.7
			c-1.4-11.9-6.6-20.8-18-25.4c-15-6-29,3.2-32.1,16c-1.3,5.5-0.7,10.8,4.1,14.5c4.4,3.4,9.5,5.3,15.2,5.3
			c10.2,0,16.5-5.1,18.4-15.1c0.2-1.3-0.2-2.7,0.7-4.2c3.1,2.5,2.9,5.5,2.1,8.5c-1.1,4.3-3.3,8.4-2,13.1c0.2,0.8-0.2,1.7-1.4,2.1
			c-0.1-2.1-0.2-4.2-1.7-6.9c-1.3,5.9-0.1,12-5.1,15.9C476.1,454.1,477.8,450.2,477.2,445.9L477.2,445.9z M494,544.2
			c-2-2.1-1.6-4.6-2.4-6.7c-0.4-1.2-1.1-1.3-2-1.3c-1.4,0.1-1.1,1.1-1,2c0.2,1.5,0.6,3,0.6,4.5c0.5,12.8-5.4,22.7-14.4,31.1
			c-10.8,10.2-26.9,9.9-37.2-0.8c-0.9-0.9-1.6-2-3.3-1.3c2.4,5,7.4,7.2,11.2,10.7c-11.3-2-14-13.6-22.1-19.9
			c1.2,8.3,6.5,13.5,12.2,19.4c-3.4-0.8-3.3-0.8-4.8-2c-10.7-9.2-11.4-21.1-9.2-33.7c0.6-3.7,2.7-6.8,5.8-9.1
			c0.9-0.6,1.9-1.4,2.7-0.4c0.7,0.9-0.6,1.5-1.1,2.1c-3.8,4.7-4.9,15.3-1.6,20.3c3.7,5.7,9,9.2,16.3,8.6c6.6-0.6,15.8-6.7,17.1-11.8
			c4.1-14.8-5.2-28.9-20.6-30.9c-4.6-0.6-9.4-1.9-13.9,0.2c-11.2,5.2-18.7,13.5-19.7,26c-1.2,14.5,3.2,27,15.6,35.9
			c3,2.2,3.2,3.9,0.2,5.6c-3.1,1.7-4.7,4.6-6,7.6c-4.3,10.3,2.8,20.7,13.8,19.8c-1.2-1.6-1.8-3.4-1.8-5.4c0-9.5,9.6-17.8,19-15.5
			c9,2.2,17.6,1.3,26.1-1.9c9.4-3.5,16.9-9.3,21-18.9c-3.7,4.1-7.4,8.3-11.9,11.5c-5.5,3.9-12.4,6.3-15.4,4.9
			c5.5-2.1,10.8-3.9,15.3-7.5c6-4.9,10-9.9,10.1-13.5c-2.3,2.3-4.4,4.5-6.6,6.6c-2.2,2.1-4.4,4.4-8,5.6c1-2.3,2.6-3.2,3.8-4.4
			c5-5,10.8-9.4,11.6-17.3c0.2-1.7,0.5-5,2.1-4.7c2.4,0.5,1.3,3.4,1.1,5.3c-0.9,11.3,9,20.8,16.8,21.1c-2.9-3.9-5.5-7.7-6.2-12.6
			c-1-6.7-0.4-13.5-0.3-20.2c0.1-6-0.4-12-3-17.5c-0.3-0.6-0.3-1.6-1.5-1.3c-1,0.3-1,0.8-0.6,1.7c2.6,6.4,2.6,13.1,2.6,19.8
			c0,2.2,1.5,4.3,0,6.9c-1.6-2.4-1.6-4.7-1.6-7.1c0-6.4-0.9-12.6-3.1-18.6c-0.4-1-0.9-2.1-2.1-1.5c-1.2,0.6-0.1,1.6,0,2.2
			c1,3.8,2.7,7.4,2,11.7c-1.2-0.9-1.1-1.9-1.4-2.9c-0.9-3.1-2-6.2-3-9.3c-0.3-0.8-0.9-1.4-1.7-1c-0.8,0.3-0.1,1-0.1,1.6
			C493.8,539.9,495.5,541.7,494,544.2L494,544.2z M510.3,481.5c1,8.5-0.8,14.1-6.3,18.3c-5.2,4-11.4,4.4-17.5,3.1
			c-10.7-2.3-20-7.4-26.8-16.4c-1-1.3-1.8-3-3.8-1.3c1.4,2.3,2.8,4.5,4.7,7.6c-4.6-1.6-4.8-6.6-9-8.1c2.3,5.8,6,9.8,8.9,14.4
			c-4.7-3.1-7.8-7.5-10.3-12.3c-0.8-1.6-1.7-2.4-3.7-1.3c4.5,8.4,10.6,15.7,17.2,22.7c-1.4,0.7-1.8,0.1-2.2-0.3
			c-7-5.9-12.2-13.2-16.8-21c-0.9-1.5-1.5-3-3.8-2.3c5.9,13.2,15.7,23.5,26.2,33.5c-1.8,0.8-2.2,0-2.8-0.5
			c-10.2-8.9-19-19-25.1-31.2c-1-1.9-1.9-3.5-4.4-3.1c-0.5,0.9,0.1,1.5,0.5,2.1c5.6,9.9,12.1,19.2,19.8,27.5
			c10.7,11.6,23.6,18.1,39.9,17.4c11-0.4,18.7-5.1,23.9-14.8C524.8,504.1,521,489.1,510.3,481.5L510.3,481.5z M369,586
			c0.1-1.1,1-0.8,1.4-1.1c0.5-0.3,1.2-0.4,1.8-0.5c14.1-1,27.8,0.5,40.8,6.5c3.3,1.5,5.4,1,7.5-1.8c-9.8-3.7-19.6-6.6-29.7-8.8
			c6.7-1.2,12.8,1.6,19.6,2.4c-3.7-6.3-10.7-4.4-16.5-7.6c4.8-0.9,8.3,0.5,12.1,1.2c-0.8-1.8-1.9-3.2-2.2-4.8
			c-0.8-3.9-2.8-5.6-6.9-6.1c-3.5-0.4-6.8-2.4-9.1-5.3c4.1,0.8,7.5,3.5,11.8,3.5c0.7,0,1.5,0.8,1.7-0.5c0.1-0.8,0.1-1.7-0.9-1.8
			c-7.2-0.9-12.5-5.3-17.9-9.6c-0.3,6.5,3.1,11.9,5.2,17.8c-0.9-0.1-1.2,0-1.4-0.2c-0.4-0.2-0.9-0.5-1.2-0.9
			c-4-4.9-9.7-4.9-15.1-4.9c-8,0-15.1,2.8-21.2,8.2c-8.9,8-18.8,13.5-31.4,11.6c-3.6-0.5-7-2.2-10.6-2.8c9,7,19.1,11.1,30.6,11.8
			c8.5,0.5,16.6-1.7,25-2.7c16.7-2,33-1.7,48.3,6.4c1.6,0.8,2.1,0.3,3-0.9c1.2-1.8-0.4-2-1.2-2.4c-9.1-3.9-18.5-6.8-28.5-7.2
			C379.1,585.8,374.1,586,369,586L369,586z"
          />
          <path
            d="M191,559.2c13-3.4,23.8-0.2,33,8.8c5.3,5.2,11.1,9.8,18.7,11.2c8.9,1.6,16.7-1.6,24.5-5.4c0.5-0.2,0.8-0.6,1.3-0.8
			c1.7-0.6,3.5-3.4,4.8-2c1.7,1.8-0.6,4-1.7,5.7c-6.7,9.9-16.9,14.7-27.7,18.4c-10.9,3.7-21.9,2-32.9,0.5
			c-16.8-2.2-33.2-1.7-48.9,5.9c-2.2,1.1-3.1,2.2-3.1,4.8c0.4,10.3-9.2,19.6-22.3,17c-0.9-0.2-2.8-0.3-2-2.4
			c3.1-7.8,1-12.5-6.8-15.9c-4-1.8-8.1-1.7-12.3-1c-15.4,2.5-35.9-5-44.2-17.8c-1.2-1.8-1.9-2-3.7-0.8c-3.5,2.5-7.3,4.5-11.9,4.7
			c-1.8,0-3.3-0.3-4.3-1.8c-1.1-1.6,0.8-2.2,1.4-3.1c4.9-7.3,5.8-15.3,4.6-23.8c-1.1-7.3-1.8-14.7,0-22c0.6-2.5,3-5.3,2.4-7.3
			c-0.6-2.1-4.2-2.9-6.2-4.7c-7-6.6-12.4-13.9-11.8-24.1c0.7-11.5,6.3-20.2,15.8-26.4c0.9-0.6,2.1-0.9,3.2-1.3
			c1.1-0.4,2.2-0.8,3.2,0.1c1.2,1,0.2,2.1-0.1,3.1c-2.3,8.2-0.6,14.4,5.1,17.9c2.2,1.4,4.7,2,7.2,2.1c10.1,0.4,23.5-6.4,29.7-15.2
			c-7.8-2.2-15.2-5.3-21.5-10.3c-10.3-8.2-15.6-18.9-16.8-32c-1-10.2,2.6-19.1,8.6-26.8c10-13,31.1-19.3,47-3.3
			c8.8,8.8,7.7,21.8,1.5,29.7c-4.3,5.5-11.2,7-17.9,8.2c-0.6,0.1-1.2,0.1-1.9,0.2c2.2,6.3,8.3,9.5,15,7.9c16.8-4,23.5-18.8,21.5-33
			c-0.7-5.2-1.7-10.3-5.1-14.5c-1.3-1.6-2.9-2.8-4.6-3.9c-2.9-1.9-2.8-3.6,0.3-5.4c1.7-1,3.7-0.8,5.5-1.2c16.1-3.5,28.1,2.3,36.7,16
			c0.2,0.3,0.5,0.5,0.9,0.9c4.9-6.8,7.2-14.6,9-22.6c3.6-16.8,2.3-33-5.4-48.6c-10.9-22.1-36.9-24.8-52.1-18.4
			c-13.3,5.6-18,23.2-9.6,36c2.2,3.4,2.5,3.7,5.9,5.6c-1.4-3.7-3.1-7-4.2-10.6c-2.1-7.3,0.4-13.4,5.3-18.5
			c11.4-11.6,30.6-10.8,39.2,8.3c6.5,14.6,1.1,38.5-21.1,46.1c-14.8,5.1-28.1,2.2-40.5-6.4c-25.7-17.8-23.9-49.3-8.2-70.5
			c7.1-9.6,16.1-16.9,28.4-19.2c4.6-0.9,4.6-0.8,3.3-5.4c-4.8-17.5,9.7-38.5,27.6-39.9c2.7-0.2,4.2,0.6,3.1,3.8
			c-1.6,4.5-2.6,9-1.5,13.8c1.6,6.7,5.3,11.9,11.1,15.8c11.6,7.8,21.9,17,30.2,28.3c6.2,8.5,9.8,18,12.5,28c0.9,3,1.7,6.1,2.7,9.5
			c7.7-5.1,15.8-5,24.1-2.4c9.7,3.1,13.8,10.9,16.8,19.7c0.8,2.3-0.2,3.6-2.6,3.9c-3,0.4-5.5,1.8-8.1,3.2c-2,1.2-1.7,1.8,0.1,3
			c8.7,6.3,12.7,14.6,10.7,25.5c-0.6,3.5-2,4.4-5.6,3.5c-5-1.2-10-1.6-15.1-1.2c-7.9,0.7-14.2,4.3-19.7,9.8
			c-4.9,4.9-9,10.4-12.8,16.1c-8.4,12.9-19.1,23.8-30.6,33.9c-7.2,6.4-16.2,9.9-24.9,13.8c-1.8,0.8-2.7,1.7-3.5,3.8
			c-8.9,24.6-23.4,43.9-50.2,51c-1.8,0.5-2.7,1.4-3.1,3.3c-2.6,12.1,2.4,21.7,9.7,30.8c7.6,9.5,17.5,9,27.9,4.3
			c-7.1-1-12.3-4.6-17.1-9.2c-8.3-7.9-6.3-27,1.1-33.1c8.5-7,17.4-12.8,28.8-11.4c16.5,2.1,29,13.5,30.8,31.2
			c0.7,6.9,0.6,6.9,6.8,3.7c4.2-2.2,8.3-4.5,11.9-7.6c2.6-2.2,3.4-0.8,4.3,1.5C192.7,552,191,555.4,191,559.2L191,559.2z
 M88.4,449.8c-1.5-1.7-1.9-3-1.6-4.2c0.8-3.3-0.2-6.1-1.3-9.1c-1.1-2.9-2.2-6.1-0.4-9.2c0.4-0.6,0.5-2,1.7-1.6
			c0.9,0.3,0.6,1.2,0.7,2c0.5,3.5,0.8,7,3.2,10c6.3,7.8,17.3,9.4,26.9,3.8c4.2-2.5,7.2-5.4,7.9-10.8c1.5-12.2-11.7-24.9-24.1-23.6
			c-8,0.8-15.2,3.8-19.9,9.8c-6.1,7.9-7.8,17.7-6.9,27.7c1.1,12.1,8.2,21,17.2,28c12.1,9.4,26.4,9.8,40.6,6.6
			c11.2-2.5,21.3-8.1,30.6-14.7c9-6.5,16.1-15.2,23.3-23.6c7.4-8.6,13.4-18.2,21.1-26.6c10.9-12,20.5-15.4,38.9-10.8
			c2.9,0.7,3.2-0.1,3-2.2c-1.1-11.2-5.9-18.8-18-21.6c-11-2.5-19.8,2-27.7,9.3c-1.6,1.5-2.9,3.5-5.1,4.3c-0.1-0.4-0.3-0.7-0.2-0.9
			c0.2-0.6,0.4-1.2,0.8-1.7c3.4-5.6,8.4-9.5,14.2-12.3c3.7-1.8,7.9-2.8,9.9-7c0.1-0.1,0.6,0,1,0c0.2,1.4-1.5,2.1-0.9,3.8
			c4-0.6,4.6-6.6,9.4-6.3c-1.1,2.3-3.9,3.2-4.7,6.8c6.3-2.3,9.3-9.3,16.8-8.5c-4,2.7-8.3,3.9-10.6,8.1c1.4,0.6,2.5,1.2,3.7-0.4
			c3-3.9,7.1-5.9,12.4-5.8c-2.6-6.1-6.3-11-11.6-14.3c-5.9-3.7-12.2-4.6-18.9-2c-7.6,2.9-13.1,8-17,15.1c-0.6,1.2-0.7,3-3.9,3.3
			c5.7-10.9-1.7-20.2-2.2-30.4c3.3,6.2,4.4,13.2,6.6,19.9c1.7-1.9,1.2-3.9,0.9-5.7c-1.6-8.1-4.4-15.7-7.9-23.1
			c-0.7-1.5-2.1-2.9-1.5-4.9c1,0.2,1.2,1,1.5,1.6c4.1,8,7.7,16.1,9.6,24.9c0.2,0.8-0.3,2.1,1.2,1.8c0.8-0.2,1.4-1.1,1.2-2.1
			c-0.1-0.8-0.1-1.5-0.3-2.3c-2.1-7-4.2-14.1-7.4-20.7c-7.7-15.7-18.1-29-33.1-38.5c-5.1-3.2-10.1-6.9-13.4-12.2
			c-4.6-7.3-4.8-15-1.4-23.3c-6.5,1.3-11.1,4.4-14.8,8.9c-10.4,12.7-9.3,26.6-3.9,40.8c-1.5,0.3-1.8-0.6-2.3-1.3
			c-3.9-5.3-4.3-5.5-10.8-3.1c-0.9,0.4-1.9,0.8-2.8,1.2c-15.7,8-25.6,20.8-29.6,37.8c-2.6,11-1.4,21.8,5.2,31.5
			c9.8,14.5,26.2,21.3,43.2,17.3c8.5-2,16.9-5.1,21.1-14.1c2.5-5.3,3.9-10.8,4.2-16.5c0.6-9.2-7.3-19.2-16.3-20.9
			c-15.2-3-24.2,7.4-22.6,19.5c0.9,6.7,7.7,12.7,14.7,13.1c7.8,0.5,11.9-3.5,14.1-11c0.5-1.6-0.4-3.4,0.9-5.2
			c3.2,6.7-2.9,18.2-12.5,19.4c-3.3,0.4-6.6-0.7-9.8,0.3c-3.5,1-6.8,0.3-9.9-1.5c-14.8-8.6-16.7-33.2-3.5-44.2
			c15.5-13,32.8-12.5,50.3-2.4c11.2,6.5,15.7,18,20.6,29c4.4,9.9,4.2,20.4,3.3,31.1c-1.2,13.2-4.7,25.3-12.9,35.9
			c-0.9,1.1-1.4,2.1-0.8,3.6c5.2-3.3,6.9-9.6,11.4-13.4c-2.4,5.1-4.7,10.2-8.7,14c-2.3,2.2-3.2,4.6-3.8,7.5
			c-1.1,5.4-2.4,10.7-6.6,14.7c-0.6,0.5-1.2,1.6-2.2,0.8c-0.6-0.6-0.2-1.4,0.2-2.1c0.3-0.4,0.6-0.9,0.8-1.3
			c5.8-13.6,5.4-24.7-4.3-34.1c-8.1-7.9-18-8.2-28.9-6.9c6.1,3.8,8.1,9.5,9.6,15.5c0.4,1.9,0.8,3.7,1,5.6c1.7,12.2-2,22.6-11.3,30.4
			c-13.7,11.4-26,9-35-6.3c-0.5-0.9-1.3-1.5-2.1-1.2c-1.1,0.4-0.5,1.5-0.2,2.3c1.6,4.9,3.9,9.2,8.7,13c-3.1,0-4.8-1.2-5.9-2.8
			c-2.5-3.8-4.9-7.7-5.4-12.4c-0.2-1.4-0.3-2.5-2.1-2.5c-1.1,4.2,1.5,8,2.2,12.6c-5-4.7-3.8-10.9-6-15.9
			C88.8,444.9,88.7,446.9,88.4,449.8L88.4,449.8z M139.5,620.2c6.9,0.1,12.9-4.1,14.7-9.9c1.8-6.1-1.4-14.4-6.8-17.6
			c-2.9-1.7-2.9-3.6,0.2-5.8c13.8-9.3,20.6-31.8,12-47.3c-4.5-8.1-11.1-14.3-20.6-15.4c-9.9-1.2-18.8,2.4-26,9.5
			c-7.3,7.1-7,21.2,0.4,28.1c6.1,5.7,15.3,7.4,21.5,4c7.2-3.9,10.2-10.9,8.9-20.1c-0.5-3.6-2.6-6.3-4.9-9.3c4,0.1,6.9,2.9,8.6,7.6
			c1.2,3.1,0.2,6.4,0.9,9.4c1.9,8.1-0.5,15-5.2,21.4c-2.1,2.8-4.4,5.6-7.9,6.8c4.2-5.8,9.7-10.7,10.8-18.9c-3.4,2.8-5.9,5.4-7.9,8.8
			c-3.2,5.5-7.9,9.3-14.7,11.7c1.9-3.1,4.8-3.7,6.6-5.6c1.8-1.8,4-3.3,5.1-5.9c-2.1-0.7-2.7,0.8-3.6,1.7
			c-7.1,7.8-22.6,10.6-31.2,4.6c-13.4-9.3-22-21.5-19.6-38.9c0.1-0.9,1.2-2.4-0.6-2.9c-1.7-0.4-2.2,0.8-2.4,2.2
			c-0.1,0.9-0.5,1.7-0.7,2.5c-0.2,1,0.2,2.8-1.4,2.5c-1.6-0.4,1.3-5.7-0.2-7.7c-3.2,4.2-3.5,9.3-5.7,14.7c-0.9-4.9,0.9-8.2,1.7-11.8
			c0.2-0.9,2.2-2.4,0-3.1c-1.7-0.5-1.7,1.4-2.1,2.5c-0.2,0.6-0.5,1.2-0.7,1.8c-1.7,5.9-1.9,12-2.3,18c-0.1,1.7,0.1,3.4-2.2,5
			c0.8-4.2,0.7-7.8,0.6-11.4c0-4.9,0.8-9.5,2.2-14.2c0.3-0.8,1.8-2.2-0.1-2.8c-1.7-0.5-1.6,1.4-1.9,2.2c-2.8,7-2.7,14.4-2.4,21.8
			c0.3,9.8,1,19.7-7.2,27.9c9.7-2.1,15.6-8.7,17.3-17.3c0.6-3.3-1-6.8,1.9-10.4c0.8,12.1,8.7,19.5,16.8,26.8
			c-6.3-2.3-10.1-7.6-15-11.7c2,5.6,6.2,9.5,10.5,13.3c4.5,3.9,10.2,5.3,15.2,8.2c-3.9-0.1-7.4-1.2-10.8-2.8c-3.3-1.6-6.3-3.9-9-6.4
			c-2.6-2.5-4.9-5.4-7.4-8.1c1.2,3.6,3,6.8,5.7,9.3c11.6,10.8,25,14.9,40.7,11.7c6.4-1.3,11.9,0.5,15.9,5.8
			C141.2,609.5,143.1,614.5,139.5,620.2L139.5,620.2z M129.3,484.7c-2.2-0.7-3,0.5-3.8,1.9c-3.2,6.2-7.8,11.6-12.2,17
			c-1.8,2.2-3.9,4.1-6.8,4.9c6.6-7.1,12.4-14.6,16.6-23.5c-5.9,3.4-7.1,11.3-13.7,14c3-4.5,6.7-8.6,9.1-14.5
			c-4.3,1.9-4.6,6.6-8.3,8.1c0-2.5,2-3.8,2.8-5.7c0.2-0.6,1.3-1.2,0.4-1.9c-0.8-0.6-1.7-0.5-2.3,0.4c-0.7,0.9-1.3,1.9-2.1,2.7
			c-9.7,10.2-21,16.9-35.8,14.8c-6.9-1-11.9-5.9-13.5-12.7c-0.7-2.9-0.2-5.6,0-9.1c-3.4,3.1-6,5.9-7.9,9.5
			c-7.5,14.1-1.4,33.4,12.5,37.8c13.2,4.2,26.2,3,38.1-4.7c14.9-9.6,24.3-24,33-39c0.2-0.4,0.4-1-0.1-1.4c-1.7,0.1-3,0.7-3.9,2.5
			c-4.1,8.2-9.3,15.7-15.6,22.4c-3.7,3.9-7.3,7.8-12.2,10.3c4.5-5.4,9.6-10.1,14.1-15.5C122.2,497.3,125.9,491.2,129.3,484.7
			L129.3,484.7z M200.7,585.8c-0.6,0.6-1.2,0.3-1.8,0.3c-14.1-1-27.8,1-40.7,6.8c-1,0.5-3.1,0.3-2.2,2.6c1,2.3,2.3,1.3,3.9,0.5
			c2.4-1.2,4.8-2.3,7.4-3.2c15.3-5.1,30.8-4.5,46.4-1.9c10.5,1.8,20.8,3,31.3-0.4c6.6-2.1,15.2-6.5,18.2-10.1
			c-0.8,0.3-1.4,0.5-2,0.7c-13.1,5.3-25.1,3.2-36-5.8c-2.8-2.2-5.5-4.5-8.4-6.6c-5.2-3.6-11.1-5.2-17.3-5c-5.3,0.2-10.9,0.1-14.7,5
			c-0.4,0.5-1.3,1.3-2.7,0.6c2.2-5.5,5.7-10.8,5.2-17.6c-5.4,4.8-10.7,8.9-17.7,9.8c-1,0.1-1.2,0.8-1.1,1.7c0.1,1.6,1.1,0.7,1.9,0.7
			c4.3-0.1,7.7-2.8,11.7-3.6c-2.8,3.5-6.6,4.9-10.8,5.8c-1.6,0.3-3.2,0.2-3.9,2.3c-0.9,2.7-2.1,5.3-3.2,8.2
			c3.7-0.5,7.2-2.4,10.9-0.9c-2.8,1.1-5.6,2-8.5,2.6c-3.2,0.6-5.9,1.7-7.2,5.1c3.4-0.8,6.6-2,10-2.4c3.1-0.3,6.4-2.1,10.8,0.1
			c-11.2,1.5-20.9,4.6-31.3,8.6c3,1.2,3.9,3.5,7,2c13.2-6.4,27.3-8.2,41.8-7.2C198.7,584.5,199.9,584.7,200.7,585.8L200.7,585.8z"
          />
          <path
            d="M57.1,604.4c26,24.3,55.7,36.9,92.1,29c-2.9,3.6-21.4,6.2-32.3,4.5C89,633.6,64.2,616.8,57.1,604.4z"
          />
          <path
            d="M512.6,604.4c-8.5,22.9-72.3,43.2-92,29.3C456.8,641.3,486.6,629,512.6,604.4z"
          />
          <path
            d="M340.9,606.1c-18.5-3.2-36.8-7.3-55.9-7.3c-19.1,0-37.5,4.1-56,7.4c18.2-5.8,36.8-9.4,55.9-9.4
			C304,596.8,322.6,600.5,340.9,606.1L340.9,606.1z"
          />
          <path
            d="M218.7,609.7c-18.1,5.9-36.1,11.9-54.3,19.7C167.2,624.8,210.7,609.3,218.7,609.7L218.7,609.7z"
          />
          <path
            d="M404.9,628.6c-17.7-6.9-35.6-13.1-54-18.5C354.4,608.1,400.4,623.6,404.9,628.6z"
          />
          <path
            d="M300.1,593.5c-5.2,0-10.3-0.4-15.5-0.4c-5,0-10,0.4-15.6,0.6C275.8,590.7,292.7,590.8,300.1,593.5z"
          />
          <path
            d="M517.2,600.1c1.5-2.8,3.2-5.3,5.9-7.6C522.7,595.9,520.8,598.3,517.2,600.1z"
          />
          <path
            d="M47,593.2c2.4,1.8,4.6,3.9,5.6,6.9C49.5,598.9,48,596.2,47,593.2z"
          />
          <path
            d="M423,270.5c-0.2,9-4,15.9-10.8,20.7c-8.5,6-17.5,11.2-24.1,19.5c-0.1,0.1-0.5,0.1-0.7,0.1c-1-1.1,0.2-1.5,0.7-2.1
			c5.5-6.8,12.2-12.1,19.7-16.5c7.7-4.5,12.1-11.2,13.7-19.9c0.1-0.4,0.2-0.7,0.5-1C422,270.9,422.3,270.9,423,270.5L423,270.5z"
          />
          <path
            d="M416.8,377.1c13.4,10.7,28.1,10.7,43.7,5c-3.5,4.9-17.9,7.5-27.2,4.8C427,385.2,420.4,383.4,416.8,377.1z"
          />
          <path
            d="M430.4,456.5c-2,0.6-2.8-0.7-3.5-1.6c-7.1-9.2-9.6-19.6-7.7-31.1c0.1-0.7,0.5-1.7,1.2-1.6c1,0,0.9,0.9,0.8,1.8
			c-1.8,11.5,0.6,21.9,8.2,30.9C429.7,455.3,430,455.9,430.4,456.5L430.4,456.5z"
          />
          <path
            d="M371.7,419.6c-9-10.2-18.5-19.3-33.5-20.2C345.7,394.8,363.4,405.6,371.7,419.6z"
          />
          <path
            d="M475.8,471.7c-4.8,3.8-10,5.3-15.3,6.3c-6,1.1-12,1.6-18.1,0.2c-0.9-0.2-1.9-0.3-1.8-1.4c0.1-0.7,0.9-0.8,1.5-0.6
			c11.2,2,21.8,0,32-4.6C474.5,471.4,474.8,471.5,475.8,471.7L475.8,471.7z"
          />
          <path
            d="M476.9,435.5c-2,4.7-9,7.3-15.6,6.1c-8.8-1.6-14.3-6.1-14.5-12.5C454.8,441.3,464.9,443,476.9,435.5z"
          />
          <path
            d="M324.6,394.1c2.1-9.7,22.1-17,31.8-7.2C343.4,381.6,333,383.8,324.6,394.1z"
          />
          <path
            d="M436,294.6c1.6-11.5-1.1-21.8-9.5-31.2C435.2,266.6,441.6,287.3,436,294.6z"
          />
          <path
            d="M462.7,314.4c8.5,3.8,18.1,22.4,15.8,30.8C476.8,333.1,470.7,323.3,462.7,314.4z"
          />
          <path
            d="M431.4,315c-12,0-22.7,3.9-33,10.3C401.3,318.5,424.1,311.4,431.4,315z"
          />
          <path
            d="M430.4,367.1c5.5-1.2,9.8-2.8,11.9-7.8c2-4.7,0.4-9-0.7-14c3.7,3.1,4.8,11,2.5,14.9C440.7,366.1,435.8,368.9,430.4,367.1
			L430.4,367.1z"
          />
          <path
            d="M382,427.5c-7.6-8-12.2-18.6-21.2-25.4C371.5,407.4,375.8,418.2,382,427.5z"
          />
          <path
            d="M399.8,429.5c-1.2-8.6,8.8-21.1,17.1-20.9c-4.1,2.3-7.5,5.2-10.7,8.6C402.9,420.7,401.8,425.3,399.8,429.5L399.8,429.5z"
          />
          <path
            d="M333.7,356.5c6.4-5.1,20-3,24.6,4.7c-3.8-2.1-7.4-4.7-11.8-5.2C342.2,355.6,337.9,355.6,333.7,356.5L333.7,356.5z"
          />
          <path
            d="M477.8,469.3c6-5.5,10.2-12,13-20.6C492.1,455.4,484.3,467.2,477.8,469.3z"
          />
          <path
            d="M422.3,283.3c-3,9.5-11.7,12.5-19,16.9C409.3,294.3,418.2,291.4,422.3,283.3z"
          />
          <path
            d="M451,465c-4.6,1.7-13.3-0.8-18.7-5.6C439.3,461.3,444.6,464.9,451,465z"
          />
          <path
            d="M478.5,351.5c1.9,6.5,0.2,12.6-4.4,16.5C476.7,363,477,357.5,478.5,351.5z"
          />
          <path
            d="M376,424.6c3.6,5,7.2,10.1,11.1,15.4C383.4,438.4,377,430,376,424.6z"
          />
          <path
            d="M470.4,433.8c-4.6,4-13.9,3.2-16.3-1.6C459.5,435.5,464.9,434.8,470.4,433.8z"
          />
          <path
            d="M432.7,363.7c3.7-2.6,7.1-5.4,7.7-10.3C441.9,358.7,439.4,362.2,432.7,363.7z"
          />
          <path
            d="M439.2,316.7c5.3-0.5,8.9,2.4,12,6.1C447.1,321.1,443.4,318.4,439.2,316.7z"
          />
          <path
            d="M413.1,414.2c-3.1,3.3-5.8,7.1-8.9,10.3C405.6,419.7,408.6,416.2,413.1,414.2z"
          />
          <path
            d="M415,434.1c1.6,4.9,2.3,9.4,4.7,13.5C416,445.9,413.9,440.1,415,434.1z"
          />
          <path
            d="M335.1,389.4c3.1-2.6,6.6-3.3,10.4-1.8C342.1,388.2,338.6,388.8,335.1,389.4z"
          />
          <path
            d="M453,474.1c3.5-0.5,6.9-1,10.4-1.6c-3.1,2.9-6.7,2.7-10.4,2.6C453.1,474.8,453.1,474.5,453,474.1L453,474.1z"
          />
          <path
            d="M341.1,358.5c3.8-1.6,5.9-1.1,9.6,1.9C347.2,360.8,344.4,358.4,341.1,358.5z"
          />
          <path
            d="M420.2,292.7c-1.2,3.3-4,4.7-7.7,6.5C414.7,295.6,417.5,294.3,420.2,292.7z"
          />
          <path
            d="M456.6,556.8c-1.1,4.4-6.1,8.2-10.6,8.6c-7.8,0.6-14.1-2.1-16.3-7.1c-2.5-5.7-1.8-13.4,2.1-17.2c-0.4,3.1-1.4,5.6-1.6,8.3
			c-0.9,10.8,8.5,17.4,18.5,13C451.5,561.2,453.7,559.1,456.6,556.8z"
          />
          <path
            d="M432.2,600.6c-0.6-2.4,0.9-3.1,2-3.7c4.4-2.3,8.9-4,14.1-2.7c2.4,0.6,4.9,1,7.5,1c2.5-0.1,5-0.4,8,0.4
			c-1.9,1.5-3.6,1.3-5.2,1.2c-3.6-0.3-7.3-0.2-11-0.8C442,595.1,436.8,596.7,432.2,600.6L432.2,600.6z"
          />
          <path
            d="M424.8,594.5c-6.9,8-8.1,15.5,0.7,22.8c-4.3-0.6-7.6-4.6-8.1-8.8C416.8,602.4,419.8,596.2,424.8,594.5L424.8,594.5z"
          />
          <path
            d="M434.5,528.1c8.1-2.4,20.8,5.3,22.8,13.6c-2.8-3.4-5.3-7-9.3-9.1S439.5,529.5,434.5,528.1L434.5,528.1z"
          />
          <path
            d="M448.7,559.5c-3.1,2.7-7.9,2.7-11.5,0.5c-3.3-2.1-5-6.2-3.9-11C434.7,558.3,439.8,561.8,448.7,559.5L448.7,559.5z"
          />
          <path
            d="M500.8,570c2.4,4,4.7,8.1,8.3,12.4C504.1,580.2,501.4,576.1,500.8,570z"
          />
          <path
            d="M422.8,610.1c-2.5-3.4-0.9-5.6,0.5-8.5C424.2,604.8,422.9,607,422.8,610.1z"
          />
          <path
            d="M496.3,526.4c8.4-2.2,14.6-6.9,18.2-14.8c3.6-7.9,2.5-15.6-1.3-23.5c5,2.9,7,14.1,4.2,22.1
			C514.4,518.9,505.5,528.4,496.3,526.4L496.3,526.4z"
          />
          <path
            d="M513,498.3c2.1,8.1-4,19.7-11.1,21.9c-0.6,0.2-1.2,0.7-1.7,0c-0.6-0.8,0.1-1.3,0.7-1.8C506.8,513.5,510.8,506.8,513,498.3
			L513,498.3z"
          />
          <path
            d="M472.2,522.9c4.4,0.2,8.4,2.3,12.9,2.1c0.4,0,1,0.5,1.1,0.8c0.1,0.7-0.6,1.2-1.2,1.1C480.4,526.7,475.9,526.1,472.2,522.9
			z"
          />
          <path
            d="M358.9,587.1c-11.1,4.6-22.3,4-33.6,1C336.5,588.5,347.7,589.2,358.9,587.1z"
          />
          <path
            d="M351.1,575.1c2.8-6.5,16-10.7,24.1-7.9C366.5,567.1,358.8,570,351.1,575.1z"
          />
          <path
            d="M183.2,310.4c-0.9,0.6-1.4,0-1.8-0.5c-6.4-7.5-14.5-12.9-22.8-18.2c-6.5-4.2-10.1-10.4-11.8-17.9c-0.1-0.5-0.1-1-0.1-1.5
			c0-0.6,0.1-1.2,0.2-1.9c2,0.4,1.2,2.1,1.5,3.1c2.4,9.4,8.4,15.7,16.7,20.4C172.4,298.1,178.5,303.5,183.2,310.4L183.2,310.4z"
          />
          <path
            d="M109,381.7c7.5,3.4,14.5,4.4,21.8,4c7.7-0.4,14.5-2.9,20.4-7.8c0.6-0.5,1.1-1.9,2-0.7c0.8,1-0.6,1.6-1.1,2
			c-7.8,6.5-16.9,9.5-27,8.4c-4.2-0.4-8.5-0.8-12.5-2.8C111.5,384.4,110,384.2,109,381.7L109,381.7z"
          />
          <path
            d="M149,421.3c5.9,9.1-0.5,32.1-9.8,35.6c4.4-5.4,7.5-10.6,9.1-16.6C149.8,434.4,149.1,428.4,149,421.3L149,421.3z"
          />
          <path
            d="M94.2,471.1c6.2,1.9,11,4.3,16.4,5c5.6,0.7,11.1,0.9,16.6,0.1c0.9-0.1,1.9-0.5,2.2,0.7c0.2,0.9-0.8,1.1-1.5,1.2
			c-11.3,2.1-22.1,0.2-32.3-5C95.3,472.9,95.1,472.4,94.2,471.1L94.2,471.1z"
          />
          <path
            d="M231.4,398.6c-6.6,1.6-13,3.5-18.7,7.2c-5.6,3.6-9,9.6-14.3,13.4C205.9,406.8,217.5,397.7,231.4,398.6L231.4,398.6z"
          />
          <path
            d="M92.7,435.3c11.3,7.6,23.6,5.5,30-5.7c0.8,4.1-2.9,8.4-9.3,10.6C104.8,443.3,95.9,441.4,92.7,435.3z"
          />
          <path
            d="M214.3,386.1c6.7-5.2,13.9-5,21.1-1.8c4.4,2,8.5,4.4,9.8,9.7C236.8,383.8,226.5,381.6,214.3,386.1z"
          />
          <path
            d="M134.5,294.8c-6.4-3.6-1.1-27.3,8.4-31.1C135,272.6,132.3,283,134.5,294.8z"
          />
          <path
            d="M107.9,313.8c-8.5,9.6-14.8,19.3-16.3,32.4C88.4,338.3,98.8,318,107.9,313.8z"
          />
          <path
            d="M171.6,325c-10.3-6-21-10-34-9.6C145.1,311.4,166.7,317.8,171.6,325z"
          />
          <path
            d="M187.6,426.8c6.5-8.9,11-19.5,21.6-24.8c-4.6,3.3-7.7,7.9-11,12.4c-3.3,4.4-6.3,8.9-9.5,13.4
			C188.3,427.5,188,427.1,187.6,426.8L187.6,426.8z"
          />
          <path
            d="M139.1,367.3c-6.4,1.6-13.7-4-14.3-10.3c-0.3-3.2-0.7-6.6,1.2-9.6c0.4-0.7,0.6-2,1.7-1.6c1.4,0.5,0.2,1.5,0,2.2
			c-1.2,4.8-1.6,9.4,1.4,13.8C131.4,365.2,135.1,365.6,139.1,367.3L139.1,367.3z"
          />
          <path
            d="M169.9,429.7c-2.4-9.6-7.9-16.6-17.3-20.8C159.8,406.6,171.7,421,169.9,429.7z"
          />
          <path
            d="M236.4,356.4c-8.7-1.5-17-1.2-24.6,5.3C214.9,353.9,229.5,351.2,236.4,356.4z"
          />
          <path
            d="M92,469.4c-6.6-2.6-13.7-13.2-13.1-19.7C82,457.1,85.9,464,92,469.4z"
          />
          <path
            d="M166.7,300.2c-11.8-5.4-16.9-9.9-19.1-15.8C153.6,289.3,160.1,294.8,166.7,300.2z"
          />
          <path
            d="M91.3,351.6c2,5.7,1.2,11.6,5.6,16.7C91.7,365.8,89.2,357.7,91.3,351.6z"
          />
          <path
            d="M118.8,465.2c6.3-0.5,11.8-3.5,18.2-5.8C133.6,463.6,124.7,466.4,118.8,465.2z"
          />
          <path
            d="M183.1,439.7c3.6-5.1,7.3-10.1,11-15.2c0.3,0.2,0.6,0.5,0.9,0.7C191.9,430.7,188.4,435.9,183.1,439.7L183.1,439.7z"
          />
          <path
            d="M116.4,431.8c-3,5.3-12.9,6-17.4,1.5C105.1,434.8,110.4,435.6,116.4,431.8z"
          />
          <path
            d="M137.4,363.6c-6.4-0.7-9.7-5-7.9-10.8C130.3,358.3,133.4,361.3,137.4,363.6z"
          />
          <path
            d="M165.4,424.6c-2.6-3.6-5.7-6.8-9.2-10.1C160.3,414.5,164.9,419.6,165.4,424.6z"
          />
          <path
            d="M131.6,317c-5.1,1.4-8.7,3.9-12.7,6.4C120,319.3,126.7,315.8,131.6,317z"
          />
          <path
            d="M154.9,435c0.9,4.9-0.6,9-3.8,12.6C152.1,443.3,153.6,439.2,154.9,435z"
          />
          <path
            d="M223.7,387.6c4.2-1.7,8.1-1.1,11.6,1.9C231.2,388.8,227.7,388.2,223.7,387.6z"
          />
          <path
            d="M105.8,472.5c4.5,0.6,8.1,0.8,12.1,2.1C113.3,475.8,109.3,475.1,105.8,472.5z"
          />
          <path
            d="M150.2,292.5c2.4,1.9,4.8,3.7,7.2,5.6c-0.2,0.3-0.4,0.6-0.7,0.8C154,297.4,151,296.4,150.2,292.5L150.2,292.5z"
          />
          <path
            d="M228.5,358.5c-2.8,0.7-5.5,1.3-8.2,2c-0.2,0-0.4-0.2-0.9-0.4C222.3,357.9,225.1,356.8,228.5,358.5z"
          />
          <path
            d="M113.4,556.8c6.3,5.8,12.7,9.6,20.9,4.8c8.7-5.2,5.4-12.9,4.1-20.5c3.8,4,4.4,12.5,1.6,18.1c-2.2,4.4-9.7,7.2-16.5,6.3
			C119.2,564.8,114.1,560.6,113.4,556.8L113.4,556.8z"
          />
          <path
            d="M105.9,594.7c9.3,2.3,17.8-3.2,26.4,0.5c2.4,1,5.1,1.8,6.4,6c-5.6-4.7-11.2-6.2-17.2-5.1c-4.2,0.8-8.3,0.5-12.5,0.6
			C108.2,596.8,107.2,597,105.9,594.7z"
          />
          <path
            d="M145.7,594.8c4.7,2,7.1,7.5,6.5,14.1c-0.4,4-3.4,7.6-8.5,8.8C153.3,610.1,152,602.6,145.7,594.8L145.7,594.8z"
          />
          <path
            d="M113,542.2c-1.4-7.8,14.7-16.1,22.5-14.2C126,530.3,117.5,532.9,113,542.2z"
          />
          <path
            d="M120.7,559.5c11.8,0.6,12.8-0.1,15.9-10c1.6,2.2,0.5,6.2-2.5,9.2C130.6,562.1,124.9,562.6,120.7,559.5L120.7,559.5z"
          />
          <path
            d="M69,570c0.2,5.6-3.1,9.1-7.2,12.2C63.9,578,67.2,574.4,69,570z"
          />
          <path
            d="M146.5,601.7c2.1,2.8,2.4,5.2,1,7.7C147.1,607,145.7,604.8,146.5,601.7z"
          />
          <path
            d="M73.8,526.4c-9.7,1.9-18.5-7.7-21.5-16.9c-2.5-7.8-0.3-18.8,4.3-21.3c-3.6,7.9-4.5,15.7-1.1,23.6
			C59,519.7,65.2,524.4,73.8,526.4z"
          />
          <path
            d="M70.4,519.8c-7.8,0.6-16.2-12.7-13.4-22.1C58.6,507.6,61.8,515.4,70.4,519.8z"
          />
          <path
            d="M98,522.3c-3.1,3.8-11.3,5.6-15.1,3.2C88.3,524.9,92.9,523.7,98,522.3z"
          />
          <path
            d="M211.4,586.9c11,2.1,22.1,1.9,34,0.4C239.7,591.8,217,591.4,211.4,586.9z"
          />
          <path
            d="M218.9,575c-7.7-4.8-15.2-8.1-24.5-7.4C200.3,563.7,215.7,568.3,218.9,575z"
          />
          <g>
            <g>
              <path
                class="st1"
                d="M361.5,518.1c0,0,0.1,0,0.1-0.1C361.6,518,361.5,518,361.5,518.1z"
              />
              <path
                class="st1"
                d="M361.3,518.2C361.3,518.2,361.2,518.2,361.3,518.2C361.2,518.2,361.2,518.2,361.3,518.2z"
              />
              <path
                class="st1"
                d="M385.4,482.8c-0.1-0.1-0.1-0.1-0.2-0.2C385.2,482.6,385.3,482.7,385.4,482.8z"
              />
              <path
                class="st1"
                d="M393.9,489.4c0.5,0.2,1.1,0,1.4-0.4c0.4-0.7-0.4-1.1-1-1.4c-1.4-0.8-2.3-1.8-3.4-2.9
					c-2.2-2.2-4.4-4.5-6.6-6.7c-2.1-2.1-4.3-4.2-6.3-6.5c-2.1-2.4-4.8-4.5-7.2-6.7c0.1,0.1,0.1,0.1,0.2,0.2
					c-0.1-0.1-0.2-0.2-0.3-0.3c0,0,0,0,0,0c-4-3.7-8.5-7.3-13-10.6c-4.8-3.5-9.7-6.8-15.1-9.7c-10-5.3-20.6-10.3-31.9-13.4
					c-6-1.7-12-2.7-18.4-2.9c-2.7-0.1-5.4,0.2-8.1,0.1c-3.2-0.2-6.3,0-9.5,0.3c-12.3,1.1-24.4,3.5-35.5,8.4
					c-5.4,2.4-10.6,5.3-15.4,8.5c-4.9,3.3-9.8,6.6-14,10.5c-4.3,4-8.9,7.7-13.3,11.6c-4.2,3.8-8.2,7.9-11.9,12.1
					c-1.8,2-3.6,4-5.6,5.8c-0.5,0.4-1,0.9-1.5,1.3c-0.4,0.4-1.3,0.9-1.6,1.3c-0.2,0.4-0.2,0.8,0.3,1c0.7,0.2,1.2-0.5,1.7-0.8
					c2.2-1.8,4.4-3.6,6.6-5.5c2-1.8,3.9-3.7,6-5.6c4.4-3.9,8.8-7.8,13.3-11.6c4.5-3.8,8.9-7.6,13.9-11c4.8-3.3,9.7-6.7,14.9-9.4
					c5.2-2.7,10.7-5.1,16.4-6.9c6-1.9,12.3-3.4,18.7-4.3c6.3-0.9,12.6-1.1,19-1.3c2.8-0.1,5.6-0.3,8.5-0.3c0,0-0.1,0-0.1,0
					c0.2,0,0.3,0,0.5,0c-0.1,0-0.2,0-0.3,0c7.2,0.2,14.6,1.8,21.3,4c7.1,2.3,14,5.3,20.4,8.7c6.3,3.4,12.4,7,18.3,10.9
					c5.4,3.6,10.8,7.7,15.4,12.1c1.3,1.3,2.8,2.5,4.1,3.9c1.1,1.1,2.1,2.3,3.2,3.5c2.6,2.7,5.2,5.4,8,8c0,0,0,0,0,0
					C388.4,485.6,390.7,487.8,393.9,489.4L393.9,489.4z"
              />
              <path
                class="st1"
                d="M295.2,432.4C295.2,432.4,295.3,432.4,295.2,432.4C295.3,432.4,295.2,432.4,295.2,432.4z"
              />
              <path
                class="st1"
                d="M385.5,508c-0.7,0-1.3,1-1.6,1.4c-0.6,0.7-1.2,1.4-1.9,2.1c-2.1,2.1-4.3,4-6.7,5.8
					c-4.7,3.7-9.6,7.2-14.6,10.5c-9.3,6.2-19.2,11.9-30.2,15.8c-3.1,1.1-6.4,2.1-9.6,3c-3.4,0.9-6.9,2-10.4,2.4l0,0
					c-0.3,0-0.7,0.1-1,0.2c0,0,0,0,0,0c-0.1,0-0.1,0-0.2,0c0,0,0,0,0,0c-0.5,0.1-0.9,0.2-1.4,0.2l0,0c0,0,0,0,0,0h0
					c-12.4,2.1-25.1,2.1-37.6,0.4c-6.3-0.9-12.6-1.6-18.7-3.2c-6-1.5-11.8-3.6-17.4-5.8c-6.2-2.5-12.2-5.5-18-8.7
					c0.1,0.1,0.3,0.2,0.4,0.2c-11-6.2-21.4-13.6-30.2-22c0.1,0,0.1,0.1,0.1,0.1c-1.4-1.3-2.8-2.7-4.1-4.1c-0.3-0.3-1.4-1.8-1.7-0.8
					c-0.2,0.7,1.5,2,1.9,2.6c1.2,1.6,2.4,3.1,3.8,4.6c5.3,6.2,11.9,11.6,18.7,16.5c6.8,4.9,14.1,8.9,22,12.5
					c8,3.6,16.3,6.8,24.9,9.1c5,1.3,10.2,2.4,15.3,3.3c2.7,0.5,5.3,0.9,8,1c2.6,0.1,5.2,0,7.9,0c0,0-0.1,0-0.1,0
					c17,0,34-3.2,49.5-9.3c14.1-5.5,26.8-13.4,37.8-22.6c3.4-2.8,6.8-5.7,9.8-8.9c1.4-1.5,2.8-3,4.2-4.6
					C385.1,509.6,386.8,508,385.5,508L385.5,508z"
              />
              <path
                class="st1"
                d="M283.8,555.4c0,0-0.1,0-0.1,0C283.7,555.4,283.7,555.4,283.8,555.4z"
              />
              <path
                class="st1"
                d="M193.8,520.1c-0.1-0.1-0.2-0.2-0.2-0.2c0,0,0,0,0,0c-0.1,0-0.1-0.1-0.2-0.2
					C193.6,519.8,193.7,520,193.8,520.1L193.8,520.1z"
              />
              <path
                class="st1"
                d="M193.7,519.9C193.7,519.9,193.6,519.9,193.7,519.9C193.6,519.9,193.7,519.9,193.7,519.9z"
              />
              <path
                class="st1"
                d="M188,511.7C188,511.7,187.9,511.6,188,511.7C187.9,511.6,188,511.7,188,511.7z"
              />
              <path
                class="st1"
                d="M282.6,522.6c1,0,2.1,0.2,3.2,0.1c1.2-0.1,1.4-0.9,0.2-1.2c-0.9-0.2-2.2-0.2-3.1-0.1
					C282.1,521.6,281.5,522.6,282.6,522.6L282.6,522.6z"
              />
              <path
                class="st1"
                d="M263.8,528.3c-0.9-0.5-1.7-0.9-2.6-1.3c-0.4-0.2-1.3-0.6-1,0.2c0.1,0.5,1.8,1.1,2.3,1.3
					c0.3,0.2,1,0.7,1.3,0.5C264.1,528.9,264.2,528.5,263.8,528.3L263.8,528.3z"
              />
              <path
                class="st1"
                d="M273.4,532c-0.5-0.1-4-1.5-3.8-0.5c0.1,0.4,1.9,0.7,2.4,0.8c0.2,0.1,1.1,0.4,1.4,0.3
					C273.6,532.6,273.8,532.1,273.4,532L273.4,532z"
              />
              <path
                class="st1"
                d="M285.1,533c-0.8,0-4.6-0.4-4.8,0.4c-0.2,1,3.8,0.6,4.5,0.6c0,0,0,0,0,0C285.6,534,286,533,285.1,533
					L285.1,533z"
              />
              <path
                class="st1"
                d="M295.8,532.2c-1.2,0-2.8,0.4-4,0.7c-0.3,0.1-0.9,0.1-0.7,0.6c0.2,0.5,1.6,0,2-0.1c0,0-0.1,0-0.1,0
					c0.6-0.1,1.2-0.2,1.7-0.3c0,0-0.1,0-0.1,0c0.2,0,0.3-0.1,0.5-0.1c0,0,0,0,0,0c0.2,0,0.9-0.1,1-0.2
					C296.2,532.6,296.2,532.2,295.8,532.2L295.8,532.2z M295.1,533C295.1,533,295.1,533,295.1,533C295.1,533,295.1,533,295.1,533
					C295.1,533,295.1,533,295.1,533L295.1,533z"
              />
              <path
                class="st1"
                d="M305.6,529c-0.8,0-1.8,0.7-2.5,1c-0.2,0.1-2.9,0.9-1.7,1.3c0.6,0.2,2.5-0.8,3-1c0.4-0.2,0.8-0.3,1.2-0.5
					C305.7,529.8,306.5,529,305.6,529L305.6,529z"
              />
              <path
                class="st1"
                d="M313.8,524.1c-1,0-2.5,1.3-3.3,1.7c-0.4,0.2-1.1,0.5-1.1,1c0,0.5,0.5,0.6,1,0.5c0.8-0.1,2.2-1.3,2.9-1.7
					C313.7,525.4,315.3,524.1,313.8,524.1L313.8,524.1z"
              />
              <path
                class="st1"
                d="M207.1,508.4C207.1,508.4,207.1,508.4,207.1,508.4C207.1,508.4,207.1,508.4,207.1,508.4
					C207,508.4,207,508.4,207.1,508.4L207.1,508.4z"
              />
              <path
                class="st1"
                d="M357.5,486.6c-3.6-2.6-6.8-5.6-10.4-8.2c-0.9-0.7-1.8-1.4-2.8-2c-0.7-0.5-1.8-1.5-2.7-1.6
					c-0.9-0.1-1.2,0.4-0.7,1c0.6,0.7,1.9,1.2,2.7,1.7c0,0,0,0,0,0c2.9,2,5.7,4.1,8.5,6.1c1.4,1,2.7,2.1,4.1,3.1
					c0.4,0.3,1.4,1.4,2,1.3C359.4,487.9,357.7,486.8,357.5,486.6L357.5,486.6z"
              />
              <path
                class="st1"
                d="M351.3,484.5c-0.7-0.5-1.4-1-2.1-1.5c-0.4-0.3-1.3-1.1-1.8-1.1c-1.8,0.1,1.5,2.4,1.9,2.6
					c0.6,0.5,1.3,0.9,1.9,1.4c0.5,0.3,1.4,1.3,2,1.3C355.2,487.1,351.6,484.7,351.3,484.5L351.3,484.5z"
              />
              <path
                class="st1"
                d="M228.7,474.4c-1.2,0-3,1.4-3.9,2c-1.3,0.8-2.7,1.7-3.9,2.6c-2.6,1.9-4.9,4-7.3,6c-0.6,0.5-1.3,1-1.9,1.6
					c-0.4,0.4-0.8,1.3,0.3,0.9c0.7-0.2,1.5-1.2,2-1.6c0.6-0.5,1.3-1,1.9-1.5c2.7-2,5.5-3.9,8.3-5.7c0,0-0.1,0-0.1,0
					c0.1,0,0.1-0.1,0.2-0.1c0,0,0,0,0,0c0.4-0.3,0.8-0.5,1.2-0.8c0,0-0.1,0-0.1,0c1-0.6,2-1.1,3-1.7c0.4-0.2,1-0.5,1.1-0.9
					C229.6,474.8,229.2,474.4,228.7,474.4L228.7,474.4z"
              />
              <path
                class="st1"
                d="M221.7,478.4c0.1-0.1,0.2-0.1,0.4-0.2C221.9,478.2,221.8,478.3,221.7,478.4z"
              />
              <path
                class="st1"
                d="M221.4,483.4C221.4,483.4,221.4,483.4,221.4,483.4C221.4,483.4,221.4,483.4,221.4,483.4z"
              />
              <path
                class="st1"
                d="M223.4,481C223.4,481,223.4,480.9,223.4,481C223.4,480.9,223.4,480.9,223.4,481z"
              />
              <path
                class="st1"
                d="M226,479.4c-0.8,0-2.4,1.4-2.9,1.8c-1.1,0.7-2.2,1.5-3.2,2.2c-0.4,0.3-2.9,1.9-1.9,2.2
					c0.6,0.2,2.9-1.9,3.4-2.3c0,0,0,0,0,0c0,0,0.1,0,0.1-0.1c0,0,0,0,0,0c1.1-0.7,2.3-1.5,3.5-2.1c0.4-0.2,0.9-0.4,1.2-0.7
					C226.5,480.2,227,479.4,226,479.4L226,479.4z"
              />
              <path
                class="st1"
                d="M266.4,540.9c-0.2,0.5,1.5,0.6,1.8,0.7c0,0,0,0-0.1,0c0.1,0,0.3,0,0.5,0.1l0,0c1.4,0.2,2.7,0.5,4.1,0.7
					c0,0-0.1,0-0.1,0c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0.1,0,0.2,0,0.4,0.1h0c0,0,0.1,0,0.1,0c0,0,0,0,0,0c2.6,0.4,5.5,0.6,8.2,0.7
					c2.8,0.1,5.5,0.2,8.3,0c0,0,0,0,0,0c5.6-0.2,11.4-0.6,16.8-2.1c0,0,0,0,0,0c0.5-0.1,2.5-0.5,2.6-1c0.2-0.5-1.4,0-1.5,0
					c-1.8,0.3-3.7,0.5-5.6,0.7c-3.9,0.4-7.9,0.8-11.8,0.8c-3.8,0-7.8,0.3-11.6,0c-3.7-0.3-7.4-0.1-11.1-0.7c0,0,0,0,0,0
					C267.3,540.8,266.6,540.5,266.4,540.9L266.4,540.9z"
              />
              <path class="st1" d="M272.7,542.3L272.7,542.3L272.7,542.3z" />
              <path
                class="st1"
                d="M319.5,538.9c2.8-0.6,5.7-1,8.5-1.7c1.5-0.4,2.8-0.9,4.2-1.5c0.5-0.2,1.3-0.4,1.6-0.9
					c0.3-0.5-0.1-1-0.8-1.1c-1.1-0.1-2.7,0.8-3.6,1.2c-1.3,0.6-2.7,1.1-4.1,1.5c-1.5,0.5-3,0.7-4.5,1c-0.5,0.1-1.5,0.1-1.8,0.5
					C318.7,538.3,318.9,539.1,319.5,538.9L319.5,538.9z"
              />
              <path
                class="st1"
                d="M339.6,532.5c1.2-0.5,2.7-0.9,3.8-1.6c0.9-0.5,0.4-1.3-0.6-1.1c-1.1,0.2-2.7,0.8-3.6,1.4
					C338.3,531.6,338.5,532.9,339.6,532.5L339.6,532.5z"
              />
              <path
                class="st1"
                d="M358.6,521.4c-2.1,1.1-4.2,2.2-6.4,3.2c-1.1,0.5-2.1,1-3.3,1.5c-1.2,0.5-0.8,1.2,0.4,0.8
					c4.4-1.3,8.4-3.3,12.2-5.5c3.9-2.3,7.7-4.7,11.5-7.1c0.9-0.6,2-1.2,2.8-2c0.5-0.5,0.7-1.5-0.4-1.5c-0.8,0-1.5,0.9-1.9,1.2
					c-0.9,0.8-1.9,1.5-2.9,2.2c0,0,0,0,0,0C366.8,516.8,362.7,519.2,358.6,521.4L358.6,521.4z"
              />
              <path
                class="st1"
                d="M237.5,534.3c2.9,0.9,5.5,2.2,8.4,3c0.9,0.3,7.9,2.2,7.9,0.7c0-0.9-3.3-1.2-4.1-1.5
					c-1.7-0.5-3.4-0.9-5.2-1.2c-1.6-0.3-3.1-0.8-4.6-1.3c-0.6-0.2-1.4-0.6-2-0.6C237.4,533.5,236.5,534,237.5,534.3L237.5,534.3z
 M241.9,534.8L241.9,534.8L241.9,534.8L241.9,534.8z M241.9,534.8C241.9,534.8,241.9,534.8,241.9,534.8
					C241.9,534.8,241.9,534.8,241.9,534.8z"
              />
              <path
                class="st1"
                d="M231.2,530.7c-0.9-0.3-2.6-1.1-3.6-1c-0.5,0.1-0.9,0.4-0.9,0.8c0,0.7,0.6,0.8,1.2,1
					c1.1,0.4,2.5,0.9,3.7,0.9c1.1,0,1.4-0.8,0.5-1.4c0,0,0.1,0,0.1,0.1C231.9,531,231.6,530.8,231.2,530.7L231.2,530.7z"
              />
              <path
                class="st1"
                d="M198.5,514c-0.5-0.3-2.5-1.9-3.1-1.6c-1.1,0.6,1.2,1.5,1.6,1.8c1.9,1.3,3.6,2.8,5.5,4.2
					c3.9,2.8,8.1,5.4,12.7,7.3c1.2,0.5,2.5,1.2,3.8,1.6c1.1,0.3,1.7-0.2,0.8-0.9c-0.8-0.6-2-1-2.9-1.4c-1.2-0.5-2.3-1.1-3.4-1.7
					c-4.4-2.5-9.1-4.9-12.9-7.9c0,0,0,0,0.1,0C200,515,199.3,514.4,198.5,514L198.5,514z"
              />
              <path
                class="st1"
                d="M217.3,539.8c-1.2-0.8-2.6-1.5-3.8-2.3c0.1,0,0.1,0.1,0.2,0.1c-3.5-2.3-6.9-4.7-10.1-7.3
					c-0.8-0.6-1.6-1.2-2.4-1.8c-0.4-0.3-1.4-1.6-1.9-1.5c-1.1,0,0.6,1.3,0.7,1.4c0.7,0.7,1.4,1.3,2.1,1.9c3,2.6,6,5.2,9.3,7.6
					c1.6,1.2,3.3,2.2,5.2,3.1c0.5,0.2,1.6,1,2.2,0.6C219.5,541,217.7,540.1,217.3,539.8L217.3,539.8z"
              />
              <path
                class="st1"
                d="M228.5,546.8c0.5,0.2,3,0.8,2.2-0.5c-0.4-0.6-2.5-1-3.2-1.3c-0.4-0.2-3.2-1.7-2.8-0.2
					c0.1,0.5,1.2,0.8,1.7,1C227.1,546.2,227.8,546.5,228.5,546.8L228.5,546.8z"
              />
              <path
                class="st1"
                d="M259,556.5c1.1-1.5-3.2-2-4.2-2.1c-1.7-0.3-3.5-0.7-5.1-1.2c-1.8-0.5-3.6-1.3-5.3-2c-1-0.4-2-0.8-3-1.2
					c-0.4-0.2-1.6-0.7-1.4,0.1c0.1,0.7,3.5,1.7,4.2,2c1.5,0.7,3,1.4,4.5,2c1.7,0.7,3.6,1.2,5.4,1.7
					C255.1,556.3,258.2,557.6,259,556.5L259,556.5z"
              />
              <path
                class="st1"
                d="M244,551.1c0,0,0.1,0,0.1,0C244.1,551.2,244.1,551.2,244,551.1z"
              />
              <path
                class="st1"
                d="M327,551.6c1.3-0.5,2.6-1,3.8-1.6c0.3-0.2,1.1-0.5,0.3-0.8c-0.3-0.1-1.5,0.5-1.8,0.6c0,0,0,0,0,0
					c-0.1,0-0.3,0.1-0.4,0.1c0,0,0.1,0,0.1,0c-0.4,0.1-0.8,0.3-1.2,0.4c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0,0,0,0,0
					c0,0,0,0,0,0c-2.3,0.8-4.6,1.4-6.9,2c-1.3,0.3-2.7,0.6-4,1c-0.8,0.2-2.4,0.3-3,0.8c-0.7,0.6-0.1,1.1,0.6,1.1
					c1.3,0.1,3-0.6,4.2-0.9c2.4-0.6,4.7-1.5,7-2.3c0,0-0.1,0-0.1,0C326,552,326.5,551.8,327,551.6L327,551.6z"
              />
              <path
                class="st1"
                d="M343.5,544.2c-0.9,0-2.2,1-3,1.3c-0.4,0.1-2.7,0.7-1.4,1.3c0.7,0.4,2.5-0.7,3.1-1c0.4-0.2,1.3-0.5,1.6-0.8
					C344,544.7,344.1,544.2,343.5,544.2L343.5,544.2z"
              />
              <path
                class="st1"
                d="M351,539.6c-0.6,0.3-0.5,1,0.2,1.2c0.6,0.2,1.4-0.4,1.9-0.6c1.4-0.7,2.5-1.5,3.8-2.3c0,0,0,0,0,0
					c0.1,0,0.1-0.1,0.2-0.1c0,0-0.1,0-0.1,0.1c3.1-2,6-4.1,8.9-6.2c1.4-1,2.7-2,4.1-3c0.6-0.5,4-2.4,3.1-3.2c-0.8-0.7-3.2,1.6-3.9,2
					c-1.4,1-2.8,2-4.2,3c-3,2.2-6.1,4.2-9.3,6.2c0,0,0.1,0,0.1,0c-1.6,1-3.1,2-4.7,3c0,0,0.1-0.1,0.2-0.1
					C351.3,539.5,351.2,539.5,351,539.6L351,539.6z"
              />
              <path
                class="st1"
                d="M391.9,506.4c-1.5-0.8-2.6,2-3.2,2.8c-0.5,0.6-2.7,2.8-0.9,3.2c1.1,0.2,2.5-1.9,3-2.6
					C391.4,509.1,393.3,507.1,391.9,506.4L391.9,506.4z"
              />
              <path
                class="st1"
                d="M385.3,516.5c-0.6,0-1.5,1.4-1.9,1.8c0,0,0,0,0,0c-0.3,0.3-0.6,0.6-0.9,1c0,0,0,0,0,0
					c-3.8,4.1-7.3,8.3-11.1,12.5c0,0,0,0,0,0c-0.2,0.3-0.5,0.5-0.7,0.8c0,0,0.1-0.1,0.1-0.2c-1,1.1-2.2,2.2-3.2,3.3
					c-0.3,0.3-0.9,0.8-0.4,1.3c0.6,0.5,1.5-0.3,1.9-0.6c2.5-2.1,4.6-4.7,6.7-7c0,0-0.1,0.1-0.1,0.2c2.1-2.2,3.9-4.7,5.7-7.1
					c0.8-1,1.5-2,2.3-2.9C384.2,518.9,386.7,516.5,385.3,516.5L385.3,516.5z"
              />
              <path
                class="st1"
                d="M362.3,541c0.5-0.5-0.1-0.8-0.6-0.8c-0.5,0.1-1.1,0.9-1.5,1.2c-1,0.8-2.1,1.6-3.1,2.4
					c-1.1,0.8-2.2,1.6-3.3,2.4c-1,0.7-2.8,1.6-3.5,2.6c-0.2,0.3-0.3,0.7,0.1,0.9c0.5,0.3,1,0,1.4-0.3c1.3-0.7,2.4-1.6,3.6-2.5
					C357.7,545.1,360,543,362.3,541z"
              />
              <path
                class="st1"
                d="M329.6,562.8C329.6,562.8,329.6,562.8,329.6,562.8C329.6,562.8,329.6,562.8,329.6,562.8z"
              />
              <path
                class="st1"
                d="M332.5,559.9c0,0-0.1,0-0.1,0C332.4,559.9,332.4,559.9,332.5,559.9z"
              />
              <path
                class="st1"
                d="M182,512.6c0,0,0,0.1,0.1,0.1c-0.8-1.3-1.6-2.6-2.1-4c-0.2-0.5-0.4-1.8-0.8-2.2c-0.5-0.5-0.9-0.2-1,0.3
					c-0.1,0.6,0.7,1.8,0.9,2.4c0.3,0.7,0.6,1.4,0.9,2c0.3,0.6,0.6,1.6,1.3,2C181.9,513.6,182.4,513.1,182,512.6L182,512.6z"
              />
              <path
                class="st1"
                d="M189,524.1c3.5,4.4,7.5,8.4,11.8,12.2l0,0c0.4,0.4,1.5,1.5,2.2,1.2c1-0.4-0.1-1.2-0.5-1.6
					c0.1,0,0.1,0.1,0.1,0.1c-4.7-4.2-9.2-8.8-13.3-13.5c-0.9-1-1.8-2.1-2.6-3.2c-0.5-0.7-1.1-1.3-1.6-2c-0.2-0.3-1.1-1.4-1.4-0.6
					c-0.1,0.4,1,1.5,1.3,1.9c0.4,0.6,0.8,1.1,1.2,1.7C187,521.6,188,522.9,189,524.1L189,524.1z"
              />
              <path
                class="st1"
                d="M217.6,548.5c-2.6-1.7-5-3.4-7.2-5.5c-0.5-0.4-1-0.8-1.5-1.3c-0.4-0.3-1.1-1.4-1.7-1.4
					c-1.7-0.1,1,2.2,1.3,2.5c0,0-0.1,0-0.1-0.1c1.9,1.8,3.7,3.6,5.7,5.2c0.7,0.6,1.6,1.4,2.6,1.7C218,550,218.6,549.1,217.6,548.5
					L217.6,548.5z M216.6,548L216.6,548C216.7,548,216.7,548,216.6,548C216.7,548,216.7,548,216.6,548z"
              />
              <path
                class="st1"
                d="M206.4,550.3C206.4,550.3,206.5,550.3,206.4,550.3C206.5,550.3,206.5,550.3,206.4,550.3z"
              />
              <path
                class="st1"
                d="M206.5,550.4C206.5,550.4,206.6,550.4,206.5,550.4C206.6,550.4,206.5,550.4,206.5,550.4z"
              />
              <path
                class="st1"
                d="M338.8,449.3C338.8,449.3,338.8,449.3,338.8,449.3C338.8,449.3,338.8,449.3,338.8,449.3z"
              />
              <path
                class="st1"
                d="M391,490.4c0.3-0.3-9.2-8.5-10.2-9.5c-3.5-3.5-7-7-10.8-10.3c-3.6-3.3-7.2-6.7-11.2-9.7
					c-3.9-2.9-8.1-5.5-12.3-8.1c0,0,0,0,0,0c0,0-0.1,0-0.1-0.1c0,0,0,0,0,0c-0.1-0.1-0.2-0.1-0.3-0.2c0,0,0,0,0,0c0,0,0,0,0,0
					c0,0,0,0,0,0c0,0,0,0,0,0l0,0c-2.5-1.7-5.5-3.1-8.3-4.4c-0.8-0.3-1.5-0.6-2.3-1c-0.2-0.1-1.7-0.9-1.6-0.2c0,0.3,1.7,0.8,2,1
					c1,0.4,1.9,0.8,2.8,1.4c0,0,0,0,0,0c0,0,0,0,0.1,0c0,0,0,0,0,0c2.8,1.8,5.8,3.2,8.6,5c3,1.9,6,3.9,8.8,6
					c5.4,4,10.3,8.7,14.8,13.4c0,0-0.1-0.1-0.1-0.1c0.1,0.1,0.1,0.1,0.2,0.2c0,0-0.1-0.1-0.1-0.1c0,0,0.1,0.1,0.1,0.1l0,0
					c0,0,0.1,0.1,0.1,0.1c0,0,0,0,0,0c0,0.1,0.1,0.1,0.2,0.2c0,0,0,0,0,0c2.7,2.8,6,5.3,8.9,8c2.8,2.5,5.4,5.4,8.7,7.5c0,0,0,0,0,0
					C389.2,489.6,390.6,490.7,391,490.4L391,490.4z"
              />
              <path class="st1" d="M346.2,452.5L346.2,452.5L346.2,452.5z" />
              <path
                class="st1"
                d="M346.6,452.7C346.6,452.7,346.6,452.7,346.6,452.7C346.6,452.7,346.6,452.7,346.6,452.7z"
              />
              <path
                class="st1"
                d="M371.1,473.7C371.1,473.7,371.1,473.8,371.1,473.7C371.1,473.8,371.1,473.7,371.1,473.7z"
              />
              <path
                class="st1"
                d="M346.2,452.5C346.2,452.5,346.2,452.5,346.2,452.5C346.2,452.5,346.2,452.5,346.2,452.5z"
              />
              <path
                class="st1"
                d="M350.7,455.2c0,0,0.1,0,0.1,0.1C350.8,455.2,350.7,455.2,350.7,455.2z"
              />
              <path
                class="st1"
                d="M318.7,440.1c-0.4-0.1-1.4-0.4-1.7,0c-0.5,0.7,1,0.8,1.5,0.9c1.5,0.3,3.1,0.5,4.5,1.2
					c0.5,0.2,1.6,1.1,2.1,0.8c0.7-0.5-0.9-1.1-1.2-1.2C322.2,440.9,320.6,440.5,318.7,440.1z"
              />
              <path
                class="st1"
                d="M322,440.9C322,440.9,322,440.9,322,440.9C322.1,440.9,322.1,440.9,322,440.9z"
              />
              <path class="st1" d="M292,434.8L292,434.8L292,434.8z" />
              <path
                class="st1"
                d="M292,434.8C292,434.8,292,434.8,292,434.8C291.9,434.8,292,434.8,292,434.8z"
              />
              <path
                class="st1"
                d="M286.8,434.5c-6.2-0.3-12.8-0.2-18.9,0.9c-1.4,0.3-2.8,0.5-4.1,0.9c-0.5,0.1-2.9,0.9-1.2,1.5
					c0.9,0.3,2.9-0.3,3.8-0.5c1.6-0.3,3.3-0.6,4.9-0.9c-0.1,0-0.2,0-0.2,0c9.2-1.2,18.9-1.1,28.1-0.2c0,0-0.1,0-0.1,0
					c0.8,0.1,1.6,0.1,2.4,0.2c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c1,0.1,1.9,0.4,2.9,0.5c0.9,0.2,1.4-0.1,0.3-0.5
					c-2.2-0.7-4.6-0.9-7-1.2l0,0c-0.1,0-0.1,0-0.2,0l0,0c0,0,0,0-0.1,0c0,0,0,0,0,0c-1.9-0.2-3.7-0.4-5.6-0.5c0,0,0,0,0,0l0,0
					c0,0,0,0,0,0c-0.3,0-0.5-0.1-0.8-0.1l0,0c0,0-0.1,0-0.2,0c0,0,0,0,0,0C289.6,434.6,288.2,434.5,286.8,434.5L286.8,434.5z"
              />
              <path
                class="st1"
                d="M297.5,435.4C297.5,435.4,297.5,435.4,297.5,435.4C297.5,435.4,297.5,435.4,297.5,435.4z"
              />
              <path
                class="st1"
                d="M301.5,436.5C301.5,436.5,301.5,436.5,301.5,436.5C301.5,436.5,301.5,436.5,301.5,436.5z"
              />
              <path
                class="st1"
                d="M259.5,446.5c-0.6,0.1-0.6,0.8-0.1,1c0.4,0.2,1.2-0.1,1.6-0.1c1.8-0.3,3.5-0.6,5.2-0.9
					c3.2-0.6,6.5-1.1,9.8-1.4c6.8-0.7,13.9-0.6,20.7-0.1c3.1,0.2,6.2,0.6,9.2,1.4c0.4,0.1,1.7,0.7,1.9,0.1c0.2-0.5-0.9-0.6-1.2-0.7
					c-1.6-0.5-3.3-0.9-5-1.2c-6.4-1.3-13.4-1.4-20-1.1C274.3,443.7,266.7,444.8,259.5,446.5z"
              />
              <path
                class="st1"
                d="M253.2,439.6c-1.3,0-3.1,0.9-4.4,1.3c-0.7,0.2-1.4,0.5-2,0.7c-0.6,0.2-1.2,0.9-0.1,0.9c0.6,0,1.4-0.5,2-0.6
					c0.7-0.3,1.5-0.5,2.2-0.8c0.6-0.2,2-0.4,2.5-0.7C253.7,440.1,253.9,439.6,253.2,439.6z"
              />
              <path
                class="st1"
                d="M190.9,486C190.9,486,190.9,485.9,190.9,486C190.9,485.9,190.9,485.9,190.9,486z"
              />
              <path
                class="st1"
                d="M195.3,482.3c2.5-2.3,5.1-4.6,7.4-7c0.5-0.5,0.8-1,0-0.4c-0.6,0.4-1.1,1.1-1.6,1.6
					c-1.2,1.1-2.6,2.1-3.9,3.1c0,0,0,0,0,0c-0.1,0.1-0.3,0.2-0.4,0.4c0,0,0,0,0,0c-0.8,0.6-1.6,1.2-2.4,1.9c0,0,0.1,0,0.1,0
					c0,0-0.1,0-0.1,0.1c0,0,0,0,0,0c-1.1,0.9-2.3,1.8-3.4,2.6c0,0,0.1-0.1,0.1-0.1c-1.4,1.1-2.6,2.3-4,3.4c0,0,0,0,0,0
					c-0.1,0-0.1,0.1-0.1,0.1c0,0,0.1-0.1,0.1-0.1c-0.3,0.2-0.5,0.4-0.8,0.6c0,0,0,0,0,0c-0.2,0.2-1.9,1.3-1.8,1.6
					c0.3,0.8,1.6-0.3,2-0.6c1.6-1,3-2.3,4.4-3.4c0,0,0,0,0,0c0,0,0.1-0.1,0.1-0.1l0,0C192.4,484.7,193.9,483.6,195.3,482.3
					L195.3,482.3z"
              />
              <path
                class="st1"
                d="M370.9,478.7c2.2,2,4.5,3.9,6.8,5.8c2.1,1.8,4.4,3.5,6.8,4.9l0,0c0,0,0,0,0,0l0,0c0.3,0.2,1.9,1.4,2,1
					c0-0.2-1.6-1.2-1.8-1.3c-1.4-0.9-2.6-2-3.9-3c-2.5-2.1-4.9-4.4-7.4-6.5c-2.6-2.2-5.1-4.3-7.6-6.5c-1.1-1-2.3-1.9-3.5-2.9
					c-0.2-0.2-1.5-1.4-1.8-1c-0.3,0.3,1,1,1.3,1.2c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0C364.6,473.1,367.9,475.9,370.9,478.7
					L370.9,478.7z"
              />
              <path
                class="st1"
                d="M384.5,489.3C384.5,489.3,384.6,489.3,384.5,489.3C384.6,489.3,384.6,489.3,384.5,489.3z"
              />
              <path
                class="st1"
                d="M369.5,477.4C369.5,477.4,369.5,477.4,369.5,477.4C369.5,477.4,369.5,477.4,369.5,477.4z"
              />
              <path class="st1" d="M361.9,470.2L361.9,470.2L361.9,470.2z" />
              <path
                class="st1"
                d="M353.1,447.1c-0.6-0.3-1.2-1.2-1.8-1.6c-0.7-0.6-1.4-1.2-2.2-1.8c-1.7-1.3-3.5-2.5-5.5-3.4
					c-2-1-4-1.7-6.1-2.5c-1-0.4-2-0.8-3-1.1c-0.9-0.3-2.2-0.9-3.1-0.9c0.4,0,1.2,0.5,1.6,0.7l0,0c3.5,1.6,6.9,3.4,10.4,5.2
					c3.2,1.6,6,3.8,9.3,5.3c0,0,0,0,0,0C352.9,446.9,353,447,353.1,447.1L353.1,447.1z"
              />
              <path
                class="st1"
                d="M333.7,431.4c3.4,1.6,6.8,3,10.2,4.6l0,0c0,0,0.1,0,0.1,0l0,0c0.5,0.3-0.6-0.6-0.5-0.6
					c-0.3-0.3-0.7-0.6-1-0.9c-0.8-0.7-1.7-1.3-2.6-1.9c-1.7-1.2-3.6-2.2-5.5-3c-4.1-1.8-8.6-3.4-13.2-3.8c0.8,0,2.1,0.8,2.9,1.1
					c1.1,0.5,2.2,0.9,3.3,1.4C329.3,429.3,331.5,430.4,333.7,431.4L333.7,431.4z"
              />
              <path
                class="st1"
                d="M386,472.1c-3.9-4.5-7.5-9.2-11.9-13.5c-4.4-4.2-9.3-8.2-14.2-11.9c0,0,0,0,0,0c-0.7-0.6-1.5-1.1-2.2-1.7
					c-0.3-0.2-1.5-1.4-2-1.4c0.6,0,2.3,1.9,2.7,2.3c0.9,0.8,1.8,1.5,2.6,2.3c1.7,1.6,3.4,3.2,5.1,4.9c3.5,3.4,6.6,7,9.9,10.6
					c3.4,3.7,6.7,7.5,10,11.3c3.3,3.6,7,6.9,10.6,10.3c-0.1-0.1-0.3-0.4-0.3-0.4l0,0C393.1,480.6,389.6,476.3,386,472.1L386,472.1z"
              />
              <path
                class="st1"
                d="M377,451.7C377,451.7,377,451.7,377,451.7C377,451.7,377,451.7,377,451.7z"
              />
              <path
                class="st1"
                d="M378.2,456.4C378.2,456.4,378.2,456.4,378.2,456.4C378.2,456.5,378.2,456.5,378.2,456.4L378.2,456.4
					L378.2,456.4z"
              />
              <path
                class="st1"
                d="M378.1,456.4C378.1,456.4,378.2,456.4,378.1,456.4C378.2,456.4,378.2,456.4,378.1,456.4z"
              />
              <path
                class="st1"
                d="M379,457.4C379,457.4,379,457.5,379,457.4C379,457.5,379,457.4,379,457.4z"
              />
              <path
                class="st1"
                d="M378.3,456.5C378.3,456.5,378.2,456.5,378.3,456.5C378.2,456.5,378.2,456.5,378.3,456.5z"
              />
              <path
                class="st1"
                d="M215.7,448.2L215.7,448.2L215.7,448.2L215.7,448.2L215.7,448.2c4-2.2,7.7-4.7,11.8-6.9
					c2-1.1,3.9-2.2,5.8-3.4c1-0.6,1.9-1,3-1.5c0.6-0.3,1.9-1.2,2.6-1.2c-0.8,0-2.1,0.7-2.9,0.9c-1.1,0.4-2.2,0.8-3.3,1.2
					c-2.1,0.9-4.3,1.9-6.4,2.9c-4.1,2-7.8,5-10.8,8C215.5,448.3,215.6,448.3,215.7,448.2L215.7,448.2
					C215.7,448.2,215.7,448.2,215.7,448.2L215.7,448.2z"
              />
              <path
                class="st1"
                d="M204.2,453.3C204.2,453.3,204.2,453.3,204.2,453.3C204.2,453.3,204.2,453.3,204.2,453.3z"
              />
              <path
                class="st1"
                d="M174.8,485c4.6-5.3,9.5-10.4,14.1-15.8c2.3-2.7,4.5-5.4,6.9-8c2.6-2.8,5.6-5.2,8.4-7.9c0,0,0,0-0.1,0.1
					c0,0,0.1-0.1,0.1-0.1c0,0,0,0,0,0c0.6-0.5,1.1-1.1,1.7-1.6c0,0,0,0-0.1,0c0,0,0.1-0.1,0.1-0.1c0,0,0,0-0.1,0
					c1.6-1.5,3.4-3,5-4.5c0.9-0.8,1.8-1.6,2.7-2.4c0.5-0.5,1-0.9,1.6-1.4c0.1-0.1,1-1,1.2-1c-0.5,0-1.8,1.3-2.1,1.6
					c-0.9,0.7-1.9,1.4-2.8,2.1c-1.6,1.2-3.2,2.4-4.8,3.6c-3.3,2.5-6.4,5.2-9.5,8c-6.1,5.5-11,12-16,18.3c-2.9,3.6-5.3,7.5-8.2,11.1
					C173.6,486.3,174.2,485.7,174.8,485L174.8,485z"
              />
              <path
                class="st1"
                d="M205.9,451.7C205.9,451.7,205.9,451.7,205.9,451.7C205.9,451.7,205.9,451.7,205.9,451.7z"
              />
              <path
                class="st1"
                d="M202.3,442.3C202.3,442.3,202.3,442.3,202.3,442.3C202.3,442.3,202.3,442.3,202.3,442.3z"
              />
              <path
                class="st1"
                d="M207.2,442.8C207.2,442.8,207.2,442.8,207.2,442.8C207.2,442.8,207.2,442.8,207.2,442.8z"
              />
              <path
                class="st1"
                d="M228.1,434.7c-0.9,0.6-1.8,1.3-2.6,2c0-0.1,0.5-0.3,0.5-0.3l0,0c3.6-2,7.4-3.7,11.1-5.5
					c1.9-0.9,3.8-1.8,5.7-2.7c0.9-0.4,1.8-0.8,2.8-1.2c0.6-0.2,2.1-1.2,2.7-1.2c-0.8,0-1.8,0.5-2.6,0.7c-0.9,0.2-1.7,0.5-2.6,0.8
					c-1.9,0.6-3.8,1.2-5.6,2.1C234.3,430.8,231.1,432.6,228.1,434.7L228.1,434.7z"
              />
              <path
                class="st1"
                d="M255.9,428.1C255.9,428.1,255.9,428.1,255.9,428.1C255.8,428.1,255.9,428.1,255.9,428.1z"
              />
              <path
                class="st1"
                d="M247.3,430.6c-0.3,0.1-2.6,1.2-1.9,1.2c0.7,0,2-0.8,2.7-1c2.7-0.8,5.5-1.3,8.2-1.9c2.9-0.6,5.8-0.9,8.6-1.5
					c1.4-0.3,2.8-0.5,4.2-0.8c0.4-0.1,1.6-0.1,1.8-0.4c0.5-0.4-0.1-0.6-0.6-0.6c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0
					c-2.7,0.1-5.4,0.7-8.1,1.1c-2.1,0.3-4.3,0.7-6.4,1.3c0,0,0,0,0.1,0c-0.1,0-0.1,0-0.2,0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0.1,0
					c-1.2,0.3-2.5,0.6-3.7,1c0,0,0.1,0,0.1,0C250.5,429.5,248.7,429.9,247.3,430.6L247.3,430.6z"
              />
              <path
                class="st1"
                d="M255.7,428.1C255.7,428.1,255.7,428.1,255.7,428.1C255.6,428.1,255.6,428.1,255.7,428.1z"
              />
              <path
                class="st1"
                d="M255.9,428.1C255.9,428.1,255.9,428.1,255.9,428.1C255.9,428.1,255.9,428.1,255.9,428.1z"
              />
              <path
                class="st1"
                d="M279.6,425c-0.2,0-0.4,0.3-0.4,0.5c0.1,0.4,0.5,0.3,0.8,0.3c0.6,0,1.3-0.2,1.9-0.2c1.5,0,3,0.1,4.6,0.1
					c0.7,0,2.7,0.2,2.5-0.8c-0.2-1-2.6-0.6-3.4-0.6c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0l0,0
					c-0.7,0-1.5,0.1-2.2,0.1c0.1,0,0.1,0,0.2,0C282.1,424.6,280.8,424.8,279.6,425L279.6,425z"
              />
              <path
                class="st1"
                d="M308.2,426.5c-2.5-0.6-5-1.2-7.5-1.4c0,0,0,0,0.1,0c-0.1,0-0.2,0-0.3,0c0,0,0.1,0,0.1,0
					c-0.9-0.1-3.1-0.4-3.3,0.6c-0.2,1.1,2,1,2.8,1.1c3.4,0.4,6.6,0.8,9.9,1.6c3.1,0.7,6.2,1.3,9.3,2.1c1.4,0.3,2.8,0.8,4.2,1
					c0.2,0,1.5,0.4,1.2-0.2c-0.1-0.2-1.4-0.3-1.8-0.4c0,0,0,0,0.1,0c-0.1,0-0.1,0-0.2-0.1c0,0,0,0,0.1,0c-2.2-0.7-4.4-1.4-6.7-2.1
					c0,0,0.1,0,0.1,0C313.5,427.9,310.9,427.1,308.2,426.5L308.2,426.5z"
              />
              <path
                class="st1"
                d="M303,558.2c-6.2,0.5-12.3,0.8-18.5,0.7c-3.1-0.1-6.3,0.1-9.4-0.2c-1.5-0.1-2.9-0.3-4.4-0.4
					c-1-0.1-2.5,0.1-3.2,0l0,0c1,0.1,2,0.4,2.9,0.6c0,0,0,0-0.1,0c0.5,0.2,1,0.3,1.6,0.4c0,0,0,0,0,0c0.3,0.1,0.6,0.2,1,0.3
					c0,0-0.1,0-0.2,0c7.9,2.4,16.8,1.9,24.9,0.4c0,0-0.1,0-0.1,0c1.6-0.3,3.2-0.6,4.8-1.1c0.6-0.2,1.2-0.3,1.9-0.5
					c0.5-0.1,1.5-0.7,2-0.7c-0.6,0-1.3,0.3-1.9,0.4c0,0,0,0,0,0C303.7,558.2,303.4,558.2,303,558.2L303,558.2z"
              />
              <polygon class="st1" points="324,480.8 324,480.8 324,480.8 				" />
              <polygon class="st1" points="324,480.8 324,480.8 324,480.8 				" />
              <path
                class="st1"
                d="M398.1,497.1c-0.5-0.5-1.8-0.2-2.5-0.3c-1.2-0.1-2.5-0.4-3.7-0.7c-2.5-0.6-4.6-1.5-6.8-2.7h0h0l0,0
					c-6.5-3.7-11.1-9.3-16.7-13.8c-2.8-2.2-5.5-4.5-8.4-6.6c-3.1-2.2-6.2-4.4-9.5-6.4c-13.3-7.9-28.1-15.2-44.3-17.6
					c-4.6-0.7-9.3-1-13.9-1.1c-4.9-0.2-9.8-0.4-14.6-0.1h0.3c-6.8,0.2-13.7,1.4-20.3,2.7c-3.2,0.6-6.2,1.7-9.4,2.5
					c-3,0.8-5.9,1.8-8.8,2.9c-11.2,4.3-21.5,10.2-30.4,17.4c-2.7,2.2-5.3,4.5-8,6.7c-1.3,1-2.6,2-3.8,3.2c-1.2,1.2-2.3,2.4-3.7,3.4
					h0c-5.9,4.4-11.7,9.6-20,9.9c-1.2,0-2.7,0.2-2.8,1.5c-0.1,1.3,1.4,2.4,2.4,3.1c2.9,1.8,6.7,2.4,10.2,2.3h-0.1
					c1.2,0,2.6-0.3,3.8-0.6c4.4,5,10,9.1,15.8,12.9c2.9,1.9,6,3.5,9.2,5c3.5,1.6,6.9,3.3,10.4,4.8c0,0-0.1,0-0.1,0
					c7.7,3.1,15.8,5.9,24,8c4.2,1.1,8.4,2.1,12.6,3c4.2,0.9,8.5,1.9,12.8,2.5c0,0,0,0,0,0c0.6,0.1,1.2,0.2,1.9,0.3l0,0
					c4.1,0.5,16.5,0.6,17.9,0.5c1.5,0,4.1-0.3,6-0.5l0,0c0.5-0.1,1.1-0.1,1.6-0.2h0c0.1,0,0.3,0,0.4,0c0,0,0,0,0,0
					c5.7-0.8,11.5-1.6,17.1-2.9c5.3-1.2,10.6-2.6,15.7-4.3c5.1-1.7,10.2-3.6,15-5.8c5-2.3,9.6-5,14.2-7.7c-0.1,0.1-0.3,0.2-0.4,0.2
					c7.5-4.3,13.9-9.9,19.1-16.1c3.6,1.8,9.3,2.8,13.2,1.2c1.6-0.7,2.9-1.7,3.9-3C397.6,499.8,399.1,498,398.1,497.1L398.1,497.1z
 M313.1,485.1c1.9-0.7,3.8-1.5,5.7-2.2c0.1,0.2,0.2,0.3,0.2,0.5c-2.4,0.8-4.8,1.5-7.3,2.2C312.2,485.4,312.7,485.2,313.1,485.1
					L313.1,485.1z M317.1,481.9c0,0-0.1,0-0.1,0c0.4-0.2,0.8-0.3,1.2-0.5c0,0,0,0,0,0.1C317.8,481.7,317.4,481.8,317.1,481.9
					C317.1,481.9,317.1,481.9,317.1,481.9L317.1,481.9z M315.4,486.1c1.5-0.4,2.9-0.9,4.3-1.3c0.1,0.1,0.1,0.3,0.2,0.5
					c-2.7,0.7-5.4,1.3-8.1,1.9C313,486.8,314.2,486.5,315.4,486.1L315.4,486.1z M325.2,478.5c0.1,0.1,0.1,0.2,0.2,0.3
					c-1.1,0.5-2.2,0.9-3.3,1.3c0-0.1-0.1-0.2-0.1-0.2C323,479.4,324.1,479,325.2,478.5z M322.8,476.5c0.2-0.1,0.5-0.2,0.7-0.3
					c0,0,0.1,0.1,0.1,0.1C323.3,476.4,323,476.5,322.8,476.5L322.8,476.5z M327.7,507.7c-0.1,0-0.2-0.1-0.3-0.1c0,0,0,0,0,0l0,0l0,0
					c-0.1,0-0.1,0-0.2-0.1l0,0l0,0c0,0,0,0,0,0c0,0,0,0-0.1,0c0,0,0,0,0,0l0,0c0,0,0,0,0,0c0,0,0,0-0.1,0c0,0,0,0,0,0l0,0l0,0
					c0,0-0.1,0-0.1,0l0,0c-1-0.4-2.1-0.8-3.2-1.1c0.1-0.3,0.3-0.7,0.4-1c0.4,0.1,1.6,0.7,1.6-0.1c0-0.5-1-0.6-1.3-0.7
					c0.1-0.4,0.2-0.7,0.3-1c1.9,0.5,3.9,1,5.8,1.5c-0.3,1.2-0.6,2.4-1,3.6C329.1,508.1,328.5,507.9,327.7,507.7L327.7,507.7z
 M329.6,509.1c-0.4,1.2-0.9,2.4-1.4,3.5c-2-1-3.9-1.9-5.9-2.9c0.5-0.9,1-1.8,1.4-2.7C325.6,507.7,327.6,508.4,329.6,509.1
					L329.6,509.1z M328.7,503.4c-1.2-0.3-2.3-0.6-3.5-0.9c0.1-0.4,0.2-0.8,0.3-1.1c0.4,0.1,1.9,0.6,1.9-0.1c0-0.5-1.3-0.5-1.7-0.6
					c0.1-0.5,0.2-1.1,0.3-1.7c1.8,0.2,3.7,0.4,5.5,0.6c-0.1,1.5-0.2,2.9-0.5,4.3C330.2,503.7,329.5,503.5,328.7,503.4L328.7,503.4z
 M331.3,498.8c-1.8-0.2-3.5-0.4-5.3-0.6c0.1-0.6,0.1-1.3,0.1-1.9c1.8,0,3.5-0.1,5.3-0.1c0,0.9,0.1,1.8,0,2.7
					C331.4,498.9,331.4,498.8,331.3,498.8L331.3,498.8z M327.2,494.3c0.1,0,0.2,0,0.2,0c0,0,0.1,0.1,0.1,0.1c-0.5,0-0.9,0-1.4,0
					c0-0.1,0-0.1,0-0.2C326.5,494.3,326.9,494.3,327.2,494.3L327.2,494.3z M327.2,493.3c0.1,0,0.3,0,0.5,0
					C327.5,493.3,327.5,493.3,327.2,493.3z M331,493.4c-0.5,0-1-0.1-1.5-0.2c0.6,0,1.1-0.1,1.7-0.1c0,0.1,0,0.2,0,0.3
					C331.1,493.4,331.1,493.4,331,493.4L331,493.4z M327.4,492.5c-0.5,0-0.9,0-1.4,0.1c0-0.1,0-0.2,0-0.2c1.6-0.3,3.2-0.6,4.8-0.9
					c0.1,0.3,0.1,0.6,0.2,0.9C329.8,492.4,328.6,492.5,327.4,492.5L327.4,492.5z M329.6,490.4c-1.2,0.2-2.5,0.4-3.7,0.5
					c0-0.3-0.1-0.6-0.1-0.9c1.5-0.2,3.1-0.5,4.6-0.8c0.1,0.3,0.2,0.7,0.3,1C330.3,490.3,329.9,490.4,329.6,490.4L329.6,490.4z
 M325.4,488.2c-0.1-0.4-0.2-0.9-0.4-1.3c1.4-0.3,2.8-0.7,4.2-1c0.2,0.5,0.4,0.9,0.5,1.4C328.3,487.6,326.8,487.9,325.4,488.2
					L325.4,488.2z M324.7,485.8c0-0.1-0.1-0.2-0.1-0.3c1.3-0.4,2.7-0.7,4-1.1c0.1,0.1,0.1,0.3,0.2,0.5
					C327.4,485.1,326,485.4,324.7,485.8z M325.7,483.7c-0.5,0.1-1.1,0.3-1.6,0.4c-0.1-0.2-0.2-0.5-0.3-0.7c1.2-0.4,2.5-0.8,3.7-1.2
					c0.2,0.3,0.3,0.6,0.5,0.9C327.2,483.3,326.4,483.5,325.7,483.7L325.7,483.7z M323.9,481.8c-0.3,0.1-0.5,0.2-0.8,0.2
					c-0.1-0.2-0.2-0.5-0.4-0.7c0.4-0.2,0.8-0.4,1.2-0.5c0,0,0,0-0.1,0c0,0,0.1,0,0.1,0l0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
					c0,0,0,0,0,0l0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0.7-0.2,1.4-0.5,2.1-0.8c0.2,0.3,0.3,0.5,0.5,0.8
					C325.7,481.2,324.8,481.5,323.9,481.8L323.9,481.8z M324,480.8C324,480.8,324,480.8,324,480.8C324,480.8,324,480.8,324,480.8
					L324,480.8C324,480.8,324,480.8,324,480.8L324,480.8z M324,480.8C324,480.8,324.1,480.8,324,480.8
					C324.1,480.8,324,480.8,324,480.8z M324.1,480.8C324.1,480.8,324.1,480.8,324.1,480.8C324.1,480.8,324.1,480.8,324.1,480.8z
 M324.2,480.7C324.2,480.7,324.2,480.7,324.2,480.7C324.2,480.7,324.1,480.8,324.2,480.7C324.1,480.8,324.2,480.8,324.2,480.7z
 M324.2,480.7C324.2,480.7,324.2,480.7,324.2,480.7C324.2,480.7,324.2,480.7,324.2,480.7z M324.1,480.8
					C324.1,480.8,324.1,480.8,324.1,480.8C324.1,480.8,324.1,480.8,324.1,480.8C324.2,480.8,324.1,480.8,324.1,480.8L324.1,480.8z
 M323.5,511.3c1.4,0.7,2.9,1.4,4.3,2c-7,13.6-22.1,22.7-39.6,23.8c0.1,0,0.2,0,0.3,0c-15,0.9-29.1-4.4-38.9-14l0,0
					c-1-0.8-1.8-1.9-2.6-2.8c1.9-1.2,3.9-2.4,5.8-3.6l0,0c0,0,0,0,0.1,0c-0.1,0-0.1,0.1-0.2,0.1c0.3-0.2,0.7-0.4,1-0.6
					c11.4,12.5,32.6,16.7,49.5,9.9c5.6-2.2,10.5-5.6,14.3-9.7c0.5,0.3,2.9,2.2,3.5,1.6c0.7-0.7-2.4-2-2.9-2.2c0.5-0.6,1-1.2,1.5-1.8
					c0.5,0.3,2.3,1.8,2.7,0.8c0.3-0.8-1.5-1.4-2-1.7c0.6-0.8,1.1-1.6,1.6-2.4C322.4,510.8,322.9,511,323.5,511.3L323.5,511.3z
 M246.1,470c0,0,0.1,0,0.1,0C246.2,470,246.2,470,246.1,470c0.1,0,0.1,0,0.1,0C246.2,469.9,246.1,470,246.1,470L246.1,470
					C246.1,470,246.1,470,246.1,470L246.1,470z M246.2,476.9c1.3,0.6,2.6,1.2,3.9,1.8c0,0-0.1,0.1-0.1,0.1c-1.3-0.6-2.6-1.2-3.9-1.8
					C246.2,477,246.2,477,246.2,476.9L246.2,476.9z M263.1,494.3c0.6,0,1.3-0.1,1.9-0.1c0,0.3,0,0.7,0,1c-1.7-0.1-3.5,0.1-5.1,0.2
					c0-0.3,0-0.7,0-1C261,494.4,262,494.3,263.1,494.3L263.1,494.3z M260,493.5C260,493.5,260,493.5,260,493.5c0.3-0.1,0.6-0.1,1,0
					C260.6,493.5,260.3,493.5,260,493.5L260,493.5z M265.4,498c0,0.2,0.1,0.5,0.2,0.7c-1.7,0.3-3.5,0.5-5.2,0.8
					c-0.1-0.4-0.2-0.8-0.3-1.2c1.7-0.2,3.5-0.5,5.2-0.7C265.4,497.8,265.4,497.9,265.4,498L265.4,498z M265.4,490.3
					c0.1,0,0.1,0,0.2,0c0,0,0,0.1,0,0.1C265.5,490.4,265.5,490.3,265.4,490.3L265.4,490.3z M263.3,501.3
					C263.3,501.3,263.3,501.3,263.3,501.3C263.3,501.3,263.3,501.3,263.3,501.3c-0.7,0.1-1.4,0.3-2.2,0.4c-0.1-0.3-0.2-0.7-0.4-1
					c1.8-0.3,3.5-0.5,5.3-0.8c0.1,0.2,0.2,0.5,0.3,0.7c0,0-0.1,0-0.1,0c0,0,0,0,0.1,0C265.3,500.9,264.3,501.1,263.3,501.3
					L263.3,501.3z M263.7,502.2c0.2-0.1,0.5-0.1,0.7-0.2c-0.9,0.3-1.8,0.7-2.8,1c-0.1-0.1-0.1-0.2-0.2-0.4
					C262.2,502.5,263,502.3,263.7,502.2L263.7,502.2z M263.2,501.4C263.2,501.4,263.2,501.4,263.2,501.4
					C263.2,501.4,263.2,501.4,263.2,501.4z M263.1,496.9c-1,0.1-2.1,0.3-3.1,0.4c0-0.2,0-0.5-0.1-0.8c1.6-0.1,3.7,0.1,5.2-0.3
					c0,0.1,0,0.2,0,0.3C264.5,496.7,263.8,496.8,263.1,496.9L263.1,496.9z M260.2,506.6C260.2,506.6,260.3,506.6,260.2,506.6
					c-1.6,0.8-3.9,1.4-5,2.7c-0.7,0.8,0.1,1.1,1.1,0.7c1.6-0.7,3.1-1.6,4.6-2.4c0.4,0.5,0.8,1,1.2,1.5c-1.6,1-3.2,2-4.9,3.1
					c0,0,0,0,0,0c-0.1,0-0.1,0.1-0.2,0.1l0,0c-0.6,0.4-1.2,0.8-1.8,1.2c-1.5-1.7-2.7-3.6-3.8-5.5c2.6-1,5.1-1.9,7.7-2.9
					C259.6,505.5,259.9,506.1,260.2,506.6L260.2,506.6z M261.4,514.9c-0.3,0.2-0.2,0.7,0.2,0.8c0.5,0.2,0.9-0.3,1.1-0.5
					c1-0.9,2.1-1.8,3.2-2.7c10.8,8.3,27.8,8.3,38.4-0.2c1.3,1,2.6,1.9,3.8,2.9c0.5,0.4,1.9,1.8,2.6,1c0.5-0.7-0.8-1.5-1.3-1.8
					c-1.4-1-2.7-2-4-3c0.6-0.5,1.2-1.1,1.7-1.6c2.3,1.6,4.7,3.1,7.1,4.6c-10.3,11.4-29.8,15.2-45.1,8.9c-5-2.1-9.3-5.1-12.8-8.8
					c0.6-0.4,1.2-0.8,1.8-1.2c0,0,0,0,0,0c1.7-1.1,3.4-2.1,5-3.2c0.6,0.6,1.2,1.2,1.9,1.8c-0.5,0.5-1.1,0.9-1.7,1.4
					c0,0,0.1,0,0.1-0.1C262.8,513.7,262.1,514.3,261.4,514.9L261.4,514.9z M307.7,509.1c0.4-0.5,0.8-0.9,1.2-1.4
					c2.4,1.4,4.9,2.8,7.3,4.2c-0.5,0.6-0.9,1.2-1.4,1.8C312.3,512.2,310,510.6,307.7,509.1L307.7,509.1z M258.2,513.2
					C258.2,513.2,258.3,513.2,258.2,513.2C258.3,513.2,258.3,513.2,258.2,513.2C258.3,513.2,258.3,513.2,258.2,513.2
					C258.3,513.2,258.2,513.2,258.2,513.2C258.2,513.2,258.2,513.2,258.2,513.2C258.2,513.2,258.2,513.2,258.2,513.2L258.2,513.2
					c-0.1,0.1-0.1,0.1-0.1,0.1C258.2,513.3,258.2,513.2,258.2,513.2C258.2,513.2,258.2,513.2,258.2,513.2
					C258.2,513.2,258.2,513.2,258.2,513.2L258.2,513.2z M249.4,488.1C249.4,488.1,249.5,488.1,249.4,488.1L249.4,488.1
					c0.1,0,0.1,0,0.1,0c0,0,0,0,0,0c0.4,0.1,0.9,0.1,1.3,0.2c0,0,0,0,0,0c2.2,0.3,4.2,0.9,6.4,1.4c0,0,0,0,0,0c-2.6-0.3-5.3-0.7-8-1
					C249.3,488.4,249.3,488.2,249.4,488.1C249.4,488,249.4,488,249.4,488.1C249.4,488,249.4,488,249.4,488.1L249.4,488.1z
 M249.7,489.8c-0.2,0-0.5,0-0.8-0.1c0,0,0,0,0,0C249.2,489.7,249.5,489.7,249.7,489.8L249.7,489.8z M256.8,491.3
					c0,0.3-0.1,0.6-0.1,0.9c-2.7-0.2-5.4-0.4-8.1-0.7c0-0.3,0.1-0.6,0.1-0.8C251.5,490.9,254.2,491.1,256.8,491.3L256.8,491.3z
 M250.4,492.8c2.1,0.2,4.1,0.3,6.2,0.5c0,0.1,0,0.3,0,0.4c-2.7,0.1-5.4,0.2-8.1,0.4c0-0.5,0-1,0.1-1.4
					C249.1,492.7,249.8,492.7,250.4,492.8z M255,494.6c0.5,0,1,0,1.5-0.1c0,0.4,0,0.7,0,1.1c-2.7,0.1-5.4,0.3-8.1,0.4
					c0-0.4,0-0.7,0-1.1C250.6,494.8,252.8,494.7,255,494.6L255,494.6z M251.9,497c1.6-0.1,3.1-0.2,4.7-0.3c0,0.3,0.1,0.6,0.1,1
					c-2.7,0.4-5.4,0.7-8.1,1.1c-0.1-0.6-0.1-1.1-0.2-1.7C249.6,497.1,250.8,497,251.9,497L251.9,497z M256.3,498.9
					c0.2,0,0.4-0.1,0.6-0.1c0.1,0.4,0.2,0.8,0.3,1.2c-2.7,0.4-5.4,0.8-8.1,1.2c-0.1-0.4-0.2-0.8-0.3-1.2
					C251.3,499.5,253.8,499.2,256.3,498.9L256.3,498.9z M253,501.9c1.6-0.2,3.1-0.4,4.7-0.6c0.1,0.4,0.3,0.8,0.4,1.2
					c-2.7,0.5-5.4,1.1-8.1,1.6c-0.2-0.5-0.4-1.1-0.5-1.7C250.6,502.2,251.8,502.1,253,501.9L253,501.9z M256.2,503.7
					c0.8-0.1,1.5-0.3,2.2-0.5c0.1,0.3,0.2,0.5,0.4,0.8c-2.6,0.9-5.1,1.9-7.6,2.9c-0.3-0.7-0.6-1.3-0.9-2
					C252.2,504.5,254.2,504.1,256.2,503.7z M258.7,485.6c-2.4-1-4.8-1.9-7.2-2.9c0.1-0.1,0.1-0.2,0.1-0.3c2.4,0.9,4.9,1.8,7.2,2.8
					C258.8,485.3,258.8,485.4,258.7,485.6z M252.1,484.1c-0.4-0.1-0.8-0.2-1.1-0.4c0,0,0,0,0-0.1C251.4,483.8,251.8,484,252.1,484.1
					z M252.3,484.7c2,0.6,3.9,1.3,5.9,2c0,0.1-0.1,0.1-0.1,0.2c-2.5-0.8-5-1.5-7.5-2.3c0.1-0.1,0.1-0.3,0.2-0.4
					C251.3,484.4,251.8,484.6,252.3,484.7L252.3,484.7z M254.5,486.8c1.1,0.3,2.2,0.7,3.3,1c0,0.1,0,0.1-0.1,0.2
					c-2.5-0.7-5-1.4-7.6-2.1c0.1-0.1,0.1-0.2,0.1-0.4C251.7,486,253.1,486.4,254.5,486.8L254.5,486.8z M246.7,479.8
					c0.7,0.3,1.4,0.6,2.2,1c-1.4-0.5-2.9-0.9-4.4-1.4c0.1-0.1,0.2-0.3,0.3-0.4C245.4,479.3,246.1,479.5,246.7,479.8L246.7,479.8z
 M245,480.1c1.2,0.4,2.5,0.8,3.7,1.2c0,0.1-0.1,0.2-0.1,0.3c-1.5-0.5-3-1.1-4.5-1.5c0.1-0.1,0.1-0.2,0.2-0.3
					C244.5,479.9,244.7,480,245,480.1L245,480.1z M244.6,481.3c1.1,0.4,2.3,0.8,3.4,1.2c0,0.1-0.1,0.1-0.1,0.2
					c-0.7-0.2-1.5-0.5-2.2-0.7l0,0c-0.8-0.3-1.6-0.6-2.4-0.8c0.1-0.1,0.1-0.2,0.1-0.2C243.9,481.1,244.3,481.2,244.6,481.3
					L244.6,481.3z M244.1,482c1.2,0.4,2.4,0.9,3.6,1.2c-0.1,0.1-0.1,0.3-0.2,0.5c-1.4-0.4-3.2-1.3-4.7-1.4c0.1-0.2,0.2-0.4,0.3-0.6
					C243.5,481.8,243.8,481.9,244.1,482L244.1,482L244.1,482z M247.2,484.6c-0.1,0.1-0.1,0.3-0.2,0.5c-1.6-0.5-3.2-1-4.9-1.5
					c0.1-0.1,0.1-0.3,0.2-0.5c0.5,0.4,1.3,0.5,1.9,0.6C245.3,484,246.2,484.3,247.2,484.6L247.2,484.6z M243.2,484.2
					c0.8,0.2,1.6,0.5,2.4,0.7c-1-0.2-2.7-1-3.7-0.8c0-0.1,0.1-0.2,0.1-0.3C242.4,483.9,242.8,484.1,243.2,484.2L243.2,484.2z
 M241.9,485.2c1.1,0.2,2.1,0.5,3.1,0.7c-1.2-0.1-2.4-0.5-3.6-0.8c0-0.1,0.1-0.1,0.1-0.2C241.6,485.1,241.8,485.2,241.9,485.2z
 M241.8,486c1.6,0.4,3.1,0.7,4.7,1c0,0.1,0,0.1-0.1,0.2c-0.3,0-0.7-0.1-1-0.1c0,0,0.1,0,0.1,0c-0.3,0-0.7-0.1-1-0.1l0,0
					c-1.1-0.1-2.3-0.3-3.5-0.4c0.1-0.2,0.2-0.5,0.3-0.7C241.4,485.9,241.6,486,241.8,486L241.8,486z M241.3,487.3
					c1.7,0.1,3.3,0.3,5,0.4c0,0.2-0.1,0.4-0.1,0.5c-1.8-0.2-3.7-0.5-5.5-0.7c0-0.1,0.1-0.2,0.1-0.3
					C240.9,487.3,241.1,487.3,241.3,487.3L241.3,487.3z M245.9,489.3c-0.1,0.6-0.2,1.3-0.3,1.9c-1.9-0.2-3.9-0.4-5.8-0.6
					c0.2-0.7,0.3-1.4,0.5-2C242.1,488.8,244,489,245.9,489.3L245.9,489.3z M243.6,492.1c0.6,0.1,1.2,0.1,1.9,0.2
					c-0.1,0.6-0.1,1.3-0.1,1.9c-0.6,0-2.5-0.2-2.6,0.5c-0.1,0.9,2.1,0.4,2.6,0.4c0,0.3,0,0.7,0,1c-0.8,0-1.5,0.1-2.3,0.1
					c0.1,0,0.1,0,0.2,0c-0.5,0-1,0.1-1.5,0.1c0.1,0,0.1,0,0.2,0c-1,0.1-1.9,0.2-2.9,0.3c0.1-1.7,0.2-3.4,0.6-5
					C240.9,491.8,242.2,491.9,243.6,492.1L243.6,492.1z M241.3,497.5c1.4-0.1,2.7-0.2,4.1-0.2c0.1,0.7,0.1,1.3,0.2,2
					c-0.6,0.1-2.6,0-2.5,0.9c0.1,0.9,2,0.3,2.7,0.2c0.1,0.5,0.2,0.9,0.3,1.3c-2.3,0.4-4.5,0.8-6.8,1.2c-0.2-1.7-0.3-3.5-0.3-5.2
					C239.7,497.6,240.5,497.5,241.3,497.5L241.3,497.5z M246.3,502.8c0.2,0.6,0.4,1.2,0.6,1.8c-0.6,0.1-2.9,0.2-3,1
					c0,1,2.8,0,3.3-0.1c0.3,0.8,0.7,1.7,1.1,2.5c-2.3,0.9-4.6,1.7-6.9,2.5c-0.9-2.1-1.5-4.3-1.9-6.6
					C241.7,503.6,244,503.2,246.3,502.8z M243.3,510.9c1.8-0.6,3.6-1.3,5.4-1.9c1.1,2.1,2.5,4.2,4.1,6.1c-2.1,1.4-4.3,2.8-6.4,4.2
					c-1.9-2.5-3.4-5-4.6-7.8C242.3,511.3,242.8,511.1,243.3,510.9L243.3,510.9z M316.6,497.9c1.7,0.2,3.4,0.4,5.1,0.6
					c0,0.5-0.1,0.9-0.2,1.4c-2.8-0.5-5.7-1-8.5-1.6c0-0.3,0.1-0.6,0.1-0.8C314.2,497.6,315.4,497.7,316.6,497.9L316.6,497.9z
 M313.1,496.7c0-0.1,0-0.1,0-0.2c2.9,0,5.8,0,8.7-0.1c0,0.4,0,0.9-0.1,1.3C318.9,497.3,316,497,313.1,496.7L313.1,496.7z
 M318.4,500c1,0.2,2,0.4,3,0.5c0,0.3-0.1,0.5-0.2,0.8c-2.8-0.7-5.6-1.5-8.4-2.2c0,0,0-0.1,0-0.1
					C314.7,499.3,316.5,499.7,318.4,500L318.4,500z M315.4,500.8c1.9,0.5,3.7,1,5.5,1.5c-0.1,0.3-0.1,0.5-0.2,0.8
					c-2.7-0.9-5.5-1.9-8.2-2.8c0,0,0-0.1,0-0.1C313.5,500.4,314.4,500.6,315.4,500.8z M315.2,502.2c1.7,0.6,3.5,1.2,5.2,1.7
					c-0.1,0.3-0.2,0.6-0.3,1c-2.7-1-5.4-1.9-8.1-2.9c0.1-0.3,0.2-0.5,0.3-0.8C313.2,501.5,314.2,501.9,315.2,502.2L315.2,502.2z
 M319.1,494.7c-2,0-3.9,0-5.9,0.1c0-0.1,0-0.3,0-0.4c2.9-0.1,5.8-0.1,8.6-0.1c0,0.1,0,0.2,0,0.4
					C320.9,494.6,320,494.7,319.1,494.7L319.1,494.7z M321,491.6c-2.7,0.2-5.3,0.5-8,0.7c0-0.1,0-0.3-0.1-0.4
					c2.8-0.4,5.7-0.7,8.5-1.1c0,0.3,0.1,0.5,0.1,0.8C321.4,491.5,321.2,491.6,321,491.6L321,491.6z M315.3,489.8
					c-0.9,0.1-1.8,0.2-2.7,0.4c0-0.2-0.1-0.4-0.1-0.6c2.8-0.5,5.5-1,8.3-1.6c0.1,0.4,0.2,0.7,0.3,1.1
					C319.2,489.3,317.3,489.5,315.3,489.8L315.3,489.8z M319.1,487c-0.8,0.2-1.6,0.3-2.5,0.5c1.3-0.3,2.5-0.6,3.8-0.9
					c0,0,0,0.1,0,0.1C320,486.8,319.6,486.9,319.1,487L319.1,487z M311.7,502.8c2.7,1,5.4,1.9,8.1,2.9c-0.1,0.3-0.2,0.5-0.3,0.8
					c-2.6-1.2-5.2-2.4-7.8-3.6C311.6,502.8,311.7,502.8,311.7,502.8L311.7,502.8z M311,504.2c2.6,1.2,5.1,2.4,7.7,3.5
					c0,0.1-0.1,0.2-0.2,0.3c-2.6-1.2-5.2-2.4-7.8-3.5C310.9,504.4,311,504.3,311,504.2z M312.6,506.3c1.9,0.8,3.8,1.7,5.6,2.6
					c-0.4,0.7-0.9,1.4-1.4,2.1c-2.4-1.4-4.8-2.9-7.2-4.3c0.3-0.4,0.6-0.9,0.8-1.3C311.2,505.6,311.9,506,312.6,506.3L312.6,506.3z
 M306.8,497c-0.5-0.1-1-0.2-1.4-0.4c0,0,0-0.1,0-0.1c1.6,0.2,3.2,0.4,4.8,0.6c0,0.2,0,0.4-0.1,0.6
					C309,497.5,307.9,497.3,306.8,497C306.8,497,306.8,497,306.8,497L306.8,497z M310.3,494.7L310.3,494.7c0,0-0.1,0-0.1,0
					C310.3,494.8,310.3,494.8,310.3,494.7z M310.2,492.4c0,0,0,0.1,0,0.1c-0.4,0-0.7,0-1,0.1C309.5,492.5,309.8,492.5,310.2,492.4z
 M305.5,490.5c1.4-0.1,2.8-0.3,4.1-0.5c0,0.1,0.1,0.3,0.1,0.5c-1.6,0.2-3.1,0.3-4.7,0.5c0-0.1-0.1-0.3-0.1-0.4
					C305.1,490.6,305.3,490.6,305.5,490.5L305.5,490.5z M305,498.7c1.5,0.5,3,1.1,4.5,1.6c-0.1,0.2-0.2,0.5-0.3,0.8
					c-1.5-0.5-3-1.1-4.5-1.6C304.8,499.2,304.9,499,305,498.7L305,498.7z M304.2,500.8c1.4,0.7,2.8,1.4,4.3,2.1
					c-0.1,0.1-0.1,0.3-0.2,0.4c-1.4-0.6-2.9-1.3-4.3-1.9C304,501.2,304.1,501,304.2,500.8L304.2,500.8z M303.5,502.2
					c1.5,0.6,2.9,1.3,4.4,1.9c-0.2,0.4-0.4,0.7-0.6,1c-1.3-0.8-2.7-1.7-4-2.5C303.3,502.5,303.4,502.4,303.5,502.2L303.5,502.2z
 M302.4,503.9c1.4,0.8,2.7,1.6,4.1,2.4c-0.3,0.4-0.7,0.9-1,1.3c-1.3-0.9-2.6-1.8-3.9-2.6C301.8,504.6,302.1,504.3,302.4,503.9
					L302.4,503.9z M300.9,505.6c1.3,0.9,2.6,1.8,4,2.7c-0.5,0.5-1,1-1.6,1.5c-1.3-0.9-2.5-1.9-3.8-2.8
					C299.9,506.6,300.4,506.1,300.9,505.6L300.9,505.6z M271.8,507.4c7.5,5.8,18.8,5.8,26.5,0.4c1.3,1,2.5,1.9,3.8,2.9
					c-9.5,7.4-24.7,7.5-34.2,0C269.3,509.7,270.5,508.5,271.8,507.4L271.8,507.4z M269.9,505.9C269.9,505.9,270,505.9,269.9,505.9
					c0.4,0.3,0.8,0.6,1.1,1c-1.3,1.1-2.5,2.2-3.8,3.3c-0.6-0.5-1.1-1-1.6-1.5c0.3-0.2,0.7-0.4,1-0.6c0,0,0,0,0,0
					C267.7,507.3,268.8,506.6,269.9,505.9L269.9,505.9z M268.4,484.9L268.4,484.9C268.4,484.9,268.4,484.9,268.4,484.9L268.4,484.9
					L268.4,484.9z M268.7,504.4c0.1,0.1,0.2,0.2,0.3,0.3c-1.1,0.7-2.2,1.4-3.2,2c0,0,0,0,0,0c0,0-0.1,0.1-0.2,0.1l0,0c0,0,0,0,0,0
					c0,0,0,0,0,0c-0.4,0.2-0.7,0.5-1.1,0.7c-0.4-0.4-0.7-0.9-1-1.3c1.5-0.8,3.1-1.6,4.7-2.4C268.4,504,268.6,504.2,268.7,504.4
					L268.7,504.4z M264.4,504.5c-0.5,0.2-1,0.5-1.4,0.7c-0.3-0.4-0.5-0.9-0.8-1.3c1.6-0.6,3.3-1.2,5-1.8c0.1,0.2,0.3,0.5,0.5,0.8
					C266.5,503.4,265.5,504,264.4,504.5L264.4,504.5z M263.1,484.7c1.4,0.6,2.8,1.3,4.1,2c-0.1,0.2-0.2,0.4-0.3,0.6
					c-1.4-0.5-2.7-1.5-4.1-2.2C262.9,484.9,263,484.8,263.1,484.7z M262,486.6c1.4,0.6,2.9,1.3,4.3,1.9c0,0,0,0.1-0.1,0.1
					c-1.5-0.6-2.9-1.2-4.4-1.8C261.9,486.8,261.9,486.7,262,486.6L262,486.6z M261.4,487.9c0,0,0.1,0,0.1,0
					C261.5,487.9,261.4,487.9,261.4,487.9C261.4,487.9,261.4,487.9,261.4,487.9L261.4,487.9z M261,488.8c1.2,0.4,2.5,0.7,3.7,1.1
					c-1.3-0.2-2.5-0.6-3.8-0.9C261,488.9,261,488.9,261,488.8z M260.2,491.6c1.7,0.1,3.3,0.3,5,0.4c0,0.2,0,0.3-0.1,0.5
					c-1.7,0-3.4-0.1-5-0.1C260.2,492.1,260.2,491.8,260.2,491.6L260.2,491.6z M260,483.2c-0.1,0.1-0.1,0.2-0.2,0.3
					c-2.3-1.1-4.6-2.2-6.8-3.3c0-0.1,0.1-0.1,0.1-0.2C255.4,481.1,257.7,482.2,260,483.2L260,483.2z M200.1,495
					c0.6-0.5,1.3-1,1.9-1.5c-1.4,4.7-1.9,10.1-0.9,14.9c-1.1-0.9-2.2-1.7-3.3-2.6c0.1-2.2,0.4-4.4,0.6-6.6c0.1-0.9,0-1.8,0.3-2.6
					C198.9,495.9,199.4,495.5,200.1,495L200.1,495z M194.7,503.3c-1-0.9-1.9-1.7-2.9-2.6c1.8-1,3.4-2.3,5.1-3.4
					c-0.3,2.4-0.6,4.9-0.7,7.3C195.7,504.1,195.2,503.8,194.7,503.3z M252.3,532.3c-9.2-2.6-18.2-5.6-26.7-9.5
					c-2.1-1-4.3-2.1-6.3-3.2c-1.7-0.9-4.1-1.7-5.4-2.9c-1.1-1.1-1.3-2.7-1.7-4.1c-0.4-1.7-0.8-3.3-0.6-5c0.1-0.8-0.6-1.9-1.4-0.8
					c-0.4,0.5-0.2,1.5-0.2,2.1c0.2,2.2,0.8,4.4,1.6,6.6c-1.2-0.6-2.6-1.1-3.2-2.2c-0.8-1.4-1-3.2-1.2-4.8c0,0,0,0,0,0
					c0,0,0-0.1,0-0.2v0c-0.2-1.5-0.2-3-0.3-4.6c0-0.3,0.1-0.8-0.3-1c-0.6-0.3-0.9,0.1-1.1,0.5c-0.4,1.1,0,2.9,0.1,4
					c0.1,1.7,0.4,3.4,0.8,5.1c-1.4-1-3.5-2-4-3.6c-0.6-2.1-0.5-4.4-0.4-6.5c0.1-2.2,0.2-4.4,0.7-6.5c0.1-0.7,1.2-2.7,0.5-3.2
					c1-0.8,2-1.6,3-2.4c-0.4,2.5-0.7,5.1-1.1,7.6c-0.1,0.5-0.3,1.6,0.5,1.8c1,0.3,1.1-0.9,1.2-1.4c0.2-2.4,0.5-4.9,0.9-7.3
					c0.2-1.3,0.4-2.4,1.4-3.4c1.1-1.1,2.3-2.1,3.4-3.2l-0.1,0.1c1.2-1,2.2-2.1,3.4-3.2h0c2.6-2.2,5-4.7,8-6.6
					c3.3-2.1,7-3.8,10.7-5.4c7.4-3.2,15.3-5.8,23.2-7.9c-1.2,0.7-2.3,1.6-3.7,2.1c-1.5,0.6-3.1,1-4.7,1.5c-3.1,1-6.1,2.2-9,3.4
					c-1.4,0.6-2.8,1.1-4.1,1.8c-0.7,0.4-3,1.2-3.2,2c-0.3,1,1.3,0,1.7-0.2c1.3-0.7,2.8-1.3,4.2-1.8c0,0,0,0-0.1,0c0.1,0,0.1,0,0.1,0
					c0,0,0,0,0,0c4-1.4,7.9-3,12-4.3c-0.6,0.4-1,1-1.6,1.4c-0.6,0.4-1.4,0.6-2.1,0.8c-1.7,0.6-3.4,1.3-5.1,2.1
					c-3.5,1.6-6.9,3.4-10.1,5.4c0,0,0,0,0,0c0,0,0,0-0.1,0c0,0,0,0,0.1,0c-0.3,0.2-2.3,1.2-2.2,1.6c0.1,0.4,3.1-1.3,3.3-1.4
					c1.3-0.7,2.7-1.4,4.1-2c2.9-1.3,5.9-2.5,9-3.6c-0.6,0.6-1,1.3-1.7,1.8c-0.7,0.6-1.6,0.9-2.5,1.2c-1.8,0.8-3.6,1.6-5.4,2.5
					c-0.4,0.2-1.2,0.5-1.2,1c0.1,0.8,1.1,0.4,1.5,0.2c1.7-0.8,3.4-1.5,5.2-2c-4.5,6-7.1,12.9-7.8,20c-0.3,3.8,0,7.6,0.8,11.3
					c0.7,3.3,1.7,7.2,3.8,10.1c0,0,0,0,0,0c2.2,3.4,4.5,6.9,7.4,9.9c2.9,3,6.5,5.5,10.3,7.7C255.3,533.2,253.8,532.7,252.3,532.3
					L252.3,532.3z M364.3,512.2c-0.2,0.2-0.5,0.4-0.8,0.6c0.4-2.2,0.6-4.5,0.9-6.7c0.1-0.8,0.5-1.9,0.1-2.7c-0.3-0.7-1.3-1-1.9-0.3
					c-0.5,0.5-0.3,1.5-0.3,2.1c0,1.1-0.2,2.1-0.3,3.2c-0.1,1.1-0.3,2.2-0.5,3.2c-0.2,1-0.6,2-0.5,3c-1.1,0.8-2.2,1.5-3.4,2.2
					c0.7-2.2,1.1-4.3,1.5-6.5c0.1-0.5,0.4-1.5-0.1-1.9c-0.8-0.5-1,0.4-1.1,0.9c-0.4,2.3-0.8,4.5-1.5,6.7c-0.2,0.8-0.4,1.7-1,2.3
					c-0.7,0.7-1.8,1-2.7,1.6c0.1-0.1,0.2-0.1,0.4-0.2c-12.3,7.1-26.2,11.8-40.6,14.7c17.9-9.7,27-27.7,22.8-45.3
					c-1.2-5.1-3.4-10-6.6-14.4c0.8,0.4,2.8,1.9,3.8,1.8c1.2-0.1-0.7-1.2-1-1.4c-1.2-0.7-2.4-1.3-3.6-2c-1.2-0.7-1.9-1.7-2.8-2.7
					c4.4,1.8,8.5,3.9,12.6,6.1c1,0.5,1.9,1,2.8,1.6c0.3,0.2,1.4,1.1,1.9,1c1.3-0.2-1.7-1.9-2-2.1c0,0,0,0,0,0
					c-3.2-2.2-6.6-4.2-10.3-5.9c-1.7-0.8-3.5-1.6-5.3-2.3c-0.8-0.3-1.7-0.5-2.4-1c-0.6-0.4-1.1-1-1.7-1.5c3.5,1.2,7,2.5,10.3,4
					c1.5,0.7,3.1,1.4,4.5,2.2c0.3,0.2,1.8,1.3,2.2,0.9c0.4-0.4-4.2-2.8-4.5-2.9c-3.2-1.8-6.5-3.5-10-4.7c0.1,0,0.1,0,0.2,0.1
					c0,0,0,0,0,0l0,0c0,0,0,0-0.1,0l0,0c0,0,0,0-0.1,0c0,0,0,0,0,0c-0.1,0-0.2-0.1-0.3-0.1c0,0,0,0,0,0c-1.3-0.5-2.6-1-4-1.3
					c-1.4-0.4-2.3-1-3.5-1.8c5.2,1.5,10.4,3.3,15.4,5.4c4.6,2,9.7,4,13.5,7c4.1,3.2,8.5,6.1,12.4,9.5c1.7,1.5,3.8,2.6,4.2,4.7
					c0.5,1.9,0.8,3.8,1,5.8c0.1,0.7,0.1,1.7,0.8,2.2c0.7,0.5,1.3,0.2,1.7-0.4c0.9-1.7,0.2-4.3-0.2-6c0.7,0.7,1.7,1.5,2.1,2.4
					c0.3,0.7,0.3,1.6,0.4,2.3c0.2,2.1,0.3,4.1,0.3,6.2c0,2-0.1,4-0.5,6c-0.1,0.8-0.2,1.6-0.5,2.4C365.9,511.1,365,511.6,364.3,512.2
					L364.3,512.2z M371.4,505.9c-0.3,0.3-0.7,0.6-1,1c0,0,0,0,0,0l0,0c0.1-0.1,0.2-0.2,0.3-0.3c-0.9,0.9-1.8,1.8-2.8,2.6
					c0.8-4.8,0.5-9.7,0.1-14.5c1,0.9,2,1.8,3.1,2.6l0,0l0,0l0,0c0.2,0.2,0.5,0.4,0.7,0.5c-0.4,1.4,0,2.9,0.1,4.3
					C371.9,503.2,372.4,504.9,371.4,505.9L371.4,505.9z M373.3,503.8c0-1.7,0-3.3-0.1-5c0.9,0.6,1.7,1.3,2.7,1.8
					C375.1,501.7,374.2,502.8,373.3,503.8L373.3,503.8z"
              />
              <path class="st1" d="M296.4,539.1L296.4,539.1L296.4,539.1z" />
              <path
                class="st1"
                d="M296.4,539.1C296.4,539.1,296.4,539.1,296.4,539.1C296.4,539.1,296.4,539.1,296.4,539.1z"
              />
              <path
                class="st1"
                d="M265.7,506.8C265.7,506.8,265.7,506.8,265.7,506.8C265.7,506.8,265.7,506.8,265.7,506.8
					C265.7,506.8,265.7,506.8,265.7,506.8L265.7,506.8z"
              />
              <path
                class="st1"
                d="M258.2,513.2C258.2,513.2,258.2,513.2,258.2,513.2C258.2,513.2,258.2,513.2,258.2,513.2L258.2,513.2z"
              />
              <polygon
                class="st1"
                points="258.1,513.2 258.1,513.2 258.1,513.2 				"
              />
              <path
                class="st1"
                d="M265.6,506.9L265.6,506.9C265.6,506.9,265.6,506.8,265.6,506.9C265.6,506.8,265.6,506.8,265.6,506.9
					L265.6,506.9z"
              />
              <path
                class="st1"
                d="M265.6,506.8C265.6,506.8,265.6,506.8,265.6,506.8C265.6,506.8,265.6,506.8,265.6,506.8z"
              />
              <path class="st1" d="M258.1,513.3L258.1,513.3L258.1,513.3z" />
              <path
                class="st1"
                d="M265.7,506.8C265.7,506.8,265.6,506.8,265.7,506.8C265.6,506.8,265.7,506.8,265.7,506.8z"
              />
              <polygon class="st1" points="324,480.8 324,480.8 324,480.8 				" />
              <path
                class="st1"
                d="M324,480.8L324,480.8C324,480.8,324,480.8,324,480.8C324,480.8,324,480.8,324,480.8z"
              />
              <path
                class="st1"
                d="M272.8,511.6c1.1,0.5,2.2,1.1,3.4,1.4c1,0.2,1.8-0.3,0.8-1c-0.8-0.5-2-0.8-2.9-1.2c-0.5-0.2-1-0.5-1.4-0.2
					C272.4,510.9,272.4,511.4,272.8,511.6L272.8,511.6z"
              />
              <path
                class="st1"
                d="M281,513.7c0.6,0.1,1.2,0.2,1.8,0.3c0,0-0.1,0-0.1,0c0.2,0,0.4,0,0.5,0.1c0,0-0.1,0-0.1,0
					c0.4,0.1,2,0.4,1.7-0.4c-0.2-0.5-2.2-0.5-2.6-0.5c0,0,0.1,0,0.1,0c-0.3,0-1.1-0.3-1.4-0.1C280.7,513.2,280.6,513.6,281,513.7z"
              />
              <path
                class="st1"
                d="M288.3,514.2c0.9-0.1,1.8-0.2,2.6-0.4c0.4-0.1,1.4-0.5,0.5-0.8c-0.5-0.2-1.5,0.2-2,0.3
					c-0.3,0-1.1,0-1.3,0.2C287.8,513.7,287.6,514.3,288.3,514.2L288.3,514.2z"
              />
              <path
                class="st1"
                d="M295.2,512.2c0.4-0.2,3.5-1.8,1.8-2c-0.5,0-1.4,0.8-1.8,1c-0.2,0.1-0.7,0.3-0.8,0.6
					C294.5,512.1,294.9,512.4,295.2,512.2z"
              />
              <path
                class="st1"
                d="M265.7,517.1c0.9,0.5,2,1.1,3,1.4c0.8,0.2,1.4-0.2,0.7-0.8c-0.5-0.4-2-0.8-2.7-1.1c-0.2-0.1-0.6-0.4-1-0.2
					C265.4,516.5,265.3,516.9,265.7,517.1L265.7,517.1z"
              />
              <path
                class="st1"
                d="M273.2,520.8c0.9,0.3,1.9,0.6,2.9,0.8c1,0.2,1.7-0.3,0.6-0.8c-0.7-0.3-2.1-0.7-2.9-0.8
					C273.2,519.9,272.3,520.5,273.2,520.8L273.2,520.8z"
              />
              <path
                class="st1"
                d="M292,521.8c0.6,0.3,2.6-0.3,3.3-0.5c0.9-0.4,1-1.5-0.2-1c0,0,0.1,0,0.1,0c-0.9,0.2-1.7,0.4-2.6,0.6
					C292.2,520.9,291.2,521.3,292,521.8z"
              />
              <path
                class="st1"
                d="M301.8,518.9c0.8-0.3,2.6-0.7,3.2-1.3c0.5-0.5,0.4-1-0.4-1c-0.7,0-2.3,0.8-3,1.1c-0.4,0.2-0.8,0.3-0.8,0.7
					C300.8,518.8,301.4,519,301.8,518.9L301.8,518.9z"
              />
              <path
                class="st1"
                d="M252.2,520.2c-0.6-0.4-0.9,0.3-0.7,0.7c0.3,0.4,1.6,1.2,2.2,1.4c0.8,0.3,1.2,0,0.6-0.6
					C253.8,521.2,252.9,520.7,252.2,520.2z"
              />
              <path
                class="st1"
                d="M318.7,519.8c-0.9,0-3.1,2.3-1.4,2.1C317.9,521.8,320.2,519.8,318.7,519.8z"
              />
              <path
                class="st1"
                d="M246,513.5c0.2,0.5,0.4,1.3,1.1,1.3c1,0.1,0.8-0.7,0.6-1.2c-0.2-0.5-0.4-1.5-0.9-1.8
					C245.4,511.4,245.8,513,246,513.5L246,513.5z"
              />
              <path
                class="st1"
                d="M323.5,465.9C323.5,465.9,323.5,465.9,323.5,465.9C323.5,465.9,323.5,465.9,323.5,465.9z"
              />
              <path
                class="st1"
                d="M238.8,470C238.8,470,238.8,470,238.8,470C238.8,470,238.8,470,238.8,470C238.8,470,238.8,470,238.8,470
					L238.8,470z"
              />
              <path
                class="st1"
                d="M246.1,470C246.1,470,246.1,470,246.1,470C246.1,470,246.1,470,246.1,470z"
              />
              <path
                class="st1"
                d="M206.1,468.4c3.5-3.1,7-6,10.7-8.9c3.8-3,7.9-5.5,12-8.2c2-1.3,4-2.5,6.1-3.6c0.7-0.4,2.3-0.9,2.7-1.5
					c0.2-0.2,0-0.3-0.2-0.3c-0.3,0-1.7,0.9-2,1c-4.4,2.2-9,4.5-13.1,7.1c-4.5,2.9-8.7,6.1-12.7,9.5c0,0,0.1-0.1,0.1-0.1
					c-6.4,5.1-12.2,10.8-18.4,16.1c-3.1,2.6-5.9,5.6-9.1,8.1c-0.7,0.5-1.6,1-2.2,1.6c-0.8,0.8-0.2,1.2,0.8,0.7
					c1.5-0.8,2.9-1.9,4.2-2.9c0,0,0,0,0,0c1.8-1.4,3.3-3.1,5-4.6c1.7-1.5,3.4-3,5.1-4.5C198.8,474.8,202.5,471.6,206.1,468.4
					L206.1,468.4z"
              />
              <path
                class="st1"
                d="M185,487.1c0,0-0.1,0.1-0.1,0.1C184.9,487.2,185,487.2,185,487.1z"
              />
              <path
                class="st1"
                d="M252,488.4C252,488.4,252,488.4,252,488.4C252,488.4,252,488.4,252,488.4z"
              />
              <path
                class="st1"
                d="M249.5,488.1C249.5,488.1,249.5,488.1,249.5,488.1L249.5,488.1L249.5,488.1L249.5,488.1
					C249.4,488.1,249.4,488.1,249.5,488.1C249.4,488.1,249.4,488.1,249.5,488.1L249.5,488.1z"
              />
            </g>
            <g>
              <path
                class="st2"
                d="M284.3,481.8C284.3,481.8,284.3,481.8,284.3,481.8C284.3,481.8,284.3,481.8,284.3,481.8z"
              />
              <path
                class="st2"
                d="M284.3,481.8c0-0.1-0.1-0.2-0.1-0.3C284.3,481.6,284.3,481.7,284.3,481.8z"
              />
              <path
                class="st3"
                d="M284.4,483.3c0-0.3,0-0.6,0-0.9c0,0.1,0,0.1,0,0.2c-0.1-2.3-2.6-3.7-5.2-2.8c-2,0.7-3.5,2.2-4,3.9
					c-0.8,2.7,0.6,5.2,4.2,5.1C283,488.8,284.7,486.1,284.4,483.3L284.4,483.3z"
              />
            </g>
            <path
              class="st3"
              d="M270.2,491.5c-0.2,1.3-0.4,2.7-0.4,4c0,0,0,0,0-0.1c0,0.1,0,0.1,0,0.2c0,0,0,0,0,0c0,0.3,0,0.6,0,0.9
				c0,0,0-0.1,0-0.1l0,0c0,0,0,0,0-0.1c0.1,1.9,0.7,4.2,1.9,5.9c0,0-0.1-0.1-0.1-0.1c0.1,0.1,0.2,0.2,0.2,0.3c0-0.1-0.1-0.1-0.1-0.2
				c0.7,0.9,1.5,1.7,2.4,2.4c-0.1-0.1-0.3-0.2-0.4-0.3c1.7,1.4,4.3,2.4,6.7,2c0.9-0.1,1.9-0.6,1.8-1.5c-0.1-0.8-1.1-1.1-1.8-1.5
				c0,0,0.1,0,0.1,0.1c-3.1-1.4-6.2-2.7-8-5.4c-0.8-1.2-2-2.9-2-4.4c0-0.5,0.4-1.1,0.3-1.6C271,491.6,270.3,490.7,270.2,491.5
				L270.2,491.5L270.2,491.5z M270.7,493.5L270.7,493.5L270.7,493.5L270.7,493.5z M271.8,502.1C271.8,502.1,271.8,502.1,271.8,502.1
				C271.9,502.1,271.8,502.1,271.8,502.1L271.8,502.1z M270.8,500.3c0,0,0-0.1,0-0.1C270.8,500.2,270.8,500.2,270.8,500.3
				L270.8,500.3z M270.8,500.2c0-0.1,0-0.1-0.1-0.2C270.7,500,270.8,500.1,270.8,500.2L270.8,500.2z"
            />
            <path
              class="st3"
              d="M295,481c-4.7,0-8.8,3.1-9.1,7.1c-0.4,4.1,3.5,8,8.4,8.3c4.9,0.3,9.6-2.9,9.9-7.1
				C304.4,485,300.2,481,295,481L295,481L295,481z"
            />
          </g>
          <path
            d="M42.3,582.9c-6.8-21.2-13-44.7-12.5-73.4c0.8-43.4,7.7-71.8,11.7-84.8c0,0,1.6-4,2-4c0.7,0-0.7,4.4-0.7,4.4
			c-4,13-10.9,41.2-11.6,84.4c-0.5,28.5,5.7,51.9,12.4,72.9c0,0,1.3,4.1,0.9,4.3C43.9,587,42.3,582.9,42.3,582.9L42.3,582.9z"
          />
          <path
            d="M47,410.8c0,0-1.8,5.8-2.4,5.5c-0.4-0.2,1.1-5.9,1.1-5.9c2.4-8.6,5.1-17.2,7.8-25.7c2.5-7.9,5.2-15.8,8-23.6
			c0,0,3.8-9.3,4.2-9.2c0.5,0.2-2.9,9.6-2.9,9.6s-5.5,15.7-8,23.6C52,393.6,49.4,402.3,47,410.8L47,410.8z"
          />
          <path
            d="M36.6,489.6c0,0-0.6,7.4-1.3,7.4c-0.5,0,0-7.5,0-7.5c1.4-16.1,3.8-32.3,7.1-48.2c3.9-18.6,8.1-37.5,15.8-54.9
			c0.5-1.1,2.2-4.3,2.8-4c0.3,0.1-1.6,4.4-1.6,4.4c-6.5,17.8-11.8,36.2-15.7,54.7C40.4,457.3,38,473.5,36.6,489.6L36.6,489.6z"
          />
          <path
            d="M122.3,248.9c0,0-3.1,4.3-3.4,4.2c-0.2-0.1,1.3-2.9,2-4.2c0.2-0.4,0.4-0.8,0.7-1.2c4.5-6.8,47.9-72,72.3-103.6
			c18.3-23.6,58.7-70.6,58.7-70.6s2.5-2.6,2.8-2.5c0.6,0.3-2,3.6-2.2,3.8c-20.9,23.4-40.1,46.6-58.2,70
			C169.2,178.2,144.8,213.2,122.3,248.9L122.3,248.9z"
          />
          <circle cx="284.2" cy="353.3" r="8.8" />
          <circle cx="402.4" cy="232" r="8.8" />
          <circle cx="162.5" cy="233.5" r="8.8" />
          <path
            d="M288.2,75.2c7.2,2,12.4,8.6,12.4,16.4c0,9.4-7.6,17-17,17s-17-7.6-17-17c0-7.8,5.3-14.4,12.4-16.4
			c-10.4,2.1-18.2,11.3-18.2,22.3c0,12.6,10.2,22.8,22.8,22.8s22.8-10.2,22.8-22.8C306.4,86.5,298.6,77.3,288.2,75.2z"
          />
          <path
            d="M159,201.9c0,0-2.6,3.8-3.1,3.4c-0.5-0.4,1.3-3.2,2-4.2c9.3-14,19.3-27.5,29.1-40.8c11.5-15.8,18.5-25.4,28.4-37.6
			c8.9-11.1,17.6-22.7,27.9-32.2c0.9-0.8,3.6-2.9,3.8-2.7c0.2,0.2-2.8,3.6-2.8,3.6c-9.5,10.3-18.9,21.1-27.8,32.1
			c-9.9,12.2-16.8,21.7-28.4,37.5C178.3,174.4,168.6,188.1,159,201.9L159,201.9z"
          />
          <path
            d="M446.8,248.9c0,0,3.1,4.3,3.5,4.2c0.1-0.1-1.3-2.9-2-4.2c-0.2-0.4-0.5-0.8-0.7-1.2c-4.5-6.8-47.9-72-72.3-103.6
			c-18.3-23.6-58.7-70.6-58.7-70.6s-2.5-2.6-2.8-2.5c-0.6,0.3,2,3.6,2.2,3.8c20.9,23.4,40.1,46.6,58.2,70
			C399.9,178.2,424.4,213.2,446.8,248.9L446.8,248.9z"
          />
          <path
            d="M410.1,201.9c0,0,2.6,3.8,3.1,3.4c0.5-0.4-1.3-3.2-2-4.2c-9.3-14-19.3-27.5-29.1-40.8c-11.5-15.8-18.5-25.4-28.4-37.6
			c-9-11.1-17.6-22.7-27.9-32.2c-0.9-0.8-3.6-2.9-3.8-2.7c-0.2,0.2,2.8,3.6,2.8,3.6c9.5,10.3,18.9,21.1,27.8,32.1
			c9.9,12.2,16.8,21.7,28.4,37.5C390.8,174.4,400.6,188.1,410.1,201.9L410.1,201.9z"
          />
          <path
            d="M527,581.6c6.8-21.2,13-44.7,12.5-73.4c-0.8-43.4-7.7-71.8-11.7-84.8c0,0-1.6-4-2-4c-0.7,0,0.7,4.4,0.7,4.4
			c4,13,10.9,41.2,11.7,84.4c0.5,28.5-5.7,51.9-12.4,72.9c0,0-1.3,4.1-0.9,4.3C525.5,585.7,527,581.6,527,581.6L527,581.6z"
          />
          <path
            d="M522.4,409.6c0,0,1.8,5.8,2.4,5.5c0.4-0.2-1.1-5.9-1.1-5.9c-2.4-8.6-5.1-17.2-7.8-25.7c-2.5-7.9-5.2-15.8-8-23.6
			c0,0-3.8-9.3-4.2-9.2c-0.5,0.2,2.9,9.6,2.9,9.6s5.5,15.7,8,23.6C517.3,392.4,519.9,401,522.4,409.6L522.4,409.6z"
          />
          <path
            d="M532.7,488.3c0,0,0.6,7.4,1.3,7.4c0.5,0,0-7.5,0-7.5c-1.4-16.1-3.8-32.3-7.1-48.2c-3.9-18.6-8.1-37.5-15.8-54.9
			c-0.5-1.1-2.2-4.3-2.8-4c-0.3,0.1,1.6,4.4,1.6,4.4c6.5,17.8,11.8,36.2,15.7,54.7C529,456.1,531.4,472.2,532.7,488.3L532.7,488.3z"
          />
          <path
            d="M457.1,223.1C371.8,92.4,290.8,19.4,290,18.7l-5.4-4.9l-5.4,4.9c-0.8,0.7-81.8,73.8-167.1,204.5
			C12.3,376.1,6.9,481.9,9.2,520.3c3.3,54.1,16,112.1,85.5,132.2c9.8,2.8,19.3,4,28.6,4c25.2,0,48.9-9,73.5-18.3
			c27-10.2,54.9-20.8,87.8-20.8s60.8,10.6,87.8,20.8c24.6,9.3,48.3,18.3,73.5,18.3c9.3,0,18.8-1.2,28.6-4
			c69.5-20.1,82.2-78.1,85.5-132.2C562.3,481.9,556.9,376.1,457.1,223.1L457.1,223.1z M543.8,519.3c-3.1,50.9-14.1,100.3-73.8,117.6
			c-31.4,9.1-59.4-1.5-91.8-13.8c-27-10.2-57.7-21.8-93.6-21.8S218.1,612.8,191,623c-32.4,12.3-60.5,22.9-91.8,13.8
			c-59.7-17.3-70.7-66.7-73.8-117.6C23.2,482.5,28.5,381,125.7,232C197.2,122.6,265.3,54.3,284.6,35.8
			c19.3,18.4,87.3,86.6,158.9,196.2C540.6,381,546,482.5,543.8,519.3L543.8,519.3z"
          />
          <path
            class="st4"
            d="M218,71.3l-1.6,1.9c-29.6,35.2-98.8,117.6-142.8,201.5C24.5,368.4,2.9,437.6,1.4,505.9
			c-1.5,71.2,19.3,120.9,60.2,143.6c36.6,20.3,68.2,21.3,116.8,3.5c4.6-1.7,9-3.3,13.2-4.9c37.9-14,60.3-23.2,92.9-23.2
			s56.1,9.2,94,23.2c4.2,1.6,8.6,3.2,13.2,4.9c48.6,17.7,80.2,16.8,116.8-3.5c40.9-22.8,61.7-72.4,60.2-143.6
			c-1.5-68.4-23-137.5-72.2-231.2c-44-83.9-113.2-166.3-142.8-201.5l-1.6-1.9C328.8,43.4,294.1,10.9,284.6,2
			C274.4,11.7,241,44,218,71.3L218,71.3z"
          />
          <path
            d="M284.6,316.7c-45.9,0-83.2-37.3-83.2-83.2s37.3-83.2,83.2-83.2s83.2,37.3,83.2,83.2S330.5,316.7,284.6,316.7z
 M284.6,154.6c-43.5,0-78.9,35.4-78.9,78.9s35.4,78.9,78.9,78.9s78.9-35.4,78.9-78.9S328.1,154.6,284.6,154.6z"
          />
          <path
            d="M283.7,333.3c-55,0-99.8-44.8-99.8-99.8s44.8-99.8,99.8-99.8s99.8,44.8,99.8,99.8S338.7,333.3,283.7,333.3z M283.7,145.9
			c-48.3,0-87.6,39.3-87.6,87.6s39.3,87.6,87.6,87.6s87.6-39.3,87.6-87.6S332,145.9,283.7,145.9z"
          />
          <path
            d="M570.2,505.9c-1.5-68.6-23.1-137.9-72.4-231.8c-44.1-84-113.4-166.5-143-201.7l-1.6-1.9c-23.4-27.8-57.9-60.5-67.5-69.5
			c-0.4-0.3-0.7-0.6-1-0.9c-0.4,0.3-0.7,0.7-1.2,1.1c-10.3,9.8-43.7,42-66.6,69.3l-1.6,1.9C185.8,107.5,116.5,190,72.4,274
			C23.2,368,1.5,437.3,0.1,505.9C-1.4,577.6,19.6,627.7,61,650.7c19.6,10.9,37.8,16.3,57.8,16.3c17.8,0,37.1-4.2,60.1-12.7
			c4.6-1.7,9-3.3,13.2-4.9c37.7-13.9,60.1-23.1,92.5-23.1s55.8,9.2,93.6,23.1c4.2,1.6,8.6,3.2,13.2,4.9c23,8.4,42.3,12.7,60.1,12.7
			c20.1,0,38.2-5.4,57.8-16.3C550.7,627.7,571.7,577.6,570.2,505.9L570.2,505.9z M391,650.7c-4.6-1.7-8.9-3.3-13.1-4.8
			c-37.6-13.9-60.9-23-93.3-23s-54.7,9.1-92.2,23c-4.2,1.5-8.6,3.2-13.1,4.8c-48.2,17.6-79.6,16.7-115.9-3.5
			C22.7,624.6,2.1,575.3,3.6,504.6C5,436.8,26.4,368.1,75.2,275.1c43.7-83.3,112.4-165.1,141.7-200l1.6-1.9
			c22.8-27.1,56-59.1,66.1-68.8c9.5,8.8,43.9,41.1,67.1,68.8l1.6,1.9c29.4,34.9,98.1,116.7,141.7,200c48.8,93,70.2,161.6,71.7,229.5
			c1.5,70.7-19.2,120-59.7,142.6C470.7,667.3,439.2,668.3,391,650.7L391,650.7z M61.6,649.5C22,627.5,1.2,580.1,1.4,512.2
			c0,67.6,20.9,114.8,60.7,137c19.5,10.8,37.6,16.2,57.5,16.2c11,0,22.5-1.6,35.2-4.9C118.8,669.9,91.9,666.4,61.6,649.5L61.6,649.5
			z M253.6,628.1c9.8-2,19.8-3.1,31-3.1s22.1,1.2,32.5,3.4c-10.4-2.1-20.9-3.3-32.5-3.3S263.4,626.1,253.6,628.1L253.6,628.1z
 M508.6,649.5c-30.3,16.8-57.2,20.4-93.2,11c12.7,3.2,24.2,4.9,35.2,4.9c20,0,38.1-5.4,57.5-16.2c39.8-22.1,60.7-69.4,60.7-137
			C569.1,580.1,548.3,627.5,508.6,649.5L508.6,649.5z"
          />
          <g
            ref="crosshair"
            class="crosshair"
            transform="translate(265 215)"
            v-show="showCrosshair"
          >
            <line x1="0" y1="20" x2="40" y2="20"></line>
            <line x1="20" y1="0" x2="20" y2="40"></line>
          </g>
        </g>
      </g>
    </svg>
    <audio :src="bgSoundSrc" autoplay loop></audio>
    <audio :src="typeSoundSrc" ref="typeSound"></audio>
    <audio :src="eraseSoundSrc" ref="eraseSound"></audio>
    <audio :src="dramaticSoundSrc" ref="dramaticSound"></audio>
    <audio :src="unlockSoundSrc" ref="unlockSound"></audio>
  </div>
</template>
<script>
import currentGameMixin from "../../mixins/currentGameMixin";

export default {
  name: "Ouija",
  mixins: [currentGameMixin],
  data() {
    return {
      hoveredSymbol: null,
      inputWord: "",
      maxChars: 6,
      crosshairOffset: {
        x: 0,
        y: 215,
      },
      mouseDown: false,
      hotPoint: {
        x: 0,
        y: 0,
      },
      showDescription: true,
      showUnlockNotification: false,
    };
  },
  computed: {
    isLocked: function() {
      return this.$store.getters.ghostsOuijaLocked;
    },
    showCrosshair: function() {
      return this.$store.getters.ghostsOuijaShowCrosshair;
    },
    canInput: function() {
      return this.inputWord.length < this.maxChars;
    },
    bgSoundSrc: function() {
      return `${this.casePublicAudio}/ouija/background_sound.mp3`;
    },
    typeSoundSrc: function() {
      return `${this.casePublicAudio}/ouija/type_sound.mp3`;
    },
    eraseSoundSrc: function() {
      return `${this.casePublicAudio}/ouija/erase_sound.mp3`;
    },
    dramaticSoundSrc: function() {
      return `${this.casePublicAudio}/ouija/dramatic_sound.mp3`;
    },
    unlockSoundSrc: function() {
      return `${this.casePublicAudio}/common/unlock_sound.mp3`;
    },
  },
  methods: {
    playTypeSound: function() {
      this.$refs.typeSound.play();
    },
    playEraseSound: function() {
      this.$refs.eraseSound.play();
    },
    playDramaticSound: function() {
      this.$refs.dramaticSound.play();
    },
    playUnlockSound: function() {
      this.$refs.unlockSound.play();
    },
    updatePlanchette: function(event) {
      if (event.type === "mousemove" && !this.mouseDown) return;
      const board = this.$refs.board;
      const drop = this.$refs.drop;
      const dropClip = this.$refs.dropClip;
      const crosshair = this.$refs.crosshair;
      const halo = this.$refs.halo;
      const pt = board.createSVGPoint();
      const clientX = event.type.startsWith("touch")
        ? event.touches[0].clientX
        : event.clientX;
      const clientY = event.type.startsWith("touch")
        ? event.touches[0].clientY
        : event.clientY;

      const cursorPoint = function(x, y) {
        pt.x = x;
        pt.y = y;
        return pt.matrixTransform(board.getScreenCTM().inverse());
      };

      const bbox = dropClip.getBBox();
      const loc = cursorPoint(clientX, clientY);

      this.hotPoint.x = loc.x;
      this.hotPoint.y = loc.y - bbox.height + this.crosshairOffset.y;

      // hotPoint.x = Math.max(hotPoint.x, 0);
      // hotPoint.x = Math.min(hotPoint.x, board.viewBox.baseVal.width);
      // hotPoint.y = Math.max(hotPoint.y, this.crosshairOffset.y);
      // hotPoint.y = Math.min(
      //   hotPoint.y,
      //   board.viewBox.baseVal.height - bbox.height + this.crosshairOffset.y
      // );

      let px = loc.x - bbox.width / 2;
      let py = loc.y - bbox.height;

      drop.setAttribute("transform", `translate(${px} ${py}) scale(1)`);

      const bbox2 = crosshair.getBBox();
      const cx = bbox.width / 2 - bbox2.width / 2;
      const cy = this.crosshairOffset.y;

      crosshair.setAttribute("transform", `translate(${cx} ${cy}) scale(1)`);

      this.hoveredSymbol = this.getSymbolFromPoint(this.hotPoint);

      if (this.hoveredSymbol) {
        const bbox = this.hoveredSymbol.getBBox();
        const radius = halo.r.baseVal.value;

        const hx = bbox.x + bbox.width / 2 - radius;
        const hy = bbox.y + bbox.height / 2 - radius;

        halo.setAttribute("transform", `translate(${hx} ${hy}) scale(1)`);
        halo.classList.add("targeted");
      } else {
        halo.classList.remove("targeted");
      }

      event.preventDefault();
    },
    getSymbolFromPoint: function(point) {
      const resetSymbol = this.$refs.resetSymbol;

      if (this.isPointInSymbol(point, resetSymbol)) {
        return resetSymbol;
      }

      const symbols = document.getElementsByClassName("symbol");

      return Array.prototype.find.call(symbols, (s) =>
        this.isPointInSymbol(point, s)
      );
    },
    isPointInSymbol: function(point, symbol) {
      const bbox = symbol.getBBox();

      if (
        point.x >= bbox.x &&
        point.x <= bbox.x + bbox.width &&
        point.y >= bbox.y &&
        point.y <= bbox.y + bbox.height
      )
        return true;
    },
    inputSymbol: function(e) {
      if (e.target.classList.contains("hovered")) {
        e.target.classList.remove("hovered");
        console.log(`symbol input: ${e.target.dataset.symbol}`);
        this.playTypeSound();
        this.inputWord += e.target.dataset.symbol.replaceAll(" ", "");
      }
    },
    resetWord: function(e) {
      if (e.target.classList.contains("hovered")) {
        e.target.classList.remove("hovered");
        this.playEraseSound();
        this.inputWord = "";
      }
    },
  },
  watch: {
    hoveredSymbol(newValue, previousValue) {
      if (previousValue) previousValue.classList.remove("hovered");

      if (newValue && (newValue === this.$refs.resetSymbol || this.canInput))
        newValue.classList.add("hovered");
    },
    inputWord(newValue) {
      if (newValue && newValue.length > 0)
        this.$store.dispatch("ghostsTryUnlockOuija", newValue);
    },
    isLocked(newValue, previousValue) {
      if (!newValue && previousValue) {
        this.playDramaticSound();

        setTimeout(() => {
          this.showUnlockNotification = true;
        }, 2000);
      }
    },
    showUnlockNotification(newValue, previousValue) {
      if (newValue && !previousValue) {
        this.playUnlockSound();
      }
    },
  },
  async mounted() {
    await this.$nextTick();

    const symbols = document.getElementsByClassName("symbol");

    symbols.forEach((s) => {
      s.addEventListener("transitionend", this.inputSymbol);
    });

    const resetSymbol = this.$refs.resetSymbol;

    resetSymbol.addEventListener("transitionend", this.resetWord);
    this.$refs.board.addEventListener("touchstart", this.updatePlanchette);
    this.$refs.board.addEventListener("touchmove", this.updatePlanchette);

    this.showUnlockNotification = !this.isLocked;
    this.showDescription = this.isLocked;
  },
};
</script>
<style scoped>
@font-face {
  font-family: "IMFellEnglish";
  src: local("IMFellEnglish"),
    url(/common/assets/fonts/IMFellEnglish-Regular.ttf) format("truetype");
}

.ouija-container {
  position: relative;
}

.crosshair {
  stroke: #ffffff;
}

.ouija-board {
  max-height: 100vh;
  /* cursor: none; */
}

.symbol {
  stroke: #00000000;
  transition: all 1s ease-in-out;
  font-family: "Rye", cursive;
  font-size: 40px;
}

.reset-symbol {
  stroke: #00000000;
}

.reset-carving {
  fill: #e2c8ad;
}

.reset-symbol {
  fill: #4f4536;
  transition: all 1s ease-in-out;
}

.symbol.hovered {
  fill: #ffffff;
  stroke: #ffffff;
}

.reset-symbol.hovered {
  fill: #ffffff;
}

.input-text {
  font-family: "IMFellEnglish";
  font-size: 150px;
  fill: white;
}

.input-glow {
  fill: rgba(84, 238, 163, 1);
  font-family: "IMFellEnglish";
  font-size: 150px;
  filter: url(#glow);
}

.st1 {
  fill: #191412;
}

.st2 {
  fill: url(#lueur_x5F_selection_00000093140127371622970170000017317467368733747345_);
}

.st1 {
  fill: #000100;
}

.st2 {
  fill: #ffff01;
}

.st3 {
  fill: #fffffb;
}

.st4 {
  fill: none;
}

.halo {
  visibility: hidden;
}

.halo.targeted {
  visibility: visible;
}

.crosshair {
  stroke: #ffffff;
  stroke-width: 10;
}
</style>
