<template>
  <div class="section-frame fill-height">
    <div class="suitcase-container">
      <v-overlay absolute opacity="0" :value="advertiseUnlock">
        <div class="dialog-frame unlock">
          <p>
            <span v-html="$t('egypt.suitcase.unlock_desc')"></span>
          </p>
          <p></p>
        </div>
      </v-overlay>
      <v-overlay absolute opacity="0" :value="showLeftCode">
        <div class="dialog-frame left-code">
          <v-hover v-slot="{ hover }">
            <div class="suitcase-icon-close" :class="{ hovered: hover }" @click="onClickClose"></div>
          </v-hover>
          <label>{{ $t('egypt.suitcase.left_code') }}</label>
          <svg-numeric-padlock
            :secretLength="4"
            secretChar="?"
            :isOk="!isLockedLeft"
            :isLocked="isLocked"
            :initCode="codeLeft"
            @codeChange="onLeftPadlockCodeChange"
            class="padlock-ui"
            ref="padlock_left"
          >
          </svg-numeric-padlock>
        </div>
      </v-overlay>
      <v-overlay absolute opacity="0" :value="showRightCode">     
        <div class="dialog-frame right-code">
          <v-hover v-slot="{ hover }">
            <div class="suitcase-icon-close" :class="{ hovered: hover }" @click="onClickClose"></div>
          </v-hover>
          <label>{{ $t('egypt.suitcase.right_code') }}</label>          
          <svg-numeric-padlock
            :secretLength="4"
            secretChar="?"
            :isOk="!isLockedRight"
            :isLocked="isLocked"            
            :initCode="codeRight"            
            @codeChange="onRightPadlockCodeChange"
            class="padlock-ui"
            ref="padlock_right"
          >
          </svg-numeric-padlock>          
        </div>
      </v-overlay>
      <v-overlay absolute opacity="0"  :value="showLabel">     
        <div class="dialog-frame label">
          <v-hover v-slot="{ hover }">
            <div class="suitcase-icon-close" :class="{ hovered: hover }" @click="onClickClose"></div>
          </v-hover>
          <div>
            {{ $t('egypt.suitcase.label') }}
          </div>
          <div>
            {{ $t('egypt.suitcase.label_signature') }}
          </div>          
        </div>
      </v-overlay>             
      <div class="suitcase-image">
          <svg-suitcase
            :locked1="isLockedLeft"
            :locked2="isLockedRight">
          </svg-suitcase>

          <v-hover v-slot="{ hover }" v-if="!isAnyPanelShown" >
            <div class="suitcase-icon-eye leftcode" :class="{ hovered: hover }" @click="onClickLeftCode" ></div>
          </v-hover>
          <v-hover v-slot="{ hover }" v-if="!isAnyPanelShown">      
            <div class="suitcase-icon-eye rightcode" :class="{ hovered: hover }" @click="onClickRightCode"></div>
          </v-hover>
          <v-hover v-slot="{ hover }" v-if="!isAnyPanelShown">
            <div class="suitcase-icon-eye label" :class="{ hovered: hover }" @click="onClickLabel"></div>
          </v-hover>
      </div>
    </div>
    <audio :src="unlockSoundSrc" ref="unlockSound"></audio>
  </div>
</template>
<script>
import SvgNumericPadlock from "@/components/egypt/SvgNumericPadlock.vue";
import SvgSuitcase from "@/components/egypt/SvgSuitcase.vue";
import currentGameMixin from "../../mixins/currentGameMixin";

export default {
  name: "Suitcase",
  data() {
    return {
      sectionId: "suitcase",
      advertiseUnlock: false,
      showLeftCode: false,
      showRightCode: false,
      showLabel: false,
      timeout1: null,
      timeout2: null,
    };
  },
  components: {
    SvgNumericPadlock,
    SvgSuitcase,
  },  
  mixins: [currentGameMixin],
  computed: {
    isLocked: function() {
      return this.$store.getters.egyptSuitcaseLocked;
    },
    isLockedLeft: function() {
      return this.$store.getters.egyptSuitcaseLockedLeft;
    },    
    isLockedRight: function() {
      return this.$store.getters.egyptSuitcaseLockedRight;
    },       
    isLeftCodeShown: function() {
      return this.showLeftCode;
    },
    isRightCodeShown: function() {
      return this.showRightCode;
    },
    isLabelShown: function() {
      return this.showLabel;
    },
    isAnyPanelShown: function() {
      return this.isLeftCodeShown || this.isRightCodeShown || this.isLabelShown;
    },
    codeLeft: function() {
      return this.$store.getters.egyptSuitcaseCodeLeft;
    },
    codeRight: function() {
      return this.$store.getters.egyptSuitcaseCodeRight;
    },
    unlockSoundSrc: function() {
      return `${this.casePublicAudio}/unlock_sound.mp3`;
    },      
  },
  methods: {
    unlock: async function() {
      await this.$store.dispatch("unlockSection", { gameID: this.caseID, sectionID: this.sectionId });
      await this.$store.dispatch("enableSection", { gameID: this.caseID, sectionID: 'phone' });

      this.onClickClose();
      this.playUnlockSound();

      setTimeout(() => {
        this.advertiseUnlock = true;
      }, 1000);
    },
    onClickLeftCode: function() {
      this.showLeftCode = true;
    },
    onClickRightCode: function() {
      this.showRightCode = true; 
    },
    onClickLabel: function() {
      this.showLabel = true;
    },
    onClickClose: function() {
      this.showLabel = false;
      this.showRightCode = false;
      this.showLeftCode = false;
    },
    playUnlockSound: function() {
      this.$refs.unlockSound.play();
    },    
    onLeftPadlockCodeChange(code) {
        if(this.timeout1) {
          clearTimeout(this.timeout1);
        }
        this.timeout1 = setTimeout(() => {
          this.$store.dispatch("egyptTryUnlockSuitcaseLeft", code);
        }, 1000);
    },
    onRightPadlockCodeChange(code) {
      if(this.timeout2) {
          clearTimeout(this.timeout2);
        }
        this.timeout2 = setTimeout(() => {
          this.$store.dispatch("egyptTryUnlockSuitcaseRight", code);
        }, 1000);
    },    
  },
  mounted: async function() {
    if (!this.isLocked) this.advertiseUnlock = true;
  },
  watch: {
    isLocked: function(newValue, previousValue) {
      if (previousValue && !newValue) this.unlock();
    },
  },
};
</script>
<style scoped>
.section-frame {
  border: 0 !important;
  background: transparent !important;
}
.suitcase-container {
  background: transparent;
  position: relative;
  font-family: Arimo;
  padding-bottom: 30px;
}
.suitcase-image {
  width: 80%;
  position: relative;
  margin: auto;
  margin-top: 40px;
}
.suitcase-image img {
  width: 100%;
  position: relative;  
  display: block;
}
.suitcase-icon-eye {
  width: 45px;
  height: 45px;
  position: absolute !important;
  cursor: pointer;
  background-image: url(/egypt/assets/images/suitcase/icone_oeil_55.svg);
}
.suitcase-icon-eye.hovered {
  background-image: url(/egypt/assets/images/suitcase/icone_oeil_55_hover.svg);
}
.suitcase-icon-eye.leftcode {
  left: 14%;
  top: 60%;
}
.suitcase-icon-eye.rightcode {
  left: 80%;
  top: 60%;
}
.suitcase-icon-eye.label {
  left: 33%;
  top: 78%;
}
.suitcase-icon-close {
  width: 45px;
  height: 45px;
  position: absolute !important;
  cursor: pointer;
  background-image: url(/egypt/assets/images/suitcase/icone_close.svg);
  top: 10px;
  right: 10px;
}
.suitcase-icon-close.hovered {
  background-image: url(/egypt/assets/images/suitcase/icone_close_hover.svg);
}
.dialog-frame {
  border-radius: 0;
  width: 305px;
  height: 330px;
}

.dialog-frame.unlock {
  width: 100%;
  height: auto;
}

.dialog-frame label {
  margin-top: 20px;
  font-family: 'Bebas Neue';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 22px;
}

.dialog-frame .padlock-ui {
  margin-top: 30px;
}

.dialog-frame.label {
  min-width: 205px !important;
  width: 205px;
  height: 250px;
  font-family: Arimo;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-transform: none;
  padding-top: 65px;
}
</style>
