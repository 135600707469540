<template>
  <div class="phone-container">
    <svg-phone
      class="phone-ui"
      @pinEntered="onPin"
      @input="onInput"
      :locked="locked"
      :error="error"
    ></svg-phone>
    <audio :src="unlockSoundSrc" ref="unlockSound" />
  </div>
</template>

<script>
import SvgPhone from "@/components/egypt/SvgPhone.vue";
import currentGameMixin from "../../mixins/currentGameMixin";

export default {
  components: { SvgPhone },
  name: "Phone",
  data() {
    return {};
  },
  mixins: [currentGameMixin],  
  computed: {
    locked: function () {
      return this.$store.getters.egyptPhoneLocked;
    },
    error: function() {
      return this.$store.getters.egyptPhoneError;
    },
    unlockSoundSrc: function() {
      return `${this.casePublicAudio}/unlock_sound_phone.mp3`;
    }, 
  },
  watch: {
    locked: function (newValue) {
      this.overlay = !newValue;
      this.playUnlockSound();
    },
  },
  methods: {

    onPin: function (pin) {
      this.$store.dispatch("egyptTryUnlockPhone", pin);
    },
    onInput: function() {
      if (this.error)
        this.$store.dispatch("egyptResetPhone");
    },
    playUnlockSound: function() {
      this.$refs.unlockSound.play();
    }
  },
  created() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.phone-container {
  width: 100%;
  max-width: 100%;
  background-color: #00000000;
}

.phone-ui {
  max-width: 300px;
  margin-left:auto;
  margin-right: auto;
}
</style>
