<template>
  <div>
    <div>
      <v-container class="pa-0">
        <router-view></router-view>
      </v-container>
    </div>
  </div>
</template>

<script>
export default {
  name: "Research",
  data() {
    return {

    };
  },
  components: {

  },
  mounted() {
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
