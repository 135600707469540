<template>
    <div class="dc-short-text">
    <subscribe :caseID="caseID" v-if="allowedLocales.includes('it')"></subscribe>
    <h2>Soluzione</h2>
    <p>
        Il 18 febbraio 2024 alle ore 7:15, Daren Jobs, capo della sicurezza dello zoo, ritrova il corpo di
        Meredith Carter nel Boschetto degli orsi.
    </p>
    <p>
        Da diverse settimane, Meredith conduce un&#39;indagine su un traffico di animali a partire dallo zoo
        stesso (<i>Pannello d&#39;indagine di Meredith</i>). La donna ha identificato la tigre Surya, riconosciuta su un
        volantino del circo, dove figura anche Russel McBrook, ex dipendente licenziato per
        maltrattamento di animali (<i>Recensione di un visitatore dello zoo, Tesserino di Russel</i>). Le ricerche
        di Meredith hanno rivelato che alcuni animali, ufficialmente &quot;liberati nel loro habitat naturale&quot;
        secondo il registro dello zoo (<i>Animali reintrodotti nel loro habitat naturale</i>), non hanno mai
        raggiunto la loro destinazione. Contattando la Riserva di Sikhote-Alin in Siberia, Meredith constata
        che alcuni animali non vi sono mai arrivati (<i>E-mail dalla Riserva di Sikhote-Alin</i>). Meredith ha
        inoltre notato che l&#39;orso Leo doveva essere trasferito in Siberia sotto la supervisione di Carmen.
        Queste incoerenze l&#39;hanno portata a sospettare che Carmen falsifichi i documenti per vendere gli
        animali a dei clienti privati o a dei circhi, in particolare quello di Russel.
    </p>
    <p>
        Carmen, sentendosi minacciata, invia una lettera minatoria anonima a Meredith, per dissuaderla
        dal continuare le sue indagini (<i>Foto della scrivania di Meredith</i>). Carmen ha organizzato questo
        traffico spinta da difficoltà finanziarie legate alle costose cure della madre malata (<i>Foto degli effetti
        personali di Carmen</i>).
    </p>
    <p>
        Svariati indizi permettono di stabilire la cronologia della notte dell&#39;omicidio e di collegare Carmen al
        crimine.
    </p>
    <p>
        Alle 22:10, il capo della sicurezza nota un&#39;agitazione insolita fra le scimmie, 20 minuti dopo la
        distribuzione del loro pasto (<i>Testimonianza di Dare</i>n). In realtà, quella è l&#39;ora in cui Carmen si
        introduce nello zoo, entrando dalla zona di manutenzione e attraversando l&#39;Isola delle scimmie,
        causando appunto la loro agitazione (<i>Registro utilizzi tesserini</i>).
    </p>
    <p>
        Il registro degli utilizzi dei tesserini conferma un ingresso alle 21:50 (<i>Kelly, per nutrire le scimmie,
        vedere Schema di alimentazione degli animali</i>) e un secondo ingresso sospetto dall&#39;esterno alle
        22:07, corrispondente all&#39;arrivo di Carmen. La videosorveglianza mostra una sagoma che si
        allontana dall&#39;Isola delle scimmie per dirigersi verso il Centro medico, dove Carmen recupera il
        fucile con i dardi (<i>Mappa dello zoo, Riserva dardi</i>).
    </p>
    <p>
        Alle 22:22, Kelly nota una luce accesa nel Centro medico (<i>Conversazione fra Kelly e sua madre</i>).
        Carmen, dopo aver preso l&#39;attrezzatura, si reca al Boschetto degli orsi, dove Meredith deve andare
        per distribuire il pasto alle 22:30.
    </p>
    <p>
        Il Registro utilizzi tesserini conferma l&#39;ingresso di Meredith e poi quello di Carmen nella cinta degli
        orsi. Carmen spara a Meredith ed esce dalla cinta alle 22:40.
    </p>
    <p>
        Il dardo utilizzato per uccidere Meredith non viene ritrovato sul cadavere. Donald, un cebo
        cappuccino che spesso accompagna Meredith, ha l&#39;abitudine di rubare piccoli oggetti (<i>Carta di
        Donald, Extragram di una visitatrice</i>) e di nasconderli nell&#39;appartamento di Meredith, nel condotto
        di aerazione. Il dardo viene ritrovato infatti proprio lì (<i>Foto del condotto di aerazione</i>).
    </p>
    <p>
        Inseguendo Donald, che ha rubato il dardo, Carmen ritorna verso l&#39;Isola delle scimmie. Donald si
        arrampica allora sulle corde vicino alla gabbia di Abelli, scatenandone le urla (<i>Mappa dell&#39;Isola
        delle scimmie, Testimonianza di Daren, Conversazione fra Kelly e sua madre</i>). Kelly e Daren,
        allertati dalle grida, si dirigono verso l&#39;Isola delle scimmie. Carmen interrompe l&#39;inseguimento,
        lascia il fucile nella zona di manutenzione e scappa (<i>Foto del fucile nella zona di manutenzione</i>).
    </p>
    <p>
        Kelly, la stagista, dice a sua madre di aver sentito dei rumori nei condotti verso le 23:00, ovvero
        l&#39;ora in cui Donald ha nascosto il dardo (<i>Conversazione fra Kelly e sua madre</i>).
    </p>
    <p>
        L&#39;analisi della riserva di dardi rivela che ne mancano tre; due sono stati sparati quella stessa
        mattina sugli orsi, da parte di Daren. Carmen ha dunque utilizzato il terzo dardo, letale su qualcuno
        di peso inferiore ai 150 kg, su Meredith. Infine, la To do list di Richard, l&#39;addetto a felini e ursidi,
        conferma che le gabbie della cinta degli orsi erano state ben chiuse quella sera, e che la riserva di
        dardi era stata rifornita. Carmen ha aperto le gabbie dopo l&#39;omicidio per simulare un incidente.
    </p>
    <p>
        Malgrado il suo conflitto con Meredith a causa del progetto SMART, Luis non può essere
        l&#39;assassino poiché il suo tesserino non possiede le autorizzazioni necessarie a entrare nel Centro
        medico (<i>Tesserino di Luis, Mappa dello zoo</i>). Non avrebbe dunque potuto impossessarsi dell&#39;arma
        del delitto.
    </p>
    <p>
        Kelly stava conversando con la madre al computer all&#39;ora del delitto, come testimoniano gli orari
        dei messaggi che si sono scambiate (<i>Conversazione fra Kelly e sua madre</i>). Le autorizzazioni del
        suo tesserino non le permettono inoltre di accedere alla zona di manutenzione, dove il fucile è
        stato ritrovato (<i>Tesserino di Kelly, Mappa dello zoo</i>). Infine, i suoi dissapori con Meredith a causa
        di Abelli sembrano essere un movente insufficiente.
    </p>
    <p>
        Richard non si trovava allo zoo la sera del delitto. Era infatti uscito in anticipo, per assistere al
        concerto dei Cowboys di Kenwood (<i>To do list di Richard, Articolo del Kenwood Paper</i>).
    </p>
    <p>
        Quanto a Russel, quella sera era impegnato con l&#39;anteprima del suo spettacolo circense alle 21:30
        (<i>Volantino del circo</i>). Se è complice di Carmen nel traffico di animali, non lo è però nell&#39;omicidio.
    </p>
    <p>
        Infine, Daren è innocente poiché ha trascorso tutta la notte all&#39;interno dello zoo. Non aveva alcun
        interesse a fuggire dallo zoo tramite la zona di manutenzione e ha trascorso il tempo alla
        postazione di sicurezza (<i>Testimonianza di Daren</i>). Inoltre, non ha alcun movente contro Meredith.
    </p>

    </div>
</template>

<script>
import config from '@/config/';
import Cases from "@/enums/Cases";
import currentGameMixin from "../../mixins/currentGameMixin";
import Subscribe from "@/components/Subscribe.vue";  

export default {
  name: "MeredithCarter",
  components: { Subscribe },
  mixins: [currentGameMixin],
  computed: {
    allowedLocales: function() {
        console.log(this.currentLocale)
      return config.common.showSubscribeForLocales
    },  
  },
  watch: { },
  mounted() {
    this.$i18n.locale = "it";
  },
  created() {
    this.setCurrentGame(Cases.MEREDITHCARTER);
  },    
}
</script>

<style scoped>
.dc-short-text {
    color: #fff;
    padding: 30px;
    text-align: center;
}
</style>