<template>
  <div class="section-frame fill-height">
    <div class="tourist-office-container">
      <div class="tourist-office-header">
        <img
          src="@/assets/images/ghosts/tourist/logo.svg"
          class="tourist-office-logo"
        />
        <h2>{{ $t("ghosts.touristOffice.header.title") }}</h2>
      </div>
      <v-img
        src="@/assets/images/ghosts/tourist/banner.jpg"
        class="tourist-office-banner"
      ></v-img>
      <div class="tourist-office-view">
        <tourist-office-menu class="tourist-office-menu"></tourist-office-menu>
        <v-container class="fill-height pa-0" fluid>
          <router-view></router-view>
        </v-container>
      </div>
    </div>
  </div>
</template>
<script>
import TouristOfficeMenu from "@/components/ghosts/TouristOfficeMenu";
import currentGameMixin from "../../mixins/currentGameMixin";

export default {
  name: "TouristOffice",
  data() {
    return {};
  },
  components: {
    TouristOfficeMenu,
  },
  mixins: [currentGameMixin],
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.tourist-office-container {
  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tourist-office-header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tourist-office-header > img {
  margin-top: 24px;
}

.tourist-office-header > h2 {
  font-family: Familjen Grotesk;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0.065em;
  text-align: center;
  color: #989898;
  text-transform: uppercase;
  margin: 9px 0;
}

.tourist-office-logo {
  max-width: 223px;
}

.tourist-office-banner {
  height: 264px;
}

.tourist-office-menu {
  margin: 0 auto;
}

.tourist-office-view {
  padding: 0 10%;
  width: 100%;
}
</style>
